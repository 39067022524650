import { useEffect } from 'react';

export function useKeyboardNavigation(
  socialLinksOpen: boolean,
  setSocialLinksOpen: React.Dispatch<React.SetStateAction<boolean>>,
  nameRef: React.RefObject<HTMLInputElement | null>,
  descriptionRef: React.RefObject<HTMLInputElement | null>,
  phoneNumberRef: React.RefObject<HTMLInputElement | null>,
  emailRef: React.RefObject<HTMLInputElement | null>,
  websiteRef: React.RefObject<HTMLInputElement | null>,
  facebookRef: React.RefObject<HTMLInputElement | null>,
  instagramRef: React.RefObject<HTMLInputElement | null>,
  twitterRef: React.RefObject<HTMLInputElement | null>,
  linkedinRef: React.RefObject<HTMLInputElement | null>,
  youtubeRef: React.RefObject<HTMLInputElement | null>
) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();

        const inputs: (HTMLElement | null)[] = [
          nameRef.current,
          descriptionRef.current,
          phoneNumberRef.current,
          emailRef.current,
          // ... any other refs before the accordion
        ];

        const socialInputs: (HTMLElement | null)[] = [
          websiteRef.current,
          facebookRef.current,
          instagramRef.current,
          twitterRef.current,
          linkedinRef.current,
          youtubeRef.current,
        ];

        const nonNullInputs: HTMLElement[] = inputs.filter(Boolean) as HTMLElement[];
        const nonNullSocialInputs: HTMLElement[] = socialInputs.filter(Boolean) as HTMLElement[];

        const allInputs = [...nonNullInputs, ...nonNullSocialInputs];
        const index = allInputs.indexOf(e.target as HTMLElement);

        if (index !== -1) {
          let nextElement: HTMLElement | null = null;
          if (e.key === 'ArrowDown') {
            nextElement = allInputs[index + 1] || allInputs[0];
          } else if (e.key === 'ArrowUp') {
            nextElement = allInputs[index - 1] || allInputs[allInputs.length - 1];
          }

          if (nextElement) {
            if (nonNullInputs.includes(nextElement) && socialLinksOpen) {
              setSocialLinksOpen(false);
            } else if (nonNullSocialInputs.includes(nextElement) && !socialLinksOpen) {
              setSocialLinksOpen(true);
            }

            nextElement.focus();
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    socialLinksOpen,
    nameRef,
    descriptionRef,
    phoneNumberRef,
    emailRef,
    websiteRef,
    facebookRef,
    instagramRef,
    twitterRef,
    linkedinRef,
    youtubeRef,
  ]);
}
