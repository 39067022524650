// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppearanceSettings_section-heading__zjXZO .AppearanceSettings_reset-button__CorKk {
  color: var(--accent-color);
  font-size: 1rem;
  font-weight: 500;
  margin-left: 5px;
}

.AppearanceSettings_table__YlgLk {
  width: 100%;
}
.AppearanceSettings_table__YlgLk .AppearanceSettings_previews__O20nc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.AppearanceSettings_table__YlgLk .AppearanceSettings_previews__O20nc > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.AppearanceSettings_table__YlgLk td {
  padding: 10px;
}
.AppearanceSettings_table__YlgLk input[type=range] {
  width: calc(100% - 65px);
  min-width: 70px;
}
.AppearanceSettings_table__YlgLk .AppearanceSettings_color-input__TfGho {
  display: inline-block;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #999;
  margin-right: 10px;
}
.AppearanceSettings_table__YlgLk .AppearanceSettings_color-input__TfGho input[type=color] {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/AppearanceSettings/AppearanceSettings.module.scss"],"names":[],"mappings":"AACG;EAEG,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAN;;AAIA;EACG,WAAA;AADH;AAGG;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,eAAA;AADN;AAGM;EACG,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AADT;AAKG;EACG,aAAA;AAHN;AAMG;EACG,wBAAA;EACA,eAAA;AAJN;AAOG;EACG,qBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;AALN;AAOM;EACG,eAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AALT","sourcesContent":[".section-heading {\n   .reset-button {\n      margin-left: 10px;\n      color: var(--accent-color);\n      font-size: 1rem;\n      font-weight: 500;\n      margin-left: 5px;\n   }\n}\n\n.table {\n   width: 100%;\n\n   .previews {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: space-evenly;\n      flex-wrap: wrap;\n\n      >div {\n         display: flex;\n         flex-direction: column;\n         align-items: center;\n         margin: 10px;\n      }\n   }\n\n   td {\n      padding: 10px;\n   }\n\n   input[type=\"range\"] {\n      width: calc(100% - 65px);\n      min-width: 70px;\n   }\n\n   .color-input {\n      display: inline-block;\n      width: 50px;\n      height: 20px;\n      border-radius: 10px;\n      overflow: hidden;\n      border: 1px solid #999;\n      margin-right: 10px;\n\n      input[type=\"color\"] {\n         cursor: pointer;\n         width: 100%;\n         height: 100%;\n         opacity: 0;\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-heading": `AppearanceSettings_section-heading__zjXZO`,
	"reset-button": `AppearanceSettings_reset-button__CorKk`,
	"table": `AppearanceSettings_table__YlgLk`,
	"previews": `AppearanceSettings_previews__O20nc`,
	"color-input": `AppearanceSettings_color-input__TfGho`
};
export default ___CSS_LOADER_EXPORT___;
