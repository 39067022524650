import React from 'react';
import TextInput from 'Components/forms/TextInput';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TOnboardingPayload } from 'types/shared';

interface LastNameInputProps {
    register: UseFormRegister<TOnboardingPayload>;
    defaultValue?: string;
    errors: FieldErrors<TOnboardingPayload>;
}

const LastNameInput: React.FC<LastNameInputProps> = ({ register, defaultValue, errors }) => {
    return (
        <TextInput
            register={register}
            inputName='last_name'
            errors={errors}
            placeholder='Enter your last name'
            inputLabel='Last Name (optional):'
            required={false}
            defaultValue={defaultValue || ''}
        />
    );
};

export default LastNameInput;
