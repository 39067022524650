import { Link } from 'react-router-dom';

import styles from './SearchForm.module.scss';
import LargeLoadingSpinner from 'Components/LargeLoadingSpinner/LargeLoadingSpinner';


export type SearchResult = {
	mainText: string;
	subText: string;
	matchedObject: string;
	matchedSkill: string;
	url: string;
	targetType: 'user' | 'location' | 'contact';
	/** The `id` of the target element */
	targetId: number;
	distance: number;
};


/**
 * The list of search results. This is a separate component
 * so that it can be conditionally rendered.
 */
export function ResultsList({
	searchResults,
	stillLoading,
}: {
	/** The search results */
	searchResults: SearchResult[] | null;
	/** Whether the search results are still loading or not */
	stillLoading: boolean;
}) {
	return (
		<div className={styles['search-results']}>
			{searchResults && !stillLoading ? (
				searchResults.length === 0 ? (
					<div className={styles['no-results-found']}>
						<p>No results found</p>
					</div>
				) : (
					searchResults.map((result) => (
						<div
							className={styles['result']}
							key={`${result.targetType}:${result.targetId}`}
						>
							<Link to={result.url}>
								<div className={styles['main']}>
									<span>{result.mainText}</span>
									<span className={styles['distance']}>
										{result.distance}km
									</span>
								</div>
								<div className={styles['sub']}>{result.subText}</div>
								<div className={styles['skills']}>{result.matchedSkill}</div>
								<div className={styles['objects']}>{result.matchedObject}</div>
							</Link>
						</div>
					))
				)
			) : (
				<div className={styles['loading-spinner']}>
					<LargeLoadingSpinner primaryColor="grey" secondaryColor="#0002" />
				</div>
			)}
		</div>
	);
}