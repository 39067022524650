// UserOnboarding.tsx
import React, { useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useMount, useSetState } from 'react-use';
import a11yChecker from 'a11y-checker';
import { createJoyrideSteps } from './JoyrideSteps';

interface Props {
  breakpoint: string;
}

interface State {
  run: boolean;
  steps: Step[];
}

const UserOnboarding: React.FC<Props> = (props) => {
  const [{ run }, setState] = useSetState<State>({
    run: false,
    steps: createJoyrideSteps(),
  });

  useMount(() => {
    a11yChecker();
  });

  const handleClickStart = () => {
    setState({
      run: true,
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let newUser = localStorage.getItem('newUser');
      if (newUser) {
        handleClickStart();
      }
      localStorage.removeItem('newUser');
    }, 2000);
  }, []);

  return (
    <>
      {/* Other components, if any */}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={createJoyrideSteps()}
        disableOverlayClose={false}  // Allows users to interact with the background
        spotlightClicks={true}       // Allows users to click on elements in the spotlight
				  styles={{
          options: {
            backgroundColor: '#e3ffeb',
            primaryColor: '#000',
            textColor: '#004a14',
            zIndex: 1000000000,
          },
        }}
      />
    </>
  );
};

export default UserOnboarding;
