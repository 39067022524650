// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowMyLocation_my-current-location__1OewL {
  box-shadow: var(--shadow);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: white;
  height: 45px;
  width: 45px;
  margin-right: 5px;
}
.ShowMyLocation_my-current-location__1OewL svg {
  height: 20px;
}
.ShowMyLocation_my-current-location__1OewL.ShowMyLocation_loading__kHjN4 svg {
  animation: ShowMyLocation_blink-green__UWUam 1s ease infinite;
}
@keyframes ShowMyLocation_blink-green__UWUam {
  0% {
    fill: var(--accent-color-xx-dark);
  }
  50% {
    fill: var(--accent-color);
  }
  100% {
    fill: var(--accent-color-xx-dark);
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FloatingControls/ShowMyLocation/ShowMyLocation.module.scss"],"names":[],"mappings":"AAAA;EACG,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AACH;AACG;EACG,YAAA;AACN;AAGM;EACG,6DAAA;AADT;AAGS;EACG;IACG,iCAAA;EADb;EAIU;IACG,yBAAA;EAFb;EAKU;IACG,iCAAA;EAHb;AACF","sourcesContent":[".my-current-location {\n   box-shadow: var(--shadow);\n   border-radius: 25px;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n   border: none;\n   background-color: white;\n   height: 45px;\n   width: 45px;\n   margin-right: 5px;\n\n   svg {\n      height: 20px;\n   }\n\n   &.loading {\n      svg {\n         animation: blink-green 1s ease infinite;\n\n         @keyframes blink-green {\n            0% {\n               fill: var(--accent-color-xx-dark);\n            }\n\n            50% {\n               fill: var(--accent-color);\n            }\n\n            100% {\n               fill: var(--accent-color-xx-dark);\n            }\n         }\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"my-current-location": `ShowMyLocation_my-current-location__1OewL`,
	"loading": `ShowMyLocation_loading__kHjN4`,
	"blink-green": `ShowMyLocation_blink-green__UWUam`
};
export default ___CSS_LOADER_EXPORT___;
