import React from "react";
import { LocationEditMarker } from "../LocationEditMarker/LocationEditMarker";
import ContactEditMarker from "../ContactEditMarker/ContactEditMarker";
import AddTravelPlan from "../AddTravelPlan/AddTravelPlan";
import { DispatchAction } from "../reducer";
import { TravelPlanData } from "../HomePageTypes";

import { HomePageState } from "Pages/Home/HomePageState";


interface EditMarkersProps {
  state: HomePageState;
  dispatch: (action: DispatchAction) => void;
}
//TODO Fix the add travel Plan when editing state (currently the url is piinting to URL 3000 instead of backedn 8000)
const EditMarkers: React.FC<EditMarkersProps> = ({ state, dispatch }) => {
  return (
    <>
      {state.locationBeingEdited && !state.addingCoordinates && (
        <LocationEditMarker
          locationData={state.locationBeingEdited.data}
          state={state}
          dispatch={dispatch}
        />
      )}
      {state.contactBeingEdited && !state.addingCoordinates && (
        <ContactEditMarker
          contact={state.contactBeingEdited.data}
          state={state}
          dispatch={dispatch}
        />
      )}
      {state.editingTravelPlan ? (
        <AddTravelPlan
          initialValue={state.editingTravelPlan as TravelPlanData} // Cast to the correct type
          dispatch={dispatch}
          state={state}
        />
      ) : null}
    </>
  );
};

export default EditMarkers;
