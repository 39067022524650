import React, { useState } from 'react'

import styles from './DeleteAccount.module.scss'
import SmallLoadingSpinner from 'Components/SmallLoadingSpinner/SmallLoadingSpinner'
import CustomCheckbox from 'Components/CustomCheckbox/CustomCheckbox'
import { useDocumentTitle } from 'hooks/useSEO'

import { useAxiosPrivate } from 'hooks/useAxiosPrivate'
import { useRouter } from 'hooks/useRouter'
import { apiRoutes } from 'services/routes'
import { errorHandler } from 'services/utils'
import { useAuth } from '../../../hooks/useAuth'

/**
 * Page used to delete the user's account.
 * This page is only accessible if the user is logged in.
 * @returns
 */
export default function DeleteAccount () {
  useDocumentTitle('Delete Account')
  const { clearLocalStorage } = useAuth()
  /**
   * `"waiting confirmation"` = Waiting for the user to confirm
   * that they actually want to delete
   *
   * `"confirmed choice"` = When the user has confirmed that
   * they actually want to delete
   *
   * `"loading"` = Waiting for the response from the server
   *
   * `"successfull"` = Successfuly deleted
   */
  type Status =
    | 'waiting confirmation'
    | 'confirmed choice'
    | 'loading'
    | 'successfull'

  let [deletionStatus, setDeletionStatus] = useState<Status>(
    'waiting confirmation'
  )

  const router = useRouter()
  const axiosPrivate = useAxiosPrivate()
  const onSubmit = async () => {
    setDeletionStatus('loading')
    try {
      const res = await axiosPrivate.delete(apiRoutes.DELETE_ACCOUNT)
      if (res.status === 200) {
        //clear localstorage
        clearLocalStorage?.()
        router.push('/accounts/login/')
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  return (
    <main className={styles['delete-account']}>
      <form onSubmit={e => e.preventDefault()} method='POST'>
        <h1>Are you sure?</h1>
        <p>Deleting your account will be irreversible</p>
        <p
          className={styles['checkbox-container']}
          onClick={() =>
            deletionStatus === 'confirmed choice'
              ? setDeletionStatus('waiting confirmation')
              : deletionStatus === 'waiting confirmation'
              ? setDeletionStatus('confirmed choice')
              : null
          }
        >
          <CustomCheckbox
            checked={deletionStatus !== 'waiting confirmation'}
            disabled={['loading', 'successfull'].includes(deletionStatus)}
            accentColor='red'
          />{' '}
          Yes, I'm sure
        </p>
        <button
          type='submit'
          className='btn btn-danger btn-block mb-4'
          disabled={deletionStatus !== 'confirmed choice'}
          onClick={onSubmit}
        >
          {deletionStatus === 'loading' ? (
            <>
              <SmallLoadingSpinner
                primaryColor='white'
                secondaryColor='#fff3'
                size={18}
                margin='0 10px'
              />
              Loading
            </>
          ) : (
            'Delete account'
          )}
        </button>
      </form>
    </main>
  )
}