import moment from 'moment';
import parseDuration from './parseDuration';

// Function to calculate days until contact
export const calculateDaysUntilContact = (contactTimestamp: string, minimumTimeToContact: string): number => {
    console.log('@@@Input contactTimestamp:', contactTimestamp);
    console.log('Input minimumTimeToContact:', minimumTimeToContact);

    const contactDate = moment(contactTimestamp, "YYYY-MM-DD HH:mm:ss");
    console.log('Parsed contactDate:', contactDate.format());

    const duration = parseDuration(minimumTimeToContact);
    console.log('Parsed duration:', duration);

    const adjustedContactDate = contactDate.add(duration);
    console.log('Adjusted contactDate:', adjustedContactDate.format());

    const daysUntilContact = adjustedContactDate.diff(moment(), 'days');
    console.log('Days until contact:', daysUntilContact);

    return daysUntilContact;
};


export const parseHumanTimeToDays = (humanTime: string): number => {
    console.log('[parseHumanTimeToDays]@@@Input humanTime:', humanTime);
    const parts = humanTime.split(' ');
    if (parts.length !== 2) {
        throw new Error('Invalid input format. Expected: "X units"');
    }

    const amount = parseInt(parts[0], 10);
    const unit = parts[1].toLowerCase();

    switch (unit) {
        case 'day':
        case 'days':
            return amount;
        case 'week':
        case 'weeks':
            return amount * 7;
        case 'month':
        case 'months':
            return amount * 30; // Approximation
        case 'year':
        case 'years':
            return amount * 365; // Approximation
        default:
            throw new Error('Invalid time unit. Supported units: days, weeks, months, years');
    }
};