import  { useState, useEffect } from "react";
import L from "leaflet";

import { DispatchAction } from "../../Pages/Home/reducer";
import { useDebouncedAction } from "hooks/useDebounce";
import { apiRoutes } from "services/routes";
import { coreFetchData } from "./coreFetchData"; // Adjust the path as necessary



/**
 * Automatically manages the loading and reloading of data
 * whenever the user scrolls on the map. The data can either
 * be map clusters or the direct users, contacts, and places
 *
 * @returns The number of iterations to the current fetch request. It can be useful for forcing re-renders or other actions
 */
export function useCoreMapData(
    mapBounds: L.LatLngBounds | null,
    layersDisplaying: Array<String>,
    dispatch: (value: DispatchAction) => void,
    isSearching: boolean
): number {
    // A count of the number of iterations to the current fetch request
    let [fetchCount, setFetchCount] = useState(0);

    let delayRequest = useDebouncedAction();


    // Fetch data normally on the map. This is only done when the layer
    // is being displayed. Otherwise, the data is not loaded.
    useEffect(() => {
        if (!isSearching && mapBounds) {
            delayRequest(
                coreFetchData(
                    apiRoutes.GET_MAP_DATA +
                        ("?layersDisplaying=" + layersDisplaying.join(",")) +
                        "&bbox=" +
                        mapBounds.toBBoxString(),
                        dispatch, 
                        setFetchCount
                ),
                500
            );
        }
    }, [mapBounds, isSearching, layersDisplaying]);

    return fetchCount;
}
