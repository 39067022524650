import React, { useState,useEffect, ChangeEvent } from 'react';
import styles from './TimeInputComponent.module.scss'; // Import the SCSS file

enum TimeUnit {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years'
}

type TimeInputComponentProps = {
  label: string;
  name: string;
  timeValue: number;
  timeUnit: string;
  onValueChange: (name: string, inputValue: number) => void;
  onUnitChange: (name: string, newUnit: TimeUnit) => void; // Updated type
};

type UnitBounds = {
  [key in TimeUnit]: { min: number; max: number };
};

const timeUnitBounds: UnitBounds = {
  [TimeUnit.Days]: { min: 1, max: 31 },
  [TimeUnit.Weeks]: { min: 1, max: 52 },
  [TimeUnit.Months]: { min: 1, max: 24 },
  [TimeUnit.Years]: { min: 1, max: 10 },
};

type TimeUnitTransition = {
  [key in TimeUnit]: TimeUnit;
};

const nextTimeUnit: TimeUnitTransition = {
  [TimeUnit.Days]: TimeUnit.Weeks,
  [TimeUnit.Weeks]: TimeUnit.Months,
  [TimeUnit.Months]: TimeUnit.Years,
  [TimeUnit.Years]: TimeUnit.Years, // No next unit after Years
};

const prevTimeUnit: TimeUnitTransition = {
  [TimeUnit.Days]: TimeUnit.Days, // No previous unit before Days
  [TimeUnit.Weeks]: TimeUnit.Days,
  [TimeUnit.Months]: TimeUnit.Weeks,
  [TimeUnit.Years]: TimeUnit.Months,
};

const TimeInputComponent = ({
  label,
  name,
  timeValue: initialTimeValue,
  timeUnit: initialTimeUnit,
  onValueChange,
  onUnitChange
}: TimeInputComponentProps) => {
  const [isCooldownActive, setIsCooldownActive] = useState(false);

  const [localTimeUnit, setLocalTimeUnit] = useState<string>(initialTimeUnit);
  const [localTimeValue, setLocalTimeValue] = useState<number>(initialTimeValue);
  const [autoUnitChange, setAutoUnitChange] = useState(false);
  const [isSliderActive, setIsSliderActive] = useState(false);

  useEffect(() => {
    if (autoUnitChange) {
      // Reset the highlight after a delay
      const timer = setTimeout(() => setAutoUnitChange(false), 1000); // Adjust time as needed
      return () => clearTimeout(timer);
    }
  }, [autoUnitChange]);

  useEffect(() => {
    // When the unit changes, activate cooldown
    setIsCooldownActive(true);
  
    // Set a timeout to deactivate cooldown after a specified period
    const cooldownTimer = setTimeout(() => {
      setIsCooldownActive(false);
    }, 300); // COOLDOWN_PERIOD is the time in milliseconds
  
    // Cleanup the timer on component unmount or if the unit changes again
    return () => clearTimeout(cooldownTimer);
  }, [localTimeUnit]);
  // Handle changes in time unit or value


  useEffect(() => {
    const bounds = timeUnitBounds[localTimeUnit as TimeUnit];
    let newUnit: TimeUnit | null = null;
  
    if (localTimeValue >= bounds.max) {
      if (localTimeUnit !== TimeUnit.Years) {
        newUnit = nextTimeUnit[localTimeUnit as TimeUnit];
        setLocalTimeUnit(newUnit);
        setLocalTimeValue(timeUnitBounds[newUnit].min + 1);
      } else {
        setLocalTimeValue(bounds.max);
      }
    } else if (localTimeValue <= bounds.min) {
      if (localTimeUnit !== TimeUnit.Days) {
        newUnit = prevTimeUnit[localTimeUnit as TimeUnit];
        setLocalTimeUnit(newUnit);
        setLocalTimeValue(timeUnitBounds[newUnit].max - 1);
      } else {
        setLocalTimeValue(bounds.min);
      }
    }
  
    // If the unit has changed and it's an automatic change, set autoUnitChange and notify the parent component
    if (newUnit !== null && localTimeUnit !== newUnit) {
      setAutoUnitChange(true);
      onUnitChange(name + "_type", newUnit);
    }
  }, [localTimeValue, localTimeUnit]);
  
    // Event handlers for hover and focus
  const handleSliderHoverFocus = () => {
    setIsSliderActive(true);
  };

  const handleSliderBlur = () => {
    setIsSliderActive(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(e.target.value, 10);
    if (isNaN(newValue)) newValue = timeUnitBounds[localTimeUnit as TimeUnit].min;

    setLocalTimeValue(newValue);
    onValueChange(name + "_input", newValue);
  };

  const handleLocalSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newUnit = e.target.value as TimeUnit;
    setLocalTimeUnit(newUnit);
    onUnitChange(name + "_type", newUnit);
  };
  // Function to calculate the position of the value indicator
  const calculateIndicatorPosition = () => {
    const bounds = timeUnitBounds[localTimeUnit as TimeUnit];
    const range = bounds.max - bounds.min;
    return ((localTimeValue - bounds.min) / range) * 100;
  };

  const unitSelectorClassName = autoUnitChange ? styles.highlight : '';

  const bounds = timeUnitBounds[localTimeUnit as TimeUnit];
  return (
    <div className={styles["time-input-container"]}>
      <div className={`${styles["slider-container"]} ${isSliderActive ? styles["active-slider"] : ''}`}>
        <label className={styles["label-container"]}>{label}:</label>
        <input
          type="range"
          name={name + "_input"}
          value={localTimeValue}
          onChange={handleInputChange}
          onMouseEnter={handleSliderHoverFocus}
          onMouseLeave={handleSliderBlur}
          onFocus={handleSliderHoverFocus}
          onBlur={handleSliderBlur}
          min={bounds.min}
          max={bounds.max}
          step={1}
          disabled={isCooldownActive}
          className={styles.rangeInput} // If you have specific styles for the range input
        />
        <div 
          className={styles["slider-value-indicator"]}
          style={{ left: `${calculateIndicatorPosition()}%` }}
        >
          {localTimeUnit === TimeUnit.Days && localTimeValue === bounds.min ? (
            "1 Day"
          ) : (
            `${localTimeValue} ${localTimeUnit}`
          )}        
        </div>
      </div>
      <div className={`${styles["unit-selector-container"]} ${unitSelectorClassName}`}>
        <select
          name={name + "_type"}
          value={localTimeUnit}
          onChange={handleLocalSelectChange}
        >
          <option value="Days">{TimeUnit.Days}</option>
          <option value="Weeks">{TimeUnit.Weeks}</option>
          <option value="Months">{TimeUnit.Months}</option>
          <option value="Years">{TimeUnit.Years}</option>
        </select>
      </div>
    </div>
  );
};

export default TimeInputComponent;