import React from "react";
import { Link } from "react-router-dom";

import styles from "./ProfileSkillsList.module.scss";
import { CloseIcon } from "Icons/CloseIcon";
import { Skill } from "Pages/Home/HomePageTypes";
import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import { useAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";
import { apiRoutes } from "services/routes";

/**
 * A list of skills to be displayed on the profile
 * page of a user or a place
 */
export function ProfileSkillsList({
    skillBeingAdded,
    elements,
    finishAddingSkill,
    setSkillBeingAdded,
    deleteSkill,
}: {
    elements: Array<Skill>;
    /**
     * When the user has pressed a button to add a new skill,
     * the name of the new skill will be this string. Otherwise,
     * it will just be `null`
     * */
    skillBeingAdded: string | null;
    finishAddingSkill: (skillName: string | null) => void;
    setSkillBeingAdded: (skill: string | null) => void;
    /** When present, the skill deleting button will be displayed */
    deleteSkill?: (element: string) => void;
}) {
    let autocompleteSuggestions = useAutocompleteSuggestions(
        apiRoutes.GET_AUTOCOMPLETE("skills"),
        skillBeingAdded || ""
    );

    return elements.length > 0 || skillBeingAdded !== null ? (
        <>
            <div className={styles.elements}>
                <h4>Skills</h4>
                <ul>
                    {elements.map((element, index) => (
                        <span key={index}>
                            <Link
                                to={`/?search=${encodeURIComponent(
                                    element.name
                                )}&searchFor=Skills`}
                            >
                                <li>{element.name}</li>
                            </Link>
                            {deleteSkill && element.canEdit && (
                                <button
                                    className={styles["delete-button"]}
                                    onClick={() => deleteSkill(element.name)}
                                >
                                    <CloseIcon color="#999" />
                                </button>
                            )}
                        </span>
                    ))}
                    {skillBeingAdded !== null && (
                        <>
                            <AutocompleteInput
                                autocompleteSuggestions={autocompleteSuggestions.filter(
                                    (element) =>
                                        !elements
                                            .map((skill) => skill.name)
                                            .includes(element)
                                )}
                                placeholder="Add new skill"
                                value={skillBeingAdded}
                                padding="10px 20px"
                                borderRadius="23px"
                                setValue={setSkillBeingAdded}
                                submitValue={finishAddingSkill}
                                submitOnBlur={true}
                                autoFocus
                                margin="5px"
                                closeInput={() => {
                                    setSkillBeingAdded(null);
                                    finishAddingSkill(null);
                                }}
                            />
                        </>
                    )}
                </ul>
            </div>
            <hr style={{ width: "100%" }} />
        </>
    ) : null;
}
