// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileSettings_profile-photo__N4OSU {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 40px 0;
}

.ProfileSettings_profile-details__TPbHv .ProfileSettings_add-buttons__ggQGZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/ProfileSettings/ProfileSettings.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,sBAAA;AACJ;;AAGI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAR","sourcesContent":[".profile-photo {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    padding: 20px 0 40px 0;\n}\n\n.profile-details {\n    .add-buttons {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: center;\n        font-size: 0.9em;\n\n        // button {\n        // }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-photo": `ProfileSettings_profile-photo__N4OSU`,
	"profile-details": `ProfileSettings_profile-details__TPbHv`,
	"add-buttons": `ProfileSettings_add-buttons__ggQGZ`
};
export default ___CSS_LOADER_EXPORT___;
