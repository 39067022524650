import React, { useEffect } from 'react';
import { RefObject } from 'react';
import { Map } from 'leaflet';

interface MapZoomHandlerProps {
    zoomToLatitude: number | null;
    zoomToLongitude: number | null;
    zoomLevel: number | null;
    mapRef: RefObject<Map>;
}

/**
This function is used to zoom into a specific location on the map.
Fly to a new location on the map whenever the x, y and z parameters change
@param {number} zoomToLatitude - The latitude of the location to zoom in on
@param {number} zoomToLongitude - The longitude of the location to zoom in on
@param {number} zoomLevel - The zoom level to zoom in to (optional, default is 12)
@param {RefObject<Map>} mapRef - The ref to the map
*/
const MapZoomHandler: React.FC<MapZoomHandlerProps> = ({
    zoomToLatitude,
    zoomToLongitude,
    zoomLevel,
    mapRef
}) => {
    useEffect(() => {
        if (zoomToLatitude !== null && zoomToLongitude !== null) {
            mapRef.current?.flyTo(
                [zoomToLatitude, zoomToLongitude],
                zoomLevel !== null ? zoomLevel : 12
            );
        }
    }, [zoomToLatitude, zoomToLongitude, zoomLevel, mapRef]);

    // Since this component doesn't render anything, it returns null
    return null;
};

export default MapZoomHandler;
