import { ACTIONS } from '../Pages/Home/reducerActions';

export const addNewContactSearch = async (dispatch: React.Dispatch<any>) => {
  // Prompt the user for an address to search for
  const address = prompt("Enter an address to search for:");

  try {
      // Make a request to the Nominatim API with the user's search query
      const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
      );

      if (!response.ok) {
          throw new Error("Network response was not ok");
      }

      // Extract the latitude and longitude coordinates of the first result
      const data = await response.json();
      const { lat, lon } = data[0];

      const coordinates = [lat, lon];
      const point = { x: coordinates[1], y: coordinates[0] };

      // Dispatch a CREATE_NEW_CONTACT action with the extracted coordinates
      dispatch({
          type: ACTIONS.CREATE_NEW_CONTACT,
          coordinates: point,
      });
  } catch (error) {
      console.error(error);
  }
};