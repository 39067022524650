// ./hooks/useFloatingControlActions.ts
import React from 'react';
import { GpsLocation } from "../Pages/Home/FloatingControls/ShowMyLocation/ShowMyLocation";
import { ACTIONS } from '../Pages/Home/reducerActions';

export const showMyLocation = (
  dispatch: React.Dispatch<any>, 
  coordinates: GpsLocation | null,
  firstTime: boolean
): void => {
  if (firstTime) {
      dispatch({
          type: ACTIONS.alert,
          value: "It can take some seconds until it going to find your aproximate location",
      });    
  }
  if (firstTime && coordinates !== null) {
      dispatch({
          type: ACTIONS.mapPosition,
          value: { ...coordinates, zoomLevel: 16 },
      });
  }
  dispatch({
      type: ACTIONS.gpsPosition,
      value: coordinates,
  });
};