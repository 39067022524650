export const apiRoutes = {
    //AUTH - POST
    CREATE_ACCOUNT: 'auth/account/',
    LOGOUT: 'auth/logout/',
    LOGIN: 'auth/login/',
    REFRESH_TOKEN: 'auth/token/refresh/',
    CONFIRM_EMAIL_VERIFICATION: 'auth/account/account-confirm-email/',
    VERIFY_ACCESS_TOKEN: 'auth/token/verify/',
    REFRESH_ACCESS_TOKEN: 'auth/token/refresh/',
    INITIALIZE_PASSWORD_RESET: 'auth/password-reset/',
    CONFIRM_PASSWORD_RESET: 'auth/password-reset/confirm/',
    COMPLETE_ACCOUNT_ONBOARDING: 'accounts/onboarding/',
    GOOGLE_LOGIN: 'oauth2.0/google/',
    SIGNUP: '/accounts/signup/',
    SIGNIN: '/accounts/login/',
    PASSWORD_RESET: '',
    ONBOARDING: '/accounts/onboarding',
  
    // AUTH - GET
    GET_CURRENT_AUTHENTICATED_USER: 'auth/user/',
  
    //JOURNALS
    CREATE_OR_GET_JOURNALS: 'journals/',
    UPDATE_DELETE_JOURNAL: (journal_id: string) => `journals/${journal_id}/`,
    GET_JOURNAL_MENTIONS: (value: string, type: string) =>
      `/journals/mentions/?query=${value}&type=${type}`,
    SEARCH_JOURNAL: 'journals/search_journal/',
  
    //COMMON
    GET_COMMON_INTERESTS: 'common-interests/',
    GET_PUBLIC_ACTIVITIES: 'public-activities/',
    GET_SEARCH: (category: 'places' | 'users' | 'contacts') =>
      `search/${category}/`,
    CONTACT_US: 'contact-us/',
    GET_READ_ONLY_USERS: (queryParams: string) =>
      `read-only-users/?${queryParams}`,
    GET_AUTOCOMPLETE: (
      category: 'skills' | 'objects' | 'cities' | 'personal-objects'
    ) => `autocomplete/${category}/`,
  
    // USER PROFILE, CONNECTIONS, MESSAGES ETC
    GET_USER_LIST: 'users/',
    GET_USER: (user_handle: string) => `users/${user_handle}`,
    GET_OR_UPDATE_USER: 'users/me/',
    GET_CURRENT_USER_DETAILS: 'users/me/basic_details/',
    GET_OR_UPDATE_APPEARANCE: 'users/appearance/',
    UPDATE_PRIVACY: 'users/privacy/',
    DELETE_ACCOUNT: 'users/delete-account/',
    CREATE_SKILLS: 'users/skills/',
    DELETE_SKILL: (skill_name: string) => `users/skills/${skill_name}/`,
    CREATE_OBJECT: 'users/objects/',
    DELETE_OBJECTS: (object_name: string) => `users/objects/${object_name}/`,
    GET_CONNECTIONS: 'users/connections/',
    GET_NOTIFICATION_CONFIRMATIONS: 'users/confirmation-notifications/',
    GET_NOTIFICATIONS_COUNT: 'users/notifications-count/',
    GET_OR_CREATE_NOTIFICATIONS: 'users/notifications/',
    GET_CONVERSATIONS: 'users/conversations/',
    GET_UNMESSAGED_CONNECTIONS: 'users/unmessaged-connections/',
    GET_MESSAGES_COUNT: 'users/messages-count/',
  
    GET_PERMISSIONS: 'users/permissions/',
    GET_USER_TRAVEL_PLANS: (user_handle: string) =>
      `users/${user_handle}/travel-plans/`,
    GET_MESSAGES: (user_slug: string) => `users/${user_slug}/messages/`,
    SEND_MESSAGES: (userHandle: string) => `messages/send/${userHandle}/`,
    DELETE_MESSAGES: (pk: number) => `messages/delete/${pk}/`,
    CREATE_OR_DELETE_CONNECTION_REQUESTS: (user: string) =>
      `connection-requests/${user}/`,
    ACCEPT_REJECT_END_CONNECTION_REQUESTS: (user: string) =>
      `connection-requests/${user}/status/`,
    CREATE_OR_CANCEL_LOCATION_SHARING_REQUEST: (user: string) =>
      `location-requests/${user}/`,
    STOP_SHARING_LOCATION: (user: string) =>
      `location-requests/${user}/stop-sharing/`,
    ACCEPT_REJECT_END_LOCATION_REQUESTS: (user: string) =>
      `location-requests/${user}/status/`,
    CREATE_CONNECTIONS_KEEP_IN_TOUCH: (
      user_or_contact_pk: number,
      connection_type: 'user' | 'contact'
    ) =>
      `connections/${user_or_contact_pk}/kit/?connection_type=${connection_type}`,
    GET_CONNECTIONS_KEEP_IN_TOUCH: `connections/`,
    GET_CONNECTIONS_KEEP_IN_TOUCH_DROPDOWN: `connections/dropdown/`,
  
    //TRAVEL PLANS
    CREATE_TRAVEL_PLANS: `travel-plans/`,
    GET_TRAVEL_PLAN_DETAILS: (travel_plan_pk: number) =>
      `travel-plans/${travel_plan_pk}/`,
    UPDATE_OR_DELETE_TRAVEL_PLAN: (travel_plan_pk: number | null) =>
      `travel-plans/${travel_plan_pk}/`,
    ADD_TRAVEL_PLAN_OBJECT: (travel_plan_pk: number) =>
      `travel-plans/add-object/${travel_plan_pk}/`,
    GET_ALL_ACCESSIBLE_TRAVEL_PLANS: 
      `travel-plans/all/`,

    UPDATE_OR_DELETE_TRAVEL_PLAN_OBJECT: (
      travel_plan_pk: number,
      object_pk: number
    ) => `travel-plans/${travel_plan_pk}/${object_pk}/`,
  
    //SKILLS
    ADD_SKILL: (pk: number) => `skills/additions/${pk}/`,
    ADD_SKILL_TO_OTHER_USER: (user_pk: number) =>
      `skills/add-to-other-user/${user_pk}/`,
  
    ADD_SKILL_TO_CONTACT: (contact_pk: number) => `skills/contact/${contact_pk}/`,
    DELETE_CONTACT_SKILLS: (contact_pk: number, skill_name: string) =>
      `skills/contact/${contact_pk}/${skill_name}/`,
  
    //PLACES
    CREATE_OR_GET_PLACES: `places/`,
    GET_OR_UPDATE_OR_DELETE_PLACE: (place_handle: string) =>
      `places/${place_handle}/`,
    UPLOAD_PLACE_PICTURE: (place_slug: string) =>
      `places/pictures/${place_slug}/`,
    DELETE_PLACE_PICTURE: (place_slug: string, picture_pk: number) =>
      `places/pictures/${place_slug}/${picture_pk}/`,
    CREATE_OR_DELETE_PLACE_SHARING_REQUEST: (pk: number) =>
      `places/sharing-requests/${pk}/`,
    CREATE_OR_DELETE_PLACE_SHARING_REQUEST_ACCEPTANCE: (pk: number) =>
      `places/sharing-request-acceptance/${pk}/`,
    CREATE_OR_DELETE_PLACE_PERMISSION: (place_id: number, user_id: number) =>
      `places/permissions/${place_id}/${user_id}/`,
  
    //OBJECTS
    CREATE_CONTACT_OBJECT: (contact_pk: number) =>
      `objects/contact/${contact_pk}/`,
    DELETE_CONTACT_OBJECT: (contact_pk: number, object_name: any) =>
      `objects/contact/${contact_pk}/${object_name}/`,
    CREATE_PLACE_OBJECT: (place_pk: number) => `objects/place/${place_pk}/`,
    DELETE_PLACE_OBJECT: (place_pk: any, object_name: string) =>
      `objects/place/${place_pk}/${object_name}/`,
    ADD_OBJECT_TO_OTHER_USER: (user_pk: number) =>
      `objects/add-to-other-user/${user_pk}/`,
    CREATE_CONTACT_IMAGE_OBJECT: `objects/contact-image/`,
    DELETE_CONTACT_IMAGE_OBJECT: (image_id: any) =>
      `objects/contact-image/${image_id}/`,
    CREATE_PLACE_IMAGE_OBJECT: `objects/place-image/`,
    DELETE_PLACE_IMAGE_OBJECT: (image_id: any) =>
      `objects/place-image/${image_id}/`,
    ADD_OBJECT: (pk: number) => `objects/object-addition/${pk}/`,
    CREATE_USER_IMAGE_OBJECT: `objects/user-image/`,
    DELETE_USER_IMAGE_OBJECT: (image_id: any) =>
      `objects/user-image/${image_id}/`,
  
    //CONTACTS
    CREATE_OR_GET_CONTACTS: `contacts/`,
    GET_CONTACTS_DUPLICATES: `contacts/duplicates/`,
    GET_COORDINATE_CLUSTERS: `contacts/coordinate-clusters/`,
    SHAKE_COORDINATE_CLUSTERS: `contacts/coordinate-clusters/`,
    BATCH_UPLOAD_CONTACTS: `contacts/imports/batch-upload/`,
    UNFINISHED_UPLOADED_CONTACTS: `contacts/imports/unfinished-contacts/`,
    UPDATE_OR_DELETE_OR_GET_CONTACT: (contact_pk: number) =>
      `contacts/${contact_pk}/`,
    UPLOAD_CONTACT_PICTURE: (contact_pk: number) =>
      `contacts/pictures/${contact_pk}/`,
  
    CREATE_OR_DELETE_CONTACT_SHARING_REQUEST: (pk: number) =>
      `contacts/sharing-requests/${pk}/`,
    CREATE_OR_DELETE_CONTACT_SHARING_REQUEST_ACCEPTANCE: (pk: number) =>
      `contacts/sharing-request-acceptance/${pk}/`,
    CREATE_OR_DELETE_CONTACT_PERMISSION: (contact_id: number, user_id: number) =>
      `contacts/permissions/${contact_id}/${user_id}/`,
    ADD_CONTACT_FROM_LINKEDIN: 'contacts/add-from-linkedin/',
  
    //NETWORK
    CREATE_BULK_MEMBERS_NETWORK: `networks/bulk-members/`,
    GET_ALL_NETWORKS: `networks/`,
    UPDATE_OR_DELETE_OR_GET_NETWORK: (network_id: any) =>
      `networks/${network_id}/`,
    ACCEPT_OR_REJECT_NETWORK_MEMBERSHIP: (network_id: number) =>
      `networks/${network_id}/membership/`,
    REMOVE_NETWORK_MEMBERS: `networks/members/`,
    GET_NETWORK_MEMBER_SUGGESTIONS: `networks/members/suggestions/`,
    GET_TOTAL_NETWORKS_MEMBERS_COUNT: `networks/members/count/`,
  
    // INVITATIONS
    SEND_INVITATIONS: 'invitations/send/',
    GET_INVITATION: (invitationToken: string) =>
      `invitations/get/${invitationToken}/`,
    ACCEPT_OR_REJECT_INVITATION: (invitationToken: string) =>
      `invitations/acceptance/${invitationToken}/`,
    GET_INVITABLE_CONTACTS: (searchKeywords: string) =>
      searchKeywords
        ? `invitations/invitable-contacts/?search=${searchKeywords}`
        : 'invitations/invitable-contacts/',
    GET_AVAILABLE_INVITES: 'invitations/available-invites/',
  
    // USER_STATS
    GET_USER_PLACES_COUNT: (user_id: number) =>
      `user_stats/${user_id}/places/count/`,
    GET_USER_CONTACTS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/contacts/count/`,
  
    GET_USER_NETWORKS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/networks/count/`,
    GET_USER_CONNECTIONS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/connections/count/`,
    GET_USER_TRAVEL_PLANS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/travel-plans/count/`,
    GET_USER_OBJECTS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/objects/count/`,
    GET_USER_SKILLS_COUNT: (user_id: number) =>
      `user_stats/${user_id}/skills/count/`,
  
    GET_ALL_USER_STATS: (user_id: number) => `user_stats/${user_id}/all/count`,
  
    // ADDRESSES

    CREATE_NEW_ADDRESS: `addresses/address/create/`,
    EDIT_ADDRESS: (address_id: number) => `addresses/address/edit/${address_id}/`,
    GET_ALL_ADDRESSES: "addresses/list/",
    DELETE_ADDRESS: (address_id: number) => `addresses/address/delete/${address_id}/`,

    GET_USER_ADDRESSES: (user_id: number) => `users/${user_id}/addresses/`,
    GET_PLACE_ADDRESSES: (place_id: number) => `places/${place_id}/addresses/`,
    GET_CONTACT_ADDRESSES: (contact_id: number) => `contacts/${contact_id}/addresses/`,

    // EXPERIENCES

    CREATE_NEW_EXPERIENCE: `experiences/experience/create/`,
    EDIT_EXPERIENCE: (address_id: number) => `experiences/experience/edit/${address_id}/`,
    GET_ALL_EXPERIENCES: "experiences/list/",
    DELETE_EXPERIENCE: (address_id: number) => `experiences/experience/delete/${address_id}/`,

    GET_USER_EXPERIENCES: (user_id: number) => `users/${user_id}/experiences/`,
    GET_PLACE_EXPERIENCES: (place_id: number) => `places/${place_id}/experiences/`,
    GET_CONTACT_EXPERIENCES: (contact_id: number) => `contacts/${contact_id}/experiences/`,

    // NOTIFICATION SUBSCRIPTIONS
    GET_CREATE_NOTIFICATION_SUBSCRIPTIONS: 'notification-subscriptions/',
    GET_UPDATE_DELETE_NOTIFICATION_SUBSCRIPTION: (id: string | number) =>
      `notification-subscriptions/${id}/`,
  
    // MAP_DATA
    GET_MAP_DATA: 'map_data'
  }
  
  export const BASE_API_ENDPOINT = process.env.REACT_APP_BASE_API_ENDPOINT