import React, { useState, useEffect } from 'react';
import SettingsSubpage from '../SettingsSubpage/SettingsSubpage';
import { useDocumentTitle } from 'hooks/useSEO';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';

interface CoordinateCluster {
  coordinates: string;
  count: number;
}

class Semaphore {
  private permits: number;

  constructor(permits: number) {
    this.permits = permits;
  }

  async acquire() {
    while (this.permits <= 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.permits -= 1;
  }

  release() {
    this.permits += 1;
  }
}


function CoordinateClusters() {
  useDocumentTitle("Coordinate Clusters");

  const [coordinateClusters, setCoordinateClusters] = useState<CoordinateCluster[]>([]);
  const apiInstance = useAxiosPrivate();

  const [shakingProgress, setShakingProgress] = useState<number>(0);
  const [isShakingAll, setIsShakingAll] = useState<boolean>(false);

  const shakeAllClusters = async () => {
    setIsShakingAll(true);
    setShakingProgress(0);
  
    const semaphore = new Semaphore(8);
    const promises: Promise<void>[] = [];
  
    for (const cluster of coordinateClusters) {
      promises.push(
        (async () => {
          await semaphore.acquire();
          await shakeSingleCluster(cluster.coordinates).finally(() => {
            setShakingProgress(prevProgress => prevProgress + 1);
            semaphore.release();
          });
        })()
      );
    }
  
    await Promise.all(promises);
  
    setIsShakingAll(false);
  };

  const fetchCoordinateClusters = async () => {
    try {
      const response = await apiInstance.get(apiRoutes.GET_COORDINATE_CLUSTERS);
      const data = await response.data?.data?.clusters;
      if (data) {
        setCoordinateClusters(data);
      }
    } catch (error) {
      console.error("An error occurred while fetching coordinate clusters:", error);
    }
  };

  useEffect(() => {
    fetchCoordinateClusters();
  }, []);

  const shakeSingleCluster = async (coordinates: string) => {
    try {
        await apiInstance.post(`${apiRoutes.SHAKE_COORDINATE_CLUSTERS}shake/${encodeURIComponent(coordinates)}/`);
        fetchCoordinateClusters();
    } catch (error) {
        console.error("An error occurred while shaking the cluster:", error);
    }
};

return (
  <SettingsSubpage title="Coordinate Clusters">
      <div>
          <button onClick={shakeAllClusters} disabled={isShakingAll}>
              Shake All Clusters
          </button>
          {isShakingAll && (
              <p>
                  Shaking progress: {shakingProgress} / {coordinateClusters.length}
              </p>
          )}
          
          {coordinateClusters.length > 0 ? (
              <>
                  <p>There are {coordinateClusters.length} coordinate clusters:</p>
                  <ul>
                      {coordinateClusters.map((cluster, index) => (
                          <li key={index}>
                              Coordinates: {cluster.coordinates} <br />
                              Count: {cluster.count} <br />
                              <button onClick={() => shakeSingleCluster(cluster.coordinates)}> Shake</button>
                          </li>
                      ))}
                  </ul>
              </>
          ) : (
              <p>There are no coordinate clusters.</p>
          )}
      </div>
  </SettingsSubpage>
);
}

export default CoordinateClusters;
