// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_notifications__A\\+uAT {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Notifications_notifications__A\\+uAT .Notifications_notifications-list__6h2r0 {
  height: 100%;
  width: 100%;
  max-width: 700px;
}
@media screen and (min-width: 1600px) {
  .Notifications_notifications__A\\+uAT .Notifications_notifications-list__6h2r0 {
    max-width: 1000px;
  }
}
.Notifications_notifications__A\\+uAT .Notifications_notifications-list__6h2r0 .Notifications_no-notifications__-BElX {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.Notifications_notifications__A\\+uAT .Notifications_notifications-list__6h2r0 .Notifications_no-notifications__-BElX p {
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;EACA,gBAAA;AACR;AACQ;EALJ;IAMQ,iBAAA;EAEV;AACF;AAAQ;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;AAEZ;AAAY;EACI,WAAA;AAEhB","sourcesContent":[".notifications {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .notifications-list {\n        height: 100%;\n        width: 100%;\n        max-width: 700px;\n\n        @media screen and (min-width: 1600px) {\n            max-width: 1000px;\n        }\n\n        .no-notifications {\n            width: 100%;\n            height: 100%;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            text-align: center;\n            padding: 20px;\n\n            p {\n                color: grey;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifications": `Notifications_notifications__A+uAT`,
	"notifications-list": `Notifications_notifications-list__6h2r0`,
	"no-notifications": `Notifications_no-notifications__-BElX`
};
export default ___CSS_LOADER_EXPORT___;
