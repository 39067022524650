import React, { CSSProperties } from "react";

import styles from "./HttpErrorMessage.module.scss";
import { CloseIcon } from "Icons/CloseIcon";
import { ApiError } from "hooks/useAPI";

/**
 * An error message component used for displaying
 * errors due to failed http requests
 */
export default function HttpErrorMessage({
    direction,
    error,
    hideBorderAndBackground,
    color,
    style,
}: {
    error?: ApiError;
    direction?: "column" | "row";
    hideBorderAndBackground?: boolean;
    color?: string;
    style?: CSSProperties;
}) {
    return (
        <div
            className={styles["error-box"]}
            style={{
                flexDirection: direction || "row",
                ...(hideBorderAndBackground
                    ? { border: "none", backgroundColor: "transparent" }
                    : {}),
                color,
                ...style,
            }}
        >
            <div className={styles["x"]}>
                <CloseIcon color="currentColor" lineLength={30} lineWidth={4} />
            </div>
            <p
                style={{
                    textAlign: direction === "column" ? "center" : undefined,
                }}
            >
                An error occured while fetching data{error?.reason ? ": " : ""}
                {error?.reason &&
                    (error.reason.toString() as string).slice(0, 50) +
                        ((error.reason.toString() as string).length < 50
                            ? ""
                            : "...")}
            </p>
        </div>
    );
}
