import { useAuthContext } from "Context/AuthContext";
import Navbar from "./Navbar";

//put the layout of the app in a single file gives more control on what to show and what not to show
// in specific pages as well as the styling
export default function AppLayout({
    children,
    isProtected,
    showNavbar,
}: {
    showNavbar: boolean;
    isProtected: boolean;
    children: JSX.Element | JSX.Element[];
}) {
    const { isAuthenticated } = useAuthContext();

    const displayNavbar = isAuthenticated && showNavbar && isProtected;

    return (
        <div className={"layout " + (displayNavbar ? "displaying-navbar" : "")}>
            <div className="app" style={{ overflowY: "scroll" }}>
                {children}
            </div>
            {displayNavbar && <Navbar />}
        </div>
    );
}
