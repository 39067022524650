// AddExperience.tsx

import React, { useState, useRef, KeyboardEvent, ChangeEvent } from 'react';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';
import styles from './AddExperience.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface AddExperienceProps {
  entityType: 'user' | 'place' | 'contact';
  entityId: number;
  refreshObjects: () => void;
}

const AddExperience: React.FC<AddExperienceProps> = ({ entityType, entityId, refreshObjects }) => {
  const [newExperience, setNewExperience] = useState<string>('');
  const privateAPIInstance = useAxiosPrivate();
  const [showInput, setShowInput] = useState<boolean>(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);


  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowInput(true);
    }, 2000); // 2 seconds hover
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowInput(true);
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShowInput(false);
    }
  };

  const handleAddNewExperience = async () => {
    try {
      const response = await privateAPIInstance.post(apiRoutes.CREATE_NEW_EXPERIENCE, {
        content_type: entityType,
        object_id: entityId,
        company_name: newExperience,
      });

      if (response && response.data) {
        refreshObjects();
        setNewExperience('');
      }
    } catch (error) {
      console.error('Error creating new experience:', error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewExperience(e.target.value);
  };

  const handleInputBlur = async () => {
    if (newExperience.trim() !== '') {
      await handleAddNewExperience();
    }
  };

  const handleInputKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newExperience.trim() !== '') {
      await handleAddNewExperience();
    }
  };

  return (
    <div 
      ref={containerRef}
      className={styles.addObjectContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleEscapeKey}
    >
      {showInput ? (
        <input
          type="text"
          placeholder="Add New Experience"
          value={newExperience}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleInputKeyPress}
        />
      ) : (
        <div className={styles.addIcon} onClick={handleClick}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
    </div>
  );
};



export default AddExperience;
