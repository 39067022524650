import React from "react";

import styles from "../Conversation.module.scss";

import { ApiError } from "hooks/useAPI";

type MessageData = {
    id: number;
    from_user_id: number;
    text: string;
    time: string;
};

export default function MessageInput({
  setMessageBeingTyped,
  messages,
  messageBeingTyped,
  sendMessage,
}: {
  setMessageBeingTyped: React.Dispatch<React.SetStateAction<string>>;
  messages: MessageData[] | ApiError | null;
  messageBeingTyped: string;
  sendMessage: () => void;
}) {
  return (
      <div className={styles["message-input"]}>
          <input
              id="message-input"
              type="text"
              placeholder="Enter a message"
              onInput={(event) =>
                  setMessageBeingTyped(event.currentTarget.value)
              }
              disabled={messages === null}
              value={messageBeingTyped}
              onKeyDown={(event) => {
                  if (event.key === "Enter") {
                      sendMessage();
                  }
              }}
          />
          <label htmlFor="message-input">
              <button
                  disabled={messages === null || messageBeingTyped === ""}
                  onClick={sendMessage}
              >
                  <SendButtonIcon />
              </button>
          </label>
      </div>
  );
}

function SendButtonIcon() {
  return (
      <svg
          width="65.242mm"
          height="67.821mm"
          version="1.1"
          viewBox="0 0 65.242 67.821"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path d="m0 0 8.1252 25.594a7.16 7.16 38.757 0 0 6.1845 4.9648l37.352 3.3513-37.352 3.3513a7.1599 7.1599 141.24 0 0-6.1845 4.9648l-8.1252 25.594 63.928-31.79a2.3683 2.3683 90 0 0-7e-6 -4.2411z" />
      </svg>
  );
}
