// OLD CODE WITHOUT useMemo
// import { useEffect, Dispatch } from "react";

// // Define a specific action type for ContextMenuCloser
// interface CloseContextMenuAction {
//     type: "contextMenu";
//     value: null;
// }

// interface ContextMenuCloserProps {
//     dispatch: Dispatch<CloseContextMenuAction>;
// }

// const ContextMenuCloser: React.FC<ContextMenuCloserProps> = ({ dispatch }) => {
//     useEffect(() => {
//         const handler = () => dispatch({ type: "contextMenu", value: null });
//         document.addEventListener("click", handler);
//         return () => document.removeEventListener("click", handler);
//     }, [dispatch]);

//     return null;
// };

// export default ContextMenuCloser;
//
// BELOW CODE WITH useMemo
import { useEffect, useMemo, Dispatch } from "react";
import { ACTIONS } from '../reducerActions';

// Define a specific action type for ContextMenuCloser
interface CloseContextMenuAction {
    type: "contextMenu";
    value: null;
}

interface ContextMenuCloserProps {
    dispatch: Dispatch<CloseContextMenuAction>;
}

const ContextMenuCloser: React.FC<ContextMenuCloserProps> = ({ dispatch }) => {
    // Memoize the handler function
    const handler = useMemo(() => {
        return () => dispatch({ type: ACTIONS.contextMenu, value: null });
    }, [dispatch]);

    useEffect(() => {
        console.log("ContextMenuCloser with memo should appear just at the start",handler);
        document.addEventListener("click", handler);
        return () => document.removeEventListener("click", handler);
    }, [handler]); // Dependency array now includes the memoized handler

    return null;
};

export default ContextMenuCloser;
