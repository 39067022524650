import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import { MinimalNetworkData } from '../../Pages/Home/HomePageTypes';
import styles from './NetworkMarkerPopup.module.scss'; // Adjust the path as needed

type Position = [number, number]; // [latitude, longitude]

// Create the default icon with custom size
const defaultIconSize: L.PointExpression = [16, 16];
const defaultIcon = new L.Icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Small-dark-grey-circle.svg/16px-Small-dark-grey-circle.svg.png',
  iconSize: defaultIconSize,
});

// Create the hovered icon with a bigger size (adjust as needed)
const hoveredIconSize: L.PointExpression = [48, 48];
const hoveredIcon = new L.Icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Small-dark-grey-circle.svg/24px-Small-dark-grey-circle.svg.png',
  iconSize: hoveredIconSize,
});

// Utility function to create a Leaflet icon from a custom URL with an optional class name
const createIcon = ({
  iconUrl,
  iconSize,
  className = '',
  isHovered,
}: {
  iconUrl: string;
  iconSize: L.PointExpression;
  className?: string;
  isHovered: boolean;
}): Icon => {
  const size = iconSize as [number, number];
  return new L.Icon({
    iconUrl,
    iconSize: size,
    iconAnchor: [size[0] / 2, size[1] / 2],
    popupAnchor: [0, -size[1] / 2],
    className: className,
  });
};



type NetworkMarkerPopupProps = {
  network: MinimalNetworkData;
  position: Position;
};

const NetworkMarkerPopup: React.FC<NetworkMarkerPopupProps> = ({ network, position }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClickable, setIsClickable] = useState(false);
  const icon = network.network_logo
  ? createIcon({
      iconUrl: network.network_logo,
      iconSize: isHovered ? hoveredIconSize : defaultIconSize,
      className: 'marker-network-icon-mapview',
      isHovered,
    })
  : createIcon({
      iconUrl: defaultIcon.options.iconUrl, // Use default icon's URL
      iconSize: isHovered ? hoveredIconSize : defaultIconSize,
      className: 'marker-network-icon-mapview',
      isHovered,
    });


  const markerKey: string = String(network.id);

  const networkDetailsLink = `/network/${network.id}`;

  const handleMouseOver = () => {
    setIsHovered(true);
    // Increase icon size and allow clicks after 0.4 seconds
    setTimeout(() => {
      setIsClickable(true);
    }, 400);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
    setIsClickable(false);
  };

  const popup = isClickable && (
    <Popup>
      <div className={styles['network-marker-popup-container']}>
        {network.network_logo && (
          <img src={network.network_logo} alt={network.name} />
        )}
        <a href={networkDetailsLink} className={styles['network-marker-name']}>
          {network.name}
        </a>
        {network.description.length > 0 && (
          <>
            <div className={styles['network-description-label']}>Description:</div>
            <div className={styles['network-marker-description']}>
              {network.description}
            </div>
          </>
        )}
      </div>
    </Popup>
  );

  return (
    <Marker
      key={markerKey}
      position={position}
      icon={icon}
      riseOnHover
      eventHandlers={{
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
      }}
    >
      {popup}
    </Marker>
  );
};

export default NetworkMarkerPopup;
