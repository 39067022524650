import React, { CSSProperties, ReactNode } from "react";
import styles from "./CustomDialog.module.scss"; // Update the path accordingly

interface CustomDialogProps {
    open?: boolean;
    onClose?: () => void;
    "aria-labelledby"?: string;
    "aria-describedby"?: string;
    children: React.ReactNode;
    style?: CSSProperties | null;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
    open,
    onClose,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    children,
}) => {
    return (
        <div
            className={`${styles["custom-dialog"]} ${
                open ? styles["open"] : ""
            }`}
            role="dialog"
            aria-labelledby={ariaLabelledBy}
            aria-describedby={ariaDescribedBy}
        >
            <div className={styles["dialog-content"]}>{children}</div>
            <button onClick={onClose} className={styles["custom-dialog-close"]}>
                Close
            </button>
        </div>
    );
};

export default CustomDialog;
