import React from 'react';
import styles from './ConversationPreview.module.scss';
import { getElapsedTimeFromIsoString } from '../../../Utils/utils';

import { ConversationData } from '../Messages'; // Assuming this is where the type is defined

type ConversationDetailsProps = {
  conversation: ConversationData;
};

const ConversationDetails: React.FC<ConversationDetailsProps> = ({ conversation }) => (
    <div className={styles['conversation-details']}>
        <div className={styles['upper-details']}>
            <div className={styles['user-name']}>{conversation.user.name}</div>
            <div className={styles['time']}>
                {getElapsedTimeFromIsoString(conversation.lastMessage.time)}
            </div>
        </div>
        <div className={styles['lower-details']}>
            <div className={`${styles['message-text']} ${conversation.lastMessage.seen ? '' : styles['unseen']}`}>
                {conversation.lastMessage.text}
            </div>
            {/* Include unseen messages count logic here if needed */}
            <div className={styles['unseen-messages-count']}></div>
        </div>
    </div>
);

export default ConversationDetails;
