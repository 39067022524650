// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FloatingControls_floating-controls__Adu3W {
  position: absolute;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.FloatingControls_floating-controls__Adu3W > div::-webkit-scrollbar {
  display: none;
  width: 0;
}
.FloatingControls_floating-controls__Adu3W > * {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FloatingControls/FloatingControls.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,qBAAA;AACD;AACC;EACC,aAAA;EACA,QAAA;AACF;AAMC;EACC,gBAAA;AAJF","sourcesContent":[".floating-controls {\n\tposition: absolute;\n\tbottom: 30px;\n\tright: 20px;\n\tz-index: 1000;\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n\talign-items: flex-end;\n\n\t>div::-webkit-scrollbar {\n\t\tdisplay: none;\n\t\twidth: 0;\n\t}\n\n\t// @media screen and (min-width: 600px) {\n\t// \tbottom: 30px;\n\t// }\n\n\t>* {\n\t\tmargin-top: 15px;\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floating-controls": `FloatingControls_floating-controls__Adu3W`
};
export default ___CSS_LOADER_EXPORT___;
