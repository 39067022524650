import { useEffect, useState } from "react";
import { useAxiosPrivate } from "./useAxiosPrivate";
import { apiInstance } from "services/apiInstance";
import axios from "axios";

/**
 * This hook is used to fetch autocomplete suggestions from
 * the server which match with the provided text input from
 * the user. It is implemented like `useApi`
 *
 * ---
 * @param apiEndpointUrl The url of the API endpoint which is used for fetching autocomplete suggestions
 * @param textValue The current value of the input text. Is used to fetch the suggestions from the server
 * @returns A list of strings matching the current input text
 */
export function useAutocompleteSuggestions(
    apiEndpointUrl: string,
    textValue: string
) {
    let [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
        string[]
    >([]);

    useEffect(() => {
        if (textValue.length >= 2) {
            apiInstance
                .get(
                    `${apiEndpointUrl}?keywords=${encodeURIComponent(
                        textValue
                    )}`
                )
                .then((response) => response.data?.data)
                .then((data) => setAutocompleteSuggestions(data));
        } else {
            setAutocompleteSuggestions([]);
        }
    }, [textValue, apiEndpointUrl]);

    return autocompleteSuggestions;
}

/**
 * This hook is used to fetch autocomplete suggestions from OSMNominatim
 *
 * ---
 * @param apiEndpointUrl The url of the API endpoint which is used for fetching autocomplete suggestions
 * @param textValue The current value of the input text. Is used to fetch the suggestions from the server
 * @returns A list of strings matching the current input text
 */
export function useOSMAutocompleteSuggestions(
    apiEndpointUrl: string,
    textValue: string
) {
    let [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
        string[]
    >([]);

    useEffect(() => {
        if (textValue.length >= 2) {
            try {
                axios
                    .get(`${apiEndpointUrl}`)
                    .then((response) => response.data)
                    .then((data) => setAutocompleteSuggestions(data));
            } catch (err) {
                setAutocompleteSuggestions([]);
            }
        } else {
            setAutocompleteSuggestions([]);
        }
    }, [textValue, apiEndpointUrl]);

    return autocompleteSuggestions;
}

export function useNEWAPIAutocompleteSuggestions(
    apiEndpointUrl: string,
    textValue: string
) {
    let [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
        string[]
    >([]);
    const apiInstance = useAxiosPrivate();
    useEffect(() => {
        if (textValue.length >= 2) {
            apiInstance
                .get(
                    `${apiEndpointUrl}?keywords=${encodeURIComponent(
                        textValue
                    )}`
                )
                .then((response) => response.data?.data)
                .then((data) => setAutocompleteSuggestions(data));
        } else {
            setAutocompleteSuggestions([]);
        }
    }, [textValue, apiEndpointUrl]);

    return autocompleteSuggestions;
}
