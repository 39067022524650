import { isNotVisible } from '../../Pages/Home/visibility';
import L from 'leaflet';
import { LocationData } from "../../Pages/Home/HomePageTypes";
import styles from './LocationMarkerIcon.module.scss';

export const LocationMarkerIcon = (
  location: LocationData,
) => {
  return L.divIcon({
    html: `
  <div
    class="icon"
    style="
    background-image: url(${isNotVisible(location.visibility)
          ? '/images/unknown-contact.svg'
          : location.mainPicture || '/images/default-user-image.svg'
        });
    "
  >
  </div>
    <p class="${styles['info-message']}">Click and move the location to a new position</p>
  `,
    iconUrl:
      "data:image/svg+xml,%3Csvg width='40px' height='40px' version='1.1' viewBox='0 0 88.319 119.34' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23525154' stroke-width='3'%3E%3Cpath d='m44.159 1.5c-23.56 9.7e-5 -42.659 14.906-42.659 42.659 0.0099 8.1668 3.2912 15.827 6.782 23.028 8.8832 18.323 35.317 49.844 35.317 49.844s27.323-31.392 36.438-49.844c3.5441-7.1742 6.7722-14.861 6.782-23.028 0-27.754-19.099-42.659-42.659-42.659z' fill='%237d7d7d' /%3E%3Ccircle cx='44.159' cy='44.159' r='15.606' fill='%23fff' /%3E%3C/g%3E%3C/svg%3E",
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/images/marker-shadow.png',
    shadowSize: [41, 41], // size of the shadow
    shadowAnchor: [13, 41], // point of the shadow which will correspond to marker's location
    // ... other properties ...
  })
};