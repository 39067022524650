// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RangeDatePicker_customDatePicker__WvUhv {
  padding: 0.5rem;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #495057;
  width: 100%;
}
.RangeDatePicker_customDatePicker__WvUhv:focus {
  border-color: #0056b3;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.RangeDatePicker_customDatePicker__WvUhv::placeholder {
  color: #adb5bd;
}`, "",{"version":3,"sources":["webpack://./src/Components/RangeDatePicker/RangeDatePicker.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,WAAA;AADF;AAGE;EACE,qBAAA;EACA,aAAA;EACA,gDAAA;AADJ;AAIE;EACE,cAAA;AAFJ","sourcesContent":["// RangeDatePicker.module.scss\n\n.customDatePicker {\n  padding: 0.5rem;\n  border: 2px solid #007bff; // A bright border color for visibility\n  border-radius: 4px; // Rounded corners for a modern look\n  background-color: #f8f9fa; // A light background to stand out\n  color: #495057; // Text color for contrast\n  width: 100%; // Ensuring it fills the container\n  \n  &:focus {\n    border-color: #0056b3; // Darker border color on focus for better visibility\n    outline: none; // Removing default focus outline\n    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); // Adding a subtle focus shadow\n  }\n\n  &::placeholder { // Styling the placeholder with a lighter color\n    color: #adb5bd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customDatePicker": `RangeDatePicker_customDatePicker__WvUhv`
};
export default ___CSS_LOADER_EXPORT___;
