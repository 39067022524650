// Heading.tsx

import React from "react";
import styles from "../LocationProfile.module.scss";
import { FaEdit as EditIcon, FaSave as SaveIcon } from "react-icons/fa";

function Heading({
    editing,
    locationName,
    updateName,
    onEditButtonClicked,
    canEdit,
}: {
    editing: boolean;
    locationName: string;
    updateName: React.FormEventHandler<HTMLInputElement>;
    onEditButtonClicked: () => void;
    canEdit: boolean;
}) {
    return (
        <h1 className={styles["main-heading"]}>
            {editing ? (
                <input type="text" value={locationName} onInput={updateName} />
            ) : (
                locationName
            )}
            {"  "}
            {canEdit ? (
                <sup onClick={onEditButtonClicked}>
                    {editing ? <SaveIcon /> : <EditIcon />}
                </sup>
            ) : null}
        </h1>
    );
}

export default Heading;
