import React from "react";

import styles from "./Notifications.module.scss";

import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import { Notification } from "./Notification/Notification";
import { ConfirmationButtons } from "./ConfirmationButtons/ConfirmationButtons";
import { OptionsList } from "Components/OptionsList/OptionsList";
import { apiRoutes } from "services/routes";
import { ApiError, useApi } from "hooks/useAPI";
import { useDocumentTitle } from "hooks/useSEO";

/**
 * Represents notifications requiring you to confirm something,
 * such as a connection request, etc
 */
export type ConfirmationRequest = {
    /** URL for accepting */
    acceptUrl: string;
    /** URL for rejecting */
    rejectUrl: string;
    text: string;
    type?: "place" | "contact";
    picture: string | null;
    url: string;
    accepted: boolean | "loading" | "rejected";
    id: string;
    /** Place and contact sharing requests require the user to specify permissions for which attributes can be accessed*/
    options?: { [key: string]: boolean };
    postValue? : { [key: string]: any };
};

/** A notification which just notifies the user about something that has happened */
type InformatoryNotification = {
    id: number;
    picture: string;
    text: string;
    seen: boolean;
    url: string;
};

export default function Notifications() {
    useDocumentTitle("Notifications");

    let [notifications] = useApi<Array<InformatoryNotification> | null>(
        apiRoutes.GET_OR_CREATE_NOTIFICATIONS
    );
    let [confirmationRequests, setConfirmationRequests] =
        useApi<Array<ConfirmationRequest> | null>(
            apiRoutes.GET_NOTIFICATION_CONFIRMATIONS
        );

    const updateRequestStatus = (
        status: boolean | "loading" | "rejected",
        request: ConfirmationRequest
    ): void =>
        setConfirmationRequests(
            (confirmationRequests as ConfirmationRequest[]).map((r) =>
                r.id === request.id ? { ...request, accepted: status } : r
            )
        );

    /** Updates the `options` field/property of a request */
    const updateRequestOption = (
        key: string,
        value: boolean,
        requestId: string
    ) =>
        setConfirmationRequests((requests) =>
            requests instanceof Array
                ? requests.map((request) =>
                      request.id === requestId
                          ? {
                                ...request,
                                options: { ...request.options, [key]: value },
                            }
                          : request
                  )
                : requests
        );

    return (
        <main className={styles["notifications"]}>
            {confirmationRequests === null && notifications === null ? (
                <OverlayingLoadingSpinner />
            ) : (
                <div className={styles["notifications-list"]}>
                    {confirmationRequests instanceof Array &&
                    confirmationRequests.length === 0 &&
                    notifications instanceof Array &&
                    notifications.length === 0 ? (
                        <div className={styles["no-notifications"]}>
                            <p>No notifications found</p>
                        </div>
                    ) : (
                        <>
                            {confirmationRequests &&
                                (confirmationRequests instanceof ApiError ? (
                                    <HttpErrorMessage
                                        error={confirmationRequests}
                                    />
                                ) : (
                                    confirmationRequests.map((request) => (
                                        <Notification
                                            message={request.text}
                                            picture={request.picture}
                                            url={request.url}
                                            key={request.id}
                                        >
                                            <ConfirmationButtons
                                                currentRequest={request}
                                                updateRequestStatus={(status) =>
                                                    updateRequestStatus(
                                                        status,
                                                        request
                                                    )
                                                }
                                            />
                                            {request.options && request.type ? (
                                                <OptionsList
                                                    type={request.type}
                                                    options={request.options}
                                                    disabled={
                                                        !!request.accepted
                                                    }
                                                    onOptionsChanged={(
                                                        newOptions
                                                    ) => {
                                                        Object.entries(
                                                            newOptions
                                                        ).forEach(
                                                            ([key, value]) => {
                                                                if (
                                                                    request.options &&
                                                                    request
                                                                        .options[
                                                                        key
                                                                    ] !== value
                                                                ) {
                                                                    updateRequestOption(
                                                                        key,
                                                                        value,
                                                                        request.id
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }}
                                                />
                                            ) : null}
                                        </Notification>
                                    ))
                                ))}
                            {notifications &&
                                (notifications instanceof ApiError ? (
                                    <HttpErrorMessage error={notifications} />
                                ) : (
                                    notifications.map((notification) => (
                                        <Notification
                                            message={notification.text}
                                            picture={notification.picture}
                                            seen={notification.seen}
                                            url={notification.url}
                                            key={`notif:${notification.id}`}
                                        />
                                    ))
                                ))}
                        </>
                    )}
                </div>
            )}
        </main>
    );
}
