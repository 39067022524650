import { ReactNode } from "react";
import styles from "./DialogBox.module.scss";

export function DialogBox({
    children,
    close,
    className,
}: {
    close: () => void;
    children?: ReactNode | ReactNode[];
    className?: string,
}) {
    return (
        <div
            className={styles["overlay"]}
            id="overlay"
            onClick={(event) => {
                if ((event.target as HTMLElement).id === "overlay") {
                    close();
                }
            }}
        >
            <div className={`${className || ""} ${styles.box}`}>{children}</div>
        </div>
    );
}
