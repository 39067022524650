import React, { useState, useRef } from "react";
import { Popup } from "react-leaflet";

import styles from "./ContactEditMarkerPopup.module.scss";
import {
    ContactProfilePicture,
    MainTextDetails,
} from "./ContactEditUtilityComponents";

import SocialLinks from "./SocialLinks"

import ConfirmButtons from "../../../../Components/ConfirmButtons/ConfirmButtons";
import { ContactData } from "../../HomePageTypes";
import { loadImage } from "../../../../Utils/fileLoading";
import { DispatchAction } from "../../reducer";
import { MarkerPrivacySetting } from "../../MarkerPrivacySetting/MarkerPrivacySetting";
import Accordion from "../../../../Components/Accordion/Accordion";
import { apiRoutes } from "services/routes";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { usePostToAPI } from "hooks/useAPI";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";
import AddressManager from 'Components/AddressManager/AddressManager';
import AddressList from 'Components/AddressManager/AddressList';


export function ContactEditMarkerPopup({
    editedContact,
    setContactBeingEdited,
    state,
    dispatch,
}: {
    editedContact: ContactData;
    setContactBeingEdited: (contact: ContactData | null) => void;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    // When the contact is an existing one, the photo is uploaded directly,
    // but when it is a new one, the photo is stored in this variable
    let [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
    let [uploadingPhoto, setUploadingPhoto] = useState(false);
    // A warning given when the user tried to save with an empty name
    let [emptyNameWarning, setEmptyNameWarning] = useState(false);
    let [socialLinksOpen, setSocialLinksOpen] = useState(false);

    let { postToApi } = usePostToAPI();

    const loadProfilePicture = () => {
        loadImage((file, url) => {
            if (state.contactBeingEdited?.editingOrNew === "new") {
                setSelectedPhoto(file);
                setContactBeingEdited({
                    ...editedContact,
                    profilePicture: url,
                });
            } else if (state.contactBeingEdited?.editingOrNew === "editing") {
                postToApi<string>(
                    apiRoutes.UPLOAD_CONTACT_PICTURE(editedContact.id),
                    { "profile-picture": file }
                )
                    .then((response) => {
                        if (response.ok) {
                            setContactBeingEdited({
                                ...editedContact,
                                profilePicture: response.data,
                            });
                        }
                    })
                    .finally(() => setUploadingPhoto(false));
                setUploadingPhoto(true);
            }
        });
    };

    const finishEditing = (contact: ContactData) => {
        if (state.contactBeingEdited) {
            if (state.contactBeingEdited.editingOrNew === "new") {
                dispatch({
                    type: ACTIONS.ADD_CONTACT,
                    contact,
                });
            } else if (state.contactBeingEdited.editingOrNew === "editing") {
                dispatch({
                    type: ACTIONS.UPDATE_CONTACT,
                    contact,
                });
            }
            setContactBeingEdited(null);
        }
    };

    const apiInstance = useAxiosPrivate();

    const uploadChanges = async () => {
        if (editedContact.name === null || editedContact.name === "") {
            setEmptyNameWarning(true);
        } else {
            if (state.contactBeingEdited?.editingOrNew === "editing") {
                await apiInstance.patch(
                    apiRoutes.UPDATE_OR_DELETE_OR_GET_CONTACT(editedContact.id),
                    {
                        visibility: editedContact.visibility,
                        name: editedContact.name || "",
                        description: editedContact.description || "",
                        coordinates: editedContact.coordinates
                            ? `${editedContact.coordinates.y},${editedContact.coordinates.x}`
                            : null,
                        phone_number: editedContact.phoneNumber,
                        email: editedContact.email,
                        notes: editedContact.notes,
                        facebook: editedContact.facebook,
                        instagram: editedContact.instagram,
                        twitter: editedContact.twitter,
                        linkedin: editedContact.linkedin,
                        youtube: editedContact.youtube,
                        website: editedContact.website,
                    }
                );
                finishEditing(editedContact);
            } else if (state.contactBeingEdited?.editingOrNew === "new") {
                const res = await apiInstance.post(
                    apiRoutes.CREATE_OR_GET_CONTACTS,
                    {
                        name: editedContact.name || "",
                        description: editedContact.description || "",
                        coordinates: editedContact.coordinates
                            ? `${editedContact.coordinates.y},${editedContact.coordinates.x}`
                            : null,
                        visibility: editedContact.visibility,
                        "profile-picture": selectedPhoto as File,
                        phone_number: editedContact.phoneNumber,
                        email: editedContact.email,
                        notes: editedContact.notes,
                        facebook: editedContact.facebook,
                        instagram: editedContact.instagram,
                        twitter: editedContact.twitter,
                        linkedin: editedContact.linkedin,
                        youtube: editedContact.youtube,
                        website: editedContact.website,
                    }
                );
                finishEditing(res.data.data);
                setContactBeingEdited(null);
            }
        }
    };

    
    const updateContactField =
    (field: ContactStringField) =>
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setContactBeingEdited({
        ...editedContact,
        [field]: event.currentTarget.value,
    });
    
    let contactId = state.contactBeingEdited?.data.id || editedContact.id;
    
    return (
        
        <Popup>
            
            <div className={styles["contact-edit-popup"]}>
                <ContactProfilePicture
                    editedContact={editedContact}
                    uploadingPhoto={uploadingPhoto}
                    loadProfilePicture={loadProfilePicture}
                />
                
                <Accordion
                    isOpen={!socialLinksOpen}
                    onChange={(isOpen) => setSocialLinksOpen(!isOpen)}
                    chevronSize={24}
                >
                    <div className={styles["accordion-heading"]}>
                        Main details
                        <AddressManager
					content_type='CONTACT'
					object_id={contactId}
					/>
                    <AddressList 
					entityType='contact'
					entityId={contactId}
					/>
                    </div>
                    <MainTextDetails
                        editedContact={editedContact}
                        updateContactField={updateContactField}
                        emptyNameWarning={emptyNameWarning}
                    />

                </Accordion>

                <Accordion
                    isOpen={socialLinksOpen}
                    onChange={(isOpen) => setSocialLinksOpen(isOpen)}
                    chevronSize={24}
                >
                    <div className={styles["accordion-heading"]}>
                        Social media links
                    </div>
                    <SocialLinks
                        updateContactField={updateContactField}
                        editedContact={editedContact}
                    />
                </Accordion>
                <MarkerPrivacySetting
                    visibility={editedContact.visibility}
                    includePublic={false}
                    updateVisibility={(newValue) =>
                        setContactBeingEdited({
                            ...editedContact,
                            visibility: newValue,
                        })
                    }
                />
                <ConfirmButtons
                    onCancel={() => dispatch({ type: ACTIONS.CANCEL_MARKER_EDITING })}
                    onDoneEditing={uploadChanges}
                />
            </div>
        </Popup>
    );
}

type ContactStringField =
    | "description"
    | "name"
    | "email"
    | "phoneNumber"
    | "notes"
    | "facebook"
    | "instagram"
    | "twitter"
    | "youtube"
    | "linkedin"
    | "website";
