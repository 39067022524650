import React, { useState } from 'react';
import CustomRangeSlider from "Components/CustomRangeSlider/CustomRangeSlider";
import { UserAppearanceConfig } from "types/shared";
import styles from "./RangeInput.module.scss";

interface RangeInputProps {
    field: keyof UserAppearanceConfig;
    minMaxAndStep: [number, number] | [number, number, number];
    editedConfig: UserAppearanceConfig;
    updateConfigField: (field: keyof UserAppearanceConfig, isNumberField: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({ field, minMaxAndStep, editedConfig, updateConfigField }) => {
    const [sliderValue, setSliderValue] = useState(editedConfig[field] as number);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value);
        setSliderValue(newValue);
        updateConfigField(field, true)(e);
    };

    const sliderPercentage = ((sliderValue - minMaxAndStep[0]) / (minMaxAndStep[1] - minMaxAndStep[0])) * 100;

    const valueTextStyles = {
        left: `${sliderPercentage}%`,
    };

    const isMinOrMaxValue = sliderValue === minMaxAndStep[0] || sliderValue === minMaxAndStep[1];

    return (
        <div className={styles["range-input-container"]}>
            <CustomRangeSlider
                defaultValue={editedConfig[field] as number}
                value={sliderValue}
                onInput={handleSliderChange}
                min={minMaxAndStep[0]}
                max={minMaxAndStep[1]}
                step={minMaxAndStep[2]}
            />
            {!isMinOrMaxValue && (
                <div className={`${styles.rangeValue} ${styles.valueTextWithSpacing}`} style={valueTextStyles}>
                    {sliderValue}
                </div>
            )}
        </div>
    );
};

export default RangeInput;
