import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';

export interface JournalTemplate {
    name: string;
    avatar: string;
    id: number;
    type: 'contact' | 'connection' | 'place';
    profile?: any;
}

export const useSearchChangeHandler = (
    setSuggestions: React.Dispatch<React.SetStateAction<JournalTemplate[]>>,
    getMentionedUsers: () => JournalTemplate[]
) => {
    const axiosInstance = useAxiosPrivate();

    return useCallback(
        debounce(async ({ trigger, value }: { trigger: string; value: string }) => {
            setSuggestions([]);

            if (value?.length >= 1) {
                let apiURL: string = '';
                if (trigger === '@') {
                    apiURL = apiRoutes.GET_JOURNAL_MENTIONS(value, 'combined');
                } else if (trigger === '#') {
                    apiURL = apiRoutes.GET_JOURNAL_MENTIONS(value, 'network');
                }

                if (apiURL) {
                    try {
                        const response = await axiosInstance.get(apiURL);
                        const res = response.data;
                        const mentionedUsers = getMentionedUsers();
                        const filteredSuggestions = res?.data.filter(
                            (user: JournalTemplate) => 
                                !mentionedUsers.some(mentionedUser => mentionedUser.id === user.id)
                        );
                        setSuggestions(filteredSuggestions);
                    } catch (error) {
                        setSuggestions([]);
                    }
                }
            }
        }, 300),
        [getMentionedUsers]
    );
};
