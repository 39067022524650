// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationMarker_location-marker-icon__m-8-V {
  --size: 50px;
  --radius: 10px;
  --border-size: 2px;
}
.LocationMarker_location-marker-icon__m-8-V div {
  width: var(--size);
  height: var(--size);
  border-radius: var(--radius);
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.7333333333);
  border: var(--border-size) solid white;
  transition: all 0.25s ease-in;
}
.LocationMarker_location-marker-icon__m-8-V div:hover {
  box-shadow: 0px 0px 50px rgba(255, 255, 0, 0.533);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/LocationMarker/LocationMarker.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,mBAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,iDAAA;EACA,sCAAA;EACA,6BAAA;AACR;AACQ;EACI,iDAAA;AACZ","sourcesContent":[".location-marker-icon {\n    --size: 50px;\n    --radius: 10px;\n    --border-size: 2px;\n\n    div {\n        width: var(--size);\n        height: var(--size);\n        border-radius: var(--radius);\n        background-size: cover;\n        background-position: center;\n        box-shadow: 0px 0 5px #000b;\n        border: var(--border-size) solid white;\n        transition: all 0.25s ease-in;\n\n        &:hover {\n            box-shadow: 0px 0px 50px rgba(255, 255, 0, .533);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location-marker-icon": `LocationMarker_location-marker-icon__m-8-V`
};
export default ___CSS_LOADER_EXPORT___;
