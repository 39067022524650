import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LocationPopupImages } from "Pages/Home/LocationMarker/LocationMarkerPopup/LocationPopupImages";
import { isOwnedBySelf } from "Pages/Home/visibility";
import { truncateStringIfTooLong } from "Utils/utils";
import { LocationData } from "Pages/Home/HomePageTypes";
import AccessibleTravelPlans from "Components/AccessibleTravelPlans/AccessibleTravelPlans"

import styles from "./LocationHeader.module.scss";

interface LocationHeaderProps {
  location: LocationData;
  maxTitleLength: number;
}

const LocationHeader: React.FC<LocationHeaderProps> = ({
  location,
  maxTitleLength,
}) => {
  const [titleIsExpanded, setTitleIsExpanded] = useState(false);
  
  const ownerClassName = isOwnedBySelf(location.visibility)
    ? styles["location-owner-self"]
    : styles["location-owner-other"];

  return (
    <>
      <div className={styles["location-images"]}>
        {location.pictures.map((image, index) => (
          <img
            key={index}
            className={styles["location-image"]}
            src={image.url}
          />
        ))}
        <Link to={`/location/${location.handle}`}>
          <LocationPopupImages images={location.pictures} />
        </Link>
      </div>
      <div className={styles["location-popup-text"]}>
        <h5
          className={styles["heading"]}
          onClick={() => setTitleIsExpanded(!titleIsExpanded)}
          style={{
            cursor:
              location.name.length > maxTitleLength ? "pointer" : undefined,
          }}
        >

        <Link to={`/location/${location.handle}`}>
        {titleIsExpanded
            ? location.name
            : truncateStringIfTooLong(location.name, maxTitleLength, "....")}
        </Link>
            </h5>
        <p className={`${styles["location-owner"]} ${ownerClassName}`}>
          {isOwnedBySelf(location.visibility)
            ? "Your Location"
            : `${location.ownerName}'s contact`}
        </p>
        {/* <AccessibleTravelPlans/> */}
      </div>
    </>
  );
};

export default LocationHeader;
