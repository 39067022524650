import React, { useEffect, useState } from 'react';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';
import styles from './AllUserStatsCounter.module.scss'; // Import the SCSS module
import CountAnimation from 'Components//CountAnimation/CountAnimation'; // Import the CountAnimation component


interface StatsData {
  connection_count: number;
  contact_count: number;
  objects_count: number;
  skills_count: number;
  network_count: number;
  travel_plan_count: number;
}

interface AllUserStatsCounterProps {
  displayDataTypesName?: (keyof StatsData)[] | undefined;
  showText?: boolean;
  userId: number;
}

const statsLabelMap: Record<keyof StatsData | '', string> = {
  connection_count: 'Places',
  contact_count: 'Contacts',
  objects_count: 'Objects',
  skills_count: 'Skills',
  network_count: 'Connections',
  travel_plan_count: 'Travel Plans',
  '': 'Data',
};

interface CountDisplayProps {
  count: number | undefined;
  duration?: number;
}


/**
 * Renders a count value with a transition animation.
 *
 * @param count - The count value to display.
 * @param duration - The duration of the animation in milliseconds. Defaults to 2000ms.
 * @returns The count value wrapped in a `CountAnimation` component, or "N/A" if the count is undefined.
 */
function CountDisplay({ count, duration }: CountDisplayProps) {
  if (count !== undefined) {
    if (count === 0) {
      // Return a zero value if the count is zero
      return <span>0</span>;
    } else {
      // Return a `CountAnimation` component with the specified count and duration
      return <CountAnimation value={count} duration={duration || 2000} />;
    }
  } else {
    // Return "N/A" if the count is undefined
    return <span>N/A</span>;
  }
}



/**
 * Renders a loading message with the specified data types and user ID.
 *
 * @param displayDataTypesName - The data types to display in the loading message. If not specified, only "data" will be displayed.
 * @param userId - The ID of the user for which the data is being loaded.
 * @returns A loading message with the specified data types and user ID.
 */
function LoadingInfo({ displayDataTypesName, userId }: AllUserStatsCounterProps) {
  return (
    <div className={styles['loading-info']}>
      Loading {displayDataTypesName?.join(', ') || 'data'} for user {userId}...
    </div>
  );
}

function ErrorInfo({ error }: { error: string }) {
  return <div className={styles['error-info']}>{error}</div>;
}

/**
 * This component displays a single data point for a specific user, including the data type name and count.
 *
 * @param typeName - The data type name to display.
 * @param count - The count value to display.
 * @param showText - Whether to show the data type name along with the count. If false, only the count will be shown.
 */
function DataPoint({
  typeName,
  count,
  showText,
}: {
  typeName: keyof StatsData | '';
  count: number | undefined;
  showText: boolean | undefined;
}) {
  return (
    <div className={`${styles['data-point']} ${styles[typeName]}`} data-type={typeName}>
      {showText && (

        <>
          <span className={styles['label']}>{statsLabelMap[typeName]} count:</span>
          <span className={styles['count']}>
          <CountDisplay count={count} />

          </span>
        </>
      )}
      {!showText && (

          <span className={styles['count']}>
            <CountDisplay count={count} />

        </span>
      )}
    </div>
  );
}

/**
 * This component displays a list of data points for a specific user, including the number of connections, contacts, objects, skills, networks, and travel plans.
 *
 * @param displayDataTypesName - An array of data types to display. If not specified, all data types will be displayed.
 * @param showText - Whether to show the data type name along with the count. If false, only the count will be shown.
 * @param userId - The ID of the user for which to display the data points.
 */
export default function AllUserStatsCounter(props: AllUserStatsCounterProps) {
  const authInstance = useAxiosPrivate();
  const [stats, setStats] = useState<StatsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  /**
   * Fetches the user stats from the API and updates the state.
   */
  const fetchStats = async () => {
    try {
      const response = await authInstance.get(apiRoutes.GET_ALL_USER_STATS(props.userId));
      if (response.data && response.data.data) {
        setStats(response.data.data);
      } else {
        setError('Invalid API response');
      }
      setLoading(false);
    } catch (err) {
      setError('Error fetching stats');
      setLoading(false);
    }
  };

  /**
   * useEffect hook that calls the fetchStats function when the component mounts.
   */
  useEffect(() => {
    fetchStats();
  }, [authInstance, props.userId]);

  return (
    <div className={styles['all-stats-container']}>
      {loading && <LoadingInfo displayDataTypesName={props.displayDataTypesName} userId={props.userId} />}
      {error && <ErrorInfo error={error} />}
      {!loading && !error && (
        <>
          {props.displayDataTypesName?.length ? (
            props.displayDataTypesName.map((typeName) => (
              <DataPoint
                key={typeName}
                typeName={typeName}
                count={stats?.[typeName]}
                showText={props.showText}
              />
            ))
          ) : (
            Object.keys(statsLabelMap)
              .filter((key) => key !== '')
              .map((key) => (
                <DataPoint
                  key={key}
                  typeName={key as keyof StatsData}
                  count={stats?.[key as keyof StatsData]}
                  showText={props.showText}
                />
              ))
          )}
        </>
      )}
    </div>
  );
}
