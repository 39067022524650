import React, { CSSProperties } from "react";
import ContextMenu from "../../../Components/ContextMenu/ContextMenu";
import { TravelPlanData } from "../HomePageTypes";
import { DispatchAction } from "../reducer";
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import { ACTIONS } from '../reducerActions';

export type TravelPlanContextMenuData = {
    type: "travelPlan";
    travelPlan: TravelPlanData;
    style: CSSProperties;
};

export default function TravelPlanContextMenu({
    contextMenuData,
    dispatch,
}: {
    contextMenuData: TravelPlanContextMenuData;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let { postToApi } = usePostToAPI();
    const editTravelPlan = () =>
        dispatch({
            type: ACTIONS.editingTravelPlan,
            value: contextMenuData.travelPlan,
        });

    const deleteTravelPlan = () => {
        postToApi(
            apiRoutes.UPDATE_OR_DELETE_TRAVEL_PLAN(
                contextMenuData.travelPlan.id
            ),
            undefined,
            "delete"
        );
        dispatch({
            type: ACTIONS.DELETE_TRAVEL_PLAN,
            travelPlan: contextMenuData.travelPlan,
        });
    };

    const addObjectToTravelPlan = () =>
        dispatch({
            type: ACTIONS.START_ADDING_OBJECT,
            receiver: { type: "travelPlan", for: contextMenuData.travelPlan },
        });

    return (
        <ContextMenu style={contextMenuData.style}>
            <button onClick={editTravelPlan}>Edit travel plan</button>
            <button onClick={deleteTravelPlan}>Delete travel plan</button>
            <button onClick={addObjectToTravelPlan}>
                Add object being taken
            </button>
        </ContextMenu>
    );
}
