// DummyMarker.tsx
import { useMapEvents } from 'react-leaflet';
import { Dispatch } from 'react';
import { ACTIONS } from '../reducerActions';

// Define the ActionType interface
export interface ActionType {
    type: typeof ACTIONS.MAP_CLICKED; // Specify the type of action
    coordinates: {
        x: number;
        y: number;
    };
}

interface DummyMarkerProps {
    dispatch: Dispatch<ActionType>;
}
    /**
     * Used to tap into the `useMapEvents` hook to
     * listen to the `onclick` event of the map
     */
const DummyMarker: React.FC<DummyMarkerProps> = ({ dispatch }) => {
    useMapEvents({
        click: (event) => {
            dispatch({
                type: ACTIONS.MAP_CLICKED,
                coordinates: {
                    x: event.latlng.lng,
                    y: event.latlng.lat,
                },
            });
        },
    });

    return null;
};

export default DummyMarker;
