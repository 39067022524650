import React from 'react';
import { NavigationBar } from '../../Components/LandingPageComponents/navigationBar';
import { Footer } from '../../Components/LandingPageComponents/footerComponent';

export const PrivacyPolicy = () => {
	return (
		<div className="mx-3">
			<div className="w-[100vw] flex">
				<div className="container lg:w-3/5">
					<NavigationBar />
					<div className="container">
						<h2 className="text-2xl m-4">Mapbond Privacy Policy</h2>
						<p className="m-4">
							This Privacy Policy describes how your personal information is
							collected, used, and shared when you use the Mapbond mobile
							application (the "Service").
							<h1 className="text-lg mt-2 font-medium text-gray-800">
								Collection of Personal Information
							</h1>
							When you use the Service, we may collect the following types of
							information: Location data: We use location data to show you other
							users nearby and to personalize the Service for you. You can
							control whether we access your location data through the settings
							on your device. Personal details: We may collect your name, email
							address, and other personal information when you create an account
							or update your profile. Images and videos: You may choose to
							upload images and videos to the Service, which will be visible to
							other users. Use of Personal Information
							<h2 className="font-medium mt-2 text-base text-gray-800">
								We use the information we collect from you to:
							</h2>
							<p>
								Provide, maintain, and improve the Service; Allow you to connect
								with other users and add contacts, places, and events to the
								Service; Personalize your experience by providing you with
								content and advertising that is more relevant to your interests;
								Communicate with you about the Service and respond to your
								inquiries; Detect and prevent fraud or other prohibited
								activities. Sharing of Personal Information
							</p>
							<h2 className="font-medium mt-2 text-base text-gray-800">
								We may share your personal information with third parties in the
								following ways:
							</h2>
							<p>
								With service providers: We may share your information with
								third-party service providers who perform functions on our
								behalf, such as hosting the Service, sending email or SMS
								messages, or analyzing data. For legal reasons: We may disclose
								your personal information to third parties if we determine that
								such disclosure is reasonably necessary to (a) comply with the
								law; (b) protect any person from death or serious bodily injury;
								(c) prevent fraud or abuse of Mapbond or its users; or (d)
								protect Mapbond's property rights. Security
							</p>
							<p>
								We take reasonable precautions to protect your personal
								information from loss, theft, misuse, and unauthorized access,
								disclosure, alteration, and destruction. However, no internet or
								mobile application transmission is ever fully secure or
								error-free, so you should take special care in deciding what
								information you send to us.
							</p>
							<h2 className="font-medium mt-2 text-base text-gray-800">
								Changes to This Privacy Policy
							</h2>
							<p>
								We may update this Privacy Policy from time to time. If we make
								any changes to this policy, we will notify you by updating the
								date at the top of this policy.
							</p>
							<h2 className="font-medium mt-2 text-base text-gray-800">
								Contact Us
							</h2>
							<p>
								If you have any questions or concerns about this Privacy Policy,
								please contact us at [email address or contact form].
							</p>
						</p>
					</div>
				</div>
			</div>
			<Footer backGround={'middle'} />
		</div>
	);
};
