import React, { useState, ChangeEvent, FormEvent } from 'react';
import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import styles from './ExperienceList.module.scss';

interface Experience {
  id: number;
  person_id: string | null;
  company_name: string | null;
  company_size: string | null;
  company_id: string | null;
  company_founded: string | null;
  company_industry: string | null;
  company_location_name: string | null;
  company_location_locality: string | null;
  company_location_region: string | null;
  company_location_geo: string | null;
  company_location_country: string | null;
  company_location_continent: string | null;
  company_street_address: string | null;
  company_address_line_2: string | null;
  company_postal_code: string | null;
  company_linkedin_url: string | null;
  company_linkedin_id: string | null;
  company_facebook_url: string | null;
  company_twitter_url: string | null;
  company_website: string | null;
  start_date: string | null;
  end_date: string | null;
  title_name: string | null;
  title_role: string | null;
  title_sub_role: string | null;
  title_levels: Record<string, any> | null;
  location_names: Record<string, any> | null;
  is_primary: boolean | null;
  summary: string | null;
  object_id: number;
  experience_type: string;
  created_date: string; // Assuming it's a string representation of a timestamp
  content_type_id: number;
  content_type?: string;
}


interface EditObjectProps {
  data: Experience;
  onObjectUpdated: () => void;
}

const EditAddress: React.FC<EditObjectProps> = ({ data, onObjectUpdated }) => {
  const [editedObject, setEditedObject] = useState<Experience>(data);
  const privateAPIInstance = useAxiosPrivate();
  const excludedFields = [
    'id', 'lat','lng','object_id', 'formatted_address','content_type', 'created_date',
    'person_id',
    'company_size',
    'company_id',
    'company_founded',
    'company_industry',
    'company_location_name',
    'company_location_locality',
    'company_location_region',
    'company_location_geo',
    'company_location_country',
    'company_location_continent',
    'company_street_address',
    'company_address_line_2',
    'company_postal_code',
    'company_linkedin_url',
    'company_linkedin_id',
    'company_facebook_url',
    'company_twitter_url',
    'company_website',
    'title_role',
    'title_sub_role',
    'title_levels',
    'location_names',
    'is_primary',
  ];

  const handleChange = async (e: ChangeEvent<HTMLInputElement>, isBlur: boolean = false) => {
    const { name, value } = e.target;
    let updatedObject = { ...editedObject, [name]: value };

    setEditedObject(updatedObject);

    if (isBlur && JSON.stringify(data[name as keyof Experience]) !== JSON.stringify(value)) {
      try {
        await privateAPIInstance.put(apiRoutes.EDIT_EXPERIENCE(updatedObject.id), updatedObject);
        onObjectUpdated();
      } catch (error) {
        // Handle update error
      }
    }
  };

  return (
    <div>
      {Object.entries(editedObject).map(([key, value]) => {
        if (excludedFields.includes(key)) return null; // Skip excluded fields
        return (
          <div className={styles.groupedIn} key={key}>
            <div className={styles.label}>{key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}:</div>
            <input 
              type="text"
              name={key}
              placeholder={key}
              value={value}
              onChange={(e) => handleChange(e)}
              onBlur={(e) => handleChange(e, true)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EditAddress;