import AuthProvider from 'Context/AuthContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_OAUTH_CLIENT_ID } from 'Utils/auth-utils'

// Wraps all the contexts in the application
export default function StateManager ({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) {
  console.log('StateManager rendered') // Debug message

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <AuthProvider>{children}</AuthProvider>
    </GoogleOAuthProvider>
  )
}