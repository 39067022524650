import React from 'react';
import styles from './MarkerObject.module.scss';
import { OwnedObject } from 'Pages/Home/HomePageTypes';

import { ObjectImages } from 'Components/ObjectImages/ObjectImages';
import { FiImage } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { loadImage } from 'Utils/fileLoading';

/**
 * A single object in a list of objects
 */
export default function MarkerObject({
	element,
	uploadingImage,
	deleteImage,
	addNewImage,
	deleteObject,
	triggerSearch,
}: {
	element: OwnedObject;
	uploadingImage: boolean;
	deleteObject?: (object: OwnedObject) => void;
	deleteImage?: (imageId: number, objectId: number) => void;
	addNewImage?: (imageFile: File, dataUrl: string) => void;
	triggerSearch: (keywords: string) => void;
}) {
	return (
		<>
			<div className={styles['object-text']}>
				<button
					className={styles['clickable-name']}
					onClick={() => triggerSearch(element.name)}
				>
					{element.name}
				</button>{' '}
				{element.pictures.length === 0 && !uploadingImage && addNewImage && (
					<button
						onClick={() => loadImage(addNewImage)}
						className={styles['add-image-button']}
					>
						<FiImage />
					</button>
				)}
				{deleteObject ? (
					<button
						onClick={() => deleteObject(element)}
						className={styles['delete-object-button']}
					>
						<RiDeleteBinLine />
					</button>
				) : null}
			</div>
			{element.pictures.length > 0 || uploadingImage ? (
				<ObjectImages
					uploadingImage={uploadingImage}
					object={element}
					deleteImage={deleteImage}
					addNewImage={addNewImage}
				/>
			) : null}
		</>
	);
}
