import { useState } from "react";
import { toast } from "react-toastify";

import styles from "./InviteOthers.module.scss";
import { DialogBox } from "Components/DialogBox/DialogBox";
import { DialogCloseButton } from "Components/DialogCloseButton/DialogCloseButton";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBinLine, RiSendPlane2Line } from "react-icons/ri";
import { useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { EMAIL_REGEX } from "Utils/regex";

type InvitableContact = {
    email: string;
    name: string;
    profilePicture: string | null;
};

export default function InviteOthers({
    closeDialog,
}: {
    closeDialog: () => void;
}) {
    let [inputEmail, setInputEmail] = useState("");
    let [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
    let [contactsSearch, setContactsSearch] = useState("");
    let [invitableContacts, setInvitableContacts] = useApi<
        Array<InvitableContact>
    >(apiRoutes.GET_INVITABLE_CONTACTS(contactsSearch));
    let [uploading, setUploading] = useState(false);

    let filteredInvitablecontacts =
        invitableContacts instanceof Array
            ? invitableContacts.filter(
                  (contact) => !selectedEmails.includes(contact.email)
              )
            : null;

    let { postToApi } = usePostToAPI();

    const addEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (
            inputEmail !== "" &&
            EMAIL_REGEX.test(inputEmail) &&
            !selectedEmails.includes(inputEmail)
        ) {
            setSelectedEmails([...selectedEmails, inputEmail]);
            setInputEmail("");
        }
    };

    const deleteEmail = (email: string) => () =>
        setSelectedEmails((emails) => emails.filter((e) => e !== email));

    const sendInvitations = async () => {
        setUploading(true);

        let response = await postToApi<Array<string>>(
            apiRoutes.SEND_INVITATIONS,
            { emails: JSON.stringify(selectedEmails) }
        );

        setUploading(false);

        if (response.ok) {
            let successfullyInvitedEmails = response.data;

            let allInvitesSent =
                successfullyInvitedEmails.length === selectedEmails.length;

            if (allInvitesSent) {
                closeDialog();
                toast.success("All invitations sent successfully");
            } else {
                toast.error("Some invitations not sent");
                setSelectedEmails((emails) =>
                    emails.filter(
                        (email) => !successfullyInvitedEmails.includes(email)
                    )
                );
                setInvitableContacts((contacts) =>
                    contacts instanceof Array
                        ? contacts.filter(
                              (contact) =>
                                  !successfullyInvitedEmails.includes(
                                      contact.email
                                  )
                          )
                        : contacts
                );
            }
        } else {
            toast.error(response.message);
        }
    };

    return (
        <DialogBox className={styles.box} close={closeDialog}>
            <h3>
                Invite Your Friends to MapBond
                <DialogCloseButton
                    className={styles.close}
                    onClick={closeDialog}
                />
            </h3>
            <div>
                <ol>
                    <li>
                        <p>Invite by email:</p>
                        <p>
                            <EmailInvitationForm
                                addEmail={addEmail}
                                inputEmail={inputEmail}
                                uploading={uploading}
                                setInputEmail={setInputEmail}
                            />
                        </p>
                    </li>
                    {filteredInvitablecontacts &&
                    (filteredInvitablecontacts.length > 0 ||
                        contactsSearch !== "") ? (
                        <li>
                            <p>
                                Invite your existing contacts (the list includes
                                only those with emails)
                            </p>
                            <p>
                                <input
                                    type="text"
                                    className={
                                        styles["contacts-search"] +
                                        " form-control"
                                    }
                                    placeholder="Search contacts"
                                    value={contactsSearch}
                                    onInput={(e) =>
                                        setContactsSearch(
                                            (e.target as HTMLInputElement).value
                                        )
                                    }
                                />
                                <InvitableContacts
                                    filteredInvitablecontacts={
                                        filteredInvitablecontacts
                                    }
                                    uploading={uploading}
                                    setSelectedEmails={setSelectedEmails}
                                    selectedEmails={selectedEmails}
                                />
                            </p>
                        </li>
                    ) : null}
                </ol>
                <br />
                {selectedEmails.length > 0 ? (
                    <p className={styles["selected-emails"]}>
                        Selected emails:
                        <ul>
                            {selectedEmails.map((email) => (
                                <li>
                                    {email}
                                    <button
                                        className="btn btn-tertiary"
                                        onClick={deleteEmail(email)}
                                        disabled={uploading}
                                    >
                                        <RiDeleteBinLine />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </p>
                ) : null}
                <br />
                <div className={styles["send-button-container"]}>
                    <SendButton
                        sendInvitations={sendInvitations}
                        selectedEmails={selectedEmails}
                        uploading={uploading}
                    />
                </div>
            </div>
        </DialogBox>
    );
}

function EmailInvitationForm({
    addEmail,
    inputEmail,
    setInputEmail,
    uploading,
}: {
    addEmail: (e: React.FormEvent<HTMLFormElement>) => void;
    inputEmail: string;
    uploading: boolean;
    setInputEmail: React.Dispatch<React.SetStateAction<string>>;
}) {
    return (
        <form className={styles["email-input-container"]} onSubmit={addEmail}>
            <input
                value={inputEmail}
                disabled={uploading}
                className="form-control"
                type="email"
                name="email"
                placeholder="Email"
                onInput={(e) =>
                    setInputEmail((e.target as HTMLInputElement).value)
                }
            />
            &nbsp;&nbsp;
            <button
                disabled={uploading}
                className="btn btn-primary"
                type="submit"
            >
                <FaPlus />
            </button>
        </form>
    );
}

function InvitableContacts({
    filteredInvitablecontacts,
    selectedEmails,
    setSelectedEmails,
    uploading,
}: {
    filteredInvitablecontacts: InvitableContact[];
    uploading: boolean;
    setSelectedEmails: React.Dispatch<React.SetStateAction<string[]>>;
    selectedEmails: string[];
}) {
    return (
        <ul className={styles["invitable-contacts"]}>
            {filteredInvitablecontacts.map((contact) => (
                <li
                    className={uploading ? styles["disabled"] : undefined}
                    onClick={() =>
                        !uploading &&
                        setSelectedEmails([...selectedEmails, contact.email])
                    }
                >
                    <RoundThumbnailImage
                        image={
                            contact.profilePicture ||
                            "/images/default-user-image.svg"
                        }
                        size={20}
                    />
                    &nbsp;&nbsp;
                    {contact.name} ({contact.email})
                </li>
            ))}
        </ul>
    );
}

function SendButton({
    selectedEmails,
    sendInvitations,
    uploading,
}: {
    sendInvitations: () => Promise<void>;
    selectedEmails: string[];
    uploading: boolean;
}) {
    return (
        <button
            onClick={sendInvitations}
            disabled={selectedEmails.length === 0 || uploading}
            className="btn btn-primary"
        >
            Send Invitations &nbsp;&nbsp;{" "}
            {uploading ? (
                <SmallLoadingSpinner primaryColor="white" size={16} />
            ) : (
                <RiSendPlane2Line />
            )}
        </button>
    );
}
