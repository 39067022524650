// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notes_notes__Ygun7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.Notes_notes__Ygun7 span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 1px 5px;
}
.Notes_notes__Ygun7 a {
  color: inherit;
  font-weight: 400;
}
.Notes_notes__Ygun7 a:hover {
  color: var(--accent-color);
}
.Notes_notes__Ygun7 svg {
  fill: rgb(255, 252, 200);
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Notes/Notes.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;AACR;AAEI;EACI,cAAA;EAEA,gBAAA;AADR;AAGQ;EACI,0BAAA;AADZ;AAKI;EACI,wBAAA;EACA,iBAAA;AAHR","sourcesContent":[".notes {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: wrap;\n    justify-content: center;\n\n    span {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        align-items: center;\n        margin: 1px 5px;\n    }\n\n    a {\n        color: inherit;\n        // text-decoration: underline;\n        font-weight: 400;\n\n        &:hover {\n            color: var(--accent-color);\n        }\n    }\n\n    svg {\n        fill: rgb(255, 252, 200);\n        margin-right: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notes": `Notes_notes__Ygun7`
};
export default ___CSS_LOADER_EXPORT___;
