import JournalIcon from "Icons/JournalIcon";
import MapIcon from "Icons/MapIcon";
import MessagesIcon from "Icons/MessagesIcon";
import NetworkIcon from "Icons/NetworkIcon";
import NotificationsIcon from "Icons/NotificationsIcon";

export const navBarRoutes = [
    {
        icon: MapIcon,
        name: "Home",
        route: "/",
        identifier: "navbarHome",
        exact: true,
    },
    {
        icon: MessagesIcon,
        name: "Messages",
        route: "/messages",
        identifier: "navbarMessages",
        exact: false,
    },
    {
        icon: NetworkIcon,
        name: "Networks",
        route: "/network",
        identifier: "navbarNetworks",
        exact: true,
    },
    {
        icon: NotificationsIcon,
        name: "Notifications",
        route: "/notifications",
        identifier: "navbarNotifications",
        exact: false,
        showCount: true,
    },
    {
        icon: JournalIcon,
        name: "Journal",
        route: "/journal",
        identifier: "journal",
        exact: false,
    },
];
