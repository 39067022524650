import { useRouter } from "hooks/useRouter";



/**
 * Parses the `x`, `y`, and `z` parameters of the url. If they
 * are not present, then the default values are used.
 * The default values are `0` for `x` and `y` and `1` for `z`.
 *
 * ---
 * @returns An object representing the coordinates of where the
 * parameters tell the map to zoom to
 */
export function useXYZParameters() {
    const { query } = useRouter();
    let xParameter: string | null = query.get("x");
    let yParameter: string | null = query.get("y");
    let zParameter: string | null = query.get("z");

    let parseParameter = (param: string | null) =>
        param && !Number.isNaN(Number(param)) ? Number(param) : null;

    let zoomToLongitude = parseParameter(xParameter),
        zoomToLatitude = parseParameter(yParameter),
        zoomLevel = parseParameter(zParameter);

    return { zoomToLatitude, zoomToLongitude, zoomLevel };
}
