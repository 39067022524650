// fetchData.ts
import { apiInstance } from "../../services/apiInstance";
import { ACTIONS } from '../../Pages/Home/reducerActions';
import { ApiError } from "hooks/useAPI";
import {
  CoreMapDataAPIReturnType,
  
} from "../../Pages/Home/HomePageTypes";
import { DispatchAction } from "../../Pages/Home/reducer";

interface ApiResponse {
  status: number;
  data: any; // You may want to specify a more specific type here
}

/**
 * Returns a callback which is used to perform the actual fetching of data. It
 * is used to fetch data when the user scrolls on the map or performs a search.
 * It is also used to fetch data when the user changes the tile layer.
 *
 * ---
 * @param url The fetch url
 * @param dispatch The dispatch function to handle state updates
 * @param setFetchCount Function to update fetch count state
 * @returns a callback which is used to perform the actual fetching of data
 */
export function coreFetchData(
  url: string,
  dispatch: (value: DispatchAction) => void,
  setFetchCount: React.Dispatch<React.SetStateAction<number>>
): () => Promise<void> {
  return async () => {
    try {
      const response: ApiResponse = await apiInstance.get(url);
      if (response.status === 200) {
        let data: CoreMapDataAPIReturnType = response.data.data;
        dispatch({ type: ACTIONS.MAP_DATA, data });
        setFetchCount((count) => count + 1);
      }
    } catch (reason) {
      const error = new ApiError(reason, 0);
      dispatch({ type: ACTIONS.MAP_DATA, data: error });
      setFetchCount((count) => count + 1);
    }
  };
}
