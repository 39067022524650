import React from 'react';

import { Point } from '../../Pages/Home/HomePageTypes';
import { Link } from 'react-router-dom';
import { formatDecimal, sixDecimalPlaces } from '../../Utils/utils';

export function CoordinatesLink({
	coords,
	userId,
}: {
	coords: Point;
	userId: number;
}) {
	return (
		<Link
			to={`/?y=${sixDecimalPlaces(coords.y)}&x=${sixDecimalPlaces(
				coords.x
			)}&z=12&showUser=${userId}`}
		>
			{renderCoordinatesAsText(coords)}
		</Link>
	);
}

export function renderCoordinatesAsText(coords: Point, decimalPlaces = 4) {
	return `${formatDecimal(Math.abs(coords.y), decimalPlaces)}°${coords.y >= 0 ? 'N' : 'S'
		}, ${formatDecimal(Math.abs(coords.x), decimalPlaces)}°${coords.x >= 0 ? 'E' : 'W'
		}`;
}
