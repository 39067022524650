// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Networks/MyMenuItem.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;EACA,eAAA;EACA,sCAAA;AACJ;AACI;EACE,oCAAA;AACN","sourcesContent":[".menu-item {\n    padding: 8px 16px;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  \n    &:hover {\n      background-color: rgba(0, 0, 0, 0.1);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
