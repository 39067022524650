import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import styles from "./TravelPlanPopup.module.scss";
import {
    ObjectImage,
    OwnedObject,
    TravelPlanData,
    TravelPlanObject,
} from "../../HomePageTypes";
import { DispatchAction } from "../../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import OptionsButton from "Components/OptionsButton/OptionsButton";
import { getContextMenuStyle } from "Components/ContextMenu/ContextMenu";
import MarkerObjectsList from "../../MarkerObjectsList/MarkerObjectsList";
import { ApiError, usePostToAPI } from "hooks/useAPI";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
import { apiRoutes } from "services/routes";

export function FullTravelPlanPopupContent({
    travelPlan,
    setTravelPlan,
    state,
    dispatch,
}: {
    travelPlan: TravelPlanData;
    setTravelPlan: React.Dispatch<
        React.SetStateAction<ApiError | TravelPlanData | null>
    >;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [
        idOfObjectWithImageBeingUploaded,
        setIdOfObjectWithImageBeingUploaded,
    ] = useState<number | null>(null);
    let { currentUser } = useCurrentUserDetails();

    let { postToApi } = usePostToAPI();

    const addingObject =
        state.objectBeingAddedTo !== null &&
        state.objectBeingAddedTo.type === "travelPlan" &&
        state.objectBeingAddedTo.for.id === travelPlan.id;

    const openContextMenu = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch({
            type: ACTIONS.contextMenu,
            value: {
                type: "travelPlan",
                travelPlan: travelPlan,
                style: getContextMenuStyle(event),
            },
        });
    };

    const deleteObjectImage = (imageId: number, objectId: number): void => {
        postToApi(
            apiRoutes.DELETE_USER_IMAGE_OBJECT(imageId),
            undefined,
            "delete"
        );
        setTravelPlan({
            ...travelPlan,
            objectsTaken: travelPlan.objectsTaken.map((o) =>
                o.id === objectId
                    ? {
                          ...o,
                          pictures: o.pictures.filter((p) => p.id !== imageId),
                      }
                    : o
            ),
        });
    };

    const addNewObjectImage = async (
        file: File,
        url: string,
        objectId: number
    ) => {
        setIdOfObjectWithImageBeingUploaded(objectId);

        let response = await postToApi<ObjectImage>(
            apiRoutes.CREATE_USER_IMAGE_OBJECT,
            {
                image: file,
                objectId: objectId.toString(),
            }
        );

        setIdOfObjectWithImageBeingUploaded(null);

        if (response.ok) {
            let picture = response.data;

            setTravelPlan({
                ...travelPlan,
                objectsTaken: travelPlan.objectsTaken.map((o) =>
                    o.id === objectId
                        ? {
                              ...o,
                              pictures: [...o.pictures, picture],
                          }
                        : o
                ),
            });
        }
    };

    const addObject = async (object: string | null) => {
        if (object) {
            dispatch({ type: ACTIONS.objectBeingAddedTo, value: null });

            let response = await postToApi<TravelPlanObject>(
                apiRoutes.ADD_TRAVEL_PLAN_OBJECT(travelPlan.id),
                {
                    object,
                }
            );

            if (response.ok) {
                setTravelPlan({
                    ...travelPlan,
                    objectsTaken: [...travelPlan.objectsTaken, response.data],
                });
            }
        }
    };

    const deleteObject = (object: OwnedObject) => {
        postToApi(apiRoutes.DELETE_OBJECTS(object.name), undefined, "delete");
        setTravelPlan({
            ...travelPlan,
            objectsTaken: travelPlan.objectsTaken.filter(
                (obj) => obj.id !== object.id
            ),
        });
    };

    const searchForObject = (keywords: string) =>
        dispatch({ type: ACTIONS.START_OBJECT_SEARCH, objectName: keywords });

    return (
        <div className={styles["inner-container"]}>
            {travelPlan.name ? (
                <>
                    <h3 className={`${styles["header"]}`}>{travelPlan.name}</h3>
                    <h6 className={styles["sub-header"]}>
                        {currentUser?.id === travelPlan.user.id
                            ? "Your"
                            : `${travelPlan.user.name}'s`}{" "}
                        travel plan
                    </h6>
                </>
            ) : (
                <h3 className={`${styles["header"]}`}>
                    {currentUser?.id === travelPlan.user.id
                        ? "Your"
                        : `${travelPlan.user.name}'s`}{" "}
                    travel plan
                </h3>
            )}
            <div className={styles["locations"]}>
                <Link
                    to={`/?x=${travelPlan.origin.lon}&y=${travelPlan.origin.lat}&z=12`}
                >
                    {travelPlan.origin.display_name}
                </Link>{" "}
                <RightArrow />{" "}
                <Link
                    to={`/?x=${travelPlan.destination.lon}&y=${travelPlan.destination.lat}&z=12`}
                >
                    {travelPlan.destination.display_name}
                </Link>
            </div>
            <div className={styles["dates"]}>
                <b>{formatDate(travelPlan.arrivalDate)}</b>
                <br /> (Departing <b>{formatDate(travelPlan.departureDate)}</b>)
            </div>
            <div className={styles["text-details"]}>
                <div>{travelPlan.description}</div>
                <div>{travelPlan.notes}</div>
                <div>{travelPlan.requests}</div>
            </div>
            <MarkerObjectsList
                elements={travelPlan.objectsTaken}
                triggerSearch={searchForObject}
                addNewObjectImage={addNewObjectImage}
                addObject={addObject}
                addingNewObject={addingObject}
                cancelAddingObject={() =>
                    dispatch({ type: ACTIONS.CANCEL_ADDING_OBJECT })
                }
                beginAddingNewObject={() =>
                    dispatch({
                        type: ACTIONS.START_ADDING_OBJECT,
                        receiver: { type: "travelPlan", for: travelPlan },
                    })
                }
                deleteObject={deleteObject}
                deleteObjectImage={deleteObjectImage}
                idOfObjectWithImageBeingUploaded={
                    idOfObjectWithImageBeingUploaded
                }
                highlightedElement={state.openPopup?.highlightObject}
            />
            {travelPlan.user.id === currentUser?.id ? (
                <OptionsButton onClick={openContextMenu} />
            ) : null}
        </div>
    );
}

function formatDate(dateString: string) {
    let [, yearStr, monthStr, dayStr] = /(\d+)-(\d+)-(\d+)/.exec(
        dateString
    ) as RegExpExecArray;
    let [day, month, year] = [
        Number(dayStr),
        Number(monthStr),
        Number(yearStr),
    ];

    return DateTime.fromObject({ day, month, year }).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
    );
}

function RightArrow() {
    return (
        <svg
            width="14"
            height="9"
            fill="#aaa"
            version="1.1"
            viewBox="0 0 16.628 10"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m11.628 0-1.41 1.41 2.58 2.59h-12.798v2h12.798l-2.58 2.59 1.41 1.41 5-5z" />
        </svg>
    );
}
