import React from 'react'
import { Link } from 'react-router-dom'

export const NavigationBar = () => {
    return (
        <header className="bg-white lg:flex justify-between items-center px-4 py-3 my-3">
            <div className="flex items-center justify-center">
                {'Mapbond'}
            </div>
            <nav className="flex items-center justify-center">
                {/* <Link className="px-3 py-2 mr-4 text-gray-800 hover:text-gray-600 lg:text-base text-sm" to={"/"}>Home</Link> */}
                <a className="px-3 py-2 mr-4 text-gray-800 hover:text-gray-600 lg:text-base text-sm" href={"#keyFeatures"}>Features</a>
                <a className="px-3 py-2 mr-4 text-gray-800 hover:text-gray-600 lg:text-base text-sm" href="#FAQsection">FAQs</a>
                <a className="px-3 py-2 mr-4 text-gray-800 hover:text-gray-600 lg:text-base text-sm" href="#ContactUsSection">Contact</a>
                <Link className="hidden lg:block px-3 py-2 rounded-full bg-green-600 hover:bg-green-500 text-white transition duration-200 ease-in-out" to="/accounts/signup/">Sign Up</Link>
            </nav>

        </header>
    )
}
