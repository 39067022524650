// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageEditingControls_image-controls__z9xGa {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 5px;
}
.ImageEditingControls_image-controls__z9xGa button {
  padding: 11px;
  background-color: rgba(30, 30, 30, 0.7);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 24px;
}
.ImageEditingControls_image-controls__z9xGa button svg {
  fill: white;
  height: 15px;
  width: 15px;
}
.ImageEditingControls_image-controls__z9xGa button:hover {
  background-color: rgba(30, 30, 30, 0.8);
}
.ImageEditingControls_image-controls__z9xGa button:hover svg {
  fill: hsl(0, 0%, 80%);
}
.ImageEditingControls_image-controls__z9xGa button:active {
  background-color: rgba(30, 30, 30, 0.9);
}
.ImageEditingControls_image-controls__z9xGa .ImageEditingControls_delete-button__1BmOp:hover svg {
  fill: rgb(255, 55, 55);
}
.ImageEditingControls_image-controls__z9xGa .ImageEditingControls_delete-button__1BmOp:disabled {
  background-color: rgba(30, 30, 30, 0.7);
}
.ImageEditingControls_image-controls__z9xGa .ImageEditingControls_delete-button__1BmOp:disabled svg {
  fill: rgb(180, 180, 180);
}`, "",{"version":3,"sources":["webpack://./src/Pages/LocationProfile/ImageEditingControls/ImageEditingControls.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;EACA,uCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,WAAA;AACZ;AAGI;EACI,uCAAA;AADR;AAGQ;EACI,qBAAA;AADZ;AAKI;EACI,uCAAA;AAHR;AAOQ;EACI,sBAAA;AALZ;AASI;EACI,uCAAA;AAPR;AASQ;EACI,wBAAA;AAPZ","sourcesContent":[".image-controls {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 3px;\n    margin: 5px;\n\n    button {\n        padding: 11px;\n        background-color: rgba(30, 30, 30, 0.7);\n        border: none;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin: 0 10px;\n        border-radius: 24px;\n\n        svg {\n            fill: white;\n            height: 15px;\n            width: 15px;\n        }\n    }\n\n    button:hover {\n        background-color: rgba(30, 30, 30, 0.8);\n\n        svg {\n            fill: hsl(0, 0%, 80%);\n        }\n    }\n\n    button:active {\n        background-color: rgba(30, 30, 30, 0.9);\n    }\n\n    .delete-button:hover {\n        svg {\n            fill: rgb(255, 55, 55);\n        }\n    }\n\n    .delete-button:disabled {\n        background-color: rgba(30, 30, 30, 0.7);\n\n        svg {\n            fill: rgb(180, 180, 180);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-controls": `ImageEditingControls_image-controls__z9xGa`,
	"delete-button": `ImageEditingControls_delete-button__1BmOp`
};
export default ___CSS_LOADER_EXPORT___;
