import React from "react";

import styles from "./ConnectionButton.module.scss";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import TickAnimation from "../../../Components/TickAnimation/TickAnimation";
import { UserData } from "../../Home/HomePageTypes";
import { CloseIcon } from "../../../Icons/CloseIcon";
import AddIcon from "../../../Icons/AddIcon";
import { apiRoutes } from "services/routes";
import { ApiReturnType } from "hooks/useAPI";

export type ConnectionStatus =
    | "connection"
    | "requested"
    | "stranger"
    | "loading"
    | "request received";

export default function ConnectionButton({
    status,
    onClick,
}: {
    status: ConnectionStatus;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
    let props = { onClick };

    switch (status) {
        case "connection":
            return (
                <button
                    className={`${styles["connection-button"]} ${styles["end-connection-button"]}`}
                    {...props}
                >
                    <CloseIcon
                        color="white"
                        lineLength={17}
                        lineWidth={2}
                        margin="0 6px 0 0"
                    />
                    End Connection
                </button>
            );
        case "loading":
            return (
                <button
                    className={`${styles["connection-button"]} ${styles["loading-connection"]}`}
                    {...props}
                    disabled
                >
                    <SmallLoadingSpinner
                        primaryColor="white"
                        secondaryColor="#fff3"
                        size={16}
                        margin="0 6px 0 0"
                    />
                    Loading
                </button>
            );
        case "requested":
            return (
                <button
                    className={`${styles["connection-button"]} ${styles["cancel-request"]}`}
                    {...props}
                >
                    <TickAnimation
                        margin="0 6px 3px 0"
                        color="white"
                        lineThickness="2px"
                    />
                    Connection request sent
                </button>
            );
        case "stranger":
            return (
                <button
                    className={`${styles["connection-button"]} ${styles["add-connection"]}`}
                    {...props}
                >
                    <AddIcon
                        color="white"
                        lineLength={16}
                        lineWidth={2}
                        margin="0 6px 0 0"
                    />
                    Add connection
                </button>
            );
        case "request received":
            return (
                <button
                    className={`${styles["connection-button"]} ${styles["add-connection"]}`}
                    {...props}
                >
                    <TickAnimation
                        margin="0 6px 3px 0"
                        color="white"
                        lineThickness="2px"
                    />
                    Accept connection request
                </button>
            );
    }
}

export function onConnectionButtonClicked(
    updateUser: (user: UserData) => void,
    user: UserData,
    postToApi: <R>(
        url: string,
        data?: { [key: string]: string | number | Blob | File | File[] },
        method?: "delete" | "post" | "patch"
    ) => Promise<ApiReturnType<R>>
) {
    const updateStatusTo =
        (newStatus: ConnectionStatus, isConnected: boolean) =>
        (response: Response) => {
            if (response.status >= 200 && response.status < 300) {
                updateUser({
                    ...user,
                    connectionStatus: newStatus,
                    isConnected,
                });
            }
        };

    switch (user.connectionStatus) {
        //reject connection request
        case "connection":
            postToApi(
                `${apiRoutes.ACCEPT_REJECT_END_CONNECTION_REQUESTS(
                    user.handle
                )}?reason=end_connection`,
                {},
                "delete"
            ).then(() => updateStatusTo("stranger", false));
            break;
        //accept connection
        case "request received":
            postToApi(
                `${apiRoutes.ACCEPT_REJECT_END_CONNECTION_REQUESTS(
                    user.handle
                )}`
            ).then(() => updateStatusTo("connection", true));
            break;
        //cancel connection request
        case "requested":
            postToApi(
                `${apiRoutes.CREATE_OR_DELETE_CONNECTION_REQUESTS(
                    user.handle
                )}`,
                {},
                "delete"
            ).then(() => updateStatusTo("stranger", false));
            break;
        //send connection request
        case "stranger":
            postToApi(
                `${apiRoutes.CREATE_OR_DELETE_CONNECTION_REQUESTS(user.handle)}`
            ).then(() => updateStatusTo("requested", false));
            break;
        case "loading":
            break;
    }

    updateUser({ ...user, connectionStatus: "loading" });
}
