// ./hooks/useFloatingControlActions.ts
import React from 'react';
import { GpsLocation } from "../Pages/Home/FloatingControls/ShowMyLocation/ShowMyLocation";
import { ACTIONS } from '../Pages/Home/reducerActions';
import { showMyLocation } from './showMyLocation'; // Import showMyLocation function
import { addNewLocationSearch } from './addNewLocationSearch'; // Import the function
import { addNewContactSearch } from './addNewContactSearch'; // Import the function



export const useFloatingControlActions = (dispatch: React.Dispatch<any>) => {
    const addNewLocation = () => {
        dispatch({
            type: ACTIONS.alert,
            value: "Click anywhere on the map to place a new location",
        });
        dispatch({ type: ACTIONS.addingCoordinates, value: true });
        dispatch({
            type: ACTIONS.CREATE_NEW_LOCATION,
            coordinates: { x: 0, y: 0 },
        });
    };

    const addNewContact = () => {
        dispatch({
            type: ACTIONS.alert,
            value: "Click anywhere on the map to place a new contact",
        });
        dispatch({ type: ACTIONS.addingCoordinates, value: true });
        dispatch({
            type: ACTIONS.CREATE_NEW_CONTACT,
            coordinates: { x: 0, y: 0 },
        });
    };

    const wrappedAddNewContactSearch = async () => {
        await addNewContactSearch(dispatch);
    }


    // Wrapper function for showMyLocation
    const wrappedShowMyLocation = (coordinates: GpsLocation | null, firstTime: boolean) => {
        showMyLocation(dispatch, coordinates, firstTime);
    };

    // Wrapper function for addNewLocationSearch
    const wrappedAddNewLocationSearch = async () => {
        await addNewLocationSearch(dispatch);
    };

    const setTileLayer = (layer: string) => {
        dispatch({ type: ACTIONS.tileLayer, value: layer });
    };

    return {
        addNewLocation,
        addNewContact,
        addNewContactSearch: wrappedAddNewContactSearch,
        addNewLocationSearch: wrappedAddNewLocationSearch,
        setTileLayer,
        showMyLocation : wrappedShowMyLocation
    };
};
