import { apiInstance } from 'services/apiInstance'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import { apiRoutes } from 'services/routes'
import useSignOut from 'react-auth-kit/hooks/useSignOut'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useRouter } from 'hooks/useRouter'
import Cookies from 'js-cookie'
import { useCallback, useMemo } from 'react'
/**
 *
 * To be used for requests that requires authentication token otherwise, use normal apiInstance from the services folder
 */

// react auth kit stores refresh tokens in cookies with this name

const REFRESH_TOKEN_NAME = '__mapbond_auth_refresh'

export const useAxiosPrivate = () => {
  const authHeader = useAuthHeader()
  const router = useRouter()
  const signOut = useSignOut()
  const signIn = useSignIn()
  const handleSignOut = useCallback(() => {
    signOut()
    router.push('/')
    window.location.reload()
  }, [])

  apiInstance.interceptors.request.use(
    config => {
      if (!config.headers['Authorization']) {
        // Update the global axios instance with a token
        config.headers['Authorization'] = authHeader
      }
      return config
    },
    error => Promise.reject(error)
  )
  apiInstance.interceptors.response.use(
    response => response,
    async error => {
      const prevRequest = error.config
      const refreshToken = Cookies.get(REFRESH_TOKEN_NAME)
      if (
        error.response.status === 401 &&
        !prevRequest._retry &&
        refreshToken
      ) {
        prevRequest._retry = true

        try {
          const res = await apiInstance.post(apiRoutes.REFRESH_TOKEN, {
            refresh: refreshToken
          })
          // update the request header with the new token
          prevRequest.headers['Authorization'] = `Bearer ${res.data.access}`
          // Sign in with react-auth-kit, so it can propagate across the app.
          if (
            signIn({
              auth: {
                token: res.data.access,
                type: 'Bearer',
                expiresAt: res.data.access_expiration
            } as any, // Using 'any' to bypass type checking              },
              refresh: refreshToken
            })
          ) {
            return apiInstance(prevRequest)
          } else {
            handleSignOut()
          }
        } catch (error) {
          handleSignOut()
        }
        // The refresh token will be undefined, if the user has not logged in or if it has expired and removed from the cookies.
        // Therefore in such scenerios, sign out and return to the home page.
      } else if (error.response.status === 401 && !refreshToken) {
        handleSignOut()
      }
      return Promise.reject(error)
    }
  )

  return useMemo(() => apiInstance, [])
}