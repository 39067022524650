// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomAlert_paragraph__3XYmZ {
  font-size: 1.2em;
}

.CustomAlert_custom-alert-button-container__C-8a4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.CustomAlert_custom-alert-button-container__C-8a4 button {
  margin: 5px;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  padding: 5px 15px;
  background-color: var(--accent-color);
  color: white;
  font-size: 1.2em;
}
.CustomAlert_custom-alert-button-container__C-8a4 button:hover {
  background-color: var(--accent-color-dark);
}
.CustomAlert_custom-alert-button-container__C-8a4 button:active {
  background-color: var(--accent-color-x-dark);
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomAlert/CustomAlert.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EAEI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AAAJ;AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qCAAA;EACA,YAAA;EACA,gBAAA;AAAR;AAEQ;EACI,0CAAA;AAAZ;AAGQ;EACI,4CAAA;AADZ","sourcesContent":[".paragraph {\n    font-size: 1.2em;\n}\n\n.custom-alert-button-container {\n    // margin-top: 20px;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    width: 100%;\n\n    button {\n        margin: 5px;\n        border: none;\n        border-radius: 5px;\n        font-weight: 700;\n        padding: 5px 15px;\n        background-color: var(--accent-color);\n        color: white;\n        font-size: 1.2em;\n\n        &:hover {\n            background-color: var(--accent-color-dark);\n        }\n\n        &:active {\n            background-color: var(--accent-color-x-dark);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `CustomAlert_paragraph__3XYmZ`,
	"custom-alert-button-container": `CustomAlert_custom-alert-button-container__C-8a4`
};
export default ___CSS_LOADER_EXPORT___;
