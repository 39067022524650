// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDialogContent_custom-dialog-content__PnKwv {
  padding: 16px;
  border-radius: 8px;
  background-color: white;
  overflow-y: auto;
  max-height: calc(100vh - 210px);
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomDialogContent/CustomDialogContent.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,+BAAA;AAAJ","sourcesContent":[".custom-dialog-content {\n    // Add your styles here for a modern look\n    padding: 16px;\n    border-radius: 8px;\n    background-color: white;\n    overflow-y: auto;\n    max-height: calc(100vh - 210px); // Considering header and footer height\n  \n    // You can add more styles like shadows etc. based on your needs\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-dialog-content": `CustomDialogContent_custom-dialog-content__PnKwv`
};
export default ___CSS_LOADER_EXPORT___;
