import React from "react";

import styles from "./ConfirmationButtons.module.scss";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import TickAnimation from "../../../Components/TickAnimation/TickAnimation";
import { ConfirmationRequest } from "../Notifications";
import { usePostToAPI } from "hooks/useAPI";

interface PostData {
    [key: string]: any; // This allows indexing with a dynamic key
}

export function ConfirmationButtons({
    currentRequest,
    updateRequestStatus,
}: {
    currentRequest: ConfirmationRequest;
    updateRequestStatus: (status: boolean | "loading" | "rejected") => void;
}) {
    let { postToApi } = usePostToAPI();

    const rejectRequest = () => {
        updateRequestStatus("loading");
        postToApi(currentRequest.rejectUrl, {}, 'delete') // specifying the method as DELETE
        .then((response) => {
            response.ok ? updateRequestStatus("rejected") : updateRequestStatus(false);
        });
    };

    const acceptRequest = () => {
        updateRequestStatus("loading");
        console.log(currentRequest);
    
        // Define the post data
        let postData: PostData = {};
        if (currentRequest.options) {
            postData.options = JSON.stringify(currentRequest.options);
        }
    
        // Check if postValue is present and not empty, then add it to the post data
        if (currentRequest.postValue && currentRequest.postValue.length > 0) {
            const keyValue = currentRequest.postValue.split('=');
            if (keyValue.length === 2) {
                postData[keyValue[0]] = keyValue[1];
            }
        }
    
        postToApi(currentRequest.acceptUrl, postData)
        .then((response) =>
            response.ok ? updateRequestStatus(true) : updateRequestStatus(false)
        );
    };
    

    return (
        <div className={styles["confirmation-buttons"]}>
            <AcceptButton
                currentRequest={currentRequest}
                acceptRequest={acceptRequest}
            />
            <RejectButton
                currentRequest={currentRequest}
                rejectRequest={rejectRequest}
            />
        </div>
    );
}

function RejectButton({
    currentRequest,
    rejectRequest,
}: {
    currentRequest: ConfirmationRequest;
    rejectRequest: () => void;
}) {
    return (
        <button
            className={styles["reject"]}
            disabled={!!currentRequest.accepted}
            onClick={rejectRequest}
        >
            {currentRequest.accepted === "loading" && (
                <SmallLoadingSpinner
                    primaryColor="white"
                    secondaryColor="#fff3"
                    size={14}
                    margin="0 7px 0 0"
                    linethickNess="2px"
                />
            )}
            {currentRequest.accepted === "rejected" && (
                <TickAnimation
                    lineThickness="2px"
                    height="6px"
                    width="14px"
                    color="white"
                    margin="0 7px 5px 0"
                />
            )}
            Reject
        </button>
    );
}

function AcceptButton({
    currentRequest,
    acceptRequest,
}: {
    currentRequest: ConfirmationRequest;
    acceptRequest: () => void;
}) {
    return (
        <button
            className={styles["accept"]}
            disabled={!!currentRequest.accepted}
            onClick={acceptRequest}
        >
            {currentRequest.accepted === "loading" && (
                <SmallLoadingSpinner
                    primaryColor="white"
                    secondaryColor="#fff3"
                    size={14}
                    margin="0 7px 0 0"
                    linethickNess="2px"
                />
            )}
            {currentRequest.accepted === true && (
                <TickAnimation
                    lineThickness="2px"
                    height="6px"
                    width="14px"
                    color="white"
                    margin="0 7px 5px 0"
                />
            )}
            Accept
        </button>
    );
}
