import { Footer } from "Components/LandingPageComponents/footerComponent";
import { NavigationBar } from "Components/LandingPageComponents/navigationBar";

export default function MapbondCSVPage() {
    return (
        <div className="mx-3">
            <div className="w-[100vw] flex">
                <div className="container lg:w-3/5">
                    <NavigationBar />
                    <div className="container">
                        <div>
                            <h1 className="text-2xl m-4">
                                MapBond Contacts CSV Format
                            </h1>
                            <p>
                                MapBond allows you to batch-upload contacts
                                encoded in CSV format. The CSV file should have
                                the following set of fields:
                            </p>
                            <ul
                                style={{
                                    listStyleType: "disc",
                                    listStyleImage: "none",
                                }}
                            >
                                <li>Name</li>
                                <li>Description</li>
                                <li>Email</li>
                                <li>Private Notes</li>
                                <li>Phone Number</li>
                                <li>Latitude</li>
                                <li>Longitude</li>
                                <li>
                                    Profile Picture
                                    <ul
                                        style={{
                                            listStyleType: "circle",
                                            listStyleImage: "none",
                                        }}
                                    >
                                        <li>
                                            A URL of the profile picture's image
                                            file
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Objects
                                    <ul
                                        style={{
                                            listStyleType: "circle",
                                            listStyleImage: "none",
                                        }}
                                    >
                                        <li>
                                            A semicolon-separated list of
                                            Objects
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Skills
                                    <ul
                                        style={{
                                            listStyleType: "circle",
                                            listStyleImage: "none",
                                        }}
                                    >
                                        <li>
                                            A semicolon-separated list of Skills
                                        </li>
                                    </ul>
                                </li>
                                <li>Facebook</li>
                                <li>Instagram</li>
                                <li>Linkedin</li>
                                <li>Twitter</li>
                                <li>YouTube</li>
                                <li>Website</li>
                            </ul>
                            <p>
                                You can find a sample CSV file{" "}
                                <a
                                    href="/files/sample-mapbond-csv.csv"
                                    target="_blank"
                                    download="sample-mapbond-csv.csv"
                                >
                                    here
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer backGround={"middle"} />
        </div>
    );
}

{
    /* <style>
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
  }

  main {
    padding: 20px;
    max-width: 800px;
  }

  @media screen and (min-width: 600px) {
    main {
      padding: 50px;
    }
  }

  h1 {
    color: black;
  }

  ul ul {
    list-style-type: "- ";
  } */
}
