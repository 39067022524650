
import React from 'react';
import {
    UserMarkerIcon,
    ContactMarkerIcon,
    LocationMarkerIcon,
    ClusterPreview,
} from "./PreviewIcons"; 
import RangeInput from './RangeInput'; 
import ColorInput from './ColorInput'; 
import { UserAppearanceConfig } from "types/shared"; 
import styles from "./AppearanceSettings.module.scss";

interface AppearanceSettingsTableProps {
    editedConfig: UserAppearanceConfig;
    appearanceConfig: UserAppearanceConfig;
    updateConfigField: (
        field: keyof UserAppearanceConfig,
        isNumberField: boolean
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppearanceSettingsTable: React.FC<AppearanceSettingsTableProps> = ({ 
  editedConfig, 
  appearanceConfig, 
  updateConfigField }
  ) => {
    return (
      <table className={styles["table"]}>
      <tr>
          <td colSpan={2}>
              <div className={styles["previews"]}>
                  <div>
                      <UserMarkerIcon
                          appearanceConfig={
                              editedConfig || appearanceConfig
                          }
                          connectionOrStranger="connection"
                      />
                      Connection
                  </div>
                  <div>
                      <UserMarkerIcon
                          appearanceConfig={
                              editedConfig || appearanceConfig
                          }
                          connectionOrStranger="stranger"
                      />
                      Stranger
                  </div>
                  <div>
                      <ContactMarkerIcon
                          appearanceConfig={
                              editedConfig || appearanceConfig
                          }
                      />
                      Contact
                  </div>
                  <div>
                      <LocationMarkerIcon
                          appearanceConfig={
                              editedConfig || appearanceConfig
                          }
                      />
                      Location
                  </div>
                  <div>
                      <ClusterPreview
                          appearanceConfig={
                              editedConfig || appearanceConfig
                          }
                      />
                      Cluster
                  </div>
              </div>
          </td>
      </tr>
      <tr>
          <td>User marker diameter:</td>
          <td>
              <RangeInput
                  field="user_marker_size"
                  minMaxAndStep={[20, 100]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>User marker border size:</td>
          <td>
              <RangeInput
                  field="user_marker_border"
                  minMaxAndStep={[0, 6, 0.5]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Connection border color:</td>
          <td>
              <ColorInput
                  field="connection_color_1"
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
              <ColorInput
                  field="connection_color_2"
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Stranger border color:</td>
          <td>
              <ColorInput
                  field="stranger_color_1"
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />

              <ColorInput
                  field="stranger_color_2"
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Contact marker diameter:</td>
          <td>
              <RangeInput
                  field="contact_marker_size"
                  minMaxAndStep={[20, 100]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Location marker diameter:</td>
          <td>
              <RangeInput
                  field="location_marker_size"
                  minMaxAndStep={[20, 100]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Location marker border size:</td>
          <td>
              <RangeInput
                  field="location_marker_border"
                  minMaxAndStep={[0, 6, 0.5]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Cluster size:</td>
          <td>
              <RangeInput
                  field="cluster_size"
                  minMaxAndStep={[30, 100]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Cluster color:</td>
          <td>
              <ColorInput
                  field="cluster_color"
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
      <tr>
          <td>Cluster threshold:</td>
          <td>
              <RangeInput
                  field="cluster_threshold"
                  minMaxAndStep={[30, 500]}
                  editedConfig={editedConfig}
                  updateConfigField={updateConfigField}
              />
          </td>
      </tr>
  </table>
    );
};

export default AppearanceSettingsTable;
