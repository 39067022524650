import React, {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect
  } from 'react'
  import { CurrentUserDetails } from 'types/shared'
  import { useAuthContext } from './AuthContext'
  import { apiRoutes } from 'services/routes'
  import { useAxiosPrivate } from 'hooks/useAxiosPrivate'
  
  type CurrentUserDetailsContextType = {
    currentUser: CurrentUserDetails | null
    setCurrentUser: React.Dispatch<
      React.SetStateAction<CurrentUserDetails | null>
    >
  }
  
  const CurrentUserContext = createContext<CurrentUserDetailsContextType | null>(
    null
  )
  
  export function CurrentUserDetailsProvider ({
    children
  }: {
    children: ReactNode | ReactNode[]
  }) {
    let { isAuthenticated } = useAuthContext()
    const [currentUser, setCurrentUser] = useState<CurrentUserDetails | null>(
      null
    )
    let axiosPrivate = useAxiosPrivate()
  
    useEffect(() => {
      if (isAuthenticated && !currentUser) {
        try {
          axiosPrivate.get(apiRoutes.GET_CURRENT_USER_DETAILS).then(res => {
            if (res.status < 300 && res.data.data) {
              setCurrentUser(res.data.data)
            }
          })
        } catch (error) {
          console.error(error)
        }
      }
    }, [isAuthenticated, currentUser])
  
    return (
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        {children}
      </CurrentUserContext.Provider>
    )
  }
  
  export const useCurrentUserDetails = (): CurrentUserDetailsContextType => {
    const context = useContext(CurrentUserContext)
    if (context === null) {
      throw new Error('useCurrentUser must be used within a CurrentUserProvider')
    }
    return context
  }