// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailAndNumber_email-and-number__mHkMP {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.EmailAndNumber_email-and-number__mHkMP a {
  margin: 0 10px;
  color: grey;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
.EmailAndNumber_email-and-number__mHkMP a:hover {
  color: #f7b500;
}
.EmailAndNumber_email-and-number__mHkMP span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.EmailAndNumber_email-and-number__mHkMP a {
  color: inherit;
  font-weight: 400;
}
.EmailAndNumber_email-and-number__mHkMP a:hover {
  color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/EmailAndNumber/EmailAndNumber.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAAI;EACE,cAAA;EACA,WAAA;EACA,iBAAA;EACA,gCAAA;AAEN;AADM;EACE,cAAA;AAGR;AACI;EACI,aAAA;EACA,mBAAA;EAEA,mBAAA;AAAR;AAII;EACI,cAAA;EAEA,gBAAA;AAHR;AAKQ;EACI,0BAAA;AAHZ","sourcesContent":[".email-and-number {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n    width: 100%;\n    max-width: 300px;\n    margin-top: 5px;\n    margin-bottom: 5px;\n    a {\n      margin: 0 10px;\n      color: grey;\n      font-size: 1.5rem;\n      transition: all 0.3s ease-in-out;\n      &:hover {\n        color: #f7b500;\n      }\n    }\n\n    span {\n        display: flex;\n        flex-direction: row;\n        // flex-wrap: wrap;\n        align-items: center;\n        // margin: 1px 5px;\n    }\n\n    a {\n        color: inherit;\n        // text-decoration: underline;\n        font-weight: 400;\n\n        &:hover {\n            color: var(--accent-color);\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email-and-number": `EmailAndNumber_email-and-number__mHkMP`
};
export default ___CSS_LOADER_EXPORT___;
