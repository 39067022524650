import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { TAWK_PROPERTY_ID, TAWK_WIDGET_ID } from "Utils/tawkFunction";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

export function RouterChat() {
    let location = useLocation();
    let { currentUser } = useCurrentUserDetails();

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    window.Tawk_API.customStyle = {
        visibility: {
            desktop: {
                position: "bl",
                xOffset: "10px",
                yOffset: 15,
            },
            mobile: {
                position: "tl",
                xOffset: 0,
                yOffset: 0,
            },
            bubble: {
                rotate: "0deg",
                xOffset: -20,
                yOffset: 0,
            },
        },
    };

    useEffect(() => {
        if (currentUser) {
            // tawkToLoadScripts();
            // tawkToLoadScripts should be called only once
            window.Tawk_API.visitor = {
                name: currentUser.name,
                email: currentUser.email,
                hash: "<calculate-hash>",
            };
            window.Tawk_API.onLoad = function () {
                //console.log("chat loaded");
                window.Tawk_API.setAttributes(
                    {
                        ...currentUser,
                    },
                    function (error: Error) {
                        console.log("window.Tawk_API errorr", error);
                    }
                );
            };
        }

        window.Tawk_API.onChatMaximized = function () {
            //place your code here
            //console.log("chat onChatMaximized");
            const page_path = location.pathname + location.search;
            window.Tawk_API.addEvent(
                "current-path",
                {
                    path: page_path,
                },
                function (error: Error) {
                    console.log("window.Tawk_API errorr", error);
                }
            );
        };
    }, []);
    return (
        <>
            <TawkMessengerReact
                propertyId={TAWK_PROPERTY_ID}
                widgetId={TAWK_WIDGET_ID}
            />
        </>
    );
}
