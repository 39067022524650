import React from "react";

import LargeLoadingSpinner from "../../LargeLoadingSpinner/LargeLoadingSpinner";

export default function ContextMenuLoadingSpinner() {
  return (
    <div style={{ padding: "25px 60px" }}>
      <LargeLoadingSpinner
        primaryColor="#888"
        secondaryColor="#ccc"
        size={45}
        linethickNess="4px"
      />
    </div>
  );
}
