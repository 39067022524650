import React, { useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./UserProfile.module.scss";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import { ObjectImage, OwnedObject, UserData } from "../Home/HomePageTypes";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import KeepInTouchComponent from "Components/KeepInTouchComponent/KeepInTouchComponent";

import { ProfileSkillsList } from "Components/ProfileSkillsList/ProfileSkillsList";
import { AddToProfileButton } from "Components/AddToProfileButton/AddToProfileButton";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";

import { ProfileObjectsList } from "Components/ProfileObjectsList/ProfileObjectsList";
import NotFound from "../NotFound/NotFound";

import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { useDocumentTitle } from "hooks/useSEO";
import { apiRoutes } from "services/routes";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

import FooterLinksSection from './FooterLinksSection/FooterLinksSection'; 
import UserBioSection from './UserBioSection/UserBioSection';
import BasicUserData from "./BasicUserData/BasicUserData";
import UserProfileOptions from "./UserProfileOptions/UserProfileOptions";
import UserStatsCounter from "Components/UserStatsCounter/UserStatsCounter";
import AllUserStatsCounter from "Components/AllUserStatsCounter/AllUserStatsCounter";

export default function UserProfile() {
    let { userHandle } = useParams<{ userHandle: string }>();

    let [userData, setUserData] = useApi<UserData>(
        apiRoutes.GET_USER(userHandle),
        [userHandle]
    );
    let [skillBeingAdded, setSkillBeingAdded] = useState<string | null>(null);
    let [objectBeingAdded, setObjectBeingAdded] = useState<string | null>(null);
    let [
        idOfObjectWithImageBeingUploaded,
        setIdOfObjectWithImageBeingUploaded,
    ] = useState<number | null>(null);

    let { currentUser } = useCurrentUserDetails();

    useDocumentTitle(
        `${
            userData && !(userData instanceof ApiError)
                ? userData.name
                : userHandle
        }'s Profile`,
        [userHandle, userData]
    );

    const { postToApi } = usePostToAPI();

    const addSkill = (skillName: string | null) => {
        if (userData && !(userData instanceof ApiError) && skillName) {
            postToApi(apiRoutes.ADD_SKILL_TO_OTHER_USER(userData.id), {
                skill: skillName,
            });
            setUserData({
                ...userData,
                skills: [
                    ...userData.skills,
                    { name: skillName, canEdit: true },
                ],
            });
        }
        setSkillBeingAdded(null);
    };

    const addObject = (objectName: string | null) => {
        if (userData && !(userData instanceof ApiError) && objectName) {
            postToApi<OwnedObject[]>(
                apiRoutes.ADD_OBJECT_TO_OTHER_USER(userData.id),
                {
                    object: objectName,
                }
            ).then((value) => {
                if (value.ok && userData && !(userData instanceof ApiError)) {
                    setUserData({
                        ...userData,
                        objects: [...userData.objects, ...value.data],
                    });
                }
            });
        }
        setObjectBeingAdded(null);
    };

    const addNewObjectImage = async (
        file: File,
        dataUrl: string,
        objectId: number
    ) => {
        setIdOfObjectWithImageBeingUploaded(objectId);
        postToApi<ObjectImage>(apiRoutes.CREATE_USER_IMAGE_OBJECT, {
            image: file,
            objectId: objectId.toString(),
        }).then((value) => {
            if (value.ok && userData && !(userData instanceof ApiError)) {
                setUserData({
                    ...userData,
                    objects: userData.objects.map((o) =>
                        o.id === objectId
                            ? {
                                  ...o,
                                  pictures: [...o.pictures, value.data],
                              }
                            : o
                    ),
                });
            }
            setIdOfObjectWithImageBeingUploaded(null);
        });
    };

    const deleteObjectImage = (imageId: number, objectId: number) => {
        postToApi(
            apiRoutes.DELETE_USER_IMAGE_OBJECT(imageId),
            undefined,
            "delete"
        );

        if (userData && !(userData instanceof ApiError)) {
            setUserData({
                ...userData,
                objects: userData.objects.map((o) =>
                    o.id === objectId
                        ? {
                              ...o,
                              pictures: o.pictures.filter(
                                  (p) => p.id !== imageId
                              ),
                          }
                        : o
                ),
            });
        }
    };

    const deleteObject = (object: OwnedObject) => {
        postToApi(apiRoutes.DELETE_OBJECTS(object.name), undefined, "delete");
        setUserData((user) =>
            user && !(user instanceof ApiError)
                ? {
                      ...user,
                      objects: user.objects.filter((o) => o.id !== object.id),
                  }
                : user
        );
    };

    const deleteSkill = (skill: string) => {
        postToApi(apiRoutes.DELETE_SKILL(skill), undefined, "delete");
        setUserData((user) =>
            user && !(user instanceof ApiError)
                ? {
                      ...user,
                      skills: user.skills.filter((s) => s.name !== skill),
                  }
                : user
        );
    };

    return userData === null ? (
        <main>
            <OverlayingLoadingSpinner
                primaryColor="white"
                secondaryColor="#fff3"
            />
        </main>
    ) : userData instanceof ApiError && userData.statusCode === 404 ? (
        <NotFound />
    ) : (
        <main className={styles["user-profile"]}>
            {userData instanceof ApiError ? (
                <HttpErrorMessage error={userData} />
            ) : (
                <>
                    <a
                        href={`${
                            userData.mediumProfilePicture
                                ? userData.mediumProfilePicture
                                : "/images/default-user-image.svg"
                        }`}
                    >
                        <RoundThumbnailImage
                            image={
                                userData.mediumProfilePicture
                                ? userData.mediumProfilePicture
                                : "/images/default-user-image.svg"
                            }
                            size={230}
                        />
                    </a>
                            <KeepInTouchComponent data={userData.keepInTouch} />
                    <BasicUserData userData={userData} />
                    <br />
                    {/* <UserProfileOptions
                        userData={userData}
                        setUserData={setUserData}
                        userHandle={userHandle}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_PLACES_COUNT(userData.id)}
                        dataTypeName="places"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_CONTACTS_COUNT(userData.id)}
                        dataTypeName="contacts"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_NETWORKS_COUNT(userData.id)}
                        dataTypeName="networks"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_CONNECTIONS_COUNT(userData.id)}
                        dataTypeName="connections"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_TRAVEL_PLANS_COUNT(userData.id)}
                        dataTypeName="travel plans"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_OBJECTS_COUNT(userData.id)}
                        dataTypeName="objects"
                        showText={false}
                    />

                    <UserStatsCounter
                        apiRoutePath={apiRoutes.GET_USER_SKILLS_COUNT(userData.id)}
                        dataTypeName="skills"
                        showText={false}
                    /> */}


                    <AllUserStatsCounter
                        // displayDataTypesName={['contact_count', 'connection_count']}
                        showText={true}
                        userId={userData.id}
                    />

                    <UserBioSection 
                        bio={userData.bio} 
                    />

                    <hr style={{ width: "100%" }} />
                    <ProfileSkillsList
                        elements={userData.skills}
                        skillBeingAdded={skillBeingAdded}
                        finishAddingSkill={addSkill}
                        setSkillBeingAdded={setSkillBeingAdded}
                        deleteSkill={deleteSkill}
                    />
                    <ProfileObjectsList
                        objects={userData.objects}
                        objectBeingAdded={objectBeingAdded}
                        onObjectAdded={addObject}
                        setObjectBeingAdded={setObjectBeingAdded}
                        deleteObjectImage={deleteObjectImage}
                        uploadObjectImage={addNewObjectImage}
                        idOfObjectWithImageBeingUploaded={
                            idOfObjectWithImageBeingUploaded
                        }
                        deleteObject={deleteObject}
                    />
                    {(userData.connectionStatus === "connection" ||
                        (currentUser && userData.id === currentUser.id)) && (
                        <>
                            <div className={styles["add-buttons"]}>
                                <AddToProfileButton
                                    onClick={() => setSkillBeingAdded("")}
                                >
                                    Add Skill
                                </AddToProfileButton>
                                <AddToProfileButton
                                    onClick={() => setObjectBeingAdded("")}
                                >
                                    Add Object
                                </AddToProfileButton>
                            </div>
                            <hr />
                        </>
                    )}
                        <FooterLinksSection 
                            coordinates={userData.coordinates} 
                            userDataId={userData.id} 
                        />
                </>
            )}
        </main>
    );
}

