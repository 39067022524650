import  { useState, useEffect } from "react";

import { getSearchQueryString } from "../../Pages/Home/SearchForm/SearchForm";

import { apiInstance } from "../../services/apiInstance";
import { useDebouncedAction } from "hooks/useDebounce";
import { ApiError } from "hooks/useAPI";

/**
 * Automatically manages the loading and reloading of data
 * whenever the user performs a search
 *
 * ---
 * @param searchUrl The url for searching for data
 * @param setData Sets the result of the data after fetching it
 * @param searchInput The search input from the user
 * @param searchFor Specifies what the user wants to search for
 * @param layerIsDisplaying Whether the layer for the current data is being displayed on the map. If not, the data is not loaded (except for when searching)
 * @returns The number of iterations to the current fetch request. It can be useful for forcing re-renders or other actions
 */
export function useMapSearchData<T>(
    searchUrl: string,
    setData: (data: ApiError | Array<T> | null) => void,
    searchInput: string,
    searchFor: string,
    layerIsDisplaying: boolean
): number {
    let _searchInput = searchInput.trim();
    const isSearching = _searchInput !== "";

    // A count of the number of iterations to the current fetch request
    let [fetchCount, setFetchCount] = useState(0);

    let delayRequest = useDebouncedAction();

    /**
     * Returns a callback which is used to perform the actual fetching of data. It
     * is used to fetch data when the user scrolls on the map or performs a search.
     * It is also used to fetch data when the user changes the tile layer.
     *
     * ---
     * @param url The fetch url
     * @returns a callback which is used to perform the actual fetching of data
     */
    function fetchData(url: string) {
        return async () => {
            try {
                const res = await apiInstance.get(url);
                if (res.status === 200) {
                    setData(res.data.data);
                    setFetchCount((count) => count + 1);
                }
            } catch (reason) {
                setData(new ApiError(reason, 0));
                setFetchCount((count) => count + 1);
            }
        };
    }

    // Fetch data when searching
    useEffect(() => {
        if (isSearching) {
            setData(null);
            delayRequest(
                fetchData(
                    searchUrl + getSearchQueryString(_searchInput, searchFor)
                ),
                500
            );
        }
    }, [searchFor, _searchInput, isSearching, searchUrl]);

    return fetchCount;
}



