import { useCallback, useState } from "react";

/**
 * Returns a callback which can be used to delay a fetch
 * request or any other action by a given time to avoid
 * performing it too often, or too much of it unnecessarily.
 * When you perform the action one after the other, It
 * cancels the previous action if it is still waiting for
 * timeout.
 *
 * The returned callback accepts another callback for
 * performing the action, as well as the number of
 * milliseconds to delay by
 *
 * @returns A callback which performs the required action after a delay
 */
export function useDebouncedAction() {
    let [lastAction, setLastAction] = useState<{
        timeOutId: NodeJS.Timeout | number;
    } | null>(null);

    let delayAction = useCallback(
        (performAction: () => void, timeDelay: number) => {
            if (lastAction !== null) {
                clearTimeout(lastAction.timeOutId);
            }
            setLastAction({
                timeOutId: setTimeout(() => {
                    setLastAction(null);
                    performAction();
                }, timeDelay),
            });
        },
        [lastAction]
    );

    return delayAction;
}
