import React from "react";

import { ProfileSkillsList } from "Components/ProfileSkillsList/ProfileSkillsList";
import { PersonalProfileData } from "../../Settings";
import { ApiError, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export function SkillsList({
    profileData,
    newSkill,
    setNewSkill,
    setProfileData,
}: {
    profileData: PersonalProfileData;
    newSkill: string | null;
    setNewSkill: (profileData: string | null) => void;
    setProfileData: (profileData: PersonalProfileData) => void;
}) {
    const { postToApi } = usePostToAPI();
    const addSkill = (skillName: string | null) => {
        if (
            skillName !== null &&
            skillName !== "" &&
            profileData &&
            !(profileData instanceof ApiError)
        ) {
            postToApi(apiRoutes.CREATE_SKILLS, { skill: skillName });
            setProfileData({
                ...profileData,
                skills: [
                    ...profileData.skills,
                    { name: skillName, canEdit: true },
                ],
            });
        }
        setNewSkill(null);
    };

    const deleteSkill = (skill: string): void => {
        postToApi(apiRoutes.DELETE_SKILL(skill), {}, "delete");
        if (profileData && !(profileData instanceof ApiError)) {
            setProfileData({
                ...profileData,
                skills: profileData.skills.filter(
                    (value) => value.name !== skill
                ),
            });
        }
    };

    return (
        <ProfileSkillsList
            elements={profileData.skills}
            skillBeingAdded={newSkill}
            setSkillBeingAdded={setNewSkill}
            finishAddingSkill={addSkill}
            deleteSkill={deleteSkill}
        />
    );
}
