import React from 'react';
import { Link } from "react-router-dom";
import { sixDecimalPlaces } from "Utils/utils"; // Adjust the path as necessary

interface FooterLinksSectionProps {
    coordinates?: { x: number, y: number };
    userDataId: number;
}

const FooterLinksSection: React.FC<FooterLinksSectionProps> = ({ coordinates, userDataId }) => {
    if (!coordinates) {
        return null;
    }

    return (
        <Link to={`/?y=${sixDecimalPlaces(coordinates.y)}&x=${sixDecimalPlaces(coordinates.x)}&z=12&showUser=${userDataId}`}>
            View on Map
        </Link>
    );
};

export default FooterLinksSection;