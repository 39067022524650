import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useCalculateMarkerPositions } from './NetworkMarkerUtils';

interface NetworkMarkersProps<T> {
  data: T | null;
  centerPosition: [number, number];
}

const NetworkMarkers: React.FC<NetworkMarkersProps<any>> = ({ data, centerPosition }) => {
  const location = useLocation();
  const [zoomLevel, setZoomLevel] = useState<number>(17);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const zParam = params.get("z");
    if (zParam) {
      const z = parseInt(zParam, 10);
      setZoomLevel(z);
    }
  }, [location.search]);

  const markers = useCalculateMarkerPositions(
    data?.networks || [],
    centerPosition,
    zoomLevel
  );

  return <>{markers}</>;
};

export default NetworkMarkers;
