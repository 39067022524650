import React, { CSSProperties } from "react";

import styles from "../Conversation.module.scss";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";

import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import { ApiError } from "hooks/useAPI";
import OptionsButton from "Components/OptionsButton/OptionsButton";

import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
import MessageContextMenu from "../MessageContextMenu/MessageContextMenu";
import { getTimeFromIsoString } from "Utils/utils";
import {
    getContextMenuStyle,
} from "Components/ContextMenu/ContextMenu";

type MessageData = {
    id: number;
    from_user_id: number;
    text: string;
    time: string;
};

type MessageContextMenuData = {
    message: MessageData;
    style: CSSProperties;
};

export default function MessagesList({
  contextMenu,
  setContextMenu,
  setMessages,
  messages,
}: {
  contextMenu: MessageContextMenuData | null;
  messages: MessageData[] | ApiError | null;
  setMessages: React.Dispatch<
      React.SetStateAction<MessageData[] | ApiError | null>
  >;
  setContextMenu: React.Dispatch<
      React.SetStateAction<MessageContextMenuData | null>
  >;
}) {
  let { currentUser } = useCurrentUserDetails();
  return (
      <div className={styles["messages-list"]}>
          {contextMenu && (
              <MessageContextMenu
                  contextMenu={contextMenu}
                  currentUser={currentUser}
                  messages={messages}
                  setMessages={setMessages}
              />
          )}
          {messages ? (
              messages instanceof ApiError ? (
                  <HttpErrorMessage
                      error={messages}
                      hideBorderAndBackground
                  />
              ) : (
                  messages.map((message, index, array) => (
                      <Message
                          message={message}
                          key={message.id}
                          sentByCurrentUser={
                              !!currentUser &&
                              message.from_user_id === currentUser.id
                          }
                          precededBySimilar={
                              index !== 0 &&
                              array[index - 1].from_user_id ===
                                  message.from_user_id
                          }
                          followedBySimilar={
                              index !== array.length - 1 &&
                              array[index + 1].from_user_id ===
                                  message.from_user_id
                          }
                          setContextMenu={setContextMenu}
                      />
                  ))
              )
          ) : (
              <OverlayingLoadingSpinner />
          )}
      </div>
  );
}



function Message({
    message,
    sentByCurrentUser,
    precededBySimilar,
    followedBySimilar,
    setContextMenu,
}: {
    message: MessageData;
    precededBySimilar: boolean;
    followedBySimilar: boolean;
    sentByCurrentUser: boolean;
    setContextMenu: (menu: MessageContextMenuData) => void;
}) {
    return (
        <div
            className={`${styles["message"]} ${
                styles[sentByCurrentUser ? "sent-by-me" : "sent-by-other"]
            } ${precededBySimilar ? styles["preceded-by-similar"] : ""} ${
                followedBySimilar ? styles["followed-by-similar"] : ""
            }`}
        >
            <p>{message.text}</p>
            <div className={styles["lower"]}>
                <span>{getTimeFromIsoString(message.time)}</span>
                <OptionsButton
                    onClick={(event) => {
                        event.stopPropagation();
                        setContextMenu({
                            message,
                            style: getContextMenuStyle(event),
                        });
                    }}
                    backgroundColor="transparent"
                    dotsColor={
                        sentByCurrentUser ? "var(--accent-color)" : "#888"
                    }
                    hoverBackgroundColor="#0001"
                    activeBackgroundColor="#0002"
                />
            </div>
        </div>
    );
}

