import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { timeAgo } from "Utils/utils";
import { MinimalJournalData } from "../../Pages/Home/HomePageTypes";
import styles from "./JournalItems.module.scss";

interface JournalItemsProps {
  journals: MinimalJournalData[] | null | undefined;
}

const JournalItems: React.FC<JournalItemsProps> = ({ journals }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const lastScrollTop = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showHint, setShowHint] = useState(true);

  useEffect(() => {
    // Hide the hint after a brief delay
    setTimeout(() => setShowHint(false), 1000);
  }, [showHint]);

  useEffect(() => {
    // Offset the scroll position slightly to enable upward scroll
    if (containerRef.current) {
      containerRef.current.scrollTop = 5;
    }
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const currentScrollTop = event.currentTarget.scrollTop;
    if (currentScrollTop < lastScrollTop.current) {
      setShowSearchBar(true);
    } else {
      setShowSearchBar(false);
    }
    lastScrollTop.current = currentScrollTop;
  };

  const filteredJournals = journals?.filter(journal =>
    journal.text.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  return (
    <div ref={containerRef} className={styles["journal-items-container"]} onScroll={handleScroll}>
      {showHint && <div className={styles["scroll-up-hint"]}>Scroll up to search</div>}
      <input
        type="text"
        placeholder="Search journals..."
        value={searchQuery}
        onChange={handleSearchChange}
        className={`${styles["search-bar"]} ${showSearchBar ? styles["show"] : ""}`}
      />
      <div className={styles["journal-items-content"]}>
        {filteredJournals.length > 0 ? filteredJournals.map((journal) => (
          <div key={journal.id} className={styles["journal-item"]}>
            <Link to={`/journal/${journal.id}`} className={styles["journal-link"]}>
              {journal.text.length > 50 ? `${journal.text.slice(0, 50)}...` : journal.text} -
              <span className={styles.timestamp}>{timeAgo(journal.timestamp)}</span>
            </Link>
          </div>
        )) : (
          <div className={styles["no-journal-items"]}>
            <p>No journal items found. Please add a new journal item.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JournalItems;
