import React, { useState } from "react";

import styles from "./ImageEditingControls.module.scss";
import { LocationData, LocationImage } from "../../Home/HomePageTypes";
import { loadImage } from "../../../Utils/fileLoading";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

/**
 * Used to delete and add new images to a location
 */
export default function ImageEditingControls({
    locationData,
    setLocationData,
    setShowingPicture,
    showingPicture,
}: {
    locationData: LocationData;
    setShowingPicture: (index: number) => void;
    setLocationData: (data: LocationData | null) => void;
    showingPicture: number;
}) {
    let [uploadingImages, setUploadingImages] = useState(0);

    const { postToApi } = usePostToAPI();

    const uploadImage = () => {
        loadImage(async (file, url) => {
            setUploadingImages(uploadingImages + 1);

            let response = await postToApi<LocationImage>(
                `${apiRoutes.UPLOAD_PLACE_PICTURE(locationData.handle)}`,
                {
                    picture: file,
                }
            );

            if (response.ok) {
                setUploadingImages(uploadingImages - 1);
                if (locationData) {
                    setShowingPicture(locationData.pictures.length);
                    setLocationData({
                        ...locationData,
                        pictures: [
                            ...locationData.pictures,
                            { id: response.data.id, url },
                        ],
                    });
                }
            }
        });
    };

    const deleteImage = () => {
        if (locationData) {
            postToApi(
                `${apiRoutes.DELETE_PLACE_PICTURE(
                    locationData.handle,
                    locationData.pictures[showingPicture].id
                )}`,
                {},
                "delete"
            );
            setLocationData({
                ...locationData,
                pictures: locationData.pictures.filter(
                    (value, index) => index !== showingPicture
                ),
            });
        }
        if (showingPicture > 0) {
            setShowingPicture(showingPicture - 1);
        }
    };

    return (
        <div className={styles["image-controls"]}>
            {uploadingImages > 0 && <SmallLoadingSpinner margin="10px" />}
            <button
                title="Add image"
                className={styles["add-button"]}
                onClick={uploadImage}
            >
                <AiOutlinePlus />
            </button>
            <button
                title="Delete image"
                className={styles["delete-button"]}
                onClick={deleteImage}
                disabled={locationData.pictures.length === 0}
            >
                <RiDeleteBinLine />
            </button>
        </div>
    );
}
