import L from 'leaflet';
import { ContactData } from "../../Pages/Home/HomePageTypes";
import { isNotVisible } from '../../Pages/Home/visibility';
import styles from './ContactMarkerIcon.module.scss';

export const ContactMarkerIcon = (
    contact: ContactData, 
  ) => {
    return L.divIcon({
        html: `
            <div
                class="icon"
                style="
                background-image: url(${
                    isNotVisible(contact.visibility)
                        ? '/images/unknown-contact.svg'
                        : contact.profilePicture || '/images/default-user-image.svg'
                });
                "
            ></div>
            <p class="${styles['info-message']}">Click and move the contact to a new position</p>
        `,
        iconSize: [46, 46],
        iconAnchor: [23, 23],
        popupAnchor: [0, -23],
        className: `${styles['contact-marker-icon']} ${
            isNotVisible(contact.visibility)
                ? styles['hidden']
                : styles['visible']
        }`,
    });
};
