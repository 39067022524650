// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomTooltip.module.scss */
.CustomTooltip_custom-tooltip__9tS8Q {
  position: relative;
  display: math;
  z-index: 1; /* Increase the z-index to make it appear on top of other elements */
}

.CustomTooltip_tooltip-content__ABF7Y {
  display: inline-block;
}

.CustomTooltip_tooltip-text__QNaES {
  visibility: hidden;
  position: absolute;
  bottom: calc(100% + 5px); /* Position the tooltip above the icon container */
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 2; /* Increase the z-index to make it appear on top of other elements */
}

.CustomTooltip_custom-tooltip__9tS8Q:hover .CustomTooltip_tooltip-text__QNaES {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/Components/NetworksIconsDisplay/CustomTooltip.module.scss"],"names":[],"mappings":"AAAA,8BAAA;AAEA;EACE,kBAAA;EACA,aAAA;EACA,UAAA,EAAA,oEAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,wBAAA,EAAA,kDAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,UAAA,EAAA,oEAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":["/* CustomTooltip.module.scss */\n\n.custom-tooltip {\n  position: relative;\n  display: math;\n  z-index: 1; /* Increase the z-index to make it appear on top of other elements */\n}\n\n.tooltip-content {\n  display: inline-block;\n}\n\n.tooltip-text {\n  visibility: hidden;\n  position: absolute;\n  bottom: calc(100% + 5px); /* Position the tooltip above the icon container */\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 5px;\n  background-color: #000;\n  color: #fff;\n  border-radius: 4px;\n  font-size: 12px;\n  white-space: nowrap;\n  z-index: 2; /* Increase the z-index to make it appear on top of other elements */\n}\n\n.custom-tooltip:hover .tooltip-text {\n  visibility: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-tooltip": `CustomTooltip_custom-tooltip__9tS8Q`,
	"tooltip-content": `CustomTooltip_tooltip-content__ABF7Y`,
	"tooltip-text": `CustomTooltip_tooltip-text__QNaES`
};
export default ___CSS_LOADER_EXPORT___;
