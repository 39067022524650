import React from "react";
import { UserContextMenu } from "../UserMarker/UserContextMenu";
import LocationContextMenu from "../LocationMarker/LocationContextMenu";
import { ContactContextMenu } from "../ContactMarker/ContactContextMenu";
import TravelPlanContextMenu from "../TravelPlan/TravelPlanContextMenu";
import { MapContextMenu } from "../MapContextMenu/MapContextMenu";
import { DispatchAction } from "../reducer";

import { HomePageState } from "Pages/Home/HomePageState";




//TODO Add proper React.Dispatch instead of <any>
// Now, use ContextMenuAction in your ContextMenuComponentProps
interface ContextMenuComponentProps {
  contextMenuData: HomePageState["contextMenu"] | null;
  dispatch: React.Dispatch<DispatchAction>; // Use ContextMenuAction
  state: HomePageState;
}

export default function ContextMenuComponent({ 
  contextMenuData, 
  dispatch, 
  state 
}: ContextMenuComponentProps) {
  return (
    <>
      {contextMenuData &&
        (contextMenuData.type === "user" ? (
          <UserContextMenu contextMenuData={contextMenuData} dispatch={dispatch} />
        ) : contextMenuData.type === "location" ? (
          <LocationContextMenu
            dispatch={dispatch}
            editingLocation={
              state.locationBeingEdited !== null &&
              state.locationBeingEdited.data.id === contextMenuData.location.id
            }
            contextMenuData={contextMenuData}
            state={state}
          />
        ) : contextMenuData.type === "contact" ? (
          <ContactContextMenu
            contextMenuData={contextMenuData}
            editingContact={
              state.contactBeingEdited !== null &&
              state.contactBeingEdited.data.id === contextMenuData.contact.id
            }
            dispatch={dispatch}
          />
        ) : contextMenuData.type === "travelPlan" ? (
          <TravelPlanContextMenu contextMenuData={contextMenuData} dispatch={dispatch} />
        ) : contextMenuData.type === "map" ? (
          <MapContextMenu contextMenuData={contextMenuData} dispatch={dispatch} />
        ) : null)}
    </>
  );
}

