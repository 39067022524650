import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import styles from "./TabsSection.module.scss";
import { ContactData, UserData } from "Pages/Home/HomePageTypes";
import { DispatchAction } from 'Pages/Home/reducer';

import { HomePageState } from "Pages/Home/HomePageState";


import { ApiError } from "hooks/useAPI";

import JournalItems from "Components/JournalItems/JournalItems"

import SkillDisplayAndDelete from "Components/SkillDisplayAndDelete/SkillDisplayAndDelete";
import SkillAdder from "Components/SkillAdder/SkillAdder";

import ObjectDisplayAndDelete from "Components/ObjectDisplayAndDelete/ObjectDisplayAndDelete";
import ObjectAdder from "Components/ObjectAdder/ObjectAdder";
//TODO FIgure out why adding objects auto updates the UI on contacts, not on own profile



interface TabsSectionProps {
  data: ContactData | UserData;
  setContact: React.Dispatch<
      React.SetStateAction<ApiError | ContactData | UserData | null>
  >;
  addingKeepInTouch: boolean;
  state: HomePageState;
  dispatch: React.Dispatch<DispatchAction>;
  UserOrContact: "user" | "contact";
}

export default function TabsSection({
    data,
  setContact,
  addingKeepInTouch,
  state,
  dispatch,
  UserOrContact
}: TabsSectionProps) {
  if (!addingKeepInTouch) {

      const updateUIAfterDeleteSkill = (skill: string) => {
          setContact({
              ...data,
              skills: data.skills.filter((skl) => skl.name !== skill),
          });
      };

      const updateUIAfterDeleteObject = (object: string) => {
          setContact({
              ...data,
              objects: data.objects.filter((obj) => obj.name !== object),
          });
      };

      return (
          <div className={styles["tabs-container"]}>
              <Tabs
                  defaultIndex={
                      state.objectBeingAddedTo &&
                          state.objectBeingAddedTo.type === UserOrContact &&
                          state.objectBeingAddedTo.for.id === data.id
                          ? 1
                          : undefined
                  }
              >
                  <TabList>
                      <Tab>Skills</Tab>
                      <Tab>Objects</Tab>
                      <Tab>Feed</Tab>
                  </TabList>

                  <TabPanel>
                      <SkillDisplayAndDelete
                          dispatch={dispatch}
                          skills={data.skills}
                          id={data.id}
                          target={UserOrContact}
                          onDelete={updateUIAfterDeleteSkill}
                          highlightedSkill={
                              state.openPopup?.highlightSkill
                          }
                      />


                      <SkillAdder
                          target={UserOrContact}
                          id={data.id}
                          onSkillAdded={(newSkills) =>
                              setContact({
                                  ...data,
                                  skills: [...data.skills, ...newSkills],
                              })
                          }
                          autoCompleteCategory="skills"
                      />

                  </TabPanel>
                  <TabPanel>


                      <ObjectDisplayAndDelete
                          dispatch={dispatch}
                          objects={data.objects}
                          id={data.id}
                          target={UserOrContact}
                          onDelete={updateUIAfterDeleteObject}
                          highlightObject={
                              state.openPopup?.highlightObject
                          }
                      />

                      <ObjectAdder
                          target={UserOrContact}
                          id={data.id}
                          onObjectAdded={(newObjects) =>
                              setContact({
                                  ...data,
                                  objects: [...data.objects, ...newObjects],
                              })
                          }
                          autoCompleteCategory="objects"
                      />


                  </TabPanel>
                  <TabPanel>
                      <JournalItems journals={data.journals} />

                  </TabPanel>
              </Tabs>
          </div>
      );
  }
  return null;
}
