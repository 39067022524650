import React from 'react';
import { BasicNetworkData } from './BasicNetworkDataTypes'; // Adjust import path as necessary
import CustomDropdown from '../CustomDropdown/CustomDropdown'; // Adjust import path as necessary
import { TextDetails } from './NetworkDetailsComponents';

interface NetworkDetailsProps {
    network: BasicNetworkData;
    setNetwork: React.Dispatch<React.SetStateAction<BasicNetworkData>>;
    setSelectedPhoto: React.Dispatch<React.SetStateAction<File | null>>;
    setIsAddingMembers: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkDetails: React.FC<NetworkDetailsProps> = ({
    network,
    setNetwork,
    setSelectedPhoto,
    setIsAddingMembers
}) => {
    return (
        <div>
            <TextDetails
                network={network}
                setNetwork={setNetwork}
                setPhoto={setSelectedPhoto}
            />
            <div className="m-2">
                <button
                    onClick={() => setIsAddingMembers(true)}
                    className="btn btn-primary"
                >
                    Add members
                </button>
                <span className="text-gray-600 ml-1">
                    {network.member_contacts.length +
                        network.member_connections.length +
                        network.member_locations.length}{" "}
                    member
                    {network.member_contacts.length +
                        network.member_connections.length +
                        network.member_locations.length !==
                        1 && "s"}{" "}
                    selected
                </span>
            </div>
            <div className="ml-2">
                <span>Visibility@ Settings:</span>
                <CustomDropdown
                    items={[
                        "PRIVATE",
                        "PUBLIC",
                        "CONNECTIONS",
                    ]}
                    onChange={(value) =>
                        setNetwork({
                            ...network,
                            visibility: value,
                        })
                    }
                    selected={network.visibility}
                />
            </div>
        </div>
    );
};

export default NetworkDetails;
