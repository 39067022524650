import React from "react";
import { BiCheck } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";

import styles from "./ConfirmButtons.module.scss";

export default function ConfirmButtons({
    onCancel,
    onDoneEditing,
}: {
    onCancel: () => void;
    onDoneEditing: () => void;
}) {
    return (
        <div className={styles["buttons"]}>
            <button onClick={onCancel}>
                <IoCloseSharp />
            </button>
            <button onClick={onDoneEditing}>
                <BiCheck />
            </button>
        </div>
    );
}
