import React, { useState, useEffect } from "react";

import { DispatchAction } from "../../Pages/Home/reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";
import { useAxiosPrivate } from "../useAxiosPrivate";


import {
    MinimalTravelPlanData,
} from "../../Pages/Home/HomePageTypes";
import { useDebouncedAction } from "hooks/useDebounce";
import { ApiError } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import { travelFetchData } from './travelFetchData';

export function useTravelPlanData(
    state: HomePageState,
    dispatch: React.Dispatch<DispatchAction>
): number {
    // A count of the number of iterations to the current fetch request
    let [fetchCount, setFetchCount] = useState(0);
    let delayRequest = useDebouncedAction();
    let setData = (plans: Array<MinimalTravelPlanData> | ApiError | null) =>
        dispatch({ type: ACTIONS.travelPlans, value: plans });
    let isSearching = state.searchInput.trim() !== "";
    let layerIsDisplaying = state.selectedLayers.includes("Travel Plans");
    const axiosPrivate = useAxiosPrivate();


    // Set data to null when searching
    useEffect(() => {
        if (isSearching) {
            setData(null);
        }
    }, [isSearching]);

    // Fetch data normally on the map. This is only done when the layer
    // is being displayed. Otherwise, the data is not loaded.
    useEffect(() => {
        if (
            !isSearching && 
            layerIsDisplaying && 
            state.hoveredOverUser
            ) {
            setData(null);
            delayRequest(
                travelFetchData(
                    axiosPrivate, // Pass the axiosPrivate instance
                    apiRoutes.GET_USER_TRAVEL_PLANS(
                        state.hoveredOverUser.handle
                    ) +
                        (state.mapBounds
                            ? "?bbox=" + state.mapBounds.toBBoxString()
                            : ""),
                            setData, 
                            setFetchCount, 
                ),
                50
            );
        }
    }, [
        state.mapBounds,
        isSearching,
        layerIsDisplaying,
        state.hoveredOverUser,
    ]);

    return fetchCount;
}
