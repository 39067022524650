import React from "react";
import ContextMenu from "../../../Components/ContextMenu/ContextMenu";
import { MapContextMenuData } from "../HomePageTypes";
import { DispatchAction } from "../reducer";
import { ACTIONS } from "Pages/Home/reducerActions";
import JournalEntry from '../../../Components/JournalEntry/JournalEntry';

export interface JournalTemplate {
	name: string;
	avatar: string;
	id: number;
	type: 'contact' | 'connection' | 'place';
	profile?: any;
}

/**
 * Showed when the user right-clicks on the map. It allows
 * the user to add a new marker or a new route. It also
 * allows the user to change the map type. It is a wrapper
 * around the ContextMenu component. It is shown when the
 * user right-clicks on the map.
 * @param props
 * @returns
 * @constructor
 * @example
 * <MapContextMenu
 * data={{
 * x: 100,
 * y: 100,
 * }}
 * dispatch={dispatch}
 * />
 * @see ContextMenu
 * @see Home
 * @see reducer
 * @see MapContextMenuData
 * @see DispatchAction
 */
export function MapContextMenu({
  contextMenuData,
  dispatch,
}: {
  contextMenuData: MapContextMenuData;
  dispatch: React.Dispatch<DispatchAction>;
}) {
  const addNewLocation = () => {
    dispatch({ type: ACTIONS.addingCoordinates, value: false });
    dispatch({
      type: ACTIONS.CREATE_NEW_LOCATION,
      coordinates: contextMenuData.coordinatesClicked,
    });
  };

  const addNewContact = () => {
    dispatch({ type: ACTIONS.addingCoordinates, value: false });
    dispatch({
      type: ACTIONS.CREATE_NEW_CONTACT,
      coordinates: contextMenuData.coordinatesClicked,
    });
  };

  const addNewNetwork = () => {
    dispatch({ type: ACTIONS.addingCoordinates, value: false });
    dispatch({ type: ACTIONS.addingNetwork, value: true });

    // dispatch({
    //   type: ACTIONS.CREATE_NEW_NETWORK,
    //   coordinates: contextMenuData.coordinatesClicked,
    // });
  };

  const addJournalEntry = () => {
    let obj: JournalTemplate = {
        id: 0,
        name: "Your Journal Entry",
        avatar: "",
        type: "connection",
    };
    dispatch({ type: ACTIONS.addingJournalEntry, value: obj });
};
  return (
    <ContextMenu style={contextMenuData.style}>
      <button onClick={addNewLocation}>Add new Location</button>
      <button onClick={addNewContact}>Create new Contact</button>
      <button onClick={addNewNetwork}>Create new Network</button>
      <button onClick={addJournalEntry}>Create new Journal Item</button>
    </ContextMenu>
  );
}
