import React, { CSSProperties } from "react";

import styles from "./ContextMenu.module.scss";

/**
 * A custom context menu. The `children` can be any
 * relevant elements like buttons, links, etc
 */
export default function ContextMenu({
  children,
  style,
}: {
  /**
   * The `children` of the context menu can be any
   * relevant elements like buttons, links, etc
   */
  children: React.ReactNode | Array<React.ReactNode>;
  style: CSSProperties;
}) {
  return (
    <div className={styles["context-menu"]} style={style}>
      {children}
    </div>
  );
}

/**
 * Used to calculate the `CSSProperties` style to be 
 * assigned to a `ContextMenu` element. For example:
 * 
 * ---
 * ```
 * 
  let [style, setStyle] = useState<CSSProperties | null>(null);

  const openMenu = (event: React.MouseEvent): void =>
    setStyle(getContextMenuStyle(event));

  const closeMenu = () => setStyle(null);

  return (
    <>
      <button onClick={openMenu}>Click me</button>
      {style && (
        <ContextMenu style={style}>
          <button onClick={closeMenu}>Close</button>
        </ContextMenu>
      )}
    </>
  );
 * ```
 * 
 * ---
 * @param event Any pointer event having `clientX` and `clientY` values
 * @returns A style that can be assigned to a context menu
 */
export function getContextMenuStyle({
  clientX,
  clientY,
}: {
  clientX: number;
  clientY: number;
}) {
  let { innerWidth, innerHeight } = window;

  let xCornerAnchorSide = clientX > innerWidth / 2 ? "Right" : "Left";
  let yCornerAnchorSide = clientY > innerHeight / 2 ? "Bottom" : "Top";

  let xAnchorDistance =
    xCornerAnchorSide === "Right" ? innerWidth - clientX : clientX;
  let yAnchorDistance =
    yCornerAnchorSide === "Bottom" ? innerHeight - clientY : clientY;

  let style: any = {};

  style[`border${yCornerAnchorSide}${xCornerAnchorSide}Radius`] = 0;
  style[xCornerAnchorSide.toLowerCase()] = xAnchorDistance + "px";
  style[yCornerAnchorSide.toLowerCase()] = yAnchorDistance + "px";

  return style as CSSProperties;
}
