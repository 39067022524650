import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import styles from './RangeDatePicker.module.scss'; // Importing the SCSS module

registerLocale("en-GB", enGB);

interface DatePickerProps {
  selected: Date | null;
  minDate?: Date;
  onChange: (date: Date | null) => void;
  highlightToday?: boolean;
  placeholderText?: string; // Added placeholderText prop
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  selected,
  minDate,
  onChange,
  highlightToday = false,
  placeholderText = "Select a date", // Default placeholder text
}) => {
  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      dateFormat="dd/MM/yyyy"
      minDate={minDate}
      highlightDates={highlightToday ? [new Date()] : []}
      locale="en-GB"
      placeholderText={placeholderText}
      className={styles.customDatePicker} // Use the imported style here
    />
  );
};

interface RangeDatePickerProps {
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  highlightToday?: boolean;
}

const CustomRangeDatePicker: React.FC<RangeDatePickerProps> = ({
  startDate,
  endDate,
  minDate = new Date(),
  onChange,
  startDatePlaceholder = "Start Date", // Default placeholder for start date
  endDatePlaceholder = "End Date", // Default placeholder for end date
  highlightToday = false,
}) => {

  const handleStartChange = (date: Date | null) => {
    if (endDate && date && date > endDate) {
      onChange(date, date);
    } else {
      onChange(date, endDate ?? null);
    }
  };

  const handleEndChange = (date: Date | null) => {
    if (startDate && date && date < startDate) {
      onChange(startDate, startDate);
    } else {
      onChange(startDate ?? null, date);
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex items-center">
        <label htmlFor="start-date" className="mr-2 md:mr-4">Start Date:</label>
        <CustomDatePicker
          selected={startDate ?? null}
          minDate={minDate}
          onChange={handleStartChange}
          highlightToday={highlightToday}
          placeholderText={startDatePlaceholder}
        />
      </div>
      <div className="flex items-center mt-4 md:mt-0 md:ml-4">
        <label htmlFor="end-date" className="mr-2 md:mr-4">End Date:</label>
        <CustomDatePicker
          selected={endDate ?? null}
          minDate={startDate || minDate}
          onChange={handleEndChange}
          highlightToday={highlightToday}
          placeholderText={endDatePlaceholder}
        />
      </div>
    </div>
  );
};

export default CustomRangeDatePicker;
