import React from "react";

export function ChevronIcon({
  direction,
  size: __size,
  color,
}: {
  direction: "up" | "down";
  size?: number;
  color?: string;
}) {
  let size = typeof __size === "number" ? __size + "px" : "32px";

  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      viewBox="0 -960 960 960"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: direction === "up" ? "rotate(180deg)" : "rotate(360deg)",
        transition: "transform 0.5s ease",
      }}
    >
      <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" fill={color || "#aaa"} />
    </svg>
  );
}
