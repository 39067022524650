import React, { CSSProperties } from "react";
import { Property } from "csstype";

import styles from "./CustomSelect.module.scss";

/**
 * A custom element that allows you to select elements
 * from a set of options. It is a wrapper around the
 * HTML select element. It is styled to match the
 * rest of the application. It is also possible to
 * style the select element using the style prop.
 * @param props
 * @returns
 * @constructor
 * @example
 * <CustomSelect
 *   options={[
 *     { value: "1", label: "One" },
 *     { value: "2", label: "Two" },
 *     { value: "3", label: "Three" },
 *   ]}
 *   value="2"
 *   onChange={(e) => console.log(e.target.value)}
 * />
 */
export function CustomSelect<T extends string>({
    options,
    selected,
    onChange,
    orientation,
    blank,
    flexJustification,
    selectedStyle,
    unselectedStyle,
}: {
    /**
     * The list of elements can either be an array of objects
     * with the display text and actual value separately or
     * just strings of text. (The idea of separating the text
     * to be displayed from the actual value comes from the
     * `select` html element)
     */
    options: Array<{ text: string; value: T } | T>;
    /** The selected element */
    selected: string;
    onChange: (newValue: T) => void;
    orientation?: "vertical" | "horizontal";
    blank?: boolean;
    /** Equivalent to the CSS `justify-content` */
    flexJustification?: Property.JustifyContent;
    /** Style to apply to selected elements */
    selectedStyle?: CSSProperties;
    /** Style to apply to unselected elements */
    unselectedStyle?: CSSProperties;
}) {
    return (
        <div
            className={styles["custom-select"]}
            style={{
                flexDirection: orientation === "vertical" ? "column" : "row",
                flexWrap: orientation === "vertical" ? undefined : "wrap",
                width: orientation === "vertical" ? "fit-content" : undefined,
                justifyContent: flexJustification || "flex-start",
            }}
        >
            {options.map((_item) => {
                let item =
                    typeof _item === "string"
                        ? { value: _item, text: _item }
                        : _item;

                return (
                    <div
                        key={item.value}
                        style={
                            item.value === selected
                                ? {
                                      ...{
                                          backgroundColor:
                                              "var(--accent-color)",
                                          color: "white",
                                      },
                                      ...selectedStyle,
                                  }
                                : {
                                      ...{
                                          backgroundColor: "#eee",
                                          color: "black",
                                      },
                                      ...unselectedStyle,
                                  }
                        }
                        onClick={() => {
                            onChange(
                                selected === item.value
                                    ? blank === false
                                        ? item.value
                                        : ("" as T)
                                    : item.value
                            );
                        }}
                    >
                        {item.text}
                    </div>
                );
            })}
        </div>
    );
}
