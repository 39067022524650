import React, { useCallback, useEffect } from 'react'
import CustomButton from 'Components/CustomButton/CustomButton'
import { useForm, SubmitHandler } from 'react-hook-form'
import TextInput from 'Components/forms/TextInput'

import { Link, useLocation } from 'react-router-dom'
import { useEmailFromToken } from './useEmailFromToken' // Updated import name
import { useGoogleAuth } from '../../hooks/auth/google-oauth'
import { useSignUp } from 'hooks/auth/signup'
import {
    TOnboardingPayload,
    TSignInForm,
    TSignUpForm,
    TUserData
  } from 'types/shared'

const SignUpForm = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search) // Get the invitationToken from the URL
  const invitationToken = queryParams.get('invitationToken')

  const inviteeEmail = useEmailFromToken(invitationToken) // Updated function name
  console.log(inviteeEmail)

    // Log the inviteeEmail and invitationToken whenever they change
    useEffect(() => {
        console.log('Invitation Token:', invitationToken);
        console.log('Invitee Email:', inviteeEmail);
      }, [invitationToken, inviteeEmail]);
    

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TSignUpForm>()

  const handleRegistration = useSignUp()
  const { initializeGoogleAuth } = useGoogleAuth()
  const onSubmit: SubmitHandler<TSignUpForm> = async data => {
    console.log('Form Submission Data:', data); // Log form submission data

    const { email, username, password1 } = data;
    const payload: TSignInForm = {
      username,
      email,
      password: password1,  // Assuming password1 is the password you want to use
      password1: password1,  // Assuming password1 is the password you want to use
      password2: password1  // Assuming password1 is the password you want to use
    };

    handleRegistration?.(payload);
}

  // Log errors from form state
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log('Form Errors:', errors);
    }
  }, [errors]);
  

  const handleGoogleAuth = useCallback(() => {
    initializeGoogleAuth?.()
  }, [])

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='flex items-center justify-center w-full p-20'>
        <div className='flex flex-col space-y-4 shadow-xl p-3 md:w-[350px]'>
          <h1 className='text-3xl text-center'>Sign up</h1>
          <small className='text-center'>With a third party</small>
          <div
            className='flex justify-center items-center space-x-3 bg-red-500 p-2 cursor-pointer'
            onClick={handleGoogleAuth}
          >
            <img src='/images/google-icon.png' alt='Google Icon'></img>
            <small className='uppercase text-white'>Sign Up with Google</small>
          </div>
          <div className='border-b w-full text-center relative'>
            <span className='absolute -translate-y-3 -translate-x-3	 bg-white w-8 h-8'>
              OR
            </span>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col space-y-4 '
          >
            <TextInput
              register={register}
              inputName='username'
              errors={errors}
              placeholder='Username'
              inputLabel='Username:'
              emptyErrorMessage='Username is required'
            />
            <TextInput
              register={register}
              inputName='email'
              formType='email'
              errors={errors}
              placeholder='E-mail address'
              inputLabel='E-mail (optional):'
              emptyErrorMessage='Email is required'
              patternErrorMessage='Invalid email address'
              defaultValue={inviteeEmail}
            />
            <TextInput
              formType='password'
              register={register}
              inputName='password1'
              errors={errors}
              placeholder='Password'
              inputLabel='Password:'
              emptyErrorMessage='Password is required'
            />
            <CustomButton buttonType='submit' variant='primary'>
              Sign Up
            </CustomButton>
          </form>

          <div>
            <Link className='pt-8 text-xs ' to='/accounts/login/'>
              Already have an account? Sign in.
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpForm