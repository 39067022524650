import React, { useEffect, useState } from "react";
import { useCollapsibleAnimation } from "../Accordion/useCollapsibleAnimation";

import styles from "./CustomDropdown.module.scss";

/**
 * A custom dropdown menu
 */
export default function CustomDropdown<T extends string>({
  selected,
  items,
  onChange,
  disabled,
}: {
  selected: T;
  items: Array<T>;
  onChange: (value: T) => void;
  disabled?: boolean;
}) {
  let [isOpen, setIsOpen] = useState(false);
  let { elementRef, toggleCollapsible } = useCollapsibleAnimation(
    isOpen,
    setIsOpen
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", toggleCollapsible);
      return () => document.removeEventListener("click", toggleCollapsible);
    }
  }, [isOpen, toggleCollapsible]);

  return (
    <div
      className={`${styles["custom-dropdown"]} ${isOpen && !disabled ? styles["open"] : ""
        } ${disabled ? styles["disabled"] : ""}`}
      onClick={(event) => {
        event.stopPropagation();
        toggleCollapsible();
      }}
    >
      <div className={styles["display-value"]}>
        {selected}
        <svg width="10px" height="5px" viewBox="0 0 10 5">
          <path d="m 0,0 10,0 -5,5" fill="grey" stroke="none" />
        </svg>
      </div>
      <div className={styles["hidden-values"]}>
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <div className={styles["all-values"]} ref={elementRef}>
        {items.map((item, index) => (
          <div key={index}
            className={selected === item ? styles["selected"] : undefined}
            onClick={() => onChange(item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
