import React, { useEffect } from "react";

import { DispatchAction } from "../../Pages/Home/reducer";

import { useRouter } from "hooks/useRouter";
import { ACTIONS } from 'Pages/Home/reducerActions';

/**
 * Used to parse `showUser`, `showContact`, `showLocation`,
 * `highlightSkill`, and `highlightObject` URL parameters,
 * which tell the map which popup to open on the map. This
 * is useful for sharing a link to a specific popup. The
 * `highlightSkill` and `highlightObject` parameters are used
 * to highlight a specific skill or object on the map.
 *
 * ---
 * @param dispatch The dispatch callback
 */
export function useOpenPopupParameters(
    dispatch: React.Dispatch<DispatchAction>
) {
    const { query } = useRouter();
    let showUserParameter: string | null = query.get("showUser");
    let showContactParameter: string | null = query.get("showContact");
    let showLocationParameter: string | null = query.get("showLocation");
    let highlightSkill: string | null = query.get("highlightSkill");
    let highlightObject: string | null = query.get("highlightObject");
    console.log(highlightSkill);

    useEffect(() => {
        if (showLocationParameter) {
            if (!Number.isNaN(Number(showLocationParameter))) {
                dispatch({
                    type: ACTIONS.openPopup,
                    value: {
                        type: "location",
                        id: Number(showLocationParameter),
                        highlightObject: highlightObject || undefined,
                    },
                });
            }
        }
    }, [showLocationParameter, highlightObject, dispatch]);

    useEffect(() => {
        if (showUserParameter) {
            if (!Number.isNaN(Number(showUserParameter))) {
                dispatch({
                    type: ACTIONS.openPopup,
                    value: {
                        type: "user",
                        id: Number(showUserParameter),
                        highlightObject: highlightObject || undefined,
                        highlightSkill: highlightSkill || undefined,
                    },
                });
            }
        }
    }, [showUserParameter, highlightSkill, highlightObject, dispatch]);

    useEffect(() => {
        if (showContactParameter) {
            if (!Number.isNaN(Number(showContactParameter))) {
                dispatch({
                    type: ACTIONS.openPopup,
                    value: {
                        type: "contact",
                        id: Number(showContactParameter),
                        highlightObject: highlightObject || undefined,
                        highlightSkill: highlightSkill || undefined,
                    },
                });
            }
        }
    }, [showContactParameter, highlightSkill, highlightObject, dispatch]);
}
