import React, { useState, useEffect, useCallback } from "react";
import { apiRoutes } from "services/routes";
import { usePostToAPI } from "hooks/useAPI";
import { useAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";
import { OwnedObject } from "../../Pages/Home/HomePageTypes";  // Update type import
import AddIcon from "Icons/AddIcon";
import styles from "./ObjectAdder.module.scss";  // Update CSS module
import { CloseIcon } from "Icons/CloseIcon";

interface ObjectInputProps {
  object: string | null;
  setObject: React.Dispatch<React.SetStateAction<string | null>>;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const ObjectInput: React.FC<ObjectInputProps & { onFocus: (e: React.FocusEvent<HTMLInputElement>) => void }> = ({
  object,
  setObject,
  onKeyPress,
  placeholder,
  onFocus,
}) => {
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setObject(null); // Clear the object input when Escape is pressed
    }
  }, [setObject]);

  return (
    <input
      type="text"
      className={styles["object-adder-input"]}  
      placeholder={placeholder}
      value={object || ""}
      onChange={(e) => setObject(e.target.value)}
      onKeyPress={onKeyPress}
      onFocus={onFocus} 
      onKeyDown={handleKeyDown} 
      autoFocus
    />
  );
};



interface AutocompleteSuggestionsListProps {
  suggestions: string[];
  onSuggestionClick: (suggestion: string) => void;
}

const AutocompleteSuggestionsList: React.FC<AutocompleteSuggestionsListProps> = ({ suggestions, onSuggestionClick }) => (
  <div className={styles["object-adder-suggestions-container"]}>
  <ul className={styles["object-adder-suggestions"]}>
    {suggestions.map((suggest, index) => (
      <li
        key={index}
        className={styles["object-adder-suggestion-item"]}
        onClick={() => onSuggestionClick(suggest)}
      >
        {suggest}
      </li>
    ))}
  </ul>
  </div>
);

const useObjects = (
  target: string, 
  id: number, 
  onObjectAdded: (newObjects: OwnedObject[]) => void
) => {
  const { postToApi } = usePostToAPI();

  const addObject = async (object: string) => {
    const objectsToAdd = object.split(",");
    const newObjects: OwnedObject[] = [];

    for (const o of objectsToAdd) {
      const response = await postToApi(
        target === "contact"
        ? apiRoutes.CREATE_CONTACT_OBJECT(id)
        : target === "place"
        ? apiRoutes.CREATE_PLACE_OBJECT(id)
        : apiRoutes.ADD_OBJECT_TO_OTHER_USER(id),
        {
          object: o.trim(),
        }
      );

      if (response.ok && response.data) {
        const addedObject = response.data as OwnedObject;
        newObjects.push(addedObject);  // Use the object returned from the API
      }
    }

    onObjectAdded(newObjects);
  };

  return { addObject };
};

interface ObjectAdderProps {
  target: "contact" | "user" | "place";
  id: number;
  onObjectAdded: (newObjects: OwnedObject[]) => void;
  autoCompleteCategory?: "skills" | "objects" | "cities" | "personal-objects";
}

const ObjectAdder: React.FC<ObjectAdderProps> = ({
  target,
  id,
  onObjectAdded,
  autoCompleteCategory = "objects",
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [object, setObject] = useState<string | null>(null);
  const [currentInput, setCurrentInput] = useState("");
  const [addedObjects, setAddedObjects] = useState<string[]>([]);
  const { addObject } = useObjects(target, id, (newObjects) => {
    onObjectAdded(newObjects);
    setAddedObjects(addedObjects.concat(newObjects.map(o => o.name)));
  });

  const handleInputFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    // Define behavior on input focus
    // For example, you could set some state here or fetch data
  }, []);

  const autocompleteSuggestions = useAutocompleteSuggestions(
    apiRoutes.GET_AUTOCOMPLETE(autoCompleteCategory),
    object || ""
  ).filter(suggestion => !addedObjects.includes(suggestion));

  const handleSuggestionClick = useCallback((selectedSuggestion: string) => {
    setObject(selectedSuggestion);
    setTimeout(() => addObject(selectedSuggestion), 25);
  }, [addObject, addedObjects]);
  // ... (rest of the component code, similar to SkillAdder but adapted for objects)
  
  const handleAddIconClick = useCallback((event) => {
    event.stopPropagation();
    setShowInput(true);
  }, []);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        if (object) {
          addObject(object);
          setCurrentInput("");
        }
      }
    },
    [object, addObject, addedObjects]
  );

  // Function to hide the input box
  const handleCloseInput = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault(); // Prevent the default behavior (e.g., following a link)
    e.stopPropagation(); // Stop the event propagation
  
    setShowInput(false);
    setObject(null);
  };


  
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowInput(false);
        setObject(null);
      }
    };
  
    const onKeyDown = (e: KeyboardEvent) => {
      handleEscapeKey(e);
    };
  
    window.addEventListener("keydown", onKeyDown);
  
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);
  return (
    <>
      {!showInput && (
        <div className={styles["add-icon-container"]} onClick={handleAddIconClick}>
          <AddIcon color="var(--accent-color)" lineLength={16} />
        </div>
      )}
      {showInput && (
        <div className={styles["object-adder-container"]}>  
          {/* Close button (X) */}
          <div className={styles["close-icon-container"]} onClick={(e) => handleCloseInput(e)}>
            <CloseIcon color="var(--accent-color)" lineLength={16} />
          </div>
          <ObjectInput
            object={currentInput}
            setObject={(value) => {
              setObject(value);
              setCurrentInput((value as string) || "");
            }}
            onKeyPress={handleKeyPress}
            placeholder={`Add an object for this ${target}`}
            onFocus={handleInputFocus}
          />
          {autocompleteSuggestions.length > 0 && (
            <AutocompleteSuggestionsList
              suggestions={autocompleteSuggestions}
              onSuggestionClick={handleSuggestionClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ObjectAdder;
