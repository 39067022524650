import React, { useState, useEffect, useCallback } from "react";
import { apiRoutes } from "services/routes";
import { usePostToAPI } from "hooks/useAPI";
import { useAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";
import { Skill } from "../../Pages/Home/HomePageTypes";
import AddIcon from "Icons/AddIcon";
import styles from "./SkillAdder.module.scss";
import { CloseIcon } from "Icons/CloseIcon";

interface SkillInputProps {
  skill: string | null;
  setSkill: React.Dispatch<React.SetStateAction<string | null>>;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
}


const SkillInput: React.FC<SkillInputProps & { onFocus: (e: React.FocusEvent<HTMLInputElement>) => void }> = ({
  skill,
  setSkill,
  onKeyPress,
  placeholder,
  onFocus, // Add onFocus prop
}) => {
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setSkill(null); // Clear the skill input when Escape is pressed
    }
  }, [setSkill]);

  return (
    <input
      type="text"
      className={styles["skill-adder-input"]}
      placeholder={placeholder}
      value={skill || ""}
      onChange={(e) => setSkill(e.target.value)}
      onKeyPress={onKeyPress}
      onFocus={onFocus} // Bind onFocus event
      onKeyDown={handleKeyDown} // Add keydown event handler
      autoFocus
    />
  );
};


interface AutocompleteSuggestionsListProps {
  suggestions: string[];
  onSuggestionClick: (suggestion: string) => void;
}

const AutocompleteSuggestionsList: React.FC<AutocompleteSuggestionsListProps> = ({ suggestions, onSuggestionClick }) => (
  <div className={styles["skill-adder-suggestions-container"]}>
  <ul className={styles["skill-adder-suggestions"]}>
    {suggestions.map((suggest, index) => (
      <li
        key={index}
        className={styles["skill-adder-suggestion-item"]}
        onClick={() => onSuggestionClick(suggest)}
      >
        {suggest}
      </li>
    ))}
  </ul>
  </div>
);

const useSkills = (
  target: string, 
  id: number, 
  onSkillAdded: (newSkills: Skill[]) => void
) => {
  const { postToApi } = usePostToAPI();

  const addSkill = async (skill: string) => {
    const skillsToAdd = skill.split(",");
    const newSkills: Skill[] = [];

    for (const s of skillsToAdd) {
      const response = await postToApi(
        target === "contact"
          ? apiRoutes.ADD_SKILL_TO_CONTACT(id)
          : apiRoutes.ADD_SKILL_TO_OTHER_USER(id),
        {
          skill: s.trim(),
        }
      );

      if (response.ok) {
        newSkills.push({ name: s.trim(), canEdit: true });
      }
    }

    onSkillAdded(newSkills);
  };

  return { addSkill };
};

interface SkillAdderProps {
  target: "contact" | "user";
  id: number;
  onSkillAdded: (newSkills: Skill[]) => void;
  autoCompleteCategory?: "skills" | "objects" | "cities" | "personal-objects";
}

const SkillAdder: React.FC<SkillAdderProps> = ({
  target,
  id,
  onSkillAdded,
  autoCompleteCategory = "skills",
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [skill, setSkill] = useState<string | null>(null);
  const [currentInput, setCurrentInput] = useState("");
  const [addedSkills, setAddedSkills] = useState<string[]>([]);
  const { addSkill } = useSkills(target, id, (newSkills) => {
    onSkillAdded(newSkills);
    setAddedSkills(addedSkills.concat(newSkills.map(s => s.name)));
  });

  const handleInputFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    // Define behavior on input focus
    // For example, you could set some state here or fetch data
  }, []);

  const autocompleteSuggestions = useAutocompleteSuggestions(
    apiRoutes.GET_AUTOCOMPLETE(autoCompleteCategory),
    skill || ""
  ).filter(suggestion => !addedSkills.includes(suggestion));

  const handleSuggestionClick = useCallback((selectedSuggestion: string) => {
    setSkill(selectedSuggestion);
    setTimeout(() => addSkill(selectedSuggestion), 25);
  }, [addSkill, addedSkills]);

  const handleAddIconClick = useCallback((event) => {
    event.stopPropagation();
    setShowInput(true);
  }, []);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        if (skill) {
          addSkill(skill);
          setCurrentInput("");
        }
      }
    },
    [skill, addSkill, addedSkills]
  );

  // Function to hide the input box
  const handleCloseInput = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault(); // Prevent the default behavior (e.g., following a link)
    e.stopPropagation(); // Stop the event propagation
  
    setShowInput(false);
    setSkill(null);
  };
  
  
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowInput(false);
        setSkill(null);
      }
    };
  
    const onKeyDown = (e: KeyboardEvent) => {
      handleEscapeKey(e);
    };
  
    window.addEventListener("keydown", onKeyDown);
  
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <>
      {!showInput && (
        <div className={styles["add-icon-container"]} onClick={handleAddIconClick}>
          <AddIcon color="var(--accent-color)" lineLength={16} />
        </div>
      )}
      {showInput && (
        <div className={styles["skill-adder-container"]}>
          {/* Close button (X) */}
          <div className={styles["close-icon-container"]} onClick={(e) => handleCloseInput(e)}>
            <CloseIcon color="var(--accent-color)" lineLength={16} />
          </div>
          <SkillInput
            skill={currentInput}
            setSkill={(value) => {
              setSkill(value);
              setCurrentInput((value as string) || "");
            }}
            onKeyPress={handleKeyPress}
            placeholder={`Add a skill for this ${target}`}
            onFocus={handleInputFocus}
          />
          {autocompleteSuggestions.length > 0 && (
            <AutocompleteSuggestionsList
              suggestions={autocompleteSuggestions}
              onSuggestionClick={handleSuggestionClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SkillAdder;