import React from 'react';
import { Link } from 'react-router-dom';

const MapbondDescription: React.FC = () => {
  return (
    <div
      key="mapbond-description"
      className="flex items-center justify-center py-16 px-3 lg:min-h-[80vh]"
    >
      <div className="container mx-auto px-4">
        <div className="flex-1">
          <h1 className="text-5xl font-bold mb-4 text-gradient-to-r from-yellow-400 to-pink-500">
            🗺️ Map your social capital with Mapbond 🤝
          </h1>
          <p className="text-gray-600 text-lg mb-8">
            Mapbond is not just a platform, it's a social experience. 💬 Our
            mission is to empower communities to come together and connect over
            their shared interests and passions. ❤️
          </p>
          <p className="text-gray-600 text-lg mb-8">
            With Mapbond, you can create personalized maps of your social
            connections and share locations, contacts, and recommendations with
            others. 🌎 You can even discover new people who share your interests
            and add them to your network. 🤝
          </p>
          <p className="text-gray-600 text-lg mb-8">
            Our unique feature is the ability to control your privacy and reveal
            as much or as little information about yourself as you want. 🔒 You
            can also customize your profile with funky colors and designs to
            express your personality. 🎨
          </p>
          <Link
            to="/accounts/signup/"
            className="inline-block px-6 py-3 rounded-full bg-green-500 transition duration-200 ease-in-out shadow-xl hover:translate-y-1 hover:scale-110"
          >
            <p className="text-yellow-100 hover:text-white font-bold">
              Join the Fun! 🎉
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

// Add a default export that returns the MapbondDescription component itself
export default MapbondDescription;
export { MapbondDescription as mapbondDescription }; // Make it callable as a function
