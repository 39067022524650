import moment, { Duration } from 'moment';

// Function to convert English expression to a moment duration object
const parseDuration = (timeExpression: string): Duration => {
  const units: { [key: string]: moment.unitOfTime.DurationConstructor } = {
    day: 'days',
    week: 'weeks',
    month: 'months',
    year: 'years'
  };

  const regex = /(\d+)\s*(day|week|month|year)s?/i;
  const matches = timeExpression.match(regex);

  if (matches && matches[1] && matches[2]) {
    const quantity = parseInt(matches[1], 10);
    const unit = units[matches[2].toLowerCase()];
    if (unit) {
      return moment.duration(quantity, unit);
    }
  }

  throw new Error('Invalid time expression');
};

export default parseDuration;
