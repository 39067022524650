/**
 * Used to load a file from the user. Based on:
 * https://eloquentjavascript.net/19_paint.html#h_7eec4RKHJi
 *
 * ---
 * @param formats The accepted file formats. It is the same as the `HTMLInputElement.accept` property
 */
export function loadFile(formats: string) {
    return new Promise<File>((resolve) => {
        let input = document.createElement("input");
        input.type = "file";
        input.onchange = () => {
            input.files && resolve(input.files[0]);
        };
        input.accept = formats;

        document.body.appendChild(input);
        input.click();
        input.remove();
    });
}

/**
 * Used to load an image from the user.
 *
 * ---
 * @param callBack The callback function which receives the resulting image as both a file and as a data url
 */
export function loadImage(callBack: (file: File, dataUrl: string) => void) {
    return loadFile(".png, .jpg, .jpeg")
        .then((file) => fileToDataUrl(file))
        .then((result) => result && callBack(result.file, result.dataUrl));
}

/**
 * Converts a file to a Data URL
 *
 * ---
 * @param {File} file The file which has been selected by the user
 */
function fileToDataUrl(file: File) {
    if (!file) return;

    return new Promise<{ file: File; dataUrl: string }>((resolve) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            resolve({ file, dataUrl: reader.result as string });
        });
        reader.readAsDataURL(file);
    });
}
