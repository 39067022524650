// MyIconButton.tsx
import React from 'react';

interface MyIconButtonProps {
  id?: string;
  ariaLabel: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  ariaHaspopup?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
}

const MyIconButton: React.FC<MyIconButtonProps> = ({
  id,
  ariaLabel,
  ariaControls,
  ariaExpanded,
  ariaHaspopup,
  onClick,
  children,
}) => {
  return (
    <button
      id={id}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MyIconButton;
