// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperienceList_objectContainer__B4bKd {
  margin-bottom: 20px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG .ExperienceList_label__D4Bz4 {
  font-weight: bold;
  color: #333;
  flex: 0 0 30%;
  text-align: left;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG .ExperienceList_value__-04u6, .ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG input {
  cursor: pointer;
  flex: 1 1;
  text-align: right;
  background-color: #f9f9f9;
  padding: 5px 10px;
  border-radius: 4px;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG .ExperienceList_value__-04u6:hover, .ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG input:hover {
  background-color: #e9e9e9;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG input {
  border: 1px solid #ccc;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_groupedIn__T3UnG input:focus {
  outline: none;
  border-color: #007bff;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_editButton__baYrR {
  margin-top: 10px;
  cursor: pointer;
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_editButton__baYrR:hover {
  background-color: #0056b3;
}
.ExperienceList_objectContainer__B4bKd .ExperienceList_feedbackMessage__ehb6K {
  color: red;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ExperienceManager/ExperienceList.module.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,gBAAA;AAAF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EAEA,cAAA;AADJ;AAGI;EACE,iBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AADN;AAII;EACE,eAAA;EACA,SAAA;EACA,iBAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;AAFN;AAIM;EACE,yBAAA;AAFR;AAMI;EACE,sBAAA;AAJN;AAKM;EACE,aAAA;EACA,qBAAA;AAHR;AAQE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AANJ;AAOI;EACE,yBAAA;AALN;AASE;EACE,UAAA;EACA,mBAAA;AAPJ","sourcesContent":["// AddressList.module.scss\n.objectContainer {\n  margin-bottom: 20px;\n  border: 1px solid #eee;\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  max-width: 300px;\n\n  .groupedIn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    // margin-bottom: 10px;\n    padding: 5px 0;\n\n    .label {\n      font-weight: bold;\n      color: #333;\n      flex: 0 0 30%;\n      text-align: left;\n    }\n\n    .value, input {\n      cursor: pointer;\n      flex: 1;\n      text-align: right;\n      background-color: #f9f9f9;\n      padding: 5px 10px;\n      border-radius: 4px;\n\n      &:hover {\n        background-color: #e9e9e9;\n      }\n    }\n\n    input {\n      border: 1px solid #ccc;\n      &:focus {\n        outline: none;\n        border-color: #007bff;\n      }\n    }\n  }\n\n  .editButton {\n    margin-top: 10px;\n    cursor: pointer;\n    padding: 5px 15px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    &:hover {\n      background-color: #0056b3;\n    }\n  }\n\n  .feedbackMessage {\n    color: red;\n    margin-bottom: 10px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"objectContainer": `ExperienceList_objectContainer__B4bKd`,
	"groupedIn": `ExperienceList_groupedIn__T3UnG`,
	"label": `ExperienceList_label__D4Bz4`,
	"value": `ExperienceList_value__-04u6`,
	"editButton": `ExperienceList_editButton__baYrR`,
	"feedbackMessage": `ExperienceList_feedbackMessage__ehb6K`
};
export default ___CSS_LOADER_EXPORT___;
