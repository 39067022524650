import React, { useState } from "react";

import styles from "./AddContactFromLinkedIn.module.scss";
import { DialogBox } from "Components/DialogBox/DialogBox";
import { DispatchAction } from "../reducer";
import { DialogCloseButton } from "Components/DialogCloseButton/DialogCloseButton";
import { ContactData, Point } from "../HomePageTypes";
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { ACTIONS } from '../reducerActions';

export default function AddContactFromLinkedIn({
    dispatch,
    flyToCoordinates,
}: {
    dispatch: React.Dispatch<DispatchAction>;
    flyToCoordinates: (coordinates: Point, duration: number) => void;
}) {
    let [linkedInUrl, setLinkedInUrl] = useState("");
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState("");

    let { postToApi } = usePostToAPI();

    const onClose = () =>
        dispatch({ type: ACTIONS.addingContactFromLinkedin, value: false });

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        setError("");

        let returnValue = await postToApi<ContactData>(
            apiRoutes.ADD_CONTACT_FROM_LINKEDIN,
            { linkedInUrl }
        );

        setLoading(false);

        if (returnValue.ok) {
            let contact = returnValue.data;
            const DURATION_MILLIS = 1000;
            flyToCoordinates(contact.coordinates, DURATION_MILLIS / 1000);
            setTimeout(
                () =>
                    dispatch({
                        type: ACTIONS.START_EDITING_CONTACT,
                        contact,
                    }),
                DURATION_MILLIS
            );
        } else {
            setError(returnValue.message || "Failed to load contact");
        }
    };

    return (
        <DialogBox close={onClose} className={styles.dialogBox}>
            <h3>
                Import Contact From LinkedIn URL
                <DialogCloseButton onClick={onClose} />
            </h3>
            <br />
            <p>
                <form onSubmit={onSubmit}>
                    <input
                        type="text"
                        disabled={loading}
                        placeholder="LinkedIn URL"
                        className="form-control"
                        onInput={(e) =>
                            setLinkedInUrl((e.target as HTMLInputElement).value)
                        }
                    />
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-primary"
                        disabled={!linkedInUrl.trim() || loading}
                    >
                        {loading ? (
                            <span>
                                <SmallLoadingSpinner
                                    primaryColor="white"
                                    secondaryColor="transparent"
                                    size={18}
                                />
                            </span>
                        ) : (
                            "Import"
                        )}
                    </button>
                </form>
            </p>
            {error ? <div className={styles.error}>{error}</div> : null}
        </DialogBox>
    );
}
