export function isNotVisible(visibility: Visibility | VisibilityWithPublic) {
  return ["needs request", "requested", "loading"].includes(visibility);
}

export function isOwnedBySelf(visibility: Visibility | VisibilityWithPublic) {
  return [
    "connections need to request",
    "private",
    "public",
    "shared with connections",
  ].includes(visibility);
}

/** Denotes the visibility of a contact or location to the current user */
export type VisibilityWithPublic = Visibility | "public";

/** Denotes the visibility of a contact or location to the current user */
export type Visibility =
  | "needs request"
  | "request accepted"
  | "requested"
  | "visible"
  | "private"
  | "shared with connections"
  | "connections need to request"
  | "loading";
