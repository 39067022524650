import React, { useState } from "react";

import styles from "./AddTravelPlan.module.scss";
import { DispatchAction } from "../reducer";

import { HomePageState } from "Pages/Home/HomePageState";

import AutocompleteInput from "Components/AutocompleteInput/AutocompleteInput";
import { TravelPlanData } from "../HomePageTypes";
import { ObjectsBeingTaken } from "./ObjectsBeingTaken/ObjectsBeingTaken";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import { useOSMAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";

import { DialogCloseButton } from "Components/DialogCloseButton/DialogCloseButton";

import DateInputSection from "./DateInputSection/DateInputSection"
import { TextDetails } from './TextDetails'; // Import the TextDetails component
import { PrivacyLevelSelector } from './PrivacyLevelSelector'; // Import the PrivacyLevelSelector component
import BackAndForthArrows from "Components/BackAndForthArrows/BackAndForthArrows";
import TravelPlanManager from './TravelPlanManager'; // Import the new component
import { ACTIONS } from '../reducerActions';

  
export default function AddTravelPlan({
    initialValue,
    state,
    dispatch,
}: {
    initialValue?: TravelPlanData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState({
        origin: "",
        destination: "",
        arrival: "",
        departure: "",
    });

    const [originSearchInput, setOriginSearchInput] = useState<string>(
        initialValue?.origin.display_name ?? ""
    );
    const [destinationSearchInput, setDestinationSearchInput] =
        useState<string>(initialValue?.destination.display_name ?? "");

    const originCitiesAutocomplete = useOSMAutocompleteSuggestions(
        `https://nominatim.openstreetmap.org/search?q=${originSearchInput}&format=json&addressdetails=1&limit=5`,
        originSearchInput
    );
    const destinationCitiesAutocomplete = useOSMAutocompleteSuggestions(
        `https://nominatim.openstreetmap.org/search?q=${destinationSearchInput}&format=json&addressdetails=1&limit=5`,
        destinationSearchInput
    );

    const isExistingTravelPlan = !!initialValue;

    const closeMenu = () => dispatch({ type: ACTIONS.CLOSE_TRAVEL_PLAN_EDITOR });


    return (
        <TravelPlanManager
            initialValue={initialValue}
            isExistingTravelPlan={isExistingTravelPlan}
            dispatch={dispatch}
            closeMenu={closeMenu}
            originSearchInput={originSearchInput}
            destinationSearchInput={destinationSearchInput}
        >
            
            {(travelPlan, setTravelPlan, submitTravelPlan) => (
        <div className={styles["container"]}>
            <div className={styles["add-flight-plan"]}>
                <div className={styles["inputs"]}>
                    {isUploading ? (
                        <OverlayingLoadingSpinner
                            primaryColor="var(--accent-color)"
                            backgroundColor="#fffa"
                        />
                    ) : null}
                    <div className={styles["heading"]}>
                        <h3>
                            {isExistingTravelPlan
                                ? "Edit Travel Plan"
                                : "Add a Travel Plan"}
                        </h3>
                        <DialogCloseButton
                            className={styles.close}
                            onClick={closeMenu}
                        />
                    </div>
                        <DateInputSection
                            travelPlan={travelPlan}
                            setTravelPlan={setTravelPlan}
                            errors={errors}
                            setErrors={setErrors} // Pass setErrors here
                        />
                    <div className={styles["errors"]}>
                        <div>{errors.arrival}</div>
                        <div>{errors.departure}</div>
                    </div>
                    <div className={styles["place-input-row"]}>
                        <AutocompleteInput
                            placeholder="From"
                            inputClassname={styles["place-input"]}
                            containerClassname={styles["place-input-container"]}
                            value={
                                (originSearchInput as any)?.display_name ??
                                originSearchInput
                            }
                            setValue={(value) => {
                                setOriginSearchInput(value);
                                setTravelPlan({ ...travelPlan, origin: value });
                                setErrors({ ...errors, origin: "" });
                            }}
                            autocompleteSuggestions={originCitiesAutocomplete}
                            style={{
                                borderColor: errors.origin ? "red" : undefined,
                            }}
                        />
                        <BackAndForthArrows />
                        <AutocompleteInput
                            placeholder="To"
                            inputClassname={styles["place-input"]}
                            containerClassname={styles["place-input-container"]}
                            value={
                                (destinationSearchInput as any)?.display_name ??
                                destinationSearchInput
                            }
                            setValue={(value) => {
                                setDestinationSearchInput(value);
                                setTravelPlan({
                                    ...travelPlan,
                                    destination: value,
                                });
                                setErrors({ ...errors, destination: "" });
                            }}
                            autocompleteSuggestions={
                                destinationCitiesAutocomplete
                            }
                            style={{
                                borderColor: errors.destination
                                    ? "red"
                                    : undefined,
                            }}
                        />
                    </div>
                    <div className={styles["errors"]}>
                        <div>{errors.origin}</div>
                        <div>{errors.destination}</div>
                    </div>
                    <TextDetails
                        travelPlan={travelPlan}
                        setTravelPlan={setTravelPlan}
                    />

                <PrivacyLevelSelector
                    travelPlan={travelPlan}
                    setTravelPlan={setTravelPlan}
                />

                    <ObjectsBeingTaken
                        travelPlan={travelPlan}
                        objectsBeingTaken={travelPlan.objectsTaken}
                        setObjectsBeingTaken={(objects) =>
                            setTravelPlan({
                                ...travelPlan,
                                objectsTaken: objects,
                            })
                        }
                        isExistingTravelPlan={isExistingTravelPlan}
                    />
                </div>
                <div className={styles["button-container"]}>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            submitTravelPlan().catch(error => {
                                console.error("Error submitting travel plan:", error);
                                // Handle the error appropriately
                            });
                        }}
                        disabled={
                            !travelPlan.arrivalDate ||
                            !travelPlan.departureDate ||
                            !travelPlan.origin ||
                            !travelPlan.destination ||
                            isUploading
                        }
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>

                    )}
                </TravelPlanManager>

    );
}


