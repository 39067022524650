import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

/**
 * Used to display a prompt when you want to navigate away
 * from the page but there are unsaved changes.
 */
export default function UnsavedChangesPrompt({
  unsavedChangesExist,
}: {
  unsavedChangesExist: boolean;
}) {
  // The implementation uses both the `Prompt` element from
  // `react-router` as well as the default browser
  // `beforeunload` event, since neither works in both
  // single-page navigation as well as normal url navigation

  useWarningBeforeLeavingUnsavedChanges(unsavedChangesExist);

  return (
    <Prompt
      message="You have unsaved changes. Are you sure you want to leave?"
      when={unsavedChangesExist}
    />
  );
}

export function useWarningBeforeLeavingUnsavedChanges(unsavedChanges: boolean) {
  useEffect(() => {
    const eventHandler = (ev: BeforeUnloadEvent): string => {
      const warning =
        "You have unsaved changes. Are you sure you want to leave?";
      ev.returnValue = warning;
      return warning;
    };

    if (unsavedChanges) {
      window.addEventListener("beforeunload", eventHandler);
    }

    return () => window.removeEventListener("beforeunload", eventHandler);
  }, [unsavedChanges]);
}
