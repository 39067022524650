import React, { useState } from "react";
import styles from "./OptionsList.module.scss";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

type OptionsType = { [key: string]: boolean };

type CategoryType = {
    [key: string]: string[];
};

function getCategories(type: "contact" | "place"): CategoryType {
    return {
        "Basic Info":
            type === "contact"
                ? [
                      "Contact picture",
                      "Contact name",
                      "Description",
                      "Skills",
                      "Objects",
                  ]
                : ["Place pictures", "Place name", "Description", "Objects"],
        "Contact Details": ["Email", "Phone number", "Precise position"],
        "Social Media": [
            "Facebook",
            "Instagram",
            "Twitter",
            "LinkedIn",
            "YouTube",
            "Website",
        ],
    };
}

function categorizeOptions(
    options: OptionsType,
    type: "contact" | "place"
): {
    [category: string]: OptionsType;
} {
    const categories = getCategories(type);
    const categorizedOptions: { [category: string]: OptionsType } = {};

    for (let category of Object.keys(categories)) {
        categorizedOptions[category] = {};
        for (let optionName of categories[category]) {
            if (options.hasOwnProperty(optionName)) {
                categorizedOptions[category][optionName] = options[optionName];
            }
        }
    }

    return categorizedOptions;
}

function getCategoryState(
    options: OptionsType,
    categoryKeys: string[]
): "all" | "none" | "partial" {
    const enabledOptions = categoryKeys.filter((key) => options[key]);

    if (enabledOptions.length === 0) {
        return "none";
    }
    if (enabledOptions.length === categoryKeys.length) {
        return "all";
    }
    return "partial";
}

function getCategoryColor(state: "all" | "none" | "partial"): string {
    switch (state) {
        case "all":
            return "green";
        case "none":
            return "red";
        default:
            return "orange";
    }
}

export function OptionsList({
    type,
    options,
    onOptionsChanged,
    disabled,
}: {
    type: "contact" | "place";
    options: { [key: string]: boolean };
    onOptionsChanged: (newOptions: { [key: string]: boolean }) => void;
    disabled: boolean;
}) {
    const categorizedOptions = categorizeOptions(options, type);

    const handleToggleOption = (key: string) => {
        onOptionsChanged({ ...options, [key]: !options[key] });
    };

    const categories: CategoryType = getCategories(type);

    const toggleCategory = (categoryName: string) => {
        const categoryKeys = categories[categoryName];
        const allEnabled = categoryKeys.every((key) => options[key]);

        const newOptions = { ...options };

        for (let key of categoryKeys) {
            if (allEnabled && options[key]) {
                newOptions[key] = false;
            } else if (!allEnabled && !options[key]) {
                newOptions[key] = true;
            }
        }

        onOptionsChanged(newOptions);
    };

    return (
        <>
            <div className={styles["options-list"]}>
                {Object.entries(categorizedOptions).map(
                    ([categoryName, categoryOptions]) => (
                        <div key={categoryName}>
                            {Object.keys(categoryOptions).length > 0 && (
                                <>
                                    <h6
                                        onClick={() => {
                                            if (!disabled) {
                                                toggleCategory(categoryName);
                                            }
                                        }}
                                        style={{
                                            color: getCategoryColor(
                                                getCategoryState(
                                                    options,
                                                    categories[categoryName]
                                                )
                                            ),
                                        }}
                                    >
                                        {categoryName} (Toggle)
                                    </h6>
                                    {Object.entries(categoryOptions).map(
                                        ([key, isChecked]) => (
                                            <label
                                                key={key}
                                                onClick={() => {
                                                    if (!disabled) {
                                                        handleToggleOption(key);
                                                        // toggleOption(key);
                                                    }
                                                }}
                                            >
                                                <CustomCheckbox
                                                    checked={isChecked}
                                                    disabled={disabled}
                                                    className={
                                                        styles["checkbox"]
                                                    }
                                                    animate={false}
                                                />
                                                {key}
                                            </label>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    )
                )}
            </div>
        </>
    );
}
