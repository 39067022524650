import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaEye } from "react-icons/fa";

import styles from "./ProfilePhoto.module.scss";
import LargeLoadingSpinner from "../../../../Components/LargeLoadingSpinner/LargeLoadingSpinner";
import RoundThumbnailImage from "../../../../Components/RoundThumbnailImage/RoundThumbnailImage";
import { loadImage } from "../../../../Utils/fileLoading";
import { PersonalProfileData } from "../../Settings";
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export function ProfilePhoto({
    profileData,
    setProfileData,
}: {
    profileData: PersonalProfileData;
    setProfileData: (profile: PersonalProfileData) => void;
}) {
    let [uploadingPhoto, setUploadingPhoto] = useState(false);
    const { postToApi } = usePostToAPI();
    return (
        <div className={styles["container"]}>
            <RoundThumbnailImage
                image={
                    profileData.profilePicture
                        ? profileData.profilePicture
                        : "/images/default-user-image.svg"
                }
                size={210}
            />
            <div className={styles["buttons"]}>
                <button
                    title="edit"
                    onClick={() =>
                        loadImage((file, url) => {
                            setUploadingPhoto(true);
                            postToApi(apiRoutes.GET_OR_UPDATE_USER, {
                                "profile-picture": file,
                            }).then((response) => {
                                setUploadingPhoto(false);
                                profileData &&
                                    response.ok &&
                                    setProfileData({
                                        ...profileData,
                                        profilePicture: url,
                                    });
                            });
                        })
                    }
                >
                    <AiOutlineEdit />
                </button>
                <a
                    title="view"
                    href={
                        profileData.profilePicture
                            ? profileData.profilePicture
                            : "/images/default-user-image.svg"
                    }
                >
                    <FaEye />
                </a>
            </div>
            {uploadingPhoto && (
                <div className={styles["uploading"]}>
                    <LargeLoadingSpinner
                        primaryColor="white"
                        secondaryColor="#fff3"
                    />
                </div>
            )}
        </div>
    );
}
