// useMarkerIcon.tsx
import { useMemo } from "react";
import L from "leaflet";
import { MinimalLocationData } from "../HomePageTypes";
import { UserAppearanceConfig } from "../../../types/shared";
import styles from "./LocationMarker.module.scss";

import { isNotVisible } from "../visibility";
import { generateRandomRGBValues } from "../../../Utils/utils";

export function useMarkerIcon(
    location: MinimalLocationData,
    appearanceConfig: UserAppearanceConfig | undefined
): L.DivIcon | L.Icon<L.IconOptions> | undefined {
  let size =
  (appearanceConfig?.location_marker_size || 50) +
  (isNotVisible(location.visibility) ? -5 : 0);

let [color1, color2] = useMemo(() => {
  let numbers1 = generateRandomRGBValues();
  let numbers2 = numbers1.map((n) => n - 40); // The hue of the second color has to be the same as the first color, but darker

  return [
      // Convert the numbers to hex and concatenate them as one string
      numbers1.reduce((prev, curr) => prev + curr.toString(16), ""),
      numbers2.reduce((prev, curr) => prev + curr.toString(16), ""),
  ];
}, []);

return location.mainPicture || isNotVisible(location.visibility)
  ? L.divIcon({
        html: `
    <div
      class="icon"
      style="
        background-image: url(${
            isNotVisible(location.visibility)
                ? "/images/unknown-contact.svg"
                : location.mainPicture
        });
        --size: ${size}px;
        --radius: ${size * 0.2}px;
        --border-size: ${
            isNotVisible(location.visibility) || !appearanceConfig
                ? 0
                : appearanceConfig.location_marker_border
        }px;
      "
    ></div>`,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, -(size / 2)],
        className: styles["location-marker-icon"],
    })
  : L.icon({
        iconUrl: `data:image/svg+xml,%3Csvg width='40px' height='40px' version='1.1' viewBox='0 0 88.319 119.34' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23${color2}' stroke-width='3'%3E%3Cpath d='m44.159 1.5c-23.56 9.7e-5 -42.659 14.906-42.659 42.659 0.0099 8.1668 3.2912 15.827 6.782 23.028 8.8832 18.323 35.317 49.844 35.317 49.844s27.323-31.392 36.438-49.844c3.5441-7.1742 6.7722-14.861 6.782-23.028 0-27.754-19.099-42.659-42.659-42.659z' fill='%23${color1}' /%3E%3Ccircle cx='44.159' cy='44.159' r='15.606' fill='%23fff' /%3E%3C/g%3E%3C/svg%3E`,
        // "data:image/svg+xml,%3Csvg width='40px' height='40px' version='1.1' viewBox='0 0 88.319 119.34' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23525184' stroke-width='3'%3E%3Cpath d='m44.159 1.5c-23.56 9.7e-5 -42.659 14.906-42.659 42.659 0.0099 8.1668 3.2912 15.827 6.782 23.028 8.8832 18.323 35.317 49.844 35.317 49.844s27.323-31.392 36.438-49.844c3.5441-7.1742 6.7722-14.861 6.782-23.028 0-27.754-19.099-42.659-42.659-42.659z' fill='%237d7dcd' /%3E%3Ccircle cx='44.159' cy='44.159' r='15.606' fill='%23fff' /%3E%3C/g%3E%3C/svg%3E",
        iconAnchor: [20, 40],
        popupAnchor: [0, -40],
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/images/marker-shadow.png",
        shadowSize: [41, 41],
        shadowAnchor: [13, 41],
    });}
