// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacySettings_privacy-settings__Fbi4I h2 {
  margin-top: 0;
}
.PrivacySettings_privacy-settings__Fbi4I .PrivacySettings_privacy-settings-container__6Dtew {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.PrivacySettings_privacy-settings__Fbi4I .PrivacySettings_privacy-settings-row__7UgWT {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  align-items: center;
  font-size: 14px;
}
.PrivacySettings_privacy-settings__Fbi4I .PrivacySettings_privacy-settings-label__zVakk {
  flex: 1 1;
}
.PrivacySettings_privacy-settings__Fbi4I .PrivacySettings_privacy-settings-dropdown__ok9ih {
  flex: 2 1;
}
.PrivacySettings_privacy-settings__Fbi4I button {
  display: block;
  margin-bottom: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
}
.PrivacySettings_privacy-settings__Fbi4I button:hover, .PrivacySettings_privacy-settings__Fbi4I button:focus {
  text-decoration: none;
}
.PrivacySettings_privacy-settings__Fbi4I .PrivacySettings_loading-spinner__5DPVX {
  margin-left: 10px;
}
.PrivacySettings_privacy-settings__Fbi4I p {
  margin: 0;
  margin-bottom: 1rem;
}
.PrivacySettings_privacy-settings__Fbi4I hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-top: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/PrivacySettings/PrivacySettings.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AADR;AAII;EACI,aAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAFR;AAKI;EACI,SAAA;AAHR;AAMI;EACI,SAAA;AAJR;AAOI;EACI,cAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;AALR;AAOQ;EAEI,qBAAA;AANZ;AAUI;EACI,iBAAA;AARR;AAWI;EACI,SAAA;EACA,mBAAA;AATR;AAYI;EACI,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,0BAAA;AAVR","sourcesContent":[".privacy-settings {\n    h2 {\n        margin-top: 0;\n    }\n\n    .privacy-settings-container {\n        display: flex;\n        flex-direction: column;\n        row-gap: 8px;\n    }\n\n    .privacy-settings-row {\n        display: flex;\n        flex-wrap: wrap;\n        column-gap: 16px;\n        align-items: center;\n        font-size: 14px;\n    }\n\n    .privacy-settings-label {\n        flex: 1;\n    }\n\n    .privacy-settings-dropdown {\n        flex: 2;\n    }\n\n    button {\n        display: block;\n        margin-bottom: 10px;\n        border: none;\n        background: none;\n        cursor: pointer;\n        font-size: 1rem;\n        font-weight: bold;\n        text-decoration: underline;\n\n        &:hover,\n        &:focus {\n            text-decoration: none;\n        }\n    }\n\n    .loading-spinner {\n        margin-left: 10px;\n    }\n\n    p {\n        margin: 0;\n        margin-bottom: 1rem;\n    }\n\n    hr {\n        margin-top: 1rem;\n        margin-bottom: 1rem;\n        border: none;\n        border-top: 1px solid #ccc;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy-settings": `PrivacySettings_privacy-settings__Fbi4I`,
	"privacy-settings-container": `PrivacySettings_privacy-settings-container__6Dtew`,
	"privacy-settings-row": `PrivacySettings_privacy-settings-row__7UgWT`,
	"privacy-settings-label": `PrivacySettings_privacy-settings-label__zVakk`,
	"privacy-settings-dropdown": `PrivacySettings_privacy-settings-dropdown__ok9ih`,
	"loading-spinner": `PrivacySettings_loading-spinner__5DPVX`
};
export default ___CSS_LOADER_EXPORT___;
