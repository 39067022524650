import React, { useRef } from "react";
import { ContactData } from "../../HomePageTypes";

export default function SocialLinks({
  updateContactField,
  editedContact,
}: {
  updateContactField: (
      field:
          | "facebook"
          | "instagram"
          | "twitter"
          | "youtube"
          | "linkedin"
          | "website"
  ) => (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  editedContact: ContactData;
}) {
  const facebookRef = useRef(null);
  const instagramRef = useRef(null);
  const twitterRef = useRef(null);
  const youtubeRef = useRef(null);
  const linkedinRef = useRef(null);
  const websiteRef = useRef(null);

  const handleNavigation = (
      event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
      fields: React.RefObject<HTMLInputElement | HTMLTextAreaElement>[]
  ) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
          const currentIndex = fields.findIndex(
              (field) => field.current && field.current === event.target
          );
          const direction = event.key === "ArrowUp" ? -1 : 1;
          const nextIndex =
              (currentIndex + direction + fields.length) % fields.length;

          if (fields[nextIndex].current?.focus) {
              fields[nextIndex].current?.focus();
          }
      }
  };

  return (
      <div>
          <input
              ref={facebookRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Facebook (optional)"
              title="Facebook (optional)"
              name="facebook"
              onInput={updateContactField("facebook")}
              value={editedContact.facebook}
              maxLength={256}
          />
          <input
              ref={instagramRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Instagram (optional)"
              title="Instagram (optional)"
              name="instagram"
              onInput={updateContactField("instagram")}
              value={editedContact.instagram}
              maxLength={256}
          />
          <input
              ref={twitterRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Twitter (optional)"
              title="Twitter (optional)"
              name="twitter"
              onInput={updateContactField("twitter")}
              value={editedContact.twitter}
              maxLength={256}
          />
          <input
              ref={linkedinRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Linkedin (optional)"
              title="Linkedin (optional)"
              name="linkedin"
              onInput={updateContactField("linkedin")}
              value={editedContact.linkedin}
              maxLength={256}
          />
          <input
              ref={youtubeRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Youtube (optional)"
              title="Youtube (optional)"
              name="youtube"
              onInput={updateContactField("youtube")}
              value={editedContact.youtube}
              maxLength={256}
          />
          <input
              ref={websiteRef}
              onKeyDown={(e) =>
                  handleNavigation(e, [
                      facebookRef,
                      instagramRef,
                      twitterRef,
                      linkedinRef,
                      youtubeRef,
                      websiteRef,
                  ])
              }
              placeholder="Website (optional)"
              title="Website (optional)"
              name="website"
              onInput={updateContactField("website")}
              value={editedContact.website}
              maxLength={256}
          />
      </div>
  );
}
