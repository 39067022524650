// used it for auth at first before I switched to a more secure way of dealing with auth

import { useCallback } from 'react'

// will leave it here as it might be useful in the future
export const useLocalStorage = () => {
  console.log('LOCAL STORAGE HOOK TRIGGERED')
  const getLocalData = useCallback((key: string) => {
    if (typeof window === 'undefined') return {}
    const localItem = localStorage.getItem(key)
    if (localItem !== undefined && localItem !== 'undefined') {
      return JSON.parse(localItem as string)
    } else {
      return {}
    }
  }, [])

  const setLocalData = useCallback((key: string, value: any) => {
    if (typeof window === 'undefined') return
    localStorage.setItem(key, JSON.stringify(value))
  }, [])

  const updateLocalData = useCallback((key: string, value: any) => {
    if (typeof window === 'undefined') return
    const data = getLocalData(key)
    const updatedData = { ...data, ...value }
    localStorage.setItem(key, JSON.stringify(updatedData))
  }, [])

  const removeLocalData = useCallback((key: string) => {
    if (typeof window === 'undefined') return
    localStorage.removeItem(key)
  }, [])
  return { getLocalData, setLocalData, updateLocalData, removeLocalData }
}