import React from "react";
import { CSSProperties } from "react";

export function CloseIcon({
  lineLength,
  lineWidth,
  color,
  margin,
}: {
  lineLength?: number;
  lineWidth?: number;
  color?: string;
  margin?: string;
}) {
  let innerDivStyle: CSSProperties = {
    position: "absolute",
    width: (lineWidth || 3) + "px",
    height: (lineLength || 20) + "px",
    backgroundColor: color || "red",
  };
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: (lineLength || 20) + "px",
        width: (lineLength || 20) + "px",
        margin,
      }}
    >
      <div style={{ ...innerDivStyle, transform: "rotate(45deg)" }}></div>
      <div style={{ ...innerDivStyle, transform: "rotate(-45deg)" }}></div>
    </div>
  );
}
