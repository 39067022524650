import React from "react";

import styles from "./CustomAlert.module.scss";
import { DialogBox } from "Components/DialogBox/DialogBox";

/**
 * Used to display a message to the user without using the
 * built-in `alert` function
 */
export default function CustomAlert({
  children,
  closeAlert,
}: {
  /**
   * Any thing(s) to display inside the alert box
   */
  children: React.ReactNode | Array<React.ReactNode>;
  closeAlert: () => void;
}) {
  return (
    <DialogBox close={closeAlert}>
      <p className={styles.paragraph}>{children}</p>
      <div className={styles["custom-alert-button-container"]}>
        <button onClick={closeAlert}>Ok</button>
      </div>
    </DialogBox>
  );
}
