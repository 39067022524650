import React from 'react';
import TextInput from 'Components/forms/TextInput';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TOnboardingPayload } from 'types/shared';

interface FirstNameInputProps {
    register: UseFormRegister<TOnboardingPayload>;
    defaultValue?: string;
    errors: FieldErrors<TOnboardingPayload>;
}

const FirstNameInput: React.FC<FirstNameInputProps> = ({ register, defaultValue, errors }) => {
    return (
        <TextInput
            register={register}
            inputName='first_name'
            errors={errors}
            placeholder='Enter your first name'
            inputLabel='First Name:'
            emptyErrorMessage='First name is required'
            defaultValue={defaultValue || ''}
        />
    );
};

export default FirstNameInput;
