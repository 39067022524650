import { UserAppearanceConfig } from "../../../types/shared";

/**
 * Used to tell when the user's current appearance settings
 * are different from the default ones. It can also be used
 * to reset those values
 */
export const defaultAppearanceSettings: UserAppearanceConfig = {
    user_marker_size: 50,
    user_marker_border: 2,
    connection_color_1: "#6eed89",
    connection_color_2: "#065b18",
    stranger_color_1: "#ff0000",
    stranger_color_2: "#500000",
    contact_marker_size: 46,
    location_marker_size: 50,
    location_marker_border: 2,
    cluster_color: "#0bb12f",
    cluster_size: 50,
    cluster_threshold: 20
};
