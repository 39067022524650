// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OptionsList_options-list__q\\+\\+Bb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px 0;
}
.OptionsList_options-list__q\\+\\+Bb h6 {
  cursor: pointer;
  margin-right: 20px;
}
.OptionsList_options-list__q\\+\\+Bb label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 7px;
  cursor: pointer;
}
.OptionsList_options-list__q\\+\\+Bb label .OptionsList_checkbox__gk1Kr {
  transform: scale(0.85);
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/OptionsList/OptionsList.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;AACJ;AACI;EACI,eAAA;EACA,kBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AAAR;AAEQ;EACI,sBAAA;EACA,iBAAA;AAAZ","sourcesContent":[".options-list {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    flex-wrap: wrap;\n    margin: 10px 0;\n\n    h6 {\n        cursor: pointer;\n        margin-right: 20px;\n    }\n\n    label {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        margin: 3px 7px;\n        cursor: pointer;\n\n        .checkbox {\n            transform: scale(0.85);\n            margin-right: 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options-list": `OptionsList_options-list__q++Bb`,
	"checkbox": `OptionsList_checkbox__gk1Kr`
};
export default ___CSS_LOADER_EXPORT___;
