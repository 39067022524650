// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsAccordion_accordion__ZZQ7O {
  margin: 0 16px;
  padding: 0 32px 16px 32px;
  background-color: white;
  border-radius: 4px;
  box-shadow: var(--shadow);
}
@media screen and (max-width: 600px) {
  .SettingsAccordion_accordion__ZZQ7O {
    margin: 0;
    padding: 0 16px 16px 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/SettingsAccordion/SettingsAccordion.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,yBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACI;EAPJ;IAQQ,SAAA;IACA,yBAAA;EAEN;AACF","sourcesContent":[".accordion {\n    margin: 0 16px;\n    padding: 0 32px 16px 32px;\n    background-color: white;\n    border-radius: 4px;\n    box-shadow: var(--shadow);\n\n    @media screen and (max-width: 600px) {\n        margin: 0;\n        padding: 0 16px 16px 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `SettingsAccordion_accordion__ZZQ7O`
};
export default ___CSS_LOADER_EXPORT___;
