// MentionSuggestionsComponent.tsx
import React, { useCallback, useState } from 'react';
import { EditorJournalEntry } from './EditorJournalEntry'; // Adjust the path as necessary
import { useSearchChangeHandler } from './SearchChangeHandler'; // Adjust the path as necessary

export interface MentionSuggestionsProps {
  plugins: any[]; // Replace 'any' with the specific type
  suggestions: JournalTemplate[];
  setSuggestions: React.Dispatch<React.SetStateAction<JournalTemplate[]>>;
  extractMentions: () => JournalTemplate[];
}


export interface JournalTemplate {
	name: string;
	avatar: string;
	id: number;
	type: 'contact' | 'connection' | 'place';
	profile?: any;
}
const MentionSuggestionsComponent: React.FC<MentionSuggestionsProps> = ({
  plugins, 
  suggestions, 
  setSuggestions,
  extractMentions
}) => {    const [open, setOpen] = useState(false);

  const onOpenChange = useCallback((_open: boolean) => {
      setOpen(_open);
  }, []);

  const onSearchChange = useSearchChangeHandler(setSuggestions, extractMentions);

  const { MentionSuggestions } = plugins[0]; // Assuming the first plugin is the mentionPlugin

  return (
      <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
              setSuggestions([]);
          }}
          entryComponent={EditorJournalEntry}
      />
  );
};


export default MentionSuggestionsComponent;
