// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AllUserStatsCounter.module.scss */
.AllUserStatsCounter_loading-info__xG0lM {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}

.AllUserStatsCounter_error-info__fmwdF {
  color: #ff0000;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}

.AllUserStatsCounter_data-point__4nWUC {
  min-width: 100px; /* Set the minimum width to 100px */
  max-width: 250px; /* Set the maximum width to 250px */
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}

.AllUserStatsCounter_label__k\\+4fy {
  font-weight: bold;
  margin-bottom: 5px; /* Add margin between label and count (adjust as needed) */
}

.AllUserStatsCounter_count__rGpuj {
  font-size: 18px;
  color: #007bff;
}

.AllUserStatsCounter_all-stats-container__zKMZX {
  display: flex;
  flex-wrap: wrap; /* Allow elements to wrap to the next row when needed */
  gap: 20px; /* Add gap between data points (adjust as needed) */
  justify-content: center; /* Center align data points horizontally */
}`, "",{"version":3,"sources":["webpack://./src/Components/AllUserStatsCounter/AllUserStatsCounter.module.scss"],"names":[],"mappings":"AAAA,oCAAA;AAEA;EACE,WAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA,EAAA,mCAAA;EACA,gBAAA,EAAA,mCAAA;EACA,aAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;;AAIA;EACE,iBAAA;EACA,kBAAA,EAAA,0DAAA;AADF;;AAIA;EACE,eAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA,EAAA,uDAAA;EACA,SAAA,EAAA,mDAAA;EACA,uBAAA,EAAA,0CAAA;AADF","sourcesContent":["/* AllUserStatsCounter.module.scss */\n\n.loading-info {\n  color: #333;\n  font-size: 16px;\n  font-weight: bold;\n  margin: 20px 0;\n}\n\n.error-info {\n  color: #ff0000;\n  font-size: 16px;\n  font-weight: bold;\n  margin: 20px 0;\n}\n\n.data-point {\n  min-width: 100px; /* Set the minimum width to 100px */\n  max-width: 250px; /* Set the maximum width to 250px */\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #ccc;\n  padding: 10px 15px;\n  background-color: #fff;\n  border-radius: 5px;\n  text-align: center;\n}\n\n\n.label {\n  font-weight: bold;\n  margin-bottom: 5px; /* Add margin between label and count (adjust as needed) */\n}\n\n.count {\n  font-size: 18px;\n  color: #007bff;\n}\n\n.all-stats-container {\n  display: flex;\n  flex-wrap: wrap; /* Allow elements to wrap to the next row when needed */\n  gap: 20px; /* Add gap between data points (adjust as needed) */\n  justify-content: center; /* Center align data points horizontally */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-info": `AllUserStatsCounter_loading-info__xG0lM`,
	"error-info": `AllUserStatsCounter_error-info__fmwdF`,
	"data-point": `AllUserStatsCounter_data-point__4nWUC`,
	"label": `AllUserStatsCounter_label__k+4fy`,
	"count": `AllUserStatsCounter_count__rGpuj`,
	"all-stats-container": `AllUserStatsCounter_all-stats-container__zKMZX`
};
export default ___CSS_LOADER_EXPORT___;
