// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContextMenu_context-menu__r\\+Wgv {
  position: fixed;
  background-color: rgb(250, 247, 253);
  display: flex;
  flex-direction: column;
  z-index: 1000000;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: var(--shadow);
  overflow: hidden;
}
.ContextMenu_context-menu__r\\+Wgv > * {
  background-color: transparent;
  border: none;
  display: block;
  text-decoration: none;
  padding: 10px 20px;
  width: 100%;
  color: rgb(54, 54, 54);
  text-align: left;
  font-weight: 400;
}
.ContextMenu_context-menu__r\\+Wgv > *:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(54, 54, 54);
}
.ContextMenu_context-menu__r\\+Wgv > *:active {
  background-color: rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/Components/ContextMenu/ContextMenu.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,oCAAA;EAEA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,6BAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AAAR;AAGI;EACI,oCAAA;EACA,sBAAA;AADR;AAII;EACI,oCAAA;AAFR","sourcesContent":[".context-menu {\n    position: fixed;\n    background-color: rgb(250, 247, 253);\n    // padding: 5px;\n    display: flex;\n    flex-direction: column;\n    z-index: 1000000;\n    align-items: flex-start;\n    border-radius: 10px;\n    box-shadow: var(--shadow);\n    overflow: hidden;\n\n    >* {\n        background-color: transparent;\n        border: none;\n        display: block;\n        text-decoration: none;\n        padding: 10px 20px;\n        width: 100%;\n        color: rgb(54, 54, 54);\n        text-align: left;\n        font-weight: 400;\n    }\n\n    >*:hover {\n        background-color: rgba(0, 0, 0, 0.1);\n        color: rgb(54, 54, 54);\n    }\n\n    >*:active {\n        background-color: rgba(0, 0, 0, 0.2);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"context-menu": `ContextMenu_context-menu__r+Wgv`
};
export default ___CSS_LOADER_EXPORT___;
