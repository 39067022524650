export default function JournalIcon({
	className,
	width,
	height,
}: {
	className: string;
	width: string;
	height: string;
}) {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox="0 0 59.384495 76.020683"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path
					fill="currentColor"
					d="M 9.690365,0 C 4.338496,-6.6999224e-5 -6.6998448e-5,4.3384958 0,9.6903648 V 66.33032 c -6.4998448e-5,5.35187 4.338498,9.69043 9.690365,9.69036 H 59.384494 V 14.698845 H 9.690365 c -2.766107,10e-7 -5.008481,-2.242373 -5.00848,-5.0084802 -1e-6,-2.766107 2.242373,-5.008481 5.00848,-5.00848 H 59.384494 V 0 Z m 24.325649,23.05234 h 17.70744 v 29.85658 l -8.94467,-7.81721 -8.76277,7.63582 z"
				/>
			</g>
		</svg>
	);
}
