import { useRef, useReducer } from "react";
import {
    MapContainer,
    TileLayer,
} from "react-leaflet";
import { Redirect } from "react-router-dom";
import { latLng } from "leaflet";

import styles from "./Home.module.scss";

import { getSearchQueryString, SearchForm } from "./SearchForm/SearchForm";
import { getTileLayerProps } from "./FloatingControls/TileLayerSelector/TileLayerSelector";
import { FloatingControls } from "./FloatingControls/FloatingControls";

import { useXYZParameters } from "hooks/map/useXYZParameters";
import { useMapRef } from "hooks/map/useMapRef";
import { useOpenPopupParameters } from "hooks/map/useOpenPopupParameters";
import { useTileLayers } from "hooks/map/useTileLayers";
import { useCoreMapData } from "hooks/map/useCoreMapData";


import { getInitialState, reducer } from "./reducer";
import { sixDecimalPlaces } from "Utils/utils";


import UserOnboarding from "Components/OnboardingExperience/UserOnboarding";
import { MyGpsPosition } from "./MyGpsPosition/MyGpsPosition";
import { useDocumentTitle } from "hooks/useSEO";
import { useRouter } from "hooks/useRouter";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

import DummyMarker from "./DummyMarker/DummyMarker"
import ContextMenuCloser from './ContextMenuCloser/ContextMenuCloser';
import ContextMenuComponent from './ContextMenuManager/ContextMenuManager';
import MapMarkers from "./MapMarkers/MapMarkers";
import EditMarkers from "./EditMarkers/EditMarkers";
import AddActions from "./AddActions/AddActions";
import LoadingSpinnerAlert from "./LoadingSpinnerAlert/LoadingSpinnerAlert";
import MapZoomHandler from './MapZoomHandler/MapZoomHandler';
import MapSearchBoundsHandler from './MapSearchBoundsHandler/MapSearchBoundsHandler';

/**
 * The Homepage
 */
export default function Home() {
    useDocumentTitle("Home");

    const { query } = useRouter();
    // URL params
    let searchParameter = query.get("search");
    let searchForParameter = query.get("searchFor");
    let { zoomToLatitude, zoomToLongitude, zoomLevel } = useXYZParameters();
    let { currentUser } = useCurrentUserDetails();

    // Main homepage state
    let [state, dispatch] = useReducer(
        reducer,
        getInitialState(searchParameter, searchForParameter)
    );

    // Various variables
    let { mapRef, mapRefCallback } = useMapRef(dispatch);
    let featureGroupRef = useRef<L.FeatureGroup>(null);
    let isSearching = state.searchInput.trim() !== "";
    let tileLayerRef = useTileLayers(mapRef, state.tileLayer);


    useCoreMapData(
        state.mapBounds,
        state.selectedLayers,
        dispatch,
        state.searchInput.trim() !== ""
    );


    useOpenPopupParameters(dispatch);


    return (
        <div
            className={`${styles.main} ${
                // Show the mouse cursor as a crosshair when intending to
                // add a new place or contact by clicking on the map
                state.addingCoordinates ? styles["map-cursor-crosshair"] : ""
                }`}
        >
            <ContextMenuCloser dispatch={dispatch} />

            <Redirect
                to={
                    `/` +
                    (isSearching
                        ? getSearchQueryString(
                            state.searchInput,
                            state.searchFor
                        )
                        : state.mapPosition
                            ? `?x=${sixDecimalPlaces(
                                state.mapPosition.x
                            )}&y=${sixDecimalPlaces(state.mapPosition.y)}&z=${state.mapPosition.zoomLevel
                            }`
                            : "")
                }
                push={false}
            />
            <UserOnboarding breakpoint="lg" />

            <ContextMenuComponent
                contextMenuData={state.contextMenu}
                dispatch={dispatch}
                state={state}
            />

            <MapContainer
                    center={[
                        zoomToLatitude ?? (currentUser?.coordinates?.y ?? 0),
                        zoomToLongitude ?? (currentUser?.coordinates?.x ?? 0),
                    ]}
                    zoom={
                        zoomLevel !== null
                            ? zoomLevel
                            : zoomToLatitude || zoomToLongitude || currentUser
                                ? 12
                                : 2
                    }
                    className={styles["map-container"]}
                    ref={mapRefCallback}
                >
                <DummyMarker 
                    dispatch={dispatch} />
                <TileLayer
                    {...getTileLayerProps(state.tileLayer)}
                    ref={tileLayerRef}
                />

                <MapMarkers
                    state={state} 
                    dispatch={dispatch} 
                    featureGroupRef={featureGroupRef} />


                <MyGpsPosition state={state} />

                <EditMarkers 
                    state={state} 
                    dispatch={dispatch} />
                <MapZoomHandler
                    zoomToLatitude={zoomToLatitude}
                    zoomToLongitude={zoomToLongitude}
                    zoomLevel={zoomLevel}
                    mapRef={mapRef}
                />
                <MapSearchBoundsHandler
                    isSearching={isSearching}
                    state={state}
                    dispatch={dispatch}
                    mapRef={mapRef}
                    featureGroupRef={featureGroupRef}
                    />
            </MapContainer>
            <SearchForm
                state={state}
                dispatch={dispatch}
                searchParameter={searchParameter}
                searchForParameter={searchForParameter}
            />
            <FloatingControls
                state={state} 
                dispatch={dispatch} />

            <AddActions
                state={state}
                dispatch={dispatch}
                mapRef={mapRef}
                latLng={latLng} />


            <LoadingSpinnerAlert state={state} dispatch={dispatch} />

        </div>
    );
}
