import React, { useCallback, useRef, useEffect } from 'react';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import L from 'leaflet';

import { Position, DispatchAction } from './reducer';

import { MinimalUserData } from '../Home/HomePageTypes';
import { getMarkerIcon } from '../Home/UserMarker/UserMarker';
import { apiInstance } from 'services/apiInstance';
import { apiRoutes } from 'services/routes';
import { ApiError } from 'hooks/useAPI';

// map ref for read only map
function useMapRef(dispatch: React.Dispatch<DispatchAction>) {
	let mapRef = useRef<L.Map | null>(null);

	const updateMapBoundsAndPosition = useCallback(() => {
		if (mapRef.current) {
			dispatch({ type: 'mapBounds', value: mapRef.current.getBounds() });
			let mapCenter = mapRef.current.getCenter();
			dispatch({
				type: 'initialPosition',
				value: {
					x: mapCenter.lng,
					y: mapCenter.lat,
					zoomLevel: mapRef.current.getZoom(),
				},
			});
		}
	}, [dispatch]);

	let mapRefCallback = useCallback(
		(map: L.Map | null) => {
			if (map) {
				map.zoomControl.setPosition('bottomleft');
				map.addEventListener('resize', updateMapBoundsAndPosition);
				map.addEventListener('dragend', updateMapBoundsAndPosition);
				map.addEventListener('moveend', updateMapBoundsAndPosition);
				map.addEventListener('zoomend', updateMapBoundsAndPosition);
				map.addEventListener('contextmenu', (event) => {});
			}
			mapRef.current = map;
			updateMapBoundsAndPosition();
		},
		[dispatch, updateMapBoundsAndPosition]
	);

	return { mapRef, mapRefCallback };
}

/*
 * ReadOnlyMap: This component is used to render view only maps in the landing page, where unauthenticated user can get an idea of the product
 * We also show some activity stream
 * @returns
 */

function ReadOnlyMap({
	centerPosition,
	mapBounds,
	userProfiles,
	dispatch,
}: {
	centerPosition: Position;
	mapBounds: L.LatLngBounds | null;
	userProfiles: Array<MinimalUserData> | null | ApiError;
	dispatch: React.Dispatch<DispatchAction>;
}) {
	let { mapRef, mapRefCallback } = useMapRef(dispatch);

	useEffect(() => {
		getUserData();
	}, [mapBounds]);

	async function getUserData() {
		try {
			const res = await apiInstance.get(
				apiRoutes.GET_READ_ONLY_USERS(
					mapBounds
						? 'bbox=' + mapBounds.toBBoxString()
						: 'bbox=-213.40170009965163,-82.98345103001317,104.76236240034842,71.07393052077796'
				)
			);
			if (res.status === 200) {
				dispatch({
					type: 'userProfiles',
					value: res.data.data	,
				});
			}
		} catch (reason) {
			console.log(reason);
		}
	}

	return (
		<>
			<MapContainer
				className="h-[95vh] shadow-md rounded-[30px]"
				center={[centerPosition.x, centerPosition.y]}
				zoom={2}
				scrollWheelZoom={false}
				ref={mapRefCallback}
			>
				<TileLayer
					{...{
						url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
						attribution:
							'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
						subdomains: 'abcd',
						minZoom: 2,
						maxZoom: 20,
					}}
				/>
				{userProfiles instanceof Array &&
					userProfiles.map((profile) =>
						profile.coordinates ? (
							<Marker
								position={[profile.coordinates.y, profile.coordinates.x]}
								// icon={Icon}
								key={profile.id}
								icon={getMarkerIcon(profile)}
								riseOnHover
							>
								<Popup>
									<h5 className="p-4">
										<b>Name:</b> {profile.name}
									</h5>
								</Popup>
							</Marker>
						) : null
					)}

				{/* <Marker position={position}>
                        </Marker> */}
			</MapContainer>
			{/* <img className='h-[90vh] px-4 rounded-[15rem] mt-4' src="https://images.unsplash.com/photo-1532154066703-3973764c81fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bWFwfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60" alt="image" /> */}
		</>
	);
}

export default ReadOnlyMap;
