import React, { useState } from "react";
import styles from "./PrivacySettings.module.scss";
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import { PrivacyLevelWithPrivate, UserPrivacy } from "../Settings";
import { Link } from "react-router-dom";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { useDebouncedAction } from "hooks/useDebounce";
import { apiRoutes } from "services/routes";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { useRouter } from "hooks/useRouter";
import {
	SettingsAccordion,
} from "../SettingsAccordion/SettingsAccordion";

const PrivacySettings = ({
	privacySettings,
	savePrivacySettings,
}: {
	privacySettings: UserPrivacy;
	savePrivacySettings: (newValue: UserPrivacy) => void;
}) => {
	const [savingData, setSavingData] = useState(false);
	let delayRequest = useDebouncedAction();
	const router = useRouter();
	type CategoryVisibility = {
		[key: string]: boolean;
	};

	const [categoryVisibility, setCategoryVisibility] =
		useState<CategoryVisibility>({
			"Basic Info": false,
			"User Details": false,
			"Social Media": false,
		});

	const toggleCategoryVisibility = (category: string): void => {
		setCategoryVisibility({
			...categoryVisibility,
			[category]: !categoryVisibility[category],
		});
	};

	const categories = ["Basic Info", "User Details", "Social Media"];

	type PrivacyFieldData = {
		fieldName: keyof UserPrivacy;
		labelText: string;
		items: Array<PrivacyLevelWithPrivate>;
		category: string;
	};

	const privacyFields: Array<PrivacyFieldData> = [
		{
			fieldName: "name",
			labelText: "Name",
			items: ["Share with connections", "Public"],
			category: "Basic Info",
		},
		{
			fieldName: "age",
			labelText: "Age",
			items: ["Share with connections", "Public", "Private"],
			category: "Basic Info",
		},
		{
			fieldName: "bio",
			labelText: "Bio",
			items: ["Share with connections", "Public"],
			category: "Basic Info",
		},
		{
			fieldName: "profilePicture",
			labelText: "Profile picture",
			items: ["Share with connections", "Public"],
			category: "Basic Info",
		},
		{
			fieldName: "occupation",
			labelText: "Occupation",
			items: ["Share with connections", "Public"],
			category: "Basic Info",
		},
		{
			fieldName: "email",
			labelText: "Email",
			items: ["Share with connections", "Public", "Private"],
			category: "User Details",
		},
		{
			fieldName: "phoneNumber",
			labelText: "Phone number",
			items: ["Share with connections", "Public", "Private"],
			category: "User Details",
		},
		{
			fieldName: "skills",
			labelText: "Skills",
			items: ["Share with connections", "Public", "Private"],
			category: "User Details",
		},
		{
			fieldName: "objects",
			labelText: "Objects",
			items: ["Share with connections", "Public", "Private"],
			category: "User Details",
		},
		{
			fieldName: "facebook",
			labelText: "Facebook",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		{
			fieldName: "instagram",
			labelText: "Instagram",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		{
			fieldName: "linkedin",
			labelText: "Linkedin",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		{
			fieldName: "youtube",
			labelText: "Youtube",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		{
			fieldName: "website",
			labelText: "Website",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		{
			fieldName: "twitter",
			labelText: "Twitter",
			items: ["Share with connections", "Public"],
			category: "Social Media",
		},
		// ...
	];

	type PrivacySettingFieldProps = {
		fieldName: string;
		labelText: string;
		items: string[];
		disabled: boolean;
		onChange: (value: string) => void;
		selected: string;
	};

	const PrivacySettingField: React.FC<PrivacySettingFieldProps> = ({
		fieldName,
		labelText,
		items,
		disabled,
		onChange,
		selected,
	}) => (
		<div className={styles["privacy-settings-row"]}>
			<div className={styles["privacy-settings-label"]}>{labelText}:</div>
			<div className={styles["privacy-settings-dropdown"]}>
				<CustomDropdown
					disabled={disabled}
					items={items}
					onChange={onChange}
					selected={selected}
				/>
			</div>
		</div>
	);

	const authInstance = useAxiosPrivate();

	const updatePrivacyField =
		(field: keyof UserPrivacy) => (newValue: string) => {
			let newData = { [field]: newValue };
			delayRequest(() => {
				setSavingData(true);
				authInstance
					.patch(apiRoutes.UPDATE_PRIVACY, newData)
					.then(() => setSavingData(false));
			}, 1000);
			savePrivacySettings({ ...privacySettings, ...newData });
		};

	return (
		<section className={styles["privacy-settings"]}>
			<SettingsAccordion>
				<h3>
					Privacy settings{" "}
					{savingData && (
						<SmallLoadingSpinner linethickNess="3px" />
					)}
				</h3>
				<>
					{categories.map((category) => (
						<div key={category}>
							<h3>
								<button
									type="button"
									onClick={() =>
										toggleCategoryVisibility(category)
									}
								>
									{categoryVisibility[category]
										? `Hide ${category}`
										: `Show ${category}`}
								</button>
							</h3>
							{categoryVisibility[category] &&
								privacyFields
									.filter(
										(field) =>
											field.category === category
									)
									.map((field) => (
										<PrivacySettingField
											key={field.fieldName}
											fieldName={field.fieldName}
											labelText={field.labelText}
											items={field.items}
											disabled={!privacySettings}
											onChange={updatePrivacyField(
												field.fieldName
											)}
											selected={
												privacySettings[
												field.fieldName
												]
											}
										/>
									))}
						</div>
					))}
					<br />
					<Link to="/settings/manage-permissions">
						Manage contact and place permissions you have handed out
					</Link>
					<br />
				</>
			</SettingsAccordion>
		</section>
	);
};

export default PrivacySettings;
