import React, { CSSProperties, useState } from "react";

import styles from "./AutocompleteInput.module.scss";

export default function AutocompleteInput({
  value,
  setValue,
  submitValue,
  autocompleteSuggestions,
  placeholder,
  padding,
  borderRadius,
  submitOnBlur,
  margin,
  closeInput,
  containerClassname,
  inputClassname,
  autoFocus,
  style,
}: {
  value?: string;
  placeholder?: string;
  setValue?: (newValue: string) => void;
  autocompleteSuggestions?: any[];
  /** Used to signal that the user has finished carrying out their input */
  submitValue?: (value: any) => void;
  padding?: string;
  borderRadius?: string;
  /** If true, then the current text value will be submitted when the text input loses focus */
  submitOnBlur?: boolean;
  margin?: string;
  closeInput?: () => void;
  inputClassname?: string;
  containerClassname?: string;
  autoFocus?: boolean;
  style?: CSSProperties;
}) {
  let [showSuggestions, setShowSuggestions] = useState(false);
  let [highlightedOption, setHighlightedOption] = useState(-1);

  let onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (highlightedOption >= 0) {
        if (autocompleteSuggestions) {
          setValue && setValue(autocompleteSuggestions[highlightedOption]);
          submitValue &&
            submitValue(autocompleteSuggestions[highlightedOption]);
        }
        setHighlightedOption(-1);
      } else {
        if (submitValue && value) {
          submitValue(value);
        }
      }
    } else if (event.key === "Escape") {
      closeInput && closeInput();
    } else if (event.key === "ArrowUp" && highlightedOption >= 0) {
      event.preventDefault();
      setHighlightedOption(highlightedOption - 1);
    } else if (
      event.key === "ArrowDown" &&
      autocompleteSuggestions &&
      highlightedOption < autocompleteSuggestions.length - 1
    ) {
      event.preventDefault();
      setHighlightedOption(highlightedOption + 1);
    }
  };

  return (
    <label
      className={`
        ${styles["container"]} ${
        showSuggestions &&
        autocompleteSuggestions &&
        autocompleteSuggestions.length > 0
          ? styles["showing-suggestions"]
          : ""
      } ${containerClassname || ""}
          `}
      style={
        {
          "--padding": padding,
          "--border-radius": borderRadius,
          margin,
        } as CSSProperties
      }
    >
      <input
        className={`${styles["input"]} ${inputClassname || ""}`}
        value={value}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        onInput={(event) => {

          setValue && setValue(event.currentTarget.value)
        }}
        onKeyDown={onKeyDown}
        onFocus={() => setTimeout(() => setShowSuggestions(true), 500)}
        /* A timeout is used to allow the user to click an option before the suggestions list disappears */
        onBlur={() =>
          setTimeout(() => {
            setShowSuggestions(false);
            if (submitOnBlur && submitValue && value) {
              submitValue(value);
            }
          }, 300)
        }
        style={style}
      />
      {autocompleteSuggestions && autocompleteSuggestions.length > 0 && (
        <div className={styles["suggestions"]}>
          {autocompleteSuggestions.map((suggestion, index) => (
            <div
              className={
                highlightedOption === index ? styles["highlighted"] : undefined
              }
              onClick={() => {
                setValue && setValue(suggestion);
                submitValue && submitValue(suggestion);
              }}
            >
              {suggestion?.display_name}
            </div>
          ))}
        </div>
      )}
    </label>
  );
}
