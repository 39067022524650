import React, { useState } from 'react';
import { calculateDaysUntilContact, parseHumanTimeToDays } from 'Pages/Settings/ViewKeepInTouch/calculateDaysUntilContact';
import IconIndicator from './IconIndicator'; // Adjust the path as needed

interface KeepInTouchProps {
  data?: {
    minimum_time_input: string | number;
    minimum_time_type: string;
    maximum_time_input: string | number;
    maximum_time_type: string;
    reason_for_contact: string;
    contact_timestamp?: string;
  } | null;
}


const KeepInTouchComponent = React.memo(({ data }: KeepInTouchProps) => {

  if (!data) {
    return null;
  }
  const reason = data.reason_for_contact || '';
  const timestamp = data.contact_timestamp || 'default-timestamp-value';

  const minDaysLeft = calculateDaysUntilContact(
    timestamp,
    `${data.minimum_time_input} ${data.minimum_time_type}`
  );

  const maxDaysLeft = calculateDaysUntilContact(
    timestamp,
    `${data.maximum_time_input} ${data.maximum_time_type}`
  );

  const minDaysTotal = parseHumanTimeToDays(
    `${data.minimum_time_input} ${data.minimum_time_type}`
  );

  const maxDaysTotal = parseHumanTimeToDays(
    `${data.maximum_time_input} ${data.maximum_time_type}`
  );

  const createdDaysAgo = Math.abs(
    calculateDaysUntilContact(timestamp, "0 Days")
  );

  const presentDays = (createdDaysAgo === 0) ? createdDaysAgo :
                      (minDaysLeft < 0) ? createdDaysAgo : createdDaysAgo;

  return (
    <div>
      <IconIndicator 
        createdDaysAgo={createdDaysAgo}
        minDays={minDaysTotal}
        minDaysLeft={minDaysLeft}
        maxDays={maxDaysTotal}
        maxDaysLeft={maxDaysLeft}
        presentDays={presentDays}
        reason={reason}
      />
    </div>
  );
});

export default KeepInTouchComponent;
