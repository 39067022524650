
import { LocationData, MinimalLocationData } from "../../HomePageTypes";

import { PostToApiFnType } from "hooks/useAPI";
import { apiRoutes } from "services/routes";


export function handleLocationConsentRequest(
  updatelocation: (location: MinimalLocationData | LocationData) => void,
  location: MinimalLocationData | LocationData,
  postToApi: PostToApiFnType
) {
  const handleRequest = (
      method: "post" | "delete",
      setVisibilityTo: "requested" | "needs request"
  ) => {
      updatelocation({ ...location, visibility: "loading" });
      postToApi(
          apiRoutes.CREATE_OR_DELETE_PLACE_SHARING_REQUEST(location.id),
          undefined,
          method
      ).then((response) =>
          response.ok
              ? updatelocation({ ...location, visibility: setVisibilityTo })
              : updatelocation({
                    ...location,
                    visibility: location.visibility,
                })
      );
  };

  if (location.visibility === "needs request") {
      handleRequest("post", "requested");
  } else if (location.visibility === "requested") {
      handleRequest("delete", "needs request");
  }
}

