import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import CustomButton from 'Components/CustomButton/CustomButton'
import TextInput from 'Components/forms/TextInput'
import { TSignInForm } from 'types/shared'
import { apiRoutes } from '../../services/routes'
import { useAuth } from '../../hooks/useAuth'

const PasswordResetForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TSignInForm>()
  const { handlePasswordReset } = useAuth()
  const onSubmit: SubmitHandler<TSignInForm> = data => {
    handlePasswordReset?.(data)
  }

  return (
    <div className='h-screen w-screen'>
      <div className='flex items-center justify-center h-screen p-20'>
        <div className='flex flex-col space-y-4 shadow-xl p-3 md:w-[350px]'>
          <h1 className='text-3xl text-center'>Password Reset</h1>
          <small className='text-center'>
            Enter your user account's verified email address and we will send
            you a password reset link.
          </small>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col space-y-4 '
          >
            <TextInput
              register={register}
              inputName='email'
              errors={errors}
              placeholder='E-mail address'
              inputLabel='E-mail:'
              emptyErrorMessage='E-mail is required'
              formType='email'
            />

            <CustomButton buttonType='submit' variant='primary'>
              RESET MY PASSWORD
            </CustomButton>
          </form>
          <div className='text-center text-xs '>
            <a href='/'>Home</a> /<a href={apiRoutes.SIGNIN}> Log In</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetForm