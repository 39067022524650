import { Link } from "react-router-dom";
import { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { BiUserPlus } from "react-icons/bi";

import styles from "./ManageConnections.module.scss";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import { UserData } from "Pages/Home/HomePageTypes";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import SettingsSubpage from "../SettingsSubpage/SettingsSubpage";
import { CloseIcon } from "Icons/CloseIcon";
import { DialogBox } from "Components/DialogBox/DialogBox";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import LargeLoadingSpinner from "Components/LargeLoadingSpinner/LargeLoadingSpinner";
import { useDocumentTitle } from "hooks/useSEO";
import InviteOthers from "./InviteOthers/InviteOthers";

import AvailableInvitesCounter from "Components/AvailableInvitesCounter/AvailableInvitesCounter";

export default function ManageConnections({}: {}) {
    useDocumentTitle("Manage Connections");

    let [users, setUsers] = useApi<{ connections: Array<UserData> }>(
        apiRoutes.GET_CONNECTIONS
    );
    let [endConnectionDialog, setEndConnectionDialog] = useState<{
        user: UserData;
    } | null>(null);
    let [idsOfPendingUsers, setIdsOfPendingUsers] = useState<Array<number>>([]);
    let [showInvitation, setShowInvitation] = useState(false);

    const closeDialog = () => setEndConnectionDialog(null);

    let { postToApi } = usePostToAPI();

    const confirmEndingConnection = () => {
        if (endConnectionDialog) {
            let user = endConnectionDialog.user;
            closeDialog();
            setIdsOfPendingUsers((IDs) => [...IDs, user.id]);
            postToApi(
                `${apiRoutes.ACCEPT_REJECT_END_CONNECTION_REQUESTS(
                    user.handle
                )}?reason=end_connection`,
                {},
                "delete"
            )
                .then((response) => {
                    if (response.ok) {
                        setUsers((users) =>
                            users && !(users instanceof ApiError)
                                ? {
                                      connections: users.connections.filter(
                                          (u) => u.id !== user.id
                                      ),
                                  }
                                : users
                        );
                    }
                    toast(
                        `Your connection with ${user.name} was succesfully ended`,
                        { type: "success" }
                    );
                })
                .finally(() =>
                    setIdsOfPendingUsers((IDs) =>
                        IDs.filter((value) => value === user.id)
                    )
                );
        }
    };

    return (
        <SettingsSubpage
            title="Manage Connections"
            addToHeading={
                <button
                    className="btn btn-tertiary"
                    style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    onClick={() => setShowInvitation(true)}
                >
                    <BiUserPlus /> &nbsp; Invite others
                </button>
            }
        >
            <AvailableInvitesCounter/>

            {endConnectionDialog ? (
                <EndConnectionDialog
                    closeDialog={closeDialog}
                    confirmEndingConnection={confirmEndingConnection}
                />
            ) : null}
            {showInvitation ? (
                <InviteOthers closeDialog={() => setShowInvitation(false)} />
            ) : null}
            <div>
                {!users ? (
                    <CenteredContainer>
                        <LargeLoadingSpinner
                            primaryColor="var(--accent-color)"
                            secondaryColor="#ccc"
                        />
                    </CenteredContainer>
                ) : users instanceof ApiError ? (
                    <CenteredContainer>
                        <HttpErrorMessage
                            error={users}
                            hideBorderAndBackground
                        />
                    </CenteredContainer>
                ) : users.connections.length === 0 ? (
                    <CenteredContainer>
                        <span style={{ color: "grey" }}>
                            You have no connections
                        </span>
                    </CenteredContainer>
                ) : (
                    users.connections.map((user) => (
                        <Connection
                            user={user}
                            endConnection={() =>
                                setEndConnectionDialog({ user })
                            }
                            loading={idsOfPendingUsers.includes(user.id)}
                        />
                    ))
                )}
            </div>
        </SettingsSubpage>
    );
}

function CenteredContainer({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px",
            }}
        >
            {children}
        </div>
    );
}

function EndConnectionDialog({
    closeDialog,
    confirmEndingConnection,
}: {
    closeDialog: () => void;
    confirmEndingConnection: () => void;
}) {
    return (
        <DialogBox close={closeDialog}>
            <h3>Are you sure?</h3>
            <p>Do you really want to end your connection to this user?</p>
            <div className={styles["dialog-buttons"]}>
                <button className="btn btn-tertiary" onClick={closeDialog}>
                    No
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                    className="btn btn-danger"
                    onClick={confirmEndingConnection}
                >
                    Yes
                </button>
            </div>
        </DialogBox>
    );
}

function Connection({
    user,
    endConnection,
    loading,
}: {
    user: UserData;
    endConnection: () => void;
    loading: boolean;
}) {
    return (
        <div className={styles["user"]}>
            <Link to={`/@${user.handle}`} className={styles["user-link"]}>
                <RoundThumbnailImage
                    image={
                        user.smallProfilePicture ||
                        "/images/default-user-image.svg"
                    }
                    size={50}
                />
                <h6 className={styles.name}>{user.name}</h6>
            </Link>
            <button
                className="btn btn-danger"
                onClick={endConnection}
                disabled={loading}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {loading ? (
                    <SmallLoadingSpinner
                        primaryColor="white"
                        secondaryColor="#fff8"
                        size={16}
                    />
                ) : (
                    <CloseIcon color="white" lineWidth={2} lineLength={16} />
                )}
            </button>
        </div>
    );
}
