import LargeLoadingSpinner from "../LargeLoadingSpinner/LargeLoadingSpinner";

/**
 * Used to display an overlay over a section (or the whole)
 * of a page which is still loading.
 *
 * **Note:** You need to set the parent element's style to
 * `position: relative` in order for the overlay
 * to be contained by it. Otherwise, it will be anchored
 * to the page or window
 */
export function OverlayingLoadingSpinner({
  primaryColor,
  secondaryColor,
  backgroundColor,
  position,
  zIndex,
}: {
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColor?: string;
  position?: "fixed" | "absolute";
  zIndex?: number;
}) {
  return (
    <div
      style={{
        position: position || "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: zIndex || 100000,
        backgroundColor: backgroundColor || "#0003",
      }}
    >
      <LargeLoadingSpinner
        primaryColor={primaryColor || "white"}
        secondaryColor={secondaryColor || "#fff3"}
      />
    </div>
  );
}
