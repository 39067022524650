// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationEditMarker_edit-location-popup__yPR3A {
  padding: 10px;
}
.LocationEditMarker_edit-location-popup__yPR3A .LocationEditMarker_images__loFCU {
  position: relative;
}
.LocationEditMarker_edit-location-popup__yPR3A .LocationEditMarker_images__loFCU .LocationEditMarker_controls__VvFis {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
}
.LocationEditMarker_edit-location-popup__yPR3A .LocationEditMarker_empty-name-warning__DVpD6 {
  color: red;
  text-align: center;
}
.LocationEditMarker_edit-location-popup__yPR3A input,
.LocationEditMarker_edit-location-popup__yPR3A textarea {
  margin: 3px 0;
  padding: 5px 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
  resize: vertical;
  width: 240px;
  box-shadow: 0 0 0 0 var(--accent-color-02-opacity);
}
.LocationEditMarker_edit-location-popup__yPR3A input:focus,
.LocationEditMarker_edit-location-popup__yPR3A textarea:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px var(--accent-color-02-opacity);
  outline: none;
}
.LocationEditMarker_edit-location-popup__yPR3A textarea {
  margin-bottom: 0;
}

.LocationEditMarker_info-message__y9t1h {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(30px, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 100px;
}

.LocationEditMarker_hide-message__rPmyj {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/LocationEditMarker/LocationEditMarker.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,kBAAA;AACR;AACQ;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;AACZ;AAGI;EACI,UAAA;EACA,kBAAA;AADR;AAII;;EAEI,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EAEA,kDAAA;AAHR;AAKQ;;EACI,iCAAA;EACA,oDAAA;EACA,aAAA;AAFZ;AAMI;EACI,gBAAA;AAJR;;AASA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,0CAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;EAAA,sBAAA;EACA,gBAAA;AANJ;;AASA;EACI,aAAA;AANJ","sourcesContent":[".edit-location-popup {\n    padding: 10px;\n\n    .images {\n        position: relative;\n\n        .controls {\n            width: 100%;\n            display: flex;\n            flex-direction: row;\n            justify-content: center;\n            position: absolute;\n            bottom: 0;\n        }\n    }\n\n    .empty-name-warning {\n        color: red;\n        text-align: center;\n    }\n\n    input,\n    textarea {\n        margin: 3px 0;\n        padding: 5px 10px;\n        border: 1px solid #aaa;\n        border-radius: 3px;\n        resize: vertical;\n        width: 240px;\n        // font-size: 1.1em;\n        box-shadow: 0 0 0 0 var(--accent-color-02-opacity);\n\n        &:focus {\n            border-color: var(--accent-color);\n            box-shadow: 0 0 0 3px var(--accent-color-02-opacity);\n            outline: none;\n        }\n    }\n\n    textarea {\n        margin-bottom: 0;\n    }\n}\n\n\n.info-message {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(30px, -50%);\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 5px;\n    border-radius: 3px;\n    font-size: 12px;\n    max-width: fit-content;\n    min-width: 100px;\n}\n\n.hide-message {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit-location-popup": `LocationEditMarker_edit-location-popup__yPR3A`,
	"images": `LocationEditMarker_images__loFCU`,
	"controls": `LocationEditMarker_controls__VvFis`,
	"empty-name-warning": `LocationEditMarker_empty-name-warning__DVpD6`,
	"info-message": `LocationEditMarker_info-message__y9t1h`,
	"hide-message": `LocationEditMarker_hide-message__rPmyj`
};
export default ___CSS_LOADER_EXPORT___;
