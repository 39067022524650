import React from 'react';
import styles from './TitleHeader.module.scss'; // Import the SCSS module

type TitleHeaderProps = {
  title: string;
};

function TitleHeader({ title }: TitleHeaderProps) {
  return (
    <div className={styles['magic-title-container']}> 
      <h2 className={styles['title-header']}>{title}</h2>
    </div>
  );
}

export default TitleHeader;
