import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import styles from "./Navbar.module.scss";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import { navBarRoutes } from "Utils/data/constants";
import { apiRoutes } from "services/routes";
import { ApiError, useAutoRefreshingApiData } from "hooks/useAPI";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

export default function Navbar() {
    let { currentUser } = useCurrentUserDetails();

    // Indicates the number of unread messages.
    let [messagesCount] = useAutoRefreshingApiData<number>(
        apiRoutes.GET_MESSAGES_COUNT,
        10_000
    );
    // Indicates the number of unseen notification
    let [notificationsCount] = useAutoRefreshingApiData<number>(
        apiRoutes.GET_NOTIFICATIONS_COUNT,
        10_000
    );

    // I  wrapped the children of <NavbarLink into Fragment i.e <></> because it was throwing a type error
    return (
        <nav id="navbarMain" className={"navbar " + styles.navbar}>
            {navBarRoutes.map((nav) => (
                <NavbarLink
                    to={nav.route}
                    identifier={nav.identifier}
                    exact={nav.exact}
                    key={nav.identifier}
                >
                    {nav.name === "Messages" || nav.name === "Notifications" ? (
                        <IconWithCount
                            count={
                                nav.name === "Messages"
                                    ? messagesCount
                                    : notificationsCount
                            }
                            Icon={nav.icon}
                        >
                            {nav.name}
                        </IconWithCount>
                    ) : (
                        <>
                            <nav.icon
                                className=" text-inherit"
                                width="20px"
                                height="20px"
                            />
                            {nav.name}
                        </>
                    )}
                </NavbarLink>
            ))}

            <NavbarLink to="/settings" identifier="navbarSettings">
                <div
                    id="navbarUser"
                    className={`${
                        currentUser?.profilePicture !== "NA"
                            ? ""
                            : "bg-gray-300 animate-pulse rounded-full flex"
                    }`}
                >
                    <UserIcon profilePicture={currentUser?.profilePicture} />
                    {/* <span className={styles["user-name"]}>{currentUser.name}</span> */}
                </div>
            </NavbarLink>
        </nav>
    );
}

/**
 * Displays the current user's profile picture. It can adjust in
 * size responsively, based on whether it's a mobile or desktop
 * layout/width
 */
function UserIcon({ profilePicture }: { profilePicture?: string }) {
    let [size, setSize] = useState(25);

    // Changes the size of the icon based on changes in window width
    useEffect(() => {
        const adjustImageSize = () => {
            if (window.innerWidth > 600) {
                setSize(40);
            } else {
                setSize(25);
            }
        };

        adjustImageSize();

        window.addEventListener("resize", adjustImageSize);
    }, []);

    return (
        <RoundThumbnailImage
            image={profilePicture || "/images/default-user-image.svg"}
            size={size}
        />
    );
}

/**
 * Displays the number of unread messages or notifications
 */
function IconWithCount({
    count,
    Icon,
    children,
}: {
    count: number | ApiError | null;
    Icon: (props: {
        className: string;
        width: string;
        height: string;
    }) => JSX.Element;
    children: React.ReactNode;
}) {
    return (
        <div className="flex items-center flex-col">
            <div className="relative p-2">
                <Icon className="text-inherit" width="20px" height="20px" />
                {Number(count) > 0 ? (
                    <div className="absolute bg-[red] text-[white] font-bold text-[0.9em] px-1 py-0 rounded-[10px] left-[25px] top-0">
                        {count}
                    </div>
                ) : null}
            </div>
            {children}
        </div>
    );
}

/**
 * A navigation link. Can be styled one way or the other,
 * depending on whether the current page/url matches that
 * of the link
 */
function NavbarLink({
    to,
    children,
    exact,
    identifier,
}: {
    to: string;
    children: JSX.Element | JSX.Element[];
    exact?: boolean;
    identifier?: string;
}) {
    return (
        <NavLink
            to={to}
            className={(isActive) =>
                `p-2 flex items-center text-center justify-center flex-col text-[rgb(70, 70, 70)] ${
                    isActive
                        ? "text-accent-color bg-accent-color-01-opacity"
                        : ""
                }`
            }
            exact={exact}
            id={identifier}
        >
            {children}
        </NavLink>
    );
}
