import { useState, useEffect, useRef, RefObject } from 'react';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	// MinimalContactData,
	ForNetworkContactData,
	MinimalLocationData,
	MinimalUserData,
} from '../../Pages/Home/HomePageTypes';

type Options = IntersectionObserverInit & {
	freezeOnceVisible?: boolean;
  };
  
  function useIntersectionObserver(
	ref: RefObject<Element>,
	options?: Options
  ): boolean {
	const [isIntersecting, setIntersecting] = useState<boolean>(false);
  
	useEffect(() => {
	  const observer = new IntersectionObserver(([entry]) => {
		if (options?.freezeOnceVisible && entry.isIntersecting) {
		  observer.unobserve(entry.target);
		  setIntersecting(true);
		} else {
		  setIntersecting(entry.isIntersecting);
		}
	  }, options);
  
	  if (ref.current) {
		observer.observe(ref.current);
	  }
  
	  return () => {
		if (ref.current) {
		  observer.unobserve(ref.current);
		}
	  };
	}, [ref, options]);
  
	return isIntersecting;
  }



interface ContactProps {
	data: ForNetworkContactData | MinimalUserData | MinimalLocationData;
	selected: boolean;
	onSelect: (
		contact: ForNetworkContactData,
		type: 'Contacts' | 'Connections' | 'Locations'
	) => void;
}

interface UserProps {
	data: ForNetworkContactData | MinimalUserData | MinimalLocationData;
	selected: boolean;
	onSelect: (
		user: MinimalUserData,
		type: 'Contacts' | 'Connections' | 'Locations'
	) => void;
}

interface LocationProps {
	data: ForNetworkContactData | MinimalUserData | MinimalLocationData;
	selected: boolean;
	onSelect: (
		location: MinimalLocationData,
		type: 'Contacts' | 'Connections' | 'Locations'
	) => void;
}

export const ContactItem: React.FC<ContactProps> = ({ data, selected, onSelect }) => {
    const imgRef = useRef(null);
    const isVisible = useIntersectionObserver(imgRef, { threshold: 0.1 });

    if ('profilePicture' in data) {
        return (
            <div className={`flex items-center px-4 py-2 space-x-3 cursor-pointer ${selected ? 'bg-gray-200' : ''}`} onClick={() => onSelect(data, 'Contacts')}>
                <img
                    ref={imgRef}
                    src={isVisible ? (data.profilePicture ? data.profilePicture : '/images/default-user-image.svg') : undefined}
                    alt={data.name ? data.name : ''}
                    className="w-8 h-8 rounded-full"
                />
                <span className="text-sm font-medium">{data.name}</span>
                <span className="text-xs font-small grey">{data.description}</span>
            </div>
        );
    }
    return null;
};

export const UserItem: React.FC<UserProps> = ({ data, selected, onSelect }) => {
    const imgRef = useRef(null);
    const isVisible = useIntersectionObserver(imgRef, { threshold: 0.1 });

    if ('smallProfilePicture' in data) {
        return (
            <div className={`flex items-center px-4 py-2 space-x-3 cursor-pointer ${selected ? 'bg-gray-200' : ''}`} onClick={() => onSelect(data, 'Connections')}>
                <img
                    ref={imgRef}
                    src={isVisible ? (data.smallProfilePicture ? data.smallProfilePicture : '/images/default-user-image.svg') : undefined}
                    alt={data.name}
                    className="w-8 h-8 rounded-full"
                />
                <div>
                    <span className="text-sm font-medium">{data.name}</span>
                </div>
            </div>
        );
    }
    return null;
};

// export const LocationItem: React.FC<LocationProps> = ({
// 	data,
// 	selected,
// 	onSelect,
// }) => {
// 	if ('mainPicture' in data) {
// 		return (
// 			<div
// 				className={`flex items-center px-4 py-2 space-x-3 cursor-pointer ${selected ? 'bg-gray-200' : ''
// 					}`}
// 				onClick={() => onSelect(data, 'Locations')}
// 			>
// 				{/* <img
//                 src={data.mainPicture?data.mainPicture:''}
//                 alt={data.name}
//                 className="w-8 h-8 rounded-full"
//             /> */}
// 				<FontAwesomeIcon icon={faMapLocationDot} className="bg-green-300" />
// 				<div>
// 					<span className="text-sm font-medium">{data.name}</span>
// 					{/* <br />
//                 <span className="text-xs text-gray-500">{data.}</span> */}
// 				</div>
// 			</div>
// 		);
// 	}
// 	return null;
// };


export const LocationItem: React.FC<LocationProps> = ({
	data,
	selected,
	onSelect,
}) => {
	const imgRef = useRef(null);
	const isVisible = useIntersectionObserver(imgRef, { threshold: 0.1 });

	if ('mainPicture' in data) {
		return (
			<div
				className={`flex items-center px-4 py-2 space-x-3 cursor-pointer ${selected ? 'bg-gray-200' : ''}`}
				onClick={() => onSelect(data, 'Locations')}
			>
				<img
					ref={imgRef}
					src={isVisible ? (data.mainPicture || '') : undefined}
					alt={data.name}
					className="w-8 h-8 rounded-full"
				/>
				<div>
					<span className="text-sm font-medium">{data.name}</span>
				</div>
			</div>
		);
	}
	return null;
};