
export const ACTIONS = {
  users: "users",
  contacts: "contacts",
  locations: "locations",
  travelPlans: "travelPlans",
  clusters: "clusters",
  mapBounds: "mapBounds",
  mapPosition: "mapPosition",
  gpsPosition: "gpsPosition",
  openPopup: "openPopup",
  searchInput: "searchInput", 
  searchFor: "searchFor",
  contextMenu : "contextMenu",
  skillBeingAddedTo: "skillBeingAddedTo",
  objectBeingAddedTo: "objectBeingAddedTo",
  addingCoordinates: "addingCoordinates",
  importingContacts: "importingContacts",
  addingContactFromLinkedin: "addingContactFromLinkedin",
  addingTravelPlan: "addingTravelPlan",
  editingTravelPlan: "editingTravelPlan",
  locationBeingEdited: "locationBeingEdited",
  contactBeingEdited: "contactBeingEdited",
  alert: "alert",
  selectedLayers: "selectedLayers",
  hoveredOverUser: "hoveredOverUser",
  addingNetwork: "addingNetwork",
  addingJournalEntry: "addingJournalEntry",
  tileLayer: "tileLayer",
  ADD_CONTACT: "ADD_CONTACT",
  ADD_LOCATION: "ADD_LOCATION",
  ADD_TRAVEL_PLAN: "ADD_TRAVEL_PLAN",
  CANCEL_ADDING_SKILL: "CANCEL_ADDING_SKILL",
  CANCEL_ADDING_OBJECT: "CANCEL_ADDING_OBJECT",
  CANCEL_MARKER_EDITING: "CANCEL_MARKER_EDITING",
  MAP_DATA: "MAP_DATA",
  CLEAR_ALERT: "CLEAR_ALERT",
  CLOSE_POPUP: "CLOSE_POPUP",
  CLOSE_TRAVEL_PLAN_EDITOR: "CLOSE_TRAVEL_PLAN_EDITOR",
  CREATE_NEW_CONTACT: "CREATE_NEW_CONTACT",
  CREATE_NEW_LOCATION: "CREATE_NEW_LOCATION",
  CREATE_NEW_NETWORK: "CREATE_NEW_NETWORK",
  DELETE_CONTACT: "DELETE_CONTACT",
  DELETE_LOCATION: "DELETE_LOCATION",
  DELETE_TRAVEL_PLAN: "DELETE_TRAVEL_PLAN",
  INSERT_CONTACTS: "INSERT_CONTACTS",
  MAP_CLICKED: "MAP_CLICKED",
  MAP_RESIZED: "MAP_RESIZED",
  START_ADDING_OBJECT: "START_ADDING_OBJECT",
  START_ADDING_SKILL: "START_ADDING_SKILL",
  START_EDITING_CONTACT: "START_EDITING_CONTACT",
  START_EDITING_LOCATION: "START_EDITING_LOCATION",
  START_SKILL_SEARCH: "START_SKILL_SEARCH",
  START_OBJECT_SEARCH: "START_OBJECT_SEARCH",

  UPDATE_CONTACT: "UPDATE_CONTACT",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  UPDATE_TRAVEL_PLAN: "UPDATE_TRAVEL_PLAN",
  keepInTouchAdded: "keepInTouchAdded",
  ADD_KEEP_IN_TOUCH: "ADD_KEEP_IN_TOUCH",
  CANCEL_ADDING_KEEP_IN_TOUCH: "CANCEL_ADDING_KEEP_IN_TOUCH"
} as const;