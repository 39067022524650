export default function NotificationsIcon({
	className,
	width,
	height,
}: {
	className: string;
	width: string;
	height: string;
}) {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			version="1.1"
			viewBox="0 0 44.979 57.757"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g transform="translate(-10.885 -26.573)">
				<path
					d="m31.455 84.023c-2.1542-0.80304-3.9021-2.9302-3.9021-4.7489v-0.71022h11.711l-0.14455 1.0777c-0.43159 3.2178-4.5188 5.5542-7.6647 4.3815zm-19.671-9.5337c-0.73681-0.73681-0.89958-1.1002-0.89958-2.0082 0-1.0371 0.15381-1.2679 2.3812-3.5745l2.3812-2.4658 0.0046-8.9536c0.0049-9.6014 0.14181-10.975 1.4056-14.104 2.0213-5.0043 5.525-8.4189 10.301-10.039l1.386-0.47011 0.15225-1.6955c0.29047-3.2349 1.6234-4.606 4.478-4.606 2.8476 0 4.1857 1.3703 4.478 4.5855l0.15225 1.6751 1.3229 0.46656c4.4443 1.5674 7.5596 4.3449 9.6668 8.6187 1.8757 3.8043 1.9264 4.1683 2.0915 14.997l0.14796 9.7053 2.3151 2.3653c2.1726 2.2197 2.3151 2.4342 2.3151 3.4842 0 0.91966-0.16016 1.279-0.89958 2.0184l-0.89958 0.89958h-41.381z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
}
