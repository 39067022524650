import React from "react";

import styles from "./MarkerPrivacySetting.module.scss";
import CustomDropdown from "../../../Components/CustomDropdown/CustomDropdown";
import CustomCheckbox from "../../../Components/CustomCheckbox/CustomCheckbox";
import { Visibility, VisibilityWithPublic } from "../visibility";

/**
 * Allows the user to choose the privacy level of a contact or location. It is
 * shown when the user clicks on the privacy icon on the marker popup. It is a
 * wrapper around the CustomDropdown and CustomCheckbox components.
 * @param props
 * @returns
 * @constructor
 * @example
 * <MarkerPrivacySetting
 * visibility={visibility}
 * setVisibility={setVisibility}
 * /> 
 */
export function MarkerPrivacySetting<IncludePublic extends boolean>({
  visibility,
  updateVisibility,
  includePublic,
}: {
  visibility: IncludePublic extends false ? Visibility : VisibilityWithPublic;
  updateVisibility: (
    string: IncludePublic extends false ? Visibility : VisibilityWithPublic
  ) => void;
  includePublic: IncludePublic;
}) {
  return (
    <table className={styles["visibility"]}>
      <tr>
        <td>Visibility:</td>
        <td>
          <CustomDropdown
            items={
              includePublic
                ? ["Private", "Share with connections", "Public"]
                : ["Private", "Share with connections"]
            }
            selected={
              visibility === "private"
                ? "Private"
                : visibility === "public"
                ? "Public"
                : "Share with connections"
            }
            onChange={(newValue) =>
              updateVisibility(
                newValue === "Share with connections"
                  ? "shared with connections"
                  : (newValue.toLowerCase() as typeof visibility)
              )
            }
          />
        </td>
      </tr>
      {(visibility === "connections need to request" ||
        visibility === "shared with connections") && (
        <tr>
          <td></td>
          <td>
            <label
              className={styles["require-permission"]}
              onClick={() =>
                updateVisibility(
                  visibility === "connections need to request"
                    ? "shared with connections"
                    : "connections need to request"
                )
              }
            >
              <CustomCheckbox
                className={styles["checkbox"]}
                checked={visibility === "connections need to request"}
              />
              Require permission
            </label>
          </td>
        </tr>
      )}
    </table>
  );
}
