import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ConversationPreview.module.scss';
import ProfilePicture from './ProfilePicture';
import ConversationDetails from './ConversationDetails';
import { ConversationData } from '../Messages';

export default function ConversationPreview({ conversation }: { conversation: ConversationData }) {
    return (
        <Link to={`/messages/${conversation.user.handle}`}>
            <div className={styles['conversation-item']}>
                <ProfilePicture image={conversation.user.profilePicture} />
                <ConversationDetails conversation={conversation} />
            </div>
        </Link>
    );
}
