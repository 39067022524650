import React from "react";

import { getContextMenuStyle } from "Components/ContextMenu/ContextMenu";
import { UserData, ContactData } from "../../HomePageTypes";
import OptionsButton from "Components/OptionsButton/OptionsButton";
import { DispatchAction } from "../../reducer";
import KeepInTouchComponent from "Components/KeepInTouchComponent/KeepInTouchComponent";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";
import AddressList from 'Components/AddressManager/AddressList';
import ExperienceList from 'Components/ExperienceManager/ExperienceList';


import { PopupHeading } from "./PopupHeading"; // Import PopupHeading component
import { ApiError } from "hooks/useAPI";

import ExpandableDescription from 'Components/ExpandableDescription/ExpandableDescription'
import { NetworksIconsDisplay } from 'Components/NetworksIconsDisplay/NetworksIconsDisplay'

import KeepInTouchSection from "Components/KeepInTouchSection/KeepInTouchSection";
import DetailsSection from "Components/DetailsSection/DetailsSection";
import TabsSection from "Components/TabsSection/TabsSection";
export function FullUserPopupContent({
    user,
    setUser,
    dispatch,
    state,
}: {
    user: UserData;
    setUser: React.Dispatch<React.SetStateAction<UserData | ContactData | ApiError | null>>;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {

    let userId = user.id;

    const addingKeepInTouch =
        state.keepInTouchAdded !== null &&
        state.keepInTouchAdded.for.id === user.id;

    const maxDescriptionLength = 100;

    const onOptionsButtonClicked = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style: getContextMenuStyle(event), user, type: "user" },
        });
    };

    return (
        <>
            <PopupHeading user={user} />

            <ExpandableDescription
                description={user.bio}
                maxDescriptionLength={maxDescriptionLength}
            />
            {!addingKeepInTouch && (
                <KeepInTouchComponent data={user.keepInTouch} />
            )}
                <AddressList 
					entityType='user'
					entityId={userId}
					/>

                <ExperienceList 
					entityType='user'
					entityId={userId}
					/>


            <NetworksIconsDisplay
                networks={user.networks}
            />

            <DetailsSection
                data={user}
                addingKeepInTouch={addingKeepInTouch}
            />

            <TabsSection
                data={user}
                setContact={setUser}
                addingKeepInTouch={addingKeepInTouch}
                dispatch={dispatch}
                state={state}
                UserOrContact="user"
            />


            <OptionsButton onClick={onOptionsButtonClicked} />

            <KeepInTouchSection
                addingKeepInTouch={addingKeepInTouch}
                data={user}
                state={state}
                UserOrContact="user"
            />
        </>
    );
}
