// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsSection_horizontal-container__rj4uE {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.DetailsSection_horizontal-container__rj4uE::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/DetailsSection/DetailsSection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EAEA,wBAAA;EACA,qBAAA;AAAF;AAEE;EACI,aAAA;AAAN","sourcesContent":[".horizontal-container {\n  display: flex;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  max-width: 200px;\n\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n      display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-container": `DetailsSection_horizontal-container__rj4uE`
};
export default ___CSS_LOADER_EXPORT___;
