import React from 'react';
import TextInput from 'Components/forms/TextInput';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TOnboardingPayload } from 'types/shared';

interface OccupationInputProps {
    register: UseFormRegister<TOnboardingPayload>;
    errors: FieldErrors<TOnboardingPayload>;
}

const OccupationInput: React.FC<OccupationInputProps> = ({ register, errors }) => {
    return (
        <TextInput
            register={register}
            inputName='occupation'
            errors={errors}
            placeholder='Enter your occupation'
            inputLabel='Occupation (optional):'
            required={false}
        />
    );
};

export default OccupationInput;
