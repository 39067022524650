import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import customStyles from "./CustomTooltip.module.scss"; // Import the new module for your custom tooltip
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { BasicNetworkData } from "Components/NetworkGroups/BasicNetworkDataTypes";
import {MinimalNetworkData} from "../../Pages/Home/HomePageTypes"
import styles from './NetworksIconsDisplay.module.scss'

//TODO Tooltip needs to be visible in front of other elements, curretnly using display:math that works but only one line, need to modify to allow for multi line display
interface CustomTooltipProps {
    title: string;
    children: React.ReactNode;
}

export function CustomTooltip({ title, children }: CustomTooltipProps) {
    return (
        <div className={customStyles["custom-tooltip"]}>
            <div className={customStyles["tooltip-content"]}>{children}</div>
            <span className={customStyles["tooltip-text"]}>{title}</span>
        </div>
    );
}

interface ContactNetworkIconsProps {
  networks: MinimalNetworkData[] | null | undefined;
}

export const NetworksIconsDisplay: React.FC<ContactNetworkIconsProps> = ({
  networks,
}) => {
  // Check if networks is defined and its length is greater than 0
  if (!networks || networks.length === 0) {
    return null; // Return null if networks is null or has no elements
  }

  return (
    <div className={styles["networks-icons-container"]}>
      {networks.map((network: any) => (
        <div key={network.id} className={styles["icon-container"]}>
          <CustomTooltip title={network.name}>
            <Link to={`/network/${network.id}`}>
              {network.network_logo ? (
                <img
                  className={styles["fa-icon"]}
                  src={network.network_logo}
                  alt="Logo"
                />
              ) : (
                <FontAwesomeIcon
                  className={styles["fa-icon"]}
                  fontSize={30}
                  icon={faUserGroup}
                />
              )}
            </Link>
          </CustomTooltip>
        </div>
      ))}
    </div>
  );
};
