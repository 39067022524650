// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbar__tdq2l {
  width: var(--navbar-width);
  font-size: 0.7em;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(100 * var(--vh));
  padding: 0;
}
@media screen and (min-width: 1200px) {
  .Navbar_navbar__tdq2l {
    font-size: 0.8em;
  }
}
.Navbar_navbar__tdq2l > * {
  width: var(--navbar-width);
  height: var(--navbar-width);
}
@media screen and (max-width: 600px) {
  .Navbar_navbar__tdq2l {
    flex-direction: row;
    width: 100vw;
    height: 47px;
  }
  .Navbar_navbar__tdq2l > * {
    height: 47px;
    width: 16.666666667%;
    font-size: 0em;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,6BAAA;EACA,UAAA;AACJ;AACI;EAVJ;IAWQ,gBAAA;EAEN;AACF;AAAI;EACI,0BAAA;EACA,2BAAA;AAER;AACI;EAnBJ;IAoBQ,mBAAA;IACA,YAAA;IACA,YAAA;EAEN;EAAM;IACI,YAAA;IACA,oBAAA;IACA,cAAA;EAEV;AACF","sourcesContent":[".navbar {\n    width: var(--navbar-width);\n    font-size: 0.7em;\n    justify-content: flex-start;\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    height: calc(100 * var(--vh));\n    padding: 0;\n\n    @media screen and (min-width: 1200px) {\n        font-size: 0.8em;\n    }\n\n    >* {\n        width: var(--navbar-width);\n        height: var(--navbar-width);\n    }\n\n    @media screen and (max-width: 600px) {\n        flex-direction: row;\n        width: 100vw;\n        height: 47px;\n\n        >* {\n            height: 47px;\n            width: 16.666666667%;\n            font-size: 0em;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Navbar_navbar__tdq2l`
};
export default ___CSS_LOADER_EXPORT___;
