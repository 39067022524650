// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LargeLoadingSpinner_loading-spinner-large__7pbq7 {
  display: inline-block;
  animation: LargeLoadingSpinner_rotating__7zApZ 0.8s linear infinite;
  border: 4px solid var(--secondary-color);
  border-top-color: var(--primary-color);
  border-right-color: var(--primary-color);
  width: 50px;
  height: 50px;
  border-radius: 60px;
  border-width: 4px;
}

@keyframes LargeLoadingSpinner_rotating__7zApZ {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/LargeLoadingSpinner/LargeLoadingSpinner.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mEAAwC;EACxC,wCAAwC;EACxC,sCAAsC;EACtC,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-spinner-large {\n  display: inline-block;\n  animation: rotating 0.8s linear infinite;\n  border: 4px solid var(--secondary-color);\n  border-top-color: var(--primary-color);\n  border-right-color: var(--primary-color);\n  width: 50px;\n  height: 50px;\n  border-radius: 60px;\n  border-width: 4px;\n}\n\n@keyframes rotating {\n  0% {\n    transform: rotate(0deg);\n  }\n  25% {\n    transform: rotate(90deg);\n  }\n  50% {\n    transform: rotate(180deg);\n  }\n  75% {\n    transform: rotate(270deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-spinner-large": `LargeLoadingSpinner_loading-spinner-large__7pbq7`,
	"rotating": `LargeLoadingSpinner_rotating__7zApZ`
};
export default ___CSS_LOADER_EXPORT___;
