// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CreateNetwork.module.scss */
.CreateNetwork_warning__F\\+h0L {
  color: var(--bs-warning);
  font-size: 14px;
  margin-bottom: 10px;
}

.CreateNetwork_button-container__tuDsT {
  text-align: center;
}

.CreateNetwork_create-button__p4NQc {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.CreateNetwork_create-button__p4NQc:hover {
  background-color: var(--accent-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/Components/CreateNetwork/CreateNetwork.module.scss"],"names":[],"mappings":"AAAA,8BAAA;AAEA;EACE,wBAAA;EACA,eAAA;EACA,mBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,qCAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAGA;EACE,0CAAA;AAAF","sourcesContent":["/* CreateNetwork.module.scss */\n\n.warning {\n  color: var(--bs-warning);\n  font-size: 14px;\n  margin-bottom: 10px;\n}\n\n.button-container {\n  text-align: center;\n}\n\n.create-button {\n  background-color: var(--accent-color);\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n}\n\n.create-button:hover {\n  background-color: var(--accent-color-dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": `CreateNetwork_warning__F+h0L`,
	"button-container": `CreateNetwork_button-container__tuDsT`,
	"create-button": `CreateNetwork_create-button__p4NQc`
};
export default ___CSS_LOADER_EXPORT___;
