// ./hooks/addNewLocationSearch.ts
import { ACTIONS } from '../Pages/Home/reducerActions';

export const addNewLocationSearch = async (dispatch: React.Dispatch<any>) => {
    const address = prompt("Enter an address to search for:");

    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const { lat, lon } = data[0];
        const point = { x: lon, y: lat };

        dispatch({
            type: ACTIONS.CREATE_NEW_LOCATION,
            coordinates: point,
        });
    } catch (error) {
        console.error(error);
    }
};
