// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ExpandableDescription.module.scss */
.ExpandableDescription_description__iUMF7 {
  text-align: justify;
  margin: 15px;
  margin-bottom: 5px;
  white-space: pre-line;
  cursor: pointer;
  max-height: 100px; /* Initial max height */
  transition: max-height 0.3s ease-in-out;
}
.ExpandableDescription_description__iUMF7:hover .ExpandableDescription_see-more__S2NYu {
  color: rgb(6, 91, 189);
  font-weight: bold;
}
.ExpandableDescription_description__iUMF7.ExpandableDescription_expanded__M5uTc {
  max-height: none; /* Remove the max height limit */
  transition: max-height 0.3s ease-in-out;
}

.ExpandableDescription_see-more__S2NYu {
  color: rgb(131, 15, 15);
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.ExpandableDescription_see-more-content__mTiSt {
  max-height: 100px; /* Initially hide the content */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: max-height 0.3s ease-in-out;
}

/* Style for the "See more" content */
.ExpandableDescription_see-more-content__mTiSt p {
  margin: 0;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ExpandableDescription/ExpandableDescription.module.scss"],"names":[],"mappings":"AAAA,sCAAA;AAEA;EACE,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA,EAAA,uBAAA;EACA,uCAAA;AAAF;AAGI;EACE,sBAAA;EACA,iBAAA;AADN;AAKE;EACE,gBAAA,EAAA,gCAAA;EACA,uCAAA;AAHJ;;AAOA;EACE,uBAAA;EACA,eAAA;EACA,kCAAA;AAJF;;AAOA;EACE,iBAAA,EAAA,+BAAA;EACA,gBAAA,EAAA,8BAAA;EACA,uCAAA;AAJF;;AAOA,qCAAA;AACA;EACE,SAAA;EACA,YAAA;AAJF","sourcesContent":["/* ExpandableDescription.module.scss */\n\n.description {\n  text-align: justify;\n  margin: 15px;\n  margin-bottom: 5px;\n  white-space: pre-line;\n  cursor: pointer;\n  max-height: 100px; /* Initial max height */\n  transition: max-height 0.3s ease-in-out;\n\n  &:hover {\n    .see-more {\n      color: rgb(6, 91, 189);\n      font-weight: bold;\n    }\n  }\n\n  &.expanded {\n    max-height: none; /* Remove the max height limit */\n    transition: max-height 0.3s ease-in-out;\n  }\n}\n\n.see-more {\n  color: rgb(131, 15, 15);\n  cursor: pointer;\n  transition: color 0.3s ease-in-out;\n}\n\n.see-more-content {\n  max-height: 100px; /* Initially hide the content */\n  overflow-y: auto; /* Enable vertical scrolling */\n  transition: max-height 0.3s ease-in-out;\n}\n\n/* Style for the \"See more\" content */\n.see-more-content p {\n  margin: 0;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `ExpandableDescription_description__iUMF7`,
	"see-more": `ExpandableDescription_see-more__S2NYu`,
	"expanded": `ExpandableDescription_expanded__M5uTc`,
	"see-more-content": `ExpandableDescription_see-more-content__mTiSt`
};
export default ___CSS_LOADER_EXPORT___;
