import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./ManagePermissions.module.scss";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";

import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import { UserSection } from "./UserSection";
import { useDocumentTitle } from "hooks/useSEO";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import SettingsSubpage from "../SettingsSubpage/SettingsSubpage";

export type PlaceOrContactPermissions = {
    id: number;
    name: string;
    type: "place" | "contact";
    url: string;
    image: string;
    users: Array<PermissionsForUser>;
};

export type PermissionsForUser = {
    id: number;
    name: string;
    url: string;
    image: string;
    saving: boolean;
    originalOptions: {
        [key: string]: boolean;
    };
    editedOptions: {
        [key: string]: boolean;
    };
};

/**
 * Used to manage permissions which have been handed out to
 * other users to access one's own places and contacts
 */
export default function ManagePermissions() {
    useDocumentTitle("Manage Contact and Place Permissions");

    type PermissionsFromServer = {
        id: number;
        name: string;
        url: string;
        image: string;
        users: Array<{
            id: number;
            name: string;
            url: string;
            image: string;
            options: {
                [key: string]: boolean;
            };
        }>;
    };

    // The initial values for permissions from the server are
    // different from "PlaceOrContactPermissions" as the latter needs
    // to include properties like "saving", "originalOptions", and
    // "editedOptions"
    let [initialPermissions] = useApi<{
        contactPermissions: Array<PermissionsFromServer>;
        placePermissions: Array<PermissionsFromServer>;
    } | null>(apiRoutes.GET_PERMISSIONS);

    let [permissions, setPermissions] = useState<
        Array<PlaceOrContactPermissions>
    >([]);

    useEffect(() => {
        if (initialPermissions && !(initialPermissions instanceof ApiError)) {
            setPermissions([
                ...initialPermissions.contactPermissions.map(
                    (p) =>
                        ({
                            id: p.id,
                            name: p.name,
                            type: "contact",
                            url: p.url,
                            image: p.image,
                            users: p.users.map((u) => ({
                                id: u.id,
                                name: u.name,
                                url: u.url,
                                image: u.image,
                                saving: false,
                                originalOptions: u.options,
                                editedOptions: u.options,
                            })),
                        } satisfies PlaceOrContactPermissions)
                ),
                ...initialPermissions.placePermissions.map(
                    (p) =>
                        ({
                            id: p.id,
                            name: p.name,
                            type: "place",
                            url: p.url,
                            image: p.image,
                            users: p.users.map((u) => ({
                                id: u.id,
                                name: u.name,
                                url: u.url,
                                image: u.image,
                                saving: false,
                                originalOptions: u.options,
                                editedOptions: u.options,
                            })),
                        } satisfies PlaceOrContactPermissions)
                ),
            ]);
        }
    }, [initialPermissions]);

    const updateUser = (
        userId: number,
        contactOrPlaceId: number,
        getNewValue: (user: PermissionsForUser) => PermissionsForUser
    ) => {
        setPermissions(
            permissions.map((p) =>
                p.id === contactOrPlaceId
                    ? {
                          ...p,
                          users: p.users.map((u) =>
                              u.id === userId ? getNewValue(u) : u
                          ),
                      }
                    : p
            )
        );
    };

    /** Revokes all permissions for a user to access a specified contact or location */
    const deleteUserPermissions = (
        userId: number,
        contactOrPlaceId: number
    ) => {
        setPermissions(
            permissions.map((p) =>
                p.id === contactOrPlaceId
                    ? { ...p, users: p.users.filter((u) => u.id !== userId) }
                    : p
            )
        );
    };

    let { postToApi } = usePostToAPI();

    const savePermissionOptions = (
        userId: number,
        contactOrPlaceId: number,
        type: "contact" | "place"
    ) => {
        updateUser(userId, contactOrPlaceId, (userPerms) => {
            postToApi(
                type === "place"
                    ? apiRoutes.CREATE_OR_DELETE_PLACE_PERMISSION(
                          contactOrPlaceId,
                          userId
                      )
                    : apiRoutes.CREATE_OR_DELETE_CONTACT_PERMISSION(
                          contactOrPlaceId,
                          userId
                      ),
                {
                    options: JSON.stringify(userPerms.editedOptions),
                }
            ).then((response) =>
                updateUser(userId, contactOrPlaceId, (u) => ({
                    ...u,
                    saving: false,
                    originalOptions: response.ok
                        ? u.editedOptions
                        : u.originalOptions,
                }))
            );

            return {
                ...userPerms,
                saving: true,
            };
        });
    };

    const revokeUserPermissions = (
        userId: number,
        contactOrPlaceId: number,
        type: "contact" | "place"
    ) => {
        updateUser(userId, contactOrPlaceId, (userPerms) => {
            postToApi(
                type === "place"
                    ? apiRoutes.CREATE_OR_DELETE_PLACE_PERMISSION(
                          contactOrPlaceId,
                          userId
                      )
                    : apiRoutes.CREATE_OR_DELETE_CONTACT_PERMISSION(
                          contactOrPlaceId,
                          userId
                      ),
                undefined,
                "delete"
            ).then((response) =>
                response.ok
                    ? deleteUserPermissions(userId, contactOrPlaceId)
                    : updateUser(userId, contactOrPlaceId, (u) => ({
                          ...u,
                          saving: false,
                      }))
            );

            return {
                ...userPerms,
                saving: true,
            };
        });
    };

    return (
        <SettingsSubpage title="Contact and Place Permissions">
            {!initialPermissions ? (
                <OverlayingLoadingSpinner />
            ) : initialPermissions instanceof ApiError ? (
                <HttpErrorMessage error={initialPermissions} />
            ) : permissions.length === 0 ||
              permissions.every((p) => p.users.length === 0) ? (
                <p style={{ color: "grey" }}>No permissions assigned yet</p>
            ) : (
                permissions.map((p) =>
                    p.users.length === 0 ? null : (
                        <Fragment key={`${p.type}:${p.id}`}>
                            <div className={styles["contact-or-place"]}>
                                <div className={styles["contact-or-place-bar"]}>
                                    <Link to={p.url}>
                                        <RoundThumbnailImage
                                            image={p.image}
                                            size={40}
                                        />{" "}
                                        <h5>{p.name}</h5>
                                    </Link>
                                </div>
                                <div className={styles["users"]}>
                                    {p.users.map((user) => (
                                        <UserSection
                                            user={user}
                                            allPermissions={p}
                                            onOptionsChanged={(newOptions) =>
                                                updateUser(
                                                    user.id,
                                                    p.id,
                                                    (u) => ({
                                                        ...u,
                                                        editedOptions:
                                                            newOptions,
                                                    })
                                                )
                                            }
                                            save={() =>
                                                savePermissionOptions(
                                                    user.id,
                                                    p.id,
                                                    p.type
                                                )
                                            }
                                            revokeUserPermissions={() =>
                                                revokeUserPermissions(
                                                    user.id,
                                                    p.id,
                                                    p.type
                                                )
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </Fragment>
                    )
                )
            )}
        </SettingsSubpage>
    );
}
