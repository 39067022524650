import React, { useReducer, useEffect } from 'react';
import uuid from 'react-uuid';

import ReadOnlyMap from './ReadOnlyMap';
import { reducer, getInitialState, LandingPageState } from './reducer';
import { featureData, FAQsData, userTestimonials } from './data';
import { NavigationBar } from 'Components/LandingPageComponents/navigationBar';
import { Footer } from 'Components/LandingPageComponents/footerComponent';
import FAQSection from 'Components/LandingPageComponents/FAQSection';
import ActivityStream from 'Components/LandingPageComponents/ActivityStream';
import { EMAIL_REGEX } from 'Utils/regex';
import { apiInstance } from 'services/apiInstance';
import { apiRoutes } from 'services/routes';
import MapbondDescription from './MapbondDescription/MapbondDescription'

function isValidEmail(email: string): boolean {
	const emailRegex = EMAIL_REGEX;
	return emailRegex.test(email);
}

type Feedback = {
	NAME: string;
	EMAIL: string;
	MESSAGE: string;
	SUCCESS: string;
	EMPTY: string;
};

const feedbackStatus: Feedback = {
	NAME: 'Please enter the name!',
	EMAIL: 'Please enter a valid email!',
	MESSAGE: 'Please enter the message!',
	SUCCESS: 'Feedback submitted successfully!',
	EMPTY: '',
};

function LandingPage() {
	// Main homepage state
	let [state, dispatch] = useReducer(reducer, getInitialState());

	const handleInputChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		let field = event.target.name;
		switch (field) {
			case 'name':
				dispatch({ type: 'UPDATE_CONTACT_NAME', value: event.target.value });
				break;
			case 'email':
				dispatch({ type: 'UPDATE_CONTACT_EMAIL', value: event.target.value });
				break;
			case 'message':
				dispatch({ type: 'UPDATE_CONTACT_MESSAGE', value: event.target.value });
		}
		console.log('CONTACT INPUT', state.contactUsForm);
	};
	const clearContactForm = () => {
		// clearing the form
		dispatch({ type: 'UPDATE_CONTACT_NAME', value: '' });
		dispatch({ type: 'UPDATE_CONTACT_EMAIL', value: '' });
		dispatch({ type: 'UPDATE_CONTACT_MESSAGE', value: '' });
	};

	async function fetchActivityStream() {
		try {
			const res = await apiInstance.get(apiRoutes.GET_PUBLIC_ACTIVITIES);
			if (res.status === 200) {
				dispatch({
					type: 'publicActivityStream',
					value: res.data.data,
				});
			}
		} catch (reason) {
			//there should be a proper error handling mechanism
			console.log(reason);
		}
	}

	const formOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (state.contactUsForm.name.trim().length === 0) {
			dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.NAME });
			return;
		}
		if (
			state.contactUsForm.email.trim().length === 0 ||
			!isValidEmail(state.contactUsForm.email)
		) {
			dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.EMAIL });
			return;
		}
		if (state.contactUsForm.message.trim().length === 0) {
			dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.MESSAGE });
			return;
		}

		dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.EMPTY });
		//API call to the BE
		apiInstance.post(apiRoutes.CONTACT_US, JSON.stringify(state.contactUsForm));
		dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.SUCCESS });
		setTimeout(() => {
			dispatch({ type: 'UPDATE_FORM_STATUS', value: feedbackStatus.EMPTY });
		}, 2000);
		clearContactForm();
	};

	useEffect(() => {
		fetchActivityStream();
	}, []);

	return (
		<div className="overflow-x-hidden">
			<div className="w-full lg:min-h-screen lg:flex">
				<div className="container lg:w-3/5">
					<NavigationBar />
					{/* hero with CTA  */}
					<MapbondDescription />
				</div>
				<div className="lg:w-2/5 py-3 px-3">
					<ReadOnlyMap
						centerPosition={state.initialMapPosition}
						mapBounds={state.mapBounds}
						userProfiles={state.userProfiles}
						dispatch={dispatch}
					/>
				</div>
			</div>
			<div className="">
				<ActivityStream activities={state.publicActivityStream} />
			</div>

			{/* Features Section  */}
			{features()}

			{/* FAQs Section  */}
			<FAQSection FAQsData={FAQsData} />

			{/* Contact us section  */}
			{contactUsForm(formOnSubmit, state, handleInputChange)}

			<section>
				<div className="flex flex-col items-center w-full gap-4 p-8 mb-8 md:flex-row md:mb-0 flex-between">
					<h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
						Words of wisdom from our users
					</h2>

					{userTestimonials.map((t) => {
						return (
							<div
								className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4"
								key={uuid()}
							>
								<p className="text-gray-600 dark:text-white">
									<span className="text-lg font-bold text-indigo-500">“</span>
									{t.testimonial}
									<span className="text-lg font-bold text-indigo-500">”</span>
								</p>
								<div className="flex items-center mt-4">
									<a href="#" className="relative block">
										<img
											alt="profil"
											src={t.profile}
											className="mx-auto object-cover rounded-full h-10 w-10 "
										/>
									</a>
									<div className="flex flex-col justify-between ml-2">
										<span className="text-sm font-semibold text-indigo-500">
											{t.name}
										</span>
										<span className="flex items-center text-xs dark:text-gray-400">
											User of Mapbond
										</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</section>
			<Footer backGround="default" />
		</div>
	);
}

export default LandingPage;
function contactUsForm(
	formOnSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
	state: LandingPageState | undefined,
	handleInputChange: (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => void
) {
	const name = state?.contactUsForm?.name || '';
	const email = state?.contactUsForm?.email || '';
	const message = state?.contactUsForm?.message || '';

	const formStatus = state?.formStatus;

	return (
		<section className=" bg-yellow-100 py-20" id="ContactUsSection">
			<div className="px-4 flex flex-col max-w-6xl mx-auto md:flex-row">
				<h2 className="w-full mr-8 text-4xl font-extrabold leading-9 text-green-500 md:w-1/3">
					✉️ Drop Us a Line 📨
				</h2>
				<form
					onSubmit={formOnSubmit}
					className="w-full md:w-2/3 bg-green-400 shadow-md rounded-md px-8 pb-3 mt-2"
				>
					<div className="mb-4">
						<label
							className="block mt-4 text-white text-lg font-bold mb-2"
							htmlFor="name"
						>
							🤖 Name
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-green-700 leading-tight focus:outline-none focus:shadow-outline"
							value={name}
							onChange={handleInputChange}
							name="name"
							placeholder="👤 Name"
						/>
					</div>
					<div className="mb-4">
						<label
							className="block text-white text-lg font-bold mb-2"
							htmlFor="email"
						>
							📧 Email
						</label>
						<input
							className="shadow appearance-none border rounded w-full py-2 px-3 text-green-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							name="email"
							value={email}
							onChange={handleInputChange}
							placeholder="📧 Email"
						/>
					</div>
					<div className="mb-4">
						<label
							className="block text-white text-lg font-bold mb-2"
							htmlFor="message"
						>
							💬 Message
						</label>
						<textarea
							className="shadow appearance-none border rounded w-full py-2 px-3 text-green-700 leading-tight focus:outline-none focus:shadow-outline"
							name="message"
							value={message}
							onChange={handleInputChange}
							placeholder="💬 Message"
						/>
					</div>
					<div className="flex items-center justify-between">
						<button
							className="border-0 bg-green-500 mb-4 hover:bg-green-600 text-white transition duration-200 ease-in-out font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="submit"
						>
							💥 Submit 💥
						</button>
						<div className="text-purple-700">{formStatus} 😎</div>
					</div>
				</form>
			</div>
		</section>
	);
}


function features() {
	return (
		<section id="keyFeatures" className="rounded-lg bg-white py-16 px-4 mt-3">
			<div className="container mx-auto">
				<h2 className="text-4xl font-bold mb-8 text-center text-green-500 funky-text">
					🎉 Key Features of Mapbond 🎉
				</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					{featureData.map((f, index) => (
						<div
							key={index}
							className="bg-white shadow-lg rounded-lg p-8 transform hover:-translate-y-1 hover:scale-95 transition duration-200 ease-in-out funky-border"
						>
							<h3 className="text-2xl font-bold mb-4 text-gray-800">{f.key}</h3>
							<p className="text-gray-700 hover:rainbow-text mb-6">
								{f.details}
							</p>
							<a
								className="inline-block px-6 py-3 rounded-full bg-green-500 transition duration-200 ease-in-out shadow-lg shadow-xl"
								href="#"
							>
								<p className="text-yellow-100 hover:text-white">
									🚀 Learn More 🚀
								</p>
							</a>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
