import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

/***
 * A unified way of dealing with navigation and routing.
 * Took inspiration from Next.js
 * Includes query, pathname, and history
 * query was derived from [the react-router website
 * ](https://v5.reactrouter.com/web/example/query-parameters)
 * @example
 * //
 * const foo: string = useQuery().get("foo");
 *
 * @returns URL query parameters
 *
 * push -> To navigate the user to a particular page. Accepts the route.
 * @example
 * const router = useRouter()
 * router.push('/path')
 *
 * pathname -> To get the current path the user is currently at
 * @example
 * const router = useRouter()
 * router.pathname
 *
 *
 */

export const useRouter = () => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const push = useCallback((route: string) => {
    history.push(route)
  }, [])

  const query = useMemo(() => new URLSearchParams(search), [search])

  return { push, pathname, query, search }
}