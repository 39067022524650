import React from 'react';

interface SearchBarProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ value, onChange }) => (
  <div className="flex-none px-4 py-2 bg-white border-b border-gray-200">
      <input
          type="text"
          placeholder="Search contacts, users, or locations"
          className="w-full px-2 py-1 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          value={value}
          onChange={onChange}
      />
  </div>
);
