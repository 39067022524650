// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageNotifications_subtext__-DtBb {
  color: grey;
}

.ManageNotifications_addButton__GWWyL {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: var(--accent-color);
  margin-top: 20px;
}
.ManageNotifications_addButton__GWWyL:hover {
  background-color: #f8f8f8;
}
.ManageNotifications_addButton__GWWyL:active {
  background-color: #f0f0f0;
}
.ManageNotifications_addButton__GWWyL .ManageNotifications_addIcon__yhD3R {
  padding: 5px;
  font-size: 1em;
}

.ManageNotifications_subscriptionsList__OKxP9 {
  padding-left: 16px;
}
.ManageNotifications_subscriptionsList__OKxP9 li {
  margin-bottom: 10px;
}
.ManageNotifications_subscriptionsList__OKxP9 li input {
  padding: 5px 10px;
  border-bottom: solid 1px #ccc;
  outline: none;
}
.ManageNotifications_subscriptionsList__OKxP9 li input:focus {
  background-color: #f8f8f8;
  border-radius: 5px;
}
.ManageNotifications_subscriptionsList__OKxP9 li div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ManageNotifications_subscriptionsList__OKxP9 li div button {
  padding-left: 16px;
  color: rgb(219, 52, 52);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/ManageNotifications/ManageNotifications.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iCAAA;EACA,gBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,yBAAA;AAAR;AAGI;EACI,YAAA;EACA,cAAA;AADR;;AAKA;EACI,kBAAA;AAFJ;AAII;EACI,mBAAA;AAFR;AAIQ;EACI,iBAAA;EACA,6BAAA;EACA,aAAA;AAFZ;AAIY;EACI,yBAAA;EACA,kBAAA;AAFhB;AAMQ;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AAJZ;AAMY;EACI,kBAAA;EACA,uBAAA;AAJhB","sourcesContent":[".subtext {\n    color: grey;\n}\n\n.addButton {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    border-color: var(--accent-color);\n    margin-top: 20px;\n\n    &:hover {\n        background-color: #f8f8f8;\n    }\n\n    &:active {\n        background-color: #f0f0f0;\n    }\n\n    .addIcon {\n        padding: 5px;\n        font-size: 1em;\n    }\n}\n\n.subscriptionsList {\n    padding-left: 16px;\n\n    li {\n        margin-bottom: 10px;\n\n        input {\n            padding: 5px 10px;\n            border-bottom: solid 1px #ccc;\n            outline: none;\n\n            &:focus {\n                background-color: #f8f8f8;\n                border-radius: 5px;\n            }\n        }\n\n        div {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n\n            button {\n                padding-left: 16px;\n                color: rgb(219, 52, 52);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtext": `ManageNotifications_subtext__-DtBb`,
	"addButton": `ManageNotifications_addButton__GWWyL`,
	"addIcon": `ManageNotifications_addIcon__yhD3R`,
	"subscriptionsList": `ManageNotifications_subscriptionsList__OKxP9`
};
export default ___CSS_LOADER_EXPORT___;
