import React from "react";

import styles from "./MarkerConsentMessage.module.scss";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import TickAnimation from "../../../Components/TickAnimation/TickAnimation";
import { Visibility, VisibilityWithPublic } from "../visibility";
import AddIcon from "../../../Icons/AddIcon";

/**
 * Tells the user that they need to ask for permission to view a contact or location
 */
export function MarkerConsentMessage({
    handleConsentRequest,
    visibility,
    ownerName,
    contactOrLocation,
}: {
    handleConsentRequest: () => void;
    ownerName: string | null | undefined;
    visibility: Visibility | VisibilityWithPublic;
    contactOrLocation: "contact" | "location";
}) {
    return (
        <div className={styles["consent-message"]}>
            <p>
                You need to ask {ownerName}, the owner of this {contactOrLocation} for
                consent in order to see it
            </p>
            <div className={styles["button-container"]}>
                {visibility === "loading" ? (
                    <button disabled>
                        <SmallLoadingSpinner
                            size={13}
                            margin="0 6px 3px 0"
                            primaryColor="grey"
                        />{" "}
                        Loading
                    </button>
                ) : visibility === "needs request" ? (
                    <button onClick={() => handleConsentRequest()}>
                        <AddIcon
                            color="currentColor"
                            margin="0 6px 2px 0"
                            lineLength={12}
                            lineWidth={2}
                        />
                        Send request
                    </button>
                ) : visibility === "requested" ? (
                    <button onClick={() => handleConsentRequest()}>
                        <TickAnimation
                            color="var(--accent-color)"
                            lineThickness="2px"
                            margin="0 6px 6px 0"
                            height="6px"
                            width="13px"
                        />
                        Request sent
                    </button>
                ) : null}
            </div>
        </div>
    );
}
