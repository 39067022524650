// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HttpErrorMessage_error-box__rP3Qu {
  width: -moz-fit-content;
  width: fit-content;
  color: #f55;
  background-color: #fcc;
  border: 4px solid #f99;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.HttpErrorMessage_error-box__rP3Qu .HttpErrorMessage_x__zhO4n {
  border-radius: 40px;
  border: 4px solid currentColor;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
}
.HttpErrorMessage_error-box__rP3Qu > * {
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/HttpErrorMessage/HttpErrorMessage.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AACI;EACI,mBAAA;EACA,8BAAA;EACA,uBAAA;EAAA,kBAAA;EACA,aAAA;AACR;AAEI;EACI,YAAA;AAAR","sourcesContent":[".error-box {\n    width: fit-content;\n    color: #f55;\n    background-color: #fcc;\n    border: 4px solid #f99;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-weight: 500;\n\n    .x {\n        border-radius: 40px;\n        border: 4px solid currentColor;\n        width: fit-content;\n        padding: 10px;\n    }\n\n    >* {\n        margin: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-box": `HttpErrorMessage_error-box__rP3Qu`,
	"x": `HttpErrorMessage_x__zhO4n`
};
export default ___CSS_LOADER_EXPORT___;
