import React from 'react';
import HttpErrorMessage from '../../HttpErrorMessage/HttpErrorMessage';
import { ApiError } from 'hooks/useAPI';

export default function ContextMenuError({ error }: { error?: ApiError }) {
	return (
		<div
			style={{
				padding: 0,
				fontFamily: 'var(--website-font)',
				fontSize: 'large',
				color: '#666',
				maxWidth: '220px',
			}}
		>
			<HttpErrorMessage
				direction="column"
				error={error}
				hideBorderAndBackground
				color="inherit"
			/>
		</div>
	);
}
