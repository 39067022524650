import React, { CSSProperties } from "react";
import { Property } from "csstype";

import styles from "./TickAnimation.module.scss";

/**
 * An animation depicting a checkmark or, as the name
 * suggests, ticking yes using a pen. It's made using
 * plain html and css as it allows for easy and dynamic
 * resizing and styling
 */
export default function TickAnimation({
  margin,
  color,
  lineThickness,
  width,
  height,
  duration,
}: {
  margin?: Property.Margin;
  color?: string;
  lineThickness?: string;
  /** The length of the shorter line */
  width?: string;
  /** The length of the longer line */
  height?: string;
  /** Animation duration */
  duration?: number;
}) {
  return (
    <div
      className={styles["tick-animation-container"]}
      style={
        {
          margin,
          "--height": height || "7px",
          "--width": width || "15px",
          "--line-thickness": lineThickness || "3px",
          "--duration": `${typeof duration === "number" ? duration : 0.7}s`,
        } as CSSProperties
      }
    >
      <div>
        <div style={{ backgroundColor: color }}></div>
        <div style={{ backgroundColor: color }}></div>
      </div>
    </div>
  );
}
