import React, { ReactNode } from "react";
import styles from "./CustomDialogTitle.module.scss"; // Add your custom styles here
import { CloseIcon } from "../../Icons/CloseIcon"; // import the CloseIcon

interface CustomDialogTitleProps {
    children: ReactNode;
    id?: string;
    onClose?: () => void; // Make sure the type is correct
    open?: boolean;
}

const CustomDialogTitle: React.FC<CustomDialogTitleProps> = ({
    children,
    id,
    onClose,
}) => {
    return (
        <div className={styles["custom-dialog-title"]} id={id}>
            {children}
            {onClose && (
                <button onClick={onClose} className={styles["close-button"]}>
                    <CloseIcon />
                </button>
            )}
        </div>
    );
};

export default CustomDialogTitle;
