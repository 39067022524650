// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsSubpage_outer-container__LyyJi {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  min-height: 100%;
}
.SettingsSubpage_outer-container__LyyJi .SettingsSubpage_inner-container__us6Z5 {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  box-shadow: var(--shadow);
  background-color: white;
}
@media screen and (min-width: 600px) {
  .SettingsSubpage_outer-container__LyyJi .SettingsSubpage_inner-container__us6Z5 {
    padding: 30px 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/SettingsSubpage/SettingsSubpage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EAEA,yBAAA;EACA,uBAAA;AAAR;AAEQ;EARJ;IASQ,kBAAA;EACV;AACF","sourcesContent":[".outer-container {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: #f8f8f8;\n    min-height: 100%;\n\n    .inner-container {\n        max-width: 800px;\n        width: 100%;\n        padding: 20px;\n        // border-radius: 5px;\n        box-shadow: var(--shadow);\n        background-color: white;\n\n        @media screen and (min-width: 600px) {\n            padding: 30px 40px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer-container": `SettingsSubpage_outer-container__LyyJi`,
	"inner-container": `SettingsSubpage_inner-container__us6Z5`
};
export default ___CSS_LOADER_EXPORT___;
