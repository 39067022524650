import React from "react";
import { Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Home from "Pages/Home/Home";
import Messages from "Pages/Messages/Messages";
import Notifications from "Pages/Notifications/Notifications";
import Settings from "Pages/Settings/Settings";
import UserProfile from "Pages/UserProfile/UserProfile";
import LocationProfile from "Pages/LocationProfile/LocationProfile";
import NotFound from "Pages/NotFound/NotFound";
import DeleteAccount from "Pages/Settings/DeleteAccount/DeleteAccount";
import ManagePermissions from "Pages/Settings/ManagePermissions/ManagePermissions";
import LandingPage from "Pages/LandingPage/LandingPage";
import { PrivacyPolicy } from "Pages/LandingPage/PrivacyPolicy";
import { RouterChat } from "Components/TawkTo/routerChat";
import ViewKeepInTouch from "Pages/Settings/ViewKeepInTouch/ViewKeepInTouch";
import DuplicateContacts from "Pages/Settings/DuplicateContacts/DuplicateContacts";
import CoordinateClusters from "Pages/Settings/CoordinateClusters/CoordinateClusters";
import StatisticsPage from "Pages/Settings/StatisticsPage/StatisticsPage";
import CommonInterestsPage from "Pages/Settings/CommonInterestsPage/CommonInterestsPage";
import SignUpForm from "Pages/Accounts/SignUp";
import SignInForm from "Pages/Accounts/SignIn";
import { useAuthContext } from "Context/AuthContext";
import OnboardingForm from "Pages/Accounts/Onboarding";
import NetworkPage from "Pages/Networks/NetworkPage";
import JournalViewAll from "Pages/Journals/JournalViewAll";
import MapbondCSVPage from "Pages/MapbondCSV";
import NetworkView from "Pages/Networks/NetworkView/NetworkView";
import ManageConnections from "Pages/Settings/ManageConnections/ManageConnections";
import InvitationAcceptance from "Pages/Accounts/InvitationAcceptance";
import { CurrentUserDetailsProvider } from "Context/CurrentUserDetailsContext";
import ManageNotifications from "Pages/Settings/ManageNotifications/ManageNotifications";
import PasswordResetForm from "Pages/Accounts/PasswordReset";

import AppRoute from "./AppRoute"; // Import the AppRoute component

/**
 * App: The main component of the app. It contains the navbar and the
 * routing logic.
 * @returns
 * @constructor
 * @example
 * <App />
 */
const App = () => {
    const { isAuthenticated } = useAuthContext();

    return (
        <CurrentUserDetailsProvider>
            <main className="wrapper">
                {isAuthenticated && <RouterChat />}
                <Switch>
                    {/** Only show the mapview page when the user is authenticated */}
                    {isAuthenticated ? (
                        <AppRoute path="/" exact isProtected={true}>
                            <Home />
                        </AppRoute>
                    ) : (
                        <AppRoute path="/" exact>
                            <LandingPage />
                        </AppRoute>
                    )}
                    <AppRoute path="/about/mapbond-csv" exact>
                        <MapbondCSVPage />
                    </AppRoute>
                    <AppRoute path="/privacy-policy" exact>
                        <PrivacyPolicy />
                    </AppRoute>
                    {/** Auth Routes */}
                    <AppRoute isProtected={false} path="/accounts/signup" exact>
                        <SignUpForm />
                    </AppRoute>
                    <AppRoute isProtected={false} path="/accounts/login" exact>
                        <SignInForm />
                    </AppRoute>
                    <AppRoute
                        path="/accounts/onboarding"
                        exact
                        showNavbar={false}
                        isProtected={true}
                    >
                        <OnboardingForm />
                    </AppRoute>
                    <AppRoute
                        path="/accounts/invitation/:invitationToken"
                        exact
                        showNavbar={false}
                    >
                        <InvitationAcceptance />
                    </AppRoute>
                    <AppRoute isProtected={false} path="/password/reset/" exact>
                        <PasswordResetForm />
                    </AppRoute>
                    {/** Settings Routes */}
                    <AppRoute isProtected={true} path="/settings" exact>
                        <Settings />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/delete-account"
                        exact
                    >
                        <DeleteAccount />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/manage-connections"
                        exact
                    >
                        <ManageConnections />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/manage-notifications"
                        exact
                    >
                        <ManageNotifications />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/manage-permissions"
                        exact
                    >
                        <ManagePermissions />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/common-interests"
                        exact
                    >
                        <CommonInterestsPage />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/statistics"
                        exact
                    >
                        <StatisticsPage />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/duplicate-contacts"
                        exact
                    >
                        <DuplicateContacts />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/coordinate-clusters"
                        exact
                    >
                        <CoordinateClusters />
                    </AppRoute>
                    <AppRoute
                        isProtected={true}
                        path="/settings/keep-in-touch"
                        exact
                    >
                        <ViewKeepInTouch />
                    </AppRoute>
                    {/* Other routes */}
                    <AppRoute path="/messages" isProtected={true}>
                        <Messages />
                    </AppRoute>
                    <AppRoute path="/notifications" isProtected={true}>
                        <Notifications />
                    </AppRoute>
                    <AppRoute path="/@:userHandle" isProtected={true}>
                        <UserProfile />
                    </AppRoute>
                    <AppRoute
                        path="/location/:locationHandle"
                        isProtected={true}
                    >
                        <LocationProfile
                        />
                    </AppRoute>
                    <AppRoute path="/network" isProtected={true} exact>
                        <NetworkPage />
                    </AppRoute>
                    <AppRoute path="/network/:networkId" isProtected={true}>
                        <NetworkView />
                    </AppRoute>
                    <AppRoute isProtected={true} path="/journal">
                        <JournalViewAll />
                    </AppRoute>
                    {/* <AppRoute
                        isProtected={true}
                        path="/journal/:journal_id"
                        exact
                    >
                        <JournalOneView />
                    </AppRoute> */}
                    <AppRoute path="*">
                        <NotFound />
                    </AppRoute>
                </Switch>
            </main>
        </CurrentUserDetailsProvider>
    );
};

export default App;

