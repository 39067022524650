import React, { useEffect, useState } from 'react';

interface CountAnimationProps {
  value: number;
  duration: number;
  formatter?: (value: number) => string;
}

/**
 * Counts from 0 to the specified value with an animation.
 * @param value - The value to count to.
 * @param duration - The duration of the animation in milliseconds.
 * @param formatter - A function to format the count value.
 * @returns A React element that displays the animated count.
 */
function CountAnimation({ value, duration, formatter = (value) => `${value}` }: CountAnimationProps) {
  const [animatedValue, setAnimatedValue] = useState(0);
  const [isReverting, setIsReverting] = useState(false);

  useEffect(() => {
    let start = 0;
    const easeOutQuad = (t: number) => t * (2 - t);

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      const easedProgress = easeOutQuad(progress);
      const currentValue = Math.floor(easedProgress * value);
      setAnimatedValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        setIsReverting(true); // Start the font size reset animation
      }
    };

    requestAnimationFrame(step);
  }, [value, duration]);

  // Calculate font size based on animation progress with a maximum size of 3 times the original
  const originalFontSize = 16; // Original font size
  const maxFontSize = originalFontSize * 3; // Maximum allowed font size
  const fontSize = isReverting ? originalFontSize : originalFontSize + Math.min(animatedValue * 2, maxFontSize - originalFontSize);

  // Apply CSS style to change font size with a transition
  const style = {
    fontSize: `${fontSize}px`,
    transition: 'font-size 0.3s ease', // Adjust the duration and easing as needed
  };

  return <span style={style}>{formatter(animatedValue)}</span>;
}

export default CountAnimation;
