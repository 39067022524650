import React, { CSSProperties } from "react";

import styles from "./CustomCheckbox.module.scss";
import TickAnimation from "../TickAnimation/TickAnimation";

/**
 * A custom checkbox
 */
export default function CustomCheckbox({
  checked,
  className,
  accentColor,
  onChange,
  disabled,
  animate,
}: {
  checked: boolean;
  /** Custom classname to attach extra styles */
  className?: string;
  /** The color of the checkbox */
  accentColor?: string;
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
  /** Whether to animated the tick */
  animate?: boolean;
}) {
  return (
    <span
      className={`${styles["custom-checkbox"]} ${
        checked ? styles["checked"] : ""
      } ${className || ""}`}
      style={
        {
          backgroundColor: checked ? accentColor : undefined,
          borderColor: checked ? accentColor : undefined,
          opacity: disabled ? 0.4 : 1,
          "--transition-duration": animate === false ? "0" : undefined,
        } as CSSProperties
      }
      onClick={() => onChange && onChange(!checked)}
    >
      {checked && (
        <TickAnimation
          lineThickness="3px"
          color="white"
          margin="0 0 3px 0"
          width="14px"
          duration={animate === false ? 0 : undefined}
        />
      )}
    </span>
  );
}
