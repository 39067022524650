// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDialog_custom-dialog__w\\+zU1 {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.CustomDialog_custom-dialog__w\\+zU1 .CustomDialog_dialog-content__MiI30 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}
.CustomDialog_custom-dialog__w\\+zU1 .CustomDialog_custom-dialog-close__zK-SR {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.CustomDialog_custom-dialog__w\\+zU1 .CustomDialog_custom-dialog-close__zK-SR:hover {
  color: red;
}
.CustomDialog_custom-dialog__w\\+zU1.CustomDialog_open__G1DMc {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomDialog/CustomDialog.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,aAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,kCAAA;UAAA,0BAAA;AACJ;AACI;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,2CAAA;AACN;AAEI;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;AAAN;AACM;EACE,UAAA;AACR;AAGI;EACE,cAAA;AADN","sourcesContent":[".custom-dialog {\n    display: none; // hidden by default\n    position: fixed;\n    z-index: 1000;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); // semi-transparent background\n    backdrop-filter: blur(5px); // blurred background\n  \n    .dialog-content {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      width: 80%;\n      max-width: 400px;\n      padding: 20px;\n      background-color: #ffffff;\n      border-radius: 5px;\n      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);\n    }\n  \n    .custom-dialog-close {\n      background: none;\n      border: none;\n      font-size: 18px;\n      cursor: pointer;\n      &:hover {\n        color: red;\n      }\n    }\n  \n    &.open {\n      display: block;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-dialog": `CustomDialog_custom-dialog__w+zU1`,
	"dialog-content": `CustomDialog_dialog-content__MiI30`,
	"custom-dialog-close": `CustomDialog_custom-dialog-close__zK-SR`,
	"open": `CustomDialog_open__G1DMc`
};
export default ___CSS_LOADER_EXPORT___;
