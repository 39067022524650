// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TravelPlanPopup_travel-plan-popup__ggs5A {
  font-family: var(--website-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  padding-bottom: 5px;
  min-width: 240px;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_header__hgNlR {
  margin: 5px;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_sub-header__fbccR {
  color: #555;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_locations__Pu8B3 {
  margin: 15px 0;
  text-align: center;
  font-size: 1.25em;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_dates__SfWTe {
  margin: 10px 0;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_dates__SfWTe b {
  font-weight: 500;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_text-details__8sMqn {
  margin: 5px 0;
}
.TravelPlanPopup_travel-plan-popup__ggs5A .TravelPlanPopup_inner-container__tmvx9 .TravelPlanPopup_text-details__8sMqn div {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/TravelPlan/TravelPlanPopup/TravelPlanPopup.module.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACR;AACQ;EACI,WAAA;AACZ;AAEQ;EACI,WAAA;AAAZ;AAGQ;EACI,cAAA;EACA,kBAAA;EACA,iBAAA;AADZ;AAIQ;EACI,cAAA;AAFZ;AAIY;EACI,gBAAA;AAFhB;AAMQ;EACI,aAAA;AAJZ;AAMY;EACI,cAAA;AAJhB","sourcesContent":[".travel-plan-popup {\n    font-family: var(--website-font);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    .inner-container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        text-align: center;\n        padding: 20px;\n        padding-bottom: 5px;\n        min-width: 240px;\n\n        .header {\n            margin: 5px;\n        }\n\n        .sub-header {\n            color: #555;\n        }\n\n        .locations {\n            margin: 15px 0;\n            text-align: center;\n            font-size: 1.25em;\n        }\n\n        .dates {\n            margin: 10px 0;\n\n            b {\n                font-weight: 500;\n            }\n        }\n\n        .text-details {\n            margin: 5px 0;\n\n            div {\n                margin: 10px 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"travel-plan-popup": `TravelPlanPopup_travel-plan-popup__ggs5A`,
	"inner-container": `TravelPlanPopup_inner-container__tmvx9`,
	"header": `TravelPlanPopup_header__hgNlR`,
	"sub-header": `TravelPlanPopup_sub-header__fbccR`,
	"locations": `TravelPlanPopup_locations__Pu8B3`,
	"dates": `TravelPlanPopup_dates__SfWTe`,
	"text-details": `TravelPlanPopup_text-details__8sMqn`
};
export default ___CSS_LOADER_EXPORT___;
