import {useState,useMemo,useCallback,useEffect} from 'react'
import { geocodeService } from 'services/geocoding-service'



export const useGeocoder = (userInput?:string)=>{

	const [searchResult,setSearchResult] = useState([])

	const showSearchResult = useMemo(

		()=>searchResult.length>0 &&
		(userInput ? userInput.length > 2:false) ,
	[searchResult,userInput])

	const fetchGeocodingResult = useCallback(async ()=>{
		const res = await geocodeService(userInput)
		setSearchResult(res)
	}
	,[userInput])

	useEffect(()=>{
		if(!userInput || userInput==='') return
		fetchGeocodingResult()
	},[userInput,fetchGeocodingResult])




    return {
        showSearchResult,searchResult,setSearchResult
    }

}
