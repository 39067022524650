import React, { useCallback, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import { Marker } from "react-leaflet";

import { getContextMenuStyle } from "../../../Components/ContextMenu/ContextMenu";
import { ContactData } from "../HomePageTypes";
import { usePopupOpeningEffect } from "hooks/map/usePopupOpeningEffect";

import { DispatchAction } from "../reducer";
import { ContactEditMarkerPopup } from "./ContactEditMarkerPopup/ContactEditMarkerPopup";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";

import {ContactMarkerIcon}  from "Components/ContactMarkerIcon/ContactMarkerIcon";
/**
 * Used to edit an existing contact or add details of a new one.
 */
export default function ContactEditMarker({
    contact,
    state,
    dispatch,
}: {
    contact: ContactData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [popupIsOpen, setPopupIsOpen] = useState(true);
    let { markerRefCallback, markerRef } = usePopupOpeningEffect(
        popupIsOpen,
        useCallback(() => setPopupIsOpen(false), [setPopupIsOpen])
    );

    const onContextMenu = (event: LeafletMouseEvent) => {
        let mouseEvent = event.originalEvent;
        let style = getContextMenuStyle(mouseEvent);
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style, contact, type: "contact" },
        });
    };

    let setContactBeingEdited = (contact: ContactData | null) => {
        dispatch({
            type: ACTIONS.contactBeingEdited,
            value:
                contact && state.contactBeingEdited
                    ? {
                          data: contact,
                          editingOrNew: state.contactBeingEdited.editingOrNew,
                      }
                    : null,
        });
    };
    const icon = ContactMarkerIcon(contact);

    return (
        <div>
            {contact.coordinates ? (
                <Marker
                    position={[contact.coordinates.y, contact.coordinates.x]}
                    icon={icon}
                    eventHandlers={{
                        contextmenu: onContextMenu,
                        dragend: () => {
                            if (markerRef.current) {
                                let latLong = markerRef.current.getLatLng();
                                setContactBeingEdited({
                                    ...contact,
                                    coordinates: {
                                        x: latLong.lng,
                                        y: latLong.lat,
                                    },
                                });
                            }
                        },
                    }}
                    ref={markerRefCallback}
                    draggable
                    riseOnHover
                    title="Click and hold me to move the Contact"
                >
                    <ContactEditMarkerPopup
                        editedContact={contact}
                        setContactBeingEdited={setContactBeingEdited}
                        dispatch={dispatch}
                        state={state}
                    />
                </Marker>
            ) : null}
        </div>
    );
}
