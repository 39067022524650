import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./NetworkView.module.scss";
import AddNetwork from "Components/NetworkGroups/AddNetwork";
import { BasicNetworkData } from "Components/NetworkGroups/BasicNetworkDataTypes";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import { useDocumentTitle } from "hooks/useSEO";
import { apiRoutes } from "services/routes";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

import DeleteNetworkDialog from "./DeleteNetworkDialog/DeleteNetworkDialog"; // Adjust the path as needed
import NetworkHeader from './NetworkHeader/NetworkHeader'; // Adjust the path as needed
import MemberSections from './MemberSections/MemberSections'; // Adjust the path

export default function NetworkView() {
    useDocumentTitle("Your Networks");

    const [network, setNetwork] = useState<BasicNetworkData | null>(null);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    let { networkId } = useParams<{ networkId: string }>();
    const apiInstance = useAxiosPrivate();
    let { currentUser } = useCurrentUserDetails();

    const getNetwork = () => {
        let apiURL: string =
            apiRoutes.UPDATE_OR_DELETE_OR_GET_NETWORK(networkId);
        apiInstance
            .get(apiURL)
            .then(async (response) => {
                let res = await response.data;
                response.status >= 300
                    ? console.log(response)
                    : setNetwork(res?.data);
            })
            .catch((reason) => {
                console.log(reason);
                //   setData(new ApiError(reason, 0));
                //   setFetchCount((count) => count + 1);
            });
    };

    const removeMember = (
        type: "user" | "contact" | "place",
        memberId: Number
    ) => {
        let apiURL: string = apiRoutes.REMOVE_NETWORK_MEMBERS;
        let body = {
            id: network?.id,
            member_type: type,
            member_id: memberId,
        };
        apiInstance
            .post(apiURL, JSON.stringify(body))
            .then(async (response) => {
                if (response.status < 300) {
                    console.log("member removed", await response.data);
                } else {
                    // setErrors(await response.json());
                    console.log("error", await response.data);
                }
            });

        setNetwork((prevNetwork) => {
            if (!prevNetwork) {
                return null; // Return null if the network is not defined
            }

            if (type === "user") {
                const updatedConnections =
                    prevNetwork.member_connections.filter(
                        (connection) => connection.id !== memberId
                    );
                return {
                    ...prevNetwork,
                    member_connections: updatedConnections,
                };
            } else if (type === "contact") {
                const updatedContacts = prevNetwork.member_contacts.filter(
                    (connection) => connection.id !== memberId
                );
                return {
                    ...prevNetwork,
                    member_contacts: updatedContacts,
                };
            } else {
                const updatedLocations = prevNetwork.member_locations.filter(
                    (connection) => connection.id !== memberId
                );
                return {
                    ...prevNetwork,
                    member_locations: updatedLocations,
                };
            }
        });
    };

    const isOwner = (id: Number) => {
        return network?.owner.id === id;
    };

    const isNetworkOwner = () => {
        if (currentUser && network?.owner.id === currentUser.id) {
            return true;
        } else {
            return false;
        }
    };

    const confirmDeleteNetwork = () => {
        let apiURL: string = apiRoutes.UPDATE_OR_DELETE_OR_GET_NETWORK(
            network?.id
        );
        apiInstance
            .delete(apiURL)
            .then((response) => {
                window.location.replace("/network");
            })
            .catch((reason) => {
                console.log(reason);
            });
    };

    useEffect(() => {
        getNetwork();
    }, [editModal]);

    return (
        <main className={styles["network_view"]}>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-12">
                    <div className="mx-auto space-y-8">
                        {network ? (
                            <>
                                <NetworkHeader
                                    network={network}
                                    currentUser={currentUser}
                                    setEditModal={setEditModal}
                                    setDeleteModal={setDeleteModal}
                                />
                                <MemberSections
                                    network={network}
                                    removeMember={removeMember}
                                    isOwner={isOwner}
                                    isNetworkOwner={isNetworkOwner}
                                />                            
                            </>
                        ) : (
                            <OverlayingLoadingSpinner />
                        )}
                    </div>
                    {network && editModal && (
                        <AddNetwork
                            initialValue={network}
                            modalOpen={setEditModal}
                        />
                    )}
                    <DeleteNetworkDialog
                        open={deleteModal}
                        onClose={() => setDeleteModal(false)}
                        onConfirm={confirmDeleteNetwork}
                        networkName={network?.name || ''}
                    />
                </div>
            </div>
        </main>
    );
}
