import React, { ChangeEvent, useState } from 'react';
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import { KeepInTouchType } from '../HomePageTypes';

import { HomePageState } from "Pages/Home/HomePageState";
import TimeInputComponent from "./TimeInputComponent";
import ReasonForContactInput from './ReasonForContactInput'; // Import the new component
import TitleHeader from './TitleHeader'; // Import the new component
import MagicButton from './MagicButton'; // Import the new component

enum TimeUnit {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years'
}

type InputValuesProps = {
  inputValues: KeepInTouchType | null;
  id: number;
  state: HomePageState;
  UserOrContact: "user" | "contact"
};

export function KeepInTouch({
  inputValues,
  id,
  state,
  UserOrContact
}: InputValuesProps) {
  const { postToApi } = usePostToAPI();

  const [minimumTimeInput, setMinimumTimeInput] = useState<number>(
    (inputValues?.minimum_time_input !== null && inputValues?.minimum_time_input !== undefined)
      ? inputValues.minimum_time_input
      : 0
  );
  
  const [minimumTimeType, setMinimumTimeType] = useState<string>(
    (inputValues?.minimum_time_type) || TimeUnit.Days
  );
  
  const [maximumTimeInput, setMaximumTimeInput] = useState<number>(
    (inputValues?.maximum_time_input !== null && inputValues?.maximum_time_input !== undefined)
      ? inputValues.maximum_time_input
      : 0
  );
  
  const [maximumTimeType, setMaximumTimeType] = useState<string>(
    (inputValues?.maximum_time_type) || TimeUnit.Days
  );
  
  const [reasonForContact, setReasonForContact] = useState<string>(
    inputValues?.reason_for_contact || ''
  );

  const handleInputChange = (name: string, inputValue: number) => {
    if (name === 'minimum_time_input') {
      setMinimumTimeInput(inputValue);
    } else if (name === 'maximum_time_input') {
      setMaximumTimeInput(inputValue);
    }
  };

  const handleSelectChange = (name: string, newUnit: TimeUnit) => {    
    if (name === 'minimum_time_type') {
      setMinimumTimeType(newUnit);
    } else if (name === 'maximum_time_type') {
      setMaximumTimeType(newUnit);
    }
  };

const handleReasonForContactChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
  setReasonForContact(e.target.value);
};

const saveKeepInTouch = () => {
  console.log('save keep in touch');
  console.log('[TimeInputManager][ContactId] save keep in touch', id);
  postToApi(
    `${apiRoutes.CREATE_CONNECTIONS_KEEP_IN_TOUCH(id, UserOrContact)}`,
    {
      minimum_time_input: minimumTimeInput,
      minimum_time_type: minimumTimeType,
      maximum_time_input: maximumTimeInput,
      maximum_time_type: maximumTimeType,
      reason_for_contact: reasonForContact,
    }
  );
      state.keepInTouchAdded = null;
};


return (
  <div>
      <TitleHeader title="Frequency keep in touch" />
      <ReasonForContactInput
        reasonForContact={reasonForContact}
        handleReasonForContactChange={handleReasonForContactChange}
      />
    <TimeInputComponent
        label="Min"
        name="minimum_time"
        timeValue={minimumTimeInput}
        timeUnit={minimumTimeType}
        onValueChange={handleInputChange}
        onUnitChange={handleSelectChange}
      />

      <TimeInputComponent
        label="Max"
        name="maximum_time"
        timeValue={maximumTimeInput}
        timeUnit={maximumTimeType}
        onValueChange={handleInputChange}
        onUnitChange={handleSelectChange}
      />
 
   <MagicButton label="Submit" onClick={saveKeepInTouch} />
  </div>
);
}

export default KeepInTouch;