import L from "leaflet";
import { latLng } from "leaflet";
import { Circle, Marker } from "react-leaflet";

import styles from "./Cluster.module.scss";
import { ClusterData } from "../HomePageTypes";
import { UserAppearanceConfig } from "types/shared";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
import { defaultAppearanceSettings } from "Pages/Settings/AppearanceSettings/defaultAppearanceSettings";

export default function Cluster({
    clusterData,
    zoomToBounds,
}: {
    clusterData: ClusterData;
    zoomToBounds: (bounds: L.LatLngBounds) => void;
}) {
    let { centroid, count, radius } = clusterData;
    let { currentUser } = useCurrentUserDetails();

    const appearanceConfig =
        currentUser?.appearanceConfig || defaultAppearanceSettings;

    return (
        <>
            <Circle
                center={latLng(centroid.y, centroid.x)}
                radius={radius}
                stroke
                weight={appearanceConfig.cluster_size}
                color={appearanceConfig.cluster_color || "var(--accent-color)"}
                opacity={0.4}
            />
            <Circle
                center={latLng(centroid.y, centroid.x)}
                radius={radius}
                stroke
                weight={(appearanceConfig.cluster_size || 50) * 0.8}
                color={appearanceConfig.cluster_color || "var(--accent-color)"}
                opacity={0.8}
            />
            <Marker
                position={latLng(centroid.y, centroid.x)}
                riseOnHover
                icon={getClusterNumberIcon(
                    count,
                    appearanceConfig.cluster_size || 50
                )}
                eventHandlers={{
                    click: () => {
                        const west = centroid.x - radius;
                        const south = centroid.y - radius;
                        const east = centroid.x + radius;
                        const north = centroid.y + radius;

                        zoomToBounds(
                            L.latLngBounds(
                                new L.LatLng(south, west),
                                new L.LatLng(north, east)
                            )
                        );
                    },
                }}
            />
        </>
    );
}

function getClusterNumberIcon(count: number, size: number) {
    return L.divIcon({
        html: `<span>${count}</span>`,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        className: styles.icon,
    });
}
