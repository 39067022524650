import React, { useState } from "react";

import styles from "./TextDetails.module.scss";
import { FaEdit } from 'react-icons/fa';
import { PersonalProfileData } from "../../Settings";
import { CoordinatesSelectionMap } from "../../../../Components/CoordinatesSelectionMap/CoordinatesSelectionMap";
import { CoordinatesLink } from "../../../../Components/CoordinatesLink/CoordinatesLink";

import AddressManager from 'Components/AddressManager/AddressManager';
import AddressList from 'Components/AddressManager/AddressList';


export function TextDetails({
  setProfileData,
  profileData,
}: {
  setProfileData: (newProfileData: PersonalProfileData) => void;
  profileData: PersonalProfileData;
}) {
  const updateTextField =
    (fieldName: string) =>
    (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setProfileData({
        ...profileData,
        [fieldName]: event.currentTarget.value,
      });

  const updateLocationUsingGps = () =>
    navigator.geolocation.getCurrentPosition(
      (pos) =>
        setProfileData({
          ...profileData,
          coordinates: {
            x: pos.coords.longitude,
            y: pos.coords.latitude,
          },
        }),
      (error) => alert("Failed to get your location: " + error.message),
      { enableHighAccuracy: true }
    );

  let [showingCoordinatesMap, setShowingCoordinatesMap] = useState(false);

  return (

    <div className={styles["profile-container"]}>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="first_name">First name:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <input
            type="text"
            placeholder="Enter your first name"
            id="first_name"
            value={profileData.first_name}
            onInput={updateTextField("first_name")}
            maxLength={254}
          />
        </div>
      </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="last_name">Last name:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <input
            type="text"
            placeholder="Enter your last name"
            id="last_name"
            value={profileData.last_name}
            onInput={updateTextField("last_name")}
            maxLength={254}
          />
        </div>
      </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="occupation">Occupation:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <input
            type="text"
            placeholder="Enter your occupation"
            id="occupation"
            value={profileData.occupation}
            onInput={updateTextField("occupation")}
            maxLength={64}
          />
        </div>
      </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="bio">Bio:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <textarea
            name="bio"
            placeholder="Enter your bio"
            id="bio"
            value={profileData.bio}
            onInput={updateTextField("bio")}
          ></textarea>
        </div>
      </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="email">Email:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <input
            type="text"
            placeholder="Enter your email"
            id="email"
            value={profileData.email}
            onInput={updateTextField("email")}
            maxLength={254}
          />
        </div>
      </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="phone-number">Phone Number:</label>
        </div>
        <div className={styles["input-group-right"]}>
          <input
            type="text"
            placeholder="Enter your phone number"
            id="phone-number"
            value={profileData.phoneNumber}
            onInput={updateTextField("phoneNumber")}
            maxLength={20}
          />
        </div>
      </div>
      <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="facebook">Facebook:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your Instagram profile URL"
        id="facebook"
        value={profileData.facebook}
        onInput={updateTextField("facebook")}
        maxLength={254}
      />
    </div>
  </div>
      <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="instagram">Instagram:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your Instagram profile URL"
        id="instagram"
        value={profileData.instagram}
        onInput={updateTextField("instagram")}
        maxLength={254}
      />
    </div>
  </div>
  <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="twitter">Twitter:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your Twitter profile URL"
        id="twitter"
        value={profileData.twitter}
        onInput={updateTextField("twitter")}
        maxLength={254}
      />
    </div>
  </div>
  <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="youtube">Youtube:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your Youtube channel URL"
        id="youtube"
        value={profileData.youtube}
        onInput={updateTextField("youtube")}
        maxLength={254}
      />
    </div>
  </div>
  <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="website">Website:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your website URL"
        id="website"
        value={profileData.website}
        onInput={updateTextField("website")}
        maxLength={254}
      />
    </div>
  </div>
  <div className={styles["input-group"]}>
    <div className={styles["input-group-left"]}>
      <label htmlFor="linkedin">LinkedIn:</label>
    </div>
    <div className={styles["input-group-right"]}>
      <input
        type="text"
        placeholder="Enter your LinkedIn profile URL"
        id="linkedin"
        value={profileData.linkedin}
        onInput={updateTextField("linkedin")}
        maxLength={254}
      />
    </div>
  </div>
      <div className={styles["input-group"]}>
        <div className={styles["input-group-left"]}>
          <label htmlFor="coordinates">Your coordinates:</label>
        </div>
        <AddressManager
					content_type='USER'
					object_id={profileData.id}
					/>
					<AddressList 
					entityType='user'
					entityId={profileData.id}
					/>

        <div className={styles["input-group-right"]}>
          <div className={styles["coordinates-container"]}>
            {profileData.coordinates ? (
              <CoordinatesLink
                coords={profileData.coordinates}
                userId={profileData.id}
              />
            ) : null}
            <div className={styles["edit-coordinates-buttons"]}>
              <button onClick={updateLocationUsingGps}>
                <FaEdit />
                Use GPS
              </button>
              <button onClick={() => setShowingCoordinatesMap(true)}>
                <FaEdit />
                Use Map
              </button></div>
          {showingCoordinatesMap ? (
            <CoordinatesSelectionMap
              onClose={() => setShowingCoordinatesMap(false)}
              setCoordinatesValue={(coordinates) =>
                setProfileData({
                  ...profileData,
                  coordinates,
                })
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  </div>
);



  
}



