// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogCloseButton_button__E4kpD {
  padding: 12px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s ease;
  margin-left: 30px;
}

.DialogCloseButton_button__E4kpD:hover {
  background-color: #f0f0f0;
}

.DialogCloseButton_button__E4kpD:active {
  background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/Components/DialogCloseButton/DialogCloseButton.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,6BAAA;EACA,sCAAA;EACA,iBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".button {\n    padding: 12px;\n    border: none;\n    border-radius: 50%;\n    background-color: transparent;\n    transition: background-color 0.3s ease;\n    margin-left: 30px;\n}\n\n.button:hover {\n    background-color: #f0f0f0;\n}\n\n.button:active {\n    background-color: #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DialogCloseButton_button__E4kpD`
};
export default ___CSS_LOADER_EXPORT___;
