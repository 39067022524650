import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";

import { getTileLayer } from "../../Pages/Home/FloatingControls/TileLayerSelector/TileLayerSelector";



/**
 * Manages the map's current tile layer. useTileLayers is used to get the
 * available tile layers and the current tile layer. It also manages the
 * tile layer's opacity. It is used to change the map's background.
 *
 * ---
 * @param mapRef A `Ref` of the map
 * @param tileLayer The name of the tile layer selected
 * @returns A `Ref` object of the tile layer
 */
export function useTileLayers(
    mapRef: React.RefObject<L.Map>,
    tileLayer: string
) {
    let [tileLayerObject, setTileLayerObject] = useState<L.TileLayer | null>(
        null
    );
    let tileLayerRef = useRef<L.TileLayer>(null);

    useEffect(() => {
        if (tileLayerObject && mapRef.current) {
            tileLayerObject.removeFrom(mapRef.current);
        }
        if (tileLayerRef.current && mapRef.current) {
            tileLayerRef.current.removeFrom(mapRef.current);
        }
        if (mapRef.current) {
            let layer = getTileLayer(tileLayer);
            mapRef.current.addLayer(layer);
            setTileLayerObject(layer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tileLayer]);

    return tileLayerRef;
}
