import React, { ReactNode } from 'react';
import styles from './CustomDialogActions.module.scss'; // Update this path if your file structure is different

interface CustomDialogActionsProps {
  children: ReactNode;
}

const CustomDialogActions: React.FC<CustomDialogActionsProps> = ({ children }) => {
  return <div className={styles['custom-dialog-actions']}>{children}</div>;
};

export default CustomDialogActions;
