import React from "react";

import LargeLoadingSpinner from "../../../Components/LargeLoadingSpinner/LargeLoadingSpinner";

export default function PopupLoadingSpinner() {
  return (
    <div style={{ padding: "80px 75px" }}>
      <LargeLoadingSpinner primaryColor="var(--accent-color)" />
    </div>
  );
}
