import React from 'react';
import { UserAppearanceConfig } from "types/shared";
import styles from "./AppearanceSettings.module.scss"; // Adjust the path as necessary

interface ColorInputProps {
    field: keyof UserAppearanceConfig;
    editedConfig: UserAppearanceConfig;
    updateConfigField: (field: keyof UserAppearanceConfig, isNumberField: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({ field, editedConfig, updateConfigField }) => (
    <label
        className={styles["color-input"]}
        style={{ backgroundColor: editedConfig[field] as string }}
    >
        <input
            type="color"
            onChange={updateConfigField(field, false)}
            defaultValue={editedConfig[field]}
        />
    </label>
);

export default ColorInput;
