import React from 'react';
import styles from '../UserProfile.module.scss';

// Define the type for the props
interface UserBioSectionProps {
    bio: string;
}

const UserBioSection: React.FC<UserBioSectionProps> = ({ bio }) => {
    if (bio.length === 0) {
        return null;
    }

    return (
        <>
            <hr style={{ width: "100%" }} />
            <p className={styles["bio"]}>
                <h3 className={styles["bio-heading"]}>Bio</h3>
                <div>{bio}</div>
            </p>
        </>
    );
};

export default UserBioSection;