import React from "react";

import styles from "./AppearanceSettings.module.scss";

import { defaultAppearanceSettings } from "./defaultAppearanceSettings";
import { objectsEqual } from "Utils/utils";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";

import { UserAppearanceConfig } from "types/shared";
import { SettingsAccordion } from "../SettingsAccordion/SettingsAccordion";

import AppearanceConfigSyncer from './AppearanceConfigSyncer'; // Assuming it's in the same directory
import AppearanceSettingsTable from "./AppearanceSettingsTable";

export default function AppearanceSettings({
    appearanceConfig,
    updateAppearanceConfig,
}: {
    appearanceConfig: UserAppearanceConfig;
    updateAppearanceConfig: (
        newValue: UserAppearanceConfig,
        onUpdateFinished: () => void
    ) => void;
}) {

    return (
        <AppearanceConfigSyncer
            appearanceConfig={appearanceConfig}
            updateAppearanceConfig={updateAppearanceConfig}
        >
            {(syncConfig, editedConfig, savingData) => {
                const updateConfigField = (
                    field: keyof UserAppearanceConfig,
                    isNumberField: boolean
                ) => (
                    e: React.ChangeEvent<HTMLInputElement>
                ) => {
                        let newData = {
                            ...editedConfig,
                            [field]: isNumberField ? Number(e.target.value) : e.target.value,
                        };
                        syncConfig(newData);
                    };



                return (
                    <section>
                        <SettingsAccordion>
                            <h3 className={styles["section-heading"]}>
                                Appearance settings
                                {savingData && <SmallLoadingSpinner linethickNess="3px" />}
                                {!objectsEqual(appearanceConfig, defaultAppearanceSettings) ? (
                                    <button
                                        className={styles["reset-button"]}
                                        onClick={() => syncConfig(defaultAppearanceSettings)}
                                    >
                                        Reset
                                    </button>
                                ) : null}
                            </h3>
                            <>

                            <AppearanceSettingsTable 
                                    editedConfig={editedConfig}
                                    appearanceConfig={appearanceConfig}
                                    updateConfigField={updateConfigField}
                                />

                            </>
                        </SettingsAccordion>
                    </section>
                );
            }}
        </AppearanceConfigSyncer>

    );
}
