// useConvertEditorState.ts
import { useState, useEffect } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';

type EditorStateValue = any; // Replace 'any' with a more specific type if possible

const calcState = (value: string): EditorStateValue => {
	return value
		? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
		: EditorState.createEmpty();
};

export const useConvertEditorState = (
	value: string
): [
		EditorStateValue,
		React.Dispatch<React.SetStateAction<EditorStateValue>>
	] => {
	const [editorState, setEditorState] = useState<EditorStateValue>(
		calcState(value)
	);

	useEffect(() => {
		setEditorState(calcState(value));
	}, [value]);

	return [editorState, setEditorState];
};
