import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import StateManager from 'Context/StateManager'
import { ToastContainer } from 'react-toastify'
import AuthProvider from 'react-auth-kit'
import refreshAPIToken from './services/refreshAPI'
import createStore from 'react-auth-kit/createStore'
// This is meant to be a workaround for mobile Chrome's behaviour
// of hiding the address bar when scrolling, which ends up giving
// you a different viewport height (for the CSS vh unit) than the
// actual window height. See https://stackoverflow.com/questions/61264725/chrome-for-android-hides-adress-bar-when-scrolling-expands-background-image-be
const setVerticalHeight = () =>
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  )
setVerticalHeight()
window.addEventListener('resize', setVerticalHeight)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const store = createStore<{}>({
    authName: '__mapbond_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: refreshAPIToken
  })
  
export default function renderApp () {
  root.render(
    <React.StrictMode>
      <AuthProvider store={store}>
        <BrowserRouter>
          <StateManager>
            <App />
            <ToastContainer />
          </StateManager>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  )
}

// // Render the app with the initial user details

renderApp()

// export { renderApp, initialUserDetails };