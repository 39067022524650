// ObjectDisplayAndDelete.tsx
import React, { useState } from "react";
import DeleteObject from "Components/DeleteObject/DeleteObject";
import styles from "./ObjectDisplayAndDelete.module.scss";
import { OwnedObject } from 'Pages/Home/HomePageTypes';
import { DispatchAction } from "Pages/Home/reducer";
import ObjectWithImages from "Components/ObjectWithImages/ObjectWithImages";

import { ACTIONS } from "Pages/Home/reducerActions";

const flattenObjectsArray = (objects: (OwnedObject | OwnedObject[])[]) => {
  return objects.reduce<OwnedObject[]>((acc, object) => {
    if (Array.isArray(object)) {
      // Flatten the array and add its elements to the accumulator
      return acc.concat(object);
    } else {
      // Add the object directly to the accumulator
      acc.push(object);
      return acc;
    }
  }, []);
};

interface ObjectDisplayAndDeleteProps {
  id: number;
  objects: OwnedObject[] ;
  target: "user" | "contact" | "place";
  onDelete: (objectName: string) => void;
  dispatch: React.Dispatch<DispatchAction>; // Assuming you have DispatchAction defined somewhere
  highlightObject?: string;
}

const ObjectDisplayAndDelete: React.FC<ObjectDisplayAndDeleteProps> = ({
  objects,
  id,
  target,
  onDelete,
  dispatch,
  highlightObject,
}) => {
  const [hoveredObject, setHoveredObject] = useState<string | null>(null);
  const flattenedObjects = flattenObjectsArray(objects);


  
  const triggerSearch = (keywords: string) => {
    dispatch({ type: ACTIONS.START_OBJECT_SEARCH, objectName: keywords }); 
  };



  
  const checkIfHighlighted = (element: string) => {
    if (!element) return undefined;
    return highlightObject === element
        ? styles["highlighted"]
        : undefined;
};

  if (objects && objects.length === 0) {
    return null;
  }

  // Add a debug console log here for testing
  console.log("Debugging: ObjectDisplayAndDelete objects", objects);
  console.log("Debugging: ObjectDisplayAndDelete contactId", id);
  console.log("Debugging: ObjectDisplayAndDelete target", target);
  console.log("Debugging: ObjectDisplayAndDelete highlightedObject", highlightObject);

  return (

    <div className={styles["object-display-and-delete-container"]}>
      <ul className={styles["object-display-and-delete-list"]}>

      {flattenedObjects.map((object) => (

          <li
          key={object.id} 
          className={`${checkIfHighlighted(object.name)} ${styles["object-item"]}`}
          onMouseEnter={() => setHoveredObject(object.name)}
          onMouseLeave={() => setHoveredObject(null)}
        >
                      
            <button
              className={`${checkIfHighlighted(object.name)} ${styles["clickable-name"]} clickable-name`}
              onClick={() => triggerSearch(object.name)}
            >
              {object.name}
            </button>
            
            {hoveredObject === object.name && 
            <ObjectWithImages
              object={object}
              contactObjects={objects}
              target={target}
            />
          }

          {hoveredObject === object.name && (
              <DeleteObject
              objectId={object.id}
              id={id}
              objects={objects}
              onDelete={onDelete}
              target={target}
            />
            )}

          </li>
        ))}
      </ul>
    </div>
  );
};

export default ObjectDisplayAndDelete;
