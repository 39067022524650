import React from 'react';
import { LocationImageViewer } from '../../../../LocationProfile/LocationImageViewer/LocationImageViewer';
import ImageEditingControls from '../../../../LocationProfile/ImageEditingControls/ImageEditingControls';
import styles from '../../LocationEditMarker.module.scss'; // Make sure to import the necessary styles
import { LocationData } from '../../../HomePageTypes';

interface LocationImagesControlsProps {
  locationData: LocationData;
  setLocationData: (data: LocationData | null) => void;
  pictureIndex: number;
  setPictureIndex: React.Dispatch<React.SetStateAction<number>>;
}

function LocationImagesControls({
  locationData,
  setLocationData,
  pictureIndex,
  setPictureIndex,
}: LocationImagesControlsProps) {
  return (
    <div className={styles['images']}>
      <LocationImageViewer
        pictures={locationData.pictures}
        setShowingPictureIndex={setPictureIndex}
        showingPictureIndex={pictureIndex}
        maxImageHeight="180px"
      />
      <div className={styles['controls']}>
        <ImageEditingControls
          locationData={locationData}
          setLocationData={setLocationData}
          setShowingPicture={setPictureIndex}
          showingPicture={pictureIndex}
        />
      </div>
    </div>
  );
}

export default LocationImagesControls;
