// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomCheckbox_custom-checkbox__rxUAw {
  --transition-duration: 0.5s;
  height: 18px;
  width: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #808080;
  transition: all var(--transition-duration) cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.CustomCheckbox_checked__zNUGT {
  background-color: var(--accent-color);
  border: 1px solid var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomCheckbox/CustomCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,6BAAA;EACA,yBAAA;EACA,4EAAA;AACJ;;AAEA;EACI,qCAAA;EACA,qCAAA;AACJ","sourcesContent":[".custom-checkbox {\n    --transition-duration: 0.5s;\n    height: 18px;\n    width: 18px;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 4px;\n    background-color: transparent;\n    border: 1px solid #808080;\n    transition: all var(--transition-duration) cubic-bezier(0.5, -0.5, 0.5, 1.5);\n}\n\n.checked {\n    background-color: var(--accent-color);\n    border: 1px solid var(--accent-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-checkbox": `CustomCheckbox_custom-checkbox__rxUAw`,
	"checked": `CustomCheckbox_checked__zNUGT`
};
export default ___CSS_LOADER_EXPORT___;
