import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ProfileObjectsList.module.scss';
import { CloseIcon } from 'Icons/CloseIcon';
import { OwnedObject } from 'Pages/Home/HomePageTypes';
import { ObjectImages } from '../ObjectImages/ObjectImages';
import { FaImage } from 'react-icons/fa';
import { loadImage } from 'Utils/fileLoading';
import AutocompleteInput from '../AutocompleteInput/AutocompleteInput';
import { useAutocompleteSuggestions } from 'hooks/useAutoCompleteSuggestions';
import { apiRoutes } from 'services/routes';

/**
 * A list of objects displayed on a location's
 * or user's profile
 */
export function ProfileObjectsList({
	objectBeingAdded,
	objects,
	onObjectAdded,
	setObjectBeingAdded,
	deleteObject,
	deleteObjectImage,
	uploadObjectImage,
	idOfObjectWithImageBeingUploaded,
}: {
	objects: Array<OwnedObject>;
	/**
	 * When the user has pressed a button to add a new object,
	 * the name of the new object will be this string. Otherwise,
	 * it will just be `null`
	 * */
	objectBeingAdded: string | null;
	/** When the user has finalized adding a new object */
	onObjectAdded: (objectName: string | null) => void;
	setObjectBeingAdded: (object: string | null) => void;
	deleteObject?: (object: OwnedObject) => void;
	uploadObjectImage?: (file: File, dataUrl: string, objectId: number) => void;
	deleteObjectImage?: (imageId: number, objectId: number) => void;
	idOfObjectWithImageBeingUploaded?: number | null;
}) {
	let autocompleteSuggestions = useAutocompleteSuggestions(
		apiRoutes.GET_AUTOCOMPLETE('objects'),
		objectBeingAdded || ''
	);

	return objects.length > 0 || objectBeingAdded !== null ? (
		<>
			<div className={styles.elements}>
				<h4>Objects</h4>
				<ul>
					{objects.map((element, i) => (
						<ObjectElement
							key={i}
							uploadingImage={idOfObjectWithImageBeingUploaded === element.id}
							element={element}
							uploadObjectImage={
								uploadObjectImage && element.canEdit
									? (file, url) =>
											uploadObjectImage &&
											uploadObjectImage(file, url, element.id)
									: undefined
							}
							deleteObjectImage={
								element.canEdit ? deleteObjectImage : undefined
							}
							deleteObject={element.canEdit ? deleteObject : undefined}
						/>
					))}
					{objectBeingAdded !== null && (
						<AutocompleteInput
							autocompleteSuggestions={autocompleteSuggestions.filter(
								(element) =>
									!objects.map((object) => object.name).includes(element)
							)}
							placeholder="Add new object"
							value={objectBeingAdded}
							padding="10px 20px"
							borderRadius="23px"
							setValue={setObjectBeingAdded}
							submitValue={onObjectAdded}
							submitOnBlur={true}
							margin="5px"
							autoFocus
							closeInput={() => {
								setObjectBeingAdded(null);
								onObjectAdded(null);
							}}
						/>
					)}
				</ul>
			</div>
			<hr style={{ width: '100%' }} />
		</>
	) : null;
}

function ObjectElement({
	element,
	uploadingImage,
	uploadObjectImage,
	deleteObjectImage,
	deleteObject,
}: {
	element: OwnedObject;
	uploadingImage: boolean;
	uploadObjectImage?: (file: File, dataUrl: string) => void;
	deleteObjectImage?: (imageId: number, objectId: number) => void;
	deleteObject?: (object: OwnedObject) => void;
}) {
	return (
		<span>
			<li>
				<div className={styles['name']}>
					<Link
						to={`/?search=${encodeURIComponent(
							element.name
						)}&searchFor=Objects`}
					>
						{element.name}
					</Link>
					{element.pictures.length === 0 &&
						!uploadingImage &&
						uploadObjectImage && (
							<button
								onClick={() => loadImage(uploadObjectImage)}
								className={styles['add-image-button']}
							>
								<FaImage />
							</button>
						)}
				</div>
				{(element.pictures.length > 0 || uploadingImage) && (
					<div>
						<ObjectImages
							object={element}
							uploadingImage={uploadingImage}
							addNewImage={uploadObjectImage}
							deleteImage={deleteObjectImage}
						/>
					</div>
				)}
			</li>
			{deleteObject && (
				<button
					className={styles['delete-object-button']}
					onClick={() => deleteObject(element)}
				>
					<CloseIcon color="#999" />
				</button>
			)}
		</span>
	);
}
