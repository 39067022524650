import React, { useRef } from "react";

import styles from "./ContactEditMarkerPopup.module.scss";
import RoundThumbnailImage from "../../../../Components/RoundThumbnailImage/RoundThumbnailImage";
import { FaEdit as EditIcon } from "react-icons/fa";
import SmallLoadingSpinner from "../../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { ContactData } from "../../HomePageTypes";

export function ContactProfilePicture({
    editedContact,
    uploadingPhoto,
    loadProfilePicture,
}: {
    editedContact: ContactData;
    uploadingPhoto: boolean;
    loadProfilePicture: () => void;
}) {
    return (
        <div className={styles["image"]}>
            <RoundThumbnailImage
                className={styles["profile-picture"]}
                image={
                    editedContact.profilePicture ||
                    "/images/default-user-image.svg"
                }
                size={100}
            />
            <button disabled={uploadingPhoto} onClick={loadProfilePicture}>
                {uploadingPhoto ? (
                    <SmallLoadingSpinner
                        primaryColor="white"
                        secondaryColor="darkgrey"
                        size={18}
                        linethickNess="2.5px"
                    />
                ) : (
                    <EditIcon />
                )}
            </button>
        </div>
    );
}

export function MainTextDetails({
    editedContact,
    updateContactField,
    emptyNameWarning,
}: {
    editedContact: ContactData;
    updateContactField: (
        field: "description" | "name" | "email" | "phoneNumber" | "notes"
    ) => (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    emptyNameWarning: boolean;
}) {
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const notesRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null);

    const handleResize = (
        event: React.FocusEvent<HTMLTextAreaElement>,
        size: number
    ) => {
        event.target.rows = size;
    };

    const handleNavigation = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        fields: React.RefObject<HTMLInputElement | HTMLTextAreaElement>[]
    ) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault();
            const currentIndex = fields.findIndex(
                (field) => field.current && field.current === event.target
            );
            const direction = event.key === "ArrowUp" ? -1 : 1;
            const nextIndex =
                (currentIndex + direction + fields.length) % fields.length;

            if (fields[nextIndex].current?.focus) {
                fields[nextIndex].current?.focus();
            }
        }
    };

    return (
        <div className={styles["main-text-details"]}>
            <input
                title="Contact Name"
                placeholder="Name"
                type="text"
                value={editedContact.name || ""}
                onInput={updateContactField("name")}
                style={
                    emptyNameWarning && !editedContact.name
                        ? { boxShadow: "0 0 0 2px #f003", borderColor: "red" }
                        : undefined
                }
                autoFocus
                maxLength={255}
                onKeyDown={(e) =>
                    handleNavigation(e, [
                        nameRef,
                        descriptionRef,
                        notesRef,
                        phoneNumberRef,
                        emailRef,
                    ])
                }
                ref={nameRef}
            />
            {emptyNameWarning && !editedContact.name && (
                <div className={styles["empty-name-warning"]}>
                    You need to include at least a name
                </div>
            )}
            <textarea
                title="Write a description here, or other details"
                placeholder="Write a description here, or other details"
                name="description"
                cols={30}
                rows={3}
                onFocus={(e) => handleResize(e, 4)}
                onBlur={(e) => handleResize(e, 1)}
                onInput={updateContactField("description")}
                onKeyDown={(e) =>
                    handleNavigation(e, [
                        nameRef,
                        descriptionRef,
                        notesRef,
                        phoneNumberRef,
                        emailRef,
                    ])
                }
                ref={descriptionRef}
                maxLength={512}
            >
                {editedContact.description || ""}
            </textarea>
            <textarea
                placeholder="Private notes - Where did you meet? (optional)"
                title="Private notes - Where did you meet? (optional)"
                name="notes"
                cols={30}
                rows={2}
                onFocus={(e) => handleResize(e, 4)}
                onBlur={(e) => handleResize(e, 1)}
                onInput={updateContactField("notes")}
                onKeyDown={(e) =>
                    handleNavigation(e, [
                        nameRef,
                        descriptionRef,
                        notesRef,
                        phoneNumberRef,
                        emailRef,
                    ])
                }
                ref={notesRef}
                maxLength={512}
            >
                {editedContact.notes || ""}
            </textarea>
            <input
                type="text"
                title="Phone number (optional)"
                placeholder="Phone number (optional)"
                value={editedContact.phoneNumber}
                onInput={updateContactField("phoneNumber")}
                maxLength={32}
                onKeyDown={(e) =>
                    handleNavigation(e, [
                        nameRef,
                        descriptionRef,
                        notesRef,
                        phoneNumberRef,
                        emailRef,
                    ])
                }
                ref={phoneNumberRef}
            />
            <input
                type="email"
                title="Email (optional)"
                placeholder="Email (optional)"
                value={editedContact.email}
                onInput={updateContactField("email")}
                maxLength={254}
                onKeyDown={(e) =>
                    handleNavigation(e, [
                        nameRef,
                        descriptionRef,
                        notesRef,
                        phoneNumberRef,
                        emailRef,
                    ])
                }
                ref={emailRef}
            />
        </div>
    );
}
