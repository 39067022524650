import React from "react";

import styles from "./NewElementInput.module.scss";
import AutocompleteInput from "../../../../Components/AutocompleteInput/AutocompleteInput";

/**
 * Input field for new elements in a list of skills or objects
 */
export function NewElementInput({
  value,
  setValue,
  finishAdding,
  objectOrSkill,
  autocompleteSuggestions,
  cancelAdding,
}: {
  value: string;
  setValue: (newValue: string) => void;
  finishAdding: (newValue: string) => void;
  objectOrSkill: "object" | "skill";
  autocompleteSuggestions: string[];
  cancelAdding: () => void;
}) {
  return (
    <li className={styles["container"]}>
      <AutocompleteInput
        autoFocus
        value={value}
        placeholder={`Add new ${objectOrSkill}`}
        setValue={setValue}
        submitValue={finishAdding}
        autocompleteSuggestions={autocompleteSuggestions}
        closeInput={cancelAdding}
      />
    </li>
  );
}
