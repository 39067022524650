import L from "leaflet";

import {
    ClusterData,
    CoreMapDataAPIReturnType,
    Point,
} from "Pages/Home/HomePageTypes";
import { UserAppearanceConfig } from "types/shared";
import {
    distanceBetweenClusterCenters,
    mergeClusters,
    mergeOverlappingClusters,
} from "./mergeOverlappingClusters";

/**
 * Used to perform further clustering by merging map markers which
 * overlap with clusters on the rendered map into them, based on
 * element dimensions.
 *
 * Since the backend does not know how the clusters will be rendered
 * on the map, this has to be done here on the client side
 *
 * ---
 * @param data The data from the server
 * @param mapDimensions The size of the map on the screen in pixels
 * @param mapBounds The extent of the view on the map, in degrees
 * @param appearanceConfig The current user's configurations for
 */
export function performFullClustering(
    data: CoreMapDataAPIReturnType,
    mapDimensions: { height: number; width: number } | null,
    mapBounds: L.LatLngBounds | null,
    appearanceConfig: UserAppearanceConfig
): CoreMapDataAPIReturnType {
    if (mapBounds === null || mapDimensions === null) {
        return data;
    }

    // The ratio between the degrees on the map to the equivalent pixels
    const scalingFactor =
        (mapBounds.getEast() - mapBounds.getWest()) / mapDimensions.width;

    let clusters = mergeOverlappingClusters(
        data.clusters,
        scalingFactor,
        appearanceConfig.cluster_size
    );

    let users, contacts, locations;

    [clusters, users] = addOverlappingMarkersToClusters(
        data.users,
        clusters,
        scalingFactor,
        (appearanceConfig.cluster_size + appearanceConfig.user_marker_size) / 2
    );

    [clusters, contacts] = addOverlappingMarkersToClusters(
        data.contacts,
        clusters,
        scalingFactor,
        (appearanceConfig.cluster_size + appearanceConfig.contact_marker_size) /
            2
    );

    [clusters, locations] = addOverlappingMarkersToClusters(
        data.locations,
        clusters,
        scalingFactor,
        (appearanceConfig.cluster_size +
            appearanceConfig.location_marker_size) /
            2
    );

    return {
        clusters,
        users,
        contacts,
        locations,
    };
}

/**
 * Merges markers which overlapp with clusters into the clusters,
 * to minimize overcrowding. It is implemented similar to
 * `mergeOverlappingClusters`, but instead operates on a list of
 * marker elements as well, such as users, contacts, or locations
 *
 * ---
 * @param markers users, contacts, or locations
 * @param clusters Existing clusters to which the markers could be combined with, or added to
 * @param scalingFactor The ratio between the degrees on the map to the equivalent pixels
 * @param minimumDistance The minimum allowable pixel distance between cluster centers. If lower than this, the map elements get merged
 * @returns The resulting clusters and markers
 */
function addOverlappingMarkersToClusters<
    T extends { coordinates: Point; id: number }
>(
    markers: Array<T>,
    clusters: ClusterData[],
    scalingFactor: number,
    minimumDistance: number
): [ClusterData[], T[]] {
    let markersAsClusters = markers.map((marker) => ({
        count: 1,
        centroid: marker.coordinates,
        radius: 0,
        id: marker.id,
    }));
    let newClusters: ClusterData[] = [];
    let newMarkers = [...markers];

    let remainingClusters: ClusterData[] = [...clusters];

    while (remainingClusters.length > 0) {
        let newCluster = { ...remainingClusters.pop()! };

        for (let i = markersAsClusters.length - 1; i >= 0; i--) {
            const otherCluster = markersAsClusters[i];

            const distance = distanceBetweenClusterCenters(
                newCluster,
                otherCluster
            );

            if (distance / scalingFactor <= minimumDistance) {
                markersAsClusters.splice(i, 1); // Delete otherCluster

                newMarkers = newMarkers.filter(
                    (marker) => marker.id != otherCluster.id
                );

                newCluster = mergeClusters(newCluster, otherCluster);
            }
        }

        if (newCluster.count > 1) {
            newClusters.push(newCluster);
        }
    }

    return [newClusters, newMarkers];
}
