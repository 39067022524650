import React, { useState } from 'react';
import styles from './ExpandableDescription.module.scss';

interface ExpandableDescriptionProps {
  description: string;
  maxDescriptionLength: number;
}

const ExpandableDescription: React.FC<ExpandableDescriptionProps> = ({
  description,
  maxDescriptionLength,
}) => {
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);

  // Updated to handle clicks on the entire description container
  const handleDescriptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // This will stop the event from propagating up to the popup
    setDescriptionIsExpanded(!descriptionIsExpanded);
  };
  const seeMoreText = descriptionIsExpanded ? 'See less' : 'See more';

  if (description.length === 0) {
    return null;
  }
  
  return (
    <div className={`${styles['description']} ${descriptionIsExpanded ? styles['expanded'] : ''}`} onClick={handleDescriptionClick}>
      {descriptionIsExpanded ? (
        <div className={styles['see-more-content']}>
          <p>{description}</p>
        </div>
      ) : (
        <div>
          {description.slice(0, maxDescriptionLength)}
          {description.length > maxDescriptionLength && (
            <span className={styles['see-more']} onClick={handleDescriptionClick}>{seeMoreText}</span>
            )}
        </div>
      )}
    </div>
  );
};

export default ExpandableDescription;
