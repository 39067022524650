import { useEffect } from "react";

/**
 * Uses `title` as the new document title
 *
 * ---
 * @param title New page title
 * @param dependencies If present, effect will activate if any of the values in the list change
 */
export function useDocumentTitle(title: string, dependencies: Array<any> = []) {
    useEffect(() => {
        document.title = title + " | MapBond";
    }, [title, ...dependencies]);
}
