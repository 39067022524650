import React from 'react';
import { BasicNetworkData } from '../../NetworkGroups/BasicNetworkDataTypes';

interface NetworkSummaryProps {
  networkData: BasicNetworkData;
}

export const NetworkSummary: React.FC<NetworkSummaryProps> = ({ networkData }) => (
  <div className="flex-none px-4 py-2 bg-white border-b border-gray-200">
  <span className="text-gray-600">
      {networkData.member_contacts.length +
          networkData.member_connections.length +
          networkData.member_locations.length} members selected
  </span>
</div>
);