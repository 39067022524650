// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medieval-preview {
  background: linear-gradient(rgba(139, 113, 19, 0.2), rgba(93, 86, 61, 0.2));
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.medieval-preview:hover {
  border: 2px solid #3e3429;
}

.selected {
  border: 2px solid #8B4513;
}

.medieval-text {
  font-family: "Times New Roman", serif;
  color: #5D4E3D;
}
.medieval-text.selected-text {
  color: #8B4513;
}

.medieval-timestamp {
  font-size: 0.8rem;
  color: #5D4E3D;
}`, "",{"version":3,"sources":["webpack://./src/Components/Journal/JournalPreview.scss"],"names":[],"mappings":"AAAA;EACI,2EAAA;EAEA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;;AAIE;EACE,yBAAA;AADJ;;AAIE;EACE,qCAAA;EACA,cAAA;AADJ;AAGI;EACE,cAAA;AADN;;AAKE;EACE,iBAAA;EACA,cAAA;AAFJ","sourcesContent":[".medieval-preview {\n    background: linear-gradient(rgba(139, 113, 19, 0.2), rgba(93, 86, 61, 0.2));\n    // border: 1px solid #5D4E3D;\n    border-radius: 10px;\n    padding: 1rem;\n    margin-bottom: 1rem;  // Add space between each entry\n    cursor: pointer;\n  \n    &:hover {\n      border: 2px solid darken(#5D4E3D, 10%);\n    }\n  }\n  \n  .selected {\n    border: 2px solid #8B4513;\n  }\n  \n  .medieval-text {\n    font-family: 'Times New Roman', serif;\n    color: #5D4E3D;\n  \n    &.selected-text {\n      color: #8B4513;\n    }\n  }\n  \n  .medieval-timestamp {\n    font-size: 0.8rem;\n    color: #5D4E3D;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
