import { useEffect, useState } from 'react'

import { useAxiosPrivate } from './useAxiosPrivate'
import { errorHandler } from 'services/utils'

/**
 * A custom hook to encapsulate the functionality of fetching
 * server-side data via an API
 *
 * ---
 * @param url The URL of the api resource
 * @param dependencies If present, the API will be recalled
 * if the values in the list change.
 * @param setNullOnReload If `true`, sets the data to null before
 * reloading the data. This can be useful in case you want to
 * show a loading spinner when reloading
 * @returns The data and a `useState` callback for setting the data.
 * The data is `null` if the data has not been fetched yet.
 */
export function useApi<T> (
  url: string,
  dependencies: Array<any> = [],
  setNullOnReload = false
): [
  T | ApiError | null,
  React.Dispatch<React.SetStateAction<T | ApiError | null>>
] {
  let [data, setData] = useState<T | ApiError | null>(null)
  const privateAxios = useAxiosPrivate()

  useEffect(() => {
    if (setNullOnReload) {
      setData(null)
    }

    privateAxios
      .get(url)
      .then(async response => {
        if (response.status >= 300) {
          setData(new ApiError(await response.statusText, response.status))
        } else {
          const jsonResponse = response.data
          setData(jsonResponse.data) // Unpack the data from the response
        }
      })
      .catch(reason => setData(new ApiError(reason, 1000)))
  }, [...dependencies, url, setNullOnReload])

  return [data, setData]
}

/**
 * Is used to represent errors when fetching data from the API
 */
export class ApiError {
  constructor (public reason: any, public statusCode: number) {}
}

/**
 * Reloads data at a given interval
 *
 * ---
 * @param fetchUrl Url to fetch data
 * @param fetchInterval The time interval in milliseconds for
 * fetching new data
 * @param dependencies When set, the function will immediately
 * reload whenever any value changes
 */
export function useAutoRefreshingApiData<T> (
  fetchUrl: string,
  fetchInterval = 15_000,
  dependencies?: Array<any>
): [
  ApiError | T | null,
  React.Dispatch<React.SetStateAction<ApiError | T | null>>
] {
  const [iterationCount, setIterationCount] = useState(0)
  let [data, setData] = useApi<T>(fetchUrl, [
    ...(dependencies || []),
    iterationCount
  ])

  useEffect(() => {
    const intervalId = setInterval(
      () => setIterationCount(count => count + 1),
      fetchInterval
    )

    return () => clearInterval(intervalId)
  }, [fetchUrl, fetchInterval, setData])

  return [data, setData]
}

/**
 * The return type of the `postToApi` function from `usePostApi`.
 * It's like an enum which can either be an object with the return
 * type (when `ok` is `true`) or an object with an error message (
 * when `ok` is `false)
 */
export type ApiReturnType<ReturnType, ErrorMessage = string> =
  | {
      data: ReturnType
      ok: true
    }
  | {
      message: ErrorMessage
      ok: false
    }

export type PostToApiFnType = <ReturnType, ErrorMessage = string>(
  url: string,
  data?: {
    [key: string]: string | number | Blob | File | File[]
  },
  method?: 'post' | 'delete' | 'patch'
) => Promise<ApiReturnType<ReturnType, ErrorMessage>>

export function usePostToAPI () {
  const apiInstance = useAxiosPrivate()

  const postToApi = async function <ReturnType, ErrorMessage = string> (
    url: string,
    data: {
      [key: string]: string | number | Blob | File | Array<File>
    } = {},
    method: 'post' | 'delete' | 'patch' = 'post'
  ): Promise<ApiReturnType<ReturnType, ErrorMessage>> {
    let formData = new FormData()
    let hasFile = false

    for (let key of Object.keys(data)) {
      let currentValue = data[key]

      if (currentValue instanceof File) {
        formData.append(key, currentValue, currentValue.name)
        hasFile = true
      } else if (currentValue instanceof Blob) {
        formData.append(key, currentValue, key)
        hasFile = true
      } else if (currentValue instanceof Array) {
        for (let file of currentValue) {
          formData.append(key + '[]', file, file.name)
        }
        hasFile = true
      } else if (
        typeof currentValue === 'string' ||
        typeof currentValue === 'number'
      ) {
        formData.append(key, currentValue.toString())
      }
    }

    try {
      const config = {
        headers: {
          'Content-Type': hasFile ? 'multipart/form-data' : 'application/json'
        }
      }
      const res =
        method === 'post'
          ? await apiInstance.post(url, formData, config)
          : method === 'delete'
          ? await apiInstance.delete(url)
          : await apiInstance.patch(url, formData, config)

      if (res.status < 300) {
        return { data: res.data.data as ReturnType, ok: true }
      } else {
        return { message: res.data.message as ErrorMessage, ok: false }
      }
    } catch (err: any) {
      errorHandler(err)
      return {
        message: err?.response?.data?.data ?? err?.response?.data?.message,
        ok: false
      }
    }
  }

  return { postToApi }
}