import React from "react";
import { DispatchAction } from "../reducer";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import CustomAlert from "Components/CustomAlert/CustomAlert";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


interface LoadingSpinnerAlertProps {
  state: HomePageState;
  dispatch: (action: DispatchAction) => void;
}

const LoadingSpinnerAlert: React.FC<LoadingSpinnerAlertProps> = ({
  state,
  dispatch
}) => {
  return (
    <>
      {state.users === null &&
        state.locations === null &&
        state.contacts === null &&
        state.clusters === null && (
          <OverlayingLoadingSpinner
            primaryColor="red"
            secondaryColor="#fff8"
          />
        )}
      {state.alert && (
        <CustomAlert closeAlert={() => dispatch({ type: ACTIONS.CLEAR_ALERT })}>
          {state.alert}
        </CustomAlert>
      )}
    </>
  );
};

export default LoadingSpinnerAlert;
