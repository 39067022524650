// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MagicButton.module.scss */
.MagicButton_magic-button__Uhrb3 {
  background-color: var(--accent-color);
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform-origin: center;
  display: block;
  margin: 0 auto;
}
.MagicButton_magic-button__Uhrb3:hover, .MagicButton_magic-button__Uhrb3:focus {
  transform: scale(1.1);
  background-color: var(--accent-color-dark);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
}
.MagicButton_magic-button__Uhrb3:active {
  transform: scale(1.1) translateY(2px);
  background-color: var(--accent-color-dark);
}
.MagicButton_magic-button__Uhrb3.MagicButton_focused__hhoLD {
  background-color: red;
  outline: none;
  background-color: var(--accent-color-dark);
}
.MagicButton_magic-button__Uhrb3.MagicButton_blurred__L43hu {
  animation: MagicButton_magicBlur__iJ3eG 0.5s ease-in-out;
}
@keyframes MagicButton_magicBlur__iJ3eG {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.6);
  }
}
.MagicButton_magic-button__Uhrb3.MagicButton_clicked__K2l5j {
  animation: MagicButton_magicDisappear__a\\+B\\+S 1s ease forwards 3s;
}
@keyframes MagicButton_magicDisappear__a\\+B\\+S {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FrequncyKeepInTouch/MagicButton.module.scss"],"names":[],"mappings":"AAAA,4BAAA;AAEA;EACE,qCAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,wBAAA;EACA,cAAA;EACA,cAAA;AAAF;AAEE;EAEE,qBAAA;EACA,0CAAA;EACA,+CAAA;AADJ;AAKE;EACE,qCAAA;EACA,0CAAA;AAHJ;AAME;EACE,qBAAA;EACA,aAAA;EACA,0CAAA;AAJJ;AAOE;EACE,wDAAA;AALJ;AAQE;EACE;IACE,mBAAA;EANJ;EAQE;IACE,qBAAA;EANJ;AACF;AASE;EACE,kEAAA;AAPJ;AAUE;EACE;IACE,UAAA;IACA,mBAAA;EARJ;EAUE;IACE,qBAAA;EARJ;EAUE;IACE,UAAA;IACA,mBAAA;EARJ;AACF","sourcesContent":["/* MagicButton.module.scss */\n\n.magic-button {\n  background-color: var(--accent-color);\n  color: white;\n  padding: 10px 20px;\n  font-size: 1.2rem;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  transform-origin: center;\n  display: block;\n  margin: 0 auto;\n\n  &:hover,\n  &:focus {\n    transform: scale(1.1);\n    background-color: var(--accent-color-dark);\n    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2); // Added shadow for emphasis\n\n  }\n\n  &:active {\n    transform: scale(1.1) translateY(2px);\n    background-color: var(--accent-color-dark);\n  }\n\n  &.focused {\n    background-color: red;\n    outline: none;\n    background-color: var(--accent-color-dark)\n  }\n\n  &.blurred {\n    animation: magicBlur 0.5s ease-in-out;\n  }\n\n  @keyframes magicBlur {\n    0% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(0.6);\n    }\n  }\n\n  &.clicked {\n    animation: magicDisappear 1s ease forwards 3s;\n  }\n\n  @keyframes magicDisappear {\n    0% {\n      opacity: 1;\n      transform: scale(1);\n    }\n    50% {\n      transform: scale(1.1);\n    }\n    100% {\n      opacity: 0;\n      transform: scale(0);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magic-button": `MagicButton_magic-button__Uhrb3`,
	"focused": `MagicButton_focused__hhoLD`,
	"blurred": `MagicButton_blurred__L43hu`,
	"magicBlur": `MagicButton_magicBlur__iJ3eG`,
	"clicked": `MagicButton_clicked__K2l5j`,
	"magicDisappear": `MagicButton_magicDisappear__a+B+S`
};
export default ___CSS_LOADER_EXPORT___;
