// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddToProfileButton_add-button__UPS4\\+ {
  font-size: inherit;
  border-radius: 20px;
  padding: 5px 20px;
  margin: 5px 10px;
  border: none;
  background-color: var(--accent-color);
  color: white;
  font-weight: 600;
  --bs-btn-focus-shadow-rgb: 18, 207, 59;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.AddToProfileButton_add-button__UPS4\\+:hover {
  background-color: var(--accent-color-dark);
}
.AddToProfileButton_add-button__UPS4\\+:active {
  background-color: var(--accent-color-x-dark);
}`, "",{"version":3,"sources":["webpack://./src/Components/AddToProfileButton/AddToProfileButton.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,qCAAA;EACA,YAAA;EACA,gBAAA;EACA,sCAAA;EACA,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,0CAAA;AACR;AAEI;EACI,4CAAA;AAAR","sourcesContent":[".add-button {\n    font-size: inherit;\n    border-radius: 20px;\n    padding: 5px 20px;\n    margin: 5px 10px;\n    border: none;\n    background-color: var(--accent-color);\n    color: white;\n    font-weight: 600;\n    --bs-btn-focus-shadow-rgb: 18, 207, 59;\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    &:hover {\n        background-color: var(--accent-color-dark);\n    }\n\n    &:active {\n        background-color: var(--accent-color-x-dark);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-button": `AddToProfileButton_add-button__UPS4+`
};
export default ___CSS_LOADER_EXPORT___;
