// DeleteObject.tsx
import React from "react";
import { apiRoutes } from "services/routes";
import { usePostToAPI } from "hooks/useAPI";
import { RiDeleteBinLine } from "react-icons/ri";
import styles from "./DeleteObject.module.scss";
import { OwnedObject } from "Pages/Home/HomePageTypes";

interface DeleteObjectProps {
  objectId: number;
  id: number;
  objects: OwnedObject[]; // Array of objects
  onDelete: (objectName: string) => void;
  target: "user" | "contact" | "place";
}
//TODO implement conditional chacking based on target
const DeleteObject: React.FC<DeleteObjectProps> = ({ objectId, id, objects, onDelete, target }) => {
  const { postToApi } = usePostToAPI();

  const handleDelete = () => {
    // Find the object's name based on objectId
    const object = objects.find(o => o.id === objectId);
    const objectName = object ? object.name : null;

    if (objectName) {
      const deleteRoute =
      target === "contact" && id !== undefined
      ? apiRoutes.DELETE_CONTACT_OBJECT(id, objectName)
      : target === "place"
      ? apiRoutes.DELETE_PLACE_OBJECT(id, objectName)
      : apiRoutes.DELETE_OBJECTS(objectName);

      postToApi(deleteRoute, undefined, "delete")
        .then((response) => {
          if (response.ok) {
            onDelete(objectName);
          }
        })
        .catch((error) => {
          console.error("Error deleting object:", error);
        });
    } else {
      console.error("Object not found");
    }
  };

  return (
    <button 
      className={styles["delete-object-button"]}
      onClick={handleDelete}
      title="Delete Object"
    >
      <RiDeleteBinLine />
    </button>
  );
};

export default DeleteObject;
