import React from "react";

import styles from "./ContactDetails.module.scss";
import { Point } from "../../../HomePageTypes";
import { IoLocationSharp as MapPinIcon } from "react-icons/io5";
import { renderCoordinatesAsText } from "Components/CoordinatesLink/CoordinatesLink";
import { Contact } from "../../ImportContacts";

export default function ContactDetails({
    contact,
    setCaptureCoordinatesFor,
}: {
    contact: Contact;
    setCaptureCoordinatesFor: React.Dispatch<
        React.SetStateAction<{
            contactId: string | number;
            currentValue?: Point;
        } | null>
    >;
}) {
    return (
        <div className={styles["details"]}>
            <table>
                <tbody>
                <tr>
                    <td>Coordinates:</td>
                    <td>
                        {contact.coordinates !== null ? (
                            <i>
                                {renderCoordinatesAsText(contact.coordinates)}
                            </i>
                        ) : (
                            <NoValue />
                        )}{" "}
                        <button
                            className={styles["capture-coordinates"]}
                            onClick={() =>
                                setCaptureCoordinatesFor({
                                    contactId: contact.id,
                                    currentValue:
                                        contact.coordinates || undefined,
                                })
                            }
                        >
                            <MapPinIcon />
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>Phone number:</td>
                    <td>{contact.phoneNumber || <NoValue />}</td>
                </tr>
                <tr>
                    <td>Email:</td>
                    <td>{contact.email || <NoValue />}</td>
                </tr>
                <OptionalRow displayIf={contact.notes !== ""}>
                    Notes:
                    {contact.notes}
                </OptionalRow>
                <OptionalRow displayIf={contact.description !== ""}>
                    Description:
                    {contact.description}
                </OptionalRow>
                <OptionalRow displayIf={contact.objects.length !== 0}>
                    Objects:
                    {contact.objects
                        .map((object) =>
                            typeof object === "string" ? object : object.name
                        )
                        .join(", ")}
                </OptionalRow>
                <OptionalRow displayIf={contact.skills.length !== 0}>
                    Skills:
                    {contact.skills
                        .map((skill) =>
                            typeof skill === "string" ? skill : skill.name
                        )
                        .join(", ")}
                </OptionalRow>
                <OptionalRow displayIf={contact.facebook !== ""}>
                    Facebook:
                    <a target="_blank" href={contact.facebook} rel="noreferrer">
                        {contact.facebook}
                    </a>
                </OptionalRow>
                <OptionalRow displayIf={contact.instagram !== ""}>
                    Instagram:
                    <a
                        target="_blank"
                        href={contact.instagram}
                        rel="noreferrer"
                    >
                        {contact.instagram}
                    </a>
                </OptionalRow>
                <OptionalRow displayIf={contact.linkedin !== ""}>
                    LinkedIn:
                    <a target="_blank" href={contact.linkedin} rel="noreferrer">
                        {contact.linkedin}
                    </a>
                </OptionalRow>
                <OptionalRow displayIf={contact.twitter !== ""}>
                    Twitter:
                    <a target="_blank" href={contact.twitter} rel="noreferrer">
                        {contact.twitter}
                    </a>
                </OptionalRow>
                <OptionalRow displayIf={contact.youtube !== ""}>
                    Youtube:
                    <a target="_blank" href={contact.youtube} rel="noreferrer">
                        {contact.youtube}
                    </a>
                </OptionalRow>
                <OptionalRow displayIf={contact.website !== ""}>
                    Website:
                    <a target="_blank" href={contact.website} rel="noreferrer">
                        {contact.website}
                    </a>
                </OptionalRow>
                </tbody>
            </table>
        </div>
    );
}

function NoValue() {
    return <i className={styles["no-value"]}>None</i>;
}

function OptionalRow({
    children,
    displayIf,
}: {
    displayIf: boolean;
    children: [React.ReactNode, React.ReactNode];
}) {
    return displayIf === true ? (
        <tr>
            <td>{children[0]}</td>
            <td>{children[1]}</td>
        </tr>
    ) : null;
}
