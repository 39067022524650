// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TickAnimation_tick-animation-container__rqISO > div {
  transform: rotate(-45deg);
  width: var(--width);
  height: var(--height);
  position: relative;
}
.TickAnimation_tick-animation-container__rqISO > div div {
  position: absolute;
  background-color: grey;
}
.TickAnimation_tick-animation-container__rqISO > div div:nth-child(1) {
  width: var(--line-thickness);
  height: var(--height);
  animation: TickAnimation_vertical-line__ZiniZ var(--duration) cubic-bezier(0.55, 0.06, 0.68, 0.19);
}
.TickAnimation_tick-animation-container__rqISO > div div:nth-child(2) {
  top: calc(var(--height) - var(--line-thickness));
  left: var(--line-thickness)-1;
  height: var(--line-thickness);
  width: var(--width);
  animation: TickAnimation_horizontal-line__AZcp\\+ var(--duration) cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

@keyframes TickAnimation_vertical-line__ZiniZ {
  0% {
    height: 0;
  }
  50% {
    height: var(--height);
  }
}
@keyframes TickAnimation_horizontal-line__AZcp\\+ {
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: var(--width);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/TickAnimation/TickAnimation.module.scss"],"names":[],"mappings":"AACG;EACG,yBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;AAAN;AAEM;EACG,kBAAA;EACA,sBAAA;AAAT;AAGM;EACG,4BAAA;EACA,qBAAA;EACA,kGAAA;AADT;AAIM;EACG,gDAAA;EACA,6BAAA;EACA,6BAAA;EACA,mBAAA;EACA,qGAAA;AAFT;;AAOA;EACG;IACG,SAAA;EAJJ;EAOC;IACG,qBAAA;EALJ;AACF;AAQA;EACG;IACG,QAAA;EANJ;EASC;IACG,QAAA;EAPJ;EAUC;IACG,mBAAA;EARJ;AACF","sourcesContent":[".tick-animation-container {\n   >div {\n      transform: rotate(-45deg);\n      width: var(--width);\n      height: var(--height);\n      position: relative;\n\n      div {\n         position: absolute;\n         background-color: grey;\n      }\n\n      div:nth-child(1) {\n         width: var(--line-thickness);\n         height: var(--height);\n         animation: vertical-line var(--duration) cubic-bezier(0.55, 0.06, 0.68, 0.19);\n      }\n\n      div:nth-child(2) {\n         top: calc(var(--height) - var(--line-thickness));\n         left: var(--line-thickness) - 1;\n         height: var(--line-thickness);\n         width: var(--width);\n         animation: horizontal-line var(--duration) cubic-bezier(0.55, 0.06, 0.68, 0.19);\n      }\n   }\n}\n\n@keyframes vertical-line {\n   0% {\n      height: 0;\n   }\n\n   50% {\n      height: var(--height);\n   }\n}\n\n@keyframes horizontal-line {\n   0% {\n      width: 0;\n   }\n\n   50% {\n      width: 0;\n   }\n\n   100% {\n      width: var(--width);\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tick-animation-container": `TickAnimation_tick-animation-container__rqISO`,
	"vertical-line": `TickAnimation_vertical-line__ZiniZ`,
	"horizontal-line": `TickAnimation_horizontal-line__AZcp+`
};
export default ___CSS_LOADER_EXPORT___;
