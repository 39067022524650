/**
 *  The access token lifetime in the backend is set to 24hrs i.e 1 day. But here, it is set to 23hrs, so that the frontend can request
 *  a new access token using the refresh token before it expires. Otherwise, the user will be logged out.
 *  The refresh token also expires in 2 days as set on the backend, therefore, all users will be required to authenticate again after 2 days.
 *  This is pretty fair for security reasonse, however the time can be adjusted on the backend.
 *  The corresponding django settings is the `ACCESS_TOKEN_LIFETIME` and `REFRESH_TOKEN_LIFETIME` in the backend, under the SIMPLE_JWT settings.
 **/
export const ACCESS_TOKEN_LIFETIME = 60 * 60 * 23 //23hrs
export const CURRENT_DATE = Date.now()

/**
 *  When authenticating with Google, for security reasons, it is recommended to pass a secret state token or text, such that it can be used for
 *  validation in the Google authentication flow.
 *  When Google redirects the user back to the application, the state that was earlier passed will be returned by Google in the URL.
 *  At this point, a check can then be done to confirm if the state is the same with one that was initialy sent,
 *  when redirecting the user to select their Google account.
 *  If it is, then the user will be authenticated, otherwise, they won't.
 **/

export const SECRET_STATE = 'a_very_secure_and_random_state'

// This is the route that was registered on the Google cloud portal.
// This route will be passed to Google when constructing the URL to redirect the users to select their Google account.
// After they select their account, Google will then redirect the user back to this URL.
// Must be similar to the GOOGLE_OAUTH_CALLBACK_URL in the backend
export const GOOGLE_OAUTH_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URL

export const GOOGLE_OAUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID