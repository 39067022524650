// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NetworkView_network_view__Mv4ib::-webkit-scrollbar {
  display: none;
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Networks/NetworkView/NetworkView.module.scss"],"names":[],"mappings":"AACI;EACG,aAAA;EACA,QAAA;AAAP","sourcesContent":[".network_view {\n    &::-webkit-scrollbar {\n       display: none;\n       width: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"network_view": `NetworkView_network_view__Mv4ib`
};
export default ___CSS_LOADER_EXPORT___;
