import React, { useState } from "react";

import styles from "./LoadedContacts.module.scss";
import { DispatchAction } from "../../reducer";


import { ACTIONS } from "Pages/Home/reducerActions";


import { Point } from "../../HomePageTypes";
import LargeLoadingSpinner from "../../../../Components/LargeLoadingSpinner/LargeLoadingSpinner";
import Accordion from "../../../../Components/Accordion/Accordion";
import LazyRoundThumbnailImage from "../../../../Components/LazyRoundThumbnailImage/LazyRoundThumbnailImage";
import { CoordinatesSelectionMap } from "../../../../Components/CoordinatesSelectionMap/CoordinatesSelectionMap";
import UnsavedChangesPrompt from "../../../../Components/UnsavedChangesPrompt/UnsavedChangesPrompt";
import { RadioButtons } from "../../../../Components/RadioButtons/RadioButtons";
import { Contact } from "../ImportContacts";
import ContactDetails from "./ContactDetails/ContactDetails";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { apiRoutes } from "services/routes";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

export default function LoadedContacts({
    dispatch,
    closeMenu,
    contacts,
    setLoadedContacts,
    areExistingContacts,
}: {
    dispatch: React.Dispatch<DispatchAction>;
    closeMenu: () => void;
    contacts: Contact[];
    setLoadedContacts: React.Dispatch<React.SetStateAction<Contact[] | null>>;
    areExistingContacts: boolean;
}) {
    let [contactCoordinatesPrompt, setContactCoordinatesPrompt] =
        useState(false);
    let [isUploading, setIsUploading] = useState(false);
    const apiInstance = useAxiosPrivate();
    // What should be done to contacts without coordinates?
    let [coordinatesHandling, setCoordinatesHandling] = useState<
        "random coordinates" | "edit later"
    >("random coordinates");
    let { currentUser } = useCurrentUserDetails();
    let [captureCoordinatesFor, setCaptureCoordinatesFor] = useState<{
        contactId: string | number;
        currentValue?: Point;
    } | null>(null);

    

    const assignCoordinates = (coordinates: Point): void =>
        setLoadedContacts(
            contacts.map((contact) =>
                contact.id === captureCoordinatesFor?.contactId
                    ? { ...contact, coordinates: coordinates }
                    : contact
            )
        );

    const uploadContacts = async () => {
        if (
            contacts.some((contact) => contact.coordinates === null) &&
            !contactCoordinatesPrompt
        ) {
            setContactCoordinatesPrompt(true);
        } else {
            setIsUploading(true);
            const data = {
                contacts: JSON.stringify(contacts),
                coordinatesHandling,
                ...contacts.reduce<{ [key: string]: Blob }>(
                    (blobs, contact) =>
                        contact.profilePicture instanceof Blob
                            ? { ...blobs, [contact.id]: contact.profilePicture }
                            : blobs,
                    {}
                ),
            };
            if (areExistingContacts) {
                await apiInstance.patch(apiRoutes.BATCH_UPLOAD_CONTACTS, data);
            } else {
                const res = await apiInstance.post(
                    apiRoutes.BATCH_UPLOAD_CONTACTS,
                    data
                );
                dispatch({
                    type: ACTIONS.INSERT_CONTACTS,
                    contacts: res.data.data,
                });
            }
            closeMenu();
            setIsUploading(false);
        }
    };

    return (
        <div className={styles["loaded-contacts"]}>
            {captureCoordinatesFor ? (
                <CoordinatesSelectionMap
                    onClose={() => setCaptureCoordinatesFor(null)}
                    setCoordinatesValue={assignCoordinates}
                    initialViewpoint={
                        captureCoordinatesFor.currentValue ||
                        currentUser?.coordinates
                    }
                />
            ) : null}
            <UnsavedChangesPrompt unsavedChangesExist={true} />
            {!contactCoordinatesPrompt || isUploading ? (
                <div className={styles["list-container"]}>
                    {isUploading ? (
                        <div className={styles["uploading-spinner"]}>
                            <LargeLoadingSpinner primaryColor="var(--accent-color)" />
                        </div>
                    ) : null}
                    <div className={styles["list"]}>
                        {contacts.map((contact) => (
                            <div className={styles["contact"]} key={contact.id}>
                                <Accordion chevronSize={24}>
                                    <div className={styles["contact-heading"]}>
                                        <LazyRoundThumbnailImage
                                            size={35}
                                            image={
                                                contact.profilePicture ||
                                                "/images/default-user-image.svg"
                                            }
                                        />
                                        <h6>{contact.name}</h6>
                                    </div>
                                    <ContactDetails
                                        contact={contact}
                                        setCaptureCoordinatesFor={
                                            setCaptureCoordinatesFor
                                        }
                                    />
                                </Accordion>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={styles["coordinates-prompt"]}>
                    How should contacts with no coordinates be handled?
                    <RadioButtons
                        elements={[
                            {
                                text: "Assign random coordinates",
                                value: "random coordinates",
                            },
                            { text: "Edit later", value: "edit later" },
                        ]}
                        selected={coordinatesHandling}
                        onChange={setCoordinatesHandling}
                    />
                </div>
            )}
            <div className={styles["button-container"]}>
                {contactCoordinatesPrompt && !isUploading ? (
                    <button
                        className="btn btn-light"
                        onClick={() => setContactCoordinatesPrompt(false)}
                    >
                        Continue Editing
                    </button>
                ) : null}
                <button
                    className="btn btn-primary"
                    onClick={uploadContacts}
                    disabled={isUploading}
                >
                    Upload
                </button>
            </div>
        </div>
    );
}
