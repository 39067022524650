import LargeLoadingSpinner from "Components/LargeLoadingSpinner/LargeLoadingSpinner";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import TickAnimation from "Components/TickAnimation/TickAnimation";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { useDocumentTitle } from "hooks/useSEO";
import { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRoutes } from "services/routes";

export type Invitation = {
    invitationToken: string;
    inviterName: string;
    inviterPicture: string;
    status: "Pending" | "Accepted" | "Declined";
};

export default function InvitationAcceptance() {
    useDocumentTitle("Invitation to Join");

    let { invitationToken } = useParams<{ invitationToken: string }>();
    let [uploading, setUploading] = useState(false);
    let [invitation] = useApi<Invitation>(
        apiRoutes.GET_INVITATION(invitationToken)
    );

    let routerHistory = useHistory();
    let { postToApi } = usePostToAPI();

    const acceptOrDecline = (intention: "Accept" | "Decline") => async () => {
        setUploading(true);

        let response = await postToApi(
            apiRoutes.ACCEPT_OR_REJECT_INVITATION(invitationToken),
            { intention }
        );

        setUploading(false);

        if (response.ok) {
            if (intention === "Accept") {
                routerHistory.push(
                    `/accounts/signup?invitationToken=${invitationToken}`
                );
            } else {
                window.history.back();
            }
        } else {
            toast.error(response.message);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col space-y-4 shadow-md p-4">
                <div className="flex flex-col space-y-4 m-4">
                    {invitation === null ? (
                        <LoadingSpinner />
                    ) : invitation instanceof ApiError ? (
                        <Redirect to={"/"} />
                    ) : (
                        <>
                            <div className="bg-white p-4 rounded-lg w-80">
                                <img
                                    src={
                                        invitation.inviterPicture ||
                                        "/images/default-user-image.svg"
                                    }
                                    alt="Inviter's Picture"
                                    className="w-20 h-20 mx-auto rounded-full mb-4"
                                />

                                <p className="text-center text-lg mb-4">
                                    You have been invited by{" "}
                                    <b>{invitation.inviterName}</b> to join{" "}
                                    <b>MapBond</b>
                                </p>

                                <div className="flex justify-around">
                                    {invitation.status === "Accepted" ? (
                                        <div className="flex flex-column items-center text-center">
                                            <TickAnimation
                                                color="var(--accent-color)"
                                                height="12px"
                                                width="30px"
                                            />{" "}
                                            &nbsp;{" "}
                                            <span>
                                                You already accepted this
                                                invitation
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <button
                                                disabled={uploading}
                                                onClick={acceptOrDecline(
                                                    "Accept"
                                                )}
                                                className="flex flex-row items-center font-bold bg-gradient-to-r from-green-400 to-green-600 text-white px-4 py-2 rounded hover:from-green-500 hover:to-green-700 active:from-green-600 active:to-green-800 disabled:opacity-50 focus:outline-none"
                                            >
                                                {uploading ? (
                                                    <>
                                                        <SmallLoadingSpinner
                                                            primaryColor="white"
                                                            secondaryColor="transparent"
                                                            size={16}
                                                        />
                                                        &nbsp;
                                                    </>
                                                ) : null}
                                                Accept
                                            </button>
                                            <button
                                                disabled={uploading}
                                                onClick={acceptOrDecline(
                                                    "Decline"
                                                )}
                                                className="flex flex-row items-center font-bold bg-gradient-to-r from-red-500 to-pink-600 text-white px-4 py-2 rounded hover:from-red-600 hover:to-pink-700 active:from-red-700 active:to-pink-800 disabled:opacity-50 focus:outline-none"
                                            >
                                                {uploading ? (
                                                    <>
                                                        <SmallLoadingSpinner
                                                            primaryColor="white"
                                                            secondaryColor="transparent"
                                                            size={16}
                                                        />
                                                        &nbsp;
                                                    </>
                                                ) : null}
                                                Decline
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="text-center text-xs">
                    <Link to="/">Go to homepage</Link>
                </div>
            </div>
        </div>
    );
}

function LoadingSpinner() {
    return (
        <div style={{ padding: "100px" }}>
            <LargeLoadingSpinner
                primaryColor="var(--accent-color)"
                secondaryColor="#ddd"
            />
        </div>
    );
}
