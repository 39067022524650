import React, { useEffect, useState } from 'react'
import CustomButton from 'Components/CustomButton/CustomButton'
import { useForm, SubmitHandler } from 'react-hook-form'
import UserGreeting from 'Components/UserGreeting/UserGreeting'
import UserProfilePicture from 'Components/UserProfilePicture/UserProfilePicture'
import FirstNameInput from './FirstNameInput' // Subcomponent
import LastNameInput from './LastNameInput' // Subcomponent
import OccupationInput from './OccupationInput' // Subcomponent
import { useAuth } from '../../hooks/useAuth'

import { TOnboardingPayload } from 'types/shared'
import { useAuthContext } from 'Context/AuthContext'
import { useLocalStorage } from 'hooks/useLocalStorage'
import CustomBirthdayPicker from 'Components/CustomBirthdayPicker/CustomBirthdayPicker'
import LocationInput from './LocationSearchComponent'

const OnboardingForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TOnboardingPayload>()
  const [coordinates, setCoordinates] = useState('') // State to store coordinates
  const { user } = useAuthContext()
  const { handleOnboardingCompletion } = useAuth()
  const updateCoordinates = (newCoordinates: string) => {
    setCoordinates(newCoordinates)
  }
  const onSubmit: SubmitHandler<TOnboardingPayload> = data => {
    //coordinates should look like this 'lat,lon'
    //profile_picture is to be added as well.
    handleOnboardingCompletion?.({ ...data, coordinates })
  }

  const { setLocalData } = useLocalStorage()
  //when a user gets to this page, setup update the localstorage and save them as a new user
  useEffect(() => {
    setLocalData('newUser', true)
  }, [setLocalData])

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='flex flex-col space-y-4 shadow-xl p-3'>
        <UserGreeting firstName={user?.first_name} />

        <UserProfilePicture profilePicture={user?.profilePicture} />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col space-y-4 '
        >
          {/* <CustomBirthdayPicker
            register={register}
            inputName='birth_date'
            errors={errors}
            // TODO fix above line
            inputLabel='Date of Birth (optional):'
          /> */}
          <FirstNameInput
            register={register}
            defaultValue={user?.first_name}
            errors={errors}
          />
          <LastNameInput
            register={register}
            defaultValue={user?.last_name}
            errors={errors}
          />
          <OccupationInput register={register} errors={errors} />

          {/** An input field to search for the user location with a button to click to get the user GPS location and also a button to click to
           * load the map so the user can click on it to pick their location. Use icon to represent GPS and icon to represent
           * Map to click on.
           * When also searching, show suggestions
           */}
          <LocationInput
            register={register}
            errors={errors}
            updateCoordinates={updateCoordinates}
            // handleSelection={handleSelection}
          />

          <CustomButton buttonType='submit' variant='primary'>
            Complete Account Setup
          </CustomButton>
        </form>
      </div>
    </div>
  )
}

export default OnboardingForm