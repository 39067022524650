import React, { CSSProperties } from "react";

/**
 * An "add" icon made using simple divs and CSS, allowing its size to be easily changed
 */
export default function AddIcon({
  lineLength,
  lineWidth,
  color,
  margin,
}: {
  lineLength?: number;
  lineWidth?: number;
  color?: string;
  margin?: string;
}) {
  /**
   * Styles to be applied to both of the divs in the component
   */
  let innerDivStyle: CSSProperties = {
    position: "absolute",
    width: (lineWidth || 3) + "px",
    height: (lineLength || 20) + "px",
    backgroundColor: color || "white",
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: (lineLength || 20) + "px",
        width: (lineLength || 20) + "px",
        margin,
      }}
    >
      <div style={{ ...innerDivStyle }}></div>
      <div style={{ ...innerDivStyle, transform: "rotate(90deg)" }}></div>
    </div>
  );
}
