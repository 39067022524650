// JoyrideSteps.tsx
import React from 'react';
import { Step } from 'react-joyride';

export const createJoyrideSteps = (): Step[] => {
  return [
    {
      content: (
        <div>
          <h2 className="text-3xl">Welcome aboard!</h2>
          <h2>Let's walkthrough the basic features of mapbond</h2>
          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/n29WWr4g6sc"
            title="onboarding"
            frameBorder="0"
            allowFullScreen
          ></iframe> #TODO replace with our own videos */}
        </div>
      ),
      styles: {
        options: {
          width: 600,
        },
      },
      locale: { skip: <strong aria-label="skip">Take it by myself</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div>
          <h2>You can control the map from this panel</h2>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: '#floatingControls',
      title: 'Floating controls of the map',
    },
    {
      content: (
        <div>
          <ul>
            <li>You can add location</li>
            <li>Create a new contact</li>
            <li>You can import contacts</li>
            <li>Add travel plans</li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      title: 'You can add or import different items on the map',
      target: '#addOptions',
    },
    {
      content: (
        <div>
          <ul>
            <li>Users</li>
            <li>Contacts</li>
            <li>Locations</li>
            <li>Travel plans</li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      title: 'Filter for different items on the map',
      target: '#floatingFilters',
    },
    {
      content: (
        <div>
          Examples:
          <ul>
            <li>Google</li>
            <li>Google Hybrid</li>
            <li>Open Street Map</li>
            <li>NASA City Lights</li>
          </ul>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      title: 'You can change title layer for your map',
      target: '#titleLayer',
    },
    {
      content: 'Search for people, locations, contacts, skills and objects!',
      placement: 'bottom',
      styles: {
        options: {
          width: 300,
        },
      },
      target: '#searchForm',
      title: 'Search Mapbond',
    },
    {
      content: <div>Navigate to different pages!</div>,
      placement: 'right',
      target: '#navbarMain',
      title: 'Mapbond navigation panel',
    },
    {
      content: (
        <div>
          <h3>Your mapbond command center.</h3>
        </div>
      ),
      placement: 'left',
      target: '#navbarHome',
      title: 'Mapbond home',
    },
    {
      content: (
        <div>
          <h3>Chat with your connections or contacts from messages page.</h3>
        </div>
      ),
      placement: 'left',
      target: '#navbarMessages',
      title: 'Mapbond messages',
    },
    {
      content: (
        <div>
          <h3>Your notification section is here</h3>
        </div>
      ),
      placement: 'left',
      target: '#navbarNotifications',
      title: 'Mapbond notification',
    },
    {
      content: (
        <div>
          <h3>Control everything navigating to settings.</h3>
        </div>
      ),
      placement: 'left',
      target: '#navbarSettings',
      title: 'Mapbond settings',
    },
    {
      content: (
        <div>
          <h3>Navigate to your account from here.</h3>
        </div>
      ),
      placement: 'left',
      target: '#navbarUser',
      title: 'Your mapbond account',
    },
  ];
};
