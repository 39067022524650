// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactDetails_details__ttQaU {
  padding: 10px 0;
  font-size: 0.9em;
}
.ContactDetails_details__ttQaU td {
  padding: 5px 10px;
}
.ContactDetails_details__ttQaU td .ContactDetails_capture-coordinates__CgwPC {
  background-color: transparent;
  border: none;
  color: var(--accent-color-dark);
  padding: 2px 8px;
  border-radius: 3px;
  margin: 0 5px;
}
.ContactDetails_details__ttQaU td .ContactDetails_capture-coordinates__CgwPC:hover {
  background-color: #eee;
}
.ContactDetails_details__ttQaU td .ContactDetails_capture-coordinates__CgwPC:active {
  background-color: #eee;
}
.ContactDetails_details__ttQaU td .ContactDetails_no-value__ya5KW {
  color: #888;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ImportContacts/LoadedContacts/ContactDetails/ContactDetails.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;AACJ;AACI;EACI,iBAAA;AACR;AACQ;EACI,6BAAA;EACA,YAAA;EACA,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;AACZ;AACY;EACI,sBAAA;AAChB;AAEY;EACI,sBAAA;AAAhB;AAIQ;EACI,WAAA;EACA,gBAAA;AAFZ","sourcesContent":[".details {\n    padding: 10px 0;\n    font-size: 0.9em;\n\n    td {\n        padding: 5px 10px;\n\n        .capture-coordinates {\n            background-color: transparent;\n            border: none;\n            color: var(--accent-color-dark);\n            padding: 2px 8px;\n            border-radius: 3px;\n            margin: 0 5px;\n\n            &:hover {\n                background-color: #eee;\n            }\n\n            &:active {\n                background-color: #eee;\n            }\n        }\n\n        .no-value {\n            color: #888;\n            font-weight: 500\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `ContactDetails_details__ttQaU`,
	"capture-coordinates": `ContactDetails_capture-coordinates__CgwPC`,
	"no-value": `ContactDetails_no-value__ya5KW`
};
export default ___CSS_LOADER_EXPORT___;
