import React from "react";

import styles from "./RadioButtons.module.scss";

/**
 * A list of items which can be checked or unchecked
 */
export function RadioButtons<ValueType extends string>({
  elements,
  selected,
  onChange,
}: {
  /**
   * The list of elements can either be an array of objects
   * with the display text and actual value separately or
   * just strings of text. (The idea of separating the text
   * to be displayed from the actual value comes from the
   * `select` html element)
   */
  elements: Array<{ value: ValueType; text: string } | ValueType>;
  selected: ValueType;
  onChange: (selected: ValueType) => void;
}) {
  return (
    <div className={styles["radio-buttons-list"]}>
      <ul>
        {elements.map((_element) => {
          const element =
            typeof _element === "string"
              ? { value: _element, text: _element }
              : _element;

          return (
            <li
              key={element.value}
              onClick={() => onChange(element.value)}
              style={{ cursor: "pointer" }}
            >
              <span
                className={`${styles["radio-button"]} ${
                  element.value === selected ? styles["selected"] : ""
                }`}
              >
                <span></span>
              </span>
              {element.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
