// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticsPage_statisticsPage__MH6kG {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.StatisticsPage_statisticsPage__MH6kG h1 {
  font-size: 4rem;
  color: #ff69b4;
  text-shadow: 2px 2px 0px #1e90ff;
  margin-bottom: 50px;
}
.StatisticsPage_statisticsPage__MH6kG .StatisticsPage_row__FUeby {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}
.StatisticsPage_statisticsPage__MH6kG .StatisticsPage_row__FUeby .StatisticsPage_colMd6__UhruO {
  width: 40%;
  padding: 30px;
  margin: 30px;
  background-color: #1e90ff;
  color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 0px #ff69b4;
  transform: perspective(1000px) rotateY(10deg) translateX(-20px) skewY(-10deg);
}
.StatisticsPage_statisticsPage__MH6kG .StatisticsPage_row__FUeby .StatisticsPage_colMd6__UhruO h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 0px #ff69b4;
}
.StatisticsPage_statisticsPage__MH6kG .StatisticsPage_row__FUeby .StatisticsPage_colMd6__UhruO p {
  font-size: 1.5rem;
  line-height: 2;
  text-shadow: 2px 2px 0px #ff69b4;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/StatisticsPage/StatisticsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,gCAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAEI;EACE,UAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,+BAAA;EACA,6EAAA;AAAN;AAEM;EACE,eAAA;EACA,mBAAA;EACA,gCAAA;AAAR;AAGM;EACE,iBAAA;EACA,cAAA;EACA,gCAAA;AADR","sourcesContent":[".statisticsPage {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 50px;\n\n  h1 {\n    font-size: 4rem;\n    color: #ff69b4;\n    text-shadow: 2px 2px 0px #1e90ff;\n    margin-bottom: 50px;\n  }\n\n  .row {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 50px;\n\n    .colMd6 {\n      width: 40%;\n      padding: 30px;\n      margin: 30px;\n      background-color: #1e90ff;\n      color: white;\n      border-radius: 10px;\n      box-shadow: 2px 2px 0px #ff69b4;\n      transform: perspective(1000px) rotateY(10deg) translateX(-20px) skewY(-10deg);\n\n      h2 {\n        font-size: 3rem;\n        margin-bottom: 20px;\n        text-shadow: 2px 2px 0px #ff69b4;\n      }\n\n      p {\n        font-size: 1.5rem;\n        line-height: 2;\n        text-shadow: 2px 2px 0px #ff69b4;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticsPage": `StatisticsPage_statisticsPage__MH6kG`,
	"row": `StatisticsPage_row__FUeby`,
	"colMd6": `StatisticsPage_colMd6__UhruO`
};
export default ___CSS_LOADER_EXPORT___;
