import { useEffect, useState } from 'react';

import styles from './SearchForm.module.scss';
import { CustomSelect } from 'Components/CustomSelect/CustomSelect';
import {
	ContactData,
	LocationData,
	MinimalContactData,
	MinimalLocationData,
	MinimalUserData,
	UserData,
} from '../HomePageTypes';
import { DispatchAction } from '../reducer';

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import { FaSearch } from 'react-icons/fa';
import { getCombinedSearchResults } from './getCombinedSearchResults';
import { ApiError } from 'hooks/useAPI';
import { ResultsList } from './ResultsList';
/**
 * The search bar for the map. It allows the user to search for a location
 * and then displays the results in a list. The user can then select a location
 * from the list and the map will zoom to that location. The user can also
 * search for a user and then select a user from the list to view their profile.
 */
export function SearchForm({
	searchParameter,
	searchForParameter,
	state,
	dispatch,
}: {
	state: HomePageState;
	dispatch: React.Dispatch<DispatchAction>;
	/** The text in the search input field */
	searchParameter: string | null;
	/** The search type, e.g., contacts, locations, skills, etc */
	searchForParameter: string | null;
}) {
	let [combinedSearchResults, setCombinedSearchResults] = useState<
		SearchResult[] | null
	>(null);
	let [resultsListOpen, setResultsListOpen] = useState(true);
	let isSearching = state.searchInput.trim() !== '';

	const stillLoading = [state.locations, state.users, state.contacts].some(
		(a) => a === null
	);

	useEffect(() => {
		if (state.searchInput.trim() !== '') {
			setCombinedSearchResults(null);
		}
	}, [state.searchInput, state.searchFor]);

	useEffect(() => {
		if (!stillLoading && isSearching) {
			let combinedResults: SearchResult[] = getCombinedSearchResults(
				state.locations as SearchLocation[] | ApiError | null,
				state.users as SearchUser[] | ApiError | null,
				state.contacts as SearchContact[] | ApiError | null,
				state.searchInput
			);
			combinedResults.sort((a, b) => Number(a.distance) - Number(b.distance));
			setCombinedSearchResults(combinedResults);
		}
	}, [
		stillLoading,
		isSearching,
		state.locations,
		state.users,
		state.contacts,
		state.searchInput,
	]);

	return (
		<form
			className={`${styles['search-form']} ${
				searchParameter ? styles['searching'] : ''
			}`}
			id="searchForm"
		>
			<input
				type="search"
				name="search"
				id="search"
				placeholder="Search"
				value={state.searchInput}
				onInput={(event) =>
					dispatch({
						type: ACTIONS.searchInput,
						value: event.currentTarget.value,
					})
				}
			/>
			<div className={styles['search-icon']}>
				<FaSearch />
			</div>
			{searchParameter && searchParameter !== '' && (
				<>
					{resultsListOpen ? (
						<div className={styles['custom-select']}>
							<CustomSelect
								options={[
									'Locations',
									'People',
									'Contacts',
									'Skills',
									'Objects',
								]}
								selected={state.searchFor}
								onChange={(value) => dispatch({ type: ACTIONS.searchFor, value })}
							/>
						</div>
					) : null}
					{resultsListOpen || stillLoading ? (
						<ResultsList
							searchResults={combinedSearchResults}
							stillLoading={stillLoading}
						/>
					) : null}
					<div
						className={styles['close-or-open-results-list']}
						onClick={() => setResultsListOpen(!resultsListOpen)}
					>
						<WideChevron pointingDirection={resultsListOpen ? 'down' : 'up'} />
					</div>
				</>
			)}
		</form>
	);
}


/**
 * Returns a search query string from the search input
 *
 * ---
 * @param searchInput The user's search input
 * @param searchFor What the user intends to search for
 * @returns A query string for searching
 */
export function getSearchQueryString(searchInput: string, searchFor: string) {
	let _searchInput = searchInput.trim();

	return `?${
		_searchInput !== '' ? 'search=' + encodeURIComponent(_searchInput) : ''
	}${
		searchFor !== '' && _searchInput !== ''
			? '&searchFor=' + encodeURIComponent(searchFor)
			: ''
	}`;
}

/**
 * An icon that points up or down in the button for closing
 * or opening the search results list. The direction is
 * controlled by the `pointingDirection` property
 */
function WideChevron({
	pointingDirection,
}: {
	pointingDirection: 'up' | 'down';
}) {
	return (
		<svg
			width="30px"
			height="7px"
			version="1.1"
			viewBox="0 0 63.836 15.37"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				transform: pointingDirection === 'down' ? 'rotate(180deg)' : undefined,
			}}
		>
			<path
				d="m0 3.222v0l31.918 12.148 31.918-12.148a5.0012 5.0012 24.159 0 0-6.4532-2.8947l-25.465 9.6935-25.467-9.6938a4.9988 4.9988 155.83 0 0-6.4506 2.895z"
				fill="#ccc"
			/>
		</svg>
	);
}

export type SearchResult = {
	mainText: string;
	subText: string;
	matchedObject: string;
	matchedSkill: string;
	url: string;
	targetType: 'user' | 'location' | 'contact';
	/** The `id` of the target element */
	targetId: number;
	distance: number;
};

type MarkerWithDistance<T> = T & {
	distance: number;
};

export type SearchLocation = MarkerWithDistance<
	MinimalLocationData | LocationData
>;

export type SearchUser = MarkerWithDistance<MinimalUserData | UserData>;

export type SearchContact = MarkerWithDistance<
	MinimalContactData | ContactData
>;
