import { useEffect, useState } from "react";
import { apiRoutes } from "services/routes";
import { useApi } from "hooks/useAPI";

export function useEmailFromToken(invitationToken: string | null) {
  const [inviteeEmail, setInviteeEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldCallAPI, setShouldCallAPI] = useState<boolean>(false);

  useEffect(() => {
    setShouldCallAPI(!!invitationToken);
  }, [invitationToken]);

  // Using a dummy URL or an empty string when shouldCallAPI is false
  const [invitationOrError] = useApi<{
    data: { invitee_email: string };
  }>(shouldCallAPI ? apiRoutes.GET_INVITATION(invitationToken || "") : "");

  useEffect(() => {
    if (invitationOrError) {
      if (typeof invitationOrError === 'object' && 'invitee_email' in invitationOrError) {
        const email = invitationOrError.invitee_email;
        if (typeof email === 'string') {
          setInviteeEmail(email);
        }
      } else {
        console.error("Received error:", invitationOrError);
      }
    }

    setLoading(false);
  }, [invitationOrError]);


  console.log("Debug - invitationToken:", invitationToken);
  console.log("Debug - invitationOrError:", invitationOrError);
  console.log("Debug - inviteeEmail:", inviteeEmail);

  return inviteeEmail;
}
