import React, { useState } from "react";
import { apiRoutes } from "services/routes";
import { usePostToAPI } from "hooks/useAPI";
import { RiDeleteBinLine } from "react-icons/ri"; // Import the delete icon
import styles from "./DeleteSkill.module.scss"

interface DeleteSkillProps {
  id?: number | undefined;
  skill: {
    name: string;
    canEdit: boolean; 
  };
  target: "user" | "contact"; // Indicates whether it's for a user or a contact
  onDelete: (skill: string) => void; // Callback function to handle deletion
}


const DeleteSkill: React.FC<DeleteSkillProps> = ({ skill, target, onDelete, id }) => {
  const { postToApi } = usePostToAPI();

  const handleDelete = () => {
    const deleteRoute =
      target === "contact" && id !== undefined 
        ? apiRoutes.DELETE_CONTACT_SKILLS(id, skill.name)
        : apiRoutes.DELETE_SKILL(skill.name); //

    postToApi(deleteRoute, undefined, "delete")
      .then((response) => {
        if (response.ok) {
          onDelete(skill.name);
        }
      })
      .catch((error) => {
        console.error("Error deleting skill:", error);
      });
  };

  if (skill.canEdit) {
    return (
      <button 
        className={styles["delete-skill-button"]}
        onClick={handleDelete}
        title="Delete Skill"
      >
        <RiDeleteBinLine />
      </button>
    );
  } else {
    // If canEdit is false, return null
    return null;
  }
};
export default DeleteSkill;
