import React, { CSSProperties } from "react";

import styles from "./SmallLoadingSpinner.module.css";

/**
 * A loading spinner made using just HTML and CSS
 */
export default function SmallLoadingSpinner({
  secondaryColor,
  primaryColor,
  size,
  margin,
  linethickNess,
}: {
  secondaryColor?: string;
  primaryColor?: string;
  size?: number;
  margin?: string;
  linethickNess?: string;
}) {
  return (
    <div
      className={styles["loading-spinner-small"]}
      style={
        {
          "--secondary-color": secondaryColor || "rgba(126, 126, 126, 0.3)",
          "--primary-color": primaryColor || "green",
          width: `${size}px`,
          height: `${size}px`,
          margin: margin,
          borderWidth: linethickNess,
        } as CSSProperties
      }
    ></div>
  );
}
