import React from "react";
import { Link } from "react-router-dom";
import { UserData } from "../../HomePageTypes";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";

import styles from "./UserMarkerPopup.module.scss";


export function PopupHeading({
	user,
}: {
	user: UserData;
}) {
	const image = user.smallProfilePicture || "";

	return (
		<div
			className={`${styles["popup-heading"]}`}
		>
			<Link to={`/@${user.handle}`}>
			<RoundThumbnailImage
          image={image}
          size={60}
          rounding={1}
        />
				<div className={styles["basic-details"]}>
					<h5>{user.name}</h5>
					<p>
						{user.age}
						{user.age && user.occupation ? ", " : null}
						{user.occupation}
					</p>
				</div>
			</Link>
		</div>
	);
}
