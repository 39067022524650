// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialMediaLinks_social-media-links__B6xMd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.SocialMediaLinks_social-media-links__B6xMd span {
  margin-right: 10px;
}
.SocialMediaLinks_social-media-links__B6xMd span:last-child {
  margin-right: 0;
}
.SocialMediaLinks_social-media-links__B6xMd a {
  position: relative;
  color: grey;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.SocialMediaLinks_social-media-links__B6xMd a:hover {
  color: #f7b500;
  transform: translateY(-5px); /* Move the icon up on hover */
}
.SocialMediaLinks_social-media-links__B6xMd a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #f7b500;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  opacity: 0;
}
.SocialMediaLinks_social-media-links__B6xMd a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/SocialMediaLinks/SocialMediaLinks.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,eAAA;AAGN;AAAE;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,gCAAA;EACA,qBAAA;AAEJ;AADI;EACE,cAAA;EACA,2BAAA,EAAA,8BAAA;AAGN;AADI;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,yBAAA;EACA,uBAAA;EACA,oBAAA;EACA,sCAAA;EACA,UAAA;AAGN;AADI;EACE,sBAAA;EACA,oBAAA;EACA,UAAA;AAGN","sourcesContent":[".social-media-links {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 auto;\n  width: 100%;\n  max-width: 300px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  span {\n    margin-right: 10px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n  a {\n    position: relative;\n    color: grey;\n    font-size: 1.5rem;\n    transition: all 0.3s ease-in-out;\n    text-decoration: none;\n    &:hover {\n      color: #f7b500;\n      transform: translateY(-5px); /* Move the icon up on hover */\n    }\n    &::before {\n      content: \"\";\n      position: absolute;\n      bottom: -5px;\n      left: 0;\n      right: 0;\n      height: 2px;\n      background-color: #f7b500;\n      transform-origin: right;\n      transform: scaleX(0);\n      transition: transform 0.3s ease-in-out;\n      opacity: 0;\n    }\n    &:hover::before {\n      transform-origin: left;\n      transform: scaleX(1);\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-media-links": `SocialMediaLinks_social-media-links__B6xMd`
};
export default ___CSS_LOADER_EXPORT___;
