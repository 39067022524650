// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePhoto_container__JRtNl {
  position: relative;
  border-radius: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_uploading__CBlBX,
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ:hover {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > button,
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > a {
  margin: 20px;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > button:hover,
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > a:hover {
  background-color: rgba(0, 0, 0, 0.6666666667);
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > button:active,
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > a:active {
  background-color: rgba(0, 0, 0, 0.7333333333);
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > button svg,
.ProfilePhoto_container__JRtNl .ProfilePhoto_buttons__dXLeJ > a svg {
  fill: white;
}
.ProfilePhoto_container__JRtNl .ProfilePhoto_uploading__CBlBX {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/ProfileSettings/ProfilePhoto/ProfilePhoto.module.scss"],"names":[],"mappings":"AAAA;EACG,kBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACH;AACG;;EAEG,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACN;AAEG;EACG,WAAA;EACA,oCAAA;EACA,UAAA;AAAN;AAEM;EACG,UAAA;EACA,6BAAA;AAAT;AAGM;;EAEG,YAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AADT;AAGS;;EACG,6CAAA;AAAZ;AAGS;;EACG,6CAAA;AAAZ;AAGS;;EACG,WAAA;AAAZ;AAKG;EACG,WAAA;EACA,oCAAA;AAHN","sourcesContent":[".container {\n   position: relative;\n   border-radius: 105px;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n\n   .uploading,\n   .buttons {\n      position: absolute;\n      left: 0;\n      top: 0;\n      width: 100%;\n      height: 100%;\n      border-radius: inherit;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n   }\n\n   .buttons {\n      z-index: 10;\n      background-color: #0003;\n      opacity: 0;\n\n      &:hover {\n         opacity: 1;\n         transition: opacity 0.3s ease;\n      }\n\n      >button,\n      >a {\n         margin: 20px;\n         width: 60px;\n         height: 60px;\n         background-color: #0009;\n         display: flex;\n         align-items: center;\n         justify-content: center;\n         border: none;\n         border-radius: 30px;\n\n         &:hover {\n            background-color: #000a;\n         }\n\n         &:active {\n            background-color: #000b;\n         }\n\n         svg {\n            fill: white;\n         }\n      }\n   }\n\n   .uploading {\n      z-index: 20;\n      background-color: #0003;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProfilePhoto_container__JRtNl`,
	"uploading": `ProfilePhoto_uploading__CBlBX`,
	"buttons": `ProfilePhoto_buttons__dXLeJ`
};
export default ___CSS_LOADER_EXPORT___;
