// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabsSection_tabs-container__lGbdY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/TabsSection/TabsSection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF","sourcesContent":[".tabs-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n  // background-color: #f7f7f7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs-container": `TabsSection_tabs-container__lGbdY`
};
export default ___CSS_LOADER_EXPORT___;
