export default function CustomButton({
	buttonType = 'button',
	variant,
	children,
}: {
	buttonType?: "button" | "submit" | "reset";
	children: string | JSX.Element | JSX.Element[];
	variant: 'primary' | 'secondary';
}) {
	const colorPicker = {
		primary: 'bg-accent-color text-white hover:bg-accent-color-dark',
		secondary: 'text-accent-color border  !border-accent-color',
	};
	return (
		<button
			type={buttonType}
			className={`${colorPicker[variant]}  max-w-full h-12 px-6 text-lg whitespace-nowrap  uppercase rounded text-bold`}
		>
			{children}
		</button>
	);
}
