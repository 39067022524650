import React from "react";
import styles from "./DetailsSection.module.scss";
import { ContactData, UserData, LocationData } from "Pages/Home/HomePageTypes";
import EmailAndNumber from "Components/EmailAndNumber/EmailAndNumber";
import SocialMediaLinks from "Components/SocialMediaLinks/SocialMediaLinks";

interface DetailsSectionProps {
  data: ContactData | UserData | LocationData;
  addingKeepInTouch: boolean;
}

export default function DetailsSection({
  data,
  addingKeepInTouch,
}: DetailsSectionProps) {
  if (!addingKeepInTouch) {
      return (
          <div className={styles["horizontal-container"]}>
              <div className={styles["horizontal-container"]}>
                  {data.email || data.phoneNumber ? (
                      <div className={styles["email-and-number"]}>
                          <EmailAndNumber
                              email={data.email}
                              number={data.phoneNumber}
                          />
                      </div>
                  ) : null}
                  {[
                      data.facebook,
                      data.instagram,
                      data.twitter,
                      data.linkedin,
                      data.youtube,
                      data.website,
                  ].some((value) => value !== "") ? (
                      <SocialMediaLinks
                          facebook={data.facebook}
                          instagram={data.instagram}
                          twitter={data.twitter}
                          linkedin={data.linkedin}
                          youtube={data.youtube}
                          website={data.website}
                      />
                  ) : null}
              </div>
          </div>
      );
  }
  return null;
}