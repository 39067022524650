import React from "react";

import styles from "./LocationImageViewer.module.scss";

export function LocationImageViewer({
  pictures,
  showingPictureIndex,
  setShowingPictureIndex,
  size,
  maxImageHeight,
}: {
  pictures: Array<{ id: number; url: string }>;
  /** The index of the currently showing picture */
  showingPictureIndex: number;
  setShowingPictureIndex: React.Dispatch<React.SetStateAction<number>>;
  /** The physical size of the component */
  size?: string;
  maxImageHeight?: string;
}) {
  return (
    <div className={styles["images"]} style={{ height: size, width: size }}>
      {pictures.length > 1 && (
        <button
          title="Previous picture"
          onClick={() => {
            if (pictures && showingPictureIndex > 0) {
              setShowingPictureIndex(showingPictureIndex - 1);
            }
          }}
        >
          {"<"}
        </button>
      )}

      <a
        href={
          pictures.length > 0
            ? pictures[showingPictureIndex].url
            : "/images/default-location-image.svg"
        }
      >
        <img
          src={
            pictures.length > 0
              ? pictures[showingPictureIndex].url
              : "/images/default-location-image.svg"
          }
          alt=""
          style={{ maxHeight: maxImageHeight }}
        />
      </a>

      {pictures.length > 1 && (
        <button
          title="Next picture"
          onClick={() => {
            if (pictures && showingPictureIndex < pictures.length - 1) {
              setShowingPictureIndex(showingPictureIndex + 1);
            }
          }}
        >
          {">"}
        </button>
      )}
    </div>
  );
}
