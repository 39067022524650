import React, { useState } from 'react';
import { FeaturesType } from '../../Pages/LandingPage/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './FAQSection.module.scss';
import uuid from 'react-uuid';
function FAQSection({ FAQsData }: { FAQsData: Array<FeaturesType> }) {
	const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
	const [readFAQs, setReadFAQs] = useState<Array<string>>([]);

	const handleClick = (key: string) => {
		if (isOpen[key]) {
			setReadFAQs(readFAQs.concat(key));
		}
		setIsOpen({ ...isOpen, [key]: !isOpen[key] });
	};

	const filteredFAQs = FAQsData.filter((faq) => !readFAQs.includes(faq.key));

	return (
		<section id="FAQsection" className="bg-green-500 py-20">
			<div className="px-4">
				<div className="flex flex-col max-w-6xl mx-auto md:flex-row">
					<h2 className="w-full mr-8 text-4xl font-extrabold leading-9 text-white md:w-1/3">
						Frequently Asked Questions
					</h2>
					<br />
					<dl className="w-full md:w-2/3">
						{filteredFAQs.map((faq) => {
							return (
								<div key={uuid()}>
									<dt
										className={`mb-4 cursor-pointer text-white ${
											isOpen[faq.key] ? 'hover:scale-110' : ''
										}`}
										onClick={() => handleClick(faq.key)}
									>
										<div className="flex items-center">
											<FontAwesomeIcon
												icon={isOpen[faq.key] ? faMinus : faPlus}
												className={`m-2 text-2xl ${
													isOpen[faq.key]
														? 'text-yellow-300 animate-rotate'
														: 'text-yellow-300 animate-rotate-reverse'
												}`}
											/>
											<h3 className="text-xl font-bold text-white">
												{faq.key}
											</h3>
										</div>
									</dt>
									<dd
										className={`mb-16 ${
											isOpen[faq.key]
												? 'block animate-fade-in'
												: 'hidden animate-fade-out'
										}`}
									>
										<p className="text-white">{faq.details}</p>
									</dd>
								</div>
							);
						})}
					</dl>
				</div>
				{filteredFAQs.length === 0 && (
					<p className="text-white text-4xl text-center mt-8">
						🎉 Congrats, you've read all our questions! <br></br>
						🤓 If you still have more burning questions, don't be shy – get in
						touch with us! 📬👋🏼
					</p>
				)}
			</div>
		</section>
	);
}

export default FAQSection;
