import React from "react";

import styles from "./Notes.module.scss";
import { AiOutlineFileText } from 'react-icons/ai';

/**
 * Notes component
 * @param {string} notes - notes to display
 * @param {string} className - additional class name
 * @returns {JSX.Element}
 * @constructor
 * @component
 * @example
 * return (
 *  <Notes notes="This is a note" />
 * )
 */
export default function Notes({
  notes,
  horizontalAlignment,
}: {
  notes: string;
  /** Phone number */
  horizontalAlignment?: "center" | "left" | "right";
}) {
  return (
    <div
      className={styles["notes"]}
      style={{
        justifyContent:
          horizontalAlignment === "left"
            ? "flex-start"
            : horizontalAlignment === "right"
            ? "flex-end"
            : "center",
      }}
    >
      {notes ? (
        <span>
          <AiOutlineFileText /> {notes}
        </span>
      ) : null}
    </div>
  );
}
