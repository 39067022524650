import React from 'react';

import styles from './EmailAndNumber.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

/**
 * Used to display the email and phone number on
 * a profile or marker
 */
export default function EmailAndNumber({
	email,
	number,
	horizontalAlignment,
}: {
	email: string;
	/** Phone number */
	number: string;
	horizontalAlignment?: 'center' | 'left' | 'right';
}) {
	return (
		<div
			className={styles['email-and-number']}
			style={{
				justifyContent:
					horizontalAlignment === 'left'
						? 'flex-start'
						: horizontalAlignment === 'right'
						? 'flex-end'
						: 'center',
			}}
		>
			{email ? (
				<span>
					<a href={`mailto:${email}`}>
						<FontAwesomeIcon icon={faEnvelope} />
					</a>
				</span>
			) : null}
			{number ? (
				<span>
					<a href={`tel:${number}`}>
						<FontAwesomeIcon icon={faPhone} />
					</a>
				</span>
			) : null}
		</div>
	);
}
