import { ReactNode } from "react";

import { toast } from "react-toastify";

import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import SettingsSubpage from "../SettingsSubpage/SettingsSubpage";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import LargeLoadingSpinner from "Components/LargeLoadingSpinner/LargeLoadingSpinner";
import { useDocumentTitle } from "hooks/useSEO";

import { Subscriptions } from "./Subscriptions/Subscriptions";


type SubscriptionEventType = "Skill" | "Object";

export type NotificationSubscription<
    T extends SubscriptionEventType = SubscriptionEventType
> = {
    id: number;
    eventType: T;
    name: string;
};

export default function ManageNotifications({}: {}) {
    useDocumentTitle("Manage Notifications");

    let [subscriptions, setSubscriptions] = useApi<NotificationSubscription[]>(
        apiRoutes.GET_CREATE_NOTIFICATION_SUBSCRIPTIONS
    );

    let { postToApi } = usePostToAPI();

    const addSubscription = <T extends SubscriptionEventType>(
        subscription: NotificationSubscription<T>
    ) =>
        postToApi<NotificationSubscription<T>>(
            apiRoutes.GET_CREATE_NOTIFICATION_SUBSCRIPTIONS,
            subscription,
            "post"
        ).then((response) => {
            if (response.ok) {
                setSubscriptions((oldValue) =>
                    oldValue && !(oldValue instanceof ApiError)
                        ? [...oldValue, response.data]
                        : oldValue
                );
            } else {
                toast.error(response.message);
            }
        });

    const deleteSubscription = <T extends SubscriptionEventType>(
        subscription: NotificationSubscription<T>
    ) => {
        setSubscriptions((oldValues) =>
            oldValues instanceof Array
                ? oldValues.filter((element) => element.id !== subscription.id)
                : oldValues
        );

        postToApi(
            apiRoutes.GET_UPDATE_DELETE_NOTIFICATION_SUBSCRIPTION(
                subscription.id
            ),
            subscription,
            "delete"
        ).then((response) => {
            if (!response.ok) {
                toast.error(
                    "Failed to delete subscription: " + response.message
                );
                setSubscriptions(subscriptions);
            }
        });
    };

    const updateSubscription = <T extends SubscriptionEventType>(
        subscription: NotificationSubscription<T>
    ) => {
        setSubscriptions((oldValues) =>
            oldValues instanceof Array
                ? oldValues.filter((element) => element.id !== subscription.id)
                : oldValues
        );

        postToApi<NotificationSubscription<T>>(
            apiRoutes.GET_UPDATE_DELETE_NOTIFICATION_SUBSCRIPTION(
                subscription.id
            ),
            subscription,
            "patch"
        ).then((response) => {
            if (response.ok) {
                setSubscriptions(
                    subscriptions instanceof Array
                        ? subscriptions.map((sub) =>
                              sub.id === subscription.id ? subscription : sub
                          )
                        : subscriptions
                );
            } else {
                setSubscriptions(subscriptions);
                toast.error(
                    "Failed to update subscription: " + response.message
                );
            }
        });
    };

    return (
        <SettingsSubpage title="Manage Notifications">
            <div>
                {!subscriptions ? (
                    <CenteredContainer>
                        <LargeLoadingSpinner
                            primaryColor="var(--accent-color)"
                            secondaryColor="#ccc"
                        />
                    </CenteredContainer>
                ) : subscriptions instanceof ApiError ? (
                    <CenteredContainer>
                        <HttpErrorMessage
                            error={subscriptions}
                            hideBorderAndBackground
                        />
                    </CenteredContainer>
                ) : (
                    <>
                        <Subscriptions
                            subscriptions={
                                subscriptions.filter(
                                    (sub) => sub.eventType === "Skill"
                                ) as NotificationSubscription<"Skill">[]
                            }
                            addSubscription={addSubscription}
                            deleteSubscription={deleteSubscription}
                            updateSubscription={updateSubscription}
                            type="Skill"
                        />
                        <br />
                        <Subscriptions
                            subscriptions={
                                subscriptions.filter(
                                    (sub) => sub.eventType === "Object"
                                ) as NotificationSubscription<"Object">[]
                            }
                            addSubscription={addSubscription}
                            deleteSubscription={deleteSubscription}
                            updateSubscription={updateSubscription}
                            type="Object"
                        />
                    </>
                )}
            </div>
        </SettingsSubpage>
    );
}


function CenteredContainer({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px",
            }}
        >
            {children}
        </div>
    );
}
