// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactMarker_contact-marker-icon__H1UPm div {
  --size: 46px;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5333333333);
  transition: all 0.2s ease-out;
}
.ContactMarker_contact-marker-icon__H1UPm div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
}
.ContactMarker_contact-marker-icon__H1UPm div:hover {
  box-shadow: 0px 0px 50px rgba(255, 255, 0, 0.533);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ContactMarker/ContactMarker.module.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,oCAAA;EACA,sBAAA;EACA,2BAAA;EACA,sBAAA;EACA,mDAAA;EACA,6BAAA;AAAR;AAEQ;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AAAZ;AAGQ;EACI,iDAAA;AADZ","sourcesContent":[".contact-marker-icon {\n    div {\n        --size: 46px;\n        width: var(--size);\n        height: var(--size);\n        border-radius: calc(var(--size) / 2);\n        background-size: cover;\n        background-position: center;\n        background-color: #ccc;\n        box-shadow: 0px 5px 5px #0008;\n        transition: all 0.2s ease-out;\n\n        &:before {\n            content: '';\n            position: absolute;\n            top: 0;\n            right: 0;\n            bottom: 0;\n            left: 0;\n            z-index: -1;\n            margin: -2px;\n            border-radius: inherit;\n        }\n\n        &:hover {\n            box-shadow: 0px 0px 50px rgba(255, 255, 0, .533);\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact-marker-icon": `ContactMarker_contact-marker-icon__H1UPm`
};
export default ___CSS_LOADER_EXPORT___;
