// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TileLayerSelector_tile-layer-selector__pqdPZ {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  max-height: calc(100vh - 320px);
  hover-bgcolor: #fafafa;
}
@media screen and (max-width: 600px) {
  .TileLayerSelector_tile-layer-selector__pqdPZ {
    max-height: calc(100vh - 300px);
  }
}
.TileLayerSelector_tile-layer-selector__pqdPZ > div {
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FloatingControls/TileLayerSelector/TileLayerSelector.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,+BAAA;EACA,sBAAA;AACJ;AACI;EAVJ;IAWQ,+BAAA;EAEN;AACF;AAAI;EACI,kBAAA;AAER","sourcesContent":[".tile-layer-selector {\n    background-color: white;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: fit-content;\n    max-height: calc(100vh - 320px);\n    hover-bgcolor: #fafafa;\n\n    @media screen and (max-width: 600px) {\n        max-height: calc(100vh - 300px);\n    }\n\n    >div {\n        overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile-layer-selector": `TileLayerSelector_tile-layer-selector__pqdPZ`
};
export default ___CSS_LOADER_EXPORT___;
