import React, { useEffect, RefObject } from 'react';
import { Map, FeatureGroup } from 'leaflet';

import { Dispatch } from 'react';
import { DispatchAction } from '../reducer'; // Adjust import paths for State and Action types
import { MinimalUserData, MinimalLocationData, MinimalContactData } from '../HomePageTypes'; // Adjust import path
import { apiRoutes } from 'services/routes'; // Adjust import path

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import { useMapSearchData } from 'hooks/map/useMapSearchData';
import { useTravelPlanData } from 'hooks/map/useTravelPlanData';

interface MapSearchBoundsHandlerProps {
    isSearching: boolean;
    state: HomePageState;
    dispatch: Dispatch<DispatchAction>;
    mapRef: RefObject<Map>;
    featureGroupRef: RefObject<FeatureGroup>;
}

const MapSearchBoundsHandler: React.FC<MapSearchBoundsHandlerProps> = ({
    isSearching,
    state,
    dispatch,
    mapRef,
    featureGroupRef
}) => {
    const clearClusters = () => dispatch({ type: "clusters", value: [] });

    // Users data fetching
    useMapSearchData<MinimalUserData>(
        apiRoutes.GET_SEARCH("users"),
        (data) => {
            dispatch({ type: ACTIONS.users, value: data });
            clearClusters();
        },
        state.searchInput,
        state.searchFor,
        state.selectedLayers.includes("Users")
    );

    // Locations data fetching
    useMapSearchData<MinimalLocationData>(
        apiRoutes.GET_SEARCH("places"),
        (data) => {
            dispatch({ type: ACTIONS.locations, value: data });
            clearClusters();
        },
        state.searchInput,
        state.searchFor,
        state.selectedLayers.includes("Locations")
    );

    // Contacts data fetching
    useMapSearchData<MinimalContactData>(
        apiRoutes.GET_SEARCH("contacts"),
        (data) => {
            dispatch({ type: ACTIONS.contacts, value: data });
            clearClusters();
        },
        state.searchInput,
        state.searchFor,
        state.selectedLayers.includes("Contacts")
    );

    // Travel plans data fetching
    useTravelPlanData(state, dispatch);

    useEffect(() => {
        if (
            isSearching &&
            mapRef.current &&
            featureGroupRef.current &&
            featureGroupRef.current.getBounds().isValid()
        ) {
            mapRef.current.flyToBounds(featureGroupRef.current.getBounds());
        }
    }, [
        isSearching,
        mapRef,
        featureGroupRef,
        state.searchInput,
        state.searchFor,
        // Potentially include the selected layers as dependencies if they affect the search
        state.selectedLayers
    ]);

    return null; // Since this component doesn't render anything, return null
};

export default MapSearchBoundsHandler;
