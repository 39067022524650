import React, { useCallback, useState } from "react";
import L, { LeafletMouseEvent } from "leaflet";
import { Marker, Popup } from "react-leaflet";

import styles from "./UserMarker.module.scss";
import { getContextMenuStyle } from "../../../Components/ContextMenu/ContextMenu";
import { MinimalUserData } from "../HomePageTypes";

import { usePopupOpeningEffect } from "hooks/map/usePopupOpeningEffect"; 
import { DispatchAction } from "../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import { UserMarkerPopup } from "./UserMarkerPopup/UserMarkerPopup";
import {
    CurrentUserDetails,
    UserAppearanceConfig,
} from "../../../types/shared";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
import NetworkMarkers from "Components/NetworkMarkers/NetworkMarkers";

/**
 * UserMarker: A marker that represents a user. It is a wrapper around
 * the Marker component from react-leaflet. It is shown
 * when the user clicks on a user marker on the map.
 * @param props
 * @returns
 * @constructor
 * @example
 * <UserMarker
 *   user={user}
 *   currentUser={currentUser}
 *   appearanceConfig={appearanceConfig}
 *   dispatch={dispatch}
 * />
 * @see Marker
 * @see Home
 * @see reducer
 * @see UserData
 * @see CurrentUserDetails
 * @see UserAppearanceConfig
 * @see DispatchAction
 * @see HomePageState
 * @see UserMarkerPopup
 */

export function UserMarker({
    user,
    state,
    dispatch,
}: {
    user: MinimalUserData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [popupIsOpen, setPopupIsOpen] = useState(false);
    let { currentUser } = useCurrentUserDetails();
    let appearanceConfig = currentUser?.appearanceConfig;

    let { markerRefCallback } = usePopupOpeningEffect(
        state.openPopup !== null &&
            state.openPopup.id === user.id &&
            state.openPopup.type === "user",
        useCallback(() => dispatch({ type: ACTIONS.CLOSE_POPUP }), [dispatch])
    );

    const contextMenuHandler = (event: LeafletMouseEvent) => {
        let mouseEvent = event.originalEvent;
        let style = getContextMenuStyle(mouseEvent);
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style, user, type: "user" },
        });
    };

    return user.coordinates ? (
        <>
        
        <Marker
            position={[user.coordinates.y, user.coordinates.x]}
            icon={getMarkerIcon(
                user,
                appearanceConfig,
                currentUser || undefined
            )}
            eventHandlers={{
                contextmenu: contextMenuHandler,
                popupopen: () => setPopupIsOpen(true),
                popupclose: () => setPopupIsOpen(false),
                mousemove: () =>
                    dispatch({ type: ACTIONS.hoveredOverUser, value: user }),
            }}
            ref={markerRefCallback}
            riseOnHover
        >
            <Popup>
                {popupIsOpen ? (
                    <UserMarkerPopup
                        user={user}
                        state={state}
                        dispatch={dispatch}
                    />
                ) : null}
            </Popup>
        </Marker>

        <NetworkMarkers 
            data={user} 
            centerPosition={[
                user.coordinates?.y ?? 0,
                user.coordinates?.x ?? 0,
                ]} />


        </>
    ) : null;
}

/**
 * Get the icon for the marker. The color of the circle is the user's color.
 * @param user
 * @param appearanceConfig
 * @param currentUserDetails
 * @returns
 * @see UserAppearanceConfig
 * @see CurrentUserDetails
 * @see UserData
 */
export function getMarkerIcon(
    user: MinimalUserData,
    appearanceConfig?: UserAppearanceConfig | undefined,
    currentUser?: CurrentUserDetails
) {
    let markerSize = appearanceConfig ? appearanceConfig.user_marker_size : 50;

    return L.divIcon({
        html: `
          <div
            class="icon"
            style="
              background-image: url(${
                  user.smallProfilePicture
                      ? user.smallProfilePicture
                      : "/images/default-user-image.svg"
              });
              --size: ${markerSize}px;
              --border-width: ${
                  appearanceConfig?.user_marker_border
                      ? appearanceConfig.user_marker_border
                      : "2"
              }px;
              --connection-border-color-1: ${
                  appearanceConfig?.connection_color_1
                      ? appearanceConfig.connection_color_1
                      : "#6eed89"
              };
              --connection-border-color-2: ${
                  appearanceConfig?.connection_color_2
              };
              --stranger-border-color-1: ${appearanceConfig?.stranger_color_1};
              --stranger-border-color-2: ${appearanceConfig?.stranger_color_2};
            "
          ></div>`,
        iconSize: [markerSize, markerSize],
        iconAnchor: [markerSize / 2, markerSize / 2],
        popupAnchor: [0, -(markerSize / 2)],
        className: `${styles["user-marker-icon"]} ${
            user.isConnected || user.id === currentUser?.id
                ? styles["connection"]
                : styles["stranger"]
        }`,
    });
}
