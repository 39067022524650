export type FeaturesType = {
    key: string,
    details: string
};

export type Testimonials = {
    name: string,
    profile: string,
    testimonial: string
};

 
export const featureData:Array<FeaturesType> = [
    {
        key: "Invite Friends",
        details: "Easily invite friends and expand your social circle on Mapbond."
    },
    {
        key: "Set Skills and Interests",
        details: "On Mapbond, you can set your skills and interests to help connect with like-minded individuals."
    },
    {
        key: "Discover New Connections",
        details: "Mapbond makes it easy to discover new connections and expand your social circle."
    },
    {
        key: "Collaborative planning and discovering new friends",
        details: "Mapbond allows users to add their contacts to the same map, set skills/interests, and discover new connections in one platform."
    },
    {
        key: "Sharing trip plans and recommendations",
        details: "With Mapbond, users can share their future travel plans and make recommendations for places they've been before, making it easy to connect with others who are genuinely interested in connecting."
    },
    {
        key: "Controlled sharing of personal information",
        details: "Mapbond allows users to choose how much information they want to reveal about themselves and their contacts or locations, giving them control over their personal data."
    }
];


export const FAQsData: Array<FeaturesType> = [
    {
        key: "What is Mapbond?",
        details: "Mapbond is a social media platform that allows users to connect with friends and expand their social circle on a map. Some of the key features of Mapbond include the ability to invite friends, set skills and interests, discover new connections, and use the messaging feature."
    },
    {
        key: "How does Mapbond work?",
        details: "Mapbond allows you to personalize and create personalized maps of your social capital, showing your connections where you're planning to go and what you're interested in. You can also share locations, contacts, and recommendations with others, helping to build and strengthen your connections on the map"
    },
    {
        key: "What makes Mapbond different from other social media platforms?",
        details: 'Mapbond is unique because it uses interactive maps to connect and share with others, rather than traditional feeds or timelines. This makes it more engaging and interactive, and allows users to explore the world and discover new things in a more visual and intuitive way.'
    },
    {
        key: "Can I control how much information I share on Mapbond?",
        details: "Yes, one of the key features of Mapbond is the ability to choose how much information you want to reveal about a person or location. You can simply indicate that you know someone or have visited a place, or you can share more detailed information if you choose. This gives you complete control over your online presence and helps to protect your privacy"
    },
    {
        key: "Is Mapbond free to use?",
        details: 'Yes, Mapbond is currently free to use for all users. We may introduce paid premium features or services in the future, but our core platform will always remain free for all users.'
    },
    {
        key: "How can I get help with using Mapbond?",
        details: 'If you have any questions or need help with using Mapbond, you can visit our Help Center, which offers a wide range of resources and support to help you get started and make the most of our platform. You can also contact our customer support team directly if you have any specific questions or issues.'
    },
    {
        key: "How do I set my skills and interests on Mapbond?",
        details: 'To set your skills and interests on Mapbond, click on the "Edit Profile" button in the main menu and select the "Skills and Interests" tab. From here, you can add and remove skills and interests to your profile.'
    }
];

export const userTestimonials: Array<Testimonials> = [
    {
        name: "John Doe",
        profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSibRhC_l2NylzcKzyuNT8H2PnInA0l93Rg7AVfSJqzKw&s",
        testimonial: "To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself"
    },
    {
        name: "John Doe",
        profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSibRhC_l2NylzcKzyuNT8H2PnInA0l93Rg7AVfSJqzKw&s",
        testimonial: "To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself"
    },
    {
        name: "John Doe",
        profile: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSibRhC_l2NylzcKzyuNT8H2PnInA0l93Rg7AVfSJqzKw&s",
        testimonial: "To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself"
    },
]

