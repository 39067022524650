import React from 'react';
import RoundThumbnailImage from '../../../Components/RoundThumbnailImage/RoundThumbnailImage';
import styles from './ConversationPreview.module.scss';

type ProfilePictureProps = {
    image: string | null;
  };
  
  const ProfilePicture: React.FC<ProfilePictureProps> = ({ image }) => (
      <div className={styles['profile-picture-container']}>
        <RoundThumbnailImage image={image || '/images/default-user-image.svg'} />
    </div>
);

export default ProfilePicture;
