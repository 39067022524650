// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_settings__PzF2W {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
}
.Settings_settings__PzF2W .Settings_container__oDpHN {
  max-width: 800px;
  width: 100%;
  padding: 40px 20px;
}
@media screen and (min-width: 1600px) {
  .Settings_settings__PzF2W .Settings_container__oDpHN {
    max-width: 900px;
  }
}
.Settings_settings__PzF2W .Settings_container__oDpHN section {
  width: 100%;
}
.Settings_settings__PzF2W .Settings_container__oDpHN section h3 {
  margin-top: 0.3em;
}
.Settings_settings__PzF2W .Settings_container__oDpHN section h3 button,
.Settings_settings__PzF2W .Settings_container__oDpHN td button {
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.Settings_settings__PzF2W .Settings_container__oDpHN section h3 button:hover,
.Settings_settings__PzF2W .Settings_container__oDpHN td button:hover {
  background-color: rgba(0, 0, 0, 0.0666666667);
}
.Settings_settings__PzF2W .Settings_container__oDpHN section h3 button:active,
.Settings_settings__PzF2W .Settings_container__oDpHN td button:active {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.Settings_settings__PzF2W .Settings_container__oDpHN section h3 button svg,
.Settings_settings__PzF2W .Settings_container__oDpHN td button svg {
  fill: grey;
}
.Settings_settings__PzF2W .Settings_container__oDpHN .Settings_logout__z3-0z {
  border: none;
  padding: 0;
  color: var(--accent-color);
  font-weight: 500;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Settings.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;AACJ;AACI;EACI,gBAAA;EACA,WAAA;EACA,kBAAA;AACR;AACQ;EALJ;IAMQ,gBAAA;EAEV;AACF;AAAQ;EACI,WAAA;AAEZ;AAAY;EACI,iBAAA;AAEhB;AAEQ;;EAEI,6BAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAAZ;AAEY;;EACI,6CAAA;AAChB;AAEY;;EACI,6CAAA;AAChB;AAEY;;EACI,UAAA;AAChB;AAGQ;EACI,YAAA;EACA,UAAA;EACA,0BAAA;EACA,gBAAA;EACA,6BAAA;AADZ","sourcesContent":[".settings {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    background-color: #f8f8f8;\n\n    .container {\n        max-width: 800px;\n        width: 100%;\n        padding: 40px 20px;\n\n        @media screen and (min-width: 1600px) {\n            max-width: 900px;\n        }\n\n        section {\n            width: 100%;\n\n            h3 {\n                margin-top: 0.3em;\n            }\n        }\n\n        section h3 button,\n        td button {\n            background-color: transparent;\n            border: none;\n            display: inline-flex;\n            align-items: center;\n            justify-content: center;\n            padding: 5px;\n\n            &:hover {\n                background-color: #0001;\n            }\n\n            &:active {\n                background-color: #0002;\n            }\n\n            svg {\n                fill: grey;\n            }\n        }\n\n        .logout {\n            border: none;\n            padding: 0;\n            color: var(--accent-color);\n            font-weight: 500;\n            background-color: transparent;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `Settings_settings__PzF2W`,
	"container": `Settings_container__oDpHN`,
	"logout": `Settings_logout__z3-0z`
};
export default ___CSS_LOADER_EXPORT___;
