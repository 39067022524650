import createRefresh from 'react-auth-kit/createRefresh'
import { ACCESS_TOKEN_LIFETIME } from '../Utils/auth-utils'
import { apiInstance } from 'services/apiInstance'
import { apiRoutes } from 'services/routes'
// Define the type for the response

interface TokenResponse {
  access: string;
  accessExpiration: number;
  // Add other fields if your response contains more data
}

const refreshAPIToken = createRefresh<{}>({ // Explicitly specify the type parameter
  interval: 2, // The time in sec to refresh the Access token,
  refreshApiCallback: async param => {
      try {
        const response = await apiInstance.post<TokenResponse>(
          apiRoutes.REFRESH_TOKEN,
              { refresh: param.refreshToken },
              // Your headers here
          );
          return {
              isSuccess: true,
              newAuthToken: response.data.access, // The new auth token
              newAuthTokenExpireIn: response.data.accessExpiration, // Token expiration
              // Include other required fields from the response
          };
      } catch (error) {
          return {
              isSuccess: false,
              newAuthToken: '', // Provide an empty string for newAuthToken
              // Set other properties to default values if needed
          };
      }
  }
});


export default refreshAPIToken;


