import React, { useRef, useCallback } from "react";
import L from "leaflet";

import { getContextMenuStyle } from "../../Components/ContextMenu/ContextMenu";
import { DispatchAction,} from "../../Pages/Home/reducer";
import { ACTIONS } from '../../Pages/Home/reducerActions';


/**
 * Used to perform various actions on initial loading of the leaflet map element.
 * It is used to set the initial view of the map, set the initial tile layer,
 * and set the initial zoom level. It is also used to set the initial bounds
 * of the map. This is useful when the user is searching for a location and
 * the map should zoom to that location. It is also used to set the initial
 * context menu style.
 *
 * ---
 * @param dispatch The reducer's dispatch callback
 * @returns The `Ref` object of the map as well as a **ref callback** for setting the ref object
 */
export function useMapRef(dispatch: React.Dispatch<DispatchAction>) {
    let mapRef = useRef<L.Map | null>(null);

    const updateMapBoundsAndPosition = useCallback(() => {
        if (mapRef.current) {
            let mapCenter = mapRef.current.getCenter();
            dispatch({
                type: ACTIONS.MAP_RESIZED,
                mapBounds: mapRef.current.getBounds(),
                mapCenter: {
                    x: mapCenter.lng,
                    y: mapCenter.lat,
                    zoomLevel: mapRef.current.getZoom(),
                },
                mapSize: mapRef.current.getSize(),
            });
        }
    }, [dispatch]);

    let mapRefCallback = useCallback(
        (map: L.Map | null) => {
            if (map) {
                map.zoomControl.setPosition("bottomleft");
                map.addEventListener("resize", updateMapBoundsAndPosition);
                map.addEventListener("dragend", updateMapBoundsAndPosition);
                map.addEventListener("moveend", updateMapBoundsAndPosition);
                map.addEventListener("zoomend", updateMapBoundsAndPosition);
                map.addEventListener("contextmenu", (event) => {
                    dispatch({
                        type: ACTIONS.contextMenu,
                        value: {
                            type: "map",
                            coordinatesClicked: {
                                x: event.latlng.lng,
                                y: event.latlng.lat,
                            },
                            style: getContextMenuStyle(event.originalEvent),
                        },
                    });
                });
            }
            mapRef.current = map;
            updateMapBoundsAndPosition();
        },
        [dispatch, updateMapBoundsAndPosition]
    );

    return { mapRef, mapRefCallback };
}
