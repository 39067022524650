// SocialLinksInput.tsx
import React from 'react';
import { LocationData } from '../../../HomePageTypes';

interface SocialLinksInputProps {
    locationData: LocationData;
    updateLocationField: (field: 'website' | 'facebook' | 'instagram' | 'twitter' | 'linkedin' | 'youtube') => (event: React.FormEvent<HTMLInputElement>) => void;
    websiteRef: React.RefObject<HTMLInputElement>;
    facebookRef: React.RefObject<HTMLInputElement>;
    instagramRef: React.RefObject<HTMLInputElement>;
    twitterRef: React.RefObject<HTMLInputElement>;
    linkedinRef: React.RefObject<HTMLInputElement>;
    youtubeRef: React.RefObject<HTMLInputElement>;
  }

const SocialLinksInput: React.FC<SocialLinksInputProps> = ({
  locationData,
  updateLocationField,
  websiteRef,
  facebookRef,
  instagramRef,
  twitterRef,
  linkedinRef,
  youtubeRef,
}) => {
  return (
    <div>
      <input
        placeholder="Website (optional)"
        ref={websiteRef}
        title="Website (optional)"
        name="website"
        onInput={updateLocationField('website')}
        value={locationData.website}
        maxLength={256}
      />
    <input
        placeholder="Facebook (optional)"
        title="Facebook (optional)"
        name="facebook"
        ref={facebookRef}
        onInput={updateLocationField('facebook')}
        value={locationData.facebook}
        maxLength={256}
    />
    <input
        placeholder="Instagram (optional)"
        title="Instagram (optional)"
        ref={instagramRef}
        name="instagram"
        onInput={updateLocationField('instagram')}
        value={locationData.instagram}
        maxLength={256}
    />
    <input
        placeholder="Twitter (optional)"
        title="Twitter (optional)"
        name="twitter"
        ref={twitterRef}
        onInput={updateLocationField('twitter')}
        value={locationData.twitter}
        maxLength={256}
    />
    <input
        placeholder="Linkedin (optional)"
        title="Linkedin (optional)"
        ref={linkedinRef}
        name="linkedin"
        onInput={updateLocationField('linkedin')}
        value={locationData.linkedin}
        maxLength={256}
    />
    <input
        placeholder="Youtube (optional)"
        title="Youtube (optional)"
        ref={youtubeRef}
        name="youtube"
        onInput={updateLocationField('youtube')}
        value={locationData.youtube}
        maxLength={256}
    />
        </div>
  );
};

export default SocialLinksInput;
