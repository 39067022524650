import React, { useState } from 'react';
import { UserAppearanceConfig } from 'types/shared';
import { useDebouncedAction } from 'hooks/useDebounce';



interface AppearanceConfigSyncerProps {
  appearanceConfig: UserAppearanceConfig;
  updateAppearanceConfig: (newValue: UserAppearanceConfig, onUpdateFinished: () => void) => void;
  children: (syncConfig: (newValue: UserAppearanceConfig) => void, editedConfig: UserAppearanceConfig, savingData: boolean) => React.ReactNode;
}

const AppearanceConfigSyncer: React.FC<AppearanceConfigSyncerProps> = ({ appearanceConfig, updateAppearanceConfig, children }) => {
  const [editedConfig, setEditedConfig] = useState<UserAppearanceConfig>(appearanceConfig);
  const [savingData, setSavingData] = useState(false);
  const delayRequest = useDebouncedAction();

  const syncConfig = (newValue: UserAppearanceConfig) => {
      setEditedConfig(newValue);
      setSavingData(true);
      delayRequest(() => {
          updateAppearanceConfig(newValue, () => setSavingData(false));
      }, 1000);
  };

  return <>{children(syncConfig, editedConfig, savingData)}</>;
};

export default AppearanceConfigSyncer;