// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImportContacts_heading__QjUP6,
.ImportContacts_csv-format__5DLpn,
.ImportContacts_no-contacts-found__wmSKW {
  margin-bottom: 20px;
}

.ImportContacts_heading__QjUP6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.ImportContacts_heading__QjUP6 h3 {
  margin: 0;
}

.ImportContacts_loading-container__p4N2I {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ImportContacts/ImportContacts.module.scss"],"names":[],"mappings":"AAAA;;;EAGG,mBAAA;AACH;;AAEA;EACG,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;AACH;AACG;EACG,SAAA;AACN;;AAGA;EACG,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAAH","sourcesContent":[".heading,\n.csv-format,\n.no-contacts-found {\n   margin-bottom: 20px;\n}\n\n.heading {\n   display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n   align-items: flex-start;\n\n   h3 {\n      margin: 0;\n   }\n}\n\n.loading-container {\n   width: 100%;\n   padding: 40px;\n   display: flex;\n   flex-direction: row;\n   align-items: center;\n   justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `ImportContacts_heading__QjUP6`,
	"csv-format": `ImportContacts_csv-format__5DLpn`,
	"no-contacts-found": `ImportContacts_no-contacts-found__wmSKW`,
	"loading-container": `ImportContacts_loading-container__p4N2I`
};
export default ___CSS_LOADER_EXPORT___;
