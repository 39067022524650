import React from "react";
import { Link } from "react-router-dom";
import { fromMarkdown } from "mdast-util-from-markdown";
import { Node, Content } from "mdast-util-from-markdown/lib";

import styles from "./Notification.module.scss";
import RoundThumbnailImage from "../../../Components/RoundThumbnailImage/RoundThumbnailImage";

export function Notification({
  message,
  picture,
  children,
  seen,
  url,
}: {
  picture: string | null;
  message: string;
  /**
   * The children are optional. The first child (if present, denoting
   * the confirmation buttons) will be displayed on the right hand
   * side, while the second child (denoting the options) will be
   * displayed below the notification
   */
  children?: React.ReactNode | [React.ReactNode, React.ReactNode];
  seen?: boolean;
  url: string;
}) {
  return (
    <div
      className={styles["notification"]}
      style={{ backgroundColor: seen ? "transparent" : undefined }}
    >
      <div className={styles["main-info"]}>
        <div className={styles["details"]}>
          <Link to={url}>
            <RoundThumbnailImage
              image={
                picture ? picture : "/images/default-user-image.svg"
              }
            />
          </Link>
          <div className={styles["description"]}>
            <MarkdownToJsx syntaxTree={fromMarkdown(message)} />
          </div>
        </div>
        {children instanceof Array ? children[0] : children}
      </div>
      {children instanceof Array ? (
        <div className={styles["options"]}>{children[1]}</div>
      ) : null}
    </div>
  );
}

/**
 * This was implemented so that hyperlinks could be rendered using
 * React-router's `Link` element. Initially, I used the `react-markdown`
 * package to parse Markdown text. However, clicking on a link rendered
 * using `react-markdown` would lead to a full page load, unlike
 * React-router's smooth navigations
 *
 * ---
 * @param syntaxTree A node of a markdown syntax tree
 * @returns Corresponding JSX React element
 */
function MarkdownToJsx({ syntaxTree }: { syntaxTree: Node }): JSX.Element {
  const parseChildren = (subtree: Node & { children: Content[] }) =>
    subtree.children.map((child, index) => (
      <MarkdownToJsx key={index} syntaxTree={child} />
    ));
    
  switch (syntaxTree.type) {
    case "link":
      return (
        <Link to={syntaxTree.url} title={syntaxTree.title || undefined}>
          {parseChildren(syntaxTree)}
        </Link>
      );
    case "root":
      return <>{parseChildren(syntaxTree)}</>;
    case "paragraph":
      return <p>{parseChildren(syntaxTree)}</p>;
    case "strong":
      return <strong>{parseChildren(syntaxTree)}</strong>;
    case "emphasis":
      return <em>{parseChildren(syntaxTree)}</em>;
    case "heading":
      return <h4>{parseChildren(syntaxTree)}</h4>;
    case "text":
      return <>{syntaxTree.value}</>;
    default:
      if (hasOwnProperty<Node, "children", Content[]>(syntaxTree, "children")) {
        return <>{parseChildren(syntaxTree)}</>;
      } else {
        return <></>;
      }
  }
}

/**
 * Checks whether an element has a specified property in a way
 * that the typescript compiler can understand. You may check:
 * https://fettblog.eu/typescript-hasownproperty/
 *
 * ---
 * @param obj The object to check for property existence
 * @param prop The property to check for
 * @returns `true` if `obj` has property `prop` and `false` otherwise
 */
function hasOwnProperty<X extends {}, Y extends PropertyKey, Z>(
  obj: X,
  prop: Y
): obj is X & Record<Y, Z> {
  return obj.hasOwnProperty(prop);
}
