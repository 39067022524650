// src/components/FloatingControls/ControlActions.tsx
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import ExpandableButton from "./ExpandableButton/ExpandableButton";
import { CustomSelect } from "../../../Components/CustomSelect/CustomSelect";
import { DispatchAction } from '../reducer'; // import the DispatchAction type
import { useFloatingControlActions } from '../../../hooks/useFloatingControlActions';
import styles from "./ControlActions.module.scss";
import { ACTIONS } from "Pages/Home/reducerActions";

interface ControlActionsProps {
    dispatch: React.Dispatch<DispatchAction>;
  }
  
const ControlActions: React.FC<ControlActionsProps> = ({ dispatch }) => {
    const { 
        addNewLocation, 
        addNewContact, 
        addNewContactSearch, 
        addNewLocationSearch 
    } = useFloatingControlActions(dispatch);



    const onAddOptionClicked = (
        selected:
            | "location"
            | "contact"
            | "contactAddress"
            | "locationAddress"
            | "contacts"
            | "travel plan"
            | "network"
            | "contact from linkedin"
    ): void => {
        switch (selected) {
            case "location":
                addNewLocation();
                break;
            case "contact":
                addNewContact();
                break;
            case "contactAddress":
                addNewContactSearch();
                break;
            case "locationAddress":
                addNewLocationSearch();
                break;
            case "contacts":
                dispatch({ type: ACTIONS.importingContacts, value: true });
                break;
            case "travel plan":
                dispatch({ type: ACTIONS.addingTravelPlan, value: true });
                break;
            case "network":
                dispatch({ type: ACTIONS.addingNetwork, value: true });
                break;
            case "contact from linkedin":
                dispatch({ type: ACTIONS.addingContactFromLinkedin, value: true });
                break;
        }
    };

    return (
        <ExpandableButton expandOnHover={true}>
            
            <div className={styles["add-location"]}
                    title="Add new location"
                    id="addOptions"
                    >
                <AiOutlinePlus />
            </div>
            <CustomSelect
                    options={[
                        { text: "Add a place on the map", value: "location" },
                        {
                            text: "Add a place using address",
                            value: "locationAddress",
                        },
                        { text: "Add a contact on the map", value: "contact" },
                        {
                            text: "Add a contact using address",
                            value: "contactAddress",
                        },
                        {
                            text: "Add a contact from LinkedIn",
                            value: "contact from linkedin",
                        },
                        { text: "Import contacts", value: "contacts" },
                        { text: "Add Travel Plan", value: "travel plan" },
                        { text: "Create a network", value: "network" },
                    ]}
                orientation="vertical"
                selected={""}
                onChange={onAddOptionClicked}
            />
        </ExpandableButton>
    );
};

export default ControlActions;
