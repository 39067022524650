import React from "react";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

import styles from "./CheckboxList.module.scss";

/**
 * A list of items which can be checked or unchecked
 */
export function CheckboxList<T extends string>({
  elements,
  selected,
  onChange,
}: {
  /**
   * The list of elements can either be an array of objects
   * with the display text and actual value separately or
   * just strings of text. (The idea of separating the text
   * to be displayed from the actual value comes from the
   * `select` html element)
   */
  elements: Array<{ value: T; text: string } | T>;
  selected: Array<T>;
  onChange: (newValues: Array<T>) => void;
}) {
  return (
    <div className={styles["checkbox-list"]}>
      <ul>
        {elements.map((_element) => {
          const element =
            typeof _element === "string"
              ? { value: _element, text: _element }
              : _element;

          const checked = selected.includes(element.value);

          return (
            <li
              key={element.value}
              onClick={() =>
                onChange(
                  checked
                    ? selected.filter((value) => value !== element.value)
                    : [...selected, element.value]
                )
              }
              style={{ cursor: "pointer" }}
            >
              <span className={styles["checkbox"]}>
                <CustomCheckbox checked={checked} />
              </span>
              {element.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
