import React, { useState } from "react";
import Papa from "papaparse";

import styles from "./LoadFile.module.scss";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";
import { loadFile } from "../../../../Utils/fileLoading";
import {
    parseGoogleCsv,
    parseMapBondCsv,
    parseOutlookCsv,
} from "./parseCsvData";
import { AiOutlineLink as ExternalLinkIcon } from "react-icons/ai";
import { CsvFormat, Contact } from "../ImportContacts";

export default function LoadFile({
    setLoading,
    loadedContacts,
    setLoadedContacts,
}: {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loadedContacts: Contact[] | null;
    setLoadedContacts: React.Dispatch<React.SetStateAction<Contact[] | null>>;
}) {
    const [csvFormat, setCsvFormat] = useState<CsvFormat>("MapBond");
    const contactPickerApiIsSupported = "contacts" in navigator;

    const loadFromCsv = () => {
        setLoading(true);
        loadFile(".csv")
            .then((file) =>
                Papa.parse<{ [key: string]: string }>(file, {
                    header: true,
                    complete: (results) => {
                        setLoadedContacts(
                            csvFormat === "Google"
                                ? parseGoogleCsv(results)
                                : csvFormat === "Outlook"
                                ? parseOutlookCsv(results)
                                : parseMapBondCsv(results).filter(
                                      (contact) => contact.name !== ""
                                  )
                        );
                        setLoading(false);
                    },
                    error: () => setLoading(false),
                })
            )
            .catch(() => setLoading(false));
    };

    const loadFromContactsApi = async () => {
        setLoading(true);

        try {
            const supportedProperties =
                await navigator.contacts.getProperties();
            const contacts = await navigator.contacts.select(
                supportedProperties,
                {
                    multiple: true,
                }
            );
            setLoadedContacts(
                contacts
                    .map((contact) => ({
                        id: crypto.randomUUID(),
                        name: contact.name ? contact.name[0] || "" : "",
                        email: contact.email ? contact.email[0] || "" : "",
                        phoneNumber: contact.tel ? contact.tel[0] || "" : "",
                        profilePicture:
                            contact.icon && contact.icon.length > 0
                                ? contact.icon[0]
                                : null,
                        address: contact.address
                            ? contact.address[0]
                            : undefined,
                        coordinates: null,
                        description: "",
                        notes: "",
                        objects: [],
                        skills: [],
                        facebook: "",
                        instagram: "",
                        linkedin: "",
                        twitter: "",
                        youtube: "",
                        ownerHandle: "",
                        keepInTouch: null,
                        journals: null,
                        website: "",
                    }))
                    .filter((contact) => contact.name !== "")
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <ol className={styles["list-container"]}>
            <li>
                <h5>Use CSV file</h5>
                <div>
                    <div className={styles["csv-format"]}>
                        <span className={styles["label"]}>CSV Format:</span>
                        <span>
                            <CustomDropdown
                                items={["MapBond", "Google", "Outlook"]}
                                onChange={(value) =>
                                    setCsvFormat(value as CsvFormat)
                                }
                                selected={csvFormat}
                            />
                        </span>
                        {csvFormat === "MapBond" ? (
                            <a href="/about/mapbond-csv" target="_blank">
                                <ExternalLinkIcon />
                            </a>
                        ) : null}
                    </div>
                    {loadedContacts && loadedContacts.length === 0 ? (
                        <div className={styles["no-contacts-found"]}>
                            <p>
                                No contacts were found. (If there were any
                                contacts without a valid name, depending on the
                                chosen CSV format, they get filtered out)
                            </p>
                        </div>
                    ) : null}
                    <div className={styles["button-container"]}>
                        <button
                            className="btn btn-primary"
                            onClick={loadFromCsv}
                        >
                            Load file
                        </button>
                    </div>
                </div>
            </li>
            {contactPickerApiIsSupported ? (
                <li>
                    <h5>Load from device</h5>
                    <div className={styles["button-container"]}>
                        <button
                            onClick={loadFromContactsApi}
                            className="btn btn-primary"
                        >
                            Import
                        </button>
                    </div>
                </li>
            ) : null}
        </ol>
    );
}
