import React from "react";
import styles from "../UserProfile.module.scss";
import EmailAndNumber from "Components/EmailAndNumber/EmailAndNumber";
import { UserData } from "../../Home/HomePageTypes";

function BasicUserData({ userData }: { userData: UserData }) {
    return (
        <>
            <h1 className={styles["main-heading"]}>{userData.name}</h1>
            <h3 className={styles["subheading"]}>
                {userData.age}
                {userData.age && userData.occupation ? ", " : null}
                {userData.occupation}
            </h3>
            {userData.email || userData.phoneNumber ? (
                <div style={{ color: "grey", fontSize: "0.9em" }}>
                    <EmailAndNumber
                        email={userData.email}
                        number={userData.phoneNumber}
                    />
                </div>
            ) : null}
        </>
    );
}
export default BasicUserData;