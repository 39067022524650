import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import ContextMenu from "Components/ContextMenu/ContextMenu";
import {
    ConnectionStatus,
    onConnectionButtonClicked,
} from "../../UserProfile/ConnectionButton/ConnectionButton";
import { MinimalUserData, UserData } from "../HomePageTypes";
import {
    LocationSharingStatus,
    onLocationSharingRequestButtonClicked,
} from "../../UserProfile/LocationSharingButton/LocationSharingButton";
import { DispatchAction } from "../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";


import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import ContextMenuLoadingSpinner from "Components/ContextMenu/ContextMenuLoadingSpinner/ContextMenuLoadingSpinner";
import ContextMenuError from "Components/ContextMenu/ContextMenuError/ContextMenuError";
import { JournalTemplate } from "Components/TextBox/Editor";
import { apiRoutes } from "services/routes";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

export type UserContextMenuData = {
    type: "user";
    user: MinimalUserData;
    style: CSSProperties;
};

/**
 * Shown when the user right-clicks on a user marker
 */
export function UserContextMenu({
    contextMenuData,
    dispatch,
}: {
    contextMenuData: UserContextMenuData;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullUser, setFullUser] = useApi<UserData>(
        apiRoutes.GET_USER(contextMenuData.user.handle)
    );

    return (
        <ContextMenu style={contextMenuData.style}>
            {fullUser === null ? (
                <ContextMenuLoadingSpinner />
            ) : fullUser instanceof ApiError ? (
                <ContextMenuError error={fullUser} />
            ) : (
                <MenuButtons
                    user={fullUser}
                    setUser={setFullUser}
                    dispatch={dispatch}
                />
            )}
        </ContextMenu>
    );
}

/**
 * Renders the menu buttons for the given user context menu
 *
 * @param user The user data for which the menu buttons are to be rendered
 * @param setUser A function that updates the user data
 * @param dispatch The dispatch function for the parent component
 */
function MenuButtons({
    user,
    setUser,
    dispatch,
}: {
    user: UserData;
    setUser: React.Dispatch<React.SetStateAction<UserData | ApiError | null>>;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let { currentUser } = useCurrentUserDetails();

    let updateUser = (newUser: UserData) => {
        setUser(newUser);
        dispatch({ type: ACTIONS.UPDATE_USER, user: newUser });
    };

    const addObjectToUser = () =>
        dispatch({
            type: ACTIONS.START_ADDING_OBJECT,
            receiver: { type: "user", for: user },
        });

    const addSkillToUser = () =>
        dispatch({
            type: ACTIONS.START_ADDING_SKILL,
            receiver: { type: "user", for: user },
        });

    const openKeepInTouch = () =>
        dispatch({
            type: ACTIONS.ADD_KEEP_IN_TOUCH,
            receiver: { type: "user", for: user },
        });

    const addJournalEntry = () => {
        let obj: JournalTemplate = {
            id: user.id,
            name: user.name || "",
            avatar: user.mediumProfilePicture || "",
            type: "connection",
        };
        dispatch({ type: ACTIONS.addingJournalEntry, value: obj });
    };

    const { postToApi } = usePostToAPI();

    return (
        <>
            {user.isConnected || user.id === currentUser?.id ? (
                <>
                    <button onClick={addSkillToUser}>Add skill</button>
                    <button onClick={addObjectToUser}>Add owned object</button>
                </>
            ) : null}
            {user.id !== currentUser?.id ? (
                <>
                    <button
                        onClick={() => {
                            onConnectionButtonClicked(
                                updateUser,
                                user,
                                postToApi
                            );
                        }}
                    >
                        {mapConnectionStatusToConnectionButtonText(
                            user.connectionStatus
                        )}
                    </button>
                    {user.isConnected ? (
                        <>
                        <button onClick={openKeepInTouch}>Keep in touch</button>
                            <button
                                onClick={() => {
                                    onLocationSharingRequestButtonClicked(
                                        updateUser,
                                        user,
                                        postToApi
                                    );
                                }}
                            >
                                {mapLocationStatusToLocationButtonText(
                                    user.sharingLocations
                                )}
                            </button>
                            <Link to={`/messages/${user.handle}`}>Message</Link>
                        </>
                    ) : null}
                </>
            ) : null}
            <Link to={`/@${user.handle}`}>Visit Profile</Link>
            {user.isConnected ? (
<>
                <button onClick={addJournalEntry}>Add a journal entry</button>
                </>
            ) : null}
        </>
    );
}

export function mapConnectionStatusToConnectionButtonText(
    connectionStatus: ConnectionStatus
) {
    switch (connectionStatus) {
        case "connection":
            return "End Connection";
        case "stranger":
            return "Connect";
        case "loading":
            return "Loading Status";
        case "requested":
            return "Cancel Connection Request";
        case "request received":
            return "Accept Connection Request";
    }
}

export function mapLocationStatusToLocationButtonText(
    status: LocationSharingStatus
) {
    switch (status) {
        case true:
            return "Stop sharing location";
        case false:
            return "Request user location";
        case "loading":
            return "Loading status";
        case "requested":
            return "Cancel location request";
        case "request received":
            return "Accept location request";
    }
}
