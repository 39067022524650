import React, { CSSProperties } from "react";

import ContextMenu from "Components/ContextMenu/ContextMenu";

import { CurrentUserDetails } from "types/shared";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";



type MessageData = {
  id: number;
  from_user_id: number;
  text: string;
  time: string;
};

type MessageContextMenuData = {
  message: MessageData;
  style: CSSProperties;
};

/**
 * Context menu shown when the user clicks on the option button for a message
 */
export default function MessageContextMenu({
  contextMenu,
  currentUser,
  setMessages,
  messages,
}: {
  contextMenu: MessageContextMenuData;
  currentUser: CurrentUserDetails | null;
  messages: MessageData[] | ApiError | null;
  setMessages: React.Dispatch<
      React.SetStateAction<MessageData[] | ApiError | null>
  >;
}) {
  let { postToApi } = usePostToAPI();
  return (
      <ContextMenu style={contextMenu.style}>
          {currentUser &&
              contextMenu.message.from_user_id === currentUser.id && (
                  <button
                      onClick={() => {
                          if (
                              messages &&
                              !(messages instanceof ApiError) &&
                              contextMenu
                          ) {
                              let messageId = contextMenu.message.id;
                              postToApi(
                                  apiRoutes.DELETE_MESSAGES(messageId),
                                  {},
                                  "delete"
                              );
                              setMessages(
                                  messages.filter(
                                      (msg) => msg.id !== messageId
                                  )
                              );
                          }
                      }}
                  >
                      Delete message
                  </button>
              )}
          <button
              onClick={() =>
                  contextMenu &&
                  navigator.clipboard.writeText(contextMenu.message.text)
              }
          >
              Copy Message
          </button>
      </ContextMenu>
  );
}
