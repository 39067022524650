import React from "react";
import AddIcon from "../../Icons/AddIcon";

import styles from "./AddToProfileButton.module.scss";

/**
 * Button used to add skills or objects on a profile page
 */
export function AddToProfileButton({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <button
      className={styles["add-button"] + " btn btn-primary"}
      onClick={onClick}
      style={{ borderRadius: "20px" }}
    >
      <AddIcon lineLength={16} lineWidth={2} margin="0 8px 0 0" />
      {children}
    </button>
  );
}
