import React, { useCallback, useState } from "react";
import { Marker, Popup } from "react-leaflet";

import { getContextMenuStyle } from "Components/ContextMenu/ContextMenu";
import { MinimalLocationData } from "../HomePageTypes";
import { usePopupOpeningEffect } from "hooks/map/usePopupOpeningEffect";
import { DispatchAction } from "../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";

import { LocationMarkerPopup } from "./LocationMarkerPopup/LocationMarkerPopup";

import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
import { defaultAppearanceSettings } from "Pages/Settings/AppearanceSettings/defaultAppearanceSettings";
import NetworkMarkers from "Components/NetworkMarkers/NetworkMarkers";
import { useMarkerIcon } from "./useMarkerIcon";

let DEFAULT_LATITUDE = 0;
let DEFAULT_LONGITUDE = 0;

export function LocationMarker({
    location,
    state,
    dispatch,
}: {
    location: MinimalLocationData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [popupIsOpen, setPopupIsOpen] = useState(false);
    let { currentUser } = useCurrentUserDetails();
    let { markerRefCallback } = usePopupOpeningEffect(
        state.openPopup !== null &&
            state.openPopup.type === "location" &&
            state.openPopup.id === location.id,
        useCallback(() => dispatch({ type: ACTIONS.CLOSE_POPUP }), [dispatch])
    );

    const icon = useMarkerIcon(
        location,
        currentUser?.appearanceConfig || defaultAppearanceSettings
    );

    if (!location) return null;

    return (
        <>
        
        <Marker
            position={[location.coordinates.y, location.coordinates.x]}
            icon={icon}
            eventHandlers={{
                contextmenu: (event) => {
                    let mouseEvent = event.originalEvent;
                    let style = getContextMenuStyle(mouseEvent);
                    dispatch({
                        type: ACTIONS.contextMenu,
                        value: { style, location, type: "location" },
                    });
                },
                popupopen: () => setPopupIsOpen(true),
                popupclose: () => setPopupIsOpen(false),
            }}
            ref={markerRefCallback}
            riseOnHover
        >
            <Popup>
                {popupIsOpen ? (
                    <LocationMarkerPopup
                        location={location}
                        dispatch={dispatch}
                        state={state}
                    />
                ) : null}
            </Popup>
        </Marker>

        <NetworkMarkers
            data={location}
            centerPosition={[
            location.coordinates?.y ?? DEFAULT_LATITUDE,
            location.coordinates?.x ?? DEFAULT_LONGITUDE,
        ]}
    />
        </>
    );
}
