import React from "react";
import CommonInterests from "../../../Components/CommonInterests/CommonInterests";
import SettingsSubpage from "../SettingsSubpage/SettingsSubpage";

const CommonInterestsPage: React.FC = () => {
  return (
    <SettingsSubpage title="Common Interests">
      <CommonInterests />
    </SettingsSubpage>
  );
};

export default CommonInterestsPage;
