import { useState, useEffect } from 'react';
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { apiRoutes } from "services/routes";

export interface Data {
    id?: string;
    type?: string;
    name: string;
    reason_to_contact: string;
    profile_photo: string;
    contact_timestamp: string;
    minimum_time_to_contact: string;
    maximum_time_to_contact: string;
    country: string;
    city: string;
}

export const useFetchData = () => {
    const [data, setData] = useState<Data[] | null>(null);
    const [loading, setLoading] = useState(true);
    const apiInstance = useAxiosPrivate();

    useEffect(() => {
        apiInstance.get(apiRoutes.GET_CONNECTIONS_KEEP_IN_TOUCH)
            .then(async (response) => {
                const fetchedData = await response.data.data;
                setData(Array.isArray(fetchedData) ? fetchedData : []);
                setLoading(false);
            })
            .catch((reason) => {
                console.error(reason);
                setLoading(false);
            });
    }, [apiInstance]);

    return { data, loading };
};
