import * as React from 'react';
import MuiReplaceCustomButton from '../MuiReplaceCustomButton/MuiReplaceCustomButton';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';  // Add your custom import here
import CustomDialog from '../CustomDialog/CustomDialog';
import CustomDialogActions from '../CustomDialogActions/CustomDialogActions';
import CustomDialogContent from '../CustomDialogContent/CustomDialogContent'; // New Import

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";
import { DispatchAction } from 'Pages/Home/reducer';

import CustomEditor, { JournalTemplate } from '../TextBox/Editor';

import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';


/**
 * Journal add dialog box
 * renders textarea
 */
export default function JournalEntry({
	state,
	dispatch,
	addingNew,
}: {
	state?: HomePageState;
	dispatch?: React.Dispatch<DispatchAction>;
	addingNew?: (id: number | null) => void;
}) {
	const [text, setText] = React.useState<any>(null);
	const [placeHolder, setPlaceholder] = React.useState('');

	const handleClose = () => {
		if (dispatch) {
			dispatch({
				type: ACTIONS.addingJournalEntry,
				value: null,
			});
		} else if (addingNew) {
			addingNew(null);
		}
	};

	React.useEffect(() => {
		if (state?.addingJournalEntry && !addingNew) {
			const entity: any = {
				blocks: [
					{
						key: 'bv74f',
						text: '',
						type: 'unstyled',
						depth: 0,
						inlineStyleRanges: [],
						entityRanges: [
							{
								offset: 0,
								length: 0,
								key: 0,
							},
						],
						data: {},
					},
				],
				entityMap: {
					'0': {
						type: 'mention',
						mutability: 'SEGMENTED',
						data: {
							mention: {
								id: 4,
								name: '',
								profile: null,
								type: '',
							},
						},
					},
				},
			};
			entity.blocks[0].text =
				(state.addingJournalEntry.type === 'place'
					? 'Visited '
					: 'Meet with ') + state.addingJournalEntry.name;
			entity.blocks[0].entityRanges[0].offset =
				state.addingJournalEntry.type === 'place' ? 8 : 10;
			entity.blocks[0].entityRanges[0]['length'] =
				state.addingJournalEntry.name?.length || 6;

			let obj: JournalTemplate = {
				id: state.addingJournalEntry.id,
				name: state.addingJournalEntry.name,
				avatar: state.addingJournalEntry.avatar,
				type: state.addingJournalEntry.type,
			};
			entity.entityMap[0].data.mention = obj;
			setPlaceholder(JSON.stringify(entity));
		}
	}, []);
	const privateAPIInstance = useAxiosPrivate();
	const saveJournalEntry = async () => {
		console.log(text);
		if (
			text?.blocks.length !== 0 ||
			!(text?.blocks.length === 0 && text.blocks[0].text === '')
		) {
			let res = await privateAPIInstance.post(
				apiRoutes.CREATE_OR_GET_JOURNALS,
				{ journal_entry: text }
			);

			handleClose();
			if (addingNew) addingNew(res.data.id);
		}
		handleClose();
	};

	return (
		<div>
			<CustomDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={true}
			>
				<CustomDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					Add a journal
				</CustomDialogTitle>
				<CustomDialogContent style={{ overflow: 'hidden' }}>
					<CustomEditor
						edited={true}
						editData={placeHolder}
						storeData={setText}
					/>
				</CustomDialogContent>
				<CustomDialogActions>
					<MuiReplaceCustomButton   
					autoFocus={true}
					color='green'
 					onClick={saveJournalEntry}>
						Save changes
					</MuiReplaceCustomButton>
				</CustomDialogActions>
			</CustomDialog>
		</div>
	);
}
