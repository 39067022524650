
import axios from 'axios';
const NOMINATIM_API = 'https://nominatim.openstreetmap.org/search?';


export const geocodeService = async (params?: string) => {
    try {
        const response = await axios.get(
            `${NOMINATIM_API}q=${params}&format=json&limit=5`
        );
        return response.data;
    } catch (error) {
        return [];
    }
}
