// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_map-cursor-crosshair__INOXM .Home_map-container__Ta5Te {
  cursor: crosshair;
}

.Home_main__RBgDl {
  width: 100%;
  height: 100%;
  position: relative;
}
.Home_main__RBgDl::-webkit-scrollbar {
  display: none;
  width: 0;
}
.Home_main__RBgDl .Home_map-container__Ta5Te {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AACD;AACC;EACC,aAAA;EACA,QAAA;AACF;AAEC;EACC,WAAA;EACA,YAAA;AAAF","sourcesContent":[".map-cursor-crosshair .map-container {\n\tcursor: crosshair;\n}\n\n.main {\n\twidth: 100%;\n\theight: 100%;\n\tposition: relative;\n\n\t&::-webkit-scrollbar {\n\t\tdisplay: none;\n\t\twidth: 0;\n\t}\n\n\t.map-container {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map-cursor-crosshair": `Home_map-cursor-crosshair__INOXM`,
	"map-container": `Home_map-container__Ta5Te`,
	"main": `Home_main__RBgDl`
};
export default ___CSS_LOADER_EXPORT___;
