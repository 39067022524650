// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactMarkerPopup_contact-popup-content__lkzCh {
  font-family: var(--hand-font);
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 220px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ContactMarker/ContactMarkerPopup/ContactMarkerPopup.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".contact-popup-content {\n    font-family: var(--hand-font);\n    padding-bottom: 5px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: fit-content;\n    min-width: 220px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact-popup-content": `ContactMarkerPopup_contact-popup-content__lkzCh`
};
export default ___CSS_LOADER_EXPORT___;
