// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadFile_list-container__wniz6 h5 {
  font-weight: 400;
  margin: 15px 0 20px 0;
}
.LoadFile_list-container__wniz6 .LoadFile_csv-format__gzqyk,
.LoadFile_list-container__wniz6 .LoadFile_no-contacts-found__1seiz {
  margin-bottom: 20px;
}
.LoadFile_list-container__wniz6 .LoadFile_csv-format__gzqyk {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.LoadFile_list-container__wniz6 .LoadFile_csv-format__gzqyk > * {
  margin-right: 20px;
}
.LoadFile_list-container__wniz6 .LoadFile_no-contacts-found__1seiz {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 50px;
}
.LoadFile_list-container__wniz6 .LoadFile_no-contacts-found__1seiz p {
  color: grey;
  text-align: center;
  max-width: 300px;
}
.LoadFile_list-container__wniz6 .LoadFile_button-container__JY4\\+8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ImportContacts/LoadFile/LoadFile.module.scss"],"names":[],"mappings":"AACG;EACG,gBAAA;EACA,qBAAA;AAAN;AAGG;;EAEG,mBAAA;AADN;AAIG;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAFN;AAIM;EACG,kBAAA;AAFT;AAMG;EACG,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAJN;AAMM;EACG,WAAA;EACA,kBAAA;EACA,gBAAA;AAJT;AAQG;EACG,aAAA;EACA,mBAAA;EACA,yBAAA;AANN","sourcesContent":[".list-container {\n   h5 {\n      font-weight: 400;\n      margin: 15px 0 20px 0;\n   }\n\n   .csv-format,\n   .no-contacts-found {\n      margin-bottom: 20px;\n   }\n\n   .csv-format {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      flex-wrap: wrap;\n\n      >* {\n         margin-right: 20px;\n      }\n   }\n\n   .no-contacts-found {\n      height: 100%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      padding: 20px 50px;\n\n      p {\n         color: grey;\n         text-align: center;\n         max-width: 300px;\n      }\n   }\n\n   .button-container {\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-end;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list-container": `LoadFile_list-container__wniz6`,
	"csv-format": `LoadFile_csv-format__gzqyk`,
	"no-contacts-found": `LoadFile_no-contacts-found__1seiz`,
	"button-container": `LoadFile_button-container__JY4+8`
};
export default ___CSS_LOADER_EXPORT___;
