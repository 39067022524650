import { MinimalContactData } from "../../HomePageTypes";

import {  PostToApiFnType } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

/**
 * Sends a consent request (or cancels it) for viewing a contact.
 * If the request is accepted, the contact's visibility is updated.
 *
 * ---
 * @param updateContact The function for updating the contact
 * @param contact The current value of the contact
 */
export default function handleContactConsentRequest(
  updateContact: (contact: MinimalContactData) => void,
  contact: MinimalContactData,
  postToApi: PostToApiFnType
) {
  const handleRequest = (
      method: "delete" | "post",
      setVisibilityTo: "requested" | "needs request"
  ) => {
      updateContact({ ...contact, visibility: "loading" });
      postToApi(
          apiRoutes.CREATE_OR_DELETE_CONTACT_SHARING_REQUEST(contact.id),
          undefined,
          method
      ).then((response) =>
          response.ok
              ? updateContact({ ...contact, visibility: setVisibilityTo })
              : updateContact({ ...contact, visibility: contact.visibility })
      );
  };

  if (contact.visibility === "needs request") {
      handleRequest("post", "requested");
  } else if (contact.visibility === "requested") {
      handleRequest("delete", "needs request");
  }
}

