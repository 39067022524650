import React, { useEffect, useState } from 'react';

const UserGreeting = ({ firstName }: { firstName: string | undefined }) => {
  const [typedText, setTypedText] = useState<string>('');

  useEffect(() => {
    const text = firstName
      ? `👋Hi ${firstName}! Finalize setting up your Mapbond Account`
      : 'Finalize setting up your Mapbond Account';

    const interval = setInterval(() => {
      if (text.length > typedText.length) {
        setTypedText(text.substring(0, typedText.length + 1));
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust the typing speed as needed

    return () => clearInterval(interval);
  }, [firstName, typedText]);

  return (
    <div className="text-container">
      <h1 className="text-3xl text-center" style={{ 
        whiteSpace: 'pre-line', 
        overflowWrap: 'break-word',
        padding: '100px 0px 0px 0px' }}>
        {typedText}
      </h1>
    </div>
  );
};

export default UserGreeting;
