import React from "react";
import styles from "../AddTravelPlan.module.scss";
import RangeDatePicker from "Components/RangeDatePicker/RangeDatePicker";
import { BasicTravelData, TravelPlanErrors } from "Pages/Home/HomePageTypes";

interface DateInputSectionProps {
    travelPlan: BasicTravelData;
    setTravelPlan: React.Dispatch<React.SetStateAction<BasicTravelData>>;
    errors: TravelPlanErrors;
    setErrors: React.Dispatch<React.SetStateAction<TravelPlanErrors>>; // Add this prop
}

export default function DateInputSection({
    travelPlan,
    setTravelPlan,
    errors,
    setErrors, // Add this prop
}: DateInputSectionProps) {
    return (
        <div className={styles["date-input-row"]}>
            <RangeDatePicker
                startDate={travelPlan.arrivalDate ?? null}
                endDate={travelPlan.departureDate ?? null}
                minDate={new Date()}
                onChange={(startDate, endDate) => {
                    setTravelPlan({
                        ...travelPlan,
                        arrivalDate: startDate ?? null,
                        departureDate: endDate ?? null,
                    });
                    setErrors({
                        ...errors,
                        arrival: "", // Clear the arrival error
                        departure: "", // Clear the departure error
                    });
                }}
                startDatePlaceholder="Arrival date"
                endDatePlaceholder="Departure date"
                highlightToday
            />
        </div>
    );
}
