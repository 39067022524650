//ProfileSettings.tsx
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import styles from "./ProfileSettings.module.scss";
import { AddToProfileButton } from "Components/AddToProfileButton/AddToProfileButton";
import { ProfilePhoto } from "./ProfilePhoto/ProfilePhoto";
import { TextDetails } from "./TextDetails/TextDetails";
import { SkillsList } from "./SkillsList/SkillsList";
import { ObjectsList } from "./ObjectsList/ObjectsList";
import { PersonalProfileData } from "../Settings";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { CurrentUserDetails } from "types/shared";
import { ApiError } from "hooks/useAPI";
import { useDebouncedAction } from "hooks/useDebounce";
import { apiRoutes } from "services/routes";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { SettingsAccordion } from "../SettingsAccordion/SettingsAccordion";

export function ProfileSettings({
    profileData,
    setProfileData,
    setCurrentUserDetails,
}: {
    profileData: PersonalProfileData;
    setProfileData: React.Dispatch<
        React.SetStateAction<PersonalProfileData | null | ApiError>
    >;
    setCurrentUserDetails: React.Dispatch<
        React.SetStateAction<CurrentUserDetails | null>
    >;
}) {
    let [savingData, setSavingData] = useState(false);
    let [newSkill, setNewSkill] = useState<string | null>(null);
    let [newObject, setNewObject] = useState<string | null>(null);
    let delayRequest = useDebouncedAction();

    const authInstance = useAxiosPrivate();

    const updateProfileData = (newProfileData: PersonalProfileData) => {
        setSavingData(true);

        delayRequest(() => {
            authInstance
                .post(apiRoutes.GET_OR_UPDATE_USER, {
                    first_name: newProfileData.first_name,
                    last_name: newProfileData.last_name,
                    bio: newProfileData.bio,
                    occupation: newProfileData.occupation,
                    email: newProfileData.email,
                    phone_number: newProfileData.phoneNumber,
                    coordinates: newProfileData.coordinates
                        ? `${newProfileData.coordinates.y},${newProfileData.coordinates.x}`
                        : "0,0",
                    facebook: newProfileData.facebook,
                    instagram: newProfileData.instagram,
                    twitter: newProfileData.twitter,
                    linkedin: newProfileData.linkedin,
                    youtube: newProfileData.youtube,
                    website: newProfileData.website,
                })
                .then(() => {
                    setSavingData(false);
                });
        }, 1000);

        setProfileData(newProfileData);

        setCurrentUserDetails((currentValue) =>
            currentValue
                ? {
                      ...currentValue,
                      coordinates:
                          newProfileData.coordinates ||
                          currentValue.coordinates,
                      name: newProfileData.first_name || currentValue.name,
                      profilePicture:
                          newProfileData.profilePicture ||
                          currentValue.profilePicture,
                  }
                : currentValue
        );
    };

    return (
        <>
            <section className={styles["profile-photo"]}>
                <ProfilePhoto
                    profileData={profileData}
                    setProfileData={updateProfileData}
                />
            </section>
            <section className={styles["profile-details"]}>
                <SettingsAccordion>
                    <h3 className={styles["section-heading"]}>
                        Your Profile{" "}
                        {savingData && (
                            <SmallLoadingSpinner linethickNess="3px" />
                        )}
                    </h3>
                    <>
                        <TextDetails
                            setProfileData={updateProfileData}
                            profileData={profileData}
                        />
                        <hr />
                        <>
                            <SkillsList
                                profileData={profileData}
                                newSkill={newSkill}
                                setNewSkill={setNewSkill}
                                setProfileData={updateProfileData}
                            />
                            <ObjectsList
                                profileData={profileData}
                                newObject={newObject}
                                setNewObject={setNewObject}
                                setProfileData={updateProfileData}
                            />
                            <div className={styles["add-buttons"]}>
                                <AddToProfileButton
                                    onClick={() => setNewSkill("")}
                                >
                                    Add Skill
                                </AddToProfileButton>
                                <AddToProfileButton
                                    onClick={() => setNewObject("")}
                                >
                                    Add Object
                                </AddToProfileButton>
                            </div>
                        </>
                    </>
                </SettingsAccordion>
            </section>
        </>
    );
}
