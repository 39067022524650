import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import styles from "./LocationMarkerPopup.module.scss";
import { getContextMenuStyle } from "Components/ContextMenu/ContextMenu";
import OptionsButton from "Components/OptionsButton/OptionsButton";
import { LocationData } from "../../HomePageTypes";
import { DispatchAction } from "../../reducer";
import { ApiError } from "hooks/useAPI";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import JournalItems from "Components/JournalItems/JournalItems"
import ExpandableDescription from 'Components/ExpandableDescription/ExpandableDescription'
import { NetworksIconsDisplay } from 'Components/NetworksIconsDisplay/NetworksIconsDisplay'

import ObjectDisplayAndDelete from "Components/ObjectDisplayAndDelete/ObjectDisplayAndDelete";
import ObjectAdder from "Components/ObjectAdder/ObjectAdder";

import DetailsSection from "Components/DetailsSection/DetailsSection";
import LocationHeader from "Components/LocationHeader/LocationHeader";

import AddressList from 'Components/AddressManager/AddressList';
import ExperienceList from 'Components/ExperienceManager/ExperienceList';


/**
 * This function is responsible for rendering the full content of the location popup.
 * 
 * @param location - The location data to be displayed in the popup.
 * @param setLocation - A function that can be used to update the location data.
 * @param dispatch - The Redux dispatch function.
 * @param state - The application state.
 * 
 * @returns The full content of the location popup.
 */
export function FullLocationPopupContent({
    location,
    setLocation,
    dispatch,
    state,
}: {
    location: LocationData;
    setLocation: React.Dispatch<
        React.SetStateAction<ApiError | LocationData | null>
    >;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let addingKeepInTouch = false;
    const maxDescriptionLength = 100;
    const maxTitleLength = 19;

    /**
     * This function is called when the options button is clicked. It displays a context menu with options
     * related to the location.
     * 
     * @param event - The event that triggered the click.
     */
    const onOptionsButtonClicked = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation();
        let style = getContextMenuStyle(event);
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style, location, type: "location" },
        });
    };

    /**
     * This function is called when an object is deleted from the location. It updates the location data
     * to remove the deleted object.
     * 
     * @param object - The name of the object that was deleted.
     */
    const updateUIAfterDeleteObject = (object: string) => {
        setLocation({
            ...location,
            objects: location.objects.filter((obj) => obj.name !== object),
        });
    };
    let placeId = location.id;

    return (
        <>
            <div>
                <LocationHeader 
                    location={location} 
                    maxTitleLength={maxTitleLength} 
                />


                <ExpandableDescription
                    description={location.description}
                    maxDescriptionLength={maxDescriptionLength}
                />

                <AddressList 
					entityType='place'
					entityId={placeId}
					/>

                <ExperienceList 
					entityType='place'
					entityId={placeId}
					/>


                <NetworksIconsDisplay
                    networks={location.networks}
                />

                <DetailsSection
                    data={location}
                    addingKeepInTouch={addingKeepInTouch}
                />


                <div className={styles["tabs-container"]}>
                    <Tabs
                        defaultIndex={
                            state.objectBeingAddedTo &&
                                state.objectBeingAddedTo.type === "location" &&
                                state.objectBeingAddedTo.for.id === location.id
                                ? 1
                                : undefined
                        }
                    >
                        <TabList>
                            <Tab>Details</Tab>
                            <Tab>Objects</Tab>
                            <Tab>Feed</Tab>
                        </TabList>

                        <TabPanel>
                            {/* Add location-specific details here */}
                        </TabPanel>

                        <TabPanel>


                            <ObjectDisplayAndDelete
                                dispatch={dispatch}
                                objects={location.objects}
                                id={location.id}
                                target="place"
                                onDelete={updateUIAfterDeleteObject}
                                highlightObject={
                                    state.openPopup?.highlightObject
                                }
                            />

                            <ObjectAdder
                                target="place"
                                id={location.id}
                                onObjectAdded={(newObjects) =>
                                    setLocation({
                                        ...location,
                                        objects: [...location.objects, ...newObjects],
                                    })
                                }
                                autoCompleteCategory="objects"
                            />

                        </TabPanel>

                        <TabPanel>
                            <JournalItems journals={location.journals} />

                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            <OptionsButton onClick={onOptionsButtonClicked} />
        </>
    );
}
