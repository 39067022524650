import React, { useState } from "react";

import styles from "./MarkerObjectsList.module.scss";
import { OwnedObject } from "../HomePageTypes";
import MarkerObject from "./MarkerObject/MarkerObject";
import { EditingConfirmationButtons } from "./EditingConfirmationButtons/EditingConfirmationButtons";
import { NewElementInput } from "./NewElementInput/NewElementInput";
import { useAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";
import { apiRoutes } from "services/routes";
import AddIcon from "Icons/AddIcon";

/**
 * List of objects on a marker's popup
 */
export default function MarkerObjectsList({
    addObject,
    cancelAddingObject,
    addingNewObject,
    beginAddingNewObject,
    elements,
    highlightedElement,
    deleteObject,
    addNewObjectImage,
    deleteObjectImage,
    idOfObjectWithImageBeingUploaded,
    triggerSearch,
}: {
    elements: Array<OwnedObject>;
    addingNewObject?: boolean;
    addObject?: (object: string | null) => void;
    beginAddingNewObject: () => void;
    deleteObject?: (object: OwnedObject) => void;
    cancelAddingObject?: () => void;
    highlightedElement?: string;
    addNewObjectImage?: (
        imageFile: File,
        dataUrl: string,
        objectId: number
    ) => void;
    deleteObjectImage?: (imageId: number, objectId: number) => void;
    idOfObjectWithImageBeingUploaded?: number | null;
    triggerSearch: (keywords: string) => void;
}) {
    let [listExpanded, setListExpanded] = useState(false);
    let [newObjectName, setNewObjectName] = useState("");
    let autocompleteSuggestions = useAutocompleteSuggestions(
        apiRoutes.GET_AUTOCOMPLETE("objects"),
        newObjectName
    );

    const addNewObject = (objectName: string) => {
        objectName === ""
            ? cancelAddingObject && cancelAddingObject()
            : addObject && addObject(objectName);
        setNewObjectName("");
    };

    const checkIfHighlighted = (element: string) => {
        if (!element) return undefined;
        return highlightedElement === element
            ? styles["highlighted"]
            : undefined;
    };

    const addImageCallback = (objectId: number, canEdit: boolean) =>
        addNewObjectImage && canEdit
            ? (file: File, url: string) =>
                  addNewObjectImage(file, url, objectId)
            : undefined;

    // console.log("console.log(elements)",elements);

    //TODO Currently after adding a new object, this will be added as null to elements. Find how to properly update with the correct object
    return (
        <div className={styles["dynamic-list"]}>
            <div className={styles.elements}>
                {/* <h6>Objects:</h6> */}
                <ul
                    onClick={() => setListExpanded(!listExpanded)}
                    style={{
                        cursor: elements.length > 51 ? "pointer" : undefined,
                        padding:
                            elements.length > 0 || addingNewObject
                                ? "10px 0"
                                : "0",
                    }}
                >
                    {elements.length > 0 &&
                        elements
                            .slice(0, listExpanded ? undefined : 50)
                            .map((element, index) => {
                                // Skip this iteration if element is null or undefined
                                if (!element) {
                                    return null;
                                }

                                return (
                                    <li
                                        className={checkIfHighlighted(
                                            element.name
                                        )}
                                    >
                                        <MarkerObject
                                            element={element}
                                            uploadingImage={
                                                idOfObjectWithImageBeingUploaded ===
                                                element.id
                                            }
                                            deleteObject={
                                                element.canEdit
                                                    ? deleteObject
                                                    : undefined
                                            }
                                            addNewImage={addImageCallback(
                                                element.id,
                                                element.canEdit
                                            )}
                                            deleteImage={
                                                element.canEdit
                                                    ? deleteObjectImage
                                                    : undefined
                                            }
                                            triggerSearch={triggerSearch}
                                        />
                                        {!listExpanded && index === 50 ? (
                                            <span
                                                className={styles["see-more"]}
                                            >
                                                {elements.length > 51 &&
                                                    " . . . (see more)"}
                                            </span>
                                        ) : null}
                                    </li>
                                );
                            })}
                    <li style={{ display: addingNewObject ? "none" : "" }}>
                        <button
                            style={{
                                backgroundColor: "transparent",
                                padding: "10px",
                            }}
                            onClick={beginAddingNewObject}
                        >
                            <AddIcon
                                color="var(--accent-color)"
                                lineLength={16}
                            />
                        </button>
                    </li>
                    {addingNewObject && (
                        <NewElementInput
                            value={newObjectName}
                            setValue={setNewObjectName}
                            finishAdding={addNewObject}
                            objectOrSkill="object"
                            autocompleteSuggestions={autocompleteSuggestions.filter(
                                (element) =>
                                    !elements
                                        .map((object) => object.name)
                                        .includes(element)
                            )}
                            cancelAdding={() =>
                                cancelAddingObject && cancelAddingObject()
                            }
                        />
                    )}
                </ul>
                {addingNewObject && (
                    <EditingConfirmationButtons
                        accept={() => addNewObject(newObjectName)}
                        cancel={() =>
                            cancelAddingObject && cancelAddingObject()
                        }
                    />
                )}
            </div>
        </div>
    );
}
