import React, { useState } from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import TextInput from 'Components/forms/TextInput';
import LeafletMap from './LeafletMap'; // Ensure the correct path is used
import { useGeocoder } from "hooks/useGeocoder";

type TOSMResponse = {
  addresstype?: string;
  boundingbox?: [];
  class?: string;
  display_name?: string;
  importance?: number;
  lat?: number;
  lon?: number;
  name?: string;
  osm_id?: number;
  osm_type?: string;
  place_id?: number;
  place_rank?: number;
  type?: string;
};

type LocationInputProps = {
    register: UseFormRegister<any>;
    errors: FieldErrors;
    updateCoordinates: Function;
};

const LocationInput: React.FC<LocationInputProps> = ({
    register,
    errors,
    updateCoordinates
}) => {
    const [lat, setLat] = useState(44); // Default latitude
    const [lon, setLon] = useState(25); // Default longitude
    const [zoom, setZoom] = useState(2); // Default zoom
    const [textInputValue, setTextInputValue] = useState(""); // State to store TextInput value

    const onMapLocationChange = (newLat : number, newLon: number ) => {
        console.log(`Map location changed to Lat: ${newLat}, Lon: ${newLon}`);
        // handleSelection("Clicked Location", newLat, newLon);
        setTextInputValue("Clicked Location");
        setLat(newLat);
        setLon(newLon);
        setZoom(12); // Default zoom
        updateCoordinates(`${newLat},${newLon}`); // Update coordinates using the callback

    };
    
    const onSearchResultSelect = (displayName: string , newLat : number, newLon : number) => {
        console.log(`Search result selected: ${displayName}, Lat: ${newLat}, Lon: ${newLon}`);
        // handleSelection(displayName, newLat, newLon);
        setTextInputValue(displayName);
        setLat(newLat);
        setLon(newLon);
        setZoom(12); // Default zoom
        updateCoordinates(`${newLat},${newLon}`); // Update coordinates using the callback

    };
    const { searchResult } = useGeocoder(textInputValue) as { searchResult: TOSMResponse[] };

    const showSearchResult = searchResult.length > 0; // Calculate based on length
    console.log(`Show search result: ${showSearchResult}`);
    console.log(`Search result: ${JSON.stringify(searchResult)}`);
    console.log(`Search result length: ${searchResult.length}`);
    console.log("TextInput.name", TextInput.name);
    // const { searchResult, showSearchResult, setSearchResult } =
    // useGeocoder(TextInput.name);

    return (
        <div className="relative">
            <p className="italic text-xs">
                We do not share your exact location with other
                users, unless you consent. We randomly reposition it
                within 10km by default. Additionally, if you leave
                it empty, we'll assign you a random location.
            </p>
            <TextInput
                register={register}
                inputName="location"
                errors={errors}
                placeholder="Start typing to search..."
                inputLabel="Enter your location or use the Map:"
                required={false}
                inputValue={textInputValue}
                onInputChange={setTextInputValue}
            />

            {showSearchResult && (
                <div className="absolute bg-white shadow-md border w-full max-h-[150px] overflow-scroll p-3 mt-1" style={{ zIndex: 9999 }}>
                    <ul>
                        {searchResult.map((result, id) => (
                            <li
                                key={`osm-result-${id}`}
                                className="p-2 cursor-pointer text-black hover:bg-gray-300"
                                onClick={() => onSearchResultSelect(
                                    result.display_name ?? 'Unknown Location',
                                    result.lat ?? 0,
                                    result.lon ?? 0
                                )}
                            >
                                <span>{result.display_name ?? 'Unknown Location'}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <LeafletMap 
                zoom={zoom}
                initialLat={lat} 
                initialLon={lon} 
                onLocationChange={onMapLocationChange} 
            />
        </div>
    );
};

export default LocationInput;