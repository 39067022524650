import React, { useState } from "react";

import styles from "./ExpandableButton.module.scss";

/**
 * Contains content which can be shown when the button is clicked.
 * It takes two child components. The first one is displayed in the
 * button, while the second one is displayed when the button is
 * clicked
 *
 * @param props
 * @returns
 * @constructor
 * @example
 * <ExpandableButtonContent>
 *  <div>Content</div>
 * </ExpandableButtonContent>
 */
export default function ExpandableButton({
    children,
    collapseOnClick,
    expandOnHover,
}: {
    children: [React.ReactNode, React.ReactNode];
    /** Whether the expandable should collapse when you click on an option */
    collapseOnClick?: boolean;
    /** Whether the expandable should automatically expand when you hover over it */
    expandOnHover?: boolean;
}) {
    let [isExpanded, setIsExpanded] = useState(false);

    const onClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        if (isExpanded) {
            if (collapseOnClick || collapseOnClick === undefined) {
                setIsExpanded(false);
            } else {
                event.stopPropagation();
            }
        } else {
            setIsExpanded(true);
            event.stopPropagation();

            let clickHandler = () => {
                setIsExpanded(false);
                document.removeEventListener("click", clickHandler);
            };

            document.addEventListener("click", clickHandler);
        }
    };

    return (
        <div
            className={`${styles["expandable-button"]} ${
                isExpanded ? styles["expanded"] : ""
            }`}
            onClick={onClick}
            onMouseLeave={() => isExpanded && setIsExpanded(false)}
            onMouseEnter={() =>
                !isExpanded && expandOnHover === true && setIsExpanded(true)
            }
        >
            {isExpanded ? (
                <div className={styles["content"]}>{children[1]}</div>
            ) : (
                <button className={styles["button"]}>{children[0]}</button>
            )}
        </div>
    );
}
