import React from 'react';
import { BasicNetworkData } from "Components/NetworkGroups/BasicNetworkDataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { CurrentUserDetails } from "types/shared";

interface NetworkHeaderProps {
    network: BasicNetworkData;
    currentUser: CurrentUserDetails | null; 
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkHeader: React.FC<NetworkHeaderProps> = ({ network, currentUser, setEditModal, setDeleteModal }) => {
    return (
        <div className="flex flex-col items-center">
            {network.network_logo ? (
                <img
                    className="h-20 w-20 rounded-full"
                    src={network.network_logo}
                    alt="Network Logo"
                />
            ) : (
                <FontAwesomeIcon
                    fontSize={70}
                    icon={faUserGroup}
                />
            )}
            <h2 className="mt-4 text-2xl font-bold text-gray-900">
                {network.name}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
                {network.description}
            </p>
            {currentUser && network.owner.id === currentUser.id && (
                <div>
                    <button
                        onClick={() => setEditModal(true)}
                        className="py-1 px-2 m-2 bg-green-300 rounded hover:bg-green-400 text-sm"
                    >
                        Edit network
                    </button>
                    <button
                        onClick={() => setDeleteModal(true)}
                        className="py-1 px-2 m-2 bg-red-300 rounded hover:bg-red-400 text-sm"
                    >
                        Delete network
                    </button>
                </div>
            )}
        </div>
    );
};

export default NetworkHeader;
