import React from "react";
import {
  FeatureGroup, // Import FeatureGroup
  useMap,
} from "react-leaflet";
import Cluster from "../Cluster/Cluster";
import { UserMarker } from "../UserMarker/UserMarker";
import ContactMarker from "../ContactMarker/ContactMarker";
import { LocationMarker } from "../LocationMarker/LocationMarker";
import TravelPlan from "../TravelPlan/TravelPlan";
import { DispatchAction } from "../reducer";

import { HomePageState } from "Pages/Home/HomePageState";


interface MapMarkersProps {
  state: HomePageState;
  dispatch: React.Dispatch<DispatchAction>; 
  featureGroupRef: React.RefObject<L.FeatureGroup>;
}

const MapMarkers: React.FC<MapMarkersProps> = ({
  state,
  dispatch,
  featureGroupRef,
}) => {
  const map = useMap(); // Get the map instance

  return (
    <FeatureGroup ref={featureGroupRef}> {/* Wrap markers with FeatureGroup */}
      {state.clusters instanceof Array &&
        state.clusters.map((cluster, index) => (
          <Cluster
            clusterData={cluster}
            zoomToBounds={(bounds) =>
              map.flyToBounds(bounds, {
                maxZoom: 14,
                duration: 0.5,
              })
            }
            key={`cluster:${index}`} // Use the index as the key
          />
        ))}
      {state.selectedLayers.includes("Users") &&
        state.users instanceof Array &&
        state.users.map((user) => (
          <UserMarker
            user={user}
            key={`user:${user.id}`}
            dispatch={dispatch}
            state={state}
          />
        ))}

      {state.selectedLayers.includes("Contacts") &&
        state.contacts instanceof Array &&
        state.contacts.map((contact) =>
          state.contactBeingEdited &&
          state.contactBeingEdited.data.id === contact.id ? null : (
            <ContactMarker
              contact={contact}
              key={`contact:${contact.id}`}
              state={state}
              dispatch={dispatch}
            />
          )
        )}
      {state.selectedLayers.includes("Locations") &&
        state.locations instanceof Array &&
        state.locations.map((location) =>
          state.locationBeingEdited &&
          state.locationBeingEdited.data.id === location.id ? null : (
            <LocationMarker
              location={location}
              key={`location:${location.id}`}
              state={state}
              dispatch={dispatch}
            />
          )
        )}
      {state.selectedLayers.includes("Travel Plans") &&
        state.travelPlans instanceof Array &&
        state.travelPlans.map((travelPlan) => (
          <TravelPlan
            key={`travelPlan:${travelPlan.id}`}
            dispatch={dispatch}
            state={state}
            travelPlan={travelPlan}
          />
        ))}
    </FeatureGroup>
  );
};

export default MapMarkers;
