import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useRouter } from "hooks/useRouter";
import { useAuthContext } from "Context/AuthContext";
import AppLayout from "layout/Layout";
import { useAuth } from './hooks/useAuth'

interface AppRouteProps {
  path: string;
  children: JSX.Element | JSX.Element[];
  exact?: boolean;
  isProtected?: boolean;
  showNavbar?: boolean;
}

/**
 * `AppRoute`: A wrapper around the Route component from
 * react-router-dom. It adds the navbar to the page.
 *
 * @example
 * <AppRoute  path="/@:userHandle">
 *   <UserProfile />
 * </AppRoute>
 */
function AppRoute({
  path,
  children,
  exact,
  isProtected = false,
  showNavbar = true,
}: AppRouteProps) {
  const router = useRouter();
  const restrictedRoutesAfterAuthentication =
    router.pathname === "/accounts/login" ||
    router.pathname === "/password/reset/" ||
    router.pathname === "/accounts/signup/";
    const { userHasCompletedOnboarding } = useAuth()

  const { isAuthenticated } = useAuthContext();

  const disableRestrictedRoutes =
    restrictedRoutesAfterAuthentication && isAuthenticated;
  const disableProtectedRoutes = isProtected && !isAuthenticated;
  const disableOnboardingPage =
    router.pathname === "/accounts/onboarding" && !isAuthenticated
      ? true
      : isAuthenticated && userHasCompletedOnboarding
      ? true
      : false;

  useEffect(() => {
    // if the user visits a restricted route and they are already authenticated, then send them home
    if (disableRestrictedRoutes) {
      router.push("/");
      // if the user visits onboarding page when they are already authenticated and their profile is completed, then send them to the login page
      // or when they visit when they are not authenticated, send them to the login page
    } else if (disableOnboardingPage) {
      router.push("/");
      // if a user visits a protected route when they are not authenticated, send them to the login page
    } else if (disableProtectedRoutes) {
      router.push("/accounts/login");
    }
  }, []);

  return (
    <Route path={path} exact={exact}>
      <AppLayout showNavbar={showNavbar} isProtected={isProtected}>
        {children}
      </AppLayout>
    </Route>
  );
}

export default AppRoute;
