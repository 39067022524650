// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_not-found-page__5Qvzs {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #eee;
  height: 100vh;
  padding: 20%;
}
@media screen and (max-width: 600px) {
  .NotFound_not-found-page__5Qvzs {
    width: 100vw !important;
  }
}
.NotFound_not-found-page__5Qvzs h1 {
  font-size: 4em;
  font-weight: 700;
}
.NotFound_not-found-page__5Qvzs p {
  font-size: 1.6em;
  color: #444;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACJ;AACI;EATJ;IAUQ,uBAAA;EAEN;AACF;AAAI;EACI,cAAA;EACA,gBAAA;AAER;AACI;EACI,gBAAA;EACA,WAAA;AACR","sourcesContent":[".not-found-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    background-color: #eee;\n    height: 100vh;\n    padding: 20%;\n\n    @media screen and (max-width: 600px) {\n        width: 100vw !important;\n    }\n\n    h1 {\n        font-size: 4em;\n        font-weight: 700;\n    }\n\n    p {\n        font-size: 1.6em;\n        color: #444;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found-page": `NotFound_not-found-page__5Qvzs`
};
export default ___CSS_LOADER_EXPORT___;
