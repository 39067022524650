//src/services/apiInstance.ts
import axios from 'axios'

import { BASE_API_ENDPOINT } from './routes'

export const BASE_URL = `${BASE_API_ENDPOINT}api/v1/`

export const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true //allows axios to send cookies to the BE when necessary
})