import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import styles from "../ManageNotifications.module.scss";
import { SubscriptionInput } from "../SubscriptionInput/SubscriptionInput";

export type SubscriptionEventType = "Skill" | "Object";

export type NotificationSubscription<
    T extends SubscriptionEventType = SubscriptionEventType
> = {
    id: number;
    eventType: T;
    name: string;
};

export function Subscriptions<T extends SubscriptionEventType>({
  subscriptions,
  addSubscription,
  deleteSubscription,
  updateSubscription,
  type,
}: {
  subscriptions: NotificationSubscription<T>[];
  addSubscription: (subscription: NotificationSubscription<T>) => void;
  deleteSubscription: (subscription: NotificationSubscription<T>) => void;
  updateSubscription: (subscription: NotificationSubscription<T>) => void;
  type: T;
}) {
  const [addingSubscription, setAddingSubscription] =
      useState<boolean>(false);

  const finishAddingSubscription = (
      value: string,
      addingSubscriptionValue: boolean = false
  ) => {
      setAddingSubscription(addingSubscriptionValue);
      if (value.trim() !== "") {
          addSubscription({
              id: Math.random(),
              eventType: type,
              name: value,
          });
      }
  };

  return (
      <div>
          <h5>Subscriptions to {type.toLowerCase()} updates</h5>
          <p className={styles.subtext}>
              You will be notified when the specified {type.toLowerCase()}s{" "}
              get added to a user or contact from among your connections
          </p>
          {subscriptions.length > 0 || addingSubscription !== null ? (
              <>
                  <ul className={styles.subscriptionsList}>
                      {subscriptions.map((sub) => (
                          <li key={sub.id}>
                              <div>
                                  <SubscriptionInput
                                      initialValue={sub.name}
                                      onBlur={(newValue) =>
                                          updateSubscription({
                                              ...sub,
                                              name: newValue,
                                          })
                                      }
                                      onEnter={(newValue) =>
                                          updateSubscription({
                                              ...sub,
                                              name: newValue,
                                          })
                                      }
                                  />{" "}
                                  <button
                                      onClick={() => deleteSubscription(sub)}
                                  >
                                      <RiDeleteBinLine />
                                  </button>
                              </div>
                          </li>
                      ))}
                      {addingSubscription ? (
                          <li>
                              <SubscriptionInput
                                  autoFocus
                                  onEnter={(inputValue) =>
                                      finishAddingSubscription(
                                          inputValue,
                                          true
                                      )
                                  }
                                  onBlur={(inputValue) =>
                                      finishAddingSubscription(inputValue)
                                  }
                              />
                          </li>
                      ) : null}
                  </ul>
              </>
          ) : null}
          <button
              className={"btn btn-tertiary " + styles.addButton}
              onClick={() => setAddingSubscription(true)}
          >
              <span className={styles.addIcon}>
                  <HiPlus />
              </span>{" "}
              Add {type.toLowerCase()} subscription
          </button>
          <br />
      </div>
  );
}