import React from 'react';

const UserProfilePicture = ({
  profilePicture,
}: {
  profilePicture: string | undefined;
}) => {
  if (profilePicture === undefined) {
    return null; // Return null when profilePicture is undefined
  }

  return (
    <div className="flex items-center justify-center">
      <img className="w-20 h-20 rounded-full" src={profilePicture} alt="" />
    </div>
  );
};

export default UserProfilePicture;
