// LocationEmailAndNumber.tsx

import React from "react";
import styles from "../LocationProfile.module.scss";
import {
  LocationData,
} from "Pages/Home/HomePageTypes";
import EmailAndNumber from "Components/EmailAndNumber/EmailAndNumber";

function LocationEmailAndNumber({
    editing,
    locationData,
    updateLocationDataField,
}: {
    editing: boolean;
    locationData: LocationData;
    updateLocationDataField: (
        field: keyof LocationData
    ) => (
        event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void | null;
}) {
    return editing ? (
        <div className={styles["email-and-number-input"]}>
            <input
                type="text"
                value={locationData.email}
                placeholder="Email (optional)"
                onInput={updateLocationDataField("email")}
            />
            <input
                type="text"
                value={locationData.phoneNumber}
                placeholder="Phone number (optional)"
                onInput={updateLocationDataField("phoneNumber")}
            />
        </div>
    ) : locationData.email || locationData.phoneNumber ? (
        <EmailAndNumber
            email={locationData.email}
            number={locationData.phoneNumber}
            horizontalAlignment="left"
        />
    ) : null;
}

export default LocationEmailAndNumber;
