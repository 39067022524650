import React, { useEffect, useState } from "react";

import styles from "./ImportContacts.module.scss";
import { DispatchAction } from "../reducer";
import { ContactData, OwnedObject, Point, Skill } from "../HomePageTypes";
import LargeLoadingSpinner from "../../../Components/LargeLoadingSpinner/LargeLoadingSpinner";
import LoadedContacts from "./LoadedContacts/LoadedContacts";
import LoadFile from "./LoadFile/LoadFile";
import { DialogBox } from "Components/DialogBox/DialogBox";
import { DialogCloseButton } from "Components/DialogCloseButton/DialogCloseButton";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { apiRoutes } from "services/routes";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


/**
 * The `ContactData` type with the `position` field altered
 * to be nullable, and `id` changed to a string, among other
 * changes. For usage of the `Omit` type, you may check:
 * https://www.typescriptlang.org/docs/handbook/utility-types.html#omittype-keys
 */
export type Contact = Omit<
    ContactData,
    | "id"
    | "coordinates"
    | "ownerId"
    | "ownerName"
    | "objects"
    | "skills"
    | "visibility"
    | "profilePicture"
    | "networks"
    | "journals"
    | "ownerHandle"
    | "keepInTouch"
> & {
    coordinates: Point | null;
    id: string | number;
    skills: Array<string | Skill>;
    objects: Array<string | OwnedObject>;
    profilePicture: string | null | Blob;
};

export type CsvFormat = "Google" | "Outlook" | "MapBond";

export default function ImportContacts({
    state,
    dispatch,
}: {
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    const [loading, setLoading] = useState(true);
    const [loadedContacts, setLoadedContacts] = useState<Contact[] | null>(
        null
    );
    const [areExistingContacts, setAreExistingContacts] = useState(false);
    const apiInstance = useAxiosPrivate();

    const closeMenu = () =>
        dispatch({ type: ACTIONS.importingContacts, value: false });

    useEffect(() => {
        apiInstance
            .get<{ data: Contact[] }>(apiRoutes.UNFINISHED_UPLOADED_CONTACTS)
            .then((value) => {
                if (
                    value.data.data instanceof Array &&
                    value.data.data.length > 0
                ) {
                    setLoadedContacts(value.data.data);
                    setAreExistingContacts(true);
                }
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <DialogBox close={closeMenu}>
            <div className={styles["heading"]}>
                {areExistingContacts ? (
                    <h3>Finish Editing Contacts</h3>
                ) : (
                    <h3>Batch-Import Contacts</h3>
                )}
                <DialogCloseButton
                    className={styles.close}
                    onClick={closeMenu}
                />
            </div>
            {loading ? (
                <div className={styles["loading-container"]}>
                    <LargeLoadingSpinner primaryColor="var(--accent-color)" />
                </div>
            ) : loadedContacts === null || loadedContacts.length === 0 ? (
                <LoadFile
                    setLoading={setLoading}
                    loadedContacts={loadedContacts}
                    setLoadedContacts={setLoadedContacts}
                />
            ) : (
                <LoadedContacts
                    dispatch={dispatch}
                    closeMenu={closeMenu}
                    contacts={loadedContacts}
                    setLoadedContacts={setLoadedContacts}
                    areExistingContacts={areExistingContacts}
                />
            )}
        </DialogBox>
    );
}
