import { EMAIL_REGEX } from "Utils/regex";
import { HTMLInputTypeAttribute } from "react";
import {
    FieldErrors,
    FieldValues,
    Path,
    PathValue,
    UseFormRegister,
} from "react-hook-form/dist/types";
import { TOnboardingPayload } from "types/shared";

export default function TextInput<
    T extends FieldValues,
    P extends {},
    E extends FieldValues
>({
    inputValue,
    onInputChange,
    defaultValue = "",
    inputName,
    register,
    formType = "text",
    errors,
    placeholder,
    inputLabel,
    required = true,
    minLength,
    maxLength,
    regexPattern,
    minLengthErrorMessage,
    maxLengthErrorMessage,
    emptyErrorMessage = "This field is required",
    patternErrorMessage,
}: {
    inputValue?: string;
    onInputChange?: (value: string) => void;
    defaultValue?: string;
    inputName: keyof FieldValues;
    register: any;
    // register: UseFormRegister<T>;
    formType?: HTMLInputTypeAttribute;
    errors: FieldErrors<E>;
    placeholder?: string;
    inputLabel: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    regexPattern?: string;
    maxLengthErrorMessage?: string;
    minLengthErrorMessage?: string;
    emptyErrorMessage?: string;
    patternErrorMessage?: string;
}) {
    return (
        <div className="flex flex-col space-y-3">
            <label htmlFor={inputName}>{inputLabel}</label>
            <input
                className="input-brand"
                defaultValue={`${defaultValue}`}
                {...register(inputName, {
                    required: required,
                    maxLength: maxLength,
                    pattern: regexPattern
                        ? regexPattern
                        : formType === "email"
                        ? EMAIL_REGEX
                        : "",
                    minLength: minLength,
                    
                })}
                type={formType}
                placeholder={placeholder}
                value={inputValue} // Use the inputValue prop as the input value
                onChange={onInputChange ? (e) => onInputChange(e.target.value) : undefined}

            />

            {errors[inputName]?.type === "required" && (
                <FormError errorMessage={emptyErrorMessage} />
            )}
            {errors[inputName]?.type === "pattern" && (
                <FormError errorMessage={patternErrorMessage} />
            )}
            {errors[inputName]?.type === "maxLength" && (
                <FormError errorMessage={maxLengthErrorMessage} />
            )}
            {errors[inputName]?.type === "minLength" && (
                <FormError errorMessage={minLengthErrorMessage} />
            )}
        </div>
    );
}

const FormError = ({ errorMessage }: { errorMessage?: string }) => {
    return <p className="text-red-400 w-full text-sm ">{errorMessage}</p>;
};
