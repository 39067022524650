import React, { useState } from "react";
import { ProfileObjectsList } from "Components/ProfileObjectsList/ProfileObjectsList";

import { ObjectImage, OwnedObject } from "Pages/Home/HomePageTypes";
import { PersonalProfileData } from "../../Settings";
import { ApiError, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export function ObjectsList({
    profileData,
    newObject,
    setNewObject,
    setProfileData,
}: {
    profileData: PersonalProfileData;
    newObject: string | null;
    setNewObject: (profileData: string | null) => void;
    setProfileData: (profileData: PersonalProfileData) => void;
}) {
    let [
        idOfObjectWithImageBeingUploaded,
        setIdOfObjectWithImageBeingUploaded,
    ] = useState<number | null>(null);
    const { postToApi } = usePostToAPI();
    const addObject = async (objectName: string | null) => {
        if (
            objectName !== null &&
            objectName !== "" &&
            profileData !== null &&
            !(profileData instanceof ApiError)
        ) {
            let response = await postToApi<OwnedObject[]>(
                apiRoutes.CREATE_OBJECT,
                { object: objectName }
            );

            if (response.ok) {
                setProfileData({
                    ...profileData,
                    objects: [...profileData.objects, ...response.data],
                });
            }
        }
        setNewObject(null);
    };

    const deleteObject = (object: OwnedObject): void => {
        postToApi(apiRoutes.DELETE_OBJECTS(object.name), {}, "delete");
        if (profileData && !(profileData instanceof ApiError)) {
            setProfileData({
                ...profileData,
                objects: profileData.objects.filter(
                    (value) => value.id !== object.id
                ),
            });
        }
    };

    const addNewObjectImage = async (
        file: File,
        dataUrl: string,
        objectId: number
    ) => {
        setIdOfObjectWithImageBeingUploaded(objectId);

        let response = await postToApi<ObjectImage>(
            apiRoutes.CREATE_USER_IMAGE_OBJECT,
            {
                image: file,
                objectId: objectId.toString(),
            }
        );

        setIdOfObjectWithImageBeingUploaded(null);

        if (response.ok) {
            let picture = response.data;

            if (profileData && !(profileData instanceof ApiError)) {
                setProfileData({
                    ...profileData,
                    objects: profileData.objects.map((o) =>
                        o.id === objectId
                            ? {
                                  ...o,
                                  pictures: [...o.pictures, picture],
                              }
                            : o
                    ),
                });
            }
        }
    };

    const deleteObjectImage = (imageId: number, objectId: number) => {
        postToApi(
            apiRoutes.DELETE_USER_IMAGE_OBJECT(
                apiRoutes.CREATE_USER_IMAGE_OBJECT
            ),
            {},
            "delete"
        );

        if (profileData && !(profileData instanceof ApiError)) {
            setProfileData({
                ...profileData,
                objects: profileData.objects.map((o) =>
                    o.id === objectId
                        ? {
                              ...o,
                              pictures: o.pictures.filter(
                                  (p) => p.id !== imageId
                              ),
                          }
                        : o
                ),
            });
        }
    };

    return (
        <ProfileObjectsList
            objects={profileData.objects}
            objectBeingAdded={newObject}
            setObjectBeingAdded={setNewObject}
            onObjectAdded={addObject}
            deleteObject={deleteObject}
            deleteObjectImage={deleteObjectImage}
            idOfObjectWithImageBeingUploaded={idOfObjectWithImageBeingUploaded}
            uploadObjectImage={addNewObjectImage}
        />
    );
}
