import React from "react";
import { Link, useParams } from "react-router-dom";
import { JournalType } from "../../Pages/Journals/JournalTypes";
import { timeAgo } from "../../Utils/utils";
import './JournalPreview.scss';  // Import the SCSS file

interface CustomProps  {
  journal: JournalType,
  setSelected: React.Dispatch<React.SetStateAction<JournalType | null>>
};

function shortenString(str: string) {
  if (str.length <= 40) {
    return str;
  } else {
    return str.substring(0, 40) + "...";
  }
};

const JournalPreview: React.FC<CustomProps> = ({ journal, setSelected }) => {
  const id = Number(window.location.href.split('/').pop()); // More reliable way to get id
  const isSelected = id === journal.id;

  return (
    <Link to={'/journal/' + journal.id}>
      <div
        className={"medieval-preview rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg transition duration-300"}
        onClick={() => setSelected(journal)}
      >
        <h2 className={"medieval-text text-lg font-medium mb-2" + (id === journal.id ? ' selected-text' : '')}>{shortenString(journal.text)}</h2>
        <p className={"medieval-timestamp text-gray-500 text-sm mb-4"}>{timeAgo(journal.timestamp)}</p>
      </div>
    </Link>
  );
}
  
export default JournalPreview;
