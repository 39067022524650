import React, { useState, useEffect } from 'react';

import { JournalType } from '../JournalTypes';
import CustomEditor from 'Components/TextBox/Editor';

import { useHistory, useParams } from 'react-router-dom';

import Cookies from 'universal-cookie';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';
import CustomButton from "./CustomButton";

const cookies = new Cookies();

type Params = {
	id: string;
};

// Custom Dialog component
const CustomDialog = ({
	open,
	onClose,
	title,
	children,
}: {
	open: boolean;
	onClose: () => void;
	title: string;
	children: React.ReactNode;
}) => {
	return (
		<div
			className={`${open ? 'block' : 'hidden'
				} fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center`}
		>
			<div className="bg-white rounded p-8">
				<div className="text-xl font-medium mb-4">{title}</div>
				{children}
			</div>
		</div>
	);
};

// Custom DialogActions component
const CustomDialogActions = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return <div className="flex justify-end mt-4">{children}</div>;
};

// Custom DialogTitle component
const CustomDialogTitle = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return <div className="text-xl font-medium mb-4">{children}</div>;
};

const JournalOneView = ({
	journals,
	getAll,
	setSelected,
}: {
	journals: JournalType[];
	getAll: () => void;
	setSelected: React.Dispatch<React.SetStateAction<JournalType | null>>;
}) => {
	const [rawJournal, setRawJournal] = useState<any | null>(null);
	const [placeHolder, setPlaceholder] = React.useState('');

	const [saving, setSaving] = React.useState<boolean>(false);

	const [open, setOpen] = React.useState(false);

	const { id } = useParams<Params>();

	const updateJournal = () => {
		console.log('changing the journal');
		const journal = journals.find((j) => j.id === Number(id));
		if (journal) {
			setSelected(journal);
			console.log('changing the journal in editor');
			setRawJournal(journal.journal_as_json);
			setPlaceholder(JSON.stringify(journal.journal_as_json));
		}
	};

	useEffect(() => {
		updateJournal();
	}, [journals, id]);
	const privateAPIInstance = useAxiosPrivate();
	const editJournalEntry = async () => {
		setSaving(true);

		let body = {
			journal_entry: rawJournal,
		};
		let response = await privateAPIInstance.patch(
			apiRoutes.UPDATE_DELETE_JOURNAL(id),
			body
		);
		console.log(await response.data);
		setSaving(false);
		getAll();
	};

	let history = useHistory();

	const deleteJournalEntry = async () => {
		let response = await privateAPIInstance.delete(
			apiRoutes.UPDATE_DELETE_JOURNAL(id)
		);
		handleClose();
		setSelected(null);
		history.push('/journal');
		getAll();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className="">
			{rawJournal && (
				<CustomEditor
					edited={true}
					editData={placeHolder}
					storeData={setRawJournal}
				/>
			)}
			{rawJournal && (
				<div>
					<CustomButton onClick={editJournalEntry} disabled={saving}>
						{saving ? 'Saving' : 'Save'}
					</CustomButton>
					<CustomButton onClick={() => setOpen(true)}>
						Delete
					</CustomButton>
				</div>
			)}

			<CustomDialog open={open} onClose={handleClose} title="Are you sure to delete your journal entry?">
				<CustomDialogActions>
					<CustomButton onClick={handleClose}>No</CustomButton>
					<CustomButton onClick={deleteJournalEntry}>Yes</CustomButton>
				</CustomDialogActions>
			</CustomDialog>
		</div>
	);
};

export default JournalOneView;
