// EditorJournalEntry.tsx
import React, { ReactElement } from 'react';
import { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';

// Define the props interface for EditorJournalEntry
export interface EntryComponentProps {
    className?: string;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
}

// The EditorJournalEntry component
export function EditorJournalEntry(props: EntryComponentProps): ReactElement {
    const {
        mention,
        theme,
        // Other props like searchValue and isFocused can be included if needed
        ...parentProps
    } = props;

    return (
        <div {...parentProps}>
            <div className="flex items-center">
                {mention.type !== 'place' && (
                    <div className="">
                        <img
                            src={
                                mention.avatar
                                    ? mention.avatar
                                    : '/images/default-user-image.svg'
                            }
                            className="w-8 h-8 rounded-full"
                            role="presentation"
                        />
                    </div>
                )}

                <div className="">
                    <div className={theme?.mentionSuggestionsEntryText}>
                        {mention.name}
                    </div>

                    <div className={theme?.mentionSuggestionsEntryTitle}>
                        {mention.type}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditorJournalEntry;
