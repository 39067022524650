import React, { Component } from "react";
import { apiInstance } from "services/apiInstance";
import { apiRoutes } from "services/routes";

interface Connection {
    id: number;
    name: string;
    profile_picture: string | null;
    connection_timestamp: string | null;
}

interface State {
    connections: Connection[];
}

class ConnectionList extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            connections: [],
        };
    }

    async componentDidMount() {
        try {
            const response = await apiInstance.get(apiRoutes.GET_CONNECTIONS);
            const data = response.data;
            if (data.connections && Array.isArray(data.connections)) {
                this.setState({ connections: data.connections });
            } else {
                console.error("Unexpected data format:", data);
            }
        } catch (error) {
            console.error("Error fetching user connections:", error);
        }
    }

    render() {
        const { connections } = this.state;

        return (
            <div>
                <h1>Connection List</h1>
                <ul>
                    {connections.map((connection) => (
                        <li key={connection.id}>
                            {connection.name}{" "}
                            <button
                                onClick={() => console.log("Remove connection")}
                            >
                                Remove Connection
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default ConnectionList;
