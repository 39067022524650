// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InviteOthers_box__UbG96 {
  font-size: 1.2em;
}
.InviteOthers_box__UbG96 h3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.InviteOthers_box__UbG96 .InviteOthers_email-input-container__YwUGE {
  display: flex;
  flex-direction: row;
}
.InviteOthers_box__UbG96 ul,
.InviteOthers_box__UbG96 .InviteOthers_contacts-search__zjrju {
  font-size: 0.8em;
}
.InviteOthers_box__UbG96 .InviteOthers_contacts-search__zjrju {
  width: 100%;
  margin-bottom: 20px;
}
.InviteOthers_box__UbG96 .InviteOthers_invitable-contacts__B66Lb {
  list-style: none;
  padding: 0;
}
.InviteOthers_box__UbG96 .InviteOthers_invitable-contacts__B66Lb li {
  padding: 7px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.InviteOthers_box__UbG96 .InviteOthers_invitable-contacts__B66Lb li:not(.InviteOthers_disabled__L7JRk) {
  cursor: pointer;
}
.InviteOthers_box__UbG96 .InviteOthers_invitable-contacts__B66Lb li:not(.InviteOthers_disabled__L7JRk):hover {
  background-color: rgba(0, 0, 0, 0.0666666667);
}
.InviteOthers_box__UbG96 .InviteOthers_invitable-contacts__B66Lb li:not(.InviteOthers_disabled__L7JRk):active {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.InviteOthers_box__UbG96 .InviteOthers_selected-emails__ZU85G li {
  margin: 3px 0;
}
.InviteOthers_box__UbG96 .InviteOthers_errors__CsLb9 {
  color: red;
}
.InviteOthers_box__UbG96 .InviteOthers_send-button-container__oxna8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.InviteOthers_box__UbG96 .InviteOthers_send-button-container__oxna8 button {
  display: flex;
  flex-direction: row;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/ManageConnections/InviteOthers/InviteOthers.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AAGI;;EAEI,gBAAA;AADR;AAII;EACI,WAAA;EACA,mBAAA;AAFR;AAKI;EACI,gBAAA;EACA,UAAA;AAHR;AAKQ;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAHZ;AAKY;EACI,eAAA;AAHhB;AAMY;EACI,6CAAA;AAJhB;AAOY;EACI,6CAAA;AALhB;AAWQ;EACI,aAAA;AATZ;AAaI;EACI,UAAA;AAXR;AAcI;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;AAZR;AAcQ;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AAZZ","sourcesContent":[".box {\n    font-size: 1.2em;\n\n    h3 {\n        width: 100%;\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        align-items: center;\n    }\n\n    .email-input-container {\n        display: flex;\n        flex-direction: row;\n    }\n\n    ul,\n    .contacts-search {\n        font-size: 0.8em;\n    }\n\n    .contacts-search {\n        width: 100%;\n        margin-bottom: 20px;\n    }\n\n    .invitable-contacts {\n        list-style: none;\n        padding: 0;\n\n        li {\n            padding: 7px 10px;\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n\n            &:not(.disabled) {\n                cursor: pointer;\n            }\n\n            &:not(.disabled):hover {\n                background-color: #0001;\n            }\n\n            &:not(.disabled):active {\n                background-color: #0002;\n            }\n        }\n    }\n\n    .selected-emails {\n        li {\n            margin: 3px 0;\n        }\n    }\n\n    .errors {\n        color: red;\n    }\n\n    .send-button-container {\n        display: flex;\n        flex-direction: row;\n        justify-content: flex-end;\n\n        button {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `InviteOthers_box__UbG96`,
	"email-input-container": `InviteOthers_email-input-container__YwUGE`,
	"contacts-search": `InviteOthers_contacts-search__zjrju`,
	"invitable-contacts": `InviteOthers_invitable-contacts__B66Lb`,
	"disabled": `InviteOthers_disabled__L7JRk`,
	"selected-emails": `InviteOthers_selected-emails__ZU85G`,
	"errors": `InviteOthers_errors__CsLb9`,
	"send-button-container": `InviteOthers_send-button-container__oxna8`
};
export default ___CSS_LOADER_EXPORT___;
