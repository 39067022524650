import { useCallback } from 'react'
import { TSignInForm, TUserData } from 'types/shared'
import { apiRoutes } from 'services/routes'
import { apiInstance } from 'services/apiInstance'
import { useAuth } from '../useAuth'
import { errorHandler } from 'services/utils'
import useSignIn from 'react-auth-kit/hooks/useSignIn'

export const useLogin = () => {
  const { determineOnboardingRedirectURL } = useAuth()
  const signIn = useSignIn()
  const handleLogin = useCallback(async (payload: TSignInForm) => {
    try {
      const res = await apiInstance.post(apiRoutes.LOGIN, payload)
      if (res.status === 200) {
        if (
          signIn({
            auth: {
              token: res.data.access,
              type: 'Bearer'
            },
            refresh: res.data.refresh,
            userState: res.data.user
          })
        ) {
          const userObject = res.data.user as TUserData
          determineOnboardingRedirectURL(userObject)
        } else {
          //Throw error
        }
      }
    } catch (reason) {
      errorHandler(reason)
    }
  }, [])

  return handleLogin
}