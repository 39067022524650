import { DateTime } from "luxon";
import Cookies from "universal-cookie";

const cookies = new Cookies();

/**
 * Formats a number with thousandths seperators as well as a given
 * maximum number of decimal places
 *
 * ---
 * @param n The given number
 * @param decimalPlaces The number of decimal places
 * @returns A well-formatted number
 */
export function formatDecimal(n: number, decimalPlaces = 2): string {
    let result = n.toString();

    let positionOfDecimal = result.indexOf(".");

    let i = result.length - 3;

    if (positionOfDecimal !== -1) {
        result = result.slice(0, positionOfDecimal + decimalPlaces + 1);
        i = positionOfDecimal - 3;
    }

    while (i > 0) {
        result = result.slice(0, i) + "," + result.slice(i);
        i -= 3;
    }

    return result;
}

export function truncateStringIfTooLong(
    str: string,
    maxLength: number = 18,
    ellipsis: string = ". . ."
) {
    return str.length > maxLength ? str.slice(0, maxLength) + ellipsis : str;
}

/**
 * Returns a user-friendly time from an ISO time string
 *
 * ---
 * @param timeString ISO time string
 */
export function getTimeFromIsoString(timeString: string) {
    let dateTime = DateTime.fromISO(timeString);

    if (dateTime.diffNow("hours").hours > -24) {
        return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
    } else if (dateTime.diffNow("days").days > -7) {
        return dateTime.toLocaleString({
            weekday: "short",
            hour: "2-digit",
            minute: "2-digit",
        });
    } else if (dateTime.diffNow().days > -365) {
        return dateTime.toLocaleString({
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
    } else {
        return dateTime.toLocaleString(DateTime.DATETIME_MED);
    }
}

/**
 * Returns a string of the rough duration of time since the
 * given ISO time string
 *
 * ---
 * @param timeString ISO time string
 */
export function getElapsedTimeFromIsoString(timeString: string) {
    let dateTime = DateTime.fromISO(timeString);

    if (dateTime.diffNow("minutes").minutes > -60) {
        return `${Math.floor(Math.abs(dateTime.diffNow("minutes").minutes))}m`;
    } else if (dateTime.diffNow("hours").hours > -24) {
        return `${Math.floor(Math.abs(dateTime.diffNow("hours").hours))}h`;
    } else if (dateTime.diffNow("days").days > -7) {
        return `${Math.floor(Math.abs(dateTime.diffNow("days").days))}d`;
    } else if (dateTime.diffNow("days").days > -365) {
        return `${Math.floor(Math.abs(dateTime.diffNow("days").days / 7))}w`;
    } else {
        return `${Math.floor(Math.abs(dateTime.diffNow("years").years))}y`;
    }
}

/**
 * Used to perform a deep comparison of two objects by value
 *
 * ---
 * @param first First object
 * @param second Second object
 * @returns `true` if both objects have the same values, and `false` otherwise
 */
export function objectsEqual<T extends { [key: string]: any }>(
    first: T,
    second: T
) {
    for (let key of Object.keys(first)) {
        if (typeof first[key] === "object") {
            if (!objectsEqual(first[key], second[key])) {
                return false;
            }
        } else {
            if (first[key] !== second[key]) {
                return false;
            }
        }
    }
    return true;
}

/**
 * Formats a number to a string with 6 decimal places
 *
 * ---
 * @param n Number to convert to 6 d.p.
 * @returns The number n converted to a string with 6 decimal places
 */
export function sixDecimalPlaces(n: number) {
    let nAsStr = n.toString();
    let decimalPosition = nAsStr.indexOf(".");

    return decimalPosition === -1
        ? nAsStr
        : nAsStr.slice(0, decimalPosition + 7);
}

/**
 * Generates random RGB values
 */
export function generateRandomRGBValues(
    rgbRanges: {
        r: { min: number; max: number };
        g: { min: number; max: number };
        b: { min: number; max: number };
    } = {
        r: { min: 80, max: 180 },
        g: { min: 80, max: 180 },
        b: { min: 80, max: 180 },
    }
): [number, number, number] {
    return [
        // Assuming the full color range is from 0 - 255
        Math.floor(
            Math.random() * (rgbRanges.r.max - rgbRanges.r.min) +
                rgbRanges.r.min
        ),
        Math.floor(
            Math.random() * (rgbRanges.g.max - rgbRanges.g.min) +
                rgbRanges.g.min
        ),
        Math.floor(
            Math.random() * (rgbRanges.b.max - rgbRanges.b.min) +
                rgbRanges.b.min
        ),
    ];
}

/**
 * Tests whether an object contains a certain property in such a
 * way that satisifies and informs the Typescript compiler. See
 * [https://fettblog.eu/typescript-hasownproperty/](https://fettblog.eu/typescript-hasownproperty/)
 *
 * ---
 * @param obj The objeect to be tested
 * @param prop The key of the property
 * @returns Whether the object includes the key
 */
export function hasOwnProperty<
    T extends {},
    Key extends string | number | symbol,
    Value
>(obj: T, prop: Key): obj is T & Record<Key, Value> {
    return obj.hasOwnProperty(prop);
}

/**
 * Convert a datetime string to a human-readable time ago string.
 * @param datetime - The datetime string to convert.
 * @returns A string in the format "X [unit] ago", where X is the number of units and [unit] is one of "year", "month", "day", "hour", or "min".
 */
export function timeAgo(datetime: string): string {
    const date = new Date(datetime);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 60) {
        return "1 min ago";
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + " mins ago";
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return hours + " hours ago";
    } else if (seconds < 2592000) {
        const days = Math.floor(seconds / 86400);
        return days + " days ago";
    } else if (seconds < 31536000) {
        const months = Math.floor(seconds / 2592000);
        return months + " months ago";
    } else {
        const years = Math.floor(seconds / 31536000);
        const months = Math.floor((seconds - years * 31536000) / 2592000);
        return (
            years +
            " year" +
            (years > 1 ? "s" : "") +
            " " +
            months +
            " month" +
            (months > 1 ? "s" : "") +
            " ago"
        );
    }
}
