import React, { useState, useEffect,CSSProperties } from "react";

import styles from "./OptionsButton.module.scss";

/**
 * A button used to open a menu
 */
export default function OptionsButton({
  onClick,
  backgroundColor,
  hoverBackgroundColor,
  activeBackgroundColor,
  dotsColor,
  orientation,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backgroundColor?: string;
  /** Background color on hover */
  hoverBackgroundColor?: string;
  /** Background color when active */
  activeBackgroundColor?: string;
  /** The color of the dots within the button */
  dotsColor?: string;
  orientation?: "vertical" | "horizontal";
}) {

  const [showTooltip, setShowTooltip] = useState(true);
  const [tooltipMessage, setTooltipMessage] = useState("");

  useEffect(() => {
    // Check if the user has visited before
    const hasVisitedCount = localStorage.getItem("hasVisitedCount");
    const visitCount = hasVisitedCount ? parseInt(hasVisitedCount, 10) : 0;

    if (visitCount === 1) {
      setTooltipMessage("Hello there, curious explorer! Click/Tap the 3 dots for more secrets.");
    } else if (visitCount === 2) {
      setTooltipMessage("Welcome back! Don't forget to click/tap the 3 dots to uncover hidden treasures.");
    } else if (visitCount === 3) {
      setTooltipMessage("You're a regular adventurer now! Keep clicking/tapping those 3 dots for surprises.");
    } else if (visitCount >= 4 && visitCount <= 5) {
      setTooltipMessage("Surprise! This is the last time you'll see me. Enjoy the outside world, adventurer!");
    } else {
      setShowTooltip(false);
    }

    if (visitCount <= 5) {
      // It's the user's first 5 visits, show the tooltip
      setShowTooltip(true);

      // Update the visit count in LocalStorage
      localStorage.setItem("hasVisitedCount", (visitCount + 1).toString());
    } else {
      // After the first 5 visits, hide the tooltip
      setShowTooltip(false);
    }
  }, []);
  return (
    <div className={styles['options-button-container']}>

    <button
      className={styles["options-button"]}
      onClick={onClick}
      style={
        {
          "--bg-color": backgroundColor || "#ffffff",
          "--hover-bg-color": hoverBackgroundColor || "#f50000",
          "--active-bg-color": activeBackgroundColor || "#ffffff",
          color: dotsColor || "var(--accent-color)",
          flexDirection: orientation === "vertical" ? "column" : "row",
        } as CSSProperties
      }
    >
          {showTooltip && 
       <div className={styles["tooltip"]}>{tooltipMessage}</div>
      }
      <span></span>
      <span></span>
      <span></span>
    </button>
</div>
  );
}
