// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQSection_animate-rotate__ZE261 {
  transition: transform 0.4s ease-in-out;
}

.FAQSection_animate-rotate__ZE261:hover {
  transform: rotate(5deg);
}

.FAQSection_animate-fade-in__daCFG {
  animation: FAQSection_fadeIn__XB6uk 0.5s ease-out;
}

@keyframes FAQSection_fadeIn__XB6uk {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
dt:hover {
  transform: scale(1.1);
}

.FAQSection_animate-fade-out__93iaK {
  animation: FAQSection_fadeOut__gHh0W 0.5s ease-out forwards;
}

@keyframes FAQSection_fadeOut__gHh0W {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LandingPageComponents/FAQSection.module.scss"],"names":[],"mappings":"AAAA;EACA,sCAAA;AACA;;AAEA;EACA,uBAAA;AACA;;AAEA;EACA,iDAAA;AACA;;AAEA;EACA;IACA,UAAA;IACA,2BAAA;EACE;EACF;IACA,UAAA;IACA,wBAAA;EACE;AACF;AAGA;EACE,qBAAA;AADF;;AAIA;EACE,2DAAA;AADF;;AAIA;EACE;IACE,UAAA;IACA,wBAAA;EADF;EAGA;IACE,UAAA;IACA,4BAAA;EADF;AACF","sourcesContent":[".animate-rotate {\ntransition: transform 0.4s ease-in-out;\n}\n\n.animate-rotate:hover {\ntransform: rotate(5deg);\n}\n\n.animate-fade-in {\nanimation: fadeIn 0.5s ease-out;\n}\n\n@keyframes fadeIn {\n0% {\nopacity: 0;\ntransform: translateY(10px);\n}\n100% {\nopacity: 1;\ntransform: translateY(0);\n}\n}\n\n\ndt:hover {\n  transform: scale(1.1);\n}\n\n.animate-fade-out {\n  animation: fadeOut 0.5s ease-out forwards;\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  to {\n    opacity: 0;\n    transform: translateY(-10px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animate-rotate": `FAQSection_animate-rotate__ZE261`,
	"animate-fade-in": `FAQSection_animate-fade-in__daCFG`,
	"fadeIn": `FAQSection_fadeIn__XB6uk`,
	"animate-fade-out": `FAQSection_animate-fade-out__93iaK`,
	"fadeOut": `FAQSection_fadeOut__gHh0W`
};
export default ___CSS_LOADER_EXPORT___;
