// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandableButton_expandable-button__evggq {
  box-shadow: var(--shadow);
  border-radius: 25px;
}
.ExpandableButton_expandable-button__evggq .ExpandableButton_button__VgTEe {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 0;
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ExpandableButton_expandable-button__evggq.ExpandableButton_expanded__2IYhl {
  background-color: white;
  padding: 10px;
  border-radius: 10px 15px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FloatingControls/ExpandableButton/ExpandableButton.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAII;EACI,uBAAA;EACA,aAAA;EACA,wBAAA;AAFR","sourcesContent":[".expandable-button {\n    box-shadow: var(--shadow);\n    border-radius: 25px;\n\n    .button {\n        width: 50px;\n        height: 50px;\n        border-radius: 25px;\n        padding: 0;\n        border: none;\n        background-color: white;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n    .content {}\n\n    &.expanded {\n        background-color: white;\n        padding: 10px;\n        border-radius: 10px 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable-button": `ExpandableButton_expandable-button__evggq`,
	"button": `ExpandableButton_button__VgTEe`,
	"expanded": `ExpandableButton_expanded__2IYhl`
};
export default ___CSS_LOADER_EXPORT___;
