import React from 'react';
import styles from './AddTravelPlan.module.scss';
import { BasicTravelData } from "../HomePageTypes";

export function TextDetails({
    travelPlan,
    setTravelPlan,
}: {
    travelPlan: BasicTravelData;
    setTravelPlan: React.Dispatch<React.SetStateAction<BasicTravelData>>;
}) {
  console.log("[TextDetails] Called, travelPlan:",travelPlan);
  const updateTextField =
  (field: keyof BasicTravelData) =>
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setTravelPlan({
          ...travelPlan,
          [field]: event.currentTarget.value,
      });

return (
  <div className={styles["text-details"]}>
      <input
          type="text"
          placeholder="Name"
          value={travelPlan.name}
          onInput={updateTextField("name")}
      />
      <textarea
          name="requests"
          placeholder="Requests"
          rows={2}
          value={travelPlan.requests}
          onInput={updateTextField("requests")}
      />
      <textarea
          name="description"
          placeholder="Description"
          rows={2}
          value={travelPlan.description}
          onInput={updateTextField("description")}
      />
      <textarea
          name="notes"
          placeholder="Notes"
          rows={2}
          value={travelPlan.notes}
          onInput={updateTextField("notes")}
      />
  </div>
);}

// You might need to export additional types or functions used by TextDetails
