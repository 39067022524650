import React, { useState, useRef, useEffect } from "react";
import {useDocumentTitle } from "hooks/useSEO";

import styles from "./NetworkPage.module.scss";
import { VisibilityWithPublic } from "../Home/visibility";
import AddNetwork  from "Components/NetworkGroups/AddNetwork";

import MyIconButton from "./MyIconButton";  // Update the path based on where MyIconButton is located
import MyMenu from "./MyMenu";  // Update the path based on where MyMenu is located
import MyMenuItem from "./MyMenuItem";  // Update the path based on where MyMenuItem is located

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical,faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import ContextMenuLoadingSpinner from "Components/ContextMenu/ContextMenuLoadingSpinner/ContextMenuLoadingSpinner";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";
import { apiRoutes } from "services/routes";


export default function NetworkPage({

}
) {
    useDocumentTitle("Settings");

    const [networks, setNetworks] = useState<MinimalNetworkData[]>([]); // Initialize as an empty array
    const [loading, setLoading] = useState<boolean>(false);
    const [addModal, setAddModal] = useState<boolean>(false);
    const uniquebuttonRef = useRef<HTMLButtonElement | null>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const apiInstance = useAxiosPrivate()

    const getAll = () => {
        setLoading(true);
        let apiURL: string = apiRoutes.GET_ALL_NETWORKS;
        apiInstance.get(apiURL)
            .then(async (response) => {
                let res = await response.data

                response.status >= 300
                    ? console.log(response)
                    : setNetworks(res?.data);
            })
            .catch((reason) => {
                console.log(reason)
                //   setData(new ApiError(reason, 0));
                //   setFetchCount((count) => count + 1);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        getAll();
    }, [])

    useEffect(() => {
        if (uniquebuttonRef.current) {
            uniquebuttonRef.current.focus();
        }
    }, []);
    
    
    useEffect(() => {
        const interval = setInterval(() => {
            console.log("document.activeElement", document.activeElement);
        }, 1000);
    
        return () => clearInterval(interval);
    }, []);
    
      

    const options = [
        'View',
    ];

    let history = useHistory();

    return (
        <main className={styles["networks"]}>

            <h1 className="text-4xl m-4">Networks</h1>
            <div className={styles["networks-list"]}>
            <div className="flex m-2 mx-auto items-center justify-center">
                    <h3 className="text-lg"></h3>
                    <button 
                        onClick={() => setAddModal(true)} 
                        autoFocus={true}
                        ref={uniquebuttonRef}
                        className={`p-2 bg-green-300 outline-none rounded capitalize hover:bg-green-400 ${styles.createNetworkButton}`}>
                        Create new Network
                    </button>
                </div>
                <div className="mt-2">
                    {loading &&
                        <div className="flex justify-center">
                            <ContextMenuLoadingSpinner />
                        </div>
                    }
                    {(Array.isArray(networks) && networks.length !== 0) && networks.map((n) => (
                        <Link to={'/network/' + n.id} style={{ textDecoration: 'none' }}>


                        <div   className={`${styles.networkItem}`} 
>
                            <Link to={'/network/' + n.id} >
                                {n.network_logo ? <img src={n.network_logo} alt="networkLogo" className="w-10 h-10 rounded-full" />
                                    : <FontAwesomeIcon fontSize={30} icon={faUserGroup} />
                                }
                            </Link>
                            <Link to={'/network/' + n.id} >
                                <div className="ml-4">
                                    <p className="text-gray-700 font-medium">{n.name}</p>
                                </div>
                            </Link>

                            <div className="ml-auto">
                            <MyIconButton
                                id="long-button"  // add the id here
                                ariaLabel="more"
                                ariaControls={open ? 'long-menu' : undefined}
                                ariaExpanded={open ? true : undefined}
                                ariaHaspopup={true}
                                onClick={handleClick}
                                >
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                </MyIconButton>
                                <MyMenu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {options.map((option) => (
                                    <MyMenuItem key={option} onClick={() => history.push(`/network/${n.id}`)}>
                                        {option}
                                    </MyMenuItem>
                                ))}
                            </MyMenu>
                            </div>


                        </div>
                        </Link>
                    ))}
                    {(networks.length === 0 && !loading) &&
                        <div className="flex justify-center items-center">
                            <h3>You are not a member of any network. Click on create new network to start.</h3>
                        </div>
                    }
                </div>
                {(addModal) &&
                    <AddNetwork createModalOpen={setAddModal} fetchCallback={getAll} />
                }
            </div>

        </main>
    );
};

export type MinimalNetworkData = {
    id: Number,
    name: string,
    owner: Number,
    description: string,
    network_logo: string | null,
    visibility: VisibilityWithPublic
};
