// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageConnections_user__sEiOf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}
.ManageConnections_user__sEiOf:last-child {
  border-bottom: none;
}
.ManageConnections_user__sEiOf .ManageConnections_user-link__aQZBB {
  display: flex;
  flex-direction: row;
  color: inherit;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.ManageConnections_user__sEiOf .ManageConnections_user-link__aQZBB .ManageConnections_name__q947f {
  margin-left: 20px;
}

.ManageConnections_dialog-buttons__jCQRE {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/ManageConnections/ManageConnections.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gCAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AAAR;AAEQ;EACI,iBAAA;AAAZ;;AAKA;EACI,YAAA;AAFJ","sourcesContent":[".user {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 0;\n    border-bottom: 1px solid #e8e8e8;\n\n    &:last-child {\n        border-bottom: none;\n    }\n\n    .user-link {\n        display: flex;\n        flex-direction: row;\n        color: inherit;\n        align-items: center;\n        width: fit-content;\n\n        .name {\n            margin-left: 20px;\n        }\n    }\n}\n\n.dialog-buttons {\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `ManageConnections_user__sEiOf`,
	"user-link": `ManageConnections_user-link__aQZBB`,
	"name": `ManageConnections_name__q947f`,
	"dialog-buttons": `ManageConnections_dialog-buttons__jCQRE`
};
export default ___CSS_LOADER_EXPORT___;
