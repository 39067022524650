import React, { CSSProperties } from "react";

import ContextMenu from "Components/ContextMenu/ContextMenu";
import ContextMenuError from "Components/ContextMenu/ContextMenuError/ContextMenuError";
import ContextMenuLoadingSpinner from "Components/ContextMenu/ContextMenuLoadingSpinner/ContextMenuLoadingSpinner";
import { ContactData, MinimalContactData } from "../HomePageTypes";
import { DispatchAction } from "../reducer";
import { ACTIONS } from "Pages/Home/reducerActions";


import { isNotVisible, isOwnedBySelf } from "../visibility";
import handleContactConsentRequest from "./ContactMarkerPopup/handleContactConsentRequest";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { JournalTemplate } from "Components/TextBox/Editor";
import { apiRoutes } from "services/routes";

export type ContactContextMenuData = {
    type: "contact";
    contact: MinimalContactData;
    style: CSSProperties;
};

export function ContactContextMenu({
    contextMenuData,
    editingContact,
    dispatch,
}: {
    contextMenuData: ContactContextMenuData;
    editingContact: boolean;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullContact, setFullContact] = useApi<ContactData>(
        `${apiRoutes.UPDATE_OR_DELETE_OR_GET_CONTACT(
            contextMenuData.contact.id
        )}`
    );

    return (
        <ContextMenu style={contextMenuData.style}>
            {fullContact === null ? (
                <ContextMenuLoadingSpinner />
            ) : fullContact instanceof ApiError ? (
                <ContextMenuError error={fullContact} />
            ) : (
                <MenuButtons
                    contact={fullContact}
                    setContact={setFullContact}
                    editingContact={editingContact}
                    dispatch={dispatch}
                />
            )}
        </ContextMenu>
    );
}

function MenuButtons({
    contact,
    setContact,
    editingContact,
    dispatch,
}: {
    contact: ContactData;
    setContact: React.Dispatch<
        React.SetStateAction<ContactData | ApiError | null>
    >;
    editingContact: boolean;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let { postToApi } = usePostToAPI();

    let updateContact = (newContact: ContactData | MinimalContactData) => {
        setContact({ ...contact, ...newContact });
        dispatch({ type: ACTIONS.UPDATE_CONTACT, contact: newContact });
    };

    const deleteContact = () => {
        postToApi(
            apiRoutes.UPDATE_OR_DELETE_OR_GET_CONTACT(contact.id),
            undefined,
            "delete"
        );
        dispatch({ type: ACTIONS.DELETE_CONTACT, contact });
    };

    const editContact = () =>
        dispatch({ type: ACTIONS.START_EDITING_CONTACT, contact });

    const addObject = () =>
        dispatch({
            type: ACTIONS.START_ADDING_OBJECT,
            receiver: { type: "contact", for: contact },
        });

    const addSkill = () =>
        dispatch({
            type: ACTIONS.START_ADDING_SKILL,
            receiver: { type: "contact", for: contact },
        });

    const openKeepInTouch = () =>
        dispatch({
            type: ACTIONS.ADD_KEEP_IN_TOUCH,
            receiver: { type: "contact", for: contact },
        });
    const addJournalEntry = () => {
        let obj: JournalTemplate = {
            id: contact.id,
            name: contact.name || "",
            avatar: contact.profilePicture || "",
            type: "contact",
        };
        dispatch({ type: ACTIONS.addingJournalEntry, value: obj });
    };
    return isNotVisible(contact.visibility) ? (
        <button
            onClick={() =>
                handleContactConsentRequest(updateContact, contact, postToApi)
            }
        >
            {contact.visibility === "needs request"
                ? "Request access"
                : contact.visibility === "requested"
                ? "Cancel request"
                : "Loading visibility"}
        </button>
    ) : (
        <>
            {isOwnedBySelf(contact.visibility) ? (
                <>
                    {editingContact ? null : (
                        <button onClick={editContact}>
                            Edit contact details
                        </button>
                    )}
                    <button onClick={deleteContact}>Delete contact</button>
                </>
            ) : null}
            <button onClick={openKeepInTouch}>Keep in touch</button>
            <button onClick={addSkill}>Add skill</button>
            <button onClick={addObject}>Add owned object</button>
            <button onClick={addJournalEntry}>Add a journal entry</button>
        </>
    );
}
