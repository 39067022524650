import React from 'react';

import HttpErrorMessage from 'Components/HttpErrorMessage/HttpErrorMessage';
import { ApiError } from 'hooks/useAPI';

export default function PopupLoadingError({ error }: { error?: ApiError }) {
	return (
		<div
			style={{
				minWidth: '240px',
				padding: '10px 20px',
				fontFamily: 'var(--website-font)',
				fontSize: 'large',
			}}
		>
			<HttpErrorMessage
				direction="column"
				error={error}
				hideBorderAndBackground
			/>
		</div>
	);
}
