import L from 'leaflet';

import { MinimalUserData } from 'Pages/Home/HomePageTypes';
import { ApiError } from 'hooks/useAPI';

export type Position = {
	x: number;
	y: number;
	zoomLevel: number;
};
export type ContactUs = {
	name: string;
	email: string;
	message: string;
};
export type PublicActivityStream = {
	action_taken_by: string;
	profile_image: string;
	action_object: string;
	action_target?: string;
	type: string;
};

export type LandingPageState = {
	initialMapPosition: Position;
	userProfiles: ApiError | Array<MinimalUserData> | null;
	mapBounds: L.LatLngBounds | null;
	contactUsForm: ContactUs;
	formStatus: string;
	publicActivityStream: Array<PublicActivityStream> | null;
};

export function getInitialState(): LandingPageState {
	return {
		initialMapPosition: { x: -31, y: -69, zoomLevel: 8 },
		userProfiles: [],
		mapBounds: null,
		contactUsForm: {
			name: '',
			email: '',
			message: '',
		},
		formStatus: '',
		publicActivityStream: [],
	};
}

export type DispatchAction =
	| { type: 'initialPosition'; value: Position }
	| { type: 'userProfiles'; value: Array<MinimalUserData> | ApiError | null }
	| { type: 'mapBounds'; value: L.LatLngBounds | null }
	| { type: 'UPDATE_CONTACT_NAME'; value: string }
	| { type: 'UPDATE_CONTACT_EMAIL'; value: string }
	| { type: 'UPDATE_CONTACT_MESSAGE'; value: string }
	| { type: 'UPDATE_FORM_STATUS'; value: string }
	| { type: 'publicActivityStream'; value: Array<PublicActivityStream> | null };

export function reducer(
	state: LandingPageState,
	action: DispatchAction
): LandingPageState {
	switch (action.type) {
		case 'initialPosition':
			return {
				...state,
				initialMapPosition: action.value,
			};
		case 'mapBounds':
			return {
				...state,
				mapBounds: action.value,
			};
		case 'userProfiles':
			return {
				...state,
				userProfiles: action.value,
			};
		case 'publicActivityStream':
			console.log('added to state', action.value);
			return {
				...state,
				publicActivityStream: action.value,
			};
		case 'UPDATE_CONTACT_NAME':
			return {
				...state,
				contactUsForm: {
					...state.contactUsForm,
					name: action.value,
				},
			};
		case 'UPDATE_CONTACT_EMAIL':
			return {
				...state,
				contactUsForm: {
					...state.contactUsForm,
					email: action.value,
				},
			};
		case 'UPDATE_CONTACT_MESSAGE':
			return {
				...state,
				contactUsForm: {
					...state.contactUsForm,
					message: action.value,
				},
			};
		case 'UPDATE_FORM_STATUS':
			return {
				...state,
				formStatus: action.value,
			};
		default:
			return state;
	}
}
