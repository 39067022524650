import React, { useState } from 'react';
import styles from './MuiReplaceCustomButton.module.scss'; // Update this path if your file structure is different

interface CustomButtonProps {
  onClick: () => void;
  children: string;
  color?: 'red' | 'blue' | 'green';
  requireDoubleCheck?: boolean;
  doubleCheckMessage?: string;
  autoFocus?: boolean;
}

const MuiReplaceCustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  color = 'blue',
  requireDoubleCheck = false,
  doubleCheckMessage = 'Are you sure?',
  autoFocus = false,
}) => {
  const [confirmState, setConfirmState] = useState(false);

  const handleClick = () => {
    if (requireDoubleCheck) {
      if (confirmState) {
        onClick();
        setConfirmState(false); // Reset the state
      } else {
        setConfirmState(true);
      }
    } else {
      onClick();
    }
  };

  return (
    <button
      className={`${styles['mui-custom-button']} ${styles[color]}`}
      onClick={handleClick}
      autoFocus={autoFocus}
    >
      {requireDoubleCheck && confirmState ? doubleCheckMessage : children}
    </button>
  );
};

export default MuiReplaceCustomButton;