import React from "react";

import styles from "./UserMarkerPopup.module.scss";
import { MinimalUserData, UserData, ContactData } from "../../HomePageTypes";
import { DispatchAction } from "../../reducer";
import PopupLoadingSpinner from "../../PopupLoadingSpinner/PopupLoadingSpinner";

import { HomePageState } from "Pages/Home/HomePageState";


import PopupLoadingError from "../../PopupLoadingError/PopupLoadingError";
import { FullUserPopupContent } from "./FullUserPopupContent";
import { ApiError, useApi } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export function UserMarkerPopup({
    user,
    state,
    dispatch,
}: {
    user: MinimalUserData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullUser, setFullUser] = useApi<UserData>(
        apiRoutes.GET_USER(user.handle)
    );

    return (
        <div
            className={`${styles["user-popup-content"]}`}
        >
            {fullUser === null ? (
                <PopupLoadingSpinner />
            ) : fullUser instanceof ApiError ? (
                <PopupLoadingError error={fullUser} />
            ) : (
                <FullUserPopupContent
                    dispatch={dispatch}
                    state={state}
                    user={fullUser as UserData} // Type assertion here
                    setUser={setFullUser as React.Dispatch<React.SetStateAction<UserData | ContactData | ApiError | null>>} // Type assertion here
                />
            )}
        </div>
    );
}
