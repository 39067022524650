import React from "react";

import styles from "./EditingConfirmationButtons.module.scss";
import { AiOutlineCheck } from 'react-icons/ai';
import { CloseIcon } from "../../../../Icons/CloseIcon";

/**
 * Buttons used to confirm the adding of a new skill or object.
 * @param props
 * @returns
 * @see https://reactjs.org/docs/components-and-props.html
 * @see https://reactjs.org/docs/hooks-intro.html
 * @see https://reactjs.org/docs/hooks-state.html
 * @see https://reactjs.org/docs/hooks-effect.html
 * @see https://reactjs.org/docs/hooks-reference.html
 * @see https://reactjs.org/docs/hooks-custom.html
 * @see https://reactjs.org/docs/hooks-rules.html
 * @see https://reactjs.org/docs/hooks-faq.html
 * @see https://reactjs.org/docs/hooks-reference.html#usestate
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 */
export function EditingConfirmationButtons({
  cancel,
  accept,
}: {
  accept: () => void;
  cancel: () => void;
}) {
  return (
    <div className={styles["add-element-buttons"]}>
      <button className={styles["close-button"]} onClick={cancel}>
        <CloseIcon lineLength={18} lineWidth={3} />
      </button>
      <button className={styles["add-button"]} onClick={accept}>
        <AiOutlineCheck />
      </button>
    </div>
  );
}
