import React, { useCallback, useMemo, useRef, useState } from 'react';
import { LatLngExpression } from 'leaflet';
import { Polyline, Popup } from 'react-leaflet';
import 'leaflet-arrowheads';
import TextPathOptions from '../../../react-leaflet-textpath';

import { MinimalTravelPlanData } from '../HomePageTypes';
import { DispatchAction } from '../reducer';

import { HomePageState } from "Pages/Home/HomePageState";


import { generateRandomRGBValues } from '../../../Utils/utils';
import TravelPlanPopup from './TravelPlanPopup/TravelPlanPopup';
import L from 'leaflet';
import { getRhumbLineBearing } from 'geolib';

export default function TravelPlan({
	dispatch,
	travelPlan,
	state,
}: {
	travelPlan: MinimalTravelPlanData;
	state: HomePageState;
	dispatch: React.Dispatch<DispatchAction>;
}) {
	let [popupIsOpen, setPopupIsOpen] = useState(false);

	let [color1, color2] = useColors();

	let { polylineRefCallback } = usePolylineRef(color2);
	// Define a line using travel plan origin and destination coordinates
	const line: LatLngExpression[][] = [
		[
			[travelPlan.origin.lat, travelPlan.origin.lon],
			[
				travelPlan.destination.lat,
				travelPlan.destination.lon,
			],
		],
	];

	interface Point {
		latitude: number;
		longitude: number;
	}

	// Define a function to calculate bearing using start and end points
	function getBearing(start: Point, end: Point): number {
		return getRhumbLineBearing(start, end);
	}
	// Map LatLngExpression array from line array, where each subArray contains coordinates of origin and destination
	const latLngs = line.map((subArray) =>
		subArray.map((coords) => {
			if (Array.isArray(coords)) {
				return L.latLng(coords[0], coords[1]);
			} else if (typeof coords === 'object' && coords !== null) {
				return L.latLng(coords.lat, coords.lng);
			} else {
				throw new Error(`Invalid coords: ${coords}`);
			}
		})
	);

	const start: Point = {
		latitude: latLngs[0][0].lat,
		longitude: latLngs[0][0].lng,
	};

	const end: Point = {
		latitude: latLngs[0][1].lat,
		longitude: latLngs[0][1].lng,
	};

	const bearing = getBearing(start, end);

	const textOrientation = bearing > 90 && bearing < 270 ? 180 : 0;
	// console.log(bearing, textOrientation, travelPlan.name);

	return (
		<>
			<TextPathOptions
				positions={line[0]}
				text={travelPlan.name}
				orientation={textOrientation}
				center
				attributes={{
					// 'font-size': 20,
					'font-weight': 'bold',
					fill: 'black',
					filter: 'drop-shadow(5px 5px 5px white)',
					'stroke-width': `${5}`,
					'stroke-opacity': `${0.5}`,
					weight: `${10}`,
					color: 'white',
				}}
			/>
			<Polyline
				ref={polylineRefCallback}
				positions={line}
				weight={5}
				color={color1}
				opacity={0.35}
				eventHandlers={{
					popupopen: () => setPopupIsOpen(true),
					popupclose: () => setPopupIsOpen(false),
				}}
			>
				<Popup autoPan={false} keepInView={false}>
					{popupIsOpen ? (
						<TravelPlanPopup
							travelPlan={travelPlan}
							state={state}
							dispatch={dispatch}
						/>
					) : null}
				</Popup>
			</Polyline>
		</>
	);
}

function usePolylineRef(color: string) {
	let polylineRef = useRef<L.Polyline | null>(null);
	let polylineRefCallback = useCallback(
		(ref: L.Polyline | null) => {
			if (ref) {
				ref.arrowheads({
					frequency: '40px',
					size: '10px',
					yawn: 40,
					fill: true,
					stroke: false,
					color: color + '99',
				});
			}
			polylineRef.current = ref;
		},
		[color]
	);

	return { polylineRefCallback, polylineRef };
}

function useColors(): [string, string] {
	return useMemo(() => {
		let numbers1 = generateRandomRGBValues();
		let numbers2 = numbers1.map((n) => n - 40); // The hue of the second color has to be the same as the first color, but darker

		return [
			// Convert the numbers to hex and concatenate them as one string
			numbers1.reduce((prev, curr) => prev + curr.toString(16), '#'),
			numbers2.reduce((prev, curr) => prev + curr.toString(16), '#'),
		];
	}, []);
}
