import React, { useState } from 'react';
import { DispatchAction } from '../reducer';
import { apiRoutes } from "services/routes";
import { usePostToAPI } from "hooks/useAPI";
import { BasicTravelData, TravelPlanErrors, TravelPlanData } from "../HomePageTypes";
import { ACTIONS } from '../reducerActions';


interface TravelPlanManagerProps {
  initialValue?: TravelPlanData;
  isExistingTravelPlan: boolean;
  dispatch: React.Dispatch<DispatchAction>;
  closeMenu: () => void;
  originSearchInput: string;
  destinationSearchInput: string;
  children: (
      travelPlan: BasicTravelData, 
      setTravelPlan: React.Dispatch<React.SetStateAction<BasicTravelData>>,
      submitTravelPlan: () => Promise<void>
  ) => JSX.Element;
}


const TravelPlanManager: React.FC<TravelPlanManagerProps> = ({
  initialValue, 
  isExistingTravelPlan, 
  dispatch, 
  closeMenu, 
  originSearchInput,
  destinationSearchInput,
  children
}) => {  
    console.log("calling TravelPlanManager");

    const [travelPlan, setTravelPlan] = useState<BasicTravelData>({
        id: initialValue?.id || null,
        arrivalDate: initialValue?.arrivalDate ? parseDate(initialValue.arrivalDate) : null,
        departureDate: initialValue?.departureDate ? parseDate(initialValue.departureDate) : null,
        description: initialValue?.description || "",
        origin: initialValue?.origin,
        destination: initialValue?.destination,
        name: initialValue?.name || "",
        notes: initialValue?.notes || "",
        objectsTaken: initialValue?.objectsTaken || [],
        requests: initialValue?.requests || "",
        privacyLevel: initialValue?.privacyLevel || "Private",
    });
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState<TravelPlanErrors>({origin: "", destination: "", arrival: "", departure: ""});
    const { postToApi } = usePostToAPI();


    console.log("calling submitTravelPlan");
    const submitTravelPlan = async () => {
        console.log("submitTravelPlan: Start");
        setIsUploading(true);
    
        console.log("submitTravelPlan: Preparing data for submission");
        const requestData = {
            name: travelPlan.name,
            description: travelPlan.description,
            requests: travelPlan.requests,
            notes: travelPlan.notes,
            arrival: dateAsString(travelPlan.arrivalDate as Date),
            departure: dateAsString(travelPlan.departureDate as Date),
            origin: travelPlan.origin
                ? JSON.stringify(travelPlan.origin)
                : originSearchInput
                ? JSON.stringify(originSearchInput)
                : JSON.stringify(initialValue?.origin),
            destination: travelPlan.destination
                ? JSON.stringify(travelPlan.destination)
                : destinationSearchInput
                ? JSON.stringify(destinationSearchInput)
                : JSON.stringify(initialValue?.destination),
            objectsTaken: JSON.stringify(travelPlan.objectsTaken),
            privacyLevel: travelPlan.privacyLevel,
        };
        console.log("submitTravelPlan: Data to be submitted", requestData);
    
        try {
            const apiEndpoint = isExistingTravelPlan
                ? `${apiRoutes.UPDATE_OR_DELETE_TRAVEL_PLAN(travelPlan.id)}`
                : `${apiRoutes.CREATE_TRAVEL_PLANS}`;
            console.log("submitTravelPlan: API Endpoint", apiEndpoint);
    
            const response = await postToApi<TravelPlanData, TravelPlanErrors>(
                apiEndpoint,
                requestData,
                isExistingTravelPlan ? "patch" : "post"
            );
    
            console.log("submitTravelPlan: API Response", response);
            if (response.ok) {
                console.log("submitTravelPlan: Submission successful");
                dispatch({
                    type: isExistingTravelPlan
                        ? ACTIONS.UPDATE_TRAVEL_PLAN
                        : ACTIONS.ADD_TRAVEL_PLAN,
                    travelPlan: response.data,
                });
                closeMenu();
            } else {
                console.log("submitTravelPlan: Submission failed with errors", response.message);
                setErrors(response.message);
                setIsUploading(false);
            }
        } catch (error) {
            console.error("submitTravelPlan: Error during submission", error);
            setIsUploading(false);
            // Handle additional error states or inform the user as needed
        }
    };
    
    // Utility functions (parseDate, etc.) can be defined here or imported

    return children(travelPlan, setTravelPlan, submitTravelPlan);
};

export default TravelPlanManager;

// Utility function
function parseDate(dateString: string) {
    let regex = /(\d+)-(\d+)-(\d+)/;
    if (regex.test(dateString)) {
        let [, year, month, day] = regex.exec(dateString) as RegExpExecArray;
        return new Date(`${month}-${day}-${year}`);
    } else {
        throw Error(`Invalid date format: ${dateString}`);
    }
}

function dateAsString(date: Date) {
  return (/^(\d+-\d+-\d+)/.exec(date.toISOString()) as RegExpExecArray)[0];
}
