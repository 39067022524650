import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Route, useHistory } from 'react-router-dom';
import JournalPreview from '../../Components/Journal/JournalPreview';
import JournalOneView from './JournalOneView/JournalOneView';
import { JournalType } from './JournalTypes';
import styles from './JournalViewAll.module.scss';
import JournalEntry from '../../Components/JournalEntry/JournalEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

// Custom Skeleton implementation
const CustomSkeleton = () => {
	return (
	  <div>
		<div className={styles['custom-skeleton']}></div>
		<div className={styles['custom-skeleton']}></div>
	  </div>
	);
  };
  

const JournalViewAll = () => {
	const [entries, setEntries] = useState([]);
	const [selectedEntry, setSelectedEntry] = useState<JournalType | null>(null);
	const [addNewModal, setAddNewModal] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [filteredEntries, setFilteredEntries] = useState<JournalType[]>([]);
	const journalViewaAlFocusedElementbuttonRef = useRef<HTMLButtonElement | null>(null);
	const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
		const url = `${apiRoutes.SEARCH_JOURNAL}?query=${e.target.value}`;
		console.log('URL: ', url);
		try {
			setSearchQuery(e.target.value);
			if (e.target.value) {
				const res = await privateAPIInstance.get(`${apiRoutes.SEARCH_JOURNAL}?query=${e.target.value}`);
				setFilteredEntries(res.data.data);
			} else {
				setFilteredEntries(entries);
			}
		} catch (error) {
			// Handle error
		}
	};

	const privateAPIInstance = useAxiosPrivate();
	const getAll = async () => {
		// setLoading(true);
		const res = await privateAPIInstance.get(apiRoutes.CREATE_OR_GET_JOURNALS);
		const data = await res.data;
		setEntries(data.data);
	};

	const addingNew = (id: number | null) => {
		setAddNewModal(false);
		getAll();
		if (id) {
			history.push('/journal/' + id);
		}
	};

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 767);
	};

	useEffect(() => {
		const fetchData = async () => {
		  await getAll(); // Fetch data initially
		  setFilteredEntries(entries);
		};
	
		fetchData();
	
		setIsMobile(window.innerWidth <= 600);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	  }, []); // Added an empty dependency array to run it only once
	
	  useEffect(() => {
		setFilteredEntries(entries); // Filter the entries whenever `entries` state changes
	  }, [entries]);

    useEffect(() => {
			if (journalViewaAlFocusedElementbuttonRef.current) {
				journalViewaAlFocusedElementbuttonRef.current.focus();
			}
	}, []);

	const history = useHistory();

	return (
        <main className={`${styles['journals']} ${styles['medieval']}`}>
			{!(isMobile && selectedEntry) && (
				<h1 className="text-4xl font-medium m-2">Journal</h1>
			)}
			<div className="flex justify-around">
				{!(isMobile && selectedEntry) && (
					<div>
                    <div className="parchment-search">
						<input 
						    className="medieval-input"
							type="text"
							placeholder="Search journals..."
							value={searchQuery}
							onChange={handleSearch}
							/>
							</div>
						<button
							onClick={() => setAddNewModal(true)}
							ref={journalViewaAlFocusedElementbuttonRef}
							className={`p-2 ml-2 bg-green-300 outline-none rounded capitalize hover:bg-green-400 mt-2 ${styles.addJournalButton}`}
							>
							Add a new journal
						</button>
						<div className="h-[90vh] overflow-y-scroll mt-2 parchment">
						{
							filteredEntries.length > 0 ? (
							filteredEntries.map((entry) => (
								<JournalPreview
								journal={entry}
								setSelected={setSelectedEntry}
								/>
							))
							) : (
								<div>
								<CustomSkeleton />
								<p>No results found.</p>
								</div>
							)
						}
						</div>
					</div>
				)}
				<div>
					{isMobile ? (
						selectedEntry ? (
							<div className="mt-1">
								<h3 className="mt-8 mb-8">
									<FontAwesomeIcon
										className="mr-2 cursor-pointer"
										onClick={() => {
											setSelectedEntry(null);
											history.push('/journal');
										}}
										icon={faArrowLeft}
									/>{' '}
									Journal
								</h3>
								<JournalViewContainer
									journals={entries}
									getAll={getAll}
									setSelected={setSelectedEntry}
									isMobile={isMobile}
								/>
							</div>
						) : (
							''
						)
					) : (
						<JournalViewContainer
							journals={entries}
							getAll={getAll}
							setSelected={setSelectedEntry}
							isMobile={isMobile}
						/>
					)}
				</div>
			</div>
			{addNewModal && <JournalEntry addingNew={addingNew} />}
		</main>
	);
};

const JournalViewContainer = ({
	journals,
	getAll,
	setSelected,
	isMobile,
}: {
	journals: JournalType[];
	getAll: () => void;
	setSelected: React.Dispatch<React.SetStateAction<JournalType | null>>;
	isMobile: boolean;
}) => {
	return (
		<div className="TEST">
			<Route path="/journal/:id">
				<JournalOneView
					journals={journals}
					getAll={getAll}
					setSelected={setSelected}
				/>
			</Route>
			<Route path="/journal" exact>
				<p className={styles['placeholder']}>Select a journal to view</p>
			</Route>
		</div>
	);
};

export default JournalViewAll;
