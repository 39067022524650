import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./LocationProfile.module.scss";
import { OverlayingLoadingSpinner } from "Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import {
    OwnedObject,
    LocationData,
    ObjectImage,
} from "Pages/Home/HomePageTypes";
import { LocationImageViewer } from "./LocationImageViewer/LocationImageViewer";
import ImageEditingControls from "./ImageEditingControls/ImageEditingControls";
import { AddToProfileButton } from "Components/AddToProfileButton/AddToProfileButton";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import { sixDecimalPlaces } from "../../Utils/utils";
import { ProfileObjectsList } from "Components/ProfileObjectsList/ProfileObjectsList";
import NotFound from "../NotFound/NotFound";
import UnsavedChangesPrompt from "Components/UnsavedChangesPrompt/UnsavedChangesPrompt";
import { isOwnedBySelf } from "Pages/Home/visibility";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { useDocumentTitle } from "hooks/useSEO";
import { apiRoutes } from "services/routes";

import LocationEmailAndNumber from './LocationEmailAndNumber/LocationEmailAndNumber';
import Heading from "./Heading/Heading";
import Description from "./Description/Description";
/**
 * The profile page for a location. It shows the location's
 * name, description, coordinates, and a list of all the
 * contacts and users that are associated with the location.
 * @param props
 * @returns
 * @constructor
 * @example
 * <LocationProfile />
 * @see Home
 * @see LocationProfile.module.scss
 */

export default function LocationProfile() {
    let { locationHandle } = useParams<{ locationHandle: string }>();

    let [locationData, setLocationData] = useApi<LocationData>(
        apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(locationHandle),
        [locationHandle]
    );
    let [editing, setEditing] = useState(false);
    let [showingPicture, setShowingPicture] = useState(0);
    let [uploadingChanges, setUploadingChanges] = useState(false);
    let [objectBeingAdded, setObjectBeingAdded] = useState<string | null>(null);
    let [
        idOfObjectWithImageBeingUploaded,
        setIdOfObjectWithImageBeingUploaded,
    ] = useState<number | null>(null);

    useDocumentTitle(
        `${
            locationData && !(locationData instanceof ApiError)
                ? locationData.name
                : locationHandle
        }'s Profile`,
        [locationData]
    );
    const { postToApi } = usePostToAPI();
    const addObject = async (objectName: string | null) => {
        if (locationData && !(locationData instanceof ApiError) && objectName) {
            let response = await postToApi<OwnedObject>(
                `${apiRoutes.CREATE_PLACE_OBJECT(locationData.id)}`,
                {
                    object: objectName,
                }
            );

            if (response.ok) {
                if (locationData && !(locationData instanceof ApiError)) {
                    setLocationData({
                        ...locationData,
                        objects: [...locationData.objects, response.data],
                    });
                }
            }
        }
        setObjectBeingAdded(null);
    };

    const addNewObjectImage = async (
        file: File,
        url: string,
        objectId: number
    ) => {
        setIdOfObjectWithImageBeingUploaded(objectId);

        let response = await postToApi<ObjectImage>(
            `${apiRoutes.CREATE_PLACE_IMAGE_OBJECT}`,
            {
                image: file,
                objectId: objectId.toString(),
            }
        );

        setIdOfObjectWithImageBeingUploaded(null);

        if (response.ok) {
            let picture = response.data;

            if (locationData && !(locationData instanceof ApiError)) {
                setLocationData({
                    ...locationData,
                    objects: locationData.objects.map((o) =>
                        o.id === objectId
                            ? {
                                  ...o,
                                  pictures: [...o.pictures, picture],
                              }
                            : o
                    ),
                });
            }
        }
    };

    const deleteObjectImage = (imageId: number, objectId: number) => {
        postToApi(apiRoutes.DELETE_PLACE_IMAGE_OBJECT(imageId.toString()));

        if (locationData && !(locationData instanceof ApiError)) {
            setLocationData({
                ...locationData,
                objects: locationData.objects.map((o) =>
                    o.id === objectId
                        ? {
                              ...o,
                              pictures: o.pictures.filter(
                                  (p) => p.id !== imageId
                              ),
                          }
                        : o
                ),
            });
        }
    };

    const onEditButtonClicked = () => {
        if (editing && locationData && !(locationData instanceof ApiError)) {
            postToApi(
                `${apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(locationHandle)}`,
                {
                    name: locationData.name,
                    description: locationData.description,
                    visibility: locationData.visibility,
                    email: locationData.email,
                    phone_number: locationData.phoneNumber,
                },
                "patch"
            ).then(() => setUploadingChanges(false));
            setUploadingChanges(true);
        }
        setEditing(!editing);
    };

    const deleteObject = (object: OwnedObject): void => {
        postToApi(
            apiRoutes.DELETE_PLACE_OBJECT(
                locationData && !(locationData instanceof ApiError)
                    ? locationData?.id
                    : null,
                object.name
            ),
            {},
            "delete"
        );
        if (locationData && !(locationData instanceof ApiError)) {
            setLocationData({
                ...locationData,
                objects: locationData.objects.filter(
                    (value) => value.id !== object.id
                ),
            });
        }
    };

    const updateLocationDataField =
        (field: keyof LocationData) =>
        (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            locationData &&
            setLocationData({
                ...locationData,
                [field]: event.currentTarget.value,
            });

    const isOwnLocation =
        !!locationData &&
        !(locationData instanceof ApiError) &&
        isOwnedBySelf(locationData.visibility);

    return locationData === null ? (
        <main>
            <OverlayingLoadingSpinner />
        </main>
    ) : locationData instanceof ApiError && locationData.statusCode === 404 ? (
        <NotFound />
    ) : (
        <main className={styles["location-profile"]}>
            {uploadingChanges && <OverlayingLoadingSpinner position="fixed" />}
            <UnsavedChangesPrompt unsavedChangesExist={editing} />
            <div className={styles["container"]}>
                {locationData instanceof ApiError ? (
                    <HttpErrorMessage error={locationData} />
                ) : (
                    <>
                        <div className={styles["images"]}>
                            <LocationImageViewer
                                pictures={locationData.pictures}
                                showingPictureIndex={showingPicture}
                                setShowingPictureIndex={setShowingPicture}
                            />
                            {editing && (
                                <ImageEditingControls
                                    locationData={locationData}
                                    setShowingPicture={setShowingPicture}
                                    setLocationData={setLocationData}
                                    showingPicture={showingPicture}
                                />
                            )}
                        </div>
                        <div className={styles["main-text-details"]}>
                            <Heading
                                editing={editing}
                                locationName={locationData.name}
                                updateName={updateLocationDataField("name")}
                                onEditButtonClicked={onEditButtonClicked}
                                canEdit={!!isOwnLocation}
                            />
                            <Description
                                editing={editing}
                                description={locationData.description}
                                updateDescription={updateLocationDataField(
                                    "description"
                                )}
                            />
                            <LocationEmailAndNumber
                                editing={editing}
                                locationData={locationData}
                                updateLocationDataField={
                                    updateLocationDataField
                                }
                            />
                        </div>
                        <br />
                        <Link
                            to={`/?y=${sixDecimalPlaces(
                                locationData.coordinates.y
                            )}&x=${sixDecimalPlaces(
                                locationData.coordinates.x
                            )}&z=12&showLocation=${locationData.id}`}
                        >
                            View on Map
                        </Link>
                        <hr />
                        <ProfileObjectsList
                            objects={locationData.objects}
                            objectBeingAdded={objectBeingAdded}
                            onObjectAdded={addObject}
                            setObjectBeingAdded={setObjectBeingAdded}
                            deleteObjectImage={deleteObjectImage}
                            uploadObjectImage={addNewObjectImage}
                            idOfObjectWithImageBeingUploaded={
                                idOfObjectWithImageBeingUploaded
                            }
                            deleteObject={deleteObject}
                        />
                        <div className={styles["add-button-container"]}>
                            <AddToProfileButton
                                onClick={() => setObjectBeingAdded("")}
                            >
                                Add Object
                            </AddToProfileButton>
                        </div>
                    </>
                )}
            </div>
        </main>
    );
}

