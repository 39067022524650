import { OwnedObject, Skill } from '../HomePageTypes';

import {
	hasOwnProperty,
	sixDecimalPlaces,
	truncateStringIfTooLong,
} from 'Utils/utils';
import {
	SearchLocation,
	SearchUser,
	SearchContact,
	SearchResult,
} from './SearchForm';
import { ApiError } from 'hooks/useAPI';

/**
 * Aggregates the search results into a format which can be
 * easily used in the search results list
 *
 * ---
 * @param placeSearchResults List of locations to display
 * @param userSearchResults List of users to display
 * @param contactSearchResults List of contacts to display
 * @param searchInput The user's search input
 */
export function getCombinedSearchResults(
	placeSearchResults: SearchLocation[] | ApiError | null,
	userSearchResults: SearchUser[] | ApiError | null,
	contactSearchResults: SearchContact[] | ApiError | null,
	searchInput: string
): SearchResult[] {
	return [
		...(placeSearchResults instanceof Array
			? placeSearchResults.map(placeAsSearchResult(searchInput))
			: []),
		...(userSearchResults instanceof Array
			? userSearchResults.map(userAsSearchResult(searchInput))
			: []),
		...(contactSearchResults instanceof Array
			? contactSearchResults.map(contactAsSearchResult(searchInput))
			: []),
	];
}

export function findMatchingElement(
	elements: Array<string>,
	searchInput: string
) {
	return elements
		.filter((element) =>
			searchInput
				.split(/\s/)
				.filter((str) => str.trim() !== '')
				.some((word) => element.toLowerCase().includes(word.toLowerCase()))
		)
		.map((element) => encodeURIComponent(element))
		.join(',');
}

export function userAsSearchResult(
	searchInput: string
): (value: SearchUser) => SearchResult {
	return (user) => {
		const matchingSkill = hasOwnProperty<SearchUser, 'skills', Skill[]>(
			user,
			'skills'
		)
			? findMatchingElement(
				user.skills.map((o) => o.name),
				searchInput
			)
			: '';
		const matchingObject = hasOwnProperty<SearchUser, 'objects', OwnedObject[]>(
			user,
			'objects'
		)
			? findMatchingElement(
				user.objects.map((o) => o.name),
				searchInput
			)
			: '';

		return {
			mainText: user.name,
			subText: hasOwnProperty<SearchUser, 'occupation', string>(
				user,
				'occupation'
			)
				? user.occupation
				: '',
			matchedObject: decodeURIComponent(matchingObject).replaceAll(',', ', '),
			matchedSkill: decodeURIComponent(matchingSkill).replaceAll(',', ', '),
			url: `/?y=${sixDecimalPlaces(
				user.coordinates?.y || 0
			)}&x=${sixDecimalPlaces(user.coordinates?.x || 0)}&z=12&showUser=${user.id}${matchingSkill ? `&highlightSkill=${matchingSkill}` : ''
				}${matchingObject ? `&highlightObject=${matchingObject}` : ''}`,
			targetType: 'user',
			targetId: user.id,
			distance: user.distance,
		} as SearchResult;
	};
}

export function placeAsSearchResult(
	searchInput: string
): (value: SearchLocation) => SearchResult {
	return (place: SearchLocation) => {
		const matchingObject = hasOwnProperty<
			SearchLocation,
			string,
			OwnedObject[]
		>(place, 'objects')
			? findMatchingElement(
				place.objects.map((o) => o.name),
				searchInput
			)
			: '';

		return {
			mainText: place.name,
			subText: truncateStringIfTooLong(
				hasOwnProperty<SearchLocation, 'description', string>(
					place,
					'description'
				)
					? place.description
					: ''
			),
			matchedObject: decodeURIComponent(matchingObject).replaceAll(',', ', '),
			url: `/?y=${sixDecimalPlaces(place.coordinates.y)}&x=${sixDecimalPlaces(
				place.coordinates.x
			)}&z=12&showLocation=${place.id}${matchingObject ? `&highlightObject=${matchingObject}` : ''
				}`,
			targetType: 'location',
			targetId: place.id,
			distance: place.distance,
		} as SearchResult;
	};
}

export function contactAsSearchResult(
	searchInput: string
): (value: SearchContact) => SearchResult {
	return (contact: SearchContact) => {
		const matchingSkill = hasOwnProperty<SearchContact, 'skills', Skill[]>(
			contact,
			'skills'
		)
			? findMatchingElement(
				contact.skills.map((o) => o.name),
				searchInput
			)
			: '';

		const matchingObject = hasOwnProperty<
			SearchContact,
			'objects',
			OwnedObject[]
		>(contact, 'objects')
			? findMatchingElement(
				contact.objects.map((o) => o.name),
				searchInput
			)
			: '';

		const defaultCoordinates = { y: 0, x: 0 };
		const coordinates = contact.coordinates || defaultCoordinates;

		let urlBase = `/?y=${sixDecimalPlaces(coordinates.y)}&x=${sixDecimalPlaces(coordinates.x)}&z=12&showContact=${contact.id}`;
		let skillFragment = matchingSkill ? `&highlightSkill=${matchingSkill}` : '';
		let objectFragment = matchingObject ? `&highlightObject=${matchingObject}` : '';

		let finalUrl = `${urlBase}${skillFragment}${objectFragment}`;


		return {
			mainText: contact.name || 'Unknown contact',
			subText: truncateStringIfTooLong(
				hasOwnProperty<SearchContact, 'description', string>(
					contact,
					'description'
				)
					? contact.description
					: ''
			),
			matchedObject: decodeURIComponent(matchingObject).replaceAll(',', ', '),
			matchedSkill: decodeURIComponent(matchingSkill).replaceAll(',', ', '),
			url: finalUrl,
			targetType: 'contact',
			targetId: contact.id,
			distance: contact.distance,
		};
	};
}
