// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationButtons_confirmation-buttons__azrLy {
  display: flex;
  flex-direction: column;
}
.ConfirmationButtons_confirmation-buttons__azrLy button {
  border: none;
  border-radius: 4px;
  margin: 5px;
  padding: 2px 8px;
  color: white;
  font-weight: 500;
  font-size: 0.9em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_accept__DFgKM {
  background-color: hsl(133, 80%, 37%);
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_accept__DFgKM:hover {
  background-color: var(--accent-color-dark);
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_accept__DFgKM:active {
  background-color: var(--accent-color-x-dark);
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_reject__5u6Cm {
  background-color: hsl(0, 100%, 60%);
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_reject__5u6Cm:hover {
  background-color: hsl(0, 100%, 52%);
}
.ConfirmationButtons_confirmation-buttons__azrLy .ConfirmationButtons_reject__5u6Cm:active {
  background-color: hsl(0, 100%, 44%);
}
.ConfirmationButtons_confirmation-buttons__azrLy button:disabled {
  background-color: grey;
  color: white;
  border: grey;
}
.ConfirmationButtons_confirmation-buttons__azrLy button:disabled:hover {
  background-color: grey;
}
.ConfirmationButtons_confirmation-buttons__azrLy button:disabled:active {
  background-color: grey;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Notifications/ConfirmationButtons/ConfirmationButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,YAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AAKI;EACI,oCAAA;AAHR;AAKQ;EACI,0CAAA;AAHZ;AAMQ;EACI,4CAAA;AAJZ;AAQI;EACI,mCAAA;AANR;AAQQ;EACI,mCAAA;AANZ;AASQ;EACI,mCAAA;AAPZ;AAWI;EACI,sBAAA;EACA,YAAA;EACA,YAAA;AATR;AAWQ;EACI,sBAAA;AATZ;AAYQ;EACI,sBAAA;AAVZ","sourcesContent":[".confirmation-buttons {\n    display: flex;\n    flex-direction: column;\n\n    button {\n        border: none;\n        border-radius: 4px;\n        margin: 5px;\n        padding: 2px 8px;\n        color: white;\n        font-weight: 500;\n        // box-shadow: 0 0 3px #ddd;\n        font-size: 0.9em;\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: center;\n        // width: fit-content;\n        // align-self: flex-end;\n    }\n\n    .accept {\n        background-color: hsl(133, 80%, 37%);\n\n        &:hover {\n            background-color: var(--accent-color-dark);\n        }\n\n        &:active {\n            background-color: var(--accent-color-x-dark);\n        }\n    }\n\n    .reject {\n        background-color: hsl(0, 100%, 60%);\n\n        &:hover {\n            background-color: hsl(0, 100%, 52%);\n        }\n\n        &:active {\n            background-color: hsl(0, 100%, 44%);\n        }\n    }\n\n    button:disabled {\n        background-color: grey;\n        color: white;\n        border: grey;\n\n        &:hover {\n            background-color: grey;\n        }\n\n        &:active {\n            background-color: grey;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation-buttons": `ConfirmationButtons_confirmation-buttons__azrLy`,
	"accept": `ConfirmationButtons_accept__DFgKM`,
	"reject": `ConfirmationButtons_reject__5u6Cm`
};
export default ___CSS_LOADER_EXPORT___;
