// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddContactFromLinkedIn_dialogBox__Hv1Wf h3 {
  margin-bottom: 0;
}
.AddContactFromLinkedIn_dialogBox__Hv1Wf h3,
.AddContactFromLinkedIn_dialogBox__Hv1Wf form,
.AddContactFromLinkedIn_dialogBox__Hv1Wf form button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.AddContactFromLinkedIn_dialogBox__Hv1Wf form button span {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AddContactFromLinkedIn_dialogBox__Hv1Wf .AddContactFromLinkedIn_error__UexeF {
  color: red;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/AddContactFromLinkedIn/AddContactFromLinkedIn.module.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAGI;;;EAGI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AADR;AAMY;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAJhB;AASI;EACI,UAAA;EACA,kBAAA;AAPR","sourcesContent":[".dialogBox {\n    h3 {\n        margin-bottom: 0;\n    }\n\n    h3,\n    form,\n    form button {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-between;\n    }\n\n    form {\n        button {\n            span {\n                padding: 4px;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n            }\n        }\n    }\n\n    .error {\n        color: red;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogBox": `AddContactFromLinkedIn_dialogBox__Hv1Wf`,
	"error": `AddContactFromLinkedIn_error__UexeF`
};
export default ___CSS_LOADER_EXPORT___;
