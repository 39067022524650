// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadedContacts_loaded-contacts__xnVsi {
  max-height: 100%;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG {
  margin-bottom: 10px;
  position: relative;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG .LoadedContacts_uploading-spinner__6IY3C {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6666666667);
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG .LoadedContacts_list__xfAjV {
  overflow-y: scroll;
  height: calc(100vh - 260px);
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG .LoadedContacts_list__xfAjV .LoadedContacts_contact__8a-SK {
  border-bottom: 1px solid #ddd;
  padding: 8px 10px;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG .LoadedContacts_list__xfAjV .LoadedContacts_contact__8a-SK .LoadedContacts_contact-heading__bxO9E {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_list-container__z9upG .LoadedContacts_list__xfAjV .LoadedContacts_contact__8a-SK .LoadedContacts_contact-heading__bxO9E h6 {
  margin: 0;
  margin-left: 20px;
  font-size: 1em;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_coordinates-prompt__C9FJU {
  margin-bottom: 30px;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_button-container__Fljsw {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.LoadedContacts_loaded-contacts__xnVsi .LoadedContacts_button-container__Fljsw button {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/ImportContacts/LoadedContacts/LoadedContacts.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;AACH;AACG;EACG,mBAAA;EACA,kBAAA;AACN;AACM;EACG,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,mDAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACT;AAEM;EACG,kBAAA;EACA,2BAAA;AAAT;AAES;EACG,6BAAA;EACA,iBAAA;AAAZ;AAEY;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;AAAf;AAEe;EACG,SAAA;EACA,iBAAA;EACA,cAAA;AAAlB;AAOG;EACG,mBAAA;AALN;AAQG;EACG,aAAA;EACA,mBAAA;EACA,yBAAA;AANN;AAQM;EACG,iBAAA;AANT","sourcesContent":[".loaded-contacts {\n   max-height: 100%;\n\n   .list-container {\n      margin-bottom: 10px;\n      position: relative;\n\n      .uploading-spinner {\n         position: absolute;\n         top: 0;\n         left: 0;\n         bottom: 0;\n         right: 0;\n         background-color: #fffa;\n         display: flex;\n         align-items: center;\n         justify-content: center;\n      }\n\n      .list {\n         overflow-y: scroll;\n         height: calc(100vh - 260px);\n\n         .contact {\n            border-bottom: 1px solid #ddd;\n            padding: 8px 10px;\n\n            .contact-heading {\n               display: flex;\n               flex-direction: row;\n               align-items: center;\n\n               h6 {\n                  margin: 0;\n                  margin-left: 20px;\n                  font-size: 1em;\n               }\n            }\n         }\n      }\n   }\n\n   .coordinates-prompt {\n      margin-bottom: 30px;\n   }\n\n   .button-container {\n      display: flex;\n      flex-direction: row;\n      justify-content: flex-end;\n\n      button {\n         margin-left: 10px;\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaded-contacts": `LoadedContacts_loaded-contacts__xnVsi`,
	"list-container": `LoadedContacts_list-container__z9upG`,
	"uploading-spinner": `LoadedContacts_uploading-spinner__6IY3C`,
	"list": `LoadedContacts_list__xfAjV`,
	"contact": `LoadedContacts_contact__8a-SK`,
	"contact-heading": `LoadedContacts_contact-heading__bxO9E`,
	"coordinates-prompt": `LoadedContacts_coordinates-prompt__C9FJU`,
	"button-container": `LoadedContacts_button-container__Fljsw`
};
export default ___CSS_LOADER_EXPORT___;
