import React from "react";
import { Link } from "react-router-dom";
import styles from "../Conversation.module.scss";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import SmallLoadingSpinner from "Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { UserData } from "Pages/Home/HomePageTypes";
import { ApiError } from "hooks/useAPI";

interface ConversationHeaderProps {
  userHandle: string;
  user: UserData | ApiError | null;
}

/**
 * Displayed at the top of the conversation page
 */
export function ConversationHeader({
  userHandle,
  user,
}: ConversationHeaderProps) {
  return (
    <div className={styles["header"]}>
      <Link to="/messages">
        <div className={styles["close-icon"]}>
          <div></div>
          <div></div>
        </div>
      </Link>
      <Link to={`/@${userHandle}`}>
        <div className={styles["user-details"]}>
          {user ? (
            <>
              {!(user instanceof ApiError) && (
                <RoundThumbnailImage
                  image={
                    user.smallProfilePicture
                      ? user.smallProfilePicture
                      : "/images/default-user-image.svg"
                  }
                  size={35}
                />
              )}
              <div className={styles["user-name"]}>
                {user && !(user instanceof ApiError)
                  ? user.name
                  : userHandle}
              </div>
            </>
          ) : (
            <>
              <SmallLoadingSpinner
                primaryColor="grey"
                secondaryColor="#8883"
              />{" "}
              {userHandle}
            </>
          )}
        </div>
      </Link>
      <div className={styles["close-icon"]}></div>
    </div>
  );
}
