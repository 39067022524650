import React, { ReactNode } from "react";

import styles from "./SettingsAccordion.module.scss"
import Accordion from "Components/Accordion/Accordion";

/** 
 * Is used on the settings page to indicate a sub-section
 * of the settings
 * 
 * The first child element is part of the accordion header, 
 * while the second child is part of the body 
 */
export function SettingsAccordion({
    children,
}: {
    children: [ReactNode, ReactNode];
}) {
    return (
        <>
            <Accordion
                className={styles.accordion}
                defaultIsOpen={false}  // Changed from true to false
                summaryStyle={{
                    padding: "16px 0",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 50,
                }}
            >
                {children}
            </Accordion>
        </>
    );
}
