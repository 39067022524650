import React, { useCallback, useState } from 'react';
import { Marker } from 'react-leaflet';
import { LocationData } from '../HomePageTypes';

import { usePopupOpeningEffect } from 'hooks/map/usePopupOpeningEffect';
import { DispatchAction } from '../reducer';

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";

import { LocationMarkerIcon } from 'Components/LocationMarkerIcon/LocationMarkerIcon';

import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { LocationEditMarkerPopup } from './LocationEditMarkerPopup/LocationEditMarkerPopup'
/**
 * Used to edit an existing location or add a new one.
 */
export function LocationEditMarker({
	locationData,
	state,
	dispatch,
}: {
	locationData: LocationData;
	state: HomePageState;
	dispatch: React.Dispatch<DispatchAction>;
}) {
	let [popupIsOpen, setPopupIsOpen] = useState(true);
	let { markerRefCallback, markerRef } = usePopupOpeningEffect(
		popupIsOpen,
		useCallback(() => setPopupIsOpen(false), [setPopupIsOpen])
	);

	const icon = LocationMarkerIcon(locationData);

	let stopEditing = () =>
		dispatch({
			type: ACTIONS.locationBeingEdited,
			value: null,
		});

	let setLocationData = (data: LocationData | null) =>
		state.locationBeingEdited &&
		dispatch({
			type: ACTIONS.locationBeingEdited,
			value: data
				? {
						data: data,
						editingOrNew: state.locationBeingEdited.editingOrNew,
				  }
				: null,
		});
	const apiInstance = useAxiosPrivate();
	const finishEditing = async () => {
		if (state.locationBeingEdited) {
			if (state.locationBeingEdited.editingOrNew === 'new') {
				const res = await apiInstance.post(apiRoutes.CREATE_OR_GET_PLACES, {
					name: state.locationBeingEdited.data.name,
					description: state.locationBeingEdited.data.description,
					coordinates: `${state.locationBeingEdited.data.coordinates.y},${state.locationBeingEdited.data.coordinates.x}`,
					visibility: state.locationBeingEdited.data.visibility,
					phone_number: state.locationBeingEdited.data.phoneNumber,
					email: state.locationBeingEdited.data.email,
					facebook: state.locationBeingEdited.data.facebook,
					instagram: state.locationBeingEdited.data.instagram,
					twitter: state.locationBeingEdited.data.twitter,
					linkedin: state.locationBeingEdited.data.linkedin,
					youtube: state.locationBeingEdited.data.youtube,
					website: state.locationBeingEdited.data.website,
				});

				dispatch({
					type: ACTIONS.ADD_LOCATION,
					location: res.data.data,
				});
			} else if (state.locationBeingEdited.editingOrNew === 'editing') {
				apiInstance.patch(
					apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(
						state.locationBeingEdited.data.handle
					),
					{
						name: state.locationBeingEdited.data.name,
						description: state.locationBeingEdited.data.description,
						coordinates: `${state.locationBeingEdited.data.coordinates.y},${state.locationBeingEdited.data.coordinates.x}`,
						visibility: state.locationBeingEdited.data.visibility,
						phone_number: state.locationBeingEdited.data.phoneNumber,
						email: state.locationBeingEdited.data.email,
						facebook: state.locationBeingEdited.data.facebook,
						instagram: state.locationBeingEdited.data.instagram,
						twitter: state.locationBeingEdited.data.twitter,
						linkedin: state.locationBeingEdited.data.linkedin,
						youtube: state.locationBeingEdited.data.youtube,
						website: state.locationBeingEdited.data.website,
					}
				);
				dispatch({
					type: ACTIONS.UPDATE_LOCATION,
					location: state.locationBeingEdited.data,
				});
			}
			stopEditing();
		}
	};

	return (
		// <MapContainer
		//   center={[locationData.coordinates.y, locationData.coordinates.x]}
		//   zoom={13}
		//   style={{ width: '100%', height: '100%' }}
		// >
		//   <TileLayer
		//     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
		//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		//   />
		<div>
		<Marker
			position={[locationData.coordinates.y, locationData.coordinates.x]}
			icon={icon}
			ref={markerRefCallback}
			draggable
			eventHandlers={{
				dragend: () => {
					if (markerRef.current) {
						let latLong = markerRef.current.getLatLng();
						setLocationData({
							...locationData,
							coordinates: { x: latLong.lng, y: latLong.lat },
						});
						setPopupIsOpen(true);
					}
				},
			}}
		>
			<LocationEditMarkerPopup
				editingOrNew={state.locationBeingEdited?.editingOrNew || null}
				locationData={locationData}
				setLocationData={setLocationData}
				cancelEditing={stopEditing}
				finishEditing={finishEditing}
			/>
		</Marker>
		</div>
		// </MapContainer>
	);
}