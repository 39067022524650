import React, { useState } from 'react';
import styles from './MagicButton.module.scss'; // Import the SCSS module for the MagicButton

type MagicButtonProps = {
  label: string;
  onClick: () => void;
};

function MagicButton({ label, onClick }: MagicButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={`${styles['magic-button']} ${isHovered ? styles['hovered'] : ''}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
    </button>
  );
}

export default MagicButton;
