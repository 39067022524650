import React from 'react';
import CustomDialog from "../../../../Components/CustomDialog/CustomDialog";
import CustomDialogActions from "../../../../Components/CustomDialogActions/CustomDialogActions";
import MuiReplaceCustomButton from "../../../../Components/MuiReplaceCustomButton/MuiReplaceCustomButton";
import CustomDialogTitle from "../../../../Components/CustomDialogTitle/CustomDialogTitle";

interface DeleteNetworkDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    networkName: string;
}

const DeleteNetworkDialog: React.FC<DeleteNetworkDialogProps> = ({ 
  open, 
  onClose, 
  onConfirm, 
  networkName 
}) => {
    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CustomDialogTitle id="alert-dialog-title">
                {`Are you sure you want to delete the network "${networkName}"? This action cannot be undone.`}
            </CustomDialogTitle>
            <CustomDialogActions>
                <MuiReplaceCustomButton onClick={onClose} color="green">
                    No
                </MuiReplaceCustomButton>
                <MuiReplaceCustomButton onClick={onConfirm} color="red" requireDoubleCheck={true} doubleCheckMessage="Are you sure?">
                    Yes
                </MuiReplaceCustomButton>
            </CustomDialogActions>
        </CustomDialog>
    );
};

export default DeleteNetworkDialog;
