// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Messages_messages__Rbumy {
  height: 100%;
}
.Messages_messages__Rbumy::-webkit-scrollbar {
  display: none;
  width: 0;
}
.Messages_messages__Rbumy .Messages_messages-list__RC1Sc {
  width: 100%;
  padding: 20px;
  background-color: rgb(245, 245, 245);
  height: 100%;
  position: relative;
  overflow-y: scroll;
}
.Messages_messages__Rbumy .Messages_messages-list__RC1Sc a {
  text-decoration: none;
  color: inherit;
}
.Messages_messages__Rbumy .Messages_messages-list__RC1Sc .Messages_other-contact__7CsVY {
  display: grid;
  grid-template-columns: 80px auto;
  padding: 10px;
}
.Messages_messages__Rbumy .Messages_messages-list__RC1Sc .Messages_other-contact__7CsVY .Messages_user-name__Jifn2 {
  height: 100%;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .Messages_messages__Rbumy .Messages_conversation-container__S22PN {
    width: 0;
    position: fixed;
    left: 100vw;
  }
}
.Messages_messages__Rbumy .Messages_conversation-container__S22PN .Messages_placeholder__wXKJu {
  padding: 40px;
  text-align: center;
  color: rgb(90, 90, 90);
}
@media screen and (min-width: 600px) {
  .Messages_messages__Rbumy {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .Messages_messages__Rbumy .Messages_conversation-container__S22PN {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow);
    background-color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Messages/Messages.module.scss"],"names":[],"mappings":"AAAA;EACG,YAAA;AACH;AACG;EACG,aAAA;EACA,QAAA;AACN;AAEG;EACG,WAAA;EACA,aAAA;EACA,oCAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAAN;AAEM;EACG,qBAAA;EACA,cAAA;AAAT;AAGM;EACG,aAAA;EACA,gCAAA;EACA,aAAA;AADT;AAGS;EACG,YAAA;EACA,gBAAA;AADZ;AAOM;EADH;IAGM,QAAA;IACA,eAAA;IACA,WAAA;EALP;AACF;AAOM;EACG,aAAA;EACA,kBAAA;EACA,sBAAA;AALT;AASG;EAhDH;IAiDM,aAAA;IACA,8BAAA;EANJ;EAQI;IACG,kBAAA;IACA,aAAA;IACA,aAAA;IACA,mBAAA;IACA,uBAAA;IACA,yBAAA;IACA,uBAAA;EANP;AACF","sourcesContent":[".messages {\n   height: 100%;\n\n   &::-webkit-scrollbar {\n      display: none;\n      width: 0;\n   }\n\n   .messages-list {\n      width: 100%;\n      padding: 20px;\n      background-color: rgb(245, 245, 245);\n      height: 100%;\n      position: relative;\n      overflow-y: scroll;\n\n      a {\n         text-decoration: none;\n         color: inherit;\n      }\n\n      .other-contact {\n         display: grid;\n         grid-template-columns: 80px auto;\n         padding: 10px;\n\n         .user-name {\n            height: 100%;\n            margin-top: 10px;\n         }\n      }\n   }\n\n   .conversation-container {\n      @media screen and (max-width: 600px) {\n         // display: none;\n         width: 0;\n         position: fixed;\n         left: 100vw;\n      }\n\n      .placeholder {\n         padding: 40px;\n         text-align: center;\n         color: rgb(90, 90, 90);\n      }\n   }\n\n   @media screen and (min-width: 600px) {\n      display: grid;\n      grid-template-columns: 50% 50%;\n\n      .conversation-container {\n         position: relative;\n         height: 100vh;\n         display: flex;\n         align-items: center;\n         justify-content: center;\n         box-shadow: var(--shadow);\n         background-color: white;\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": `Messages_messages__Rbumy`,
	"messages-list": `Messages_messages-list__RC1Sc`,
	"other-contact": `Messages_other-contact__7CsVY`,
	"user-name": `Messages_user-name__Jifn2`,
	"conversation-container": `Messages_conversation-container__S22PN`,
	"placeholder": `Messages_placeholder__wXKJu`
};
export default ___CSS_LOADER_EXPORT___;
