import React, { useMemo } from 'react';
import { MinimalNetworkData } from '../../Pages/Home/HomePageTypes';
import NetworkMarkerPopup from 'Components/NetworkMarkerPopup/NetworkMarkerPopup';

type Position = [number, number]; // [latitude, longitude]

// Custom hook to calculate marker positions
export const useCalculateMarkerPositions = (
  networks: MinimalNetworkData[] | null,
  centerPosition: Position,
  zoomLevel: number
): JSX.Element[] => {
  return useMemo(() => {
    const markers: JSX.Element[] = [];
    const baseZoomLevel = 13;
    const baseRadius = 0.004;
    const radiusIncrement = 0.001;

    if (networks && networks.length > 0) { // Check if 'networks' is not null and has elements
      const angleStep = (2 * Math.PI) / networks.length;
      const scaleFactor = Math.pow(2, baseZoomLevel - zoomLevel);

      if (zoomLevel >= baseZoomLevel) {
        networks.forEach((network, index) => {
          const circleNumber = Math.floor(index / networks.length);
          const radius = (baseRadius + circleNumber * radiusIncrement) * scaleFactor;
          const angle = index * angleStep;

          const latitude = centerPosition[0] + radius * Math.cos(angle);
          const longitude = centerPosition[1] + radius * Math.sin(angle);

          markers.push(
            <NetworkMarkerPopup 
              key={String(network.id)} 
              network={network} 
              position={[latitude, longitude]} 
            />
          );
        });
      }
    }

    return markers;
  }, [networks, centerPosition, zoomLevel]);
};


