// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_notification__Vdfyl {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  background-color: var(--accent-color-01-opacity);
}
.Notification_notification__Vdfyl .Notification_main-info__IaMEf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Notification_notification__Vdfyl .Notification_main-info__IaMEf .Notification_details__f6Q9O {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.Notification_notification__Vdfyl .Notification_main-info__IaMEf .Notification_details__f6Q9O .Notification_description__Lvx-F {
  margin: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Notifications/Notification/Notification.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,gDAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACZ;AACY;EACI,cAAA;AAChB","sourcesContent":[".notification {\n    padding: 10px 20px;\n    border-bottom: 1px solid #ddd;\n    background-color: var(--accent-color-01-opacity);\n\n    .main-info {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-between;\n\n        .details {\n            display: flex;\n            flex-direction: row;\n            height: 100%;\n            align-items: center;\n\n            .description {\n                margin: 0 20px;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Notification_notification__Vdfyl`,
	"main-info": `Notification_main-info__IaMEf`,
	"details": `Notification_details__f6Q9O`,
	"description": `Notification_description__Lvx-F`
};
export default ___CSS_LOADER_EXPORT___;
