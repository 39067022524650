export default function MapIcon({
	className,
	width,
	height,
}: {
	className: string;
	width: string;
	height: string;
}) {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			version="1.1"
			viewBox="0 0 119.77 105.96"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m60.967-3e-7c-1.0855-3.87e-5 -2.1709 0.045455-2.9941 0.13477-12.358 1.3407-22.175 10.883-23.586 22.916-0.19286 1.6446-0.18128 4.3445 0.04687 5.9844 0.23569 1.694 0.64856 3.3627 1.1855 4.9863 0.52015 1.5726 1.5867 4.042 2.3203 5.5273 6.7861 11.932 14.243 23.679 23.026 34.272 8.152-11.276 17.102-22.56 23.03-34.272 0.73316-1.4847 1.7985-3.9547 2.3184-5.5273 0.53673-1.6236 0.94824-3.2924 1.1836-4.9863 0.22784-1.6399 0.23974-4.3397 0.04687-5.9844-1.411-12.032-11.227-21.575-23.584-22.916-0.82318-0.089364-1.9086-0.13473-2.9941-0.13477zm0 18.916c1.0818-1e-6 2.1645 0.15917 2.9238 0.47656 1.7271 0.72189 3.1164 2.0654 3.873 3.7402 0.67698 1.4986 0.67657 4.3215 0 5.8203-0.75643 1.6757-2.1453 3.02-3.873 3.7422-1.5187 0.63479-4.327 0.63479-5.8457 0-1.7277-0.72216-3.1186-2.0665-3.875-3.7422-0.67657-1.4988-0.67508-4.3217 2e-3 -5.8203 0.75662-1.6749 2.146-3.0183 3.873-3.7402 0.75935-0.31739 1.8401-0.47656 2.9219-0.47656z"
				fill="currentColor"
			/>
			<path
				d="m117.94 34.184a2.1121 2.1121 0 0 0-0.9961 0.10547l-26.674 9.5527c-1.9199 3.6977-4.1219 7.3646-6.4863 10.992v49.016a2.1121 2.1121 0 0 0 2.8242 1.9883l30.336-10.865a4.2611 4.2611 0 0 0 2.8281-4.0117v-54.684a2.1121 2.1121 0 0 0-1.832-2.0938zm-89.6 1.834-25.512 9.1367a4.2611 4.2611 0 0 0-2.8242 4.0098v54.686a2.1121 2.1121 0 0 0 2.8242 1.9883l30.336-10.863a4.2611 4.2611 0 0 0 2.8262-4.0117v-39.797c-1.5784-2.6217-3.1214-5.2508-4.6172-7.8809l-0.10938-0.19141-0.097657-0.19727c-0.82606-1.6725-2.0853-4.5869-2.7188-6.502-0.040263-0.12174-0.068124-0.25421-0.10742-0.37695zm13.711 24.793v30.15a4.2611 4.2611 0 0 0 2.8242 4.0117l30.338 10.865a2.1121 2.1121 0 0 0 2.8242-1.9883v-40.617c-3.6489 5.1179-7.4161 10.139-10.943 15.018l-5.7168 7.9062-6.2266-7.5117c-4.7269-5.7011-9.0594-11.699-13.1-17.834z"
				fill="currentColor"
			/>
		</svg>
	);
}
