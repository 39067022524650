// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ControlActions_add-location__uZ4Mj {
  box-shadow: var(--shadow);
  border-radius: 25px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--accent-color);
}
@media screen and (min-width: 600px) {
  .ControlActions_add-location__uZ4Mj {
    bottom: 30px;
    right: 30px;
  }
}
.ControlActions_add-location__uZ4Mj svg {
  height: 20px;
  width: 20px;
  fill: white;
}

button.ControlActions_add-location__uZ4Mj:hover {
  background-color: var(--accent-color-dark);
}

button.ControlActions_add-location__uZ4Mj:active {
  background-color: var(--accent-color-x-dark);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FloatingControls/ControlActions.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,qCAAA;AACJ;AACI;EAXJ;IAYQ,YAAA;IACA,WAAA;EAEN;AACF;AAAI;EACI,YAAA;EACA,WAAA;EACA,WAAA;AAER;;AAEA;EACI,0CAAA;AACJ;;AAEA;EACI,4CAAA;AACJ","sourcesContent":[".add-location {\n    box-shadow: var(--shadow);\n    border-radius: 25px;\n    height: 50px;\n    width: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: none;\n    background-color: var(--accent-color);\n\n    @media screen and (min-width: 600px) {\n        bottom: 30px;\n        right: 30px;\n    }\n\n    svg {\n        height: 20px;\n        width: 20px;\n        fill: white;\n    }\n}\n\nbutton.add-location:hover {\n    background-color: var(--accent-color-dark);\n}\n\nbutton.add-location:active {\n    background-color: var(--accent-color-x-dark);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-location": `ControlActions_add-location__uZ4Mj`
};
export default ___CSS_LOADER_EXPORT___;
