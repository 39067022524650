import React, { useState } from "react";
import { BasicNetworkData } from '../NetworkGroups/BasicNetworkDataTypes';
import { apiRoutes } from 'services/routes';
import styles from './CreateNetwork.module.scss'; // Import the SCSS module

interface CreateNetworkProps {
    network: BasicNetworkData;
    selectedPhoto: File | null;
    apiInstance: any; // Replace 'any' with the specific Axios instance type
    fetchCallback?: () => void;
    createModalOpen?: React.Dispatch<boolean>;
}

const CreateNetwork: React.FC<CreateNetworkProps> = ({
    network,
    selectedPhoto,
    apiInstance,
    fetchCallback,
    createModalOpen
}) => {
    const [nameWarning, setNameWarning] = useState<string | null>(null); // State to store the warning message

    const createNetwork = () => {
        if (!network.name) {
            // Display a warning and prevent pushing without a name
            setNameWarning("Name is required.");
            return;
        }

        // Clear any previous warning
        setNameWarning(null);

        let body = new FormData();
        body.append("name", network.name);
        body.append("description", network.description);
        body.append("visibility", network.visibility);
        body.append("network_logo", selectedPhoto as File); // network_logo is the key for the file
        body.append("member_contacts", JSON.stringify(network.member_contacts));
        body.append("member_connections", JSON.stringify(network.member_connections));
        body.append("member_locations", JSON.stringify(network.member_locations));

        apiInstance.post(apiRoutes.CREATE_BULK_MEMBERS_NETWORK, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(async (response: { status: number; }) => {
            console.log("API Response:", response);
            if (response.status < 300) {
                // Handle success response
            } else {
                // Handle error response
            }
        })
        .finally(() => {
            if (fetchCallback) {
                fetchCallback();
            }
            if (createModalOpen) {
                createModalOpen(false);
            }
        });
    };

    return (
        <div>
            {nameWarning && <div className={styles.warning}>{nameWarning}</div>}
            <button className={styles["create-button"]} onClick={createNetwork}>Create Network</button>
        </div>
    );
};

export default CreateNetwork;
