import React, { useMemo } from "react";

/**
 * Used to display images in a round shape. This uses a
 * div with a `background-image` set to the url of the
 * image instead of the normal html `img` element, due
 * to it being easier to center and clip the background
 */
export default function RoundThumbnailImage({
  image,
  size: _size,
  rounding,
  className,
}: {
  /**
   * A url or blob of the image
   */
  image: string | Blob;
  /**
   * The width and height of the image
   */
  size?: number;
  /**
   * How rounded the image should be. Values range
   * from 0 to 1. A value of 1 makes it a circle,
   * while a value of 0 makes it a square
   */
  rounding?: number;
  className?: string;
}) {
  let size = _size || 60;
  let imageUrl = useMemo(
    () =>
      typeof image === "string"
        ? image
        : (window.URL || window.webkitURL).createObjectURL(image),
    [image]
  );

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${
          ((rounding === undefined ? 1 : rounding) * size) / 2
        }px`,
        display: "inline-block",
        flexShrink: 0,
      }}
    ></div>
  );
}
