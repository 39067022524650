import React, { useCallback } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";

import styles from "./CoordinatesSelectionMap.module.scss";
import { Point } from "../../Pages/Home/HomePageTypes";
import { getTileLayerProps } from "../../Pages/Home/FloatingControls/TileLayerSelector/TileLayerSelector";
import { CloseIcon } from "../../Icons/CloseIcon";

type GeoSearchResult = L.LeafletEvent & {
  location: Point;
  title: string;
};

const initializeSearchControl = (
  map: L.Map,
  setCoordinatesValue: (value: Point) => void,
  onClose: () => void
) => {  const provider = new OpenStreetMapProvider();
    // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider,
    autoComplete: true,
    autoCompleteDelay: 250,
    showMarker: false,
  });

  map.addControl(searchControl);

  map.on("geosearch/showlocation", (event) => {
    const result = event as GeoSearchResult;
    const { x, y } = result.location;
    setCoordinatesValue({ x, y });
    onClose();
  });
};

export function CoordinatesSelectionMap({
  onClose,
  setCoordinatesValue,
  initialViewpoint,
}: {
  onClose: () => void;
  setCoordinatesValue: (value: Point) => void;
  initialViewpoint?: Point;
}) {
  let mapRef = useCallback((map: L.Map | null) => {
    if (map) {
      map.addEventListener("click", (event: L.LeafletMouseEvent) => {
        setCoordinatesValue({
          x: event.latlng.lng,
          y: event.latlng.lat,
        });
        onClose();
      });
  
      initializeSearchControl(map, setCoordinatesValue, onClose);
    }
  }, []);

  return (
    <div className={styles["coordinates-map"]} onClick={onClose}>
      <div className={styles["container"]} onClick={(e) => e.stopPropagation()}>
        <MapContainer
          center={
            initialViewpoint ? [initialViewpoint.y, initialViewpoint.x] : [0, 0]
          }
          zoom={2}
          className={styles["map"]}
          ref={mapRef}
        >
          <TileLayer {...getTileLayerProps("Google")} />
        </MapContainer>
        <button onClick={onClose} className={styles["close-button"]}>
          <CloseIcon color="#555" lineLength={30} />
        </button>
      </div>
    </div>
  );
}
