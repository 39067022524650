// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationMarkerIcon_location-marker-icon__vgkp6 div {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5333333333);
}
.LocationMarkerIcon_location-marker-icon__vgkp6 div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
}

.LocationMarkerIcon_info-message__wVsc4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(30px, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 100px;
}

.LocationMarkerIcon_hide-message__5qnbF {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/LocationMarkerIcon/LocationMarkerIcon.module.scss"],"names":[],"mappings":"AACE;EACG,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,2BAAA;EACA,sBAAA;EACA,mDAAA;AAAL;AAEK;EACG,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AAAR;;AAOA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,0CAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;EAAA,sBAAA;EACA,gBAAA;AAJF;;AAOA;EACE,aAAA;AAJF","sourcesContent":[".location-marker-icon {\n  div {\n     width: 46px;\n     height: 46px;\n     border-radius: 23px;\n     background-size: cover;\n     background-position: center;\n     background-color: #ccc;\n     box-shadow: 0px 5px 5px #0008;\n\n     &:before {\n        content: '';\n        position: absolute;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        left: 0;\n        z-index: -1;\n        margin: -2px;\n        border-radius: inherit;\n     }\n  }\n   \n   \n}\n\n.info-message {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(30px, -50%);\n  background-color: rgba(255, 255, 255, 0.8);\n  padding: 5px;\n  border-radius: 3px;\n  font-size: 12px;\n  max-width: fit-content;\n  min-width: 100px;\n}\n\n.hide-message {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location-marker-icon": `LocationMarkerIcon_location-marker-icon__vgkp6`,
	"info-message": `LocationMarkerIcon_info-message__wVsc4`,
	"hide-message": `LocationMarkerIcon_hide-message__5qnbF`
};
export default ___CSS_LOADER_EXPORT___;
