import React from "react";

import styles from "./CustomRangeSlider.module.scss";

/**
 * A custom styled range slider. Styling based on:
 * https://codepen.io/dev_loop/pen/vYYxvbz
 */
export default function CustomRangeSlider({
  defaultValue,
  value,
  disabled,
  max,
  min,
  onInput,
  step,
}: {
  disabled?: boolean;
  defaultValue?: number;
  value: number;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  step?: number;
}) {
  const percentage = (100 * (value - min)) / (max - min);
  const background = `linear-gradient(90deg, var(--accent-color) ${percentage}%, rgb(150, 150, 150) ${
    percentage + 0.1
  }%)`;

  return (
    <input
      className={styles["slider"]}
      type="range"
      min={min}
      max={max}
      defaultValue={defaultValue}
      style={{ background }}
      onInput={onInput}
      step={step}
      disabled={disabled}
    />
  );
}
