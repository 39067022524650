import React, { useState, ChangeEvent, FormEvent } from 'react';
import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import styles from './AddressList.module.scss';

interface Address {
  id: number;
  formatted_address: string;
  street_number: string;
  street_name: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  lat: number; // Or number
  lng: number; // Or number
  content_type?: string;
  object_id: number;
}

interface EditAddressProps {
  data: Address;
  onObjectUpdated: () => void;
}

const EditAddress: React.FC<EditAddressProps> = ({ data, onObjectUpdated }) => {
  const [editedAddress, setEditedAddress] = useState<Address>(data);
  const privateAPIInstance = useAxiosPrivate();
  const excludedFields = ['id', 'lat','lng','object_id', 'formatted_address','content_type', 'created_date'];

  const createFormattedAddress = (updatedAddress: Address) => {
    const parts = [
      updatedAddress.street_name,
      updatedAddress.street_number,
      updatedAddress.city,
      updatedAddress.postal_code,
      updatedAddress.state,
      updatedAddress.country
    ];
    return parts.filter(Boolean).join(', ');
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>, isBlur: boolean = false) => {
    const { name, value } = e.target;
    let updatedAddress = { ...editedAddress, [name]: value };

    // Recreate formatted_address if relevant fields are changed
    if (['street_name', 'street_number', 'city', 'postal_code', 'state', 'country'].includes(name)) {
      updatedAddress.formatted_address = createFormattedAddress(updatedAddress);
    }

    setEditedAddress(updatedAddress);

    if (isBlur && JSON.stringify(data[name as keyof Address]) !== JSON.stringify(value)) {
      try {
        await privateAPIInstance.put(apiRoutes.EDIT_ADDRESS(updatedAddress.id), updatedAddress);
        onObjectUpdated();
      } catch (error) {
        // Handle update error
      }
    }
  };

  return (
    <div>
      {Object.entries(editedAddress).map(([key, value]) => {
        if (excludedFields.includes(key)) return null; // Skip excluded fields
        return (
          <div className={styles.groupedIn} key={key}>
            <div className={styles.label}>{key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}:</div>
            <input 
              type="text"
              name={key}
              placeholder={key}
              value={value}
              onChange={(e) => handleChange(e)}
              onBlur={(e) => handleChange(e, true)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EditAddress;