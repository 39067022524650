// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiReplaceCustomButton_mui-custom-button__laU1W {
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  margin: 0 8px;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_red__8oDu1 {
  background-color: #ff0000;
  color: #ffffff;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_red__8oDu1:hover {
  background-color: #d40000;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_blue__7SVlV {
  background-color: #007bff;
  color: #ffffff;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_blue__7SVlV:hover {
  background-color: #0056b3;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_green__P9T-3 {
  background-color: #28a745;
  color: white;
}
.MuiReplaceCustomButton_mui-custom-button__laU1W.MuiReplaceCustomButton_green__P9T-3:hover {
  background-color: #218838;
}`, "",{"version":3,"sources":["webpack://./src/Components/MuiReplaceCustomButton/MuiReplaceCustomButton.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,gCAAA;EACA,aAAA;AACJ;AACI;EACE,yBAAA;EACA,cAAA;AACN;AACM;EACE,yBAAA;AACR;AAGI;EACE,yBAAA;EACA,cAAA;AADN;AAGM;EACE,yBAAA;AADR;AAKI;EACE,yBAAA;EACA,YAAA;AAHN;AAKM;EACE,yBAAA;AAHR","sourcesContent":[".mui-custom-button {\n    padding: 12px 24px;\n    font-size: 16px;\n    cursor: pointer;\n    border: none;\n    border-radius: 4px;\n    transition: all 0.2s ease-in-out;\n    margin: 0 8px;\n  \n    &.red {\n      background-color: #ff0000;\n      color: #ffffff;\n  \n      &:hover {\n        background-color: #d40000;\n      }\n    }\n  \n    &.blue {\n      background-color: #007bff;\n      color: #ffffff;\n  \n      &:hover {\n        background-color: #0056b3;\n      }\n    }\n    \n    &.green {\n      background-color: #28a745;\n      color: white;\n  \n      &:hover {\n        background-color: #218838;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mui-custom-button": `MuiReplaceCustomButton_mui-custom-button__laU1W`,
	"red": `MuiReplaceCustomButton_red__8oDu1`,
	"blue": `MuiReplaceCustomButton_blue__7SVlV`,
	"green": `MuiReplaceCustomButton_green__P9T-3`
};
export default ___CSS_LOADER_EXPORT___;
