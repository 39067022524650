// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDialogActions_custom-dialog-actions__GaM4N {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #ccc;
  background-color: #f6f6f6;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
.CustomDialogActions_custom-dialog-actions__GaM4N button {
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.CustomDialogActions_custom-dialog-actions__GaM4N button:hover {
  background-color: #ddd;
}
.CustomDialogActions_custom-dialog-actions__GaM4N .CustomDialogActions_primary-action__7UHGc {
  background-color: #007bff;
  color: #fff;
}
.CustomDialogActions_custom-dialog-actions__GaM4N .CustomDialogActions_primary-action__7UHGc:hover {
  background-color: #0056b3;
}
.CustomDialogActions_custom-dialog-actions__GaM4N .CustomDialogActions_secondary-action__ORLsS {
  background-color: #ccc;
}
.CustomDialogActions_custom-dialog-actions__GaM4N .CustomDialogActions_secondary-action__ORLsS:hover {
  background-color: #bbb;
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomDialogActions/CustomDialogActions.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,0BAAA;EACA,yBAAA;EACA,0CAAA;AACJ;AAEI;EACE,iBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,6CAAA;AAAN;AAEM;EACE,sBAAA;AAAR;AAII;EACE,yBAAA;EACA,WAAA;AAFN;AAIM;EACE,yBAAA;AAFR;AAMI;EACE,sBAAA;AAJN;AAMM;EACE,sBAAA;AAJR","sourcesContent":[".custom-dialog-actions {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 16px 24px;\n    border-top: 1px solid #ccc;\n    background-color: #f6f6f6;\n    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);\n  \n    // If you have buttons or other elements, you can further style them here\n    button {\n      padding: 8px 16px;\n      font-size: 16px;\n      border: none;\n      border-radius: 4px;\n      cursor: pointer;\n      transition: background-color 0.2s ease-in-out;\n  \n      &:hover {\n        background-color: #ddd;\n      }\n    }\n  \n    .primary-action {\n      background-color: #007bff;\n      color: #fff;\n  \n      &:hover {\n        background-color: #0056b3;\n      }\n    }\n  \n    .secondary-action {\n      background-color: #ccc;\n  \n      &:hover {\n        background-color: #bbb;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-dialog-actions": `CustomDialogActions_custom-dialog-actions__GaM4N`,
	"primary-action": `CustomDialogActions_primary-action__7UHGc`,
	"secondary-action": `CustomDialogActions_secondary-action__ORLsS`
};
export default ___CSS_LOADER_EXPORT___;
