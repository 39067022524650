// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditingConfirmationButtons_add-element-buttons__eRSqz {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.EditingConfirmationButtons_add-element-buttons__eRSqz button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  height: 30px;
  width: 30px;
  border: 3px solid green;
}
.EditingConfirmationButtons_add-element-buttons__eRSqz button:hover {
  background-color: rgb(230, 230, 230);
}
.EditingConfirmationButtons_add-element-buttons__eRSqz button:active {
  background-color: rgb(200, 200, 200);
}
.EditingConfirmationButtons_add-element-buttons__eRSqz .EditingConfirmationButtons_add-button__amTTU {
  padding: 3px;
}
.EditingConfirmationButtons_add-element-buttons__eRSqz .EditingConfirmationButtons_close-button__5GBmZ {
  border-color: red;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/MarkerObjectsList/EditingConfirmationButtons/EditingConfirmationButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EAEI,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;AAAR;AAEQ;EACI,oCAAA;AAAZ;AAGQ;EACI,oCAAA;AADZ;AAKI;EACI,YAAA;AAHR;AAMI;EACI,iBAAA;AAJR","sourcesContent":[".add-element-buttons {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    button {\n        // padding: 2px;\n        display: inline-flex;\n        align-items: center;\n        justify-content: center;\n        margin: 5px 10px;\n        border-radius: 30px;\n        border: none;\n        background-color: transparent;\n        height: 30px;\n        width: 30px;\n        border: 3px solid green;\n\n        &:hover {\n            background-color: rgb(230, 230, 230);\n        }\n\n        &:active {\n            background-color: rgb(200, 200, 200);\n        }\n    }\n\n    .add-button {\n        padding: 3px;\n    }\n\n    .close-button {\n        border-color: red;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-element-buttons": `EditingConfirmationButtons_add-element-buttons__eRSqz`,
	"add-button": `EditingConfirmationButtons_add-button__amTTU`,
	"close-button": `EditingConfirmationButtons_close-button__5GBmZ`
};
export default ___CSS_LOADER_EXPORT___;
