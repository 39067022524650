import React, { useCallback } from 'react'
import CustomButton from 'Components/CustomButton/CustomButton'
import { useForm, SubmitHandler } from 'react-hook-form'
import TextInput from 'Components/forms/TextInput'
import { TSignInForm } from 'types/shared'
import { Link } from 'react-router-dom'
import { useLogin } from 'hooks/auth/login'
import { useGoogleAuth } from '../../hooks/auth/google-oauth'

const SignInForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TSignInForm>()

  const handleLogin = useLogin()
  const { initializeGoogleAuth } = useGoogleAuth()
  const onSubmit: SubmitHandler<TSignInForm> = data => {
    handleLogin?.(data)
  }
  const handleGoogleAuth = useCallback(() => {
    initializeGoogleAuth?.()
  }, [])

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='flex items-center justify-center h-screen p-20'>
        <div className='flex flex-col space-y-4 shadow-xl p-3 md:w-[350px]'>
          <h1 className='text-3xl text-center'>Sign In</h1>
          <small className='text-center'>With a third party</small>
          <div
            className='flex justify-center items-center space-x-3 bg-red-500 p-2 cursor-pointer'
            onClick={handleGoogleAuth}
          >
            <img src='/images/google-icon.png' alt='Google Icon'></img>
            <small className='uppercase text-white'>Sign In with Google</small>
          </div>
          <div className='border-b w-full text-center relative'>
            <span className='absolute -translate-y-3 -translate-x-3	 bg-white w-8 h-8'>
              OR
            </span>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col space-y-4 '
          >
            <TextInput
              register={register}
              inputName='username'
              errors={errors}
              placeholder='Username'
              inputLabel='Username:'
              emptyErrorMessage='Username is required'
            />
            <TextInput
              formType='password'
              register={register}
              inputName='password'
              errors={errors}
              placeholder='Password'
              inputLabel='Password:'
              emptyErrorMessage='Password is required'
            />
            <CustomButton buttonType='submit' variant='primary'>
              Log In
            </CustomButton>
          </form>
          <div className='text-center text-xs '>
            <Link to='/password/reset/'>Forgot Password?</Link>/
            <Link to='/accounts/signup/'>Sign Up</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignInForm