import React from "react";

import styles from "./LocationMarkerPopup.module.scss";
import { LocationData, MinimalLocationData } from "../../HomePageTypes";
import { DispatchAction } from "../../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import PopupLoadingSpinner from "../../PopupLoadingSpinner/PopupLoadingSpinner";
import PopupLoadingError from "../../PopupLoadingError/PopupLoadingError";
import { FullLocationPopupContent } from "./FullLocationPopupContent";
import { MarkerConsentMessage } from "../../MarkerConsentMessage/MarkerConsentMessage";
import { isNotVisible } from "../../visibility";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import {handleLocationConsentRequest} from "./handleLocationConsentRequest";

export function LocationMarkerPopup({
    location,
    state,
    dispatch,
}: {
    location: MinimalLocationData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullLocation, setFullLocation] = useApi<LocationData>(
        apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(location.handle)
    );

    let { postToApi } = usePostToAPI();

    const updateLocation = (newLocation: MinimalLocationData) => {
        if (fullLocation !== null && !(fullLocation instanceof ApiError)) {
            setFullLocation({ ...fullLocation, ...newLocation } as LocationData);
        }
        dispatch({ type: ACTIONS.UPDATE_LOCATION, location: newLocation });
    };

    return (
        <div
            className={`${styles["location-popup-content"]} `}
        >
            {isNotVisible(location.visibility) ? (
                <MarkerConsentMessage
                    visibility={location.visibility}
                    ownerName={location.ownerName}
                    contactOrLocation="location"
                    handleConsentRequest={() =>
                        handleLocationConsentRequest(
                            updateLocation,
                            location,
                            postToApi
                        )
                    }
                />
            ) : fullLocation === null ? (
                <PopupLoadingSpinner />
            ) : fullLocation instanceof ApiError ? (
                <PopupLoadingError error={fullLocation} />
            ) : (
                <FullLocationPopupContent
                    location={fullLocation}
                    setLocation={setFullLocation}
                    dispatch={dispatch}
                    state={state}
                />
            )}
        </div>
    );
}
