import React, { useState } from "react";

import styles from "./ShowMyLocation.module.scss";
import { FaMapMarkerAlt as MyCurrentLocation } from "react-icons/fa";
import { Point } from "../../HomePageTypes";

export type GpsLocation = Point & { accuracyRadius: number };

export function ShowMyLocation({
  updateLocation,
}: {
  updateLocation: (location: GpsLocation | null, firstTime: boolean) => void;
}) {
  let [watchId, setWatchId] = useState<number | null>(null);

  const onClick = () => {
    let firstTime = true;

    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
      updateLocation(null, firstTime);
    } else {
      setWatchId(
        navigator.geolocation.watchPosition(
          (result) => {
            updateLocation(
              {
                x: result.coords.longitude,
                y: result.coords.latitude,
                accuracyRadius: result.coords.accuracy,
              },
              firstTime
            );

            if (firstTime) {
              firstTime = false;
            }
          },
          (error) => {},
          { enableHighAccuracy: true }
        )
      );
    }
  };

  return (
    <button
      className={`${styles["my-current-location"]} ${
        watchId !== null ? styles["loading"] : ""
      }`}
      onClick={onClick}
    >
      <MyCurrentLocation />
    </button>
  );
}
