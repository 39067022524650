import React, { useEffect, useState } from "react";
import styles from "./SocialMediaLinks.module.scss";
import {
    FaFacebook,
    FaInstagram,
    FaTwitter,
    FaLinkedin,
    FaGlobe,
} from "react-icons/fa";

type DeviceOrientationValues = {
    alpha: number | null;
    beta: number | null;
    gamma: number | null;
};

export default function SocialMediaLinks({
    facebook,
    instagram,
    twitter,
    youtube,
    linkedin,
    website,
    horizontalAlignment,
}: {
    facebook: string;
    instagram: string;
    twitter: string;
    youtube: string;
    linkedin: string;
    website?: string;
    horizontalAlignment?: "center" | "left" | "right";
}) {
    const [deviceOrientation, setDeviceOrientation] =
        useState<DeviceOrientationValues>({
            alpha: 0,
            beta: 0,
            gamma: 0,
        });

    useEffect(() => {
        const handleDeviceOrientation = (event: DeviceOrientationEvent) =>
            setDeviceOrientation({
                alpha: event.alpha,
                beta: event.beta,
                gamma: event.gamma,
            });

        window.addEventListener("deviceorientation", handleDeviceOrientation);

        return () => {
            window.removeEventListener(
                "deviceorientation",
                handleDeviceOrientation
            );
        };
    }, []);

    const iconRotationStyle = {
        transform: `rotateZ(${deviceOrientation.alpha}deg)`,
    };

    return (
        <div
            className={styles["social-media-links"]}
            style={{
                justifyContent:
                    horizontalAlignment === "left"
                        ? "flex-start"
                        : horizontalAlignment === "right"
                        ? "flex-end"
                        : "center",
            }}
        >
            {website ? (
                <span>
                    <a
                        href={website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaGlobe />
                    </a>
                </span>
            ) : null}
            {facebook ? (
                <span>
                    <a
                        href={facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaFacebook />
                    </a>
                </span>
            ) : null}
            {instagram ? (
                <span>
                    <a
                        href={instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaInstagram />
                    </a>
                </span>
            ) : null}
            {twitter ? (
                <span>
                    <a
                        href={twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaTwitter />
                    </a>
                </span>
            ) : null}
            {youtube ? (
                <span>
                    <a
                        href={youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaLinkedin />
                    </a>
                </span>
            ) : null}
            {linkedin ? (
                <span>
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={iconRotationStyle}
                    >
                        <FaLinkedin />
                    </a>
                </span>
            ) : null}
        </div>
    );
}
