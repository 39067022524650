import React from 'react';
import { Link } from 'react-router-dom';

import styles from './NotFound.module.scss';
import { useDocumentTitle } from 'hooks/useSEO';

/**
 * A 404 page
 */
export default function NotFound() {
	useDocumentTitle('404 - Page Not Found');

	return (
		<main className={styles['not-found-page']}>
			<div>
				<h1>404</h1>
				<p>
					There was nothing found at this url. You may go back to{' '}
					<Link to="/">the homepage</Link>
				</p>
			</div>
		</main>
	);
}
