// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationMarkerPopup_location-popup-content__unOCI {
  font-family: var(--hand-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  padding-bottom: 5px;
}
.LocationMarkerPopup_location-popup-content__unOCI a:nth-child(1) {
  text-decoration: none;
}
@media screen and (max-height: 600px) {
  .LocationMarkerPopup_location-popup-content__unOCI.LocationMarkerPopup_adding-object__yeEBc .LocationMarkerPopup_description__uh4VJ,
  .LocationMarkerPopup_location-popup-content__unOCI.LocationMarkerPopup_adding-object__yeEBc .LocationMarkerPopup_heading__Zj7cw,
  .LocationMarkerPopup_location-popup-content__unOCI.LocationMarkerPopup_adding-object__yeEBc .LocationMarkerPopup_location-images__8jtjW,
  .LocationMarkerPopup_location-popup-content__unOCI.LocationMarkerPopup_adding-object__yeEBc .LocationMarkerPopup_email-and-number__Y4GCh {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/LocationMarker/LocationMarkerPopup/LocationMarkerPopup.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACI;EACI,qBAAA;AACR;AAGI;EAGQ;;;;IAII,aAAA;EAHd;AACF","sourcesContent":[".location-popup-content {\n    font-family: var(--hand-font);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    min-width: 200px;\n    padding-bottom: 5px;\n\n    a:nth-child(1) {\n        text-decoration: none;\n    }\n\n\n    @media screen and (max-height: 600px) {\n        &.adding-object {\n\n            .description,\n            .heading,\n            .location-images,\n            .email-and-number {\n                display: none;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location-popup-content": `LocationMarkerPopup_location-popup-content__unOCI`,
	"adding-object": `LocationMarkerPopup_adding-object__yeEBc`,
	"description": `LocationMarkerPopup_description__uh4VJ`,
	"heading": `LocationMarkerPopup_heading__Zj7cw`,
	"location-images": `LocationMarkerPopup_location-images__8jtjW`,
	"email-and-number": `LocationMarkerPopup_email-and-number__Y4GCh`
};
export default ___CSS_LOADER_EXPORT___;
