import React, { useState, useEffect, useRef } from 'react';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';
import EditAddress from './EditAddress';
import DeleteAddress from './DeleteAddress';
import styles from './AddressList.module.scss';
import AddAddress from './AddAddress'; // Adjust the import path as needed

interface Address {
  id: number;
  formatted_address: string;
  street_number: string;
  street_name: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  lat: number;
  lng: number;
  object_id: number;
  created_date: string;
  address_type: string;
}

interface AddressListProps {
  entityType: 'user' | 'place' | 'contact';
  entityId: number;
}

const AddressList: React.FC<AddressListProps> = ({ entityType, entityId }) => {
  const [expandedObjectId, setExpandedObjectId] = useState<number | null>(null);
  const [objects, setObjects] = useState<Address[]>([]);
  const [feedback, setFeedback] = useState<string>('');
  const [editObjectId, setEditObjectId] = useState<number | null>(null);
  const privateAPIInstance = useAxiosPrivate();
  const [newAddress, setNewAddress] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const fetchObjects = async () => {
    try {
      let response;
      if (entityType === 'user') {
        response = await privateAPIInstance.get(apiRoutes.GET_USER_ADDRESSES(entityId));
      } else if (entityType === 'place') {
        response = await privateAPIInstance.get(apiRoutes.GET_PLACE_ADDRESSES(entityId));
      } else if (entityType === 'contact') {
        response = await privateAPIInstance.get(apiRoutes.GET_CONTACT_ADDRESSES(entityId));
      }

      if (response && response.data) {
        setObjects(response.data.data);
        setFeedback(response.data.message);
      } else {
        setFeedback('No response from server.');
      }
    } catch (error) {
      setFeedback('Error fetching addresses.');
    }
  };

  const refreshObjects = () => {
    fetchObjects();
  };

  useEffect(() => {
    fetchObjects();
  }, [entityType, entityId, privateAPIInstance]);


  const handleObjectClick = (id: number) => {
    if (editObjectId) {
      setEditObjectId(null); // Exit edit mode if any address is in edit mode
    } else {
      setExpandedObjectId(expandedObjectId === id ? null : id);
    }
  };

  const handleEditInit = (id: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setEditObjectId(id);
  };

  // Add new Experience 


  const handleAddNewExperience = async () => {
    try {
      const response = await privateAPIInstance.post(apiRoutes.CREATE_NEW_ADDRESS, {
        formatted_address: entityType,
        object_id: entityId,
        company_name: newAddress,
      });

      if (response && response.data) {
        refreshObjects();
        setNewAddress('');
      }
    } catch (error) {
      console.error('Error creating new experience:', error);
    }
  };


  const excludedFields = ['id', 'lat', 'lng', 'object_id', 'content_type', 'created_date'];

  return (
    <div>
            {/* #TODO Pass feedback to mapbond own implementtion of notifications */}
      {/* {feedback && <p className={styles.feedbackMessage}>{feedback}</p>} */}
      {objects.length === 0 ? (
        <AddAddress 
        entityType={entityType}
        entityId={entityId}
        onAddressAdded={refreshObjects}
      />
      
      ) : (
      objects.map((obj) => (
        <div key={obj.id} className={styles.addressContainer}>
          <div className={styles.groupedIn}>
            <div className={styles.label}>🌎 </div>
            <div onClick={() => handleObjectClick(obj.id)}>{obj.formatted_address}</div>
          </div>
          {expandedObjectId === obj.id && !editObjectId && (
            <>
              {Object.entries(obj).map(([key, value]) => {
                if (excludedFields.includes(key) || key === 'formatted_address') return null;
                return (
                  <div className={styles.groupedIn} key={key}>
                    <div className={styles.label}>{key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}: </div>
                    <div onClick={(e) => handleEditInit(obj.id, e)}>{value}</div>
                  </div>
                );
              })}
              <button onClick={(e) => handleEditInit(obj.id, e)}>Edit</button>
            </>
          )}
          {editObjectId === obj.id && (
            <>
              <EditAddress data={obj} onObjectUpdated={refreshObjects} />
              <DeleteAddress dataId={obj.id} onObjectDeleted={refreshObjects} />
            </>
          )}
        </div>
      ))
      )}
    </div>
  );
};



export default AddressList;
