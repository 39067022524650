import { Circle } from "react-leaflet";

import styles from "./MyGpsPosition.module.scss";

import { HomePageState } from "Pages/Home/HomePageState";


export function MyGpsPosition({ state }: { state: HomePageState }) {
  return state.gpsPosition ? (
    <>
      {/* A circle encompassing the accuracy range of the GPS */}
      <Circle
        center={[state.gpsPosition.y, state.gpsPosition.x]}
        color="transparent"
        radius={state.gpsPosition.accuracyRadius}
        fillColor="var(--accent-color)"
        fillOpacity={0.2}
        stroke={false}
        weight={0}
        className={styles["accuracy"]}
      />
      {/* A border of the central dot of the user's location */}
      <Circle
        center={[state.gpsPosition.y, state.gpsPosition.x]}
        color="white"
        radius={0}
        stroke
        weight={13}
      />
      {/* The dot showing the user's location */}
      <Circle
        center={[state.gpsPosition.y, state.gpsPosition.x]}
        color="var(--accent-color)"
        radius={0}
        stroke
        weight={10}
      />
    </>
  ) : null;
}
