import React, { useState } from "react";

import styles from "./ObjectsBeingTaken.module.scss";
import AutocompleteInput from "Components/AutocompleteInput/AutocompleteInput";
import { CloseIcon } from "Icons/CloseIcon";

import { RiDeleteBinLine } from "react-icons/ri";
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import { BasicTravelData, TakenObject } from "Pages/Home/HomePageTypes";

import AddIcon from "Icons/AddIcon";
import { useAutocompleteSuggestions } from "hooks/useAutoCompleteSuggestions";
import { usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export function ObjectsBeingTaken({
    travelPlan,
    objectsBeingTaken,
    setObjectsBeingTaken,
    isExistingTravelPlan,
}: {
    travelPlan: BasicTravelData;
    objectsBeingTaken: Array<TakenObject>;
    setObjectsBeingTaken: (objects: Array<TakenObject>) => void;
    isExistingTravelPlan: boolean;
}) {
    const [objectBeingAdded, setObjectBeingAdded] = useState<string | null>(
        null
    );

    let autocompleteSuggestions = useAutocompleteSuggestions(
        apiRoutes.GET_AUTOCOMPLETE("personal-objects"),
        objectBeingAdded || ""
    );
    let { postToApi } = usePostToAPI();

    const addNewObject = (value: string): void => {
        setObjectBeingAdded(null);
        if (
            !objectsBeingTaken
                .map((obj) => obj.name.trim())
                .includes(value.trim())
        ) {
            if (isExistingTravelPlan) {
                postToApi<TakenObject>(
                    apiRoutes.ADD_TRAVEL_PLAN_OBJECT(travelPlan.id as number),
                    {
                        object: value,
                    }
                ).then((value) => {
                    if (value.ok) {
                        setObjectsBeingTaken([
                            ...objectsBeingTaken,
                            value.data,
                        ]);
                    }
                });
            } else {
                setObjectsBeingTaken([
                    ...objectsBeingTaken,
                    { name: value, privacy: "Private" },
                ]);
            }
        }
    };

    const deleteObject = (object: TakenObject) => {
        if (isExistingTravelPlan) {
            postToApi(
                apiRoutes.UPDATE_OR_DELETE_TRAVEL_PLAN_OBJECT(
                    travelPlan.id as number,
                    object.id as number
                ),
                undefined,
                "delete"
            );
        }
        setObjectsBeingTaken(
            objectsBeingTaken.filter((obj) => obj.name !== object.name)
        );
    };

    const setObjectPrivacy = (
        value: "Private" | "Connections",
        takenObject: TakenObject
    ) => {
        if (isExistingTravelPlan) {
            postToApi(
                apiRoutes.UPDATE_OR_DELETE_TRAVEL_PLAN_OBJECT(
                    travelPlan.id as number,
                    takenObject.id as number
                ),
                {
                    objectName: takenObject.name,
                    privacyLevel: value,
                }
            );
        }
        setObjectsBeingTaken(
            objectsBeingTaken.map((object) =>
                object.name === takenObject.name
                    ? { ...object, privacy: value }
                    : object
            )
        );
    };

    return (
        <div className={styles["taken-objects"]}>
            <h6>
                Objects you will take{" "}
                {objectBeingAdded === null && (
                    <button
                        className={styles["add-button"]}
                        onClick={() => setObjectBeingAdded("")}
                    >
                        <AddIcon
                            lineLength={12}
                            lineWidth={2}
                            color="var(--accent-color)"
                        />
                    </button>
                )}
            </h6>
            <ul>
                {objectsBeingTaken.map((obj) => (
                    <li key={`${obj.name}:${obj.id}`}>
                        <ObjectBeingTaken
                            takenObject={obj}
                            setObjectPrivacy={setObjectPrivacy}
                            deleteObject={deleteObject}
                        />
                    </li>
                ))}
                {objectBeingAdded !== null ? (
                    <li>
                        <AutocompleteInput
                            autoFocus
                            value={objectBeingAdded}
                            setValue={setObjectBeingAdded}
                            autocompleteSuggestions={
                                autocompleteSuggestions.length > 0
                                    ? autocompleteSuggestions.filter(
                                          (suggestion) =>
                                              !objectsBeingTaken
                                                  .map((obj) =>
                                                      obj.name
                                                          .toLowerCase()
                                                          .trim()
                                                  )
                                                  .includes(
                                                      suggestion
                                                          .toLowerCase()
                                                          .trim()
                                                  )
                                      )
                                    : []
                            }
                            submitValue={addNewObject}
                        />{" "}
                        <button onClick={() => setObjectBeingAdded(null)}>
                            <CloseIcon
                                color="red"
                                lineLength={14}
                                lineWidth={2}
                            />
                        </button>
                    </li>
                ) : null}
            </ul>
        </div>
    );
}

function ObjectBeingTaken({
    takenObject,
    deleteObject,
    setObjectPrivacy,
}: {
    takenObject: TakenObject;
    deleteObject: (object: TakenObject) => void;
    setObjectPrivacy: (
        value: "Private" | "Connections",
        takenObject: TakenObject
    ) => void;
}) {
    return (
        <div>
            {takenObject.name}{" "}
            <button
                className={styles["delete-button"]}
                onClick={() => deleteObject(takenObject)}
            >
                <RiDeleteBinLine />
            </button>{" "}
            <CustomDropdown
                items={["Private", "Connections"]}
                onChange={(value) => setObjectPrivacy(value, takenObject)}
                selected={takenObject.privacy}
            />
        </div>
    );
}
