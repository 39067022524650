// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelect_custom-select__UaxJQ {
  display: flex;
}
.CustomSelect_custom-select__UaxJQ div {
  padding: 5px 20px;
  margin: 1px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomSelect/CustomSelect.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AACR","sourcesContent":[".custom-select {\n    display: flex;\n\n    div {\n        padding: 5px 20px;\n        margin: 1px;\n        font-size: 0.8em;\n        cursor: pointer;\n        border-radius: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-select": `CustomSelect_custom-select__UaxJQ`
};
export default ___CSS_LOADER_EXPORT___;
