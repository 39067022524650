import React, { useEffect, useState } from "react";

import styles from "./ObjectImages.module.scss";
import { FaEye } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { OwnedObject } from "../../Pages/Home/HomePageTypes";
import { loadImage } from "../../Utils/fileLoading";
import SmallLoadingSpinner from "../SmallLoadingSpinner/SmallLoadingSpinner";

/**
 * Used to display images of "objects", or rather physical
 * items, owned by someone or found at a specific place
 */
export function ObjectImages({
  object,
  uploadingImage,
  deleteImage,
  addNewImage,
}: {
  object: OwnedObject;
  /** Controls whether to display a loading spinner or not */
  uploadingImage: boolean;
  deleteImage?: (imageId: number, objectId: number) => void;
  addNewImage?: (imageFile: File, dataUrl: string) => void;
}) {
  /** Which image has currently been opened by the user */
  let [openImage, setOpenImage] = useState<null | number>(null);

  useEffect(() => {
    let closeImage = () => setOpenImage(null);
    document.addEventListener("click", closeImage);
    return () => document.removeEventListener("click", closeImage);
  }, []);

  return (
    <div className={styles["object-pictures"]}>
      {object.pictures.map((value) => (
        <ObjectImage
          value={value}
          isOpen={openImage === value.id}
          openImage={() => setOpenImage(value.id)}
          onDeleteImage={
            deleteImage ? () => deleteImage(value.id, object.id) : undefined
          }
        />
      ))}
      {uploadingImage ? <SmallLoadingSpinner linethickNess="3px" /> : null}
      {addNewImage ? (
        <button
          className={styles["add-button"]}
          onClick={() => loadImage(addNewImage)}
        >
          <AiOutlinePlus />
        </button>
      ) : null}
    </div>
  );
}

/**
 * A single image displayed within a list of images for an object
 */
function ObjectImage({
  value,
  isOpen,
  openImage,
  onDeleteImage,
}: {
  value: {
    id: number;
    /** Displayed on the object itself */
    smallImageUrl: string;
    /** Attached to a hyperlink, so that the user can view the full image */
    largeImageUrl: string;
  };
  isOpen: boolean;
  openImage: () => void;
  onDeleteImage?: () => void;
}) {
  return (
    <div
      onClick={(e) => {
        if (!isOpen) {
          e.stopPropagation();
          openImage();
        }
      }}
      className={
        styles["object-picture"] + " " + (isOpen ? styles["image-opened"] : "")
      }
      style={{ backgroundImage: `url(${value.smallImageUrl})` }}
    >
      <a href={value.largeImageUrl}>
        <FaEye />
      </a>
      {onDeleteImage ? (
        <button onClick={onDeleteImage}>
          <RiDeleteBinLine />
        </button>
      ) : null}
    </div>
  );
}
