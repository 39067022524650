import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ContextMenuLoadingSpinner from 'Components/ContextMenu/ContextMenuLoadingSpinner/ContextMenuLoadingSpinner';
import { calculateDaysUntilContact } from './calculateDaysUntilContact';

export interface Data {
  id?: string;
  type?: string;
  name: string;
  reason_to_contact: string;
  profile_photo: string;
  contact_timestamp: string;
  minimum_time_to_contact: string;
  maximum_time_to_contact: string;
  country: string;
  city: string;
  min_calculated_days_until_contact?: number; // Add this new property
  max_calculated_days_until_contact?: number; // Add this new property
}

interface DataProps {
  data: Data[] | null; // Use Data[] instead of the custom object structure
  loading: boolean;
}
enum DataKey {
  NAME = 'name',
  MIN_DAYS = 'min_calculated_days_until_contact',
  MAX_DAYS = 'max_calculated_days_until_contact',
  REASON = 'reason_to_contact',
  MIN_TIME = 'minimum_time_to_contact',
  MAX_TIME = 'maximum_time_to_contact',
  COUNTRY = 'country',
  CITY = 'city',
  PHOTO = 'profile_photo',
  TIMESTAMP = 'contact_timestamp'
}


const DataTable: React.FC<DataProps> = ({ data, loading }) => {
  const [filter, setFilter] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Data, direction: 'asc' | 'desc' | null }>({ key: DataKey.MIN_DAYS, direction: 'asc' });

  const filteredData = useMemo(() => {
    return (data || []).filter(item => {
      // Adjust this filter logic to include all relevant fields
      return Object.keys(item).some(key => {
        const value = item[key as keyof Data];
        return value ? value.toString().toLowerCase().includes(filter.toLowerCase()) : false;
      });
    });
  }, [data, filter]);

  const getSortIndicator = (key: keyof Data) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' 🔼' : ' 🔽';
    }
    return '';
  };

  const getSortableValue = (item: Data, key: keyof Data) => {
    switch (key) {
      case DataKey.NAME:
      case DataKey.REASON:
      case DataKey.PHOTO:
      case DataKey.TIMESTAMP:
      case DataKey.MIN_TIME:
      case DataKey.MAX_TIME:
        return item[key] || '';
      case DataKey.MIN_DAYS:
      case DataKey.MAX_DAYS:
        return item[key] || 0;
      default:
        return '';
    }
  }

  const sortedData = useMemo(() => {
    if (!sortConfig.key || sortConfig.direction === null) return filteredData;
  
    return [...filteredData].sort((a, b) => {
      const aValue = getSortableValue(a, sortConfig.key);
      const bValue = getSortableValue(b, sortConfig.key);
  
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const requestSort = (key: keyof Data) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc'; // Reset the direction if the same column is clicked thrice
    }
    setSortConfig({ key, direction });
  };
  

  if (sortedData.length === 0) {
    return <div>No profiles to display</div>;
  }

  if (loading) {
    return <ContextMenuLoadingSpinner />;
  }

  if (!data) {
    return <div>No data available</div>; // Handle null data case
  }

  if (data.length === 0) {
    return <div>No profiles to display</div>;
  }

  return (
    <>
      <input
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Filter by any field"
        className="mb-4"
      />
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th onClick={() => requestSort(DataKey.NAME)}>Name{getSortIndicator(DataKey.NAME)}</th>
            <th onClick={() => requestSort(DataKey.MIN_DAYS)}>Days until min contact{getSortIndicator(DataKey.MIN_DAYS)}</th>
            <th onClick={() => requestSort(DataKey.MAX_DAYS)}>Max calculated{getSortIndicator(DataKey.MAX_DAYS)}</th>
            <th onClick={() => requestSort(DataKey.REASON)}>Reason{getSortIndicator(DataKey.REASON)}</th>
            <th onClick={() => requestSort(DataKey.MIN_TIME)}>Min time to contact{getSortIndicator(DataKey.MIN_TIME)}</th>
            <th onClick={() => requestSort(DataKey.MAX_TIME)}>Max time to contact{getSortIndicator(DataKey.MAX_TIME)}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item: Data, index: number) => (
            <tr key={index}>
              {item.profile_photo ? (
                <td>
                  <img src={item.profile_photo} alt={item.name} />
                  <Link to={"/@" + item.id}>{item.name}</Link>
                </td>
              ) : (
                <td>
                  <Link to={"/@" + item.id}>{item.name}</Link>
                </td>
              )}
              <td>{
                item.min_calculated_days_until_contact = calculateDaysUntilContact(
                  item.contact_timestamp,
                  item.minimum_time_to_contact
                )}</td>
              <td>{
                item.max_calculated_days_until_contact = calculateDaysUntilContact(
                  item.contact_timestamp,
                  item.maximum_time_to_contact
                )}</td>
              <td>{item.reason_to_contact}</td>
              <td>{item.minimum_time_to_contact}</td>
              <td>{item.maximum_time_to_contact}</td>
            
            </tr>
          ))}

        </tbody>
      </table>
    </>

  );
};

export default DataTable;
