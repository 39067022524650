import React, { useEffect } from 'react';
import { BasicNetworkData } from './BasicNetworkDataTypes'; // Adjust import path as necessary
import { apiRoutes } from 'services/routes'; // Adjust import path as necessary

interface NetworkDataFetcherProps {
    network: BasicNetworkData;
    apiInstance: any; // Replace 'any' with the specific Axios instance type
    modalOpen: boolean;
    setUserMemberData: React.Dispatch<any>; // Replace 'any' with the specific type
}

const NetworkDataFetcher: React.FC<NetworkDataFetcherProps> = ({
    network,
    apiInstance,
    modalOpen,
    setUserMemberData
}) => {
    const getUserNetworkAddData = () => {
      console.log("[NetworkDataFetcher] getUserNetworkAddData Called");
        let apiURL: string;
        if (modalOpen) {
            apiURL = `${apiRoutes.GET_NETWORK_MEMBER_SUGGESTIONS}?network_id=${network.id}`;
        } else {
            apiURL = apiRoutes.GET_NETWORK_MEMBER_SUGGESTIONS;
        }
        apiInstance.get(apiURL)
            .then(async (response: any) => {
                let res = await response.data?.data;
                console.log(res.response);
                response.status >= 300
                    ? console.log(response)
                    : setUserMemberData({
                        contacts: res.user_contacts,
                        users: res.user_connections,
                        location: res.user_locations,
                    });
            })
            .catch((reason: any) => {
                console.log(reason);
            });
    };

    useEffect(() => {
        getUserNetworkAddData();
    }, [network.id, modalOpen]); // Ensure useEffect is correctly triggered

    return null; // This component doesn't render anything
};

export default NetworkDataFetcher;
