// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationImageViewer_images__AFpwX {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LocationImageViewer_images__AFpwX button {
  position: absolute;
  font-weight: 700;
  font-size: 1.4em;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 25px;
  height: 45px;
  width: 45px;
  box-shadow: 0 0 2.5px 2.5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
}
.LocationImageViewer_images__AFpwX button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.LocationImageViewer_images__AFpwX button:active {
  background-color: rgba(0, 0, 0, 0.65);
}
.LocationImageViewer_images__AFpwX button:nth-child(1) {
  align-self: flex-start;
}
.LocationImageViewer_images__AFpwX button:nth-child(3) {
  align-self: flex-end;
}
.LocationImageViewer_images__AFpwX a {
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LocationImageViewer_images__AFpwX a img {
  max-height: 400px;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LocationProfile/LocationImageViewer/LocationImageViewer.module.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACH;AACG;EACG,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,8CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AACN;AAGG;EACG,oCAAA;AADN;AAIG;EACG,qCAAA;AAFN;AAKG;EACG,sBAAA;AAHN;AAMG;EACG,oBAAA;AAJN;AAOG;EACG,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AALN;AAOM;EACG,iBAAA;EACA,eAAA;AALT","sourcesContent":[".images {\n   width: 100%;\n   height: fit-content;\n   position: relative;\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: center;\n\n   button {\n      position: absolute;\n      font-weight: 700;\n      font-size: 1.4em;\n      color: white;\n      background-color: rgba(0, 0, 0, 0.7);\n      border: none;\n      border-radius: 25px;\n      height: 45px;\n      width: 45px;\n      box-shadow: 0 0 2.5px 2.5px rgba(0, 0, 0, 0.2);\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      justify-self: center;\n   }\n\n\n   button:hover {\n      background-color: rgba(0, 0, 0, 0.6);\n   }\n\n   button:active {\n      background-color: rgba(0, 0, 0, 0.65);\n   }\n\n   button:nth-child(1) {\n      align-self: flex-start;\n   }\n\n   button:nth-child(3) {\n      align-self: flex-end;\n   }\n\n   a {\n      width: fit-content;\n      height: 100%;\n      background-size: contain;\n      background-repeat: no-repeat;\n      background-position: center;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n\n      img {\n         max-height: 400px;\n         max-width: 100%;\n      }\n   }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"images": `LocationImageViewer_images__AFpwX`
};
export default ___CSS_LOADER_EXPORT___;
