import { toast, ToastOptions } from "react-toastify";

export default function Alert(
    type: "error" | "success" | "info" | "warning" | "default",
    content: string
) {
    const options: ToastOptions = {
        theme: "light",
        position: "top-right",
        autoClose: 3000,
    };
    switch (type) {
        case "error":
            return toast.error(content, { ...options });
        case "success":
            return toast.success(content, { ...options });
        default:
            return toast(content, { ...options, type });
    }
}
