import { useState } from 'react';
import { ContactItem, UserItem, LocationItem } from './MemberItems';
import {
	MinimalContactData,
	ForNetworkContactData,
	MinimalLocationData,
	MinimalUserData,
} from 'Pages/Home/HomePageTypes';
import { BasicNetworkData } from '../NetworkGroups/BasicNetworkDataTypes';
import {NetworkSummary} from 'Components/NetworkAdd/NetworkSummary/NetworkSummary';
import {SearchBar} from 'Components/NetworkAdd/SearchBar/SearchBar';

type Props = {
	contacts: ForNetworkContactData[];
	users: MinimalUserData[];
	locations: MinimalLocationData[];
	networkData: BasicNetworkData;
	setNetworkData: (network: BasicNetworkData) => void;
	// onAddContacts: (selectedContacts: (MinimalContactData | MinimalUserData | MinimalLocationData)[]) => void
};
type MemberType = 'member_contacts' | 'member_connections' | 'member_locations';
type TitleType = 'Contacts' | 'Connections' | 'Locations';

const memberTypeMap: Record<'Contacts' | 'Connections' | 'Locations', MemberType> = {
	'Contacts': 'member_contacts',
	'Connections': 'member_connections',
	'Locations': 'member_locations',
};

type MemberData = MinimalUserData | MinimalLocationData | MinimalContactData;

const updateNetworkData = (
  member: MemberData,
  memberType: MemberType,
  networkData: BasicNetworkData,
  setNetworkData: (network: BasicNetworkData) => void
) => {
  // Extract the specific array based on memberType
  let currentData: MemberData[] = [];
  switch (memberType) {
    case 'member_contacts':
      currentData = networkData.member_contacts as MinimalContactData[];
      break;
    case 'member_connections':
      currentData = networkData.member_connections as MinimalUserData[];
      break;
    case 'member_locations':
      currentData = networkData.member_locations as MinimalLocationData[];
      break;
  }

  const index = currentData.findIndex((m: MemberData) => m.id === member.id);

  if (index !== -1) {
    setNetworkData({
      ...networkData,
      [memberType]: currentData.filter((m: MemberData) => m.id !== member.id),
    });
  } else {
    setNetworkData({
      ...networkData,
      [memberType]: [...currentData, member],
    });
  }
};

function filterByNameOrDescription<T extends { name?: string | null; description?: string | null }>(
  items: T[], 
  query: string
): T[] {
  const lowerQuery = query.toLowerCase();
  return items.filter(item => 
    (item.name ? item.name.toLowerCase().includes(lowerQuery) : false) || 
    (item.description ? item.description.toLowerCase().includes(lowerQuery) : false)
  );
}


export const SelectableScroll = ({
	contacts,
	users,
	locations,
	networkData,
	setNetworkData,
}: Props) => {
	const [searchQuery, setSearchQuery] = useState<string>('');

	const [selectAll, setSelectAll] = useState({
		Contacts: false,
		Connections: false,
		Locations: false,
	});
	
	const handleSelectMember = (
		member: ForNetworkContactData | MinimalUserData | MinimalLocationData,
		type: 'Contacts' | 'Connections' | 'Locations'
	) => {
		const memberType = memberTypeMap[type] as MemberType;
		updateNetworkData(member, memberType, networkData, setNetworkData);
	
		// Find the corresponding item's data length for the given type
		const itemTypeData = items.find(i => i.title === type)?.data.length ?? 0;
		
		// Check if all members of this type are selected/deselected
		const isSelectedAll = networkData[memberType].length === (selectAll[type] ? 0 : itemTypeData);
		setSelectAll(prev => ({ ...prev, [type]: isSelectedAll }));
	};

	const handleSelectAll = (type: 'Contacts' | 'Connections' | 'Locations', isSelected: boolean) => {
		setSelectAll(prev => ({ ...prev, [type]: isSelected }));
		const memberType = memberTypeMap[type] as MemberType;
		let updatedMembers: MemberData[] = isSelected ? items.find(i => i.title === type)?.data || [] : [];
		setNetworkData({
			...networkData,
			[memberType]: updatedMembers,
		});
	};

		const filteredContacts = filterByNameOrDescription(contacts, searchQuery);
		const filteredUsers = filterByNameOrDescription(users, searchQuery);
		const filteredLocations = filterByNameOrDescription(locations, searchQuery);
	
		type Item = {
			title: TitleType;
			data: MemberData[];
			component: React.FC<any>; // You might want to use a more specific type here if possible
			selectedData: MemberData[];
		};
		
		const items: Item[] = [
			{
				title: 'Contacts',
				data: filteredContacts,
				component: ContactItem,
				selectedData: networkData.member_contacts,
			},
			{
				title: 'Connections',
				data: filteredUsers,
				component: UserItem,
				selectedData: networkData.member_connections,
			},
			{
				title: 'Locations',
				data: filteredLocations,
				component: LocationItem,
				selectedData: networkData.member_locations,
			},
		];

	return (
		<div className="flex flex-col h-full">
			<NetworkSummary
				networkData={networkData}
			/>

			<SearchBar
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				/>
				
			<div className="flex-1 overflow-y-auto">
				{items.map((item) => (
					<div key={item.title}>
						<div className="px-4 pt-4 pb-2 text-xs font-medium text-gray-500 uppercase">
							{item.title}
							<input
        type="checkbox"
        checked={selectAll[item.title]}
        onChange={(e) => handleSelectAll(item.title, e.target.checked)}
      		/>
						</div>
						<div className="space-y-2 overflow-y-auto h-32 border-solid border-2">
							{item.data.length > 0 ? (
								item.data.map((data) => (
									<item.component
										key={data.id}
										data={data}
										selected={item.selectedData.some((c) => c.id === data.id)}
										onSelect={handleSelectMember}
									/>
								))
							) : (
								<div className="px-4 py-2 text-sm text-gray-500">
									No {item.title.toLowerCase()} found.
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
