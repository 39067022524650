declare global {
	interface Window {
		Tawk_API: any;
		Tawk_LoadStart: any;
	}
}
export const TAWK_PROPERTY_ID = '63d9af3bc2f1ac1e2030b158';
export const TAWK_WIDGET_ID = '1go5590mv';

export const tawkToLoadScripts = async () => {
	// var Tawk_API = Tawk_API || {};

	// var Tawk_LoadStart = new Date();

	await (function () {
		var s1 = document.createElement('script'),
			s0: HTMLScriptElement = document.getElementsByTagName('script')[0];
		s1.async = true;
		s1.src = `https://embed.tawk.to/${TAWK_PROPERTY_ID}/${TAWK_WIDGET_ID}`;
		s1.charset = 'UTF-8';
		s1.setAttribute('crossorigin', '*');
		s0.parentNode?.insertBefore(s1, s0);
	})();
};
