// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TitleHeader.module.scss */
.TitleHeader_magic-title-container__NKpBl {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: TitleHeader_magicAppear__Rj2j7 1s ease-in-out;
}
@keyframes TitleHeader_magicAppear__Rj2j7 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.TitleHeader_title-header__SZlB6 {
  font-weight: bold;
  padding: 0.1rem;
  font-size: 1.3rem;
  text-align: center;
  color: var(--accent-color); /* Use a CSS variable for dynamic color */
  animation: TitleHeader_magicText__WHMJZ 1s ease-in-out;
}
@keyframes TitleHeader_magicText__WHMJZ {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/FrequncyKeepInTouch/TitleHeader.module.scss"],"names":[],"mappings":"AAAA,4BAAA;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,wDAAA;AAAF;AAEE;EACE;IACE,mBAAA;IACA,UAAA;EAAJ;EAEE;IACE,mBAAA;IACA,UAAA;EAAJ;AACF;;AAIA;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,0BAAA,EAAA,yCAAA;EACA,sDAAA;AADF;AAGE;EACE;IACE,4BAAA;IACA,UAAA;EADJ;EAGE;IACE,wBAAA;IACA,UAAA;EADJ;AACF","sourcesContent":["/* TitleHeader.module.scss */\n\n.magic-title-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  animation: magicAppear 1s ease-in-out;\n\n  @keyframes magicAppear {\n    0% {\n      transform: scale(0);\n      opacity: 0;\n    }\n    100% {\n      transform: scale(1);\n      opacity: 1;\n    }\n  }\n}\n\n.title-header {\n  font-weight: bold;\n  padding: 0.1rem;\n  font-size: 1.3rem;\n  text-align: center;\n  color: var(--accent-color); /* Use a CSS variable for dynamic color */\n  animation: magicText 1s ease-in-out;\n\n  @keyframes magicText {\n    0% {\n      transform: translateY(-20px);\n      opacity: 0;\n    }\n    100% {\n      transform: translateY(0);\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magic-title-container": `TitleHeader_magic-title-container__NKpBl`,
	"magicAppear": `TitleHeader_magicAppear__Rj2j7`,
	"title-header": `TitleHeader_title-header__SZlB6`,
	"magicText": `TitleHeader_magicText__WHMJZ`
};
export default ___CSS_LOADER_EXPORT___;
