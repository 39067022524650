import { Popup } from 'react-leaflet';
import React, { useState, useRef } from 'react';
import { LocationData, AddressParts } from '../../HomePageTypes';
import ConfirmButtons from '../../../../Components/ConfirmButtons/ConfirmButtons';
import { MarkerPrivacySetting } from '../../MarkerPrivacySetting/MarkerPrivacySetting';
import styles from '../LocationEditMarker.module.scss';
import Accordion from '../../../../Components/Accordion/Accordion'; 

import { useKeyboardNavigation } from './useKeyboardNavigation'; 

import AddressInput from '../../../../Components/AddressInput/AddressInput';
import LocationImagesControls from './LocationImagesControls/LocationImagesControls';
import LocationNameInput from './LocationNameInput/LocationNameInput';
import SocialLinksInput from './SocialLinksInput/SocialLinksInput';
import DescriptionAndContactInput from './DescriptionAndContactInput/DescriptionAndContactInput';

import AddressManager from 'Components/AddressManager/AddressManager';
import AddressList from 'Components/AddressManager/AddressList';


/**
 * The popup that appears when editing a location. This is a separate component
 * so that it can be used in the LocationProfile page as well.
 * @param props
 * @returns
 * @constructor
 * @param props.editingOrNew Whether the location is being edited or added.
 * @param props.locationData The location data.
 * @param props.setLocationData A function to set the location data.
 */
export function LocationEditMarkerPopup({
	editingOrNew,
	locationData,
	setLocationData,
	cancelEditing,
	finishEditing,
}: {
	editingOrNew: 'editing' | 'new' | null;
	locationData: LocationData;
	setLocationData: (data: LocationData | null) => void;
	cancelEditing: () => void;
	finishEditing: () => void;
}) {
	let [pictureIndex, setPictureIndex] = useState(0);
	// let selectedPhotos = useState<Array<{ file: File; dataUrl: string }>>([]);
	let [emptyNameWarning, setEmptyNameWarning] = useState(false);
	let [socialLinksOpen, setSocialLinksOpen] = useState(false); // New state to control accordion for social links
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null);
    const websiteRef = useRef(null);
    const facebookRef = useRef(null);
    const instagramRef = useRef(null);
    const youtubeRef = useRef(null);
    const twitterRef = useRef(null);
    const linkedinRef = useRef(null);


	const uploadChanges = () => {
		if (locationData.name) {
			finishEditing();
		} else {
			setEmptyNameWarning(true);
		}
	};

	const handleAddressUpdate = (updatedAddress: AddressParts) => {
    // Do something with updatedAddress
    console.log("Address updated:", updatedAddress);
  };

	useKeyboardNavigation(
		socialLinksOpen,
		setSocialLinksOpen,
		nameRef,
		descriptionRef,
		phoneNumberRef,
		emailRef,
		websiteRef,
		facebookRef,
		instagramRef,
		twitterRef,
		linkedinRef,
		youtubeRef
	);
    

	const updateLocationField =
		(
			field:
				| 'description'
				| 'name'
				| 'phoneNumber'
				| 'email'
				| 'facebook'
				| 'instagram'
				| 'twitter'
				| 'youtube'
				| 'linkedin'
				| 'website'
		) =>
		(event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) =>
			setLocationData({
				...locationData,
				[field]: event.currentTarget.value,
			});

			let placeId = locationData.id;

	return (
		<Popup>
			<div className={styles['edit-location-popup']}>
				{editingOrNew === 'editing' && (
					<LocationImagesControls
					locationData={locationData}
					setLocationData={setLocationData}
					pictureIndex={pictureIndex}
					setPictureIndex={setPictureIndex}
					/>
				)}

				<LocationNameInput
				locationData={locationData}
				nameRef={nameRef}
				emptyNameWarning={emptyNameWarning}
				updateLocationField={(field) => (event) => {
					if (field === 'name') {
					updateLocationField(field)(event);
					}
				}}
				/>

				<div className='address'>
				<AddressInput onUpdate={handleAddressUpdate} />

				</div>

				<AddressManager
					content_type='PLACE'
					object_id={placeId}
					/>
                    <AddressList 
					entityType='place'
					entityId={placeId}
					/>

				<DescriptionAndContactInput
					locationData={locationData}
					updateLocationField={updateLocationField}
					descriptionRef={descriptionRef}
					phoneNumberRef={phoneNumberRef}
					emailRef={emailRef}
				/>
				<Accordion
					isOpen={socialLinksOpen}
					onChange={(isOpen) => setSocialLinksOpen(isOpen)}
					chevronSize={24}
				>
				<div className={styles['accordion-heading']}>Social links</div>
				<div>
				<SocialLinksInput
					locationData={locationData}
					updateLocationField={updateLocationField}
					websiteRef={websiteRef}
					facebookRef={facebookRef}
					instagramRef={instagramRef}
					twitterRef={twitterRef}
					linkedinRef={linkedinRef}
					youtubeRef={youtubeRef}
					/>
				</div>
                    </Accordion>
				<MarkerPrivacySetting
					visibility={locationData.visibility}
					updateVisibility={(visibility) =>
						setLocationData({ ...locationData, visibility })
					}
					includePublic
				/>
				<ConfirmButtons
					onCancel={cancelEditing}
					onDoneEditing={uploadChanges}
				/>
			</div>
		</Popup>
	);
}
