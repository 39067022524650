import Papa from "papaparse";
import { Contact } from "../ImportContacts";

/**
 * Collects values of a parsed CSV file (one using the "MapBond" CSV format) into a list of contacts
 *
 * ---
 * @param results Parsed CSV result
 * @param randomId Whether the `id` should be randomly generated or not. Default behaviour is to randomly generate one. The option for not doing so is to allow automated testing without being wary of randomly generated values
 * @returns A list of contacts
 */
export function parseMapBondCsv(
    results: Papa.ParseResult<{ [key: string]: string }>,
    randomId = true
) {
    /**
     * Formats a decimal number in a string to 4 decimal places
     *
     * ---
     * @param value The number as a string
     * @returns The number with 4 decimal places
     */
    const formatDecimal = (value: string) => {
        let decimalPosition = value.indexOf(".");
        return value.slice(
            0,
            decimalPosition !== -1 ? decimalPosition + 5 : undefined
        );
    };

    return results.data.map(
        (result) =>
            ({
                id: randomId ? crypto.randomUUID() : "",
                name: (result["Name"] || "").slice(0, 256),
                description: (result["Description"] || "").slice(0, 512),
                email: (result["Email"] || "").slice(0, 254),
                notes: (result["Private Notes"] || "").slice(0, 512),
                phoneNumber: (result["Phone Number"] || "").slice(0, 32),
                coordinates:
                    !Number.isNaN(Number(result["Latitude"])) &&
                    !Number.isNaN(Number(result["Longitude"]))
                        ? {
                              y: Number(formatDecimal(result["Latitude"])),
                              x: Number(formatDecimal(result["Longitude"])),
                          }
                        : null,
                profilePicture: result["Profile Picture"] || null,
                objects: (result["Objects"] || "")
                    .split(";")
                    .filter((value) => value.trim() !== "")
                    .map((value) => value.slice(0, 128)),
                skills: (result["Skills"] || "")
                    .split(";")
                    .filter((value) => value.trim() !== "")
                    .map((value) => value.slice(0, 128)),
                facebook: (result["Facebook"] || "").slice(0, 256),
                instagram: (result["Instagram"] || "").slice(0, 256),
                linkedin: (result["Linkedin"] || "").slice(0, 256),
                twitter: (result["Twitter"] || "").slice(0, 256),
                youtube: (result["YouTube"] || "").slice(0, 256),
                website: (result["Website"] || "").slice(0, 256),
            } satisfies Contact)
    );
}

/**
 * Collects values of a parsed CSV file (one using Google's CSV format) into a list of contacts
 *
 * ---
 * @param results Parsed CSV result
 * @param randomId Whether the `id` should be randomly generated or not. Default behaviour is to randomly generate one. The option for not doing so is to allow automated testing without being wary of randomly generated values
 * @returns A list of contacts
 */
export function parseGoogleCsv(
    results: Papa.ParseResult<{ [key: string]: string }>,
    randomId = true
) {
    return results.data.map(
        (result) =>
            ({
                id: randomId ? crypto.randomUUID() : "",
                name: (result["Name"] || "").slice(0, 256),
                description: "",
                email: (result["E-mail 1 - Value"] || "").slice(0, 254),
                notes: (result["Notes"] || "").slice(0, 512),
                phoneNumber: (
                    result["Phone 1 - Value"] ||
                    result["Phone 2 - Value"] ||
                    ""
                ).slice(0, 32),
                coordinates: null,
                profilePicture: (result["Photo"] || "").slice(0, 512) || null,
                objects: [],
                skills: [],
                facebook: "",
                instagram: "",
                linkedin: "",
                twitter: "",
                youtube: "",
                website: "",
            } satisfies Contact)
    );
}

/**
 * Collects values of a parsed CSV file (one using Outlook's CSV format) into a list of contacts
 *
 * ---
 * @param results Parsed CSV result
 * @param randomId Whether the `id` should be randomly generated or not. Default behaviour is to randomly generate one. The option for not doing so is to allow automated testing without being wary of randomly generated values
 * @returns A list of contacts
 */
export function parseOutlookCsv(
    results: Papa.ParseResult<{ [key: string]: string }>,
    randomId = true
) {
    return results.data.map(
        (result) =>
            ({
                id: randomId ? crypto.randomUUID() : "",
                name: (
                    (result["First Name"] || " ") +
                    (result["Middle Name"] || " ") +
                    (result["Last Name"] || " ")
                )
                    .trim()
                    .replaceAll(/\s/g, " ")
                    .slice(0, 256),
                description: "",
                email: (
                    result["E-mail Address"] ||
                    result["E-mail 2 Address"] ||
                    result["E-mail 3 Address"] ||
                    ""
                ).slice(0, 254),
                notes: (result["Notes"] || "").slice(0, 512),
                phoneNumber: (
                    result["Primary Phone"] ||
                    result["Home Phone"] ||
                    result["Home Phone 2"] ||
                    result["Mobile Phone"] ||
                    result["Company Main Phone"] ||
                    result["Business Phone"] ||
                    result["Business Phone 2"] ||
                    result["Other Phone"] ||
                    result["Assistant's Phone"] ||
                    ""
                ).slice(0, 32),
                coordinates: null,
                profilePicture: null,
                objects: [],
                skills: [],
                facebook: "",
                instagram: "",
                linkedin: "",
                twitter: "",
                youtube: "",
                website: "",
            } satisfies Contact)
    );
}

/*
  MapBond CSV Fields
  ---
  Name,
  Description,
  Email,
  Private Notes,
  Phone Number,
  Latitude,
  Longitude,
  Profile Picture,
  Objects,
  Skills,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  YouTube,
  Website,
*/

/*
  Google CSV Fields
  ---
  Name,
  Given Name,
  Additional Name,
  Family Name,
  Yomi Name,
  Given Name Yomi,
  Additional Name Yomi,
  Family Name Yomi,
  Name Prefix,
  Name Suffix,
  Initials,
  Nickname,
  Short Name,
  Maiden Name,
  Birthday,
  Gender,
  Location,
  Billing Information,
  Directory Server,
  Mileage,
  Occupation,
  Hobby,
  Sensitivity,
  Priority,
  Subject,
  Notes,
  Language,
  Photo,
  Group Membership,
  E-mail 1 - Type,
  E-mail 1 - Value,
  Phone 1 - Type,
  Phone 1 - Value,
  Phone 2 - Type,
  Phone 2 - Value,
  Organization 1 - Type,
  Organization 1 - Name,
  Organization 1 - Yomi Name,
  Organization 1 - Title,
  Organization 1 - Department,
  Organization 1 - Symbol,
  Organization 1 - Location,
  Organization 1 - Job Description
 */

/*
  Outlook CSV Fields
  ---
  First Name,
  Middle Name,
  Last Name,
  Title,
  Suffix,
  Initials,
  Web Page,
  Gender,
  Birthday,
  Anniversary,
  Location,
  Language,
  Internet Free Busy,
  Notes,
  E-mail Address,
  E-mail 2 Address,
  E-mail 3 Address,
  Primary Phone,
  Home Phone,
  Home Phone 2,
  Mobile Phone,
  Pager,
  Home Fax,
  Home Address,
  Home Street,
  Home Street 2,
  Home Street 3,
  Home Address PO Box,
  Home City,
  Home State,
  Home Postal Code,
  Home Country,
  Spouse,
  Children,
  Manager's Name,
  Assistant's Name,
  Referred By,
  Company Main Phone,
  Business Phone,
  Business Phone 2,
  Business Fax,
  Assistant's Phone,
  Company,
  Job Title,
  Department,
  Office Location,
  Organizational ID Number,
  Profession,
  Account,
  Business Address,
  Business Street,
  Business Street 2,
  Business Street 3,
  Business Address PO Box,
  Business City,
  Business State,
  Business Postal Code,
  Business Country,
  Other Phone,
  Other Fax,
  Other Address,
  Other Street,
  Other Street 2,
  Other Street 3,
  Other Address PO Box,
  Other City,
  Other State,
  Other Postal Code,
  Other Country,
  Callback,
  Car Phone,
  ISDN,
  Radio Phone,
  TTY/TDD Phone,
  Telex,
  User 1,
  User 2,
  User 3,
  User 4,
  Keywords,
  Mileage,
  Hobby,
  Billing Information,
  Directory Server,
  Sensitivity,
  Priority,
  Private,
  Categories
*/
