import React from 'react';
import './CustomDialogContent.module.scss'; // Importing the SCSS file

interface CustomDialogContentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const CustomDialogContent: React.FC<CustomDialogContentProps> = ({ children, style }) => {
  return (
    <div className="custom-dialog-content" style={style}>
      {children}
    </div>
  );
};

export default CustomDialogContent;
