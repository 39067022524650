import React, { CSSProperties } from "react";

import styles from "./DialogCloseButton.module.scss";
import { CloseIcon } from "../../Icons/CloseIcon";

export function DialogCloseButton({
    className,
    onClick,
    style,
}: {
    className?: string;
    onClick: () => void;
    style?: CSSProperties;
}) {
    return (
        <button
            className={`${className} ${styles.button}`}
            onClick={onClick}
            style={style}
        >
            <CloseIcon color="#999" lineWidth={2} lineLength={22} />
        </button>
    );
}
