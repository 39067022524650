//toast and notification will be here

import Alert from '../Components/Alert/Alert'

export const errorHandler = (error: any) => {
  const actualError = error.response.data

  //this is when the error is coming from the custom api_response() message from the backend
  if (actualError.message) {
    Alert('error', actualError.message)
  } else {
    //this is default django error
    console.log(actualError)
    for (const [key, value] of Object.entries(actualError)) {
      switch (key) {
        case 'email':
          Alert('error', `${actualError[key][0]}`)
          break
        case 'password1':
          Alert('error', `${actualError[key][0]}`)
          break
        case 'username':
          Alert('error', `${actualError[key][0]}`)
          break
        case 'non_field_errors':
          Alert('error', `${actualError[key][0]}`)
          break
        default:
          Alert('error', 'An error occurred')
      }
    }
  }
}