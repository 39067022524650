import React from 'react';
import styles from './IconIndicator.module.scss';


const getRangeClassName = (percentage: number) => {
  if (percentage <= 15) {
    return 'contactRange-0-15';
  } else if (percentage <= 30) {
    return 'contactRange-16-30';
  } else if (percentage <= 45) {
    return 'contactRange-31-45';
  } else if (percentage <= 60) {
    return 'contactRange-46-60';
  } else if (percentage <= 75) {
    return 'contactRange-61-75';
  } else if (percentage <= 90) {
    return 'contactRange-76-90';
  } else {
    return 'contactRange-91-100';
  }
};

function calculatePercentage(minDaysLeft: number, maxDaysLeft: number) : number {
  if ( maxDaysLeft < 0) {
    // Handle negative values (optional)
    return 0; // or throw an error
  }
  
  if (minDaysLeft < 0) {
    minDaysLeft = Math.abs(minDaysLeft);
  }
  
  console.log("####calculatePercentage", minDaysLeft, maxDaysLeft);
  if (maxDaysLeft === 0) {
    return 100; // Avoid division by zero
  }

  const percentage = Math.round(Math.abs(( minDaysLeft / maxDaysLeft ) -1) * 100);
  return percentage;
}
const messages = [
  // 0-10%
  ["Just a baby step away!", "Easiest pie slice ever!", "Like the first sip of coffee!", "Ready, set, reconnect!", "First steps are fun steps!", "Starting line excitement! 🏁", "Embarking on a friend-venture! 🚀", "Unlocking the social treasure! 🔑", "Friendship quest: Initiated! 🌟", "Time to revive old bonds! ⏰"], 

  // 11-20%
  ["Striding along nicely!", "Picking up the friendship vibe!", "Cruising on memory lane!", "Reconnection engine warming up!", "Sprinkling the chat seeds!", "Friendship garden growing! 🌱", "Catching up is brewing! ☕", "Friendship vibes getting strong! 💪", "Keep the conversation flowing! 🌊", "Rekindling old ties! 🔥"],

  // 21-30%
  ["Rolling like a social snowball!", "Keep the friend-ship sailing!", "Chit-chat train chugging along!", "Building the bridge of banter!", "Friendship quest in motion!", "Social butterfly on the move! 🦋", "Friend-o-meter rising! 📈", "Firing up the memory lane! 🚂", "Gathering friendship momentum! 🌀", "Creating waves of conversation! 🌊"],

  // 31-40%
  ["Midway to the friendship fiesta!", "Social butterfly spreading wings!", "Halfway to 'Hey, long time!'", "Keeping up the reconnect quest!", "Friendship hotline getting warmer!", "Breaking the ice, smoothly! ❄️🔨", "On a roll with memories! 🎲", "Navigating the friendship map! 🗺️", "Chatting up a friendship storm! 🌪️", "Linking the past and present! 🔗"],

  // 41-50%
  ["Crossing the chat checkpoint!", "Friendship meter halfway full!", "Dialing up the social spirit!", "Nudging the nostalgia!", "Rekindling old laughs, halfway there!", "Bridging the social gap! 🌉", "Laughter reloaded! 😂", "Warming up the good old days! 🔥", "Halfway party, keep it going! 🎉", "Reviving the golden memories! 🏆"],

  // 51-60%
  ["Time to unleash your inner social star!", "Chat gears moving faster!", "Racing against the friend-zone clock!", "Socializing speed increasing!", "Catching up before time catches you!", "Friendship engine at full throttle! 🚗💨", "Diving deep into memories! 🤿", "Unlocking levels of friendship! 🎮", "Chat marathon, on track! 🏃", "Boosting the social connection! 🚀"],

  // 61-70%
  ["Friendly reminder: clock's ticking!", "Remember faces, not just screens!", "Social wizardry in its final act!", "Be the friend they never forgot!", "Nostalgia train's about to leave!", "Friendship clock’s winding up! ⏳", "Time’s flying, so are memories! ✈️", "Racing against the friend-clock! ⏰", "Cherishing every moment! 💖", "Memory lane at full speed! 🛣️"],

  // 71-80%
  ["Friendship finish line in sight!", "Reconnect or expect 'Remember me?' texts!", "Friendship marathon, final lap!", "Quick! Before they think you’re a myth!", "Almost there, don’t turn into a ghost!", "Final countdown, friends await! 🕒", "Sprinting towards friendship goal! 🏃‍♂️", "Reconnection magic happening! 🪄", "Nearing the friend-zone finish! 🏁", "Last leg of the memory marathon! 🏃‍♀️"],

  // 81-90%
  ["Fast-forward your friendliness!", "Friend-tervention time!", "Social sprint to the finish!", "Hurry, they're starting to forget faces!", "Last call for friendship express!", "Friendship rocket launching soon! 🚀", "Friendship fireworks about to start! 🎆", "Final friendship push! 💨", "Hustle for those heartwarming moments! 💓", "Nearing the reconnection climax! 🏔️"],

// 91-100%
["Code Red: Friendship edition!", "Final boarding call for reconnect flight!", "Now or become a 'Remember them?'", "Social buzzer-beater moment!", "Act now or be a legend - 'The One That Got Away'!", "Last chance for a friendship revival! 🌅", "Ring the alarm, it's now or never! 🔔", "Friendship SOS, send out the signal! 🆘", "Final act in the friendship drama! 🎭", "Ultimate friendship quest deadline! 🏆"]
];

function getMessageForPercentage(percentage: number) : string {
  const index = Math.min(Math.floor(percentage / 10), messages.length - 1);
  const messageArray = messages[index];
  const messageIndex = Math.floor(Math.random() * messageArray.length);
  return messageArray[messageIndex];
}


interface IconIndicatorProps {
  createdDaysAgo: number;
  minDays: number;
  minDaysLeft: number;
  maxDays: number;
  maxDaysLeft: number;
  presentDays: number;
  reason: string;
}

const IconIndicator: React.FC<IconIndicatorProps> = ({ 
  createdDaysAgo, 
  minDays, 
  minDaysLeft,
  maxDays,
  maxDaysLeft, 
  presentDays, 
  reason
}) => {
  // The total timeline is from the creation of the task to the maximum day
  const totalTimelineDays = maxDays + createdDaysAgo;
  const scaleFactor = 100 / totalTimelineDays;

  // Positions on the timeline
  const createdPos = 0; // The task was created at the start of the timeline
  const minPos = minDays < 0 ? (createdDaysAgo + minDays) * scaleFactor : minDays * scaleFactor;
  const maxPos = (createdDaysAgo + maxDays) * scaleFactor;
  const presentPos = (createdDaysAgo + (maxDays - maxDaysLeft)) * scaleFactor;
  
  const percentageGone = calculatePercentage(maxDaysLeft,(maxDays-minDays) );
  const showContactRange = presentDays >= minDays && presentDays <= maxDays;
  const rangeWidth = maxPos - minPos;

  const rangeClassName = getRangeClassName(percentageGone);
  const message = getMessageForPercentage(percentageGone);
  const reasonMessage = reason || '';

  return (
    <div className={styles.indicatorContainer}>
      {showContactRange && (
       <div 
       className={`${styles.contactRange} ${styles.message} ${styles[rangeClassName]}`}
       style={{ left: `${minPos}%`, width: `${rangeWidth}%` }}
     >
       {message}
     </div>
      )}
      
      <div className={`${styles.line} ${styles.contactTimestamp}`} style={{ left: `${createdPos}%` }}>
        <span>Created: {createdDaysAgo} days ago </span>
        
        {reasonMessage && reasonMessage.length > 0 && (
        <div className={styles.reasonMessage}>{reasonMessage}</div>
      )}
      </div>
      <div className={`${styles.line} ${styles.minDays}`} style={{ left: `${minPos}%` }}>
        <span>
          Min: {minDays} days{presentDays > minDays ? `` : ''}
        </span>
      </div>
      <div className={`${styles.line} ${styles.maxDays}`} style={{ left: `${maxPos}%` }}>
        <span>Max: {maxDays} days, {maxDaysLeft} left</span>
      </div>
      <div className={`${styles.line} ${styles.presentDays}`} style={{ left: `${presentPos}%` }}>
        <span>Now: {presentDays} days</span>
      </div>
    </div>
  );
};

export default IconIndicator;
