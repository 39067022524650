import React from 'react';
import './MyMenu.scss';

interface MyMenuProps {
  id: string;
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const MyMenu: React.FC<MyMenuProps> = ({ id, anchorEl, open, onClose, children }) => {
  if (!open || !anchorEl) return null;

  const { top, left } = anchorEl.getBoundingClientRect();

  return (
    <div id={id} className="menu" style={{ top: top + 'px', left: left + 'px' }}>
      <div className="menu-content">
        {children}
      </div>
    </div>
  );
};

export default MyMenu;
