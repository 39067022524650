import { useCallback } from 'react'
import { TSignInForm, TUserData } from 'types/shared'
import { apiRoutes } from 'services/routes'
import { errorHandler } from 'services/utils'
import { apiInstance } from 'services/apiInstance'
import Alert from 'Components/Alert/Alert'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useAuth } from '../useAuth'

export const useSignUp = () => {
  const signIn = useSignIn()
  const { determineOnboardingRedirectURL } = useAuth()
  const handleRegistration = useCallback(async (payload: TSignInForm) => {
    try {
      const res = await apiInstance.post(apiRoutes.CREATE_ACCOUNT, payload)
      if (res.status >= 200 && res.status <= 204) {
        Alert('success', 'Account created successfully')
        if (
          signIn({
            auth: {
              token: res.data.access,
              type: 'Bearer'
            },
            refresh: res.data.refresh,
            userState: res.data.user
          })
        ) {
          console.log('here')
          const userObject = res.data.user as TUserData
          determineOnboardingRedirectURL(userObject)
        } else {
          //Throw error
        }
      }
    } catch (reason) {
      errorHandler(reason)
    }
  }, [])

  return handleRegistration
}