import React, { useCallback, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";

import styles from "./ContactMarker.module.scss";
import { MinimalContactData, WithNetworkContactData } from "../HomePageTypes";
import { getContextMenuStyle } from "../../../Components/ContextMenu/ContextMenu";
import { usePopupOpeningEffect } from "hooks/map/usePopupOpeningEffect";
import { DispatchAction } from "../reducer";
import { ContactMarkerPopup } from "./ContactMarkerPopup/ContactMarkerPopup";
import { isNotVisible } from "../visibility";
import { UserAppearanceConfig } from "../../../types/shared";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";
// import SpiralMarkers from "Components/NearbyContactMarker/NearbyContactMarker";
import ContactInfo from "Components/ContactInfo/ContactInfo"; // Import the ContactInfo component
import NetworkMarkers from "Components/NetworkMarkers/NetworkMarkers";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


let DEFAULT_LATITUDE = 0;
let DEFAULT_LONGITUDE = 0;


export default function ContactMarker({
    contact,
    state,
    dispatch,
}: {
    contact: MinimalContactData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {

    let [popupIsOpen, setPopupIsOpen] = useState(false);
    let { currentUser } = useCurrentUserDetails();

    let { markerRefCallback } = usePopupOpeningEffect(
        state.openPopup !== null &&
            state.openPopup.id === contact.id &&
            state.openPopup.type === "contact",
        useCallback(() => dispatch({ type: ACTIONS.CLOSE_POPUP }), [dispatch])
    );

    const onContextMenu = (event: LeafletMouseEvent) => {
        let mouseEvent = event.originalEvent;
        let style = getContextMenuStyle(mouseEvent);
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style, contact, type: "contact" },
        });
    };

    if (!contact) return null;

    return (
        <>
        <Marker
            position={[
                contact.coordinates?.y ?? DEFAULT_LATITUDE,
                contact.coordinates?.x ?? DEFAULT_LONGITUDE,
            ]}
            icon={getMarkerIcon(contact, currentUser?.appearanceConfig)}
            eventHandlers={{
                contextmenu: onContextMenu,
                popupopen: () => setPopupIsOpen(true),
                popupclose: () => setPopupIsOpen(false),
            }}
            ref={markerRefCallback}
            riseOnHover
            >
            <Popup>
                {popupIsOpen ? (
                    <ContactMarkerPopup
                    contact={contact}
                    state={state}
                    dispatch={dispatch}
                    />
                    ) : null}
            </Popup>
        </Marker>
        {/* <ContactInfo contact={contact} /> */}



        <NetworkMarkers data={contact} centerPosition={[
                contact.coordinates?.y ?? DEFAULT_LATITUDE,
                contact.coordinates?.x ?? DEFAULT_LONGITUDE,
            ]} />


                    </>
    );
}

/**
 *  Returns a Leaflet icon for the given contact. The icon is a circle with the
 * contact's color and a white border.
 * @param contact The contact to get the icon for
 * @param appearanceConfig The appearance config of the current user
 * @param appearanceConfig The user config of the current user
 * @returns The icon for the given contact
 * @see https://leafletjs.com/reference-1.7.1.html#icon
 */
function getMarkerIcon(
    contact: MinimalContactData,
    appearanceConfig: UserAppearanceConfig | undefined
) {
    let markerSize = appearanceConfig
        ? appearanceConfig.contact_marker_size
        : 46;

    return L.divIcon({
        html: `
          <div
            class="icon"
            style="
              background-image: url(${
                  isNotVisible(contact.visibility)
                      ? "/images/unknown-contact.svg"
                      : contact.profilePicture ||
                        "/images/default-user-image.svg"
              });
              --size: ${markerSize}px
            "
          ></div>`,
        iconSize: [markerSize, markerSize],
        iconAnchor: [markerSize / 2, markerSize / 2],
        popupAnchor: [0, -(markerSize / 2)],
        className: `${styles["contact-marker-icon"]} ${
            isNotVisible(contact.visibility)
                ? styles["hidden"]
                : styles["visible"]
        }`,
    });
}
