import React, { CSSProperties, useEffect, useState } from "react";
import { ChevronIcon } from "../../Icons/ChevronIcon";

import styles from "./Accordion.module.scss";
import { useCollapsibleAnimation } from "./useCollapsibleAnimation";

export default function Accordion({
    style,
    summaryStyle,
    detailsStyle,
    children,
    isOpen: __isOpen,
    defaultIsOpen,
    onChange,
    chevronSize,
    chevronColor,
    className,
}: {
    className?: string;
    style?: CSSProperties;
    summaryStyle?: CSSProperties;
    detailsStyle?: CSSProperties;
    children: [React.ReactNode, React.ReactNode];
    isOpen?: boolean;
    defaultIsOpen?: boolean;
    onChange?: (isOpen: boolean) => void;
    chevronSize?: number;
    chevronColor?: string;
}) {
    // The inner state of `Accordion` is separate from the `isOpen`
    // value provided via props, but initialized through it (if present)
    let [isOpen, setIsOpen] = useState(
        typeof __isOpen === "boolean"
            ? __isOpen
            : typeof defaultIsOpen === "boolean"
            ? defaultIsOpen
            : false
    );

    let { elementRefCallback, toggleCollapsible } = useCollapsibleAnimation(
        isOpen,
        setIsOpen
    );

    // Update the collapsible element when `__isOpen` changes
    useEffect(() => {
        if (typeof __isOpen === "boolean" && __isOpen !== isOpen) {
            toggleCollapsible();
        }
    }, [__isOpen]);

    const onClick = () => {
        toggleCollapsible();
        if (onChange) {
            onChange(!isOpen);
        }
    };

    return (
        <div
            className={styles.accordion + " " + (className || "")}
            style={style}
        >
            <div
                className={styles.heading}
                style={summaryStyle}
                onClick={onClick}
            >
                <div>{children[0]}</div>
                <span className={styles.chevron}>
                    <ChevronIcon
                        direction={isOpen ? "up" : "down"}
                        color={chevronColor}
                        size={chevronSize}
                    />
                </span>
            </div>
            <div
                className={styles.collapsible}
                ref={elementRefCallback}
                style={{
                    ...detailsStyle,
                    overflow: isOpen ? "visible" : "hidden",
                }}
            >
                {children[1]}
            </div>
        </div>
    );
}
