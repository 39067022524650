import React from "react";

import { ContactData, UserData } from "../../HomePageTypes";
import { DispatchAction } from '../../reducer';

import { HomePageState } from "Pages/Home/HomePageState";


import { ApiError } from "hooks/useAPI";

import ExpandableDescription from 'Components/ExpandableDescription/ExpandableDescription'

import DetailsSection from "Components/DetailsSection/DetailsSection";
import PrivateNotesSection from "Components/PrivateNotesSection/PrivateNotesSection";
import KeepInTouchSection from "Components/KeepInTouchSection/KeepInTouchSection";
import TabsSection from "Components/TabsSection/TabsSection";
import ContactHeader from "Components/ContactHeader/ContactHeader"
import { NetworksIconsDisplay } from "Components/NetworksIconsDisplay/NetworksIconsDisplay";
import AddressList from 'Components/AddressManager/AddressList';
import ExperienceList from 'Components/ExperienceManager/ExperienceList';

import KeepInTouchComponent from "Components/KeepInTouchComponent/KeepInTouchComponent";
/**
 * Renders the full contact popup content, which includes the contact header, expandable description, private notes section, details section, tabs section, and keep in touch section.
 *
 * @param contact The contact data to be displayed in the popup
 * @param setContact A function to update the contact data in the popup
 * @param dispatch The Redux dispatch function
 * @param state The application state
 * @returns The full contact popup content
 */
export function FullContactPopupContent({
    contact,
    setContact,
    dispatch,
    state,
}: {
    contact: ContactData;
    setContact: React.Dispatch<
        React.SetStateAction<ApiError | UserData | ContactData | null>
    >;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let contactId = contact.id;

    const addingKeepInTouch =
        state.keepInTouchAdded !== null &&
        state.keepInTouchAdded.for.id === contact.id;

    const maxDescriptionLength = 100;
    return (
        <>
            <ContactHeader
                contact={contact}
            />
        <NetworksIconsDisplay networks={contact.networks} />

            <ExpandableDescription
                description={contact.description}
                maxDescriptionLength={maxDescriptionLength}
            />

            {!addingKeepInTouch && (
            <KeepInTouchComponent data={contact.keepInTouch} />
            )}

            <PrivateNotesSection
                contact={contact}
                addingKeepInTouch={addingKeepInTouch}
            />

                <AddressList 
					entityType='contact'
					entityId={contactId}
					/>

                <ExperienceList 
					entityType='contact'
					entityId={contactId}
					/>


            <DetailsSection
                data={contact}
                addingKeepInTouch={addingKeepInTouch}
            />

            <TabsSection
                data={contact}
                setContact={setContact}
                addingKeepInTouch={addingKeepInTouch}
                dispatch={dispatch}
                state={state}
                UserOrContact="contact"
            />

            <KeepInTouchSection
                addingKeepInTouch={addingKeepInTouch}
                data={contact}
                state={state}
                UserOrContact="contact"
            />

        </>
    );
}
