import React from "react";
import { ContactData, UserData } from "Pages/Home/HomePageTypes";

import { HomePageState } from "Pages/Home/HomePageState";


import KeepInTouch from "Pages/Home/FrequncyKeepInTouch/KeepInTouch_slim";

interface KeepInTouchSectionProps {
  addingKeepInTouch: boolean;
  data: ContactData | UserData;
  state: HomePageState;
  UserOrContact: "user" | "contact";
}

export default function KeepInTouchSection({
  addingKeepInTouch,
  data,
  state,
  UserOrContact
}: KeepInTouchSectionProps) {
  if (addingKeepInTouch) {
      return (
          <div className="">
              <KeepInTouch
                  inputValues={data.keepInTouch}
                  id={data.id}
                  state={state}
                  UserOrContact={UserOrContact}
              />
          </div>
      );
  } else {
      return null;
  }
}