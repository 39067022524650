// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomRangeSlider_slider__24uLO {
  -webkit-appearance: none;
  height: 3px;
  border-radius: 5px;
  background: rgb(150, 150, 150);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.CustomRangeSlider_slider__24uLO:disabled {
  filter: grayscale(1);
  opacity: 0.5;
}
.CustomRangeSlider_slider__24uLO::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 2px solid var(--accent-color);
}
.CustomRangeSlider_slider__24uLO::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 2px solid var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomRangeSlider/CustomRangeSlider.module.scss"],"names":[],"mappings":"AAIA;EACI,wBAAA;EACA,WAJiB;EAKjB,kBAAA;EACA,8BAPgB;EAQhB,aAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,sBAAA;AAHJ;AAKI;EACI,oBAAA;EACA,YAAA;AAHR;AAOI;EACI,wBAAA;EACA,WAvBY;EAwBZ,YAxBY;EAyBZ,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,qCAAA;AALR;AAQI;EACI,WAhCY;EAiCZ,YAjCY;EAkCZ,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,qCAAA;AANR","sourcesContent":["$range-handle-size: 16px !default;\n$range-track-color: rgb(150, 150, 150) !default;\n$range-track-height: 3px !default;\n\n.slider {\n    -webkit-appearance: none;\n    height: $range-track-height;\n    border-radius: 5px;\n    background: $range-track-color;\n    outline: none;\n    padding: 0;\n    margin: 0;\n    cursor: pointer;\n    box-sizing: border-box;\n\n    &:disabled {\n        filter: grayscale(1);\n        opacity: 0.5;\n    }\n\n    // Range Handle\n    &::-webkit-slider-thumb {\n        -webkit-appearance: none;\n        width: $range-handle-size;\n        height: $range-handle-size;\n        border-radius: 50%;\n        background: white;\n        cursor: pointer;\n        border: 2px solid var(--accent-color);\n    }\n\n    &::-moz-range-thumb {\n        width: $range-handle-size;\n        height: $range-handle-size;\n        border: 0;\n        border-radius: 50%;\n        background: white;\n        cursor: pointer;\n        border: 2px solid var(--accent-color);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `CustomRangeSlider_slider__24uLO`
};
export default ___CSS_LOADER_EXPORT___;
