// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfile_user-profile__JvAVJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding: 40px 20px;
}
.UserProfile_user-profile__JvAVJ > * {
  max-width: 600px;
}
.UserProfile_user-profile__JvAVJ hr {
  width: 100%;
}
@media screen and (min-width: 1600px) {
  .UserProfile_user-profile__JvAVJ > * {
    max-width: 800px;
  }
}
.UserProfile_user-profile__JvAVJ .UserProfile_profile-options__3f9F8 {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.UserProfile_user-profile__JvAVJ .UserProfile_profile-options__3f9F8 > * {
  text-decoration: none;
  color: white;
  padding: 5px 15px;
  border-radius: 25px;
  font-weight: 700;
  border: none;
  margin: 5px;
}
.UserProfile_user-profile__JvAVJ .UserProfile_profile-options__3f9F8 > a {
  background-color: var(--accent-color);
}
.UserProfile_user-profile__JvAVJ .UserProfile_profile-options__3f9F8 > a:hover {
  background-color: var(--accent-color-dark);
}
.UserProfile_user-profile__JvAVJ .UserProfile_profile-options__3f9F8 > a:active {
  background-color: var(--accent-color-x-dark);
}
.UserProfile_user-profile__JvAVJ .UserProfile_main-heading__3qTMR {
  margin: 20px 0 10px 0;
}
.UserProfile_user-profile__JvAVJ .UserProfile_subheading__VilTA {
  margin: 0 0 5px 0;
  color: rgb(97, 97, 97);
  font-size: 1.2em;
}
.UserProfile_user-profile__JvAVJ .UserProfile_bio__ov3S7 {
  padding: 0 20px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserProfile/UserProfile.module.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACH;AACG;EACG,gBAAA;AACN;AAEG;EACG,WAAA;AAAN;AAGG;EACG;IACG,gBAAA;EADP;AACF;AAIG;EACG,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAFN;AAIM;EACG,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AAFT;AAKM;EACG,qCAAA;AAHT;AAMM;EACG,0CAAA;AAJT;AAOM;EACG,4CAAA;AALT;AASG;EACG,qBAAA;AAPN;AAUG;EACG,iBAAA;EACA,sBAAA;EACA,gBAAA;AARN;AAWG;EACG,eAAA;EACA,WAAA;AATN","sourcesContent":[".user-profile {\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   overflow: scroll;\n   padding: 40px 20px;\n\n   >* {\n      max-width: 600px;\n   }\n\n   hr {\n      width: 100%;\n   }\n\n   @media screen and (min-width: 1600px) {\n      >* {\n         max-width: 800px;\n      }\n   }\n\n   .profile-options {\n      margin: 10px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      flex-wrap: wrap;\n\n      >* {\n         text-decoration: none;\n         color: white;\n         padding: 5px 15px;\n         border-radius: 25px;\n         font-weight: 700;\n         border: none;\n         margin: 5px;\n      }\n\n      >a {\n         background-color: var(--accent-color);\n      }\n\n      >a:hover {\n         background-color: var(--accent-color-dark);\n      }\n\n      >a:active {\n         background-color: var(--accent-color-x-dark);\n      }\n   }\n\n   .main-heading {\n      margin: 20px 0 10px 0;\n   }\n\n   .subheading {\n      margin: 0 0 5px 0;\n      color: rgb(97, 97, 97);\n      font-size: 1.2em;\n   }\n\n   .bio {\n      padding: 0 20px;\n      width: 100%;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-profile": `UserProfile_user-profile__JvAVJ`,
	"profile-options": `UserProfile_profile-options__3f9F8`,
	"main-heading": `UserProfile_main-heading__3qTMR`,
	"subheading": `UserProfile_subheading__VilTA`,
	"bio": `UserProfile_bio__ov3S7`
};
export default ___CSS_LOADER_EXPORT___;
