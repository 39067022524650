import { ReactNode } from "react";
import styles from "./SettingsSubpage.module.scss";

export default function SettingsSubpage({
    title,
    children,
    addToHeading,
}: {
    title: string;
    children: ReactNode | ReactNode[];
    addToHeading?: ReactNode;
}) {
    return (
        <div className={styles["outer-container"]}>
            <div className={styles["inner-container"]}>
                <h2>{title} {addToHeading}</h2>
                <br />
                {children}
            </div>
        </div>
    );
}
