import React from 'react';
import styles from './AddTravelPlan.module.scss';
import CustomDropdown from "Components/CustomDropdown/CustomDropdown";
import { BasicTravelData } from "../HomePageTypes";


type PrivacyLevelSelectorProps = {
    travelPlan: BasicTravelData;
    setTravelPlan: React.Dispatch<React.SetStateAction<BasicTravelData>>;
};

export const PrivacyLevelSelector: React.FC<PrivacyLevelSelectorProps> = ({
    travelPlan,
    setTravelPlan,
}) => {
    return (
        <div className={styles["privacy-level"]}>
            <span>Privacy Level:</span>
            <CustomDropdown
                items={["Private", "Connections"]}
                onChange={(value) =>
                    setTravelPlan({
                        ...travelPlan,
                        privacyLevel: value,
                    })
                }
                selected={travelPlan.privacyLevel}
            />
        </div>
    );
};
