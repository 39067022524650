// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmButtons_buttons__PLrLG {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ConfirmButtons_buttons__PLrLG button {
  margin: 0 10px;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 3px solid currentColor;
  font-size: 30px;
}
.ConfirmButtons_buttons__PLrLG button:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.ConfirmButtons_buttons__PLrLG button:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.ConfirmButtons_buttons__PLrLG button:first-child {
  color: red;
}
.ConfirmButtons_buttons__PLrLG button:nth-child(2) {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/Components/ConfirmButtons/ConfirmButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,8BAAA;EACA,eAAA;AACR;AACQ;EACI,6CAAA;AACZ;AAEQ;EACI,6CAAA;AAAZ;AAII;EACI,UAAA;AAFR;AAKI;EACI,YAAA;AAHR","sourcesContent":[".buttons {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    button {\n        margin: 0 10px;\n        width: 35px;\n        height: 35px;\n        border-radius: 20px;\n        display: inline-flex;\n        align-items: center;\n        justify-content: center;\n        background-color: transparent;\n        border: 3px solid currentColor;\n        font-size: 30px;\n\n        &:hover {\n            background-color: #0002;\n        }\n\n        &:active {\n            background-color: #0004;\n        }\n    }\n\n    button:first-child {\n        color: red;\n    }\n\n    button:nth-child(2) {\n        color: green;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `ConfirmButtons_buttons__PLrLG`
};
export default ___CSS_LOADER_EXPORT___;
