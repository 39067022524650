import React, { useState } from "react";

import styles from "./AddNetwork.module.scss";
import { DispatchAction } from "../../Pages/Home/reducer";
import { OverlayingLoadingSpinner } from '../OverlayingLoadingSpinner/OverlayingLoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectableScroll } from '../AddMembers/AddMembers';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { BasicNetworkData } from './BasicNetworkDataTypes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import { DialogCloseButton } from "Components/DialogCloseButton/DialogCloseButton";
import CreateNetwork from '../CreateNetwork/CreateNetwork';
import EditNetwork from './EditNetwork';
import NetworkDetails from './NetworkDetails'; // Adjust the path as necessary
import NetworkDataFetcher from './NetworkDataFetcher'; // Adjust the path as necessary

const Debug = ({ value }: { value: any }) => {
	return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

export default function AddNetwork({
	initialValue,
	state,
	dispatch,
	modalOpen,
	createModalOpen,
	fetchCallback,
}: {
	initialValue?: BasicNetworkData;
	state?: HomePageState;
	dispatch?: React.Dispatch<DispatchAction>;
	modalOpen?: React.Dispatch<boolean>;
	createModalOpen?: React.Dispatch<boolean>;
	fetchCallback?: () => void;
}) {
	const [network, setNetwork] = useState<BasicNetworkData>({
		id: initialValue?.id || null,
		description: initialValue?.description || "",
		name: initialValue?.name || "",
		headQuarter: initialValue?.headQuarter || "",
		privacyLevel: initialValue?.privacyLevel || "Private",
		network_logo: initialValue?.network_logo || null,
		coordinates: initialValue?.coordinates || { x: 0, y: 0 },
		visibility: initialValue?.visibility || "private",
		member_contacts: initialValue?.member_contacts || [],
		member_connections: initialValue?.member_connections || [],
		member_locations: initialValue?.member_locations || [],
		owner: null,
	});
	const [isUploading, setIsUploading] = useState(false);
	const [isAddingMembers, setIsAddingMembers] = useState(false);
	const [errors, setErrors] = useState({
		origin: "",
		destination: "",
		arrival: "",
		departure: "",
	});
	const [userMemberData, setUserMemberData] = useState({
		contacts: [],
		users: [],
		location: [],
	});
	let [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);


	const apiInstance = useAxiosPrivate();
	const closeMenu = () => {
		modalOpen
			? modalOpen(false)
			: dispatch && dispatch({ type: ACTIONS.addingNetwork, value: false });
		createModalOpen && createModalOpen(false);
	};



	// const contacts: Array<MinimalContactData> = [{ "name": "Rohit Das", "coordinates": { "x": -69.55994, "y": -31.77488 }, "id": 1, "profilePicture": null, "visibility": "private" }, { "name": "Chinmoy Kalita", "coordinates": { "x": -69.55994, "y": -31.77488 }, "id": 3, "profilePicture": null, "visibility": "private" }, { "name": "Sakira", "coordinates": { "x": -69.8032, "y": -32.38125 }, "id": 4, "profilePicture": null, "visibility": "private" }, { "name": "Alex", "coordinates": { "x": -69.8032, "y": -32.38125 }, "id": 5, "profilePicture": null, "visibility": "private" }]
	// const users: MinimalUserData[] = [{ "name": "Chinmoy Nivedita", "id": 5, "coordinates": { "x": -70.06163, "y": -31.32212 }, "handle": "ck", "smallProfilePicture": "https://res.cloudinary.com/de6915ska/image/upload/c_fill,h_100,w_100/v1/media/images/chinmoy_professional_look_ublwo6", "isConnected": false }];
	// const location: MinimalLocationData[] = [];

	return (
		<div className={styles["container"]}>
			<div className={styles["add-flight-plan"]}>
				<div className={styles["inputs"]}>
					<NetworkDataFetcher
						network={network}
						apiInstance={apiInstance}
						modalOpen={!!modalOpen}
						setUserMemberData={setUserMemberData}
					/>

					{isUploading ? (
						<OverlayingLoadingSpinner
							primaryColor="var(--accent-color)"
							backgroundColor="#fffa"
						/>
					) : null}
					<div className={styles["heading"]}>
						<h3>
							{isAddingMembers && (
								<FontAwesomeIcon
									className="mr-2 cursor-pointer"
									onClick={() => setIsAddingMembers(false)}
									icon={faArrowLeft}
								/>
							)}
							{isAddingMembers
								? modalOpen
									? "Edit Network Members"
									: "Add Network Members"
								: modalOpen
									? "Edit Network"
									: "Add a Network"}
						</h3>
						<DialogCloseButton className={styles.close} onClick={closeMenu} />
					</div>

					<div className={styles["errors"]}>
						<div>{errors.origin}</div>
						<div>{errors.destination}</div>
					</div>

					{!isAddingMembers ? (

						<NetworkDetails
							network={network}
							setNetwork={setNetwork}
							setSelectedPhoto={setSelectedPhoto}
							setIsAddingMembers={setIsAddingMembers}
						/>
					) : (
						<div>
							{/* <Debug value={network} /> */}
							{/* <Debug value={userMemberData} /> */}

							<SelectableScroll
								contacts={userMemberData.contacts}
								users={userMemberData.users}
								locations={userMemberData.location}
								networkData={network}
								setNetworkData={setNetwork}
							/>
						</div>
					)}
				</div>
				<div className={styles["button-container"]}>
					{!isAddingMembers && !modalOpen ? (
						<CreateNetwork
							network={network}
							selectedPhoto={selectedPhoto}
							apiInstance={apiInstance}
							fetchCallback={fetchCallback}
							createModalOpen={createModalOpen}
						/>
					) : !isAddingMembers && modalOpen ? (
						<EditNetwork
							network={network}
							selectedPhoto={selectedPhoto}
							apiInstance={apiInstance}
							modalOpen={modalOpen}
							isUploading={isUploading} // Pass isUploading here
						/>
					) : (
						<button
							className="btn btn-primary"
							onClick={() => setIsAddingMembers(false)}
						>
							Confirm
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

