import React, { useState } from "react";
import { Link } from "react-router-dom";

import RoundThumbnailImage from "../../../Components/RoundThumbnailImage/RoundThumbnailImage";
import { OptionsList } from "../../../Components/OptionsList/OptionsList";
import {
    PermissionsForUser,
    PlaceOrContactPermissions,
} from "./ManagePermissions"; // Add
import styles from "./ManagePermissions.module.scss";
import Accordion from "../../../Components/Accordion/Accordion";
import { objectsEqual } from "../../../Utils/utils";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import { RiDeleteBinLine } from "react-icons/ri";
import TickAnimation from "../../../Components/TickAnimation/TickAnimation";

export function UserSection({
    user,
    allPermissions,
    onOptionsChanged,
    save,
    revokeUserPermissions,
}: {
    user: PermissionsForUser;
    allPermissions: PlaceOrContactPermissions;
    onOptionsChanged: (newOptions: { [key: string]: boolean }) => void;
    save: () => void;
    revokeUserPermissions: () => void;
}) {
    let [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles["user"]}>
            <Accordion onChange={(isOpen) => setIsOpen(isOpen)}>
                <div className={styles["user-details"]}>
                    <Link to={user.url}>
                        <RoundThumbnailImage image={user.image} size={30} />
                        {user.name}{" "}
                        {isOpen ? `can access ${allPermissions.name}'s:` : null}
                    </Link>
                </div>
                <div className={styles["permission-options"]}>
                    <OptionsList
                        type={allPermissions.type}
                        options={user.editedOptions}
                        disabled={user.saving}
                        onOptionsChanged={onOptionsChanged}
                    />

                    <div className={styles["button-container"]}>
                        <RevokePermissionButton
                            user={user}
                            revokeUserPermissions={revokeUserPermissions}
                        />
                        {objectsEqual(
                            user.originalOptions,
                            user.editedOptions
                        ) ? null : (
                            <SaveButton user={user} save={save} />
                        )}
                    </div>
                </div>
            </Accordion>
        </div>
    );
}

function RevokePermissionButton({
    user,
    revokeUserPermissions,
}: {
    user: PermissionsForUser;
    revokeUserPermissions: () => void;
}) {
    return (
        <button
            className={styles["revoke"]}
            disabled={user.saving}
            onClick={revokeUserPermissions}
        >
            {user.saving ? (
                <SmallLoadingSpinner
                    primaryColor="white"
                    secondaryColor="#fff3"
                    size={14}
                />
            ) : (
                <RiDeleteBinLine />
            )}
        </button>
    );
}

function SaveButton({
    user,
    save,
}: {
    user: PermissionsForUser;
    save: () => void;
}) {
    return (
        <button
            className={styles["save"]}
            disabled={user.saving}
            onClick={save}
        >
            {user.saving ? (
                <SmallLoadingSpinner
                    primaryColor="white"
                    secondaryColor="#fff3"
                    size={14}
                />
            ) : (
                <TickAnimation
                    color="white"
                    duration={0}
                    width="17px"
                    height="8px"
                    margin="0 0 4px 0"
                    lineThickness="2px"
                />
            )}
        </button>
    );
}
