// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomComponentMentionEditor_editor__pM31C {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    margin-bottom: 2em;
    background: #fefefe;
    width: 22rem;
    color: #3e2723; /* Dark brown text */
    border: 2px solid #3e2723;
    font-family: 'MedievalSharp', cursive; /* Or any other medieval looking font */
    border-radius: 0;
  }
  
  .CustomComponentMentionEditor_editor__pM31C .public-DraftEditor-content {
    min-height: 60vh;
    width: 20vw;
  }
  @media only screen and (max-width: 767px) {
    .CustomComponentMentionEditor_editor__pM31C {
      width: 90vw;
    }
  }

  /* Additional Styles for mention suggestions and entries */
.CustomComponentMentionEditor_mentionSuggestionsEntryText__YQE8n,
.CustomComponentMentionEditor_mentionSuggestionsEntryTitle__Sj9B0 {
    font-family: 'MedievalSharp', cursive; /* Or any other medieval looking font */
    color: #3e2723; /* Dark brown text */
}
  


.CustomComponentMentionEditor_medievalJournalItem__wkCxh {
  color: #3e2723; /* Dark brown text */
  border: 1px solid #3e2723;
  font-family: 'MedievalSharp', cursive; /* Or any other medieval-looking font */
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0; /* Remove rounded corners for a more rustic look */
}`, "",{"version":3,"sources":["webpack://./src/Components/TextBox/CustomComponentMentionEditor.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,cAAc,EAAE,oBAAoB;IACpC,yBAAyB;IACzB,qCAAqC,EAAE,uCAAuC;IAC9E,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;EACA;IACE;MACE,WAAW;IACb;EACF;;EAEA,0DAA0D;AAC5D;;IAEI,qCAAqC,EAAE,uCAAuC;IAC9E,cAAc,EAAE,oBAAoB;AACxC;;;;AAIA;EACE,cAAc,EAAE,oBAAoB;EACpC,yBAAyB;EACzB,qCAAqC,EAAE,uCAAuC;EAC9E,aAAa;EACb,mBAAmB;EACnB,0CAA0C;EAC1C,gBAAgB,EAAE,kDAAkD;AACtE","sourcesContent":[".editor {\n    box-sizing: border-box;\n    border: 1px solid #ddd;\n    cursor: text;\n    padding: 16px;\n    margin-bottom: 2em;\n    background: #fefefe;\n    width: 22rem;\n    color: #3e2723; /* Dark brown text */\n    border: 2px solid #3e2723;\n    font-family: 'MedievalSharp', cursive; /* Or any other medieval looking font */\n    border-radius: 0;\n  }\n  \n  .editor :global(.public-DraftEditor-content) {\n    min-height: 60vh;\n    width: 20vw;\n  }\n  @media only screen and (max-width: 767px) {\n    .editor {\n      width: 90vw;\n    }\n  }\n\n  /* Additional Styles for mention suggestions and entries */\n.mentionSuggestionsEntryText,\n.mentionSuggestionsEntryTitle {\n    font-family: 'MedievalSharp', cursive; /* Or any other medieval looking font */\n    color: #3e2723; /* Dark brown text */\n}\n  \n\n\n.medievalJournalItem {\n  color: #3e2723; /* Dark brown text */\n  border: 1px solid #3e2723;\n  font-family: 'MedievalSharp', cursive; /* Or any other medieval-looking font */\n  padding: 16px;\n  margin-bottom: 16px;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);\n  border-radius: 0; /* Remove rounded corners for a more rustic look */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `CustomComponentMentionEditor_editor__pM31C`,
	"mentionSuggestionsEntryText": `CustomComponentMentionEditor_mentionSuggestionsEntryText__YQE8n`,
	"mentionSuggestionsEntryTitle": `CustomComponentMentionEditor_mentionSuggestionsEntryTitle__Sj9B0`,
	"medievalJournalItem": `CustomComponentMentionEditor_medievalJournalItem__wkCxh`
};
export default ___CSS_LOADER_EXPORT___;
