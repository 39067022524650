// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkerPrivacySetting_visibility__Gx2Xe {
  margin-bottom: 15px;
}
.MarkerPrivacySetting_visibility__Gx2Xe td {
  padding: 5px;
}
.MarkerPrivacySetting_visibility__Gx2Xe .MarkerPrivacySetting_require-permission__UovJq {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.MarkerPrivacySetting_visibility__Gx2Xe .MarkerPrivacySetting_require-permission__UovJq .MarkerPrivacySetting_checkbox__j5Shs {
  margin-right: 5px;
  transform: scale(0.9);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/MarkerPrivacySetting/MarkerPrivacySetting.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AAAR;AAEQ;EACI,iBAAA;EACA,qBAAA;AAAZ","sourcesContent":[".visibility {\n    margin-bottom: 15px;\n\n    td {\n        padding: 5px;\n    }\n\n    .require-permission {\n        display: flex;\n        flex-direction: row;\n        cursor: pointer;\n\n        .checkbox {\n            margin-right: 5px;\n            transform: scale(0.9);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visibility": `MarkerPrivacySetting_visibility__Gx2Xe`,
	"require-permission": `MarkerPrivacySetting_require-permission__UovJq`,
	"checkbox": `MarkerPrivacySetting_checkbox__j5Shs`
};
export default ___CSS_LOADER_EXPORT___;
