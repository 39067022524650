import { useEffect, useRef, useCallback } from "react";
import L from "leaflet";

/**
 * Used to open the popup of a given marker based on changes
 * to url parameters such as `showUser`, `showContact`, and
 * `showLocation`, etc.
 *
 * ---
 * @param popupOpen Whether the popup should be open or not
 * @param closePopup Used to reset the `popupOpen` value to null, when opening the popup is done or when the popup is closed. (Doesn't actually close the popup)
 * @returns The `Ref` of the marker as well as the **ref callback** used to assign the `Ref`
 */
export function usePopupOpeningEffect(
    popupOpen: boolean,
    closePopup: () => void
) {
    let markerRef = useRef<L.Marker | null>(null);

    /**
     * Used to switch the `autoPan` property of the marker's
     * popup between `true` and `false` whenever the popup
     * is opened
     *
     * When the popup is being opened, `autoPan` is set to
     * `true` to allow the popup to be automatically panned
     * into view. However, after a short while, `autoPan`
     * is set to `false` to allow the user to pan the popup
     * outside the map
     */
    function manageAutoPanProperty() {
        // Set `autoPan` to true so that the
        // popup should be panned within view
        if (markerRef.current) {
            let popup = markerRef.current.getPopup();
            if (popup) {
                popup.options.autoPan = true;
            }
        }
        // Set `autoPan` to false to allow the
        // popup to be panned out of view freely
        setTimeout(() => {
            if (markerRef.current) {
                let popup = markerRef.current.getPopup();
                if (popup) {
                    popup.options.autoPan = false;
                }
            }
        }, 2000);
    }

    useEffect(() => {
        if (markerRef.current && popupOpen) {
            markerRef.current.openPopup();
            manageAutoPanProperty();
        }
    }, [popupOpen, markerRef]);

    useEffect(() => {
        if (markerRef.current) {
            manageAutoPanProperty();
        }
    }, []);

    useEffect(() => {
        if (markerRef.current) {
            if (popupOpen) {
                markerRef.current.openPopup();
            }
        }
    });

    let markerRefCallback = useCallback(
        (marker: L.Marker | null) => {
            if (marker) {
                marker.addEventListener("popupclose", closePopup);
            }
            markerRef.current = marker;
        },
        [closePopup]
    );

    return { markerRef, markerRefCallback };
}
