// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObjectsBeingTaken_taken-objects__Lx1gA {
  margin-top: 20px;
}
.ObjectsBeingTaken_taken-objects__Lx1gA button {
  background-color: transparent;
  padding: 5px;
  border: none;
  border-radius: 3px;
}
.ObjectsBeingTaken_taken-objects__Lx1gA button:hover {
  background-color: #f0f0f0;
}
.ObjectsBeingTaken_taken-objects__Lx1gA button:active {
  background-color: #e0e0e0;
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li {
  margin: 10px 0;
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li > div .ObjectsBeingTaken_delete-button__aQr7K {
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li > div .ObjectsBeingTaken_delete-button__aQr7K svg {
  fill: #999;
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li > div .ObjectsBeingTaken_delete-button__aQr7K:hover svg {
  fill: rgb(255, 100, 100);
}
.ObjectsBeingTaken_taken-objects__Lx1gA ul li > div input {
  background-color: transparent;
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/AddTravelPlan/ObjectsBeingTaken/ObjectsBeingTaken.module.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;AACH;AACG;EACG,6BAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AACN;AACM;EACG,yBAAA;AACT;AAEM;EACG,yBAAA;AAAT;AAMM;EACG,cAAA;AAJT;AAMS;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;AAJZ;AAMY;EACG,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AAJf;AAMe;EACG,UAAA;AAJlB;AAQkB;EACG,wBAAA;AANrB;AAWY;EACG,6BAAA;EACA,gBAAA;AATf","sourcesContent":[".taken-objects {\n   margin-top: 20px;\n\n   button {\n      background-color: transparent;\n      padding: 5px;\n      border: none;\n      border-radius: 3px;\n\n      &:hover {\n         background-color: #f0f0f0;\n      }\n\n      &:active {\n         background-color: #e0e0e0;\n      }\n   }\n\n\n   ul {\n      li {\n         margin: 10px 0;\n\n         >div {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n\n            .delete-button {\n               height: 26px;\n               display: inline-flex;\n               align-items: center;\n               justify-content: center;\n               margin-left: 10px;\n\n               svg {\n                  fill: #999;\n               }\n\n               &:hover {\n                  svg {\n                     fill: rgb(255, 100, 100);\n                  }\n               }\n            }\n\n            input {\n               background-color: transparent;\n               font-size: 0.8em;\n            }\n         }\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taken-objects": `ObjectsBeingTaken_taken-objects__Lx1gA`,
	"delete-button": `ObjectsBeingTaken_delete-button__aQr7K`
};
export default ___CSS_LOADER_EXPORT___;
