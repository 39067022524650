import { Axios } from "axios";
import { ApiError } from "hooks/useAPI";

/**
 * Returns a callback which is used to perform the actual fetching of data. It
 * is used to fetch data when the user scrolls on the map or performs a search.
 * It is also used to fetch data when the user changes the tile layer.
 * ---
 * @param url The fetch url
 * @returns a callback which is used to perform the actual fetching of data
 */
// travelFetchData.ts

export function travelFetchData(
  axiosInstance: Axios,
  url: string,
  setData: (data: any) => void,
  setFetchCount: (count: (count: number) => number) => void
) {
  return () => {
    axiosInstance.get(url)
      .then(response => {
        setData(response.data);
        setFetchCount(count => count + 1);
      })
      .catch(error => {
        setData(new ApiError(error.message, error.response ? error.response.status : 0));
        setFetchCount(count => count + 1);
      });
  };
}
