import L from "leaflet";

import { ClusterData } from "Pages/Home/HomePageTypes";

/**
 * Merges clusters which are too close to each other on the map.
 * Specifically, it merges clusters whose outer boundaries touch
 * each other on the rendered map, based on map dimensions.
 *
 * Since the backend server has to make sure that it returns a fixed
 * number of clusters, some of the clusters end up being too close to
 * each other on the map. And since the backend does not know how the
 * clusters will be rendered on the map, this has to be done here on
 * the client side
 *
 * ---
 * @param clusters The clusters to merge
 * @param scalingFactor The ratio between the degrees on the map to the equivalent pixels
 * @param minimumDistance The minimum allowable pixel distance between cluster centers. If lower than this, the two clusters get merged
 * @returns Merged clusters
 */
export function mergeOverlappingClusters(
    clusters: Array<ClusterData>,
    scalingFactor: number,
    minimumDistance: number
): Array<ClusterData> {
    if (clusters.length <= 1) {
        return clusters;
    }

    const result: ClusterData[] = [];

    // Create a copy of the clusters list to work with
    const remainingClusters = [...clusters];

    while (remainingClusters.length > 0) {
        let newCluster: ClusterData = { ...remainingClusters.shift()! };

        for (let i = remainingClusters.length - 1; i >= 0; i--) {
            const otherCluster = remainingClusters[i];

            const distance = distanceBetweenClusterCenters(
                newCluster,
                otherCluster
            );

            if (distance / scalingFactor <= minimumDistance) {
                newCluster = mergeClusters(newCluster, otherCluster);
                remainingClusters.splice(i, 1); // Delete otherCluster
            }
        }

        result.push(newCluster);
    }

    return result;
}

/**
 * @param cluster1 First cluster
 * @param cluster2 Second cluster
 * @returns The Euclidean distance between the two cluster's centers
 */
export function distanceBetweenClusterCenters(
    cluster1: ClusterData,
    cluster2: ClusterData
) {
    let [long1, lat1] = [cluster1.centroid.x, cluster1.centroid.y];
    let [long2, lat2] = [cluster2.centroid.x, cluster2.centroid.y];

    return Math.sqrt((long1 - long2) ** 2 + (lat1 - lat2) ** 2);
}

export type Bounds = {
    west: number;
    north: number;
    south: number;
    east: number;
};

/**
 * Merges two clusters into one
 *
 * ---
 * @param cluster1 First cluster
 * @param cluster2 Second cluster
 * @returns A merged cluster
 */
export function mergeClusters(
    cluster1: ClusterData,
    cluster2: ClusterData
): ClusterData {
    let clusterBounds = (cluster: ClusterData): Bounds => {
        let { centroid } = cluster;
        return {
            west: centroid.x - cluster.radius,
            south: centroid.y - cluster.radius,
            east: centroid.x + cluster.radius,
            north: centroid.y + cluster.radius,
        };
    };

    let bounds1 = clusterBounds(cluster1);
    let bounds2 = clusterBounds(cluster2);

    let minimumBounds = {
        west: Math.min(bounds1.west, bounds2.west),
        south: Math.min(bounds1.south, bounds2.south),
        east: Math.max(bounds1.east, bounds2.east),
        north: Math.max(bounds1.north, bounds2.north),
    };

    let newLatitude = (minimumBounds.north + minimumBounds.south) / 2;
    let newLongitude = (minimumBounds.east + minimumBounds.west) / 2;

    return {
        count: cluster1.count + cluster2.count,
        radius: Math.max(
            (minimumBounds.north - minimumBounds.south) / 2,
            (minimumBounds.east - minimumBounds.west) / 2
        ),
        centroid: {
            x: newLongitude,
            y: newLatitude,
        },
    };
}
