import React, { useState } from 'react';
import styles from './ObjectWithImages.module.scss'; // Adjust path and style as needed
import { OwnedObject } from 'Pages/Home/HomePageTypes';
import { faUpload } from '@fortawesome/free-solid-svg-icons'; // Import the Upload icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome library
import { RiDeleteBinLine } from 'react-icons/ri'; // Import the Delete icon
import { apiRoutes } from 'services/routes';
import { usePostToAPI } from 'hooks/useAPI';
import { ObjectImage } from 'Pages/Home/HomePageTypes';

interface ObjectWithImagesProps {
  object: OwnedObject;
  contactObjects: OwnedObject[]; // Add contactObjects prop
  target: "user" | "contact" | "place";
}

const ObjectWithImages: React.FC<ObjectWithImagesProps> = ({
  object,
  target,
}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false); // Added uploadingImage state
  const [contactObjects, setContactObjects] = useState<OwnedObject[]>([]);

  const { postToApi } = usePostToAPI();

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const dataUrl = URL.createObjectURL(file);
      setSelectedImage(file);
      setUploadingImage(true);

      try {

        const apiRoute =
          target === "contact"
            ? apiRoutes.CREATE_CONTACT_IMAGE_OBJECT
            : target === "place"
            ? apiRoutes.CREATE_PLACE_IMAGE_OBJECT
            : apiRoutes.CREATE_USER_IMAGE_OBJECT;
            
        const response = await postToApi<ObjectImage>(
          apiRoute,
          {
            image: file,
            objectId: object.id.toString(),
          }
        );

        setUploadingImage(false);

        if (response.ok) {
          const picture = response.data;

          // Update the contactObjects state to include the new image
          const updatedObjects = contactObjects.map((o) =>
            o.id === object.id
              ? {
                  ...o,
                  pictures: [...o.pictures, picture],
                }
              : o
          );

          setContactObjects(updatedObjects);
        } else {
          // Handle errors here if the API request fails
          console.error('Error uploading image:', response);
        }
      } catch (error) {
        // Handle network errors or other exceptions here
        console.error('An error occurred while uploading image:', error);
      }
    }
  };

  const deleteObjectImage = async (imageId: number, objectId: number, target: string) => {

const apiRoute =
  target === "contact"
    ? apiRoutes.DELETE_CONTACT_IMAGE_OBJECT(imageId.toString())
    : target === "place"
    ? apiRoutes.DELETE_PLACE_IMAGE_OBJECT(imageId.toString())
    : apiRoutes.DELETE_USER_IMAGE_OBJECT(imageId.toString());

try {
  // Make a DELETE request to your API endpoint to delete the image
  const response = await fetch(apiRoute, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json', // Set your headers as needed
      // Add any other headers you need here
    },
  });

      if (response.ok) {
        // The image deletion was successful
        // Update the component's state to remove the deleted image
        const updatedObjects = contactObjects.map((o) =>
          o.id === object.id
            ? {
                ...o,
                pictures: o.pictures.filter((p) => p.id !== imageId),
              }
            : o
        );
        setContactObjects(updatedObjects);
      } else {
        // Handle errors if the deletion fails
        console.error(`Error deleting image with ID ${imageId}`);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error(`An error occurred while deleting image with ID ${imageId}:`, error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.imagesContainer}>
        {object.pictures.map((picture) => (
          <div key={picture.id} className={styles.imageWrapper}>
            <img src={picture.smallImageUrl} alt={object.name} className={styles.image} />
            <button
              onClick={() => deleteObjectImage?.(picture.id, object.id, target)} // Use optional chaining here
              className={styles.deleteButton}
            >
              <RiDeleteBinLine></RiDeleteBinLine>
            </button>
          </div>
        ))}

      {selectedImage && ( // Render the selected image if available
          <div className={styles.imageWrapper}>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt={object.name}
              className={styles.image}
            />
          </div>
        )}
      </div>
      <div className={styles.uploadContainer}>
        <label htmlFor={`upload-image-${object.id}`} className={styles.uploadLabel}>
        {uploadingImage ? 'Uploading...' : (
            <span>
              <FontAwesomeIcon icon={faUpload} />
            </span>
          )}        </label>
        <input
          type="file"
          id={`upload-image-${object.id}`}
          onChange={handleImageChange}
          style={{ display: 'none' }}
          disabled={uploadingImage}
        />
      </div>
    </div>
  );
};

export default ObjectWithImages;
