import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import React, {  useEffect, useState } from 'react';
import { apiRoutes } from 'services/routes';

type CommonInterestsState = {
  common_skills: Array<{ name: string; count: number }> | null;
  common_objects: Array<{ name: string; count: number }> | null;
};

const CommonInterests =()=>{
  const [ commonSkills,setCommonSkills] = useState([])
  const [ commonObject,setCommonObject] = useState([])
  const apiInstance = useAxiosPrivate()

  useEffect(()=>{
    const fetchData = async ()=>{
      try {
        const response = await apiInstance.get(apiRoutes.GET_COMMON_INTERESTS);
        const data = response.data?.data;
        setCommonSkills(data.common_skills)
        setCommonObject(data.common_objects)

      } catch (error) {
        console.error('Error fetching common interests:', error);
      }
    }
    fetchData()
  },[])


  const handleItemClick = async (searchFor: string, searchQuery: string)=> {
    try {
      const response = await apiInstance.get(`/search/contacts?searchFor=${searchFor}&search=${searchQuery}`);
      const relatedContacts = await response.data?.data;

      // Example: Display related contacts in an alert box
      const relatedContactsString = relatedContacts
        .map((contact: { name: string }) => contact.name)
        .join(', ');

      alert(`Related Contacts: ${relatedContactsString}`);

    } catch (error) {
      console.error('Error fetching related contacts:', error);
    }
  }


  if (!commonSkills || !commonObject) {
    return <div>Loading...</div>;
  }



    return (
      <div>
        <h2>Common Skills</h2>
        <ul>
          {commonSkills?.map((skill:any) => (
            <li key={skill.name} onClick={() => handleItemClick('Skills', skill.name)}>
              {skill.name} ({skill.count})
            </li>
          ))}
        </ul>
        <h2>Common Objects</h2>
        <ul>
          {commonObject?.map((object:any) => (
            <li key={object.name} onClick={() => handleItemClick('Objects', object.name)}>
              {object.name} ({object.count})
            </li>
          ))}
        </ul>
      </div>
    );

}

export default CommonInterests;
