import React from 'react';
import { useFetchData } from './useFetchData';
import DataTable from './DataTable';

const ViewKeepInTouch: React.FC = () => {
    const { data, loading } = useFetchData();

    return (
        <main className="flex flex-col p-2">
            <h2 className="text-2xl">Frequency keep in touch</h2>
            <DataTable data={data} loading={loading} />
        </main>
    );
};

export default ViewKeepInTouch;
