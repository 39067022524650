import React from "react";
import { DispatchAction } from "../reducer";
import AddTravelPlan from "../AddTravelPlan/AddTravelPlan";
import AddNetwork from "Components/NetworkGroups/AddNetwork";
import AddContactFromLinkedIn from "../AddContactFromLinkedIn/AddContactFromLinkedIn"; // Import AddContactFromLinkedIn
import { LatLng } from "leaflet";
import ImportContacts from "../ImportContacts/ImportContacts";
import { Map } from "leaflet";
import JournalEntry from "Components/JournalEntry/JournalEntry";

import { HomePageState } from "Pages/Home/HomePageState";


// Inside AddActions.tsx
interface AddActionsProps {
  state: HomePageState;
  dispatch: (action: DispatchAction) => void;
  mapRef: React.RefObject<Map>; // Add mapRef as a prop
  latLng: (y: number, x: number) => LatLng; // Add latLng as a prop
}


const AddActions: React.FC<AddActionsProps> = ({ state, dispatch, mapRef, latLng }) => {
  return (
    <>
      {state.addingTravelPlan ? (
        <AddTravelPlan state={state} dispatch={dispatch} />
      ) : null}
      {state.addingNetwork ? (
        <AddNetwork state={state} dispatch={dispatch} />
      ) : null}
      {state.importingContacts ? (
        <ImportContacts state={state} dispatch={dispatch} />
            ) : null}
      {state.addingJournalEntry ? (
        <JournalEntry state={state} dispatch={dispatch} />
            ) : null}
      {state.addingContactFromLinkedin ? (
        <AddContactFromLinkedIn
          dispatch={dispatch}
          flyToCoordinates={(coordinates, duration) =>
            mapRef.current?.flyTo(
              latLng(coordinates.y, coordinates.x),
              12,
              { duration }
            )
          }
        />
      ) : null}
    </>
  );
};

export default AddActions;
