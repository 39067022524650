import React, { ChangeEvent, useState, FocusEvent } from 'react';
import styles from './ReasonForContactInput.module.scss'; // Import the local CSS module

interface ReasonForContactInputProps {
  reasonForContact: string;
  handleReasonForContactChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const ReasonForContactInput: React.FC<ReasonForContactInputProps> = ({
  reasonForContact,
  handleReasonForContactChange,
}) => {
  // Array of subtitles to evoke different emotions and reasons
  const subtitles = [
    "What inspires you to keep in touch and connect over what matters most to you?",
    "Share the story that draws you back to this connection.",
    "What shared memories or future plans keep this bond alive for you?",
    "Reflect on the moments that make this relationship special to you.",
    "How does this connection enrich your life and experiences?",
    "What draws you to reconnect with this person?",
    "Describe the joy this connection brings to your life.",
    "What unique qualities make this relationship special?",
    "How has this person positively influenced your life?",
    "Share a memorable moment you've had with them.",
    "What future experiences do you hope to share together?",
    "How does this person inspire or motivate you?",
    "Recall a time this person made a difference in your day.",
    "What common interests keep your bond strong?",
    "How do you envision your relationship evolving?",
    "What words come to mind when you think of this person?",
    "Describe the feeling of being in this person's company.",
    "How do your conversations with them enrich you?",
    "What lessons have you learned from your interactions?",
    "Share a goal or dream you have related to this connection.",
    "How do you feel when you think about reuniting?",
    "What aspects of their personality resonate with you?",
    "Describe a shared value or belief that connects you.",
    "What mutual support or understanding exists between you?",
    "How has this relationship grown or changed over time?",
    "What new experiences do you wish to explore together?",
    "How do you complement each other's strengths?",
    "What mutual challenges have you faced and overcome?",
    "Share a dream or aspiration you've discussed together.",
    "What cultural or personal insights have you gained?",
    "How does this person make ordinary moments extraordinary?",
    "Describe how this person encourages your growth.",
    "What adventures await in your continued journey together?",
    "How do you celebrate each other's successes?",
    "What unique perspective or wisdom do they offer you?"
  ];

  const [subtitle] = useState(subtitles[Math.floor(Math.random() * subtitles.length)]);
  const [visibleRows, setVisibleRows] = useState(1); // Initial number of rows is 10
  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    setVisibleRows(3)
  };

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (!event.currentTarget.value) {
      setVisibleRows(1)
    }
  };

  return (
    <div className={styles.container}>
      <label className={`${styles.label} ${styles.typewriter}`}>
        Reason for wanting to keep in touch:
      </label>
      <textarea
        placeholder={subtitle}
        value={reasonForContact}
        onChange={handleReasonForContactChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${styles.textarea}`}
        rows={visibleRows}
      />
    </div>
  );
};

export default ReasonForContactInput;
