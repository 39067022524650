
import React from "react";
import styles from "../LocationProfile.module.scss";
function Description({
  editing,
  description,
  updateDescription,
}: {
  editing: boolean;
  description: string;
  updateDescription: React.FormEventHandler<HTMLTextAreaElement>;
}) {
  return (
      <p className={styles["description"]}>
          {editing ? (
              <textarea
                  value={description}
                  onInput={updateDescription}
                  style={{
                      width: "100%",
                      minHeight: "200px",
                  }}
              />
          ) : (
              description
          )}
      </p>
  );
}

export default Description;