import React from 'react';
import styles from './AddNetwork.module.scss';
import { AiOutlineEdit } from 'react-icons/ai';
import { BasicNetworkData } from '../NetworkGroups/BasicNetworkDataTypes';
import { loadImage } from 'Utils/fileLoading';
import RoundThumbnailImage from '../RoundThumbnailImage/RoundThumbnailImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserGroup } from '@fortawesome/free-solid-svg-icons';

export function TextDetails({
	network,
	setNetwork,
	setPhoto,
}: {
	network: BasicNetworkData;
	setNetwork: React.Dispatch<React.SetStateAction<BasicNetworkData>>;
	setPhoto: React.Dispatch<React.SetStateAction<File | null>>;
}) {
	const updateTextField =
		(field: keyof BasicNetworkData) =>
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
			setNetwork({ ...network, [field]: event.currentTarget.value });

	const loadProfilePicture = () => {
		loadImage((file, url) => {
			setNetwork({ ...network, network_logo: URL.createObjectURL(file) });
			setPhoto(file);
		});
	};

	return (
		<div className={styles['text-details']}>
			<ContactProfilePicture
				network={network}
				loadProfilePicture={loadProfilePicture}
			/>
			<input
				type="text"
				placeholder="Name"
				value={network.name}
				onInput={updateTextField('name')}
			/>
			<textarea
				name="description"
				placeholder="Description"
				rows={2}
				value={network.description}
				onInput={updateTextField('description')}
			/>
			{/* <AutocompleteInput
        placeholder="Headquarter Location"
        inputClassname={styles["place-input"]}
        containerClassname={styles["place-input-container"]}
        value={network.headQuarter}
        setValue={(value) => {
          setNetwork({ ...network, headQuarter: value });
          // setErrors({ ...errors, origin: "" });
        }}
        autocompleteSuggestions={originCitiesAutocomplete}
      // style={{ borderColor: errors.origin ? "red" : undefined }}
      /> */}		</div>
	);
}


function ContactProfilePicture({
	network,
	loadProfilePicture,
}: {
	network: BasicNetworkData;
	loadProfilePicture: () => void;
}) {
	return (
		<div className={styles['image']}>
			{network.network_logo ? (
				<RoundThumbnailImage
					className={styles['profile-picture']}
					image={network.network_logo || '/images/group-svgrepo-com.svg'}
					size={100}
				/>
			) : (
				<FontAwesomeIcon
					fontSize={60}
					className={styles['profile-picture']}
					icon={faUserGroup}
				/>
			)}

			<button onClick={loadProfilePicture}>
				<AiOutlineEdit />
			</button>
		</div>
	);
}
