// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RangeInput.module.scss */
.RangeInput_range-input-container__ch6uP {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adjust the gap between the slider and text as needed */
  position: relative; /* Set the container to relative positioning */
}

/* Style the range slider (CustomRangeSlider) */
.RangeInput_customRangeSlider__lcKbE {
  width: 100%; /* Make the slider take the full width of the container */
  z-index: 1; /* Place the slider above the value text */
}

/* Style the text */
.RangeInput_rangeValue__sK8P0 {
  font-size: 16px; /* Adjust the font size as needed */
  color: #333; /* Adjust the text color as needed */
  position: absolute; /* Position the text absolutely */
  left: 50%; /* Center the text horizontally */
  transform: translateX(-50%); /* Center the text horizontally */
  z-index: 2; /* Place the text above the slider */
}

.RangeInput_valueTextWithSpacing__iKKf3 {
  top: -30px; /* Adjust the top value for vertical spacing */
  padding: 5px; /* Add padding to the value text */
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/AppearanceSettings/RangeInput.module.scss"],"names":[],"mappings":"AAAA,2BAAA;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA,EAAA,yDAAA;EACA,kBAAA,EAAA,8CAAA;AAAF;;AAGA,+CAAA;AACA;EACE,WAAA,EAAA,yDAAA;EACA,UAAA,EAAA,0CAAA;AAAF;;AAGA,mBAAA;AACA;EACE,eAAA,EAAA,mCAAA;EACA,WAAA,EAAA,oCAAA;EACA,kBAAA,EAAA,iCAAA;EACA,SAAA,EAAA,iCAAA;EACA,2BAAA,EAAA,iCAAA;EACA,UAAA,EAAA,oCAAA;AAAF;;AAGA;EACE,UAAA,EAAA,8CAAA;EACA,YAAA,EAAA,kCAAA;AAAF","sourcesContent":["/* RangeInput.module.scss */\n\n.range-input-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px; /* Adjust the gap between the slider and text as needed */\n  position: relative; /* Set the container to relative positioning */\n}\n\n/* Style the range slider (CustomRangeSlider) */\n.customRangeSlider {\n  width: 100%; /* Make the slider take the full width of the container */\n  z-index: 1; /* Place the slider above the value text */\n}\n\n/* Style the text */\n.rangeValue {\n  font-size: 16px; /* Adjust the font size as needed */\n  color: #333; /* Adjust the text color as needed */\n  position: absolute; /* Position the text absolutely */\n  left: 50%; /* Center the text horizontally */\n  transform: translateX(-50%); /* Center the text horizontally */\n  z-index: 2; /* Place the text above the slider */\n}\n\n.valueTextWithSpacing {\n  top: -30px; /* Adjust the top value for vertical spacing */\n  padding: 5px; /* Add padding to the value text */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"range-input-container": `RangeInput_range-input-container__ch6uP`,
	"customRangeSlider": `RangeInput_customRangeSlider__lcKbE`,
	"rangeValue": `RangeInput_rangeValue__sK8P0`,
	"valueTextWithSpacing": `RangeInput_valueTextWithSpacing__iKKf3`
};
export default ___CSS_LOADER_EXPORT___;
