//authContext.tsx TYPESCRIPT FILE
import {
    createContext,
    useContext,
    useMemo,
    SetStateAction,
    Dispatch,
    useEffect
  } from 'react'
  import {
    TOnboardingPayload,
    TSignInForm,
    TSignUpForm,
    TUserData
  } from 'types/shared'
  import { SECRET_STATE } from 'Utils/auth-utils'
  import { useGoogleAuth } from 'hooks/auth/google-oauth'
  import { useRouter } from 'hooks/useRouter'
  
  import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
  
  type TAuthContext = {
    registerUser?: (payload: TSignUpForm) => void
    loginUser?: (payload: TSignInForm) => void
    isAuthenticated?: boolean
    user?: TUserData | null
    completeUserOnboarding?: (payload: TOnboardingPayload) => void
    userHasCompletedOnboarding?: boolean
    token?: string | null
    setToken?: Dispatch<SetStateAction<null | string>>
    setUser?: Dispatch<SetStateAction<null | TUserData>>
    googleAuthentication?: () => void
    clearStorage?: () => void
    resetPassword?: (data: any) => void
  }
  const AuthContext = createContext<TAuthContext>({})
  
  //to be able to invoke it anywhere in the codebase
  export const useAuthContext = () => useContext(AuthContext)
  
  export default function AuthProvider ({
    children
  }: {
    children: JSX.Element | JSX.Element[]
  }) {
    const router = useRouter()
    const { finalizeGoogleAuth } = useGoogleAuth()
  
    const isAuthenticated = useIsAuthenticated()
    const GoogleOauthCode = router.query.get('code')
    const GoogleOauthState = router.query.get('state')
    // Listening for Google Oauth redirection
    // When a user is redirected from Google Auth, this is the useEffect that is triggered.
    useEffect(() => {
        if (isAuthenticated) return;
      
        if (!GoogleOauthCode || GoogleOauthState !== SECRET_STATE) return;
        
        const googleLogin = async () => {
          // Since GoogleOauthCode is checked for null, TypeScript understands it can only be a string here
          await finalizeGoogleAuth(GoogleOauthCode);
        }
      
        googleLogin();
      }, [GoogleOauthCode, GoogleOauthState, isAuthenticated]);
  
    const memoizedValues = useMemo(() => {
      return {
        isAuthenticated
      }
    }, [isAuthenticated])
  
    return (
      <AuthContext.Provider value={memoizedValues}>
        {children}
      </AuthContext.Provider>
    )
  }
