import React, { CSSProperties, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import styles from "./Conversation.module.scss";
import { UserData } from "Pages/Home/HomePageTypes";


import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { useDocumentTitle } from "hooks/useSEO";
import { apiRoutes } from "services/routes";
import { apiInstance } from "services/apiInstance";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

import { ConversationHeader } from "./ConversationHeader/ConversationHeader";
import MessageInput from "./MessageInput/MessageInput";
import MessagesList from "./MessagesList/MessagesList";

type MessageData = {
    id: number;
    from_user_id: number;
    text: string;
    time: string;
};

type MessageContextMenuData = {
    message: MessageData;
    style: CSSProperties;
};

export default function Conversation({
    setLastMessage,
}: {
    setLastMessage: (
        message: {
            text: string;
            time: string;
            from_user_id: number;
        },
        toUserWithId: number
    ) => void;
}) {
    let { userHandle } = useParams<{ userHandle: string }>();
    let { currentUser } = useCurrentUserDetails();

    let [messages, setMessages] = useApi<Array<MessageData>>(
        apiRoutes.GET_MESSAGES(userHandle),
        [userHandle],
        true
    );
    let [user] = useApi<UserData>(
        apiRoutes.GET_USER(userHandle),
        [userHandle],
        true
    );
    let [messageBeingTyped, setMessageBeingTyped] = useState("");
    let [contextMenu, setContextMenu] = useState<MessageContextMenuData | null>(
        null
    );

    useDocumentTitle(
        `Messaging ${
            user && !(user instanceof ApiError) ? user.name : userHandle
        }`,
        [user, userHandle]
    );

    useEffect(() => {
        let closeMenu = () => setContextMenu(null);
        document.addEventListener("click", closeMenu);
        return () => document.removeEventListener("click", closeMenu);
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (messages && !(messages instanceof ApiError)) {
                apiInstance
                    .get(
                        `${apiRoutes.GET_MESSAGES(userHandle)}?newerThan=${
                            messages.length > 0 ? messages[0].id : 0
                        }`
                    )
                    .then((response) => {
                        const newMessages = response.data.data;
                        if (
                            messages &&
                            !(messages instanceof ApiError) &&
                            newMessages &&
                            newMessages instanceof Array
                        ) {
                            setMessages([...newMessages, ...messages]);
                        }
                    });
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [userHandle, messages]);

    let { postToApi } = usePostToAPI();

    const sendMessage = async () => {
        if (messageBeingTyped !== "") {
            let response = await postToApi<MessageData>(
                apiRoutes.SEND_MESSAGES(userHandle),
                {
                    message: messageBeingTyped,
                }
            );

            if (response.ok) {
                const newMessage: MessageData = response.data;

                if (messages instanceof Array) {
                    setMessages([newMessage, ...messages]);
                }

                setLastMessage(
                    {
                        text: newMessage.text,
                        from_user_id: newMessage.from_user_id,
                        time: newMessage.time,
                    },
                    (user as UserData).id
                );
            }

            setMessageBeingTyped("");
        }
    };

    if (currentUser && currentUser.handle === userHandle) {
        return <Redirect to="/messages" />;
    }

    return (
        <div className={styles["conversation"]}>
            <ConversationHeader userHandle={userHandle} user={user} />
            <MessagesList
                contextMenu={contextMenu}
                messages={messages}
                setMessages={setMessages}
                setContextMenu={setContextMenu}
            />
            <MessageInput
                setMessageBeingTyped={setMessageBeingTyped}
                messages={messages}
                messageBeingTyped={messageBeingTyped}
                sendMessage={sendMessage}
            />
        </div>
    );
}
