// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  position: absolute;
  z-index: 1000;
}
.menu .menu-content {
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Networks/MyMenu.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;AACJ;AACI;EACE,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,yCAAA;AACN","sourcesContent":[".menu {\n    position: absolute;\n    z-index: 1000;\n  \n    .menu-content {\n      background: white;\n      border: 1px solid #ccc;\n      border-radius: 4px;\n      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
