import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import React, { useState, useEffect } from 'react';
import { apiRoutes } from 'services/routes';
import SettingsSubpage from '../SettingsSubpage/SettingsSubpage';
import { useDocumentTitle } from 'hooks/useSEO';

interface Contact {
  email: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  phone_number: string;
  name: string;
  count: number;
}

function DuplicateContacts() {
  useDocumentTitle("Duplicate Contacts")

  const [duplicateContacts, setDuplicateContacts] = useState<Contact[]>([]);
  const apiInstance = useAxiosPrivate()

  useEffect(() => {
    // Fetch the duplicate contacts from the server
    async function fetchDuplicateContacts() {
      const response = await apiInstance.get(apiRoutes.GET_CONTACTS_DUPLICATES);
      const data = await response.data?.data;
      setDuplicateContacts(data);
    }
    fetchDuplicateContacts();
  }, []);

  return (
    <SettingsSubpage title="Duplicate Contacts">
      {duplicateContacts.length > 0 ? (
        <div>
          <p>There are {duplicateContacts.length} duplicate contacts:</p>
          <ul>
            {duplicateContacts.map(contact => (
              <li key={contact.email}>
                Email: {contact.email}<br />
                LinkedIn: {contact.linkedin}<br />
                Facebook: {contact.facebook}<br />
                Instagram: {contact.instagram}<br />
                Name: {contact?.name}<br />
                Phone number: {contact.phone_number}<br />
                Count: {contact.count}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>There are no duplicate contacts.</p>
      )}
    </SettingsSubpage>
  );
}

export default DuplicateContacts;
