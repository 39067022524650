import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';

interface Address {
  user_input: string; // Add this for the initial address input
  formatted_address: string;
  street_number: string;
  street_name: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  lat: string; // Or number
  lng: string; // Or number
  // Removed the optional markers as these will be passed as props
  content_type: string;
  object_id: number;
}

interface AddressManagerProps {
  content_type: string;
  object_id: number;
}

const AddressManager: React.FC<AddressManagerProps> = ({ content_type, object_id }) => {
  const [address, setAddress] = useState<Address>({
    user_input: '',
    formatted_address: '',
    street_number: '',
    street_name: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    lat: '',
    lng: '',
    content_type, // Set from props
    object_id,   // Set from props
  });
  const [feedback, setFeedback] = useState<string>('');
  const [isAddressExpanded, setIsAddressExpanded] = useState<boolean>(false);
  const [showEnterAddress, setShowEnterAddress] = useState<boolean>(true);
  const [addressId, setAddressId] = useState<number | null>(null); // To store the address ID
  const [shouldCreateOrUpdate, setShouldCreateOrUpdate] = useState(false);

  const privateAPIInstance = useAxiosPrivate();

  const handleCreateOrUpdate = async () => {
    try {
      let response;
      if (addressId) {
        response = await privateAPIInstance.put(apiRoutes.EDIT_ADDRESS(addressId), address);
      } else {
        response = await privateAPIInstance.post(apiRoutes.CREATE_NEW_ADDRESS, address);
        if (response.data && response.data.data) {
          setAddressId(response.data.data.id); // Store the new address ID
        }
      }
      setFeedback('Address saved successfully.');
    } catch (error) {
      setFeedback('Error saving address.');
    }
  };

  const handleAddressLookup = async () => {
    try {
      const response = await fetch(`http://localhost:3400/expand-address?address=${encodeURIComponent(address.user_input)}`);
      const data = await response.json();
      if (data) {
        setAddress({ ...address, ...data });
        setIsAddressExpanded(true);
        setShowEnterAddress(false);
        setShouldCreateOrUpdate(true); // Flag to indicate that an update is needed
      }
    } catch (error) {
      console.error('Error expanding address:', error);
      setFeedback('Error looking up address.');
    }
  };

  useEffect(() => {
    if (shouldCreateOrUpdate) {
      // Call the function to create or update the address
      handleCreateOrUpdate();
      setShouldCreateOrUpdate(false); // Reset the flag
    }
  }, [shouldCreateOrUpdate, address]); // Dependency array

  
  const handleCreate = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const response = await privateAPIInstance.post(apiRoutes.CREATE_NEW_ADDRESS, address);
      setFeedback('Address created successfully.');
      // Handle the response
    } catch (error) {
      setFeedback('Error creating address.');
      // Handle the error
    }
  };

  const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress(prev => ({ ...prev, user_input: e.target.value }));
  };

  const handleDone = () => {
    // Hide detailed fields and show the 'Enter Address' field again
    setIsAddressExpanded(false);
    setShowEnterAddress(false);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Update state and then call handleCreateOrUpdate
    await new Promise(resolve => {
      setAddress(prev => {
        const updatedAddress = { ...prev, [name]: value };
        resolve(updatedAddress);
        return updatedAddress;
      });
    });
  
    // Now that state is updated, call handleCreateOrUpdate
    await handleCreateOrUpdate();
  };
  

  // Form for creating or editing an address
  return (
    <div>
  <form onSubmit={handleCreate}>
  {showEnterAddress && (
          <input
            type="text"
            name="user_input"
            value={address.user_input}
            onChange={handleUserInput}
            onBlur={handleAddressLookup}
            placeholder="Enter Address"
          />
        )}


        {isAddressExpanded && (
          <>
  <input
    type="text"
    name="formatted_address"
    value={address.formatted_address}
    onChange={handleChange}
    placeholder="Formatted Address"
  />

  <input
    type="text"
    name="street_number"
    value={address.street_number}
    onChange={handleChange}
    placeholder="Street Number"
  />
  <input
    type="text"
    name="street_name"
    value={address.street_name}
    onChange={handleChange}
    placeholder="Street Name"
  />
  <input
    type="text"
    name="city"
    value={address.city}
    onChange={handleChange}
    placeholder="City"
  />
  <input
    type="text"
    name="state"
    value={address.state}
    onChange={handleChange}
    placeholder="State"
  />
  <input
    type="text"
    name="country"
    value={address.country}
    onChange={handleChange}
    placeholder="Country"
  />
  <input
    type="text"
    name="postal_code"
    value={address.postal_code}
    onChange={handleChange}
    placeholder="Postal Code"
  />
  <input
    type="number"
    name="lat"
    value={address.lat}
    onChange={handleChange}
    placeholder="Latitude"
  />
  <input
    type="number"
    name="lng"
    value={address.lng}
    onChange={handleChange}
    placeholder="Longitude"
    />
    <input
      type="hidden"
      name="content_type"
      value={address.content_type}
    />
    <input
      type="hidden"
      name="object_id"
      value={address.object_id.toString()}
    />
</>
  )}

{isAddressExpanded ? (
          <button type="button" onClick={handleDone}>Done</button>
        ) : (
          // Submit button hidden if 'isAddressExpanded' is true
          // <button type="submit">Submit</button>
          <div></div>
        )}
        
        </form>
      {feedback && <p>{feedback}</p>}
      {/* List existing addresses with edit and delete options */}
    </div>
  );
};

export default AddressManager;
