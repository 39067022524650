import { MinimalUserData } from "../../Home/HomePageTypes";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MuiReplaceCustomButton from '../../../Components/MuiReplaceCustomButton/MuiReplaceCustomButton';
import CustomDialogTitle from '../../../Components/CustomDialogTitle/CustomDialogTitle';  // Add your custom import here
import CustomDialog from '../../../Components/CustomDialog/CustomDialog';
import CustomDialogActions from '../../../Components/CustomDialogActions/CustomDialogActions';

export const MemberConnections = ({
    member_connections,
    pending_members,
    removeMember,
    isOwner,
    isNetworkOwner
}: {
    member_connections: MinimalUserData[];
    pending_members?: MinimalUserData[];
    removeMember: (type: 'user' | 'contact' | 'place', memberId: Number) => void;
    isOwner: (id: Number) => boolean;
    isNetworkOwner: () => boolean;
}) => {
    const [active, setActive] = useState<Number>(0);
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = useState<Number>(0);

    const handleClickOpen = (id: Number) => {
        setSelected(id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const confirmDelete = () => {
        removeMember('user', selected);
        setOpen(false);
    }
    let history = useHistory();
    return (
        <div>
            <div className="px-4 pt-4 pb-2 text-xs font-medium text-gray-500 uppercase">
                Users
            </div>
            <div className="overflow-y-auto h-40 border-solid border-2">
                {(isNetworkOwner() && pending_members && pending_members.length > 0) && (
                    pending_members.map((data) => (
                        <div
                            className={`flex justify-between items-center px-4 py-2 space-x-3 cursor-pointer hover:bg-gray-200`}
                            onMouseEnter={() => setActive(data.id)}
                            onMouseLeave={() => setActive(0)}
                            key={data.id}
                        >
                            <div className="flex items-center">
                                <img
                                    src={data.smallProfilePicture ? data.smallProfilePicture : "/images/default-user-image.svg"}
                                    alt={data.name ? data.name : ''}
                                    className="w-8 h-8 rounded-full"
                                    onClick={() => history.push(`/@${data?.username}`)}
                                />
                                <span onClick={() => history.push(`/@${data?.username}`)} className="text-sm font-medium mx-2">{data.name}</span>
                            </div>
                            <div className="text-sm italic text-gray-700">Pending Accept</div>
                            {/* {(active === data.id && !isOwner(data.id) && isNetworkOwner()) && <div onClick={() => handleClickOpen(data.id)} className="text-sm italic text-gray-700 hover:text-gray-900 cursor-pointer">remove</div>} */}
                        </div>
                    ))
                )}
                {member_connections.length > 0 && (
                    member_connections.map((data) => (
                        <div
                            className={`flex justify-between items-center px-4 py-2 space-x-3 cursor-pointer hover:bg-gray-200`}
                            onMouseEnter={() => setActive(data.id)}
                            onMouseLeave={() => setActive(0)}
                            key={data.id}
                        >
                            <div className="flex items-center">
                                <img
                                    src={data.smallProfilePicture ? data.smallProfilePicture : "/images/default-user-image.svg"}
                                    alt={data.name ? data.name : ''}
                                    onClick={() => history.push(`/@${data?.username}`)}
                                    className="w-8 h-8 rounded-full"
                                />
                                <span onClick={() => history.push(`/@${data?.username}`)} className="text-sm font-medium mx-2">{data.name}</span>
                            </div>
                            {(active === data.id && !isOwner(data.id) && isNetworkOwner()) && <div onClick={() => handleClickOpen(data.id)} className="text-sm italic text-gray-700 hover:text-gray-900 cursor-pointer">remove</div>}
                        </div>
                    ))
                )}
                <CustomDialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CustomDialogTitle id="alert-dialog-title">
                        {"Are you sure to remove " + member_connections.find(x => x.id === selected)?.name + '?'}
                    </CustomDialogTitle>
                    <CustomDialogActions>
                    <MuiReplaceCustomButton 
                            onClick={handleClose}
                            color="green">
                                No
                        </MuiReplaceCustomButton>
                        <MuiReplaceCustomButton 
                            onClick={confirmDelete}
                            color="red"
                            >
                                Yes
                        </MuiReplaceCustomButton>
                    </CustomDialogActions>
                </CustomDialog>
            </div>
        </div>
    )
};
