import React, {

	useMemo,
	useRef,
	useState,
} from 'react';
import { convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import editorStyles from './CustomComponentMentionEditor.module.css';

import '@draft-js-plugins/mention/lib/plugin.css';
import { useConvertEditorState } from './useConvertEditorState'; // Adjust the path as necessary
import MentionSuggestionsComponent from './MentionSuggestionsComponent'; // Adjust the path as necessary

export interface JournalTemplate {
	name: string;
	avatar: string;
	id: number;
	type: 'contact' | 'connection' | 'place';
	profile?: any;
}

export default function CustomEditor({
	edited,
	editData,
	storeData,
	template,
}: {
	edited?: boolean;
	editData: string;
	storeData: React.Dispatch<React.SetStateAction<null>>;
	template?: JournalTemplate;
}) {
	const ref = useRef<Editor>(null);
	const [editorState, setEditorState] = useConvertEditorState(editData);

	const [suggestions, setSuggestions] = useState<JournalTemplate[]>([]);

	const { plugins } = useMemo(() => {
		// const mentionPlugin = createMentionPlugin({
		//     entityMutability: 'IMMUTABLE',
		//     theme: mentionsStyles,
		//     mentionPrefix: '@',
		//     supportWhitespace: true,
		//   });

		const mentionPlugin = createMentionPlugin({
			mentionTrigger: ['@', '#'],
			mentionComponent(mentionProps) {
				return (
					<span
						className={mentionProps.className}
					>
						{mentionProps.children}
					</span>
				);
			},
		});
		const { MentionSuggestions } = mentionPlugin;
		const plugins = [mentionPlugin];
		return { plugins, MentionSuggestions };
	}, []);


	const onEditorChange = (data: any) => {
		setEditorState(data);

		const contentState = data.getCurrentContent();
		const stringified_raw = convertToRaw(contentState);
		storeData(stringified_raw);
	};
	
	const extractMentions = () => {
		const contentState = editorState.getCurrentContent();
		const raw = convertToRaw(contentState);
		let mentionedUsers = [];
		for (let key in raw.entityMap) {
				const entity = raw.entityMap[key];
				if (entity.type === "mention") {
						mentionedUsers.push(entity.data.mention);
				}
		}
		console.log("memtioned users",mentionedUsers); // Debugging purpose
		return mentionedUsers;
};


	return (
		<div
			className={`${editorStyles.editor} ${editorStyles.medievalJournalItem}`}
			onClick={() => {
				ref.current!.focus();
			}}
		>
			<Editor
				editorKey={'editor'}
				editorState={editorState}
				onChange={onEditorChange}
				plugins={plugins}
				ref={ref}
			/>
			<MentionSuggestionsComponent
				plugins={plugins}
				suggestions={suggestions}
				setSuggestions={setSuggestions}
				extractMentions={extractMentions}
			/>
		</div>
	);
}

