import React from "react";
import { Link, Route } from "react-router-dom";

import styles from "./Messages.module.scss";
import Conversation from "./Conversation/Conversation";
import { OverlayingLoadingSpinner } from "../../Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner";
import ConversationPreview from "./ConversationPreview/ConversationPreview";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import HttpErrorMessage from "Components/HttpErrorMessage/HttpErrorMessage";
import { CurrentUserDetails } from "types/shared";
import { apiRoutes } from "services/routes";
import { useDocumentTitle } from "hooks/useSEO";
import { ApiError, useAutoRefreshingApiData } from "hooks/useAPI";

type BasicUserData = {
    id: number;
    handle: string;
    name: string;
    profilePicture: string | null;
};

export type ConversationData = {
    user: BasicUserData;
    lastMessage: {
        text: string;
        time: string;
        seen: boolean;
    };
};

export default function Messages() {
    useDocumentTitle("Messages");

    let [conversations, setConversations] = useAutoRefreshingApiData<
        Array<ConversationData>
    >(apiRoutes.GET_CONVERSATIONS);
    let [otherUsers] = useAutoRefreshingApiData<Array<BasicUserData>>(
        apiRoutes.GET_UNMESSAGED_CONNECTIONS
    );

    const isApiError = (response: any): response is ApiError => {
        return response instanceof ApiError;
    };

    /**
     * Intended to set the last message of a conversation when
     */
    const setConversationLastMessage = (
        lastMsg: {
            text: string;
            time: string;
            from_user_id: number;
        },
        toUserWithId: number
    ): false | void => {
        if (
            conversations instanceof Array &&
            conversations.some(
                (conversation) => conversation.user.id === toUserWithId
            )
        ) {
            setConversations((prevConversations) => {
                if (
                    prevConversations === null ||
                    isApiError(prevConversations)
                ) {
                    return prevConversations;
                }
                return {
                    ...prevConversations,
                    data: prevConversations.map(
                        (conversation: ConversationData) =>
                            conversation.user.id === toUserWithId
                                ? {
                                      ...conversation,
                                      lastMessage: {
                                          seen: true,
                                          text: lastMsg.text,
                                          time: lastMsg.time,
                                      },
                                  }
                                : conversation
                    ),
                };
            });
        }
    };

    return (
        <main className={styles["messages"]}>
            <div className={styles["messages-list"]}>
                {conversations || otherUsers ? (
                    <>
                        {conversations instanceof ApiError ? (
                            <HttpErrorMessage
                                error={conversations}
                                hideBorderAndBackground
                            />
                        ) : (
                            conversations &&
                            conversations?.length > 0 && (
                                <>
                                    {conversations.map((conversation) => (
                                        <ConversationPreview
                                            conversation={conversation}
                                            key={conversation.user.id}
                                        />
                                    ))}
                                    <br />
                                </>
                            )
                        )}
                        {otherUsers instanceof ApiError ? (
                            <HttpErrorMessage
                                error={otherUsers}
                                hideBorderAndBackground
                            />
                        ) : otherUsers && otherUsers.length > 0 ? (
                            <>
                                <h4>Other Connections</h4>
                                {otherUsers.map((user) => (
                                    <Link
                                        to={`/messages/${user.handle}`}
                                        key={user.id}
                                    >
                                        <div
                                            className={styles["other-contact"]}
                                        >
                                            <RoundThumbnailImage
                                                image={
                                                    user.profilePicture ||
                                                    "/images/default-user-image.svg"
                                                }
                                            />
                                            <div
                                                className={styles["user-name"]}
                                            >
                                                {user.name}
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </>
                        ) : null}
                        {otherUsers instanceof Array &&
                            conversations instanceof Array &&
                            otherUsers.length === 0 &&
                            conversations.length === 0 && (
                                <p
                                    style={{
                                        color: "grey",
                                        padding: "20px",
                                        textAlign: "center",
                                    }}
                                >
                                    You have no conversations or connections yet
                                </p>
                            )}
                    </>
                ) : (
                    <OverlayingLoadingSpinner />
                )}
            </div>
            <ConversationContainer
                setConversationLastMessage={setConversationLastMessage}
            />
        </main>
    );
}

function ConversationContainer({
    setConversationLastMessage,
}: {
    setConversationLastMessage: (
        lastMsg: {
            text: string;
            time: string;
            from_user_id: number;
        },
        toUserWithId: number
    ) => false | void;
}) {
    // Depending on whether it's a mobile device or not, the
    // conversation is displayed fullscreen or to one side.
    // This is achieved using CSS and media queries
    return (
        <div className={styles["conversation-container"]}>
            <Route path="/messages/:userHandle">
                <Conversation setLastMessage={setConversationLastMessage} />
            </Route>
            <Route path="/messages" exact>
                <p className={styles["placeholder"]}>
                    Select a conversation on the left to open messages
                </p>
            </Route>
        </div>
    );
}
