import React from "react";

import styles from "./LocationSharingButton.module.scss";
import SmallLoadingSpinner from "../../../Components/SmallLoadingSpinner/SmallLoadingSpinner";
import TickAnimation from "../../../Components/TickAnimation/TickAnimation";
import { UserData } from "../../Home/HomePageTypes";
import { PostToApiFnType } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export type LocationSharingStatus =
    | boolean
    | "requested"
    | "request received"
    | "loading";

export default function LocationSharingButton({
    status,
    onClick,
}: {
    status: LocationSharingStatus;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
    const IconDiv = () => (
        <div className={styles["icon"]}>
            <div></div>
            <div></div>
        </div>
    );

    let props = { onClick };

    switch (status) {
        case true:
            return (
                <button
                    className={`${styles["location-sharing-button"]} ${styles["stop-button"]}`}
                    {...props}
                >
                    <IconDiv />
                    Stop sharing location
                </button>
            );
        case "loading":
            return (
                <button
                    className={`${styles["location-sharing-button"]} ${styles["loading-status"]}`}
                    {...props}
                    disabled
                >
                    <SmallLoadingSpinner
                        primaryColor="white"
                        secondaryColor="#fff3"
                        size={16}
                        margin="0 6px 0 0"
                    />
                    Loading
                </button>
            );
        case "requested":
            return (
                <button
                    className={`${styles["location-sharing-button"]} ${styles["cancel-request"]}`}
                    {...props}
                >
                    <TickAnimation
                        margin="0 6px 3px 0"
                        color="white"
                        lineThickness="2px"
                    />
                    Location request sent
                </button>
            );
        case false:
            return (
                <button
                    className={`${styles["location-sharing-button"]} ${styles["send-request"]}`}
                    {...props}
                >
                    <IconDiv />
                    Request user location
                </button>
            );
        case "request received":
            return (
                <button
                    className={`${styles["location-sharing-button"]} ${styles["send-request"]}`}
                    {...props}
                >
                    <TickAnimation
                        margin="0 6px 3px 0"
                        color="white"
                        lineThickness="2px"
                    />
                    Allow sharing location
                </button>
            );
    }
}

export function onLocationSharingRequestButtonClicked<T extends UserData>(
    updateUser: (user: T) => void,
    user: T,
    postToApi: PostToApiFnType
) {
    const updateStatusTo =
        (newStatus: LocationSharingStatus) => (response: Response) => {
            if (response.status < 300) {
                updateUser({ ...user, sharingLocations: newStatus });
            }
        };

    switch (user.sharingLocations) {
        case true:
            postToApi(apiRoutes.STOP_SHARING_LOCATION(user.handle)).then(() =>
                updateStatusTo(false)
            );
            break;
        case "request received":
            postToApi(
                apiRoutes.ACCEPT_REJECT_END_LOCATION_REQUESTS(user.handle)
            ).then(() => updateStatusTo(true));
            break;
        case "requested":
            postToApi(
                apiRoutes.CREATE_OR_CANCEL_LOCATION_SHARING_REQUEST(
                    user.handle
                ),
                undefined,
                "delete"
            ).then(() => updateStatusTo(false));
            break;
        case false:
            postToApi(
                apiRoutes.CREATE_OR_CANCEL_LOCATION_SHARING_REQUEST(user.handle)
            ).then(() => updateStatusTo("requested"));
            break;
        case "loading":
            break;
    }

    updateUser({ ...user, sharingLocations: "loading" });
}
