import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Define your custom icon with the base64 image
const customIcon = L.icon({
  iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-green.png',
  shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',

  iconSize:     [38, 95], // size of the icon
  shadowSize:   [50, 64], // size of the shadow
  iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});


type LeafletMapProps = {
  zoom: number;
  initialLat: number;
  initialLon: number;
  onLocationChange: (lon: number, lat: number) => void;
};

const LeafletMap: React.FC<LeafletMapProps> = ({ zoom, initialLat, initialLon, onLocationChange }) => {
  const [position, setPosition] = useState<[number, number]>([initialLat, initialLon]);

  const UpdateMapView = () => {
    const map = useMap();
    useEffect(() => {
      map.setView([initialLat, initialLon], zoom);
    }, [initialLat, initialLon, zoom, map]);

    return null; // This component does not render anything
  };
  

  useEffect(() => {
      setPosition([initialLat, initialLon]);
  }, [initialLat, initialLon]);

  const LocationMarker = () => {
      useMapEvents({
          click(e) {
              console.log(`Map clicked at Lat: ${e.latlng.lat}, Lon: ${e.latlng.lng}`);
              setPosition([e.latlng.lat, e.latlng.lng]);
              onLocationChange(e.latlng.lat, e.latlng.lng);
          },
      });

    return position === null ? null : (
      <Marker 
      icon={customIcon} // Use the custom icon here
        position={position} 
        draggable={true}
        riseOnHover 
        eventHandlers={{
        dragstart: () => {
          console.log('Marker drag started');
        },
        dragend: (e) => {
          const latlng = e.target.getLatLng();
          console.log(`Marker dragged to Lat: ${latlng.lat}, Lon: ${latlng.lng}`);
          setPosition([latlng.lat, latlng.lng]);
          onLocationChange(latlng.lat, latlng.lng);
        },
      }} />
    );
  };

  return (
    <MapContainer 
      center={[initialLat, initialLon]} 
      zoom={zoom} 
      scrollWheelZoom={true} 
      style={{  height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
            <UpdateMapView /> {/* Add this line */}
      <LocationMarker />
    </MapContainer>
  );
};

export default LeafletMap;
