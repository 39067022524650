import React, { useState } from "react";
import DeleteSkill from "Components/DeleteSkill/DeleteSkill";
import { DispatchAction } from "../../Pages/Home/reducer";
import styles from "./SkillDisplayAndDelete.module.scss";
import { ACTIONS } from '../../Pages/Home/reducerActions';

interface SkillDisplayAndDeleteProps {
  id?: number | undefined;
  skills: { name: string, canEdit: boolean }[]; // Updated the skills array to include 'canEdit'
  target: "user" | "contact";
  onDelete: (skillName: string) => void;
  dispatch: React.Dispatch<DispatchAction>;
  highlightedSkill?: string;
}

const SkillDisplayAndDelete: React.FC<SkillDisplayAndDeleteProps> = ({
  skills,
  id,
  target,
  onDelete,
  dispatch,
  highlightedSkill,
}) => {
  const [hoveredSkill, setHoveredSkill] = useState<string | null>(null);

  const triggerSearch = (keywords: string) => {
    dispatch({ type: ACTIONS.START_SKILL_SEARCH, skillName: keywords });
  };

  const checkIfHighlighted = (element: string) => 
    highlightedSkill === element ? styles["highlighted"] : '';

    if (skills && skills.length === 0) {
      return null;
    }
    
    return (
      <div className={styles["skill-display-and-delete-container"]}>
        <ul className={styles["skill-display-and-delete-list"]}>

        {skills.map((skill, index) => (
          <li
            key={`${skill.name}-${index}`}
            className={`${checkIfHighlighted(skill.name)} ${styles["skill-item"]}`}
            onMouseEnter={() => setHoveredSkill(skill.name)}
            onMouseLeave={() => setHoveredSkill(null)}
          >

            <button
              className={`${checkIfHighlighted(skill.name)} ${styles["clickable-name"]} clickable-name`}
              onClick={() => triggerSearch(skill.name)}
            >
              {skill.name}
            </button>
            {hoveredSkill === skill.name && (
              <DeleteSkill
                target={target}
                skill={skill}
                onDelete={onDelete}
                id={target === "contact" ? id : undefined}
              />
            )}
          </li>
        ))}
        
        </ul>
      </div>
    );
            }

export default SkillDisplayAndDelete;
