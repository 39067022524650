import React from "react";

import styles from "./FloatingControls.module.scss";
import { BsFilter as FilterIcon } from "react-icons/bs";
import ExpandableButton from "./ExpandableButton/ExpandableButton";
import TileLayerSelector from "./TileLayerSelector/TileLayerSelector";
import { CheckboxList } from "../../../Components/CheckboxList/CheckboxList";
import { DispatchAction } from "../reducer";
import { ShowMyLocation } from "./ShowMyLocation/ShowMyLocation";
import ControlActions from './ControlActions';
import { useFloatingControlActions } from '../../../hooks/useFloatingControlActions';


import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";

/**
 * The controls which float on the bottom-right corner of the screen.
 * @param props
 * @returns
 * @constructor
 * @example
 * <FloatingControls
 *   state={state}
 *   dispatch={dispatch}
 * />
 */
export function FloatingControls({
    state,
    dispatch,
}: {
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {

    const { setTileLayer, showMyLocation } = useFloatingControlActions(dispatch);

    return (
        <div className={styles["floating-controls"]} id="floatingControls">
            <ControlActions dispatch={dispatch} />
            <ExpandableButton collapseOnClick={false} expandOnHover={true}>
                <div id="floatingFilters">
                    <FilterIcon />
                </div>
                <CheckboxList
                    selected={state.selectedLayers}
                    elements={["Users", "Contacts", "Locations", "Travel Plans"]}
                    onChange={(layers) => dispatch({ type: ACTIONS.selectedLayers, value: layers })}
                />
            </ExpandableButton>
            <TileLayerSelector
                setTileLayer={setTileLayer}
                tileLayer={state.tileLayer}
            />
            <ShowMyLocation updateLocation={showMyLocation} />
        </div>
    );
}
