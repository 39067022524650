import React from "react";

import styles from "./ContactMarkerPopup.module.scss";
import { ContactData, MinimalContactData, UserData } from "../../HomePageTypes";
import { getContextMenuStyle } from "Components/ContextMenu/ContextMenu";
import OptionsButton from "Components/OptionsButton/OptionsButton";
import { DispatchAction } from "../../reducer";
import { isNotVisible } from "../../visibility";
import { MarkerConsentMessage } from "../../MarkerConsentMessage/MarkerConsentMessage";
import PopupLoadingSpinner from "../../PopupLoadingSpinner/PopupLoadingSpinner";
import { FullContactPopupContent } from "./FullContactPopupContent";
import PopupLoadingError from "../../PopupLoadingError/PopupLoadingError";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { apiRoutes } from "services/routes";
import handleContactConsentRequest from "./handleContactConsentRequest";
import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


export function ContactMarkerPopup({
    contact,
    state,
    dispatch,
}: {
    contact: MinimalContactData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullContact, setFullContact] = useApi<ContactData>(
        apiRoutes.UPDATE_OR_DELETE_OR_GET_CONTACT(contact.id)
    );

    let { postToApi } = usePostToAPI();


    const onOptionsButtonClicked = (event: React.MouseEvent) => {
        event.stopPropagation();
        let style = getContextMenuStyle(event);
        dispatch({
            type: ACTIONS.contextMenu,
            value: { style, contact, type: "contact" },
        });
    };

    const updateContact = (contact: MinimalContactData | ContactData) =>
        dispatch({ type: ACTIONS.UPDATE_CONTACT, contact });

        console.log(contact.ownerName); // Inside ContactMarkerPopup

    return (
        <div
            className={`${styles["contact-popup-content"]}`}
        >
            {
                <>
                    {isNotVisible(contact.visibility) ? (
                        <MarkerConsentMessage
                            visibility={contact.visibility}
                            ownerName={contact.ownerName}
                            handleConsentRequest={() =>
                                handleContactConsentRequest(
                                    updateContact,
                                    contact,
                                    postToApi
                                )
                            }
                            contactOrLocation="contact"
                        />
                    ) : fullContact === null ? (
                        <PopupLoadingSpinner />
                    ) : fullContact instanceof ApiError ? (
                        <PopupLoadingError error={fullContact} />
                    ) : (
                        <FullContactPopupContent
                            contact={fullContact}
                            setContact={setFullContact as React.Dispatch<React.SetStateAction<UserData | ContactData | ApiError | null>>}
                            state={state}
                            dispatch={dispatch}
                        />
                    )}
                    <OptionsButton onClick={onOptionsButtonClicked} />
                </>
            }
        </div>
    );
}
