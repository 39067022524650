import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useRouter } from 'hooks/useRouter'
import styles from './Settings.module.scss'
import { OverlayingLoadingSpinner } from 'Components/OverlayingLoadingSpinner/OverlayingLoadingSpinner'
import { OwnedObject, Point, Skill } from '../Home/HomePageTypes'
import HttpErrorMessage from 'Components/HttpErrorMessage/HttpErrorMessage'
import AppearanceSettings from './AppearanceSettings/AppearanceSettings'
import { ProfileSettings } from './ProfileSettings/ProfileSettings'
import PrivacySettings from './PrivacySettings/PrivacySettings'
import { UserAppearanceConfig } from '../../types/shared'
import { ApiError, useApi, usePostToAPI } from 'hooks/useAPI'
import { useDocumentTitle } from 'hooks/useSEO'
import { apiRoutes } from 'services/routes'
import { SettingsAccordion } from './SettingsAccordion/SettingsAccordion'
import { useCurrentUserDetails } from 'Context/CurrentUserDetailsContext'
import useSignOut from 'react-auth-kit/hooks/useSignOut'

export type PersonalProfileData = {
  id: number
  first_name: string
  last_name: string
  occupation: string
  bio: string
  skills: Array<Skill>
  objects: Array<OwnedObject>
  profilePicture: string | null
  coordinates: Point | null
  email: string
  phoneNumber: string
  privacy: UserPrivacy
  facebook: string
  instagram: string
  linkedin: string
  youtube: string
  website: string
  twitter: string
}

export type UserPrivacy = {
  name: PrivacyLevel
  age: PrivacyLevelWithPrivate
  bio: PrivacyLevel
  profilePicture: PrivacyLevel
  occupation: PrivacyLevel
  email: PrivacyLevelWithPrivate
  phoneNumber: PrivacyLevelWithPrivate
  skills: PrivacyLevelWithPrivate
  objects: PrivacyLevelWithPrivate
  facebook: PrivacyLevel
  instagram: PrivacyLevel
  linkedin: PrivacyLevel
  youtube: PrivacyLevel
  website: PrivacyLevel
  twitter: PrivacyLevel
}

export type PrivacyLevel = 'Share with connections' | 'Public'

export type PrivacyLevelWithPrivate = PrivacyLevel | 'Private'

export default function Settings () {
  useDocumentTitle('Settings')
  const signOut = useSignOut()
  const router = useRouter()
  const handleLogout = useCallback(() => {
    signOut()
    router.push('/')
  }, [])

  let { currentUser, setCurrentUser } = useCurrentUserDetails()
  let [profileData, setProfileData] = useApi<PersonalProfileData>(
    apiRoutes.GET_OR_UPDATE_USER
  )

  let { postToApi } = usePostToAPI()

  const updateAppearanceConfig = (
    newValue: UserAppearanceConfig,
    onUpdateFinished: () => void
  ) =>
    postToApi<null>(apiRoutes.GET_OR_UPDATE_APPEARANCE, newValue, 'patch')
      .then(response => {
        if (response.ok) {
          setCurrentUser(
            oldValue =>
              oldValue && {
                ...oldValue,
                appearanceConfig: newValue
              }
          )
        }
      })
      .finally(() => onUpdateFinished())

  return (
    <main className={styles['settings']}>
      {profileData === null ? (
        <OverlayingLoadingSpinner />
      ) : (
        <div className={styles['container']}>
          {profileData instanceof ApiError ? (
            <section style={{ padding: '16px' }}>
              <HttpErrorMessage error={profileData} style={{ width: '100%' }} />
            </section>
          ) : (
            <>
              <ProfileSettings
                profileData={profileData}
                setProfileData={setProfileData}
                setCurrentUserDetails={setCurrentUser}
              />
              <br />
              <PrivacySettings
                privacySettings={profileData.privacy}
                savePrivacySettings={(newPrivacy: UserPrivacy) =>
                  profileData &&
                  !(profileData instanceof ApiError) &&
                  setProfileData({
                    ...profileData,
                    privacy: newPrivacy
                  })
                }
              />
              <br />
            </>
          )}
          {currentUser && currentUser.appearanceConfig ? (
            <AppearanceSettings
              appearanceConfig={currentUser.appearanceConfig}
              updateAppearanceConfig={updateAppearanceConfig}
            />
          ) : null}
          <br />
          <GeneralSettings logoutUser={handleLogout} />
        </div>
      )}
    </main>
  )
}

function GeneralSettings ({
  logoutUser
}: {
  logoutUser: (() => void) | undefined
}) {
  return (
    <section>
      <SettingsAccordion>
        <h3 className='section-heading'>General Settings</h3>
        <>
          <Link to='/settings/statistics'>View own Statistics</Link>
          <br />
          <br />
          <Link to='/settings/keep-in-touch'>
            View connections/contacts frequency keep in touch
          </Link>
          <br />
          <br />
          <Link to='/settings/common-interests'>
            See common interests among your Contacts
          </Link>
          <br />
          <br />
          <Link to='/settings/duplicate-contacts'>
            Manage duplicate contacts
          </Link>
          <br />
          <br />
          <Link to='/settings/coordinate-clusters'>
            Manage coordinate clusters
          </Link>
          <br />
          <br />
          <Link to='/settings/manage-connections'>Manage connections</Link>
          <br />
          <br />
          <Link to='/settings/manage-notifications'>Manage notifications</Link>
          <br />
          <br />
          <button
            className={styles.logout}
            type='submit'
            onClick={() => logoutUser?.()}
          >
            Logout
          </button>
          <br />
          <br />
          <Link to='/settings/delete-account'>Delete account</Link>
          <br />
        </>
      </SettingsAccordion>
    </section>
  )
}