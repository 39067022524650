import React from "react";

import styles from "./TravelPlanPopup.module.scss";
import { MinimalTravelPlanData, TravelPlanData } from "../../HomePageTypes";
import { DispatchAction } from "../../reducer";

import { HomePageState } from "Pages/Home/HomePageState";


import PopupLoadingSpinner from "../../PopupLoadingSpinner/PopupLoadingSpinner";
import PopupLoadingError from "../../PopupLoadingError/PopupLoadingError";
import { FullTravelPlanPopupContent } from "./FullTravelPlanPopupContent";
import { ApiError, useApi } from "hooks/useAPI";
import { apiRoutes } from "services/routes";

export default function TravelPlanPopup({
    travelPlan,
    state,
    dispatch,
}: {
    travelPlan: MinimalTravelPlanData;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullTravelPlan, setFullTravelPlan] = useApi<TravelPlanData>(
        apiRoutes.GET_TRAVEL_PLAN_DETAILS(travelPlan.id)
    );

    return (
        <div className={styles["travel-plan-popup"]}>
            {fullTravelPlan === null ? (
                <PopupLoadingSpinner />
            ) : fullTravelPlan instanceof ApiError ? (
                <PopupLoadingError error={fullTravelPlan} />
            ) : (
                <FullTravelPlanPopupContent
                    travelPlan={fullTravelPlan}
                    setTravelPlan={setFullTravelPlan}
                    state={state}
                    dispatch={dispatch}
                />
            )}
        </div>
    );
}
