// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressInput_addressContainer__J6wbE {
  display: flex;
}
.AddressInput_addressContainer__J6wbE label {
  flex-basis: 30%;
  text-align: right;
  margin-right: 10px;
}
.AddressInput_addressContainer__J6wbE input {
  flex-basis: 50%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .AddressInput_addressContainer__J6wbE {
    flex-direction: column;
  }
  .AddressInput_addressContainer__J6wbE label,
  .AddressInput_addressContainer__J6wbE input {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.AddressInput_allAddrContainer__bfqiB {
  max-width: 247px; /* Set the maximum width to 300 pixels */
  margin: 0 auto; /* Center the content horizontally */
  padding: 10px; /* Add some padding for spacing */
}`, "",{"version":3,"sources":["webpack://./src/Components/AddressInput/AddressInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEE;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,eAAA;AADJ;;AAKA;EACE;IACE,sBAAA;EAFF;EAIE;;IAEE,gBAAA;IACA,eAAA;IACA,kBAAA;EAFJ;AACF;AAMA;EACE,gBAAA,EAAA,wCAAA;EACA,cAAA,EAAA,oCAAA;EACA,aAAA,EAAA,iCAAA;AAJF","sourcesContent":[".addressContainer {\n  display: flex;\n  // flex-wrap: wrap;\n\n  label {\n    flex-basis: 30%;\n    text-align: right;\n    margin-right: 10px;\n  }\n\n  input {\n    flex-basis: 50%;\n    max-width: 100%;\n  }\n}\n\n@media (max-width: 768px) {\n  .addressContainer {\n    flex-direction: column;\n\n    label,\n    input {\n      flex-basis: 100%;\n      margin-right: 0;\n      margin-bottom: 5px;\n    }\n  }\n}\n\n.allAddrContainer {\n  max-width: 247px; /* Set the maximum width to 300 pixels */\n  margin: 0 auto; /* Center the content horizontally */\n  padding: 10px; /* Add some padding for spacing */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressContainer": `AddressInput_addressContainer__J6wbE`,
	"allAddrContainer": `AddressInput_allAddrContainer__bfqiB`
};
export default ___CSS_LOADER_EXPORT___;
