import React from "react";

export function SubscriptionInput({
    autoFocus,
    initialValue,
    onBlur,
    onEnter,
}: {
    autoFocus?: boolean;
    initialValue?: string;
    onBlur: (inputValue: string) => void;
    onEnter: (inputValue: string) => void;
}) {
    return (
        <input
            defaultValue={initialValue}
            autoFocus={autoFocus}
            type="text"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    onEnter(e.currentTarget.value);
                    e.currentTarget.value = "";
                }
            }}
            onBlur={(e) => {
                onBlur(e.currentTarget.value);
            }}
        />
    );
}
