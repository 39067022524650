// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectionButton_connection-button__PDlKs {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--main-bg-color);
  --main-bg-color: var(--accent-color);
  --hover-bg-color: var(--accent-color-dark);
  --active-bg-color: var(--accent-color-x-dark);
}

.ConnectionButton_connection-button__PDlKs:hover {
  background-color: var(--hover-bg-color);
}

.ConnectionButton_connection-button__PDlKs:active {
  background-color: var(--active-bg-color);
}

.ConnectionButton_loading-connection__zYfCu {
  --main-bg-color: rgb(150, 150, 150);
  --hover-bg-color: rgb(150, 150, 150);
  --active-bg-color: rgb(150, 150, 150);
}

.ConnectionButton_end-connection-button__dI1QZ {
  --main-bg-color: hsl(0, 100%, 60%);
  --hover-bg-color: hsl(0, 100%, 56%);
  --active-bg-color: hsl(0, 100%, 52%);
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserProfile/ConnectionButton/ConnectionButton.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sCAAA;EACA,oCAAA;EACA,0CAAA;EACA,6CAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AAEA;EACI,wCAAA;AACJ;;AAEA;EACI,mCAAA;EACA,oCAAA;EACA,qCAAA;AACJ;;AAQA;EACI,kCAAA;EACA,mCAAA;EACA,oCAAA;AALJ","sourcesContent":[".connection-button {\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n    background-color: var(--main-bg-color);\n    --main-bg-color: var(--accent-color);\n    --hover-bg-color: var(--accent-color-dark);\n    --active-bg-color: var(--accent-color-x-dark);\n}\n\n.connection-button:hover {\n    background-color: var(--hover-bg-color);\n}\n\n.connection-button:active {\n    background-color: var(--active-bg-color);\n}\n\n.loading-connection {\n    --main-bg-color: rgb(150, 150, 150);\n    --hover-bg-color: rgb(150, 150, 150);\n    --active-bg-color: rgb(150, 150, 150);\n}\n\n.cancel-request {\n    // --main-bg-color: hsl(35, 100%, 50%);\n    // --hover-bg-color: hsl(35, 100%, 46%);\n    // --active-bg-color: hsl(35, 100%, 42%);\n}\n\n.end-connection-button {\n    --main-bg-color: hsl(0, 100%, 60%);\n    --hover-bg-color: hsl(0, 100%, 56%);\n    --active-bg-color: hsl(0, 100%, 52%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connection-button": `ConnectionButton_connection-button__PDlKs`,
	"loading-connection": `ConnectionButton_loading-connection__zYfCu`,
	"end-connection-button": `ConnectionButton_end-connection-button__dI1QZ`
};
export default ___CSS_LOADER_EXPORT___;
