import React, { useEffect, useState } from 'react';
import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

export default function AvailableInvitesCounter() {
  const authInstance = useAxiosPrivate();
  const [availableInvites, setAvailableInvites] = useState<number | null>(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState<string | null>(null); // Track error state

  useEffect(() => {
    const fetchAvailableInvites = async () => {
      try {
        const response = await authInstance.get(apiRoutes.GET_AVAILABLE_INVITES);
        const data = response.data.data.available_invites;
        setAvailableInvites(data);
        setLoading(false); // Set loading to false after data is received
      } catch (error) {
        setError('Error fetching available invites');
        setLoading(false); // Set loading to false on error
      }
    };

    fetchAvailableInvites(); // Call the async function
  }, [authInstance]);

  return (
    <div>
      {loading ? (
        <p>Loading available invites...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <p>Available Invites: {availableInvites}</p>
      )}
    </div>
  );
}
