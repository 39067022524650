import React from 'react';
import { BasicNetworkData } from "Components/NetworkGroups/BasicNetworkDataTypes";
import {MemberConnections} from "../MemberConnections";
import {MemberContacts} from "../MemberContacts";
import {MemberPlaces} from "../MemberPlaces";

interface MemberSectionsProps {
    network: BasicNetworkData;
    removeMember: (type: "user" | "contact" | "place", memberId: Number) => void;
    isOwner: (id: Number) => boolean;
    isNetworkOwner: () => boolean;
}

const MemberSections: React.FC<MemberSectionsProps> = ({ network, removeMember, isOwner, isNetworkOwner }) => {
    return (
        <div className="border-t border-gray-200 pt-8">
            <dl>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                        Visibility
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {network.visibility}
                    </dd>
                    <div>
                        <h2 className="text-lg">Members</h2>
                        <MemberConnections
                            member_connections={network.member_connections}
                            pending_members={network.pending_members}
                            removeMember={removeMember}
                            isOwner={isOwner}
                            isNetworkOwner={isNetworkOwner}
                        />
                        <MemberContacts
                            member_contacts={network.member_contacts}
                            removeMember={removeMember}
                            isNetworkOwner={isNetworkOwner}
                        />
                        <MemberPlaces
                            member_locations={network.member_locations}
                            removeMember={removeMember}
                            isNetworkOwner={isNetworkOwner}
                        />
                    </div>
                </div>
            </dl>
        </div>
    );
};

export default MemberSections;
