// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogBox_overlay__zVj1c {
  z-index: 10000000000;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.DialogBox_overlay__zVj1c::-webkit-scrollbar,
.DialogBox_overlay__zVj1c .DialogBox_box__fGvXT::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.DialogBox_overlay__zVj1c::-webkit-scrollbar-track,
.DialogBox_overlay__zVj1c .DialogBox_box__fGvXT::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
}
.DialogBox_overlay__zVj1c::-webkit-scrollbar-thumb,
.DialogBox_overlay__zVj1c .DialogBox_box__fGvXT::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsl(0, 0%, 90%);
  box-shadow: inset 0 0 1px 1px #616161;
}
.DialogBox_overlay__zVj1c::-webkit-scrollbar-thumb:hover,
.DialogBox_overlay__zVj1c .DialogBox_box__fGvXT::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 85%);
  background-color: hsl(0, 0%, 80%);
}
@media screen and (max-width: 600px) {
  .DialogBox_overlay__zVj1c {
    padding: 20px;
  }
}
@media screen and (min-width: 600px) {
  .DialogBox_overlay__zVj1c {
    padding: 47px;
  }
}
.DialogBox_overlay__zVj1c .DialogBox_box__fGvXT {
  box-shadow: var(--shadow);
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/DialogBox/DialogBox.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,oCAAA;EACA,eAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,MAAA;EACA,aAAA;EAGA,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAGI;;EAEI,WAAA;EACA,YAAA;AADR;AAII;;EAEI,4CAAA;AAFR;AAKI;;EAEI,kBAAA;EACA,iCAAA;EACA,qCAAA;AAHR;AAMI;;EAEI,iCAAA;EAKA,iCAAA;AATR;AAYI;EA3CJ;IA4CQ,aAAA;EALN;AACF;AAOI;EA/CJ;IAgDQ,aAAA;EAJN;AACF;AAMI;EACI,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AAJR","sourcesContent":[".overlay {\n    z-index: 10000000000;\n    background-color: rgba(0, 0, 0, 0.4);\n    position: fixed;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    top: 0;\n    display: flex;\n    // justify-content: center;\n    // align-items: center;\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n\n    &::-webkit-scrollbar,\n    .box::-webkit-scrollbar {\n        width: 10px;\n        height: 10px;\n    }\n\n    &::-webkit-scrollbar-track,\n    .box::-webkit-scrollbar-track {\n        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8)\n    }\n\n    &::-webkit-scrollbar-thumb,\n    .box::-webkit-scrollbar-thumb {\n        border-radius: 5px;\n        background-color: hsl(0, 0%, 90%);\n        box-shadow: inset 0 0 1px 1px #616161;\n    }\n\n    &::-webkit-scrollbar-thumb:hover,\n    .box::-webkit-scrollbar-thumb:hover {\n        background-color: hsl(0, 0%, 85%);\n    }\n\n    &::-webkit-scrollbar-thumb:hover,\n    .box::-webkit-scrollbar-thumb:hover {\n        background-color: hsl(0, 0%, 80%);\n    }\n\n    @media screen and (max-width: 600px) {\n        padding: 20px;\n    }\n\n    @media screen and (min-width: 600px) {\n        padding: 47px;\n    }\n\n    .box {\n        box-shadow: var(--shadow);\n        padding: 30px;\n        background-color: white;\n        border-radius: 5px;\n        margin: auto;\n        // max-height: 100%;\n        // overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `DialogBox_overlay__zVj1c`,
	"box": `DialogBox_box__fGvXT`
};
export default ___CSS_LOADER_EXPORT___;
