import React from 'react';
import './MyMenuItem.scss';

interface MyMenuItemProps {
  onClick: () => void;
  children: React.ReactNode;
}

const MyMenuItem: React.FC<MyMenuItemProps> = ({ onClick, children }) => {
  return (
    <div onClick={onClick} className="menu-item">
      {children}
    </div>
  );
};

export default MyMenuItem;
