import React, { useState, useRef, useEffect } from 'react';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';
import { apiRoutes } from 'services/routes';
import EditExperience from './EditExperience';
import DeleteExperience from './DeleteExperience';
import styles from './ExperienceList.module.scss';
import AddExperience from './AddExperience'

interface Experience {
  id: number;
  person_id: string | null;
  company_name: string | null;
  company_size: string | null;
  company_id: string | null;
  company_founded: string | null;
  company_industry: string | null;
  company_location_name: string | null;
  company_location_locality: string | null;
  company_location_region: string | null;
  company_location_geo: string | null;
  company_location_country: string | null;
  company_location_continent: string | null;
  company_street_address: string | null;
  company_address_line_2: string | null;
  company_postal_code: string | null;
  company_linkedin_url: string | null;
  company_linkedin_id: string | null;
  company_facebook_url: string | null;
  company_twitter_url: string | null;
  company_website: string | null;
  start_date: string | null;
  end_date: string | null;
  title_name: string | null;
  title_role: string | null;
  title_sub_role: string | null;
  title_levels: Record<string, any> | null;
  location_names: Record<string, any> | null;
  is_primary: boolean | null;
  summary: string | null;
  object_id: number;
  experience_type: string;
  created_date: string; // Assuming it's a string representation of a timestamp
  content_type_id: number;
}

interface ObjectListProps {
  entityType: 'user' | 'place' | 'contact';
  entityId: number;
}

const ExperienceList: React.FC<ObjectListProps> = ({ entityType, entityId }) => {
  const [expandedObjectId, setExpandedObjectId] = useState<number | null>(null);
  const [objects, setObjects] = useState<Experience[]>([]);
  const [feedback, setFeedback] = useState<string>('');
  const [editObjectId, setEditObjectId] = useState<number | null>(null);
  const privateAPIInstance = useAxiosPrivate();

  const fetchObjects = async () => {
    try {
      let response;
      if (entityType === 'user') {
        response = await privateAPIInstance.get(apiRoutes.GET_USER_EXPERIENCES(entityId));
      } else if (entityType === 'place') {
        response = await privateAPIInstance.get(apiRoutes.GET_PLACE_EXPERIENCES(entityId));
      } else if (entityType === 'contact') {
        response = await privateAPIInstance.get(apiRoutes.GET_CONTACT_EXPERIENCES(entityId));
      }

      if (response && response.data) {
        setObjects(response.data.data);
        setFeedback(response.data.message);
      } else {
        setFeedback('No response from server.');
      }
    } catch (error) {
      setFeedback('Error fetching addresses.');
    }
  };

  const refreshObjects = () => {
    fetchObjects();
  };

  useEffect(() => {
    fetchObjects();
  }, [entityType, entityId, privateAPIInstance]);


  const handleObjectClick = (id: number) => {
    if (editObjectId) {
      setEditObjectId(null); // Exit edit mode if any address is in edit mode
    } else {
      setExpandedObjectId(expandedObjectId === id ? null : id);
    }
  };

  const handleEditInit = (id: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setEditObjectId(id);
  };

  const excludedFields = [
    'id', 'lat', 'lng', 'object_id', 
    'content_type', 'created_date',
    'person_id',
    'company_size',
    'company_id',
    'company_founded',
    'company_industry',
    'company_location_name',
    'company_location_locality',
    'company_location_region',
    'company_location_geo',
    'company_location_country',
    'company_location_continent',
    'company_street_address',
    'company_address_line_2',
    'company_postal_code',
    'company_linkedin_url',
    'company_linkedin_id',
    'company_facebook_url',
    'company_twitter_url',
    'company_website',
    'title_role',
    'title_sub_role',
    'title_levels',
    'location_names',
    'is_primary',
  ];


  return (
    <div>
      {/* #TODO Pass feedback to mapbond own implementtion of notifications */}
      {/* {feedback && <p className={styles.feedbackMessage}>{feedback}</p>} */}
      {objects.length === 0 ? (
        <AddExperience
          entityType={entityType}
          entityId={entityId}
          refreshObjects={refreshObjects}
        />
      ) : (
        objects.map((obj) => (
          <div key={obj.id} className={styles.objectContainer}>
            <div className={styles.groupedIn}>
              <div className={styles.label}>💼 </div>
              <div onClick={() => handleObjectClick(obj.id)}>{obj.company_name}</div>
            </div>
            {expandedObjectId === obj.id && !editObjectId && (
              <>
                {Object.entries(obj).map(([key, value]) => {
                  if (excludedFields.includes(key) || key === 'company_name') return null;
                  return (
                    <div className={styles.groupedIn} key={key}>
                      <div className={styles.label}>{key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}: </div>
                      <div onClick={(e) => handleEditInit(obj.id, e)}>{value}</div>
                    </div>
                  );
                })}
                <button onClick={(e) => handleEditInit(obj.id, e)}>Edit</button>
              </>
            )}
            {editObjectId === obj.id && (
              <>
                <EditExperience data={obj} onObjectUpdated={refreshObjects} />
                <DeleteExperience addressId={obj.id} onObjectDeleted={refreshObjects} />
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
};


export default ExperienceList;
