// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkerConsentMessage_consent-message__xIPx3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.MarkerConsentMessage_consent-message__xIPx3 p {
  margin: 30px 20px 10px 20px;
  text-align: center;
  font-size: larger;
}
.MarkerConsentMessage_consent-message__xIPx3 .MarkerConsentMessage_button-container__Vjnma {
  margin: 10px;
}
.MarkerConsentMessage_consent-message__xIPx3 .MarkerConsentMessage_button-container__Vjnma button {
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.MarkerConsentMessage_consent-message__xIPx3 .MarkerConsentMessage_button-container__Vjnma button:disabled {
  border-color: grey;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/MarkerConsentMessage/MarkerConsentMessage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACI,2BAAA;EACA,kBAAA;EACA,iBAAA;AACR;AAEI;EACI,YAAA;AAAR;AAEQ;EACI,0BAAA;EACA,qCAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAAZ;AAGQ;EACI,kBAAA;EACA,WAAA;AADZ","sourcesContent":[".consent-message {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n\n    p {\n        margin: 30px 20px 10px 20px;\n        text-align: center;\n        font-size: larger;\n    }\n\n    .button-container {\n        margin: 10px;\n\n        button {\n            color: var(--accent-color);\n            border: 2px solid var(--accent-color);\n            background-color: white;\n            padding: 5px 10px;\n            border-radius: 20px;\n            font-weight: 700;\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n        }\n\n        button:disabled {\n            border-color: grey;\n            color: grey;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consent-message": `MarkerConsentMessage_consent-message__xIPx3`,
	"button-container": `MarkerConsentMessage_button-container__Vjnma`
};
export default ___CSS_LOADER_EXPORT___;
