import React from 'react';
import { BasicNetworkData } from './BasicNetworkDataTypes'; // Adjust import path as necessary
import { apiRoutes } from 'services/routes'; // Adjust import path as necessary

interface EditNetworkProps {
    network: BasicNetworkData;
    selectedPhoto: File | null;
    apiInstance: any; // Replace 'any' with the specific Axios instance type
    modalOpen?: React.Dispatch<boolean>;
    isUploading: boolean; // Add this line

}

const EditNetwork: React.FC<EditNetworkProps> = ({
    network,
    selectedPhoto,
    apiInstance,
    modalOpen,
    isUploading // Add this line
}) => {
    const editNetwork = () => {
        // setIsUploading(true); // Handle this state in the parent component
        console.log("[EditNetwork]Called, isUploading", isUploading);
        
        let body = new FormData();
        body.append("id", JSON.stringify(network.id ? network.id : ""));
        body.append("name", network.name);
        body.append("description", network.description);
        body.append("visibility", network.visibility);
        body.append(
          "network_logo",
          !network.network_logo?.includes("cloudinary.com")
            ? (selectedPhoto as File)
            : "unmodified"
        ); // network_logo is the key for the file
        body.append("member_contacts", JSON.stringify(network.member_contacts));
        body.append(
          "member_connections",
          JSON.stringify(network.member_connections)
        );
        body.append(
          "member_locations",
          JSON.stringify(network.member_locations)
        );

        apiInstance.patch(apiRoutes.UPDATE_OR_DELETE_OR_GET_NETWORK(network?.id), body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
			.then(async (response:any) => {
				if (response.status < 300) {
					console.log("edited successfully", await response.data);
				} else {
				}})
        .finally(() => {
            // setIsUploading(false); // Handle this state in the parent component
            if (modalOpen) {
                modalOpen(false);
            }
        });
    };

    return (
        <button onClick={editNetwork} disabled={!network.name}>
            {isUploading ? "Updating" : "Confirm"}
        </button>
    );
};

export default EditNetwork;
