import React from "react";
import L from "leaflet";

import styles from "./TileLayerSelector.module.scss";
import { CustomSelect } from "../../../../Components/CustomSelect/CustomSelect";
import ExpandableButton from "../ExpandableButton/ExpandableButton";
import { FaLayerGroup } from "react-icons/fa";

/**
 * Used to select a tile layer to display on the map
 */
export default function TileLayerSelector({
    setTileLayer,
    tileLayer,
}: {
    setTileLayer: (layer: string) => void;
    tileLayer: string;
}) {
    return (
        <ExpandableButton collapseOnClick={false} expandOnHover={true}>
            <div id="titleLayer">
                <FaLayerGroup />
            </div>
            <div className={styles["tile-layer-selector"]}>
                <CustomSelect
                    options={[
                        "Google",
                        "Google Hybrid",
                        "OpenStreetMap",
                        "Carto Dark",
                        "Nasa City Lights",
                        // "OpenStreetMap HOT",
                        "OpenWeatherMap Clouds",
                        "Thunderforest Pioneer",
                        // "Stadia Outdoors",
                        // "Stadia Alidade Smooth Dark",
                        "Esri WorldStreetMap",
                        "Esri NatGeoWorldMap",
                        "Carto Voyager",
                        // "USGS USImageryTopo",
                        // "OpenTopoMap",
                    ].sort()}
                    selected={tileLayer}
                    onChange={setTileLayer}
                    blank={false}
                    orientation="vertical"
                    unselectedStyle={{
                        backgroundColor: "transparent",
                    }}
                />
            </div>
        </ExpandableButton>
    );
}

/**
 * Returns a new tile layer based on the name of the required tile layer
 */
export function getTileLayer(name: string) {
    let props = getTileLayerProps(name);
    return new L.TileLayer(props.url, props);
}

/**
 * Gets the properties of a tile layer based on the layer's name
 */
export function getTileLayerProps(name: string) {
    switch (name) {
        case "Google Hybrid":
            return {
                url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                maxZoom: 20,
                minZoom: 2,
                attribution:
                    "&copy; <a href='https://developers.google.com/maps'>Google</a>",
            };
        case "OpenStreetMap":
            return {
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                maxZoom: 19,
                minZoom: 2,
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            };
        case "OpenStreetMap HOT":
            return {
                url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
                maxZoom: 19,
                minZoom: 2,
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a>, &copy; <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
            };
        case "Nasa City Lights":
            return {
                url: "https://map1.vis.earthdata.nasa.gov/wmts-webmerc/VIIRS_CityLights_2012/default/{time}/{tilematrixset}{maxZoom}/{z}/{y}/{x}.jpg",
                attribution:
                    "NASA Earthdata | Map tiles by <a href='https://stamen.com'>Stamen Design</a>, under <a href='https://creativecommons.org/licenses/by/3.0'>CC BY 3.0</a>. Data by <a href='https://www.nasa.gov'>NASA</a>",
                minZoom: 2,
                maxZoom: 8,
                time: "",
                tilematrixset: "GoogleMapsCompatible_Level",
            };
        case "OpenWeatherMap Clouds":
            return {
                url: "https://{s}.tile.openweathermap.org/map/clouds/{z}/{x}/{y}.png?appid={api_key}",
                attribution:
                    'Map data &copy; <a href="https://openweathermap.org">OpenWeatherMap</a>',
                api_key: "dc6f4eb8ec63a89831b8e3f9b43e4726",
                maxZoom: 19,
                minZoom: 2,
                opacity: 0.5,
            };
        case "Thunderforest Pioneer":
            return {
                url: "https://{s}.tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey={api_key}",
                attribution:
                    '&copy; <a href="https://www.opencyclemap.org">OpenCycleMap</a>, &copy; <a href="https://www.thunderforest.com/">Thunderforest</a>',
                api_key: "82bf2eebdcca4462994a784e60637c8f",
                maxZoom: 22,
                minZoom: 2,
            };
        case "Stadia Outdoors":
            return {
                url: "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
                minZoom: 2,
                maxZoom: 20,
                attribution:
                    '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>',
            };
        case "Stadia Alidade Smooth Dark":
            return {
                url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
                maxZoom: 20,
                minZoom: 2,
                attribution:
                    '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>',
            };
        case "Esri WorldStreetMap":
            return {
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
                attribution: "&copy; Esri",
            };
        case "Esri NatGeoWorldMap":
            return {
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
                attribution: "&copy; Esri",
                minZoom: 2,
                maxZoom: 16,
            };
        case "Carto Voyager":
            return {
                url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: "abcd",
                minZoom: 2,
                maxZoom: 20,
            };
        case "Carto Dark":
            return {
                url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: "abcd",
                minZoom: 2,
                maxZoom: 20,
            };
        case "USGS USImageryTopo":
            return {
                url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
                minZoom: 2,
                maxZoom: 20,
                attribution:
                    '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>',
            };
        case "OpenTopoMap":
            return {
                url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                minZoom: 2,
                maxZoom: 17,
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="http://viewfinderpanoramas.org">SRTM</a>, <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
            };
        case "Google":
        default:
            return {
                url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                minZoom: 2,
                maxZoom: 20,
                attribution:
                    "&copy; <a href='https://developers.google.com/maps'>Google</a>",
            };
    }
}
