import styles from "./LocationMarkerPopup.module.scss";

export function LocationPopupImages({
    images,
}: {
    images: Array<{ id: number; url: string }>;
}) {
    switch (images.length) {
        case 0:
            return (
                <div className={styles["location-popup-image"]}>
                    <div
                        style={{
                            backgroundImage: `url(/images/default-location-image.svg)`,
                        }}
                    ></div>
                </div>
            );
        case 1:
            return (
                <div className={styles["location-popup-image"]}>
                    <div
                        style={{
                            backgroundImage: `url(${images[0].url})`,
                        }}
                    ></div>
                </div>
            );
        case 2:
        case 3:
            return (
                <div
                    className={`${styles["location-popup-image-group"]} ${
                        images.length === 2 ? styles["two"] : styles["three"]
                    }`}
                >
                    {images.map((image) => (
                        <div
                            key={image.id}
                            style={{ backgroundImage: `url(${image.url})` }}
                        />
                    ))}
                </div>
            );
        default:
            return (
                <div
                    className={`${styles["location-popup-image-group"]} ${styles["many"]}`}
                >
                    {images.slice(0, 4).map((image, index) => (
                        <div
                            key={image.id}
                            style={{ backgroundImage: `url(${image.url})` }}
                        >
                            {index === 3 && images.length > 4 && (
                                <div className={styles["additional-images"]}>
                                    +{images.length - 4}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
    }
}
