import { useGoogleLogin } from '@react-oauth/google'
import { SECRET_STATE, GOOGLE_OAUTH_REDIRECT_URI } from 'Utils/auth-utils'
import { useAuth } from '../useAuth'
import { useCallback } from 'react'
import Alert from 'Components/Alert/Alert'
import { errorHandler } from 'services/utils'
import { apiInstance } from 'services/apiInstance'
import { apiRoutes } from 'services/routes'
import { TUserData } from 'types/shared'
import useSignIn from 'react-auth-kit/hooks/useSignIn'

export const useGoogleAuth = () => {
  const { determineOnboardingRedirectURL } = useAuth()
  const signIn = useSignIn()
  // Handles Google OAuth flow
  const finalizeGoogleAuth = useCallback(async (GoogleOauthCode: string) => {
    try {
      console.log('Finalizing Google Auth with code:', GoogleOauthCode); // Debug: Check the Google OAuth code
      console.log('apiRoutes.GOOGLE_LOGIN:', apiRoutes.GOOGLE_LOGIN); // Debug: Check the Google OAuth code

      //Make a request to the backend to receive access and refresh token
      const res = await apiInstance.post(apiRoutes.GOOGLE_LOGIN, {
        code: GoogleOauthCode
      })

      console.log('Response from GOOGLE_LOGIN:', res); // Debug: Check the response

      if (
        signIn({
          auth: {
            token: res.data.access,
            type: 'Bearer'
          },
          refresh: res.data.refresh,
          userState: res.data.user
        })
      ) {
        Alert('success', 'Authentication successful.')
        const userObject = res.data.user as TUserData

        console.log('User object:', userObject); // Debug: Check the user object


        determineOnboardingRedirectURL(userObject)
      } else {
        console.log('Error in signIn'); // Debug: signIn error

        //Throw error
      }
    } catch (error) {
      console.error('Error in finalizeGoogleAuth:', error); // Debug: Log any errors

      errorHandler(error)
    }
  }, [])

  const initializeGoogleAuth = useGoogleLogin({
    ux_mode: 'redirect',
    flow: 'auth-code',
    redirect_uri: GOOGLE_OAUTH_REDIRECT_URI,
    state: SECRET_STATE,
    accessType: 'offline',
    responseType: 'code',
    prompt: 'consent'
  })

  return {
    finalizeGoogleAuth,
    initializeGoogleAuth
  }
}