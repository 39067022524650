import React from 'react';

type CustomButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
};

const CustomButton: React.FC<CustomButtonProps> = ({ onClick, disabled, children }) => {
  return (
    <button
      onClick={onClick}
      className={`px-3 py-2 bg-green-400 rounded mx-3 hover:bg-green-500 ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default CustomButton;
