import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import ContextMenu from "Components/ContextMenu/ContextMenu";
import ContextMenuError from "Components/ContextMenu/ContextMenuError/ContextMenuError";
import ContextMenuLoadingSpinner from "Components/ContextMenu/ContextMenuLoadingSpinner/ContextMenuLoadingSpinner";
import { ApiError, useApi, usePostToAPI } from "hooks/useAPI";
import { LocationData, MinimalLocationData } from "../HomePageTypes";
import { DispatchAction } from "../reducer";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";


import { isNotVisible, isOwnedBySelf } from "../visibility";
import {handleLocationConsentRequest} from "./LocationMarkerPopup/handleLocationConsentRequest";
import { JournalTemplate } from "Components/TextBox/Editor";
import { apiRoutes } from "services/routes";
import { useAxiosPrivate } from "hooks/useAxiosPrivate";

export type LocationContextMenuData = {
    type: "location";
    location: MinimalLocationData;
    style: CSSProperties;
};

export default function LocationContextMenu({
    contextMenuData,
    editingLocation,
    state,
    dispatch,
}: {
    contextMenuData: LocationContextMenuData;
    editingLocation: boolean;
    state: HomePageState;
    dispatch: React.Dispatch<DispatchAction>;
}) {
    let [fullLocation, setFullLocation] = useApi<LocationData>(
        apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(contextMenuData.location.handle)
    );

    return (
        <ContextMenu style={contextMenuData.style}>
            {fullLocation === null ? (
                <ContextMenuLoadingSpinner />
            ) : fullLocation instanceof ApiError ? (
                <ContextMenuError error={fullLocation} />
            ) : (
                <MenuButtons
                    location={fullLocation}
                    setLocation={setFullLocation}
                    contextMenuData={contextMenuData}
                    dispatch={dispatch}
                    editingLocation={editingLocation}
                />
            )}
        </ContextMenu>
    );
}

function MenuButtons({
    location,
    setLocation,
    contextMenuData,
    dispatch,
    editingLocation,
}: {
    location: LocationData;
    setLocation: React.Dispatch<
        React.SetStateAction<LocationData | ApiError | null>
    >;
    contextMenuData: LocationContextMenuData;
    dispatch: React.Dispatch<DispatchAction>;
    editingLocation: boolean;
}) {
    let { postToApi } = usePostToAPI();

    const apiInstance = useAxiosPrivate();
    const deleteLocation = () => {
        apiInstance.delete(
            apiRoutes.GET_OR_UPDATE_OR_DELETE_PLACE(
                contextMenuData.location.handle
            )
        );
        dispatch({
            type: ACTIONS.DELETE_LOCATION,
            location,
        });
    };

    const editLocation = () =>
        dispatch({ type: ACTIONS.START_EDITING_LOCATION, location });

    const addObject = () =>
        dispatch({
            type: ACTIONS.START_ADDING_OBJECT,
            receiver: { type: "location", for: location },
        });

    const updateLocation = (
        newLocation: LocationData | MinimalLocationData
    ) => {
        setLocation({ ...location, ...newLocation } as LocationData);
        dispatch({ type: ACTIONS.UPDATE_LOCATION, location: newLocation });
    };

    const addJournalEntry = () => {
        let obj: JournalTemplate = {
            id: location.id,
            name: location.name || "",
            avatar: location.mainPicture || "",
            type: "place",
        };
        dispatch({ type: ACTIONS.addingJournalEntry, value: obj });
    };

    return isNotVisible(location.visibility) ? (
        <button
            onClick={() =>
                handleLocationConsentRequest(
                    updateLocation,
                    location,
                    postToApi
                )
            }
        >
            {location.visibility === "needs request"
                ? "Request access"
                : location.visibility === "requested"
                ? "Cancel request"
                : "Loading visibility"}
        </button>
    ) : (
        <>
            {isOwnedBySelf(location.visibility) ? (
                <>
                    {!editingLocation ? (
                        <button onClick={editLocation}>Edit location</button>
                    ) : null}
                    <button onClick={deleteLocation}>Delete location</button>
                </>
            ) : null}
            <button onClick={addObject}>Add object located here</button>
            <Link to={`/location/${contextMenuData.location.handle}`}>
                Visit Profile
            </Link>
            <button onClick={addJournalEntry}>Add a journal entry</button>
        </>
    );
}
