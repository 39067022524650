import React, { useState } from 'react';
import { apiRoutes } from 'services/routes';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate';

interface DeleteObjectProps {
  addressId: number;
  onObjectDeleted: () => void;
}

const DeleteAddress: React.FC<DeleteObjectProps> = ({ addressId, onObjectDeleted }) => {
  const [feedback, setFeedback] = useState<string>('');
  const privateAPIInstance = useAxiosPrivate();

  const handleDelete = async () => {
    try {
      await privateAPIInstance.delete(apiRoutes.DELETE_EXPERIENCE(addressId));
      setFeedback('Experience deleted successfully.');
      onObjectDeleted();
    } catch (error) {
      setFeedback('Error deleting address.');
    }
  };

  return (
    <div>
      <button onClick={handleDelete}>Delete</button>
      {feedback && <p>{feedback}</p>}
    </div>
  );
};

export default DeleteAddress;
