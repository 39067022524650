// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion_accordion__k7ycz .Accordion_heading__-7Fk1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.Accordion_accordion__k7ycz .Accordion_heading__-7Fk1 .Accordion_chevron__pRqeH {
  margin: 0 10px;
}
.Accordion_accordion__k7ycz .Accordion_collapsible__nRX\\+p {
  transition: height 0.3s ease-out;
  overflow: hidden;
  height: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Accordion/Accordion.module.scss"],"names":[],"mappings":"AACG;EACG,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEM;EACG,cAAA;AAAT;AAIG;EACG,gCAAA;EACA,gBAAA;EACA,SAAA;AAFN","sourcesContent":[".accordion {\n   .heading {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      align-items: center;\n      cursor: pointer;\n\n      .chevron {\n         margin: 0 10px;\n      }\n   }\n\n   .collapsible {\n      transition: height 0.3s ease-out;\n      overflow: hidden;\n      height: 0;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `Accordion_accordion__k7ycz`,
	"heading": `Accordion_heading__-7Fk1`,
	"chevron": `Accordion_chevron__pRqeH`,
	"collapsible": `Accordion_collapsible__nRX+p`
};
export default ___CSS_LOADER_EXPORT___;
