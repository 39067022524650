import React, { useRef, useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { useAxiosPrivate } from 'hooks/useAxiosPrivate'; // import if needed
import { apiRoutes } from 'services/routes'; // import if needed
import axios from 'axios'; // Import axios for making HTTP requests
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './AddAddress.module.scss';

interface AddAddressProps {
  entityType: 'user' | 'place' | 'contact';
  entityId: number;
  onAddressAdded: () => void;
}

interface AutocompleteResult {
  description: string;
  place_id: string;
}

const AddAddress: React.FC<AddAddressProps> = ({ entityType, entityId, onAddressAdded }) => {
  const [newAddress, setNewAddress] = useState<string>('');
  const privateAPIInstance = useAxiosPrivate(); // Assuming useAxiosPrivate is a hook for API calls
  const [showInput, setShowInput] = useState<boolean>(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const [suggestions, setSuggestions] = useState<AutocompleteResult[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadSuggestions = async () => {
      if (newAddress.trim() !== '' && newAddress.length > 4 ) {
        try {
          const response = await axios.get(`http://localhost:3500/autocomplete-address?input=${encodeURIComponent(newAddress)}`);
          setSuggestions(response.data);
        } catch (error) {
          console.error('Error fetching autocomplete suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };

    // Debounce the loadSuggestions call
    const timeoutId = setTimeout(() => {
      loadSuggestions();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [newAddress]);

  const handleSelectAddress = async (placeId: string) => {
    try {
      const detailsResponse = await axios.get(`http://localhost:3500/address-details?place_id=${placeId}`);
      const addressDetails = detailsResponse.data;

      // Immediately create the new address upon selection
      const response = await privateAPIInstance.post(apiRoutes.CREATE_NEW_ADDRESS, {
        content_type: entityType,
        object_id: entityId,
        ...addressDetails,
      });

      if (response && response.data) {
        onAddressAdded(); // Refresh list in parent component
        setNewAddress(''); // Reset input
        setSuggestions([]); // Clear suggestions
      }
    } catch (error) {
      console.error('Error selecting address or creating new address:', error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewAddress(e.target.value);
  };


  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowInput(true);
    }, 2000); // 2 seconds hover
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowInput(true);
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setShowInput(false);
    }
  };

  return (
    <div 
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleEscapeKey}
      className={styles.addObjectContainer}
    >
      {showInput ? (
        <>
          <input
            type="text"
            placeholder="Add New address"
            value={newAddress}
            onChange={handleInputChange}
            ref={inputRef}
          />
          <div className={styles.suggestions}>
            {suggestions.map(suggestion => (
              <div key={suggestion.place_id} onClick={() => handleSelectAddress(suggestion.place_id)}>
                {suggestion.description}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={styles.addIcon} onClick={handleClick}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
    </div>
  );
};



export default AddAddress;
