import React, { CSSProperties } from "react";

import userMarkerStyles from "../../Home/UserMarker/UserMarker.module.scss";
import contactMarkerStyles from "../../Home/ContactMarker/ContactMarker.module.scss";
import locationMarkerStyles from "../../Home/LocationMarker/LocationMarker.module.scss";
import { UserAppearanceConfig } from "../../../types/shared";

// Preview icons ========================================

export function UserMarkerIcon({
    appearanceConfig,
    connectionOrStranger,
}: {
    appearanceConfig: UserAppearanceConfig;
    connectionOrStranger: "connection" | "stranger";
}) {
    return (
        <div
            className={`${userMarkerStyles["user-marker-icon"]} ${userMarkerStyles[connectionOrStranger]}`}
        >
            <div
                style={
                    {
                        position: "relative",
                        backgroundImage: `url(/images/john-doe.jpg)`,
                        "--size": appearanceConfig.user_marker_size + "px",
                        "--border-width":
                            appearanceConfig.user_marker_border + "px",
                        "--connection-border-color-1":
                            appearanceConfig.connection_color_1,
                        "--connection-border-color-2":
                            appearanceConfig.connection_color_2,
                        "--stranger-border-color-1":
                            appearanceConfig.stranger_color_1,
                        "--stranger-border-color-2":
                            appearanceConfig.stranger_color_2,
                    } as CSSProperties
                }
            ></div>
        </div>
    );
}

export function ContactMarkerIcon({
    appearanceConfig,
}: {
    appearanceConfig: UserAppearanceConfig;
}) {
    return (
        <div className={`${contactMarkerStyles["contact-marker-icon"]}`}>
            <div
                style={
                    {
                        position: "relative",
                        backgroundImage: `url(/images/verdens-verste-menneske.jpg)`,
                        "--size": appearanceConfig.contact_marker_size + "px",
                    } as CSSProperties
                }
            ></div>
        </div>
    );
}

export function LocationMarkerIcon({
    appearanceConfig,
}: {
    appearanceConfig: UserAppearanceConfig;
}) {
    return (
        <div className={locationMarkerStyles["location-marker-icon"]}>
            <div
                style={
                    {
                        backgroundImage: `url(/images/krakow-1.jpg)`,
                        "--size": appearanceConfig.location_marker_size + "px",
                        "--radius":
                            appearanceConfig.location_marker_size * 0.2 + "px",
                        "--border-size":
                            appearanceConfig.location_marker_border + "px",
                    } as CSSProperties
                }
            ></div>
        </div>
    );
}

export function ClusterPreview({
    appearanceConfig,
}: {
    appearanceConfig: UserAppearanceConfig;
}) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                width: appearanceConfig.cluster_size,
                height: appearanceConfig.cluster_size,
            }}
        >
            <div
                className="outer-circle"
                style={{
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: appearanceConfig.cluster_color,
                    width: "100%",
                    height: "100%",
                    opacity: 0.4,
                }}
            />
            <div
                className="inner-cirle"
                style={{
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: appearanceConfig.cluster_color,
                    width: "80%",
                    height: "80%",
                    opacity: 0.8,
                }}
            />
            <div
                style={{
                    color: "black",
                    position: "absolute",
                    fontSize: "12px",
                }}
            >
                43
            </div>
        </div>
    );
}
