import React from 'react';
import styles from '../../LocationEditMarker.module.scss'; // Make sure to import the necessary styles
import { LocationData } from '../../../HomePageTypes';

interface LocationNameInputProps {
  locationData: LocationData;
  emptyNameWarning: boolean;
  updateLocationField: (field: keyof LocationData) => (event: React.FormEvent<HTMLInputElement>) => void;
  nameRef: React.RefObject<HTMLInputElement>;
}

const LocationNameInput: React.FC<LocationNameInputProps> = ({
  locationData,
  emptyNameWarning,
  updateLocationField,
  nameRef
}) => {
  return (
    <>
      <input
        type="text"
        placeholder="Location name"
        value={locationData.name}
        ref={nameRef}
        onInput={updateLocationField('name')}
        style={
          emptyNameWarning && !locationData.name
            ? { boxShadow: '0 0 0 3px #f003', borderColor: 'red' }
            : undefined
        }
        autoFocus
        maxLength={128}
      />
      {emptyNameWarning && !locationData.name && (
        <div className={styles['empty-name-warning']}>
          You need to include at least a name
        </div>
      )}
    </>
  );
};

export default LocationNameInput;
