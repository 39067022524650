// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteObject_delete-object-button__Hi54K {
  display: inline-flex;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 4px;
  align-self: flex-start;
  position: relative; /* Position the button absolutely within the wrapper */
  top: 0; /* Adjust as needed */
  right: 0; /* Adjust as needed */
}
.DeleteObject_delete-object-button__Hi54K svg {
  width: 20px;
  height: 20px;
  fill: grey;
  transition: fill 0.3s ease; /* Add a slow transition effect */
}
.DeleteObject_delete-object-button__Hi54K:hover svg {
  fill: crimson;
}`, "",{"version":3,"sources":["webpack://./src/Components/DeleteObject/DeleteObject.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,UAAA;EACA,YAAA;EACA,6BAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA,EAAA,sDAAA;EACA,MAAA,EAAA,qBAAA;EACA,QAAA,EAAA,qBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,UAAA;EACA,0BAAA,EAAA,iCAAA;AACJ;AAGI;EACE,aAAA;AADN","sourcesContent":[".delete-object-button {\n  display: inline-flex;\n  padding: 0;\n  border: none;\n  background-color: transparent;\n  margin-left: 4px;\n  align-self: flex-start;\n  position: relative; /* Position the button absolutely within the wrapper */\n  top: 0; /* Adjust as needed */\n  right: 0; /* Adjust as needed */\n\n  svg {\n    width: 20px;\n    height: 20px;\n    fill: grey;\n    transition: fill 0.3s ease; /* Add a slow transition effect */\n  }\n\n  &:hover {\n    svg {\n      fill: crimson;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete-object-button": `DeleteObject_delete-object-button__Hi54K`
};
export default ___CSS_LOADER_EXPORT___;
