import React, { useEffect, useState } from "react";
import styles from "./StatisticsPage.module.scss";
import CommonInterests from "../../../Components/CommonInterests/CommonInterests";
import ConnectionList from "../../../Components/ConnectionList/ConnectionList";
import { useCurrentUserDetails } from "Context/CurrentUserDetailsContext";

export default function StatisticsPage() {
    let { currentUser } = useCurrentUserDetails();
    let title = `${currentUser ? currentUser.name : "User"}'s Statistics`;
    const [count, setCount] = useState(0);

    const incrementCount = (amount: number): void => {
        setCount((prevCount) => prevCount + amount);
    };

    const mouseMoveHandler = (event: MouseEvent) => {
        const x = event.clientX / window.innerWidth - 0.5;
        const y = event.clientY / window.innerHeight - 0.5;
        const elements = document.querySelectorAll(`.${styles.colMd6}`);
        elements.forEach((element) => {
            const htmlElement = element as HTMLElement;
            htmlElement.style.transform = `perspective(1000px) rotateY(${
                x * 10
            }deg) translateX(${y * 20}px) skewY(${x * -10}deg)`;
        });
    };

    useEffect(() => {
        window.addEventListener("mousemove", mouseMoveHandler);
        return () => {
            window.removeEventListener("mousemove", mouseMoveHandler);
        };
    }, []);

    return (
        <div className={styles.statisticsPage}>
            <h1 className={styles.heading}>{title}</h1>
            <div className={styles.row}>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Overview</h2>
                    <p className={styles.text}>
                        Insert your overview component here.
                    </p>
                </div>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Top Users</h2>
                    <p className={styles.text}>
                        Insert your top users component here.
                    </p>
                </div>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Common Interests</h2>
                    <p className={styles.text}>
                        Insert your top users component here.
                    </p>
                    <CommonInterests />
                </div>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Common Interests</h2>
                    <p className={styles.text}>
                        Insert your top users component here.
                    </p>
                    <ConnectionList />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Recent Activity</h2>
                    <p className={styles.text}>
                        Insert your recent activity component here.
                    </p>
                </div>
                <div className={styles.colMd6}>
                    <h2 className={styles.subheading}>Charts and Graphs</h2>
                    <p className={styles.text}>
                        Insert your charts and graphs component here.
                    </p>
                </div>
            </div>
            <button onClick={() => incrementCount(1)}>Click me!</button>
            <p>You clicked the button {count} times.</p>
        </div>
    );
}
