import React from "react";

import styles from "./PrivateNotesSection.module.scss";
import { ContactData } from "Pages/Home/HomePageTypes";

import { isOwnedBySelf } from "Pages/Home/visibility";
import Notes from "Components/Notes/Notes";
import Accordion from "Components/Accordion/Accordion";

interface PrivateNotesSectionProps {
    contact: ContactData;
    addingKeepInTouch: boolean;
}

export default function PrivateNotesSection({ contact, addingKeepInTouch }: PrivateNotesSectionProps) {
    if (
        isOwnedBySelf(contact.visibility) &&
        contact.notes.trim() !== "" &&
        !addingKeepInTouch
    ) {
        return (
            <div className={styles["notes"]}>
                <Accordion chevronSize={24}>
                    <div>Private Notes</div>
                    <Notes notes={contact.notes} />
                </Accordion>
            </div>
        );
    }
    return null;
}
