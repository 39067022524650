import { useCallback, useState } from 'react'
import { apiRoutes } from 'services/routes'
import { TUserData } from 'types/shared'
import { errorHandler } from 'services/utils'
import { useAxiosPrivate } from 'hooks/useAxiosPrivate'
import { useRouter } from 'hooks/useRouter'
import { TOnboardingPayload } from 'types/shared'
import { apiInstance } from 'services/apiInstance'
import Alert from 'Components/Alert/Alert'

export const useAuth = () => {
  const [userHasCompletedOnboarding, setUserHasCompletedOnboarding] =
    useState(false)

  const router = useRouter()

  const axiosPrivate = useAxiosPrivate()

  // Determines Onboarding Redirection URL
  const determineOnboardingRedirectURL = useCallback(
    (userData: TUserData) => {
      const completedOnboarding = !userData.is_new_user
      setUserHasCompletedOnboarding(completedOnboarding)
      completedOnboarding ? router.push('/') : router.push(apiRoutes.ONBOARDING)
    },
    [router]
  )

  const handlePasswordReset = useCallback(
    async (data: any) => {
      try {
        const res = await apiInstance.post(
          apiRoutes.INITIALIZE_PASSWORD_RESET,
          {
            email: data.email
          }
        )
        if (res.status === 200) {
          Alert(
            'success',
            'Password reset instructions have been sent to your email address.'
          )
          setTimeout(() => {
            router.push(apiRoutes.SIGNIN)
          }, 2000)
        }
      } catch (err) {
        errorHandler(err)
      }
    },
    [router]
  )

  // Handles the completion of a user onboarding
  const handleOnboardingCompletion = useCallback(
    async (data: TOnboardingPayload) => {
      const formdata = new FormData()
      for (const [key, value] of Object.entries(data)) {
        formdata.append(key, value as string)
      }
      if (router.query.has('invitationToken')) {
        formdata.append(
          'invitationToken',
          router.query.get('invitationToken') || ''
        )
      }
      try {
        //using multipart/formdata as content type because of the profile picture
        const res = await axiosPrivate.post(
          apiRoutes.COMPLETE_ACCOUNT_ONBOARDING,
          formdata
        )
        if (res.status === 200) {
          router.push('/')
        }
      } catch (err) {
        errorHandler(err)
      }
    },
    [axiosPrivate, router]
  )

  const clearLocalStorage = useCallback(() => {
    // Implement the logic to clear local storage
    localStorage.clear();
  }, []);

  return {
    userHasCompletedOnboarding,
    handleOnboardingCompletion,
    determineOnboardingRedirectURL,
    handlePasswordReset,
    clearLocalStorage
  }
}