import React from 'react';
import { PublicActivityStream } from '../../Pages/LandingPage/reducer';
import uuid from 'react-uuid';

function ActivityStream({
	activities,
}: {
	activities: Array<PublicActivityStream> | null;
}) {
	const loopArr = [1, 2, 3, 4, 5];

	return (
		<div className="relative flex overflow-x-hidden hover:pointer-events-none">
			<div className="py-12 animate-marquee whitespace-nowrap">
				<div className="mb-4 flex">
					{activities && activities.length !== 0 ? (
						activities?.map((act) => (
							<div className="flex items-center mb-2 mx-4" key={uuid()}>
								<img
									src={act.profile_image}
									alt="Avatar"
									className="w-10 h-10 rounded-full border-2 border-purple-500"
								/>
								<div className="ml-4">
									<p className="text-gray-700 font-bold">
										{act.action_taken_by}
									</p>
									<p className="text-purple-500 ">
										<b>{act.type}</b> <i>{act.action_object}</i> to{' '}
										<b>{act.action_target}</b>
									</p>
								</div>
							</div>
						))
					) : (
						<>
							{loopArr.map((elem) => (
								<div
									key={elem}
									className="flex items-center mb-2 mx-2 animate-pulse"
								>
									<div className="w-10 h-10 bg-purple-400 rounded-full"></div>
									<div className="ml-2">
										<p className="w-48 h-5 bg-purple-400 rounded-full"></p>
										<p className="w-60 h-5 bg-purple-400 rounded-full mt-2"></p>
									</div>
								</div>
							))}
						</>
					)}
				</div>
			</div>

			<div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
				<div className="mb-4 flex">
					{activities && activities.length !== 0 ? (
						activities?.map((act) => (
							<div className="flex items-center mb-2 mx-4" key={uuid()}>
								<img
									src={act.profile_image}
									alt="Avatar"
									className="w-10 h-10 rounded-full border-2 border-pink-500"
								/>
								<div className="ml-4">
									<p className="text-gray-700 font-bold">
										{act.action_taken_by}
									</p>
									<p className="text-pink-500 ">
										<b>{act.type}</b> <i>{act.action_object}</i> to{' '}
										<b>{act.action_target}</b>
									</p>
								</div>
							</div>
						))
					) : (
						<>
							{loopArr.map((elem) => (
								<div
									key={elem}
									className="flex items-center mb-2 mx-2 animate-pulse"
								>
									<div className="w-10 h-10 bg-pink-400 rounded-full"></div>
									<div className="ml-2">
										<p className="w-48 h-5 bg-pink-400 rounded-full"></p>
										<p className="w-60 h-5 bg-pink-400 rounded-full mt-2"></p>
									</div>
								</div>
							))}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default ActivityStream;
