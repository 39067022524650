import React, { useState, useRef, useEffect, useMemo } from 'react';

export default function LazyRoundThumbnailImage({
  image,
  size: _size,
  rounding,
  className,
}: {
  image: string | Blob;
  size?: number;
  rounding?: number;
  className?: string;
}) {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLDivElement | null>(null);  // <-- specify the type here

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && imageRef.current) {  // <-- add the check here
          setLoaded(true);
          observer.unobserve(imageRef.current);
        }
      });
    });

    if (imageRef.current) {  // <-- add the check here
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {  // <-- and here too
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  let size = _size || 60;
  let imageUrl = useMemo(
    () =>
      loaded && (typeof image === "string"
        ? image
        : (window.URL || window.webkitURL).createObjectURL(image)),
    [image, loaded]
  );

  return (
    <div
      ref={imageRef}
      className={className}
      style={{
        backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${
          ((rounding === undefined ? 1 : rounding) * size) / 2
        }px`,
        display: "inline-block",
        flexShrink: 0,
      }}
    ></div>
  );
}
