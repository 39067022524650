// DescriptionAndContactInput.tsx
import React from 'react';
import { LocationData } from '../../../HomePageTypes';

interface DescriptionAndContactInputProps {
  locationData: LocationData;
  updateLocationField: (
    field:
      | 'description'
      | 'phoneNumber'
      | 'email'
  ) => (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  descriptionRef: React.RefObject<HTMLTextAreaElement>;
  phoneNumberRef: React.RefObject<HTMLInputElement>;
  emailRef: React.RefObject<HTMLInputElement>;
}

const DescriptionAndContactInput: React.FC<DescriptionAndContactInputProps> = ({
  locationData,
  updateLocationField,
  descriptionRef,
  phoneNumberRef,
  emailRef,
}) => {
  return (
    <>
      <textarea
        name=""
        id=""
        cols={30}
        rows={3}
        ref={descriptionRef}
        placeholder="Description"
        value={locationData.description}
        onInput={updateLocationField('description')}
      ></textarea>
      <input
        type="text"
        placeholder="Phone number (optional)"
        ref={phoneNumberRef}
        value={locationData.phoneNumber}
        onInput={updateLocationField('phoneNumber')}
        maxLength={20}
      />
      <input
        type="email"
        ref={emailRef}
        placeholder="Email (optional)"
        value={locationData.email}
        onInput={updateLocationField('email')}
        maxLength={254}
      />
    </>
  );
};

export default DescriptionAndContactInput;
