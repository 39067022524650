// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDialogTitle_custom-dialog-title__PsVde {
  background-color: #f2f2f2;
  color: #333;
  padding: 16px;
  font-size: 1.5em;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomDialogTitle_custom-dialog-title__PsVde .CustomDialogTitle_additional-elements__hiLPF {
  cursor: pointer;
  font-size: 1.2em;
}

.CustomDialogTitle_close-button__cRxbf {
  background: none;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/CustomDialogTitle/CustomDialogTitle.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,6BAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AAEI;EACE,eAAA;EACA,gBAAA;AAAN;;AAIE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;AADJ","sourcesContent":[".custom-dialog-title {\n    background-color: #f2f2f2;\n    color: #333;\n    padding: 16px;\n    font-size: 1.5em;\n    border-bottom: 1px solid #ccc;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  \n    // For adding a close button or additional elements in the title area\n    .additional-elements {\n      cursor: pointer;\n      font-size: 1.2em;\n    }\n  }\n  \n  .close-button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    // Add more styles to position it properly if needed\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-dialog-title": `CustomDialogTitle_custom-dialog-title__PsVde`,
	"additional-elements": `CustomDialogTitle_additional-elements__hiLPF`,
	"close-button": `CustomDialogTitle_close-button__cRxbf`
};
export default ___CSS_LOADER_EXPORT___;
