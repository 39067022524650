// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddExperience_addObjectContainer__DO7kU {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddExperience_addIcon__Ie3-t {
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
}
.AddExperience_addIcon__Ie3-t:hover {
  color: #0056b3;
}

.AddExperience_addObjectContainer__DO7kU input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}
.AddExperience_addObjectContainer__DO7kU input:focus {
  outline: none;
  border-color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/Components/ExperienceManager/AddExperience.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,eAAA;EACA,eAAA;EACA,cAAA;AAAF;AAEE;EACE,cAAA;AAAJ;;AAKA;EACE,sBAAA;EACA,kBAAA;EACA,YAAA;AAFF;AAGE;EACE,aAAA;EACA,qBAAA;AADJ","sourcesContent":[".addObjectContainer {\n  // ... other styles\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.addIcon {\n  cursor: pointer;\n  font-size: 24px; // Adjust size as needed\n  color: #007bff; // Adjust color as needed\n\n  &:hover {\n    color: #0056b3; // Adjust hover color as needed\n  }\n}\n\n// Style for the input field, if needed\n.addObjectContainer input {\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 5px;\n  &:focus {\n    outline: none;\n    border-color: #007bff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addObjectContainer": `AddExperience_addObjectContainer__DO7kU`,
	"addIcon": `AddExperience_addIcon__Ie3-t`
};
export default ___CSS_LOADER_EXPORT___;
