import React, { useState } from "react";
import { MinimalContactData } from "../../Home/HomePageTypes";
import CustomDialogTitle from '../../../Components/CustomDialogTitle/CustomDialogTitle';  // Add your custom import here
import CustomDialog from '../../../Components/CustomDialog/CustomDialog';
import CustomDialogActions from '../../../Components/CustomDialogActions/CustomDialogActions';
import MuiReplaceCustomButton from '../../../Components/MuiReplaceCustomButton/MuiReplaceCustomButton';

import { useHistory } from "react-router-dom";
import { sixDecimalPlaces } from "Utils/utils";

export const MemberContacts = ({
    member_contacts,
    removeMember,
    isNetworkOwner,
}: {
    member_contacts: MinimalContactData[];
    removeMember: (type: 'user' | 'contact' | 'place', memberId: Number) => void;
    isNetworkOwner: () => boolean;
}) => {
    const [active, setActive] = useState<Number>(0);
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = useState<Number>(0);

    const handleClickOpen = (id: Number) => {
        setSelected(id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const confirmDelete = () => {
        removeMember('contact', selected);
        setOpen(false);
    };
    let history = useHistory();
    return (
        <div>
            <div className="px-4 pt-4 pb-2 text-xs font-medium text-gray-500 uppercase">
                Contacts
            </div>
            <div className="overflow-y-auto h-40 border-solid border-2">
                {member_contacts.length > 0 ? (
                    member_contacts.map((data) => (
                        <div
                            className={`flex justify-between items-center px-4 py-2 space-x-3 cursor-pointer hover:bg-gray-200`}
                            onMouseEnter={() => setActive(data.id)}
                            onMouseLeave={() => setActive(0)}
                            key={data.id}
                        >
                            <div className="flex items-center">
                            <img
                                src={data.profilePicture ? data.profilePicture : "/static/images/default-user-image.svg"}
                                alt={data.name ? data.name : ''}
                                className="w-8 h-8 rounded-full"
                                onClick={() => {
                                    if (data.coordinates) {
                                    history.push(`/?y=${sixDecimalPlaces(data.coordinates.y)}&x=${sixDecimalPlaces(data.coordinates.x)}&z=12&showContact=${data.id}`);
                                    }
                                }}
                                />
                                <span
                                className="text-sm font-medium mx-2"
                                onClick={() => {
                                    if (data.coordinates) {
                                    history.push(`/?y=${sixDecimalPlaces(data.coordinates.y)}&x=${sixDecimalPlaces(data.coordinates.x)}&z=12&showContact=${data.id}`);
                                    }
                                }}
                                >
                                {data.name}
                                </span>
                            </div>
                            {(active === data.id && isNetworkOwner()) && <div onClick={() => handleClickOpen(data.id)} className="text-sm italic text-gray-700 hover:text-gray-900 cursor-pointer">remove</div>}
                        </div>
                    ))
                ) : (
                    <div className="px-4 py-2 text-sm text-gray-500">
                        No members found.
                    </div>
                )}

                <CustomDialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CustomDialogTitle id="alert-dialog-title">
                        {"Are you sure to remove " + member_contacts.find(x => x.id === selected)?.name + '?'}
                    </CustomDialogTitle>
                    <CustomDialogActions>
                        <MuiReplaceCustomButton 
                            onClick={handleClose}
                            color="green">
                                No
                        </MuiReplaceCustomButton>
                        <MuiReplaceCustomButton 
                            onClick={confirmDelete}
                            color="red"
                            >
                                Yes
                        </MuiReplaceCustomButton>
                    </CustomDialogActions>
                </CustomDialog>
            </div>
        </div>
    )
};