// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckboxList_checkbox-list__Pq93\\+ ul {
  list-style-type: none;
  margin: 0;
  padding: 5px;
}
.CheckboxList_checkbox-list__Pq93\\+ ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CheckboxList_checkbox-list__Pq93\\+ ul li .CheckboxList_checkbox__KIzgG {
  margin: 7px 10px 7px 0;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Components/CheckboxList/CheckboxList.module.scss"],"names":[],"mappings":"AACG;EACG,qBAAA;EACA,SAAA;EACA,YAAA;AAAN;AAEM;EACG,aAAA;EACA,mBAAA;EACA,mBAAA;AAAT;AAES;EACG,sBAAA;EACA,aAAA;AAAZ","sourcesContent":[".checkbox-list {\n   ul {\n      list-style-type: none;\n      margin: 0;\n      padding: 5px;\n\n      li {\n         display: flex;\n         flex-direction: row;\n         align-items: center;\n\n         .checkbox {\n            margin: 7px 10px 7px 0;\n            display: flex;\n         }\n      }\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-list": `CheckboxList_checkbox-list__Pq93+`,
	"checkbox": `CheckboxList_checkbox__KIzgG`
};
export default ___CSS_LOADER_EXPORT___;
