// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarkerObject_object-text__mzc5P {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.MarkerObject_object-text__mzc5P .MarkerObject_clickable-name__0aX67 {
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
}
.MarkerObject_object-text__mzc5P .MarkerObject_clickable-name__0aX67:hover {
  color: var(--accent-color);
}
.MarkerObject_object-text__mzc5P .MarkerObject_add-image-button__8kObU,
.MarkerObject_object-text__mzc5P .MarkerObject_delete-object-button__F4noV {
  display: none;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 12px;
  align-self: flex-start;
}
.MarkerObject_object-text__mzc5P .MarkerObject_add-image-button__8kObU svg,
.MarkerObject_object-text__mzc5P .MarkerObject_delete-object-button__F4noV svg {
  width: 13px;
  height: 13px;
  fill: rgb(255, 255, 255);
}
.MarkerObject_object-text__mzc5P:hover .MarkerObject_add-image-button__8kObU,
.MarkerObject_object-text__mzc5P:hover .MarkerObject_delete-object-button__F4noV {
  display: inline-flex;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 12px;
  align-self: flex-start;
}
.MarkerObject_object-text__mzc5P:hover .MarkerObject_add-image-button__8kObU svg,
.MarkerObject_object-text__mzc5P:hover .MarkerObject_delete-object-button__F4noV svg {
  width: 13px;
  height: 13px;
  fill: grey;
}
.MarkerObject_object-text__mzc5P:hover .MarkerObject_add-image-button__8kObU svg:hover,
.MarkerObject_object-text__mzc5P:hover .MarkerObject_delete-object-button__F4noV svg:hover {
  fill: crimson;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/MarkerObjectsList/MarkerObject/MarkerObject.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;AACJ;AACI;EACI,oBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,UAAA;EACA,YAAA;AACR;AACQ;EACI,0BAAA;AACZ;AAGI;;EAEI,aAAA;EACA,UAAA;EACA,YAAA;EACA,6BAAA;EACA,iBAAA;EACA,sBAAA;AADR;AAGQ;;EACI,WAAA;EACA,YAAA;EACA,wBAAA;AAAZ;AAMQ;;EAEI,oBAAA;EACA,UAAA;EACA,YAAA;EACA,6BAAA;EACA,iBAAA;EACA,sBAAA;AAJZ;AAMY;;EACI,WAAA;EACA,YAAA;EACA,UAAA;AAHhB;AAKgB;;EACI,aAAA;AAFpB","sourcesContent":[".object-text {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n\n    .clickable-name {\n        font-weight: inherit;\n        color: inherit;\n        cursor: pointer;\n        background-color: transparent;\n        padding: 0;\n        border: none;\n\n        &:hover {\n            color: var(--accent-color)\n        }\n    }\n\n    .add-image-button,\n    .delete-object-button {\n        display: none;\n        padding: 0;\n        border: none;\n        background-color: transparent;\n        margin-left: 12px;\n        align-self: flex-start;\n\n        svg {\n            width: 13px;\n            height: 13px;\n            fill: rgb(255, 255, 255);\n        }\n    }\n\n    &:hover {\n\n        .add-image-button,\n        .delete-object-button {\n            display: inline-flex;\n            padding: 0;\n            border: none;\n            background-color: transparent;\n            margin-left: 12px;\n            align-self: flex-start;\n\n            svg {\n                width: 13px;\n                height: 13px;\n                fill: grey;\n\n                &:hover {\n                    fill: crimson\n                }\n\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"object-text": `MarkerObject_object-text__mzc5P`,
	"clickable-name": `MarkerObject_clickable-name__0aX67`,
	"add-image-button": `MarkerObject_add-image-button__8kObU`,
	"delete-object-button": `MarkerObject_delete-object-button__F4noV`
};
export default ___CSS_LOADER_EXPORT___;
