import { JournalTemplate } from "Components/TextBox/Editor";
import { ApiError } from "hooks/useAPI";
import { GpsLocation } from "./FloatingControls/ShowMyLocation/ShowMyLocation";
import {
    ContactData,
    ContextMenuData,
    TravelPlanData,
    LocationData,
    OpenedPopupDetails,
    Point,
    UserData,
    MinimalUserData,
    MinimalContactData,
    MinimalLocationData,
    MinimalTravelPlanData,
    ClusterData,
    CoreMapDataAPIReturnType,
    ContactBeingEdited,
    KeepInTouchAdded,
    LocationBeingEdited,
    ObjectBeingAddedTo,
    SkillBeingAddedTo,
} from "./HomePageTypes";
import { UserAppearanceConfig } from "../../types/shared";
import { defaultAppearanceSettings } from "Pages/Settings/AppearanceSettings/defaultAppearanceSettings";
import { performFullClustering } from "Utils/clustering/performFullClustering";

import { ACTIONS } from "Pages/Home/reducerActions";
import { HomePageState } from "Pages/Home/HomePageState";

export type SearchForOptions =
    | "Locations"
    | "People"
    | "Contacts"
    | "Skills"
    | "Objects";

export function getInitialState(
    searchParameter: string | null,
    searchForParameter: string | null
): HomePageState {
    return {
        users: null,
        contacts: null,
        locations: null,
        travelPlans: null,
        clusters: null,
        mapBounds: null,
        mapPosition: null,
        mapSize: null,
        openPopup: null,
        gpsPosition: null,
        contextMenu: null,
        searchInput: searchParameter || "",
        searchFor: (searchForParameter as SearchForOptions | null) || "",
        tileLayer: localStorage.getItem("selected-tile-layer") || "Google",
        skillBeingAddedTo: null,
        objectBeingAddedTo: null,
        addingCoordinates: false,
        importingContacts: false,
        addingContactFromLinkedin: false,
        addingTravelPlan: false,
        editingTravelPlan: null,
        locationBeingEdited: null,
        contactBeingEdited: null,
        addingNetwork: false,
        networkBeingEdited: null,
        alert: null,
        selectedLayers: JSON.parse(localStorage.getItem("selected-display-layers") || '["Users", "Contacts", "Locations", "Travel Plans"]'),        hoveredOverUser: null,
        keepInTouchAdded: null,
        addingJournalEntry: null,
    };
}




export type DispatchAction =
    // Camel-case action types are used to directly update matching properties of the
    // state. E.g., the action type "users" will update the "users" property of the state.
    | { type: typeof ACTIONS.users; value: Array<MinimalUserData> | ApiError | null }
    | { type: typeof ACTIONS.contacts; value: Array<MinimalContactData> | ApiError | null }
    | { type: typeof ACTIONS.locations; value: Array<MinimalLocationData> | ApiError | null }
    | {
          type: typeof ACTIONS.travelPlans;
          value: Array<MinimalTravelPlanData> | ApiError | null;
      }
    | { type: typeof ACTIONS.clusters; value: ApiError | Array<ClusterData> | null }
    | { type: typeof ACTIONS.mapBounds; value: L.LatLngBounds | null }
    | {
          type: typeof ACTIONS.mapPosition;
          value: {
              x: number;
              y: number;
              zoomLevel: number;
          } | null;
      }
    | { type: typeof ACTIONS.gpsPosition; value: GpsLocation | null }
    | { type: typeof ACTIONS.openPopup; value: OpenedPopupDetails | null }
    | { type: typeof ACTIONS.searchInput; value: string }
    | { type: typeof ACTIONS.searchFor; value: string }
    | { type: typeof ACTIONS.contextMenu; value: ContextMenuData | null }
    | { type: typeof ACTIONS.skillBeingAddedTo; value: SkillBeingAddedTo | null }
    | { type: typeof ACTIONS.objectBeingAddedTo; value: ObjectBeingAddedTo | null }
    | { type: typeof ACTIONS.addingCoordinates; value: boolean }
    | { type: typeof ACTIONS.importingContacts; value: boolean }
    | { type: typeof ACTIONS.addingContactFromLinkedin; value: boolean }
    | { type: typeof ACTIONS.addingTravelPlan; value: boolean }
    | { type: typeof ACTIONS.editingTravelPlan; value: TravelPlanData | null }
    | { type: typeof ACTIONS.locationBeingEdited; value: LocationBeingEdited | null }
    | { type: typeof ACTIONS.contactBeingEdited; value: ContactBeingEdited | null }
    | { type: typeof ACTIONS.alert; value: string | null }
    | {
          type: typeof ACTIONS.selectedLayers;
          value: Array<"Users" | "Contacts" | "Locations" | "Travel Plans">;
      }
    | { type: typeof ACTIONS.hoveredOverUser; value: MinimalUserData | null }
    | { type: typeof ACTIONS.addingNetwork; value: boolean }
    | { type: typeof ACTIONS.addingJournalEntry; value: JournalTemplate | null }
    | { type: typeof ACTIONS.tileLayer; value: string }
    | { type: typeof ACTIONS.ADD_CONTACT; contact: ContactData }
    | { type: typeof ACTIONS.ADD_LOCATION; location: LocationData }
    | { type: typeof ACTIONS.ADD_TRAVEL_PLAN; travelPlan: TravelPlanData }
    | { type: typeof ACTIONS.CANCEL_ADDING_SKILL }
    | { type: typeof ACTIONS.CANCEL_ADDING_OBJECT }
    | { type: typeof ACTIONS.CANCEL_MARKER_EDITING }
    | {
          type: typeof ACTIONS.MAP_DATA;
          data: CoreMapDataAPIReturnType | ApiError;
          apperanceConfig?: UserAppearanceConfig;
      }
    | { type: typeof ACTIONS.CLEAR_ALERT }
    | { type: typeof ACTIONS.CLOSE_POPUP }
    | { type: typeof ACTIONS.CLOSE_TRAVEL_PLAN_EDITOR }
    | { type: typeof ACTIONS.CREATE_NEW_CONTACT; coordinates: Point }
    | { type: typeof ACTIONS.CREATE_NEW_LOCATION; coordinates: Point }
    | { type: typeof ACTIONS.CREATE_NEW_NETWORK; coordinates: Point }
    | { type: typeof ACTIONS.DELETE_CONTACT; contact: ContactData }
    | { type: typeof ACTIONS.DELETE_LOCATION; location: LocationData }
    | { type: typeof ACTIONS.DELETE_TRAVEL_PLAN; travelPlan: TravelPlanData }
    | { type: typeof ACTIONS.INSERT_CONTACTS; contacts: Array<MinimalContactData> }
    | { type: typeof ACTIONS.MAP_CLICKED; coordinates: Point }
    | {
          type: typeof ACTIONS.MAP_RESIZED;
          mapBounds: L.LatLngBounds;
          mapCenter: {
              x: number;
              y: number;
              zoomLevel: number;
          };
          mapSize: { x: number; y: number };
      }
    | { type: typeof ACTIONS.START_ADDING_OBJECT; receiver: ObjectBeingAddedTo }
    | { type: typeof ACTIONS.START_ADDING_SKILL; receiver: SkillBeingAddedTo }
    | { type: typeof ACTIONS.START_EDITING_CONTACT; contact: ContactData }
    | { type: typeof ACTIONS.START_EDITING_LOCATION; location: LocationData }
    | { type: typeof ACTIONS.START_SKILL_SEARCH; skillName: string }
    | { type: typeof ACTIONS.START_OBJECT_SEARCH; objectName: string }
    | { type: typeof ACTIONS.UPDATE_CONTACT; contact: ContactData | MinimalContactData }
    | { type: typeof ACTIONS.UPDATE_USER; user: UserData | MinimalUserData }
    | { type: typeof ACTIONS.UPDATE_LOCATION; location: LocationData | MinimalLocationData }
    | {
          type: typeof ACTIONS.UPDATE_TRAVEL_PLAN;
          travelPlan: TravelPlanData | MinimalTravelPlanData;
      }
    | { type: typeof ACTIONS.keepInTouchAdded; value: KeepInTouchAdded | null }
    | { type: typeof ACTIONS.ADD_KEEP_IN_TOUCH; receiver: KeepInTouchAdded }
    | { type: typeof ACTIONS.CANCEL_ADDING_KEEP_IN_TOUCH };

export function reducer(
    state: HomePageState,
    action: DispatchAction
): HomePageState {
    const updateField = <F extends keyof HomePageState>(
        field: F,
        value: HomePageState[F]
    ) => ({
        ...state,
        [field]: value,
    });

    switch (action.type) {
        // Camel-case action types are used to directly update
        // matching properties of the state
        // E.g., the action type "users" will update the
        // "users" property of the state.
        case ACTIONS.users:
        case ACTIONS.locations:
        case ACTIONS.contacts:
        case ACTIONS.travelPlans:
        case ACTIONS.clusters:
        case ACTIONS.mapBounds:
        case ACTIONS.mapPosition:
        case ACTIONS.gpsPosition:
        case ACTIONS.openPopup:
        case ACTIONS.searchInput:
        case ACTIONS.searchFor:
        case ACTIONS.contextMenu:
        case ACTIONS.skillBeingAddedTo:
        case ACTIONS.objectBeingAddedTo:
        case ACTIONS.addingCoordinates:
        case ACTIONS.importingContacts:
        case ACTIONS.addingContactFromLinkedin:
        case ACTIONS.addingTravelPlan:
        case ACTIONS.addingNetwork:
        case ACTIONS.editingTravelPlan:
        case ACTIONS.locationBeingEdited:
        case ACTIONS.contactBeingEdited:
        case ACTIONS.alert:
        case ACTIONS.keepInTouchAdded:
        case ACTIONS.addingJournalEntry:
        case ACTIONS.hoveredOverUser:
            return updateField(action.type, action.value);
        case ACTIONS.selectedLayers:
            const selectedLayersString = JSON.stringify(action.value);
            localStorage.setItem("selected-display-layers", selectedLayersString);
            return updateField(action.type, action.value);  
        case ACTIONS.tileLayer:
            localStorage.setItem("selected-tile-layer", action.value);
            return updateField(ACTIONS.tileLayer, action.value);
        case ACTIONS.ADD_CONTACT:
            return {
                ...state,
                contacts:
                    state.contacts instanceof Array
                        ? [...state.contacts, action.contact]
                        : [action.contact],
            };
        case ACTIONS.ADD_LOCATION:
            return {
                ...state,
                locations:
                    state.locations instanceof Array
                        ? [...state.locations, action.location]
                        : [action.location],
            };
        case ACTIONS.ADD_TRAVEL_PLAN:
            return {
                ...state,
                travelPlans:
                    state.travelPlans instanceof Array
                        ? [...state.travelPlans, action.travelPlan]
                        : [action.travelPlan],
            };
        case ACTIONS.CANCEL_ADDING_SKILL:
            return {
                ...state,
                skillBeingAddedTo: null,
                openPopup: null,
            };
        case ACTIONS.CANCEL_ADDING_OBJECT:
            return {
                ...state,
                objectBeingAddedTo: null,
                openPopup: null,
            };
        case ACTIONS.CANCEL_MARKER_EDITING:
            return {
                ...state,
                contactBeingEdited: null,
                locationBeingEdited: null,
            };
        case ACTIONS.CLEAR_ALERT:
            return {
                ...state,
                alert: null,
            };
        case ACTIONS.CLOSE_POPUP:
            return {
                ...state,
                openPopup: null,
            };
        case ACTIONS.CLOSE_TRAVEL_PLAN_EDITOR:
            return {
                ...state,
                addingTravelPlan: false,
                editingTravelPlan: null,
            };
        case ACTIONS.CREATE_NEW_CONTACT:
            return {
                ...state,
                contactBeingEdited: {
                    data: {
                        id: 0,
                        name: "",
                        coordinates: action.coordinates,
                        description: "",
                        objects: [],
                        ownerName: "",
                        ownerHandle: "",
                        ownerId: 0,
                        profilePicture: null,
                        skills: [],
                        visibility: "private",
                        email: "",
                        phoneNumber: "",
                        notes: "",
                        facebook: "",
                        twitter: "",
                        instagram: "",
                        linkedin: "",
                        youtube: "",
                        keepInTouch: null,
                        networks: [],
                        website: "",
                    } satisfies ContactData,
                    editingOrNew: "new",
                },
            };
        case ACTIONS.CREATE_NEW_LOCATION:
            return {
                ...state,
                locationBeingEdited: {
                    data: {
                        id: 0,
                        name: "",
                        handle: "",
                        pictures: [],
                        coordinates: action.coordinates,
                        description: "",
                        objects: [],
                        mainPicture: null,
                        visibility: "private",
                        email: "",
                        phoneNumber: "",
                        ownerName: "",
                        ownerHandle: "",
                        ownerId: 0,
                        youtube: "",
                        linkedin: "",
                        twitter: "",
                        facebook: "",
                        instagram: "",
                        website: "",
                    } as LocationData,
                    editingOrNew: "new",
                },
            };
        case ACTIONS.CREATE_NEW_NETWORK:
            return {
                ...state,
                networkBeingEdited: {
                    data: {
                        name: "",
                        coordinates: action.coordinates,
                        description: "",
                        network_logo: "",
                        visibility: "public",
                        members: {},
                    },
                    editingOrNew: "new",
                },
            };
        case ACTIONS.DELETE_CONTACT:
            return {
                ...state,
                contacts:
                    state.contacts instanceof Array
                        ? state.contacts.filter(
                              (contact) => contact.id !== action.contact.id
                          )
                        : state.contacts,
            };
        case ACTIONS.DELETE_LOCATION:
            return {
                ...state,
                locations:
                    state.locations instanceof Array
                        ? state.locations.filter(
                              (location) => location.id !== action.location.id
                          )
                        : state.locations,
            };
        case ACTIONS.DELETE_TRAVEL_PLAN:
            return {
                ...state,
                travelPlans:
                    state.travelPlans instanceof Array
                        ? state.travelPlans.filter(
                              (plan) => plan.id !== action.travelPlan.id
                          )
                        : state.travelPlans,
            };
        case ACTIONS.INSERT_CONTACTS:
            return {
                ...state,
                contacts:
                    state.contacts instanceof Array
                        ? [...state.contacts, ...action.contacts]
                        : action.contacts,
            };
        case ACTIONS.MAP_CLICKED:
            return state.addingCoordinates
                ? state.locationBeingEdited
                    ? {
                          ...state,
                          locationBeingEdited: {
                              data: {
                                  ...state.locationBeingEdited.data,
                                  coordinates: action.coordinates,
                              },
                              editingOrNew: "new",
                          },
                          addingCoordinates: false,
                      }
                    : state.contactBeingEdited
                    ? {
                          ...state,
                          contactBeingEdited: {
                              data: {
                                  ...state.contactBeingEdited.data,
                                  coordinates: action.coordinates,
                              },
                              editingOrNew: "new",
                          },
                          addingCoordinates: false,
                      }
                    : state
                : state;
        case ACTIONS.MAP_DATA:
            if (action.data instanceof ApiError) {
                return state;
            } else {
                return {
                    ...state,
                    ...performFullClustering(
                        action.data,
                        state.mapSize,
                        state.mapBounds,
                        action.apperanceConfig || defaultAppearanceSettings
                    ),
                };
            }
        case ACTIONS.MAP_RESIZED:
            return {
                ...state,
                mapBounds: action.mapBounds,
                mapPosition: action.mapCenter,
                mapSize: { height: action.mapSize.y, width: action.mapSize.x },
            };
        case ACTIONS.START_ADDING_OBJECT:
            return {
                ...state,
                objectBeingAddedTo: action.receiver,
                openPopup: {
                    type: action.receiver.type,
                    id: action.receiver.for.id,
                },
            };
        case ACTIONS.START_ADDING_SKILL:
            return {
                ...state,
                skillBeingAddedTo: action.receiver,
                openPopup: {
                    type: action.receiver.type,
                    id: action.receiver.for.id,
                },
            };
        case ACTIONS.START_EDITING_CONTACT:
            return {
                ...state,
                contactBeingEdited: {
                    data: action.contact,
                    editingOrNew: "editing",
                },
                addingContactFromLinkedin: false,
                openPopup: { type: "contact", id: action.contact.id },
            };
        case ACTIONS.START_EDITING_LOCATION:
            return {
                ...state,
                locationBeingEdited: {
                    data: action.location,
                    editingOrNew: "editing",
                },
                openPopup: { type: "location", id: action.location.id },
            };
        case ACTIONS.START_SKILL_SEARCH:
            return {
                ...state,
                searchFor: "Skills",
                searchInput: action.skillName,
            };
        case ACTIONS.START_OBJECT_SEARCH:
            return {
                ...state,
                searchFor: "Objects",
                searchInput: action.objectName,
            };
        case ACTIONS.UPDATE_CONTACT:
            return {
                ...state,
                contacts:
                    state.contacts instanceof Array
                        ? state.contacts.map((contact) =>
                              contact.id === action.contact.id
                                  ? action.contact
                                  : contact
                          )
                        : state.contacts,
            };
        case ACTIONS.UPDATE_LOCATION:
            return {
                ...state,
                locations:
                    state.locations instanceof Array
                        ? state.locations.map((location) =>
                              location.id === action.location.id
                                  ? action.location
                                  : location
                          )
                        : state.locations,
            };
        case ACTIONS.UPDATE_USER:
            return {
                ...state,
                users:
                    state.users instanceof Array
                        ? state.users.map((user) =>
                              user.id === action.user.id ? action.user : user
                          )
                        : state.users,
            };
        case ACTIONS.UPDATE_TRAVEL_PLAN:
            return {
                ...state,
                travelPlans:
                    state.travelPlans instanceof Array
                        ? state.travelPlans.map((plan) =>
                              plan.id === action.travelPlan.id
                                  ? action.travelPlan
                                  : plan
                          )
                        : state.travelPlans,
            };
        case ACTIONS.ADD_KEEP_IN_TOUCH:
            return {
                ...state,
                keepInTouchAdded: action.receiver,
                openPopup: {
                    type: action.receiver.type,
                    id: action.receiver.for.id,
                },
            };
        case ACTIONS.CANCEL_ADDING_KEEP_IN_TOUCH:
            return {
                ...state,
                keepInTouchAdded: null,
                openPopup: null,
            };
    default:
        return state;
    }
}
