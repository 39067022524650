import React from "react";
import styles from "./ContactHeader.module.scss";
import { ContactData } from "Pages/Home/HomePageTypes";
import RoundThumbnailImage from "Components/RoundThumbnailImage/RoundThumbnailImage";
import { isOwnedBySelf } from "Pages/Home/visibility";

interface ContactHeaderProps {
  contact: ContactData;
}

export const ContactHeader: React.FC<ContactHeaderProps> = ({ contact }) => {
  return (
    <div className={styles["popup-heading"]}>
      <RoundThumbnailImage
        className={styles["profile-picture"]}
        image={contact.profilePicture || "/images/default-user-image.svg"}
        size={60}
      />
      <div className={styles["basic-details"]}>
        <h5>{contact.name}</h5>
        <p>
          {isOwnedBySelf(contact.visibility)
            ? "Your contact"
            : `${contact.ownerName}'s contact`}
        </p>

      </div>
    </div>
  );
};

export default ContactHeader;
