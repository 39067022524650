// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactHeader_popup-heading__OQ3xr {
  max-width: 250px;
  min-width: 200px;
  width: 100%;
  padding: 0;
  text-decoration: none;
  color: inherit;
  display: grid;
  grid-template-columns: 60px auto;
  padding: 10px;
  background-color: #f8f8f8;
  font-weight: normal;
}
.ContactHeader_popup-heading__OQ3xr .ContactHeader_basic-details__NAXda {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ContactHeader_popup-heading__OQ3xr .ContactHeader_basic-details__NAXda h5,
.ContactHeader_popup-heading__OQ3xr .ContactHeader_basic-details__NAXda p {
  margin: 2px 0;
}
.ContactHeader_popup-heading__OQ3xr .ContactHeader_basic-details__NAXda h5 {
  font-weight: 600;
  font-size: 1.4em;
}
.ContactHeader_popup-heading__OQ3xr .ContactHeader_basic-details__NAXda p {
  font-size: 0.9em;
}

.ContactHeader_contact-popup-image__7bvPs {
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ContactHeader/ContactHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,qBAAA;EACA,cAAA;EACA,aAAA;EACA,gCAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACN;AACM;;EAEI,aAAA;AACV;AAEM;EACI,gBAAA;EACA,gBAAA;AAAV;AAGM;EACI,gBAAA;AADV;;AAUA;EACE,2BAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AAPF","sourcesContent":[".popup-heading {\n  max-width: 250px;\n  min-width: 200px;\n  width: 100%;\n  padding: 0;\n  text-decoration: none;\n  color: inherit;\n  display: grid;\n  grid-template-columns: 60px auto;\n  padding: 10px;\n  background-color: #f8f8f8;\n  font-weight: normal;\n\n  .basic-details {\n      margin-left: 10px;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n\n      h5,\n      p {\n          margin: 2px 0;\n      }\n\n      h5 {\n          font-weight: 600;\n          font-size: 1.4em;\n      }\n\n      p {\n          font-size: 0.9em;\n      }\n  }\n}\n\n// .email-and-number {\n//     padding: 0 10px;\n// }\n\n.contact-popup-image {\n  background-position: center;\n  background-size: cover;\n  height: 250px;\n  width: 250px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup-heading": `ContactHeader_popup-heading__OQ3xr`,
	"basic-details": `ContactHeader_basic-details__NAXda`,
	"contact-popup-image": `ContactHeader_contact-popup-image__7bvPs`
};
export default ___CSS_LOADER_EXPORT___;
