// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMarker_user-marker-icon__tzYQ3 div {
  --size: 50px;
  --border-width: 2px;
  --connection-border-color-1:
      var(--accent-color-xxx-light);
  --connection-border-color-2:
      var(--accent-color-xx-dark);
  --stranger-border-color-1: red;
  --stranger-border-color-2: 500000;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2 + var(--border-width));
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  transition: all 0.25s ease-out;
}
.UserMarker_user-marker-icon__tzYQ3 div:before {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5333333333);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: calc(-1 * var(--border-width));
  border-radius: inherit;
}
.UserMarker_user-marker-icon__tzYQ3 div:hover {
  box-shadow: 0px 0px 50px rgba(255, 255, 0, 0.533);
}
.UserMarker_user-marker-icon__tzYQ3.UserMarker_connection__SoH\\+J div:before {
  background: linear-gradient(to top right, var(--connection-border-color-2), var(--connection-border-color-1));
}
.UserMarker_user-marker-icon__tzYQ3.UserMarker_stranger__PMohd div:before {
  background: linear-gradient(to top right, var(--stranger-border-color-2), var(--stranger-border-color-1));
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/UserMarker/UserMarker.module.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,mBAAA;EACA;mCAAA;EAEA;iCAAA;EAEA,8BAAA;EACA,iCAAA;EACA,kBAAA;EACA,mBAAA;EACA,0DAAA;EACA,sBAAA;EACA,2BAAA;EACA,sBAAA;EACA,8BAAA;AAAR;AAEQ;EACI,mDAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,sCAAA;EACA,sBAAA;AAAZ;AAGQ;EACI,iDAAA;AADZ;AAOY;EACI,6GAAA;AALhB;AAYY;EACI,yGAAA;AAVhB","sourcesContent":[".user-marker-icon {\n    div {\n        --size: 50px;\n        --border-width: 2px;\n        --connection-border-color-1:\n            var(--accent-color-xxx-light);\n        --connection-border-color-2:\n            var(--accent-color-xx-dark);\n        --stranger-border-color-1: red;\n        --stranger-border-color-2: 500000;\n        width: var(--size);\n        height: var(--size);\n        border-radius: calc((var(--size) / 2) + var(--border-width));\n        background-size: cover;\n        background-position: center;\n        background-color: #ccc;\n        transition: all 0.25s ease-out;\n\n        &:before {\n            box-shadow: 0px 5px 5px #0008;\n            content: '';\n            position: absolute;\n            top: 0;\n            right: 0;\n            bottom: 0;\n            left: 0;\n            z-index: -1;\n            margin: calc(-1 * var(--border-width));\n            border-radius: inherit;\n        }\n\n        &:hover {\n            box-shadow: 0px 0px 50px rgba(255, 255, 0, .533);\n        }\n    }\n\n    &.connection {\n        div {\n            &:before {\n                background: linear-gradient(to top right, var(--connection-border-color-2), var(--connection-border-color-1));\n            }\n        }\n    }\n\n    &.stranger {\n        div {\n            &:before {\n                background: linear-gradient(to top right, var(--stranger-border-color-2), var(--stranger-border-color-1));\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-marker-icon": `UserMarker_user-marker-icon__tzYQ3`,
	"connection": `UserMarker_connection__SoH+J`,
	"stranger": `UserMarker_stranger__PMohd`
};
export default ___CSS_LOADER_EXPORT___;
